import React, { Component } from 'react'
import Star from 'CONFIG/i18n'
import Session from 'CONFIG/session'

export const UserContext = React.createContext()

export const UserConsumer = UserContext.Consumer

export class UserProvider extends Component {
  // Context state
  state = {
    logged:Session.checkTokenAndLoggedTimmer(),
    user_type:Session.getUserType(),
    user_avatar:Session.getAvatar(),
    locale:Session.getLocale(),
    template:Session.getFirstTemplate()
  }
  

  // Method to update state
  setUser = (token_input, locale_input,user_type,user_avatar,template) => {
    Session.setToken(token_input)
    Session.setLocale(locale_input)
    Session.setAvatar(user_avatar)
    Session.setUserType(user_type)
    this.setState({
      logged:true,
      locale:locale_input,
      user_type:user_type,
      user_avatar:user_avatar,
      template:Session.setTemplate(template)
    })
  }

  unSetUser = () => {
    const template_on_logout=Session.checkUrl()
    this.setState({
      logged:false,
      user_type:"General",
      user_avatar:"",
      //template:template_on_logout.template
    })
    Session.unsetToken()
    if(template_on_logout.template){
      window.location.reload(false);
    }
  }

  setLocale = async (locale_input) => {
    await this.setState({
      locale: locale_input
    })
    Session.setLocale(locale_input)
  }

  setTemplate =(input)=>{
    this.setState({
      template: Session.setTemplate(input)
    })
  }

  

  render() {
    const { children } = this.props
    const { locale } = this.state
    const { logged } = this.state
    const { user_type} =this.state
    const {user_avatar}=this.state
    const {template}=this.state
    const { setUser } = this
    const { unSetUser } = this
    const {setLocale}=this
    const {setTemplate}=this
    const i18n= new Star(this.state.locale)

    return (
      <UserContext.Provider
        value={{
          locale,
          i18n,
          logged,
          user_type,
          user_avatar,
          template,
          setUser,
          unSetUser,
          setLocale,
          setTemplate
        }}
      >
        {children}
      </UserContext.Provider>
    )
  }
}

export default 
  UserContext