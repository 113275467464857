import React from 'react';
import { Route, Routes, useNavigate } from "react-router-dom";

import {Header, Footer} from './header';

import BusinessPlanRoot from './PROJECT/Project main/bp_root';
import BudgetRoot from './BUDGET PROJECT/Budget Project Main/root';
import EditProfile from './USER/editprofile';
import AccountMain from './ACCOUNT/accountmain';
import MainScreen from './MAIN SCREEN/main_screen';
import HelpDesk from './MAIN SCREEN/helpdesk';
import Pricing from './MAIN SCREEN/pricing';
import ShowTemplates from './MAIN SCREEN/templates';
import ManagerMain from './ADMIN/root';
import PartnerMain from './PARTNERS/partners_main';
import DataResearchRoot from 'DATA RESEARCH/root';


function InPrivateAPP (props) {
  let navigate = useNavigate();

  const handleGoToGeneralRoot=()=>{
    this.handlePrincipalRouting("/app/main")
  }

  const handlePrincipalRouting=(val)=>{
    navigate(val);
  }

  const handlePrincipalButtonRouting=(event)=>{
    navigate(event.target.name)
  }

  const sendingprops={
    handlePrincipalRouting:handlePrincipalRouting,
    handlePrincipalButtonRouting:handlePrincipalButtonRouting,
    activateAlert: props.activateAlert
  }

  return (
    <div className="main_block placer1">
      <Header handlePrincipalButtonRouting={handlePrincipalButtonRouting} handleGoToGeneralRoot={handleGoToGeneralRoot}/>
      <div className="placer1 barnabeu">
        <Routes>
          <Route index element={<MainScreen {...sendingprops}/>}/>
          <Route path="main" element={<MainScreen {...sendingprops}/>}/>
          <Route path="manager" element={<ManagerMain />}/>
          <Route path="business_plans/*" element={<BusinessPlanRoot {...sendingprops}/>}/>
          <Route path="budget_project/*"element={<BudgetRoot {...sendingprops}/>}/>
          <Route path="data_research/*"element={<DataResearchRoot {...sendingprops}/>}/>
          <Route path='profile' element={<EditProfile {...sendingprops}/>}/>
          <Route path='subscriptions/*' element={<AccountMain {...sendingprops}/>}/>
          <Route path='partners/*' element={<PartnerMain {...sendingprops}/>}/>
          <Route path='templates' element={<ShowTemplates {...sendingprops}/>}/>
          <Route path='helpdesk' element={<HelpDesk />}/>
          <Route path='pricing' element={<Pricing />}/>
        </Routes>
      </div>
      <div className="footer_bar">
        <Footer />
      </div>
      <div className="footer_bar_hidden">
        <Footer />
      </div>       
    </div>
  );
}
export default InPrivateAPP;