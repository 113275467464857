const {dotMultiply, add,dotDivide,multiply, sum, pow, abs,max} = require('mathjs');

function addArrays(inputer){
  var summed=new Array(inputer[0].length)
  summed.fill(0)
  inputer.forEach(function (item){
    summed=add(summed,item)
  })
  return summed
}

function accumulateOneArray(inputer){
  let bug=[...inputer]
  for(var i=1;i<bug.length;i++){
    bug[i]=bug[i]+bug[i-1]
  }
  return bug
}

function unNan(input){
  let ad=input
  if(input===undefined){
    ad=0
  }
  return ad
}

function removeFirstElementFromArray(element){
  let arr=[]
  arr= element.slice(1)
  return arr
}

//INCOME STATEMENT FUNCTIONS
function calRevenue(item,zero,fltr){
  let bug=[...zero]
  let sums=item.revenues
  if(sums != undefined){
    if(fltr!=="---"){
      sums = item.revenues.filter(item => item.rev_center==fltr)
    }
    if(sums.length>0){
        bug=addArrays(sums.map((value)=>value._rev_total_revenue))
    }  
  }
  return bug
}

function calCogs(item,zero,fltr){
  let bug=[...zero]
  let sums=item.revenues
  if(sums != undefined){
    if(fltr!=="---"){
      sums = item.revenues.filter(item => item.rev_center==fltr)
    } 
    if(sums.length>0){
      bug=addArrays(sums.map((value)=> value.rev_cogs._cogs_total))
    }
  }
  return bug
}

const calOperationalGrants=(item,zero,fltr)=>{
    let bug=[...zero]
    let centopeid=item.other_gains_cost
    if(centopeid != undefined){
      if(fltr!=="---"){
        centopeid = item.other_gains_cost.filter(it => it.other_gains_cost_center==fltr)
      } 
      let dune_buggy=centopeid.filter(centopeid => centopeid.other_gains_cost_type === "operational_subsidy")
      if(dune_buggy.length>0){
          bug=addArrays(dune_buggy.map((value)=>value.other_gains_cost_value))
      }
    }
    return bug
}

const calOperatingExpenses=(item,zero,fltr)=>{
    let bug=[...zero]
    let sums=item.operating_expenses
    if(sums != undefined){
      if(fltr!=="---"){
        sums = item.operating_expenses.filter(item => item.op_exp_center==fltr)
      } 
      if(sums.length>0){
          bug=addArrays(sums.map((value)=>value._op_exp_total))
      }
    }
    return bug
}
const calHr=(item,zero,fltr)=>{
  let bug=[...zero]
  let sums=item.human_resources
  if(sums != undefined){
    if(fltr!=="---"){
      sums = item.human_resources.filter(item => item.hr_center==fltr)
    } 
    if(sums.length>0){
        bug=addArrays(sums.map((value)=>value._hr_total_cost))
    }
  }
  return bug
}

const calOtherGains=(item,zero,fltr)=>{
    let bug=[...zero]
    let centopeid=item.other_gains_cost
    if(centopeid != undefined){
      if(fltr!=="---"){
        centopeid = item.other_gains_cost.filter(item => item.other_gains_cost_center==fltr)
      } 
      let dune_buggy=centopeid.filter(centopeid => centopeid.other_gains_cost_type === "other_gain")
      if(dune_buggy.length>0){
          bug=addArrays(dune_buggy.map((value)=>value.other_gains_cost_value))
      }
    }
    return bug
}


const calOtherCosts=(item,zero,fltr)=>{
    let bug=[...zero]
    let centopeid=item.other_gains_cost
    if(centopeid != undefined){
      if(fltr!=="---"){
        centopeid = item.other_gains_cost.filter(item => item.other_gains_cost_center==fltr)
      } 
      let dune_buggy=centopeid.filter(centopeid => centopeid.other_gains_cost_type === "other_cost")
      if(dune_buggy.length>0){
          bug=addArrays(dune_buggy.map((value)=>value.other_gains_cost_value))
      }
    }
    return bug
}

const cal_depreciation=(item,zero,fltr)=>{
    let bug=[...zero]
    let sums=item.investments
    if(sums != undefined){
      if(fltr!=="---"){
      sums = item.investments.filter(item => item.inv_center==fltr)
    }
    if(sums.length>0){
      bug=addArrays(sums.map((value)=>value._inv_depreciation))
    }  
  }
  return bug
}

const cal_interest_cost=(item,zero,fltr)=>{
  let bug=[...zero]
  let sums=item.debt
  if(sums != undefined){
    if(fltr!=="---"){
      sums = item.debt.filter(item => item.debt_center==fltr)
    }
    if(sums.length>0){
      bug=addArrays(sums.map((value)=>value._debt_interest))
    }
  }
  return bug
}

const cal_corporate_tax=(item,zero,settings)=>{
  let bug=[...zero]
  let bug1=[...zero]
  let bug2=[...zero]
  let ebt=item.results._inc_st.ebt
  let revenue=item.results._inc_st.revenue
  let irc=settings.irc
  const gross_revenue_tax=settings.gross_revenue_tax
  for (var i=0;i<bug1.length;i++){
    bug1[i]=max(irc/100*ebt[i],0)
  }
  bug2=dotMultiply(dotDivide(gross_revenue_tax,100),revenue)
  bug=add(bug1,bug2)
  return bug
}

//GOV FUNCTION
function calVatSales(item,zero){
  let bug=[...zero]
  if(item.revenues.length>0){
      bug=addArrays(item.revenues.map((value)=>value._rev_total_vat))
  }
  return bug
}

function calVatCogs(item,zero){
  let bug=[...zero]
  if(item.revenues.length>0){
    bug=addArrays(item.revenues.map((value)=>value.rev_cogs._cogs_vat_total))
  }
  return bug
}

function calVatOpExp(item,zero){
  let bug=[...zero]
  if(item.operating_expenses.length>0){
      bug=addArrays(item.operating_expenses.map((value)=>value._op_exp_vat_total))
  }
  return bug
}

function calVATInvestment(item,zero){
  let bug=[...zero]
  if(item.investments.length>0){
      bug=addArrays(item.investments.map((value)=>value._inv_vat))
  }
  return bug
}

function calVATReceivables(item,zero, project_settings){
  let vat_balance=item.results.gov.vat_balance
  let vat_pay_period=project_settings.vat_payment
  let bug=[...zero]
  for(var i=0;i<vat_balance.length;i++){
    if(vat_balance[i]<0){
      bug[i]=vat_balance[i]*vat_pay_period/365*-1
    }else {
      bug[i]=0
    }
  }
  return bug
}

function calVATPayables(item, zero,project_settings){
  let vat_balance=item.results.gov.vat_balance
  let vat_pay_period=project_settings.vat_payment
  let bug=[...zero]
  for(var i=0;i<vat_balance.length;i++){
    if(vat_balance[i]>=0){
      bug[i]=vat_balance[i]*vat_pay_period/365
    }else {
      bug[i]=0
    }
  }
  return bug
}

function calVATCashFlow(item,zero){
  let bug=[...zero]
  let it=item.results.gov
  for(var i=0;i<it.vat_balance.length;i++){
    if(i===0){
      bug[i]=it.vat_balance[i]+it.vat_receivable[i]-it.vat_payable[i]
    } else{
      bug[i]=it.vat_balance[i]+it.vat_receivable[i]-it.vat_receivable[i-1]-it.vat_payable[i]+it.vat_payable[i-1]
    }
  }
  return bug
}

//CF FUNCTIONS
const calClients=(item,zero)=>{
  let buggy=[...zero]
  let receivables=item.version_settings.days_accounts_receivables
  if(item.revenues.length>0){
      buggy=addArrays(item.revenues.map((value)=>value._rev_receivable))
  }
  return (dotDivide(dotMultiply(buggy,receivables),365))
}

function calPayables(item,zero){
  let buggy=[...zero]
  let dune_buggy=[...zero]
  if(item.revenues.length>0){
    buggy=addArrays(item.revenues.map((value)=>value.rev_cogs._cogs_payable))
  }
  if(item.operating_expenses.length>0){
    dune_buggy=addArrays(item.operating_expenses.map((value)=>value._op_exp_payable))
  }

  return add(buggy, dune_buggy)
}

function calSuppliers(item){
  let buggy=item.results.cf.payables
  let payables=item.version_settings.days_accounts_payables
  return (dotDivide(dotMultiply(buggy,payables),365))
}

function calInvestmentSuppliers(item,zero){
  let bug=[...zero]
  let dune_buggy=[]//total expenses with vat
  let payables=item.version_settings.investment_days_accounts_payables
  if(item.investments.length>0){
    dune_buggy=addArrays(item.investments.map((value)=>value._inv_receivable))
    bug=(dotDivide(dotMultiply(dune_buggy,payables),365))
  }
  return bug
}

function calReceivedFromClients(item,zero){
  let bug=[...zero]
  for(var i=0;i<item.results._inc_st.revenue.length;i++){
    if(i===0){
      bug[i]=item.results._inc_st.revenue[i]+ item.results.gov.vat_sales[i]-item.results.cf.clients_in_debt[i]
    } else {
      bug[i]=item.results._inc_st.revenue[i]+ item.results.gov.vat_sales[i]-item.results.cf.clients_in_debt[i]+item.results.cf.clients_in_debt[i-1]
    }
  }
  return bug  
}

function calPaidToSuppliers(item,zero){
  let bug=[...zero]
  for(var i=0;i<item.results._inc_st.revenue.length;i++){
    if(i===0){
      bug[i]=item.results.cf.payables[i]-item.results.cf.debt_to_suppliers[i]
    } else {
      bug[i]=item.results.cf.payables[i]-item.results.cf.debt_to_suppliers[i]+item.results.cf.debt_to_suppliers[i-1]
    }
  }
  return bug  
}

function calPaidToHr(item,zero){
  let bug=[...zero]
  if(item.human_resources.length>0){
      bug=addArrays(item.human_resources.map((value)=>value._hr_total_cost))
  }
  return bug
}

function calCorporateTaxCashFlow(item,zero){
  let bug=[...zero]
  for(var i=1;i<bug.length;i++){
    bug[i]=item.results._inc_st.corporate_tax[i-1]*-1
  }
  return bug
}

function calOtherGainsReceivables(item,zero){
  let bug=[...zero]
  let bug1=[...zero]
  let bug2=[...zero]
  let centopeid=item.other_gains_cost
  if(centopeid != undefined){
    let dune_buggy=centopeid.filter(element => element.other_gains_cost_type === "other_gain")
    if(dune_buggy !== undefined &&dune_buggy.length>0){
      bug1=addArrays(dune_buggy.map((value)=>value.other_gains_cost_value))
    }
    let centopeid1=item.other_gains_cost
    let dune_buggy1=centopeid1.filter(element => element.other_gains_cost_type === "operational_subsidy")
    if(dune_buggy1 !== undefined &&dune_buggy1.length>0){
      bug2=addArrays(dune_buggy1.map((value)=>value.other_gains_cost_value))
    }
  }
  bug=add(bug1,bug2)
  return bug
}
function calOtherCostsPayables(item,zero){
  let bug=[...zero]
  let centopeid=item.other_gains_cost
  if(centopeid != undefined){
    let dune_buggy=centopeid.filter(element => element.other_gains_cost_type === "other_cost")
    if(dune_buggy!==undefined && dune_buggy.length>0){
      bug=addArrays(dune_buggy.map((value)=>value.other_gains_cost_value))
    }
  }
  return bug
}

function calPaymentOfFixedAssets(item,zero,fltr){
  let bug=[...zero]
  let sums=item.investments
  if(sums != undefined){
    if(fltr!=="---"){
    sums = item.investments.filter(item => item.inv_center==fltr)
  }
  let sumys = sums.filter(sums => sums.inv_existence==="new")
  if(sumys.length>0){
    bug=addArrays(sumys.map((value)=>value._inv_net_acquisition))
  }  
}
  return bug
}

function callReceivedSharePremium(item,zero){
  let bug=[...zero]
  let centopeid=item.shareholders
  let dune_buggy=centopeid.filter(centopeid => centopeid.shareholders_type === "issued_capital")
  if(dune_buggy!==undefined && dune_buggy.length>0){
      bug=addArrays(dune_buggy.map((value)=>value.shareholders_share_premium))
  }
  return bug
}

function calCashFlowsOfNonCurrentAssetsLiabilities(item,zero){
  let bug=[...zero]
  let centopeid=item.other_balance
  let dune_buggy_assets=centopeid.filter(centopeids => (centopeids.other_balance_type === "non_current_assets" ||centopeids.other_balance_type === "current_assets"))
  let assets_bug=[...zero]
  if(dune_buggy_assets.length>0){
    const bugster=addArrays(dune_buggy_assets.map((value)=> value.other_balance_increase))
    const bugster2=addArrays(dune_buggy_assets.map((value)=> value.other_balance_decrease))
    for(var i=0;i<assets_bug.length;i++){
      assets_bug[i]=-bugster[i]+bugster2[i]
    }
  }
  let dune_buggy_liabilities=centopeid.filter(centopeids => (centopeids.other_balance_type === "non_current_liabilities" ||centopeids.other_balance_type === "current_liabilities"))
  let liabilities_bug=[...zero]
  if(dune_buggy_liabilities.length>0){
    const bugster_l=addArrays(dune_buggy_liabilities.map((value)=> value.other_balance_increase))
    const bugster2_l=addArrays(dune_buggy_liabilities.map((value)=> value.other_balance_decrease))
    for(var i=0;i<liabilities_bug.length;i++){
      liabilities_bug[i]=+bugster_l[i]-bugster2_l[i]
    }
  }
  bug=add(assets_bug,liabilities_bug)
  return bug
}

function calDebtIncrease(item,zero,fltr){
  let bug=[...zero]
  let sums=item.debt
  if(sums != undefined){
    if(fltr!=="---"){
      sums = item.debt.filter(item => item.debt_center==fltr)
    }
    let sumys = sums.filter(sums => sums.debt_existence==="new")
    if(sumys.length>0){
      bug=addArrays(sumys.map((value)=>value._debt_disbursement))
    }
  }  
  return bug
}

function calEquityAndEquivalentIncrease(item,zero){
  let bug=[...zero]
  if(item.shareholders.length>0){
    bug=addArrays(item.shareholders.map((value)=>{
      if(value.shareholders_type!="shareholders_loan"){
        return(
        value.shareholders_increase)
      } else{
        return [...zero]
      }
    }))
  }
  return bug
} 

function calDebtDecrease(item,zero,fltr){
  let bug=[...zero]
  let sums=item.debt
  if(sums != undefined){
    if(fltr!=="---"){
      sums = item.debt.filter(item => item.debt_center==fltr)
    }
    let sumys = sums.filter(sums => sums.debt_existence==="new")
    if(sumys.length>0){
      bug=addArrays(sumys.map((value)=>value._debt_reimbursement))
    }
  }  
  return bug
}

function calEquityAndEquivalentDecrease(item,zero){
  let bug=[...zero]
  if(item.shareholders.length>0){
    bug=addArrays(item.shareholders.map((value)=>{
      if(value.shareholders_type!="shareholders_loan"){
        return(
        value.shareholders_decrease)
      } else{
        return [...zero]
      }
    }))
  }
  return bug
} 

function calInitialCashFlows(item,zero){
  let bug=[...zero]
  for(var i=0;i<bug.length;i++){
    if(i===0){
      bug[i]=0
    } else{
      bug[i]=bug[i-1]+item.results.cf.cash_flow_variation[i-1]
    }
  }
  return bug
}

function calYearEndCashFlows(item,zero){
  let bug=[...zero]
  for(var i=0;i<bug.length;i++){
    bug[i]=item.results.cf.initial_cash_flows[i]+item.results.cf.cash_flow_variation[i]
  }
  return bug
}


//BALANCE FUNCTIONS
function calAssets(item,zero,slicer){
  let bug=[...zero]
  let centopeid=item.investments
  let dune_buggy=centopeid.filter(centopeid => centopeid.inv_type == slicer)
  if(dune_buggy.length>0){
      bug=addArrays(dune_buggy.map((value)=>value._inv_net_value))
  }
  
  return bug
}

const cal_other_balance=(item,zero,slicer)=>{
  let bug=[...zero]
  let centopeid=item.other_balance
  let dune_buggy=centopeid.filter(centopeid => centopeid.other_balance_type == slicer)
  if(dune_buggy.length>0){
      bug=addArrays(dune_buggy.map((value)=>value._other_balance_accumulated))
  }
  return bug
}

const cal_inventory=(item,zero)=>{
  let bug=[...zero]
  if(item.revenues.length>0){
      bug=addArrays(item.revenues.map((value)=>value.rev_cogs._inventory))
  }
  return bug
}

const calShareholders=(item,slicer,zero)=>{
  let bug=[...zero]
  let centopeid=item.shareholders
  
  let dune_buggy=centopeid.filter(centopeid => centopeid.shareholders_type === slicer)
  if(dune_buggy.length>0){
      bug=addArrays(dune_buggy.map((value)=>value._shareholders_total))
  }
  return bug
}

const callSharePremium=(item,zero)=>{
  let bug=[...zero]
  let centopeid=item.shareholders
  let dune_buggy=centopeid.filter(centopeid => centopeid.shareholders_type === "issued_capital")
  if(dune_buggy.length>0){
      bug=addArrays(dune_buggy.map((value)=>value._shareholders_share_premium_accumulated))
  }
  return bug
}


function cal_past_results(item,zero){
  let bug=item.results._inc_st.net_profit
  let buggy=[...zero]
  for(var i=0; i<buggy.length;i++){
    if(i===0){
      buggy[i]= item.version_historic_data.h_past_results
    } else{
      buggy[i] = bug[i-1]+buggy[i-1]
    }
  }
  return buggy
}

const cal_debt=(item,zero)=>{
  let bug=[...zero]
  if(item.debt.length>0){
      bug=addArrays(item.debt.map((value)=>value._debt_final_debt))
  }
  return bug
}

//WORKING CAPITAL
const calWcVariation=(item,zero)=>{
  let bug=[...zero]
  for(var i=0;i<item.length;i++){
    if(i===0){
      bug[i]=item[i]
    } else{
      bug[i]=item[i]-item[i-1]
    }
  }
  return bug
}

//VALUATION FUNCTIONS
function calOperationalCorporateTax(item,zero,settings){
  let bug=[...zero]
  let irc=''
  if(item.version_settings.use_general_irc===true){
    irc=settings.irc
  } else {
    irc=item.version_settings.version_corporate_tax_rate
  }
  for( let i = 0; i< bug.length; i++){
    bug[i]=max(item.results.fe.ebit[i]*irc[i]/100,0)
  }
  return bug
}

function calFixedAssetInvestment(item,zero){
  let bug=[...zero]
  if(item.investments.length>0){
    bug=addArrays(item.investments.map((value)=>{
      if(value._inv_net_acquisition.length>0){
        return value._inv_net_acquisition
      } else {return value._inv_acquisition}
    }))
  }
  return bug
}

function calDiscountFCFF(item,zero){
  let bug=[...zero]
  bug=dotMultiply(item.results.fe.fcff,item.coc._coc_yearly_discount_factor)
  return bug
}

function calNormalizedFCFF(item){
  let normalized_fcff=0
  if(item.coc.coc_residual_value_type==="limited" || item.coc.coc_residual_value_type==="perpetuity"){
    const count=item.results.fe.fcff.length-1
    const fcff= item.results.fe.fcff[count]
    const inv= item.results.fe.fixed_asset_investment[count]
    const depre=item.results._inc_st.depreciations[count]
    normalized_fcff= (fcff+inv-depre)*(1+(item.coc.coc_residual_value_growth/100))
  }
  return normalized_fcff
}

function calPerpetuity(item){
  let perpetuity=0
  if(item.coc.coc_residual_value_type==="perpetuity"){
    perpetuity= (item.results.fe.fcff_normalized/item.coc._coc_perpetuity_calculator)
  } else if(item.coc.coc_residual_value_type==="limited"){
    const normalized_fcff=item.results.fe.fcff_normalized
    const growth=item.coc.coc_residual_value_growth/100
    const rate=item.coc._coc_perpetuity_calculator
    const year_restricted=item.coc.coc_residual_value_years
    const perpetuity1=(normalized_fcff/rate)
    const prepertuity_2=normalized_fcff*pow((1+growth),year_restricted)
    const perpetuity2=(prepertuity_2/(rate))*(1/pow((1+rate+growth),year_restricted))
    perpetuity=perpetuity1-perpetuity2
  } else if(item.coc.coc_residual_value_type==="user_input"){
    perpetuity=unNan(item.coc.coc_resuidual_value_user_input)
  }
  return perpetuity
}
function calNPV(item){
  let bug=0
  const count=item.results.fe.fcff_discounted_accumulated.length
  bug=item.results.fe.fcff_discounted_accumulated[count-1]+item.results.fe.perpetuity_discounted
  return bug
}

function calIRR(item){
  function calDiscountFactor(discount, years){
    let bug=[]
    for(var i=0;i<years;i++){
      bug[i]=(1/(pow((1+(discount/100)),(i+1))))
    }
    return bug
  }
  
  let fcff=item.results.fe.fcff
  const number_of_years=fcff.length
  let dcf_fcff=[]
  let previous_npv=item.results.fe.npv
  let previous_guess=(item.coc._coc_wacc-1)
  let previous_guess_variation=1
  let guess_size=2
  let new_guess
  let npv
  let new_guess_variation=1
  let distance_variation=1
  let discount_factor=[]

  let normalized_fcff=item.results.fe.fcff_normalized
  let growth=item.coc.coc_residual_value_growth
  let perpetuity=0
  let discounted_perpetuity=0
  let year_restricted=item.coc.coc_residual_value_years
  let perpetuity_2

  let iteration_max=100
  let iteration=0

  //Analyse the need for iteration
  let positive= false
  let negative = false
  for (var i = 0; i < fcff.length; i++) {
    if (fcff[i] > 0) positive = true;
    if (fcff[i] < 0) negative = true;
  }

  let proceed_computation = getIRR(item)

  if(proceed_computation === true){
    if(item.coc.coc_residual_value_type==="none"){
      do{
        //compute new npv
        discount_factor=calDiscountFactor(previous_guess,number_of_years)
        dcf_fcff=dotMultiply(fcff,discount_factor)
        npv=sum(dcf_fcff)

        //distance
        distance_variation=npv/previous_npv-1
        new_guess_variation=previous_guess_variation/(-distance_variation)
        new_guess=previous_guess+new_guess_variation-previous_guess_variation

        //reset values
        previous_guess_variation=new_guess-previous_guess
        previous_npv=npv
        previous_guess=new_guess
      }
      while(abs(npv)>0.5 && (++iteration <iteration_max))
    } else if(item.coc.coc_residual_value_type==="perpetuity"){
      do{
        
        //set new guess
        if(previous_npv<0){
          new_guess=previous_guess-guess_size
        } else if(previous_npv>0){
          new_guess=previous_guess+guess_size
        }

        //compute new npv
        discount_factor=calDiscountFactor(new_guess,number_of_years)
        dcf_fcff=dotMultiply(fcff,discount_factor)
        perpetuity=normalized_fcff/((new_guess-growth)/100)
        discounted_perpetuity=multiply(perpetuity, (1/(pow((1+(new_guess/100)),number_of_years))))
        npv=sum(dcf_fcff)+discounted_perpetuity

        //Decide wether to change guess size.
        if((npv<0 &&previous_npv>0)||(npv>0 &&previous_npv<0)){
          guess_size=guess_size/2
        }

        //reset guess and npv
        previous_guess=new_guess
        previous_npv=npv
      }
      while(abs(npv)>0.05 && (guess_size>0.01)) 
    } else if(item.coc.coc_residual_value_type==="limited"){
      do{
        //set new guess
        if(previous_npv<0){
          new_guess=previous_guess-guess_size
        } else if(previous_npv>0){
          new_guess=previous_guess+guess_size
        }

        //compute new npv
        discount_factor=calDiscountFactor(new_guess,number_of_years)
        dcf_fcff=dotMultiply(fcff,discount_factor)
        perpetuity=normalized_fcff/((new_guess-growth)/100)
        perpetuity_2=(normalized_fcff*(pow((1+growth),(year_restricted))))/((new_guess-growth)/100)*(1/pow((1+new_guess/100),year_restricted))
        discounted_perpetuity=multiply((perpetuity-perpetuity_2), (1/(pow((1+(new_guess/100)),number_of_years))))
        npv=sum(dcf_fcff)+discounted_perpetuity
        
        //Decide wether to change guess size.
        if((npv<0 &&previous_npv>0)||(npv>0 &&previous_npv<0)){
          guess_size=guess_size/2
        }

        //reset guess and npv
        previous_guess=new_guess
        previous_npv=npv
      }
      while(abs(npv)>0.05 && (guess_size>0.01)) 
    } else if(item.coc.coc_residual_value_type==="user_input"){
      do{
        
        //set new guess
        if(previous_npv<0){
          new_guess=previous_guess-guess_size
        } else if(previous_npv>0){
          new_guess=previous_guess+guess_size
        }

        //compute new npv
        discount_factor=calDiscountFactor(new_guess,number_of_years)
        dcf_fcff=dotMultiply(fcff,discount_factor)
        perpetuity=unNan(item.coc.coc_resuidual_value_user_input)
        discounted_perpetuity=multiply(perpetuity, (1/(pow((1+(new_guess/100)),number_of_years))))
        npv=sum(dcf_fcff)+discounted_perpetuity

        //Decide wether to change guess size.
        if((npv<0 &&previous_npv>0)||(npv>0 &&previous_npv<0)){
          guess_size=guess_size/2
        }

        //reset guess and npv
        previous_guess=new_guess
        previous_npv=npv
      }
      while(abs(npv)>0.05 && (guess_size>0.01)) 
    }
  } else {previous_guess=0}
  return (previous_guess/100)
}

function getIRR(item){
  let fcff=item.results.fe.fcff
  //Analyse the need for iteration
  let positive= false
  let negative = false
  let changecount =0 
  for (var i = 0; i < fcff.length; i++) {
    if (fcff[i] > 0) positive = true;
    if (fcff[i] < 0) negative = true;
    if (i>0){
      if((fcff[i]<0 && fcff[i-1]>0) || (fcff[i]>0 && fcff[i-1]<0)){
        changecount++
      }
    }
  }
  if(positive===true && negative ===true && changecount<2) {
    return true
  } else{
    return false
  }
}


function calPayback(item){
  const nsection=item.results.fe.fcff_discounted_accumulated
  const section=[...nsection]
  let result=0
  for(var i=0;i<section.length;i++){
    if(i===0){
      if(section[0]>0){
        result=i+1
      }
    } else if (section[i]>0 && section[i-1]<0){
      result=i+1
    } else if (section[i]<0){
      result= (">"+section.length)
    }
  }
  return result
}

const histF={
  cal_inventory:(item,zero)=>{
    let bug=[...zero]
    if(item.revenues.length>0){
        bug=addArrays(item.revenues.map((value)=>value.rev_cogs._inventory))
    }
    bug[0]=item.version_historic_data.h_inventories
    return bug
  },
  calClients:(item,zero)=>{
    let buggy=[...zero]
    let receivables=item.version_settings.days_accounts_receivables
    if(item.revenues.length>0){
        buggy=addArrays(item.revenues.map((value)=>value._rev_receivable))
    }
    buggy=(dotDivide(dotMultiply(buggy,receivables),365))
    buggy[0]=item.version_historic_data.h_clients
    return buggy
  },
  calPayables:(item,zero)=>{
    let buggy=[...zero]
    let dune_buggy=[...zero]
    if(item.revenues.length>0){
      buggy=addArrays(item.revenues.map((value)=>value.rev_cogs._cogs_payable))
    }
    if(item.operating_expenses.length>0){
      dune_buggy=addArrays(item.operating_expenses.map((value)=>value._op_exp_payable))
    }
    dune_buggy[0]=0
    return dune_buggy
  },
  calVATReceivables:(item,zero, project_settings)=>{
    let vat_balance=item.results.gov.vat_balance
    let vat_pay_period=project_settings.vat_payment
    let bug=[...zero]
    for(var i=0;i<vat_balance.length;i++){
      if(i===0){
        bug[i]=item.version_historic_data.h_government_receivable
      }else if(vat_balance[i]<0){
        bug[i]=vat_balance[i]*vat_pay_period/365*-1
      }else {
        bug[i]=0
      }
    }
    return bug
  },
  calVATPayables:(item, zero,project_settings)=>{
    let vat_balance=item.results.gov.vat_balance
    let vat_pay_period=project_settings.vat_payment
    let bug=[...zero]
    for(var i=0;i<vat_balance.length;i++){
      if(i===0){
        bug[i]=item.version_historic_data.h_government_payable
      } else if(vat_balance[i]>=0){
        bug[i]=vat_balance[i]*vat_pay_period/365
      } else {
        bug[i]=0
      }
    }
    return bug
  },
  calVATCashFlow:(item,zero)=>{
    let bug=[...zero]
    let it=item.results.gov
    for(var i=0;i<it.vat_balance.length;i++){
      if(i===0){
        bug[i]=0 
      } else if(i===1){
        bug[i]=it.vat_balance[i]+it.vat_receivable[i]-it.vat_receivable[i-1]-it.vat_payable[i]+it.vat_payable[i-1]-item.results._inc_st.corporate_tax[0]
      } else{
        bug[i]=it.vat_balance[i]+it.vat_receivable[i]-it.vat_receivable[i-1]-it.vat_payable[i]+it.vat_payable[i-1]
      }
    }
    return bug
  },
  calGovPayables:(item,zero)=>{
    let buggy=[...zero]
    buggy=add(
      item.results.gov.vat_payable,
      item.results._inc_st.corporate_tax
    )
    buggy[0]=item.version_historic_data.h_government_payable
    return buggy
  },
  calGovReceivables:(item,zero)=>{
    let buggy=[...zero]
    buggy=item.results.gov.vat_receivable
    buggy[0]=item.version_historic_data.h_government_receivable
    return buggy
  },
  calInitialCashFlows:(item,zero)=>{
    let bug=[...zero]
    for(var i=0;i<bug.length;i++){
      if(i===0){
        bug[i]=item.version_historic_data.h_cash
      } else{
        bug[i]=bug[i-1]+item.results.cf.cash_flow_variation[i-1]
      }
    }
    return bug
  },
  calYearEndCashFlows:(item,zero)=>{
    let bug=[...zero]
    for(var i=0;i<bug.length;i++){
      if(i===0){
        bug[i]=item.version_historic_data.h_cash
      } else{
        bug[i]=item.results.cf.initial_cash_flows[i]+item.results.cf.cash_flow_variation[i]  
      }
    }
    return bug
  },
  calCashVariation:(item, zero)=>{
    let bug=[...zero]
    bug=add(
      item.results.cf.operational_cash_flow,
      item.results.cf.investment_cash_flows,
      item.results.cf.financing_cashflows
    )
    bug[0]=0
    return bug
  },
  callSharePremium:(item,zero)=>{
    let bug=[...zero]
    let centopeid=item.shareholders
    let dune_buggy=centopeid.filter(centopeid => centopeid.shareholders_type === "issued_capital")
    if(dune_buggy.length>0){
      bug=addArrays(dune_buggy.map((value)=>value._shareholders_share_premium_accumulated))
    }
    bug=add(bug,new Array(zero.length).fill(item.version_historic_data.h_other_equity_changes))
    return bug
  },
  calSuppliers:(item)=>{
    let buggy=item.results.cf.payables
    let payables=item.version_settings.days_accounts_payables
    buggy=dotDivide(dotMultiply(buggy,payables),365)
    buggy[0]=item.version_historic_data.h_suppliers
    return buggy
  },
  calInvestmentSuppliers:(item,zero)=>{
    let bug=[...zero]
    let dune_buggy=[]//total expenses with vat
    let payables=item.version_settings.investment_days_accounts_payables
    if(item.investments.length>0){
      dune_buggy=addArrays(item.investments.map((value)=>value._inv_receivable))
      bug=(dotDivide(dotMultiply(dune_buggy,payables),365))
    }
    bug[0]=0
    return bug
  },
  calOtherAccountsPayables:(item,zero)=>{
    let buggy=[...zero]
    buggy=item.results.cf.debt_to_investment_suppliers
    buggy[0]=item.version_historic_data.h_other_accounts_payables
    return buggy
  },
  calOperationalCorporateTax:(item,zero)=>{
    let bug=[0]
    let irc=removeFirstElementFromArray(item.version_settings.version_corporate_tax_rate)
    for( let i = 0; i< irc.length; i++){
      bug[i]=max(item.results.fe.ebit[i]*irc[i]/100,0)
    }
    return bug
  },
  calPayablesReceivables:(item,zero)=>{
    let bug=[...zero]
    const receivables_from_other_gains=calOtherGainsReceivables(item,zero)
    const payables_from_other_costs=dotMultiply(-1,calOtherCostsPayables(item,zero))
    bug=add(
    dotMultiply(-1,item.results.gov.vat_cashflow),
    receivables_from_other_gains,
    payables_from_other_costs)
    bug[1]=bug[1]-item.version_historic_data.h_other_accounts_payables
    return bug
  }
}


//WHERE THE MAIN CAL COMES TO
const calculateOnlyIfEdited= async(budgt, settings,edited)=>{
  if(edited===true){
    budgt=calResults(budgt,settings)
  }
  return budgt
}

const calResults = async(c,settings,filter)=>{
  const s=settings
  
  const z1=new Array(12).fill(0)
  

  //INC STATEMENT
  c.results._inc_st.revenue=calRevenue(c,z1,filter)
  c.results._inc_st.cogs=calCogs(c,z1,filter)
  c.results._inc_st.operational_grants=calOperationalGrants(c,z1,filter)
  c.results._inc_st.operating_expenses=calOperatingExpenses(c,z1,filter)
  c.results._inc_st.hr=calHr(c,z1,filter)
  c.results._inc_st.other_gains=calOtherGains(c,z1,filter)
  c.results._inc_st.other_costs=calOtherCosts(c,z1,filter)
  c.results._inc_st.ebitda=add(
    c.results._inc_st.revenue,
    c.results._inc_st.operational_grants,
    (dotMultiply(-1,c.results._inc_st.cogs)),
    (dotMultiply(-1,c.results._inc_st.operating_expenses)),
    (dotMultiply(-1,c.results._inc_st.hr)),
    c.results._inc_st.other_gains,
    (dotMultiply(-1,c.results._inc_st.other_costs))
  )
  c.results._inc_st.depreciations=cal_depreciation(c,z1,filter)
  c.results._inc_st.ebit=add(
    c.results._inc_st.ebitda,
    (dotMultiply(-1,c.results._inc_st.depreciations))
  )
  c.results._inc_st.interest_cost=cal_interest_cost(c,z1,filter)
  c.results._inc_st.ebt=add(
      c.results._inc_st.ebit,
      (dotMultiply(-1,c.results._inc_st.interest_cost))
  )
  c.results._inc_st.corporate_tax=cal_corporate_tax(c,z1,settings)
  c.results._inc_st.net_profit=add(
      c.results._inc_st.ebt,
      dotMultiply(-1,c.results._inc_st.corporate_tax)
  ) 

  //INC STATEMENT ACCUMULATED
  c.results._inc_st_acc.revenue=accumulateOneArray(c.results._inc_st.revenue)
  c.results._inc_st_acc.cogs=accumulateOneArray(c.results._inc_st.cogs)
  c.results._inc_st_acc.operational_grants=accumulateOneArray(c.results._inc_st.operational_grants)
  c.results._inc_st_acc.operating_expenses=accumulateOneArray(c.results._inc_st.operating_expenses)
  c.results._inc_st_acc.hr=accumulateOneArray(c.results._inc_st.hr)
  c.results._inc_st_acc.other_gains=accumulateOneArray(c.results._inc_st.other_gains)
  c.results._inc_st_acc.other_costs=accumulateOneArray(c.results._inc_st.other_costs)
  c.results._inc_st_acc.ebitda=accumulateOneArray(c.results._inc_st.ebitda)
  c.results._inc_st_acc.depreciations=accumulateOneArray(c.results._inc_st.depreciations)
  c.results._inc_st_acc.ebit=accumulateOneArray(c.results._inc_st.ebit)
  c.results._inc_st_acc.interest_cost=accumulateOneArray(c.results._inc_st.interest_cost)
  c.results._inc_st_acc.ebt=accumulateOneArray(c.results._inc_st.ebt)
  c.results._inc_st_acc.corporate_tax=accumulateOneArray(c.results._inc_st.corporate_tax)
  c.results._inc_st_acc.net_profit=accumulateOneArray(c.results._inc_st.net_profit)
  

  //CASH STATEMENT
  c.results.cf.received_from_clients=c.results._inc_st.revenue
  c.results.cf.paid_to_suppliers=add(c.results._inc_st.cogs,c.results._inc_st.cogs)
  c.results.cf.payment_to_hr=c.results._inc_st.hr
  c.results.cf.other_payments_and_receivables=add(
    c.results._inc_st.operational_grants,
    c.results._inc_st.other_gains,
    dotMultiply(-1,c.results._inc_st.other_costs)
  )
  c.results.cf.operational_cash_flow=add(
    c.results.cf.received_from_clients,
    dotMultiply(-1,c.results.cf.paid_to_suppliers),
    dotMultiply(-1,c.results.cf.payment_to_hr),
    c.results.cf.other_payments_and_receivables
  )
  c.results.cf.investment_in_assets=calPaymentOfFixedAssets(c,z1,filter)
  c.results.cf.debt_increase=calDebtIncrease(c,z1,filter)
  c.results.cf.interest_cost=c.results._inc_st.interest_cost
  c.results.cf.debt_decrease=calDebtDecrease(c, z1,filter)
  c.results.cf.financing_cashflows=add(
    c.results.cf.debt_increase,
    dotMultiply(-1,c.results.cf.interest_cost),
    dotMultiply(-1,c.results.cf.debt_decrease),
  )
  c.results.cf.cash_flow_variation=add(
    c.results.cf.financing_cashflows,
    c.results.cf.investment_in_assets,
    c.results.cf.operational_cash_flow
  )

  //CASH STATEMENT ACCUMULATED
  c.results.cf_acc.received_from_clients=accumulateOneArray(c.results.cf.received_from_clients)
  c.results.cf_acc.paid_to_suppliers=accumulateOneArray(c.results.cf.paid_to_suppliers)
  c.results.cf_acc.payment_to_hr=accumulateOneArray(c.results.cf.payment_to_hr)
  c.results.cf_acc.other_payments_and_receivables=accumulateOneArray(c.results.cf.other_payments_and_receivables)
  c.results.cf_acc.operational_cash_flow=accumulateOneArray(c.results.cf.operational_cash_flow)
  c.results.cf_acc.investment_in_assets=accumulateOneArray(c.results.cf.investment_in_assets)
  c.results.cf_acc.debt_increase=accumulateOneArray(c.results.cf.debt_increase)
  c.results.cf_acc.interest_cost=accumulateOneArray(c.results.cf.interest_cost)
  c.results.cf_acc.debt_decrease=accumulateOneArray(c.results.cf.debt_decrease)
  c.results.cf_acc.financing_cashflows=accumulateOneArray(c.results.cf.financing_cashflows)
  c.results.cf_acc.cash_flow_variation=accumulateOneArray(c.results.cf.cash_flow_variation)


return (c)
}


export {
  calculateOnlyIfEdited,
  calResults
}