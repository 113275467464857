import React from "react";
import {Col, Row} from 'Support/grid';
import {round} from 'mathjs';
import FileSaver from 'file-saver';
import {writeFileXLSX} from 'xlsx';
import { RegularButton } from 'Support/listbuttons';

function firstRowLooper(el2, el3){
  let bug=[]
  bug.push(el2)
  var i
  for(i=0;i<el3.length;i++){
    bug.push(el3[i].toString())
  }
  return bug
}

function elLooper(first_el,el1, el2, el3){
  let bug=new Object()
  bug[el2]= first_el
  var i
  for(i=0;i<el1.length;i++){
    let upi=el3[i]
    bug[upi]=round(el1[i])
  }
  return bug
}

function elLooperSpecial(first_el,el1, el2, el3){
  let bug=new Object()
  let upi=el3[0]
  bug[el2]= first_el
  bug[upi]=el1
  return bug
}


class ExcelDownload extends React.Component{
  render(){
    const props={       
      trsl:this.props.trsl,
      data:this.props.data,
      p_name:this.props.p_name
    }

    return(
      <Row>
        <Col xs={12}>
          <h6>XLSX</h6>
        </Col>
        <ISDownload {...props} periods={this.props.periods}/>
        <ISDownloadAcc {...props} periods={this.props.periods}/>
        <CSDownload {...props} periods={this.props.periods}/>
        <CSACCDownload {...props} periods={this.props.periods}/>
      </Row>
    )
  }
}

class ISDownload extends React.Component {
  release_IS=()=>{
    const year=this.props.trsl['year']
    const periods=this.props.periods
    const header=firstRowLooper(year,periods)
    const income_statement_data = [
      elLooper(this.props.trsl['revenues'], this.props.data.results._inc_st.revenue, year,periods),
      elLooper(this.props.trsl['subsidies'], this.props.data.results._inc_st.operational_grants, year,periods),
      elLooper(this.props.trsl['cogs'], this.props.data.results._inc_st.cogs, year,periods),
      elLooper(this.props.trsl['operating_exp'], this.props.data.results._inc_st.operating_expenses, year,periods),
      elLooper(this.props.trsl['employees'], this.props.data.results._inc_st.hr, year,periods),
      elLooper(this.props.trsl['other_revenues'], this.props.data.results._inc_st.other_gains, year,periods),
      elLooper(this.props.trsl['other_costs'], this.props.data.results._inc_st.other_costs, year,periods),
      elLooper(this.props.trsl['ebitda'], this.props.data.results._inc_st.ebitda, year,periods)
    ]
    return [income_statement_data,header]
  }
  render() {
    return(
      <ExportCSV 
      dataFunction={this.release_IS} 
      fileName={this.props.trsl['income_st']} 
      p_name={this.props.p_name}
      v_name={this.props.v_name} />
    )
  }
}

class ISDownloadAcc extends React.Component {
  release_IS=()=>{
    const year=this.props.trsl['year']
    const periods=this.props.periods
    const header=firstRowLooper(year,periods)
    const income_statement_data = [
      elLooper(this.props.trsl['revenues'], this.props.data.results._inc_st.revenue, year,periods),
      elLooper(this.props.trsl['subsidies'], this.props.data.results._inc_st.operational_grants, year,periods),
      elLooper(this.props.trsl['cogs'], this.props.data.results._inc_st.cogs, year,periods),
      elLooper(this.props.trsl['operating_exp'], this.props.data.results._inc_st.operating_expenses, year,periods),
      elLooper(this.props.trsl['employees'], this.props.data.results._inc_st.hr, year,periods),
      elLooper(this.props.trsl['other_revenues'], this.props.data.results._inc_st.other_gains, year,periods),
      elLooper(this.props.trsl['other_costs'], this.props.data.results._inc_st.other_costs, year,periods),
      elLooper(this.props.trsl['ebitda'], this.props.data.results._inc_st.ebitda, year,periods)
    ]
    return [income_statement_data,header]
  }
  render() {
    return(
      <ExportCSV 
      dataFunction={this.release_IS} 
      fileName={this.props.trsl['income_st_acc']} 
      p_name={this.props.p_name}
      v_name={this.props.v_name} />
    )
  }
}

class CSDownload extends React.Component {
  release_IS=()=>{
    const year=this.props.trsl['year']
    const periods=this.props.periods
    const header=firstRowLooper(year,periods)
    const income_statement_data = [
      elLooper(this.props.trsl['received_clients'], this.props.data.results.cf.received_from_clients, year,periods),
      elLooper(this.props.trsl['paid_suppliers'], this.props.data.results.cf.paid_to_suppliers, year,periods),
      elLooper(this.props.trsl['paid_hr'], this.props.data.results.cf.payment_to_hr, year,periods),
      elLooper(this.props.trsl['other_pay_rec'], this.props.data.results.cf.other_payments_and_receivables, year,periods),
      elLooper(this.props.trsl['operational_cf'], this.props.data.results.cf.operational_cash_flow, year,periods),
      elLooper(this.props.trsl['investment_cf'], this.props.data.results.cf.investment_in_assets, year,periods),
      elLooper(this.props.trsl['debt_increase'], this.props.data.results.cf.debt_increase, year,periods),
      elLooper(this.props.trsl['financing_costs'], this.props.data.results.cf.interest_cost, year,periods),
      elLooper(this.props.trsl['debt_decrease'], this.props.data.results.cf.debt_decrease, year,periods),
      elLooper(this.props.trsl['financing_cashflows'], this.props.data.results.cf.financing_cashflows, year,periods),
      elLooper(this.props.trsl['cf_variation'], this.props.data.results.cf.cash_flow_variation, year,periods)
    ]
    return [income_statement_data,header]
  }
  render() {
    return(
      <ExportCSV 
      dataFunction={this.release_IS} 
      fileName={this.props.trsl['cash_st']} 
      p_name={this.props.p_name}
      v_name={this.props.v_name} />
    )
  }
}

class CSACCDownload extends React.Component {
  release_IS=()=>{
    const year=this.props.trsl['year']
    const periods=this.props.periods
    const header=firstRowLooper(year,periods)
    const income_statement_data = [
      elLooper(this.props.trsl['received_clients'], this.props.data.results.cf_acc.received_from_clients, year,periods),
      elLooper(this.props.trsl['paid_suppliers'], this.props.data.results.cf_acc.paid_to_suppliers, year,periods),
      elLooper(this.props.trsl['paid_hr'], this.props.data.results.cf_acc.payment_to_hr, year,periods),
      elLooper(this.props.trsl['other_pay_rec'], this.props.data.results.cf_acc.other_payments_and_receivables, year,periods),
      elLooper(this.props.trsl['operational_cf'], this.props.data.results.cf_acc.operational_cash_flow, year,periods),
      elLooper(this.props.trsl['investment_cf'], this.props.data.results.cf_acc.investment_in_assets, year,periods),
      elLooper(this.props.trsl['debt_increase'], this.props.data.results.cf_acc.debt_increase, year,periods),
      elLooper(this.props.trsl['financing_costs'], this.props.data.results.cf_acc.interest_cost, year,periods),
      elLooper(this.props.trsl['debt_decrease'], this.props.data.results.cf_acc.debt_decrease, year,periods),
      elLooper(this.props.trsl['financing_cashflows'], this.props.data.results.cf_acc.financing_cashflows, year,periods),
      elLooper(this.props.trsl['cf_variation'], this.props.data.results.cf_acc.cash_flow_variation, year,periods)
    ]
    return [income_statement_data,header]
  }
  render() {
    return(
      <ExportCSV 
      dataFunction={this.release_IS} 
      fileName={this.props.trsl['cash_st_acc']} 
      p_name={this.props.p_name}
      v_name={this.props.v_name} />
    )
  }
}

const ExportCSV = ({fileName, dataFunction, p_name, v_name}) => {

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  
  const date=new Date()
  const primarydata=[
    {
      Date:date,
      Project:p_name,
      Version:v_name
    }
  ]

  const exportToCSV = (fileName) => {
    const tdata=dataFunction()
      const ws = writeFileXLSX.utils.json_to_sheet(primarydata)
      writeFileXLSX.utils.sheet_add_json(ws, tdata[0],{header:tdata[1], origin: "A4"});
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = writeFileXLSX.write(wb, { bookType: 'XLSX', type: 'array' });
      const data = new Blob([excelBuffer], {type: fileType});
      FileSaver.saveAs(data, fileName + fileExtension);
  }

  return (
    <Col xs={6} md={4} className="dashboard_exportbutton">
      <RegularButton  bk="bk_primary" onClick={(e) => exportToCSV(fileName, dataFunction)} label={fileName} />
    </Col>
  )
}

export default ExcelDownload;