import React from 'react';
import { Row, Col} from 'Support/grid';
import { BarGraph, StackedBarGraph, LineGraphs,dataTransformForGrouping } from 'Support/graphs';
import {dotDivide, subtract, dotMultiply} from 'mathjs';


import UserContext from 'CONFIG/auth';
import { Card } from 'Support/warning_cards';

const CurrencyContext= React.createContext();

function dataTransformWithColor(y_input,x_input){
  let bug= []
  function colorSetter(input){
    if(input>=0){
      return 1
    } else{
      return 0
    }
  }
  for (var i=0;i<y_input.length;i++){
    bug[i]={
      x:x_input[i].toString(),
      y:y_input[i],
      color:colorSetter(y_input[i])
    }
  }
  return bug
}

function validateRatios(input){
  let array_to_return=new Array(input.length).fill(0)
  for(var i=0;i<input.length;i++){
    if(isFinite(input[i])){
      array_to_return[i]=input[i]
    } else {
      array_to_return[i]=0
    }
  }
  return array_to_return
}

class Graphs extends React.Component{

  render(){
    const props=this.props

    return(
      <CurrencyContext.Provider value={this.props.feasibility_settings.currency}>
        <h3>
          {this.props.trsl['visuals']}
        </h3>
        <Row>
          <RevenueGraph {...props} />
          <EBITDAGraph {...props} />
          <NetIncomeGraph {...props} />
          <CostsGraph {...props} />
          <FundingGraph {...props} />
          <InvestmentGraph {...props} />
          <GrossMarginGraph {...props} />
          <NetMarginGraph {...props} />
          <ReturnOnSalesGraph {...props} />
        </Row>
        <Row className='blanktop_10'>
          <Col xs={12}>
            <h6>{this.props.trsl['levered_analysis']}</h6>
          </Col>
          <NPVCard {...props}/>
          <IRRCard {...props}/>
          <PaybackCard {...props}/>
        </Row>
      </CurrencyContext.Provider>
    );
  }
}

class RevenueGraph extends React.Component{
  render(){
    const datur=dataTransformWithColor(this.props.data.results._inc_st.revenue,this.props.years)
    return(
      <Col xs={12} md={4} >
        <Card color="lightaccent" header={this.props.trsl['revenues']} body={<BarGraph data={datur} />}/>
      </Col>
    );
  }
}

class EBITDAGraph extends React.Component{
  render(){
    const datur=dataTransformWithColor(this.props.data.results._inc_st.ebitda,this.props.years)
    return(
      <Col xs={12} md={4} >
        <Card color="lightaccent"header={this.props.trsl['ebitda']} body={<BarGraph data={datur} />}/>
      </Col>
    );
  }
}

function NetIncomeGraph(props){
  const datur=dataTransformWithColor(props.data.results._inc_st.net_profit,props.years)
  return(
    <Col xs={12} md={4}>
      <Card color="lightaccent"header={props.trsl['net_income']} body={<BarGraph data={datur} />}/>
    </Col>
  );
}

function CostsGraph(props){
  const data=[props.data.results._inc_st.cogs, props.data.results._inc_st.operating_expenses, props.data.results._inc_st.hr]
  const color=[ props.stackpalette[0], props.stackpalette[1], props.stackpalette[2]]
  const legend = [props.trsl['cogs'], props.trsl['operating_exp'],props.trsl['employees']];
  const set1=dataTransformForGrouping(data, props.years, legend, color)

  return(
    <Col xs={12} md={4}>
      <Card color="lightaccent"header={props.trsl['costs']} body={<StackedBarGraph data={set1}/>}/>
    </Col>
  );
}

function FundingGraph(props){
  const data=[props.data.results._bal.issued_capital, props.data.results._bal.other_equity_instruments, props.data.results._bal.shareholders_loan,props.data.results._bal.debt]
  const color=[ props.stackpalette[0], props.stackpalette[1], props.stackpalette[2],props.stackpalette[3]]
  const legend = [props.trsl['issued_capital'], props.trsl['other_equity_instr'], props.trsl['shareholders_loan'], props.trsl['debt']];
  const set1=dataTransformForGrouping(data, props.years, legend, color)
  return(
    <Col xs={12} md={4}>
      <Card color="lightaccent"header={props.trsl['funding']} body={<StackedBarGraph data={set1}/>}/>
    </Col>
  );
}

function InvestmentGraph(props){
  const data=[props.data.results._bal.tangible_assets,props.data.results._bal.intangible_assets]
  const color=[props.stackpalette[0],props.stackpalette[1]]
  const legend = [props.trsl['tangible'], props.trsl['intangible']];
  const set1=dataTransformForGrouping(data, props.years, legend, color)
  return(
    <Col xs={12} md={4} >
      <Card color="lightaccent" header={props.trsl['net_tan_intan_assets']}body={<StackedBarGraph data={set1}/>} />
    </Col>
  );
}


function GrossMarginGraph(props){
  const result1=dotMultiply(subtract(dotDivide(props.data.results._inc_st.cogs,props.data.results._inc_st.revenue),1),-1)
  const result=validateRatios(result1)
  const datur=dataTransformWithColor(result,props.years)
  return(
    <Col xs={12} md={4}>
      <Card color="lightaccent" header={props.trsl['gross_margin']} body={<LineGraphs data={datur} for_min={result}/>} />
    </Col>
  );
}

function NetMarginGraph(props){
  const result1=dotDivide(props.data.results._inc_st.net_profit,props.data.results._inc_st.revenue)
  const result=validateRatios(result1)
  const datur=dataTransformWithColor(result,props.years)
  return(
    <Col xs={12} md={4}>
      <Card color="lightaccent" header={props.trsl['net_margin']} body={<LineGraphs data={datur} for_min={result}/>}/>
    </Col>
  );
}

function ReturnOnSalesGraph(props){
  const result1=dotDivide(props.data.results._inc_st.ebit,props.data.results._inc_st.revenue)
  const result=validateRatios(result1)
  const datur=dataTransformWithColor(result,props.years)
  return(
    <Col xs={12} md={4} >
      <Card color="lightaccent" header={props.trsl['return_on_sales']} body={<LineGraphs data={datur}/>}/>
    </Col>
  );
}

function NPVCard(props){
  return(
    <UserContext.Consumer>
    {user => (
      <CurrencyContext.Consumer>
        {currency => (
          <Col xs={12} md={4} >
            <Card color="lightaccent" header={props.trsl['npv']} body={<h2>{user.i18n.currF(props.data.results._lep.npv,currency)}</h2>}/>
          </Col>
        )}
      </CurrencyContext.Consumer>
    )}
  </UserContext.Consumer>
  );
}

function IRRCard(props){
  return(
    <UserContext.Consumer>
    {user => {
      function showIRR(in1,in2){
        if(in2===false){
          return 'N.A.'
        } else{ return user.i18n.percF(in1)}
      }
      return(
        <Col xs={12} md={4} >
          <Card color="lightaccent" header={props.trsl['irr']} body={<h2>{showIRR(props.data.results._lep.irr,props.data.results._lep._irr_exists)}</h2>}/>
        </Col>
    )}}
  </UserContext.Consumer>
  );
}

function PaybackCard(props){
  return(
    <Col xs={12} md={4} >
      <Card header={props.trsl['payback_period']} body={<h2>{props.data.results._lep.payback}</h2>} color="lightaccent"/>
    </Col>
  );
}

function GraphCard (props){
  return(
    
      <div className="dashboard_graphs_card">
        <h3>{props.name}</h3>
        <h2>{props.value}</h2>
      </div>

  )
}



export default Graphs;