import english from 'i18n/en.json'
import portuguese from 'i18n/pt.json'
import french from 'i18n/fr.json'

const i18n_options={
  en:english,
  pt:portuguese,
  fr:french
}

class Star {
  constructor(locale) {
    this.locale =locale;
    this.lang = i18n_options[locale];
  }
  
  getProperty(propertyName, object) {
    var parts = propertyName.split( "." ),
      length = parts.length,
      i,
      property = object || this;
  
    for ( i = 0; i < length; i++ ) {
      property = property[parts[i]];
    }
    return property;
  }

  t(pather){
    return this.getProperty(pather,this.lang )
  }
  currF(number, currency,decimals){
    if(decimals===true){
      return(
        new Intl.NumberFormat(this.locale, { 
          style: 'currency', 
          currency: currency,
          minimumFractionDigits:2,
          maximumFractionDigits:2
        }).format(number)
      )
    } else{
      return(
        new Intl.NumberFormat(this.locale, { 
          style: 'currency', 
          currency: currency,
          minimumFractionDigits:0,
          maximumFractionDigits:0
        }).format(number)
      )
    }
  }
  percF(number){
    return(
      Intl.NumberFormat(this.locale, {
        style: 'percent', 
        minimumFractionDigits:2,
        maximumFractionDigits:2}).format(number))
  }
  deciF(number,decimals){
    if(decimals===true){
      return(new Intl.NumberFormat(this.locale, { 
        style: 'decimal',
        minimumFractionDigits:2,
        maximumFractionDigits:2
      }).format(number))
    } else {
      return(new Intl.NumberFormat(this.locale, { 
        style: 'decimal',
        minimumFractionDigits:0,
        maximumFractionDigits:0
      }).format(number))
    }
  }
  dateF(date){return(Intl.DateTimeFormat(this.locale).format(Date.parse(date)))}
}

const i18n= new Star()

export default Star


const formating=(locale, currency)=>{
  const currF= new Intl.NumberFormat(locale, { 
    style: 'currency', 
    currency: currency 
  });
  const percF= new Intl.NumberFormat(locale, {
    style: 'percent', 
    minimumFractionDigits:2,
    maximumFractionDigits:2
  });
  const deciF= new Intl.NumberFormat(locale, { style: 'percent'});
  const dateF= new Intl.DateTimeFormat(locale)
}

