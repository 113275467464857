import React from 'react';
import {Row, Col} from 'Support/grid';
import {Card, Button} from 'react-bootstrap';

import ApiConnect from 'CONFIG/axios';
import Loader from 'Support/loader';

class ProjectTemplates extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      templates_data: "",
      loaded:false
    }
  }

  componentDidMount() {
    this.updateSettingsData()
  }

  updateSettingsData=async()=>{
    const request={ 
    }
    try{
      const response = await ApiConnect.post('/api/project/template/list/get', request)
      this.setState({
        templates_data:response.data,
        loaded:true
      })
    }catch(error) {
      alert(error);
    }
  }
       
  render(){
    const props={
      templates_data:this.state.project_data,
    }
    function torender(data, i){
      return data[i];
      }  
    const pages ={
    true: 
    <ToDisplay {...props} />,
    false: <Loader loader_message="Gettting templates!"/>
    }
    var i = this.state.loaded
    const renderizing = torender(pages,i)

    return(
        <div>
            {renderizing}
        </div>
    )
  }
}

class ToDisplay extends React.Component{
  render(){
    const props=this.props

    const item_holder = this.props.item_holder
    let item_distr="There are no templates"
    if(item_holder !==undefined){
      item_distr = item_holder.map((value, key) =>
        <SingleTemplate {...props}/>
      );
    }

    return(
      <div>
        <Row noGutters className="project_main_placer">
          <Col xs={12}>
            {item_distr}
          </Col>
        </Row>
      </div>
    );
  }
}

class SingleTemplate extends React.Component{
  render(){
    return(
      <Col xs={2}>
        <Card>
          <Card.Title>
            {this.props.templates_data.project_name}
          </Card.Title>
          <Card.Body>
            <Button block onClick="">Select</Button>
          </Card.Body>
        </Card>
      </Col>
    )
  }
}

export default ProjectTemplates;