import React from 'react';
import {Grid, Row, Col} from 'Support/grid'
import { Route, Routes } from "react-router-dom";
import InterestRatesData from './interest_rates'

function DataResearchRoot(props){
  return(
    <Grid >
      <Row className="blanktop_10">
        <Col xs={12}>
          <Routes>
            <Route index element={<InterestRatesData {...props}/>}/>
            <Route path={`interest_rates`} element={<InterestRatesData {...props}/>}/>
          </Routes>
        </Col>
      </Row>
    </Grid>
  )
}

export default DataResearchRoot