import React from 'react';

import InvestmentCreator from '../../../../CONFIG/CREATORS/investment_creator';
import {SinglesFeasibilityListItems} from '../feasibility_items';

class InvestmentList extends React.Component{

  handleAdd =async(item)=>{
    let number_of_years=(this.props.feasibility_settings.number_of_years)
    if(this.props.feasibility_settings.use_historic===true){
      number_of_years+=this.props.feasibility_settings.number_of_historic_years
    }
    const element_to_add= new InvestmentCreator(
        item,
        number_of_years,
        this.props.feasibility_settings.vat
      )
    this.props.handleLists.handleAdd(element_to_add, 'investments')
  }
  render(){
    return(
      <SinglesFeasibilityListItems 
      data={this.props.version_data.investments}
      feasibility_settings={this.props.feasibility_settings}
      user_permission={this.props.user_permission}
      handleAdd={this.handleAdd} 
      handleScreenNavigation={this.props.handleScreenNavigation}
      handleDeleteItem={this.props.handleLists.handleDeleteItem}
      handleDuplicateItem={this.props.handleLists.handleDuplicateItem}
      handleChangLevel={this.props.handleLists.handleChangLevel}
      title='inv_title'
      add_item='add_inv'
      item_type='investments'
      />
    )
  }
}

export default InvestmentList;