class OtherBalanceCreator{
  constructor(name, array_size, typester,hist){
    this.other_balance_item_name=name
    this.other_balance_type=typester
    this.other_balance_increase=new Array(array_size).fill(0)
    this.other_balance_decrease=new Array(array_size).fill(0)
    this._other_balance_accumulated=new Array(array_size).fill(0)
    this._rev_hist=hist
    this.other_balance_obs=""
  }
}

export default OtherBalanceCreator;