import React from 'react';

import ApiConnect from 'CONFIG/axios';

import {SinglesFeasibilityListItems} from '../budget_items';
import {RevenueCreator} from '../../../../CONFIG/CREATORS/revenue_creator';
import {calTotalsForLists} from 'CALCULATIONS/cal_totals'

import Loader from 'Support/loader';


class RevenuesList extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      data:[],
      loaded:false,
      toast:{
        show:false,
        toast_header:"",
        toast_body:"",
      },
      totals:[]
    }
    this.handleAdd=this.handleAdd.bind(this)
    this.updateListData=this.updateListData.bind(this)
    this.handleDeleteItem=this.handleDeleteItem.bind(this)
    this.handleDuplicateItem=this.handleDuplicateItem.bind(this)
  }

  componentDidMount() {
    this.updateListData()
  }

  updateListData(){
    const pgetter=this.props.budget_id
    
    const item_type="revenues"
    ApiConnect.post('/api/budget_project/multitem/all/get', {item_type:item_type,budget_id:pgetter,version_id:this.props.current_version.id})
    .then(res => {
      this.setState({ 
        data:res.data,
        loaded:true,
        totals:calTotalsForLists(res.data)
      });
    })
    .catch(function (error) {
      alert(error);
    })
  }

  handleAdd =async(item)=>{
    let months=12
    const request={
      item_type:"revenues", 
      budget_id: this.props.budget_id, 
      version_id:this.props.current_version.id,
      user_id:this.props.user_id, 
      content: new RevenueCreator(
        item,
        months,
        this.props.budget_settings.vat,
        this.props.budget_settings.raw_materials
      )
    }  
    try{
      const response= await ApiConnect.post(`/api/budget_project/multitem/item/create`, request)
      const event={
        target:{
          name:'edit',
          value:response.data
        }
      }
      this.props.handleScreenNavigation(event)
    } catch(error) {
      alert(error);
    }
  }

  handleDuplicateItem =async(event)=>{
    const bgetter=this.props.budget_id
    const rgetter=event.target.value
    const item_type="revenues"
    try{
      const response= await ApiConnect.post(`/api/budget_project/multitem/item/duplicate`, {item_id:rgetter, item_type:item_type,  budget_id:bgetter,version_id:this.props.current_version.id })
      this.updateListData()
      this.AlertFunctions.setAlert(response.data)
    } catch(error) {
      alert(error);
    }
  }

  handleDeleteItem =async(event)=>{
    const bgetter=this.props.budget_id
    const rgetter=event.target.value
    const item_type="revenues"
    try{
    const response= await ApiConnect.post(`/api/budget_project/multitem/item/delete`, {item_id:rgetter, item_type:item_type, budget_id:bgetter,version_id:this.props.current_version.id})
      this.updateListData()
      this.AlertFunctions.setAlert(response.data)
    } catch(error) {
      alert(error);
    }
  }

  handleUpLevel= async(event)=>{
    const pgetter=this.props.budget_id
    const vgetter=this.props.current_version.id
    const rgetter=event.target.value
    const item_type="revenues"
    try{
    const response= await ApiConnect.post(`/api/budget_project/multitem/item/uplevel`, {item_id:rgetter, item_type:item_type, version_id: vgetter, budget_id:pgetter })
      this.updateListData()
      this.AlertFunctions.setAlert(response.data)
    } catch(error) {
      alert(error);
    }
  }

  handleDownLevel= async(event)=>{
    const pgetter=this.props.budget_id
    const vgetter=this.props.current_version.id
    const rgetter=event.target.value
    const item_type="revenues"
    try{
    const response= await ApiConnect.post(`/api/budget_project/multitem/item/downlevel`, {item_id:rgetter, item_type:item_type, version_id: vgetter, budget_id:pgetter })
      this.updateListData()
      this.AlertFunctions.setAlert(response.data)
    } catch(error) {
      alert(error);
    }
  }

  //TOAST ALERT
  AlertFunctions={
    setAlert:(header,body)=>{
      this.setState({
        toast:{
          show:true,
          header: header,
          body:body
        }
      })
    },
    unsetalert:()=>{
      this.setState({
        toast:{
          show:false
        }
      })
    }
  }

  render(){
      function torender(data, i){
          return data[i];
        }  
      const pages ={
      true: 
      <SinglesFeasibilityListItems 
      data={this.state.data} 
      totals={this.state.totals}
      budget_settings={this.props.budget_settings}
      user_permission={this.props.user_permission}
      handleAdd={this.handleAdd} 
      handleScreenNavigation={this.props.handleScreenNavigation} 
      handleItem={this.handleDeleteItem}
      handleDeleteItem={this.handleDeleteItem}
      handleDuplicateItem={this.handleDuplicateItem}
      handleEditItem={this.props.handleDuplicateItem}
      handleUpLevel={this.handleUpLevel}
      handleDownLevel={this.handleDownLevel}
      toast={this.state.toast}
      AlertFunctions={this.AlertFunctions}
      title='rev_title'
      add_item='add_rev'
      />,
      false: <Loader loader_message="rev_list"/>
      }
      var i = this.state.loaded
      const renderizing = torender(pages,i)
  
    return(
      <>
        {renderizing}
      </>
    )
  }
}

export default RevenuesList;