class ProjectCreator{
  constructor(name, description, settings){
    this.name=name
    this.description=description
    this.number_of_years=settings.number_of_years
    this.years=settings.years
    this.starting_year=settings.starting_year
    this.country=settings.country
    this.currency=settings.currency
    this.irc=settings.irc
    this.corporate_social_security=settings.corporate_social_security
    this.vat=settings.vat
    this.vat_payment=settings.vat_payment
    this.working_months=settings.working_months
    this.raw_materials=settings.raw_materials
    this.employees=settings.employees
    this.nature_or_functions=settings.nature_or_functions
    this.use_general_vat_rate=settings.use_general_vat_rate
    this.decimal_on_reports=settings.decimal_on_reports
    this.use_historic=settings.use_historic
  }
}

class VersionCreator{
  constructor(name, description, settings){
    this.version_name=name
    this.version_description=description
    this.version_settings={
      inflation:new Array(settings.number_of_years).fill(0),
      days_account_receivables:new Array(settings.number_of_years).fill(0),
      days_account_payables:new Array(settings.number_of_years).fill(0),
      investment_days_account_payables:new Array(settings.number_of_years).fill(0)
    }
  }
}

export {
  ProjectCreator,
  VersionCreator
};