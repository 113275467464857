import React from 'react';
import {AssumptionHeaderSaveReturn} from 'PRIVATE/PROJECT/Feasibility/feasibility_items';
import { Row, Col } from 'Support/grid';

import {FormEdit, FormEditInteger, NonEditableForm, PeriodSelect2, PeriodHeader, SingleInputFormFlat, Observations,Selectacble} from 'Support/inputforms'
import UserContext from 'CONFIG/auth';
import Loader from 'Support/loader';
import ApiConnect from 'CONFIG/axios';
import {calculateHumanResources} from 'CALCULATIONS/cal_human_resources';
import { ToogleButtons } from 'Support/toogle_buttons';

class HrEntry extends React.Component {
  static contextType = UserContext

  constructor(props) {
    super(props);
    this.state = {
      data: "",
      inflation:"",
      loaded:false,
      years:this.props.feasibility_settings.historic_years.concat(this.props.feasibility_settings.years)
    }
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleObservationsChange = this.handleObservationsChange.bind(this);
    this.handlePeriodChange = this.handlePeriodChange.bind(this);

    this.handleTypeChange=this.handleTypeChange.bind(this);
  }


  //GENERAL FUNCTIONS
  componentDidMount= async()=>{
    const request={
    project_id:this.props.project_id,
    version_id:this.props.version_id,
    user_id:this.props.user_id,
    item_id:this.props.active_item,
    item_type:"human_resources"
    }
    try {
      const response = await ApiConnect.post('/api/multitem/unique/get', request)
      this.setState({
        data:response.data.item,
        inflation:response.data.version_inflation,
        loaded:true
      })
    } catch(error) {
        alert(error);
    }
  }

  handleNameChange(event){
    const value = event.target.value;
    let go = this.state.data;
    go.hr_name=value;
    this.setState({data:go })
  }

  handleObservationsChange(event){
    const value = event.target.value;
    let go = this.state.data;
    go.hr_observations=value;
    this.setState({data:go })
  }

  async handleSave(){
    const request={
      user_id:this.props.user_id,
      project_id:this.props.project_id,
      version_id:this.props.version_id,
      item_id:this.props.active_item,
      item_type:"human_resources",
      content:this.state.data
    }
    try{
      const response = await ApiConnect.post('/api/multitem/item/modify', request)
      this.props.changeScreenView("list",0)
      this.props.handleLists.handleSaveUpdater(response.data,'human_resources')
    } catch(error) {
        alert(error);
    }
  }


  //HR FUNCTIONS
  handleEditChange=(id,item)=>{
    let go = this.state.data;
    go[id]=item;
    calculateHumanResources(go,this.props.feasibility_settings, this.state.inflation)
    this.setState({data:go})
  }

  handlePeriodChange=async(event)=>{
    const value = event.target.value;
    let go = this.state.data;
    go.hr_period=value;
    calculateHumanResources(go,this.props.feasibility_settings, this.state.inflation)
    this.setState({data:go})
  }

  handleTypeChange=async(event)=>{
    let value = event.target.value;
    if(value=="false"){value=false} else value=true
    let go = this.state.data;
    go.hr_type=value;
    calculateHumanResources(go,this.props.feasibility_settings, this.state.inflation)
    this.setState({data:go})
  }
  
  render() {
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_PROJECT.PROJECT_FEASIBILITY.Individual_edits')

    const props ={
      trsl:trsl,
      handleScreenNavigation:this.props.handleScreenNavigation,
      feasibility_settings:this.props.feasibility_settings,
      user_permission:this.props.user_permission,
      data:this.state.data,
      years:this.state.years,
      handleNameChange:this.handleNameChange,
      handleObservationsChange:this.handleObservationsChange,
      handleSave:this.handleSave,
      handleEditChange:this.handleEditChange,
      handlePeriodChange:this.handlePeriodChange,
      handleTypeChange:this.handleTypeChange
    }

    function torender(data, i){
      return data[i];
    }  
    const pages ={
      true: <ToDisplay {...props}/>,
      
      false: <Loader loader_message="hr_edit"/>
    }
    var i = this.state.loaded
    const renderizing = torender(pages,i)

    return(
      <div>
        {renderizing}
      </div>
    )
  }
}

class ToDisplay extends React.Component {
  render(){
    const props=this.props

    const category_render=(checker)=>{
      if(checker===false){
        return <NumberOfWorkersEdit className="feasibility_item_list" {...props} />
      }
    }
    const num_workers_renderizing= category_render(this.props.data.hr_type)

    const monthly_render=(checker)=>{
      if(checker>1){
        return <WorkingMonthsEdit  {...props}/> 
      }
    }
    const working_months_renderizing= monthly_render(this.props.data.hr_period)

    return(
      <>
        <GlobalHeader {...props}/>
        <Row>
          <Col xs={12} >
            <PeriodHeader label={this.props.trsl['year']} periods={this.props.years} />
          </Col>
          <Col xs={12} className="feasibility_list">
            {num_workers_renderizing}
            {working_months_renderizing}
            <SalaryEdit {...props}/>
            <GrossSalaryInflated {...props}/>
            <PrizesEdit {...props}/>
            <SubsidyEdit {...props}/>
            <InsurancesEdit {...props}/>
            <OtherCostsEdit {...props}/>
            <SocialSecurity {...props}/>
            <TotalCost {...props}/>
          </Col>
          <Col xs={12} className="feasibility_list">
            <Observations className="feasibility_item_list" name="hr_observations" value={this.props.data.hr_observations} label="Observations" handleObservationsChange={this.props.handleObservationsChange}/>
          </Col>
        </Row>
      </>
    );
  }
}

class GlobalHeader extends React.Component{
  render(){
    const options=[
      {value:true,option_label:this.props.trsl['hr_individual']},
      {value:false,option_label:this.props.trsl['hr_category']}
    ]
    return(
      <div>
        <AssumptionHeaderSaveReturn title={this.props.trsl['hr_header']} user_permission={this.props.user_permission} back={this.props.trsl['back_button']} save={this.props.trsl['save_back_button']} handleSave={this.props.handleSave} handleScreenNavigation={this.props.handleScreenNavigation}/>
        <Row className="feasibility_edit_globalheader_row">
            <SingleInputFormFlat
            xs={12} md={12}
            value={this.props.data.hr_name} 
            label= {this.props.trsl['hr_name']} 
            handleEditItemChange={this.props.handleNameChange}/>
          <PeriodSelect2 xs={12} md={6} label={this.props.trsl['salaries_set']} name="hr_period" handlePeriodChange={this.props.handlePeriodChange} selected={this.props.data.hr_period}/>
          <ToogleButtons
            xs={12} md={6} 
            label={this.props.trsl['salaries_set2']}
            toogle_data={options}
            selected={this.props.data.hr_type} 
            handleToogleButton={this.props.handleTypeChange} />
          </Row>
      </div>
    );
  }
}

class SalaryEdit extends React.Component{
  render(){
    return(
      <FormEdit 
      use_historic={this.props.feasibility_settings.use_historic}
      item_holder={this.props.data.hr_gross_salary}
      id="hr_gross_salary"
      handleForm={this.props.handleEditChange}
      label={this.props.trsl['hr_gross']}
      unit={new Intl.NumberFormat('ja-JP', { style: 'currency', currency: this.props.feasibility_settings.currency}).format(0).replace(/\d./g,"")}
      />
    );
  }
}
class WorkingMonthsEdit extends React.Component{
  render(){ 
    return(
      <FormEditInteger 
      use_historic={this.props.feasibility_settings.use_historic}
      item_holder={this.props.data.hr_worked_months}
      id="hr_worked_months"
      handleForm={this.props.handleEditChange}
      label={this.props.trsl['hr_months']}
      unit="M"
      max={12}
      min={0}
      step={1}
      />
    );
  }
}

class NumberOfWorkersEdit extends React.Component{
  render(){ 
    return(
      <FormEdit 
      use_historic={this.props.feasibility_settings.use_historic}
      item_holder={this.props.data.hr_number_of_workers}
      id="hr_number_of_workers"
      handleForm={this.props.handleEditChange}
      label={this.props.trsl['hr_number_workers']}
      unit="U"
      />
    );
  }
}

class PrizesEdit extends React.Component{
  render(){ 
    return(
      <FormEdit 
      use_historic={this.props.feasibility_settings.use_historic}
      item_holder={this.props.data.hr_prizes_bonus}
      id="hr_prizes_bonus"
      handleForm={this.props.handleEditChange}
      label={this.props.trsl['hr_prizes']}
      unit={new Intl.NumberFormat('ja-JP', { style: 'currency', currency: this.props.feasibility_settings.currency}).format(0).replace(/\d./g,"")}
      />
    );
  }
}

class SubsidyEdit extends React.Component{
  render(){ 
    return(
      <FormEdit 
      use_historic={this.props.feasibility_settings.use_historic}
      item_holder={this.props.data.hr_subsidy}
      id="hr_subsidy"
      handleForm={this.props.handleEditChange}
      label={this.props.trsl['hr_subsidy']}
      unit={new Intl.NumberFormat('ja-JP', { style: 'currency', currency: this.props.feasibility_settings.currency}).format(0).replace(/\d./g,"")}
      />
    );
  }
}

class InsurancesEdit extends React.Component{
  render(){ 
    return(
      <FormEdit 
      use_historic={this.props.feasibility_settings.use_historic}
      item_holder={this.props.data.hr_insurances}
      id="hr_insurances"
      handleForm={this.props.handleEditChange}
      label={this.props.trsl['hr_insurances']}
      unit={new Intl.NumberFormat('ja-JP', { style: 'currency', currency: this.props.feasibility_settings.currency}).format(0).replace(/\d./g,"")}
      />
    );
  }
}

class OtherCostsEdit extends React.Component{
  render(){ 
    return(
      <FormEdit 
      use_historic={this.props.feasibility_settings.use_historic}
      item_holder={this.props.data.hr_othercosts}
      id="hr_othercosts"
      handleForm={this.props.handleEditChange}
      label={this.props.trsl['hr_othercosts']}
      unit={new Intl.NumberFormat('ja-JP', { style: 'currency', currency: this.props.feasibility_settings.currency}).format(0).replace(/\d./g,"")}
      />
    );
  }
}

class GrossSalaryInflated extends React.Component{
  render(){ 
    return(
      <NonEditableForm
      item_holder={this.props.data._hr_gross_salary_inflated}
      label={this.props.trsl['hr_gross_salary_inflated']}
      value_type="currency"
      currency={this.props.feasibility_settings.currency}
      />
    );
  }
}

class SocialSecurity extends React.Component{
  render(){ 
    return(
      <NonEditableForm
      item_holder={this.props.data._hr_social_security}
      label={this.props.trsl['_hr_social_security']}
      value_type="currency"
      currency={this.props.feasibility_settings.currency}
      />
    );
  }
}

class TotalCost extends React.Component{
  render(){ 
    return(
      <NonEditableForm
      item_holder={this.props.data._hr_total_cost}
      label={this.props.trsl['hr_total']}
      value_type="currency"
      currency={this.props.feasibility_settings.currency}
      />
    );
  }
}


export default HrEntry;