const {add} = require ('mathjs');

function addArrays(inputer){
  var summed
  if(inputer!==undefined && Array.isArray(inputer) && inputer.length>0){
    summed=new Array(inputer[0].length)
    summed.fill(0)
    inputer.forEach(function(item){
      summed=add(summed,item)
    })
  }
  return summed
}

function calTotalsForLists(revenues){
  const bug= addArrays(revenues.map((e)=> e.item_values))
  return bug
}

function calTotalsForListsWithFilters(items,f2){
  items=items.filter(e=>e.item_typester===f2)
  const bug= addArrays(items.map((e)=> e.item_values))
  return bug
}


export{
  calTotalsForLists,
  calTotalsForListsWithFilters
}