class HrCreator{
  constructor(name, n_years,employees,corporate_social_security,hist){
    this.hr_name=name
    this.hr_type=employees
    this.hr_gross_salary=new Array(n_years).fill(0)
    this._hr_gross_salary_inflated=new Array(n_years).fill(0)
    this.hr_period=12
    this.hr_worked_months=new Array(n_years).fill(12)
    this.hr_number_of_workers=new Array(n_years).fill(0)
    this.hr_prizes_bonus=new Array(n_years).fill(0)
    this.hr_subsidy=new Array(n_years).fill(0)
    this.hr_othercosts=new Array(n_years).fill(0)
    this.hr_insurances=new Array(n_years).fill(0)
    this.hr_social_security_rate=corporate_social_security
    this._hr_social_security=new Array(n_years).fill(0)
    this._hr_total_cost=new Array(n_years).fill(0)
    this._rev_hist=hist
    this.hr_observations=""
  }
}

export default HrCreator;