import React from 'react';
import { Row, Col  } from 'Support/grid';
import { add } from 'mathjs';


import UserContext from 'CONFIG/auth';
import ApiConnect from 'CONFIG/axios';
import Loader from 'Support/loader';

import { SingleInputLargeForm } from 'Support/inputforms';
import { ToogleButtons } from 'Support/toogle_buttons';

import { RegularButtonSmall } from 'Support/listbuttons';
import { MiniCard } from 'Support/warning_cards';
import { SimpleHorizontalTab } from 'Support/tabs';
import { RadarGraph } from 'Support/graphs';

class CompetitiveMain extends React.Component{
  static contextType = UserContext

  constructor(props) {
    super(props);
      this.state = {
      data: "",
      canva_id:""
    }
  }

  componentDidMount(){
    this.updataData()
  }

  updataData=async()=>{
    const request={
      project_id: this.props.project_id,
      item_type: "competitive" 
    }
    try{
      const response = await ApiConnect.post('/api/project/canva/get', request)
      this.setState({
        data:response.data.item,
        canva_id:response.data.canva_id,
        loaded:true
      })
    }catch(error) {
      alert(error);
    }
  }

  computeScores(elements){
    let count=0
    let sum=0
    let numbers= Object.values(elements)
    for(var i=0; i<(numbers.length-1);i++){
      if (numbers[i]>0){
        sum = add(sum, numbers[i]);
        count = add(count, 1);
      }
    }
    return sum/count
  }
  computeGlobalScore(data){
    return (add(data.client_score,data.suppliers_score,data.competition_score,data.entrants_score,data.substitutes_score) / 5)
  }

  handleClients=(event)=>{
    let go= this.state.data
    go.clients[event.target.name]=event.target.value
    if(event.target.name!=="observations"){
      go.client_score=this.computeScores(go.clients)
      go.total_score=this.computeGlobalScore(go)  
    }
    this.setState({
      data:go
    })
  }
  handleSuppliers=(event)=>{
    let go= this.state.data
    go.suppliers[event.target.name]=event.target.value
    if(event.target.name!=="observations"){
      go.suppliers_score=this.computeScores(go.suppliers)
      go.total_score=this.computeGlobalScore(go)
    }
    this.setState({
      data:go
    })
  }
  handleCompetition=(event)=>{
    let go= this.state.data
    go.competition[event.target.name]=event.target.value
    if(event.target.name!=="observations"){
      go.competition_score=this.computeScores(go.competition)
      go.total_score=this.computeGlobalScore(go)
    }
    this.setState({
      data:go
    })
  }
  handleEntrants=(event)=>{
    let go= this.state.data
    go.entrants[event.target.name]=event.target.value
    if(event.target.name!=="observations"){
      go.entrants_score=this.computeScores(go.entrants)
      go.total_score=this.computeGlobalScore(go)
    }
    this.setState({
      data:go
    })
  }
  handleSubstitutes=(event)=>{
    let go= this.state.data
    go.substitutes[event.target.name]=event.target.value
    if(event.target.name!=="observations"){
      go.substitutes_score=this.computeScores(go.substitutes)
      go.total_score=this.computeGlobalScore(go)
    }
    this.setState({
      data:go
    })
  }

  handleSave=async()=>{
    const request={
      canva_id: this.state.canva_id,
      item_type: "competitive",
      content:this.state.data 
    }
    try{
      const response = await ApiConnect.post('/api/project/canva/modify', request)
      this.props.activateAlert('ok_auto','Saved','', this.context)
    }catch(error) {
      alert(error);
    }
  }

  render(){
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_PROJECT.PROJECT_CANVA.Competitive')

    const props ={
      user_c:user_c,
      trsl:trsl,
      data:this.state.data,
      p_name:this.props.project_data.project_name,
      user_permission:this.props.user_permission,
      template:user_c.template,
      handleClients:this.handleClients,
      handleSuppliers:this.handleSuppliers,
      handleCompetition:this.handleCompetition,
      handleEntrants:this.handleEntrants,
      handleSubstitutes:this.handleSubstitutes,
      handleSave:this.handleSave
    }
    function torender(data, i){
      return data[i];
      }  
    const pages ={
    true: 
    <ToDisplay {...props} />,
    false: <Loader loader_message="Let's get hands on the job!"/>
    }
    var i = this.state.loaded
    const renderizing = torender(pages,i)

    return(
      <div>
        {renderizing}
      </div>
    )
  }
}


class ToDisplay extends React.Component{
  render(){
    const props=this.props
    return(
      <div>
        <Header {...props} />
        <Fields label={this.props.trsl['strength']} element="strengths" {...props}/>
      </div>
    );
  }
}

class Header extends React.Component{
  render(){
    let permission=true
    if(this.props.user_permission=="owner" || this.props.user_permission=="edit" ){
      permission= false
    }
    return(
      <div>
        <Row className="canva_header_row grid_middle">
          <Col xs={9}>
            <h4>{this.props.trsl['competitive']}</h4>
          </Col>
          <Col xs={3}>
            <RegularButtonSmall bk="bk_success" label={this.props.trsl['save_button']} onClick={this.props.handleSave} disabled={permission} />
          </Col>
        </Row>
      </div>
    )
  }
}

class Fields extends React.Component{
  constructor(props) {
    super(props);
      this.state = {
      tab_render: 'clients'
    }
  }

  handleTabChange=(e)=>{
    this.setState({
      tab_render: e.target.name
    })
  }

  render(){
    const props = this.props

    const tabs=[
      {label:this.props.trsl['clients'], value:'clients'},
      {label:this.props.trsl['suppliers'], value:'suppliers'},
      {label:this.props.trsl['competition'], value:'competition'},
      {label:this.props.trsl['entrants'], value:'entrants'},
      {label:this.props.trsl['substitutes'], value:'substitutes'}
    ]

    const screens={
      clients:<Clients clients={this.props.data.clients } trsl={this.props.trsl} handleClients={this.props.handleClients} />,
      suppliers:<Suppliers suppliers={this.props.data.suppliers } trsl={this.props.trsl} handleSuppliers={this.props.handleSuppliers} />,
      competition:<Competition competition={this.props.data.competition } trsl={this.props.trsl} handleCompetition={this.props.handleCompetition} />,
      entrants:<Entrants entrants={this.props.data.entrants } trsl={this.props.trsl} handleEntrants={this.props.handleEntrants} />,
      substitutes:<Substitutes substitutes={this.props.data.substitutes } trsl={this.props.trsl} handleSubstitutes={this.props.handleSubstitutes} />
    }


    return(
      <Row >
        <Col xs={6}>
          <Row>
            <ScoreCard header={this.props.trsl['clients']} score={this.props.data.client_score} color='info' {...props} />
            <ScoreCard header={this.props.trsl['suppliers']} score={this.props.data.suppliers_score} color='info'{...props} />
            <ScoreCard header={this.props.trsl['competition']} score={this.props.data.competition_score} color='info'{...props} />
            <ScoreCard header={this.props.trsl['entrants']} score={this.props.data.entrants_score} color='info'{...props} />
            <ScoreCard header={this.props.trsl['substitutes']} score={this.props.data.substitutes_score} color='info'{...props} />
            <ScoreCard header={this.props.trsl['global_score']} score={this.props.data.total_score} color='info'{...props} />
        </Row>
        </Col>
        <Col xs={6} className='blanktop_075'>
          <Graph trsl={this.props.trsl} client_score={this.props.data.client_score} suppliers_score={this.props.data.suppliers_score} competition_score={this.props.data.competition_score} entrants_score={this.props.data.entrants_score} substitutes_score={this.props.data.substitutes_score}/>
        </Col>
        <Col xs={12} className='blanktop_15'>
          <SimpleHorizontalTab options={tabs} selected={this.state.tab_render} handleTabs={this.handleTabChange}/>
        </Col>
        {screens[this.state.tab_render]}
      </Row>
    );
  }
}

function Clients(props){
  const cli_1= [
    {value:1, option_label:1, name:'cli_1'},
    {value:2, option_label:2, name:'cli_1'},
    {value:3, option_label:3, name:'cli_1'},
    {value:4, option_label:4, name:'cli_1'},
    {value:5, option_label:5, name:'cli_1'},
    {value:0, option_label:props.trsl['not_applicable'], name:'cli_1'}
  ]
  const cli_2= [
    {value:1, option_label:1, name:'cli_2'},
    {value:2, option_label:2, name:'cli_2'},
    {value:3, option_label:3, name:'cli_2'},
    {value:4, option_label:4, name:'cli_2'},
    {value:5, option_label:5, name:'cli_2'},
    {value:0, option_label:props.trsl['not_applicable'], name:'cli_2'}
  ]
  const cli_3= [
    {value:1, option_label:1, name:'cli_3'},
    {value:2, option_label:2, name:'cli_3'},
    {value:3, option_label:3, name:'cli_3'},
    {value:4, option_label:4, name:'cli_3'},
    {value:5, option_label:5, name:'cli_3'},
    {value:0, option_label:props.trsl['not_applicable'], name:'cli_3'}
  ]
  const cli_4= [
    {value:1, option_label:1, name:'cli_4'},
    {value:2, option_label:2, name:'cli_4'},
    {value:3, option_label:3, name:'cli_4'},
    {value:4, option_label:4, name:'cli_4'},
    {value:5, option_label:5, name:'cli_4'},
    {value:0, option_label:props.trsl['not_applicable'], name:'cli_4'}
  ]
  const cli_5= [
    {value:1, option_label:1, name:'cli_5'},
    {value:2, option_label:2, name:'cli_5'},
    {value:3, option_label:3, name:'cli_5'},
    {value:4, option_label:4, name:'cli_5'},
    {value:5, option_label:5, name:'cli_5'},
    {value:0, option_label:props.trsl['not_applicable'], name:'cli_5'}
  ]
  return(
    <>
      <Col xs={12} className='blanktop_10'>
        <h4>{props.trsl['clients']}</h4>
      </Col>
      <OptionBuilder  toogle={cli_1} selected={props.clients.cli_1} handleEditItem={props.handleClients} label={props.trsl['client_concentration']}/>
      <OptionBuilder  toogle={cli_2} selected={props.clients.cli_2} handleEditItem={props.handleClients} label={props.trsl['channel_dependency']}/>
      <OptionBuilder  toogle={cli_3} selected={props.clients.cli_3} handleEditItem={props.handleClients} label={props.trsl['client_cost_of_change']}/>
      <OptionBuilder  toogle={cli_4} selected={props.clients.cli_4} handleEditItem={props.handleClients} label={props.trsl['client_access_to_inofrmation']}/>
      <OptionBuilder  toogle={cli_5} selected={props.clients.cli_5} handleEditItem={props.handleClients} label={props.trsl['client_price_sensitivity']}/>
      <SingleInputLargeForm xs={12} label={props.trsl['clients'] + ": " + props.trsl['observations']}  name={'observations'} value={props.clients.observations} handleEditItemChange={props.handleClients} maxlength={2000}/>
    </>
  )
}

function Suppliers(props){
  const sup_1= [
    {value:1, option_label:1, name:'sup_1'},
    {value:2, option_label:2, name:'sup_1'},
    {value:3, option_label:3, name:'sup_1'},
    {value:4, option_label:4, name:'sup_1'},
    {value:5, option_label:5, name:'sup_1'},
    {value:0, option_label:props.trsl['not_applicable'], name:'sup_1'}
  ]
  const sup_2= [
    {value:1, option_label:1, name:'sup_2'},
    {value:2, option_label:2, name:'sup_2'},
    {value:3, option_label:3, name:'sup_2'},
    {value:4, option_label:4, name:'sup_2'},
    {value:5, option_label:5, name:'sup_2'},
    {value:0, option_label:props.trsl['not_applicable'], name:'sup_2'}
  ]
  const sup_3= [
    {value:1, option_label:1, name:'sup_3'},
    {value:2, option_label:2, name:'sup_3'},
    {value:3, option_label:3, name:'sup_3'},
    {value:4, option_label:4, name:'sup_3'},
    {value:5, option_label:5, name:'sup_3'},
    {value:0, option_label:props.trsl['not_applicable'], name:'sup_3'}
  ]
  const sup_4= [
    {value:1, option_label:1, name:'sup_4'},
    {value:2, option_label:2, name:'sup_4'},
    {value:3, option_label:3, name:'sup_4'},
    {value:4, option_label:4, name:'sup_4'},
    {value:5, option_label:5, name:'sup_4'},
    {value:0, option_label:props.trsl['not_applicable'], name:'sup_4'}
  ]
  const sup_5= [
    {value:1, option_label:1, name:'sup_5'},
    {value:2, option_label:2, name:'sup_5'},
    {value:3, option_label:3, name:'sup_5'},
    {value:4, option_label:4, name:'sup_5'},
    {value:5, option_label:5, name:'sup_5'},
    {value:0, option_label:props.trsl['not_applicable'], name:'sup_5'}
  ]
  return(
    <>
      <Col xs={12} className='blanktop_10'>
        <h4>{props.trsl['suppliers']}</h4>
      </Col>
      <OptionBuilder  toogle={sup_1} selected={props.suppliers.sup_1} handleEditItem={props.handleSuppliers} label={props.trsl['suppliers_concentration']}/>
      <OptionBuilder  toogle={sup_2} selected={props.suppliers.sup_2} handleEditItem={props.handleSuppliers}label={props.trsl['cost_of_change']}/>
      <OptionBuilder  toogle={sup_3} selected={props.suppliers.sup_3} handleEditItem={props.handleSuppliers}label={props.trsl['diferentiation_between_suppliers']} />
      <OptionBuilder  toogle={sup_4} selected={props.suppliers.sup_4} handleEditItem={props.handleSuppliers}label={props.trsl['suppliers_distribution_channels_strength']} />
      <OptionBuilder  toogle={sup_5} selected={props.suppliers.sup_5} handleEditItem={props.handleSuppliers}label={props.trsl['suppliers_vertical_integration']}/>
      <SingleInputLargeForm xs={12} label={props.trsl['suppliers'] + ": " + props.trsl['observations']}  name={'observations'} value={props.suppliers.observations} handleEditItem={props.handleSuppliers} maxlength={2000}/>
    </>
  )
}

function Competition(props){
  const com_1= [
    {value:1, option_label:1, name:'com_1'},
    {value:2, option_label:2, name:'com_1'},
    {value:3, option_label:3, name:'com_1'},
    {value:4, option_label:4, name:'com_1'},
    {value:5, option_label:5, name:'com_1'},
    {value:0, option_label:props.trsl['not_applicable'], name:'com_1'}
  ]
  const com_2= [
    {value:1, option_label:1, name:'com_2'},
    {value:2, option_label:2, name:'com_2'},
    {value:3, option_label:3, name:'com_2'},
    {value:4, option_label:4, name:'com_2'},
    {value:5, option_label:5, name:'com_2'},
    {value:0, option_label:props.trsl['not_applicable'], name:'com_2'}
  ]
  const com_3= [
    {value:1, option_label:1, name:'com_3'},
    {value:2, option_label:2, name:'com_3'},
    {value:3, option_label:3, name:'com_3'},
    {value:4, option_label:4, name:'com_3'},
    {value:5, option_label:5, name:'com_3'},
    {value:0, option_label:props.trsl['not_applicable'], name:'com_3'}
  ]
  const com_4= [
    {value:1, option_label:1, name:'com_4'},
    {value:2, option_label:2, name:'com_4'},
    {value:3, option_label:3, name:'com_4'},
    {value:4, option_label:4, name:'com_4'},
    {value:5, option_label:5, name:'com_4'},
    {value:0, option_label:props.trsl['not_applicable'], name:'com_4'}
  ]
  const com_5= [
    {value:1, option_label:1, name:'com_5'},
    {value:2, option_label:2, name:'com_5'},
    {value:3, option_label:3, name:'com_5'},
    {value:4, option_label:4, name:'com_5'},
    {value:5, option_label:5, name:'com_5'},
    {value:0, option_label:props.trsl['not_applicable'], name:'com_5'}
  ]
  const com_6= [
    {value:1, option_label:1, name:'com_6'},
    {value:2, option_label:2, name:'com_6'},
    {value:3, option_label:3, name:'com_6'},
    {value:4, option_label:4, name:'com_6'},
    {value:5, option_label:5, name:'com_6'},
    {value:0, option_label:props.trsl['not_applicable'], name:'com_6'}
  ]
  return(
    <>
      <Col xs={12} className='blanktop_10'>
        <h4>{props.trsl['competition']}</h4>
      </Col>
      <OptionBuilder  toogle={com_1} selected={props.competition.com_1} handleEditItem={props.handleCompetition} label={props.trsl['diferentiation_of_products']}/>
      <OptionBuilder  toogle={com_2} selected={props.competition.com_2} handleEditItem={props.handleCompetition} label={props.trsl['brand_strength']}/>
      <OptionBuilder  toogle={com_3} selected={props.competition.com_3} handleEditItem={props.handleCompetition} label={props.trsl['managing_team_experience']}/>
      <OptionBuilder  toogle={com_4} selected={props.competition.com_4} handleEditItem={props.handleCompetition} label={props.trsl['quality_standards']}/>
      <OptionBuilder  toogle={com_5} selected={props.competition.com_5} handleEditItem={props.handleCompetition} label={props.trsl['market_concentration']}/>
      <OptionBuilder  toogle={com_6} selected={props.competition.com_6} handleEditItem={props.handleCompetition} label={props.trsl['financial_power']}/>    
      <SingleInputLargeForm xs={12}label={props.trsl['competition'] + ": " + props.trsl['observations']}  name={'observations'} value={props.competition.observations} handleEditItemChange={props.handleCompetition} maxlength={2000}/>
    </>
  )
}

function Entrants(props){
  const ent_1= [
    {value:1, option_label:1, name:'ent_1'},
    {value:2, option_label:2, name:'ent_1'},
    {value:3, option_label:3, name:'ent_1'},
    {value:4, option_label:4, name:'ent_1'},
    {value:5, option_label:5, name:'ent_1'},
    {value:0, option_label:props.trsl['not_applicable'], name:'ent_1'}
  ]
  const ent_2= [
    {value:1, option_label:1, name:'ent_2'},
    {value:2, option_label:2, name:'ent_2'},
    {value:3, option_label:3, name:'ent_2'},
    {value:4, option_label:4, name:'ent_2'},
    {value:5, option_label:5, name:'ent_2'},
    {value:0, option_label:props.trsl['not_applicable'], name:'ent_2'}
  ]
  const ent_3= [
    {value:1, option_label:1, name:'ent_3'},
    {value:2, option_label:2, name:'ent_3'},
    {value:3, option_label:3, name:'ent_3'},
    {value:4, option_label:4, name:'ent_3'},
    {value:5, option_label:5, name:'ent_3'},
    {value:0, option_label:props.trsl['not_applicable'], name:'ent_3'}
  ]
  const ent_4= [
    {value:1, option_label:1, name:'ent_4'},
    {value:2, option_label:2, name:'ent_4'},
    {value:3, option_label:3, name:'ent_4'},
    {value:4, option_label:4, name:'ent_4'},
    {value:5, option_label:5, name:'ent_4'},
    {value:0, option_label:props.trsl['not_applicable'], name:'ent_4'}
  ]
  const ent_5= [
    {value:1, option_label:1, name:'ent_5'},
    {value:2, option_label:2, name:'ent_5'},
    {value:3, option_label:3, name:'ent_5'},
    {value:4, option_label:4, name:'ent_5'},
    {value:5, option_label:5, name:'ent_5'},
    {value:0, option_label:props.trsl['not_applicable'], name:'ent_5'}
  ]
  const ent_6= [
    {value:1, option_label:1, name:'ent_6'},
    {value:2, option_label:2, name:'ent_6'},
    {value:3, option_label:3, name:'ent_6'},
    {value:4, option_label:4, name:'ent_6'},
    {value:5, option_label:5, name:'ent_6'},
    {value:0, option_label:props.trsl['not_applicable'], name:'ent_6'}
  ]
  return(
    <>
      <Col xs={12} className='blanktop_10'>
        <h4>{props.trsl['entrants']}</h4>
      </Col>
      <OptionBuilder toogle={ent_1} selected={props.entrants.ent_1} handleEditItem={props.handleEntrants} label={props.trsl['natural_monopolies_oligopolies']}/>
      <OptionBuilder toogle={ent_2} selected={props.entrants.ent_2} handleEditItem={props.handleEntrants} label={props.trsl['retaliation']}/>
      <OptionBuilder toogle={ent_3} selected={props.entrants.ent_3} handleEditItem={props.handleEntrants} label={props.trsl['legal_requirements']}/>
      <OptionBuilder toogle={ent_4} selected={props.entrants.ent_4} handleEditItem={props.handleEntrants} label={props.trsl['initial_investment']}/>
      <OptionBuilder toogle={ent_5} selected={props.entrants.ent_5} handleEditItem={props.handleEntrants} label={props.trsl['specific_knowledge']}/>
      <OptionBuilder toogle={ent_6} selected={props.entrants.ent_6} handleEditItem={props.handleEntrants} label={props.trsl['industry_profitability']} />
      <SingleInputLargeForm xs={12}label={props.trsl['entrants'] + ": " + props.trsl['observations']}  name={'observations'} value={props.entrants.observations} handleEditItemChange={props.handleEntrants} maxlength={2000}/>
    </>
  )
}

function Substitutes(props){
  const sub_1= [
    {value:1, option_label:1, name:'sub_1'},
    {value:2, option_label:2, name:'sub_1'},
    {value:3, option_label:3, name:'sub_1'},
    {value:4, option_label:4, name:'sub_1'},
    {value:5, option_label:5, name:'sub_1'},
    {value:0, option_label:props.trsl['not_applicable'], name:'sub_1'}
  ]
  const sub_2= [
    {value:1, option_label:1, name:'sub_2'},
    {value:2, option_label:2, name:'sub_2'},
    {value:3, option_label:3, name:'sub_2'},
    {value:4, option_label:4, name:'sub_2'},
    {value:5, option_label:5, name:'sub_2'},
    {value:0, option_label:props.trsl['not_applicable'], name:'sub_2'}
  ]
  const sub_3= [
    {value:1, option_label:1, name:'sub_3'},
    {value:2, option_label:2, name:'sub_3'},
    {value:3, option_label:3, name:'sub_3'},
    {value:4, option_label:4, name:'sub_3'},
    {value:5, option_label:5, name:'sub_3'},
    {value:0, option_label:props.trsl['not_applicable'], name:'sub_3'}
  ]
  const sub_4= [
    {value:1, option_label:1, name:'sub_4'},
    {value:2, option_label:2, name:'sub_4'},
    {value:3, option_label:3, name:'sub_4'},
    {value:4, option_label:4, name:'sub_4'},
    {value:5, option_label:5, name:'sub_4'},
    {value:0, option_label:props.trsl['not_applicable'], name:'sub_4'}
  ]
  const sub_5= [
    {value:1, option_label:1, name:'sub_5'},
    {value:2, option_label:2, name:'sub_5'},
    {value:3, option_label:3, name:'sub_5'},
    {value:4, option_label:4, name:'sub_5'},
    {value:5, option_label:5, name:'sub_5'},
    {value:0, option_label:props.trsl['not_applicable'], name:'sub_5'}
  ]
  return(
    <>
      <Col xs={12} className='blanktop_10'>
        <h4>{props.trsl['substitutes']}</h4>
      </Col>
      <OptionBuilder toogle={sub_1} selected={props.substitutes.sub_1} handleEditItem={props.handleSubstitutes} label={props.trsl['number_of_substitute_produts']} />
      <OptionBuilder toogle={sub_2} selected={props.substitutes.sub_2} handleEditItem={props.handleSubstitutes} label={props.trsl['price_of_substitute_products']} />
      <OptionBuilder toogle={sub_3} selected={props.substitutes.sub_3} handleEditItem={props.handleSubstitutes} label={props.trsl['availability']}/>
      <OptionBuilder toogle={sub_4} selected={props.substitutes.sub_4} handleEditItem={props.handleSubstitutes} label={props.trsl['client_cost_of_change_for_substitutes']}/>
      <OptionBuilder toogle={sub_5} selected={props.substitutes.sub_5} handleEditItem={props.handleSubstitutes} label={props.trsl['diferentiaion_level']}/>
      <SingleInputLargeForm xs={12} label={props.trsl['substitutes'] + ": " + props.trsl['observations']}  name={'observations'} value={props.substitutes.observations} handleEditItemChange={props.handleSubstitutes} maxlength={2000}/>
    </>
  )
}



function OptionBuilder(props){
  return(
    <>
      <ToogleButtons xs={12} label={props.label} toogle_data={props.toogle} selected={props.selected} handleToogleButton={props.handleEditItem} />
    </>
  )
}

function ScoreCard(props){
  let score = parseFloat(props.score).toFixed(2)

  return(
    <MiniCard xs={6} md={6} color={props.color} header={props.header} body={<h3>{score}</h3>}/>
  )
}

function Graph(props){
  const points=[
    {name:'Business', key:props.trsl['clients'], value: props.client_score},
    {name:'Business', key:props.trsl['suppliers'], value: props.suppliers_score},
    {name:'Business', key:props.trsl['competition'], value: props.competition_score},
    {name:'Business', key:props.trsl['entrants'], value: props.entrants_score},
    {name:'Business', key:props.trsl['substitutes'], value: props.substitutes_score}
  ]
  return(
    <>
      <RadarGraph points={points}/>
    </>
  )
}

export default CompetitiveMain;