import React,{useContext} from 'react';
import { Row, Col } from 'Support/grid';
import UserContext from 'CONFIG/auth';

import {FeasibilityList, PeriodHeaderWithEdit,FilterList,FeasibilityListTotal, DropdownSimple,} from 'Support/inputforms'
import { RegularButtonSmall, DeleteButton, EditButton, DuplicateButton,  ViewButtonForObserver, Button} from 'Support/listbuttons.js';
import {AddItem} from 'Support/add_item';

import {calTotalsForLists} from 'CALCULATIONS/cal_totals'
import { DetailedCard } from 'Support/warning_cards';

function SinglesFeasibilityListItems(props){
  const user_c = useContext(UserContext);
  const trsl=user_c.i18n.t('PRIVATE_PROJECT.PROJECT_FEASIBILITY.Lists')
  return(
    <div className='animation_appear'>
      <FeasibilityListHeader {...props} trsl={trsl}/>
      <ListItems {...props} trsl={trsl}/>
    </div>
  )
}


function MultipleFeasibilityListItems (props){
  const user_c = useContext(UserContext);
  const trsl=user_c.i18n.t('PRIVATE_PROJECT.PROJECT_FEASIBILITY.Lists')

  const item_holder=props.multitabs
  const size= 12 / item_holder.length
  const selected=props.multi_option
  const item_distr=item_holder.map((value)=>{
    if(value.type===selected){
      return(
        <Col xs={size}>
          <Button bk='bk_darkshade' name={value.type} onClick={props.handleChangeType} label={trsl[value.item_trsl]}/>
        </Col>
      )
    } else{
        return(
          <Col xs={size}>
            <Button bk='bk_white' name={value.type} onClick={props.handleChangeType} label={trsl[value.item_trsl]}/>
          </Col>
        )
      }
  })

  const itempicker={
    other_gains_cost:["other_gains_cost_item_name",'other_gains_cost_value', 'other_gains_cost_type'],
    shareholders:['shareholders_item_name','_shareholders_total','shareholders_type'],
    other_balance:['other_balance_item_name','_other_balance_accumulated','other_balance_type']
  }
  const typester=itempicker[props.item_type][2]


  function filterMultiItems(arr, filter){
    return arr.filter(el => el[typester]===filter)
  }
  const filtered_data=filterMultiItems(props.data,selected)
 
  const add_element=item_holder.find(el => el.type ==selected).add_item_trsl

  const element2=item_holder.find(el => el.type ==selected).type

  const propis={
    data:filtered_data,
    totals:props.totals,
    feasibility_settings:props.feasibility_settings,
    user_permission:props.user_permission,
    handleAdd:props.handleAdd, 
    handleScreenNavigation:props.handleScreenNavigation,
    handleItem:props.handleDeleteItem,
    handleDeleteItem:props.handleDeleteItem,
    handleDuplicateItem:props.handleDuplicateItem,
    handleChangLevel:props.handleChangLevel,
    add_item:add_element,
    element2:element2,
    item_type:props.item_type
  }

  return(
    <div className='animation_appear'>
      <FeasibilityListHeader {...props} trsl={trsl}/>
      <div className="feasiblity_tabs_rows">
        <Row>
          {item_distr}
        </Row>
      </div>
      <ListItems {...propis} trsl={trsl}/>
    </div>
  );
}

function FeasibilityListHeader (props){
  return(
    <Row className="feasibility_edit_globalheader_row">
      <Col xs={12}>
        <h3>{props.trsl[props.title]}</h3>
      </Col>
    </Row>
  )
}

class ListItems extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      data:[],
      usable_data:[],
      sorter:''
    }
  }

  static getDerivedStateFromProps(props, state) {
    if(props.data!==state.data){
      return {
        data:props.data,
        usable_data:props.data
      }
    }
  }

  filterTheTable=(event)=>{
    const filterItems = (arr, query) => {
      return arr.filter(el => el.item_name.toLowerCase().indexOf(query.toLowerCase()) !== -1)
    }
    let go=this.props.data
    let tester= event.target.value
    if(tester.length>0){
      go=filterItems(this.props.data,tester)
    }
    this.setState({
      usable_data:go
    })
  }

  handleViewNavigation=(e)=>{
    const event={
      target:{
        name:'edit',
        value:e.target.value
      }
    }
    this.props.handleScreenNavigation(event)
  }



  render(){
    const itempicker={
      revenues:["rev_name","_rev_total_revenue"],
      operating_expenses:["op_exp_name",'_op_exp_total'],
      human_resources:["hr_name",'_hr_total_cost'],
      other_gains_cost:["other_gains_cost_item_name",'other_gains_cost_value', 'other_gains_cost_type'],
      subsidy:["subsidy_item_name",'subsidy_value', 'subsidy_type'],
      investments:["inv_item_name",'_inv_acquisition'],
      shareholders:['shareholders_item_name','_shareholders_total','shareholders_type'],
      debt:['debt_name','_debt_final_debt'],
      other_balance:['other_balance_item_name','_other_balance_accumulated','other_balance_type']
    }

    const namer=itempicker[this.props.item_type][0]
    const valuer=itempicker[this.props.item_type][1]
    const typester=itempicker[this.props.item_type][2]

    let data = this.state.usable_data

    if(Array.isArray(data)){
      data=data.map((value)=>{
        let obj= {
          ["item_id"]: value._id,
          ["item_name"]: value[namer],
          ['item_values']:value[valuer],
          ['item_typester']:value[typester],
          ['item_or']:value.or
        }
        return obj
      })
    }

    const position_options = []
    for(var i=0;i<data.length;i++){
      position_options[i]={value:i+1, option_label:i+1}
    }

    const totals = calTotalsForLists(data)

    const data_distr = data.map((value, key) =>{
      function setListButtons(props,value,handleViewNavigation){
        if(props.user_permission=="owner" || props.user_permission=="edit"){
          return(
            <>
              <EditButton handleEditItem={props.handleScreenNavigation} name="edit" value={value.item_id}/>
              <DeleteButton handleDelete={props.handleDeleteItem} name={props.item_type} value={value.item_id}/>
              <DuplicateButton handleDuplicate={props.handleDuplicateItem} name={props.item_type} value={value.item_id}/>
              <DropdownSimple options={position_options} name={props.item_type} id={value.item_id} value={key+1} onChange={props.handleChangLevel}/>
            </>
          )
        } else{
          return(
            <>
              <ViewButtonForObserver handleEditItem={handleViewNavigation} name="edit" value={value.item_id}/>
            </>
          )
        }
      }
      const buttonRendering=setListButtons(this.props,value, this.handleViewNavigation)
      
      return(
        <Col xs={12}>
        <Row className="grid_middle non_editable_list">
            <FeasibilityList 
            label_xs={12}
            label_md={2}
            values_xs={12}
            values_md={7}
            item_holder={value.item_values}
            label={value.item_name}
            value_type="currency"
            currency={this.props.feasibility_settings.currency}
            decimals={this.props.feasibility_settings.decimal_on_report}
            />
          <Col xs={12} md={3}>
            <Row around="xs">
              {buttonRendering}
            </Row>
          </Col>
        </Row>
        </Col>
      )}
    );

    const showTotals=()=>{
      if(totals!==undefined && totals.length>0){
        return(
          <Col xs={12}>
          <Row middle="xs" className="non_editable_list">
              <FeasibilityListTotal
              label_xs={12}
              label_md={2}
              values_xs={12}
              values_md={7}
              item_holder={totals}
              label={this.props.trsl['totals']}
              value_type="currency"
              currency={this.props.feasibility_settings.currency}
              decimals={this.props.feasibility_settings.decimal_on_report}
              />
            <Col xs={2}></Col>
          </Row>
          </Col>
        )
      } else return ''
    }
    let years=this.props.feasibility_settings.years
    if(this.props.feasibility_settings.use_historic===true){
      years=this.props.feasibility_settings.historic_years.concat(this.props.feasibility_settings.years)
    }
    const totals_distr =showTotals(this.props)  
    return(
      <>
        <Row>     
          <Col xs={12} md={7}>
            <AddItem add_item={this.props.trsl[this.props.add_item]} element2={this.props.element2} handleAdd={this.props.handleAdd} user_permission={this.props.user_permission}/>
          </Col>
          <Col xs={12} md={5}>
            <FilterList label={this.props.trsl['filter']} placeholder={this.props.trsl['filter_placeholder']} filterTheTable={this.filterTheTable}/>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <PeriodHeaderWithEdit label_xs={12} label_md={2} values_xs={1} values_md={7} actions_xs={12} actions_md={3} label={this.props.trsl['year']} periods={years} edit_label={this.props.trsl['actions']} />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            {data_distr}
            {totals_distr}
          </Col>
        </Row>
      </>
    );
  }
}

class ShowItemsInCards extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      data:[],
      usable_data:[],
      sorter:''
    }
  }

  static getDerivedStateFromProps(props, state) {
    if(props.data!==state.data){
      return {
        data:props.data,
        usable_data:props.data
      }
    }
  }

  filterTheTable=(event)=>{
    const filterItems = (arr, query) => {
      return arr.filter(el => el.item_name.toLowerCase().indexOf(query.toLowerCase()) !== -1)
    }
    let go=this.props.data
    let tester= event.target.value
    if(tester.length>0){
      go=filterItems(this.props.data,tester)
    }
    this.setState({
      usable_data:go
    })
  }

  handleViewNavigation=(e)=>{
    const event={
      target:{
        name:'edit',
        value:e.target.value
      }
    }
    this.props.handleScreenNavigation(event)
  }



  render(){
    const itempicker={
      revenues:["rev_name","_rev_total_revenue"],
      operating_expenses:["op_exp_name",'_op_exp_total'],
      human_resources:["hr_name",'_hr_total_cost'],
      other_gains_cost:["other_gains_cost_item_name",'other_gains_cost_value', 'other_gains_cost_type'],
      subsidy:["subsidy_item_name",'subsidy_value', 'subsidy_type'],
      investments:["inv_item_name",'_inv_acquisition'],
      shareholders:['shareholders_item_name','_shareholders_total','shareholders_type'],
      debt:['debt_name','_debt_final_debt'],
      other_balance:['other_balance_item_name','_other_balance_accumulated','other_balance_type']
    }

    const namer=itempicker[this.props.item_type][0]
    const valuer=itempicker[this.props.item_type][1]
    const typester=itempicker[this.props.item_type][2]

    let data = this.state.usable_data

    if(Array.isArray(data)){
      data=data.map((value)=>{
        let obj= {
          ["item_id"]: value._id,
          ["item_name"]: value[namer],
          ['item_values']:value[valuer],
          ['item_typester']:value[typester],
          ['item_or']:value.or
        }
        return obj
      })
    }

    const position_options = []
    for(var i=0;i<data.length;i++){
      position_options[i]={'value':i+1, 'option_label':i+1}
    }

    const data_distr = data.map((value, key) =>{
      function setListButtons(props,value,handleViewNavigation){
        if(props.user_permission=="owner" || props.user_permission=="edit"){
          return(
            <Row>
              <EditButton handleEditItem={props.handleScreenNavigation} name="edit" value={value.item_id}/>
              <DeleteButton handleDelete={props.handleDeleteItem} name={props.item_type} value={value.item_id}/>
              <DuplicateButton handleDuplicate={props.handleDuplicateItem} name={props.item_type} value={value.item_id}/>
              <DropdownSimple options={position_options} name={props.item_type} id={value.item_id} value={key+1} onChange={props.handleChangLevel}/>
            </Row>
          )
        } else{
          return(
            <>
              <ViewButtonForObserver handleEditItem={handleViewNavigation} name="edit" value={value.item_id}/>
            </>
          )
        }
      }
      const buttonRendering=setListButtons(this.props, value, this.handleViewNavigation)
      
      return(
        <Col xs={3}>
          <DetailedCard color='lightaccent'>
            <DetailedCard.Header color='lightaccent'>
              {key+1 + ': ' +value.item_name}
            </DetailedCard.Header>
            <DetailedCard.Body >
            {buttonRendering}
            </DetailedCard.Body>
        </DetailedCard>
        </Col>
      )}
    );

    return(
      <>
        <Row>     
          <Col xs={12} md={7}>
            <AddItem add_item={this.props.trsl[this.props.add_item]} element2={this.props.element2} handleAdd={this.props.handleAdd} user_permission={this.props.user_permission}/>
          </Col>
          <Col xs={12} md={5}>
            <FilterList label={this.props.trsl['filter']} placeholder={this.props.trsl['filter_placeholder']} filterTheTable={this.filterTheTable}/>
          </Col>
        </Row>
        <Row>
          {data_distr}
        </Row>
      </>
    );
  }
}


class AssumptionHeaderSaveReturn extends React.Component{
  render(){
    let edit_ony_permission=true
    if(this.props.user_permission=="owner" || this.props.user_permission=="edit"){
      edit_ony_permission= false
    }
    return(
      <Row className="feasibility_edit_globalheader_row" >
        <Col xs={12} md={6}>
          <h3>{this.props.title}</h3>
        </Col>
        <Col xs={6} md={3}>
          <RegularButtonSmall value={0} bk="bk_warning" label={this.props.back} name="list" onClick={this.props.handleScreenNavigation} />
        </Col>
        <Col xs={6} md={3}>
          <RegularButtonSmall bk="bk_success" label= {this.props.save} value={0} name="list" onClick={this.props.handleSave} disabled={edit_ony_permission} />
        </Col>
      </Row>
    )
  }
}

class AssumptionHeaderSave extends React.Component{
  render(){
    let edit_ony_permission=true
    if(this.props.user_permission=="owner" || this.props.user_permission=="edit"){
      edit_ony_permission= false
    }
    return(
      <Row className="feasibility_edit_globalheader_row" >
      <Col xs={12} md={9}>
        <h3>{this.props.title}</h3>
      </Col>
      <Col xs={12} md={3}>
        <RegularButtonSmall bk="bk_success" label= {this.props.save} value={0} name="list" onClick={this.props.handleSave} disabled={edit_ony_permission} />
      </Col>
      </Row>
    )
  }
}

export{
  MultipleFeasibilityListItems,
  SinglesFeasibilityListItems,
  AssumptionHeaderSaveReturn,
  AssumptionHeaderSave
}