import React from 'react';
import { Row, Col} from 'Support/grid';
import UserContext from 'CONFIG/auth';
import { ToogleButtons } from 'Support/toogle_buttons';

const TableContext= React.createContext();

class DashboardTable extends React.Component {
  static contextType = UserContext

  constructor(props) {
    super(props);
    this.state = {
      active_table_forectasts:"is",
      active_table_financial:"cf"
    }
  }

  handleChangeTableForecasts=(event)=>{
    this.setState({
      active_table_forectasts:event.target.value
    })
  }
  handleChangeTableFinancials=(event)=>{
    this.setState({
      active_table_financial:event.target.value
    })
  }
  render(){
    const props={
      re:this.props.data.results,
      year:this.props.feasibility_settings.years,
      h_year:this.props.feasibility_settings.historic_years.concat(this.props.feasibility_settings.years),
      trsl:this.props.trsl
    }

    const toogle_data_forecasts=[
      {value:'is', option_label:this.props.trsl['income_st']},
      {value:'bs', option_label:this.props.trsl['balance_sheet']},
      {value:'cs', option_label:this.props.trsl['cash_st']},
      {value:'wc', option_label:this.props.trsl['working_capital']},
      {value:'in', option_label:this.props.trsl['ratios']},
    ]

    let toogle_data_financials=[
      {value:'cf', option_label:this.props.trsl['cash_flows']},
    ]
    if (this.props.feasibility_settings.financial_standalone_project===true) toogle_data_financials.push({value:'st', option_label:this.props.trsl['stand_alone_analysis']})
    if (this.props.feasibility_settings.financial_levered_project===true) toogle_data_financials.push({value:'le', option_label:this.props.trsl['levered_analysis']})
    if (this.props.feasibility_settings.financial_investor===true) toogle_data_financials.push({value:'iv', option_label:this.props.trsl['investor_analysis']})

    toogle_data_financials.push({value:'dc', option_label:this.props.trsl['debt_coverage']})

    const is_seletor={
      false: <IncomeStatement {...props}/>,
      true: <IncomeStatementWhithHistoric {...props}/>
    }

    const bs_seletor={
      false: <BalanceSheet {...props}/>,
      true: <BalanceSheetHistoric {...props}/>
    }

    const cs_seletor={
      false: <CashStatement {...props}/>,
      true: <CashStatement {...props}/>
    }

    const wc_seletor={
      false: <WorkingCapital {...props}/>,
      true: <WorkingCapitalHistoric {...props}/>
    }

    const in_selector={
      false: <Indicators {...props}/>,
      true: <IndicatorsHistoric {...props}/>
    }


    const forecasts_table={
      is:is_seletor,
      bs:bs_seletor,
      cs:cs_seletor,
      wc:wc_seletor,
      in:in_selector,
    }
    const financial_table={
      cf:<CashFlows {...props}/>,
      st:<StandAlone {...props}/>,
      le:<LeveredProject {...props}/>,
      iv:<InvestorProject {...props}/>,
      dc:<DebtCoverage {...props}/>
    }

    return(
      <TableContext.Provider value={{decimals:this.props.feasibility_settings.decimal_on_reports}}>
        <h2>
          {this.props.trsl['reports']}
        </h2>
        <Row className='blanktop_10'>
          <Col XS={12}>
            <h5>{this.props.trsl['forecasts']} - {this.props.feasibility_settings.currency}</h5>
          </Col>
          <ToogleButtons xs={12} toogle_data={toogle_data_forecasts} label={this.props.trsl['select_report']} selected={this.state.active_table_forectasts} handleToogleButton={this.handleChangeTableForecasts}/>
          <Col xs={12} >
            {forecasts_table[this.state.active_table_forectasts][this.props.feasibility_settings.use_historic]}
          </Col>
        </Row>
        <Row className='blanktop_15'>
          <Col XS={12}>
            <h5>{this.props.trsl['financials']} - {this.props.feasibility_settings.currency}</h5>
          </Col>
          <ToogleButtons xs={12} toogle_data={toogle_data_financials} label={this.props.trsl['select_report']} selected={this.state.active_table_financial} handleToogleButton={this.handleChangeTableFinancials}/>
          <Col xs={12} >
            {financial_table[this.state.active_table_financial]}
          </Col>
        </Row>
      </TableContext.Provider>
    );
  }
}

class IncomeStatement extends React.Component{
  render(){
    return(
      <Table >
          <TableHeader years={this.props.h_year} firstcell={this.props.trsl['income_st']}/>
          <TableRow  firstcell={this.props.trsl['revenues']} values={this.props.re._inc_st.revenue} />
          <TableRow  firstcell={this.props.trsl['subsidies']} values={this.props.re._inc_st.operational_grants}/>
          <TableRow  firstcell={this.props.trsl['cogs']} values={this.props.re._inc_st.cogs}/>
          <TableRow  firstcell={this.props.trsl['operating_exp']} values={this.props.re._inc_st.operating_expenses}/>
          <TableRow  firstcell={this.props.trsl['employees']} values={this.props.re._inc_st.hr}/>
          <TableRow  firstcell={this.props.trsl['other_revenues']} values={this.props.re._inc_st.other_gains}/>
          <TableRow  firstcell={this.props.trsl['other_costs']} values={this.props.re._inc_st.other_costs}/>
          <TableRow  firstcell={this.props.trsl['ebitda']} class="table_row_special_1" values={this.props.re._inc_st.ebitda}/>
          <TableRow  firstcell={this.props.trsl['depreciations']} values={this.props.re._inc_st.depreciations}/>
          <TableRow  firstcell={this.props.trsl['ebit']} class="table_row_special_1" values={this.props.re._inc_st.ebit}/>
          <TableRow  firstcell={this.props.trsl['financing_costs']} values={this.props.re._inc_st.interest_cost}/>
          <TableRow  firstcell={this.props.trsl['ebt']} class="table_row_special_1" values={this.props.re._inc_st.ebt}/>
          <TableRow  firstcell={this.props.trsl['corporate_tax']} values={this.props.re._inc_st.corporate_tax}/>
          <TableRow  firstcell={this.props.trsl['net_income']} class="table_row_special_2" values={this.props.re._inc_st.net_profit}/>
      </Table>
    );
  }
}

class IncomeStatementWhithHistoric extends React.Component{
  render(){
    return(
      <Table >
          <TableHeader years={this.props.h_year} firstcell={this.props.trsl['income_st']}/>
          <TableRowHistoric  firstcell={this.props.trsl['revenues']} values={this.props.re._inc_st.revenue} />
          <TableRowHistoric  firstcell={this.props.trsl['subsidies']} values={this.props.re._inc_st.operational_grants}/>
          <TableRowHistoric  firstcell={this.props.trsl['cogs']} values={this.props.re._inc_st.cogs}/>
          <TableRowHistoric  firstcell={this.props.trsl['operating_exp']} values={this.props.re._inc_st.operating_expenses}/>
          <TableRowHistoric  firstcell={this.props.trsl['employees']} values={this.props.re._inc_st.hr}/>
          <TableRowHistoric  firstcell={this.props.trsl['other_revenues']} values={this.props.re._inc_st.other_gains}/>
          <TableRowHistoric  firstcell={this.props.trsl['other_costs']} values={this.props.re._inc_st.other_costs}/>
          <TableRowHistoric  firstcell={this.props.trsl['ebitda']} class="table_row_special_1" values={this.props.re._inc_st.ebitda}/>
          <TableRowHistoric  firstcell={this.props.trsl['depreciations']} values={this.props.re._inc_st.depreciations}/>
          <TableRowHistoric  firstcell={this.props.trsl['ebit']} class="table_row_special_1" values={this.props.re._inc_st.ebit}/>
          <TableRowHistoric  firstcell={this.props.trsl['financing_costs']} values={this.props.re._inc_st.interest_cost}/>
          <TableRowHistoric  firstcell={this.props.trsl['ebt']} class="table_row_special_1" values={this.props.re._inc_st.ebt}/>
          <TableRowHistoric  firstcell={this.props.trsl['corporate_tax']} values={this.props.re._inc_st.corporate_tax}/>
          <TableRowHistoric  firstcell={this.props.trsl['net_income']} class="table_row_special_2" values={this.props.re._inc_st.net_profit}/>
      </Table>
    );
  }
}

class BalanceSheet extends React.Component{
  render(){
    return(
      <Table >
        <TableHeader years={this.props.h_year} firstcell={this.props.trsl['balance_sheet']}/>
        <TableRow  firstcell={this.props.trsl['tangible']} values={this.props.re._bal.tangible_assets}/>
        <TableRow  firstcell={this.props.trsl['intangible']} values={this.props.re._bal.intangible_assets}/>
        <TableRow  firstcell={this.props.trsl['other_non_assets']} values={this.props.re._bal.other_non_current_assets}/>
        <TableRow  firstcell={this.props.trsl['non_current_assets']} class="table_row_special_1" values={this.props.re._bal.total_non_current_assets}/>
        <TableRow  firstcell={this.props.trsl['iventories']} values={this.props.re._bal.inventories}/>
        <TableRow  firstcell={this.props.trsl['clients']} values={this.props.re._bal.clients}/>
        <TableRow  firstcell={this.props.trsl['gov_receivables']} values={this.props.re.gov.gov_receivable}/>
        <TableRow  firstcell={this.props.trsl['other_current_assets']} values={this.props.re._bal.other_current_assets}/>
        <TableRow  firstcell={this.props.trsl['cash']} values={this.props.re.cf.year_end_cash_flows}/>
        <TableRow  firstcell={this.props.trsl['current_assets']} class="table_row_special_1" values={this.props.re._bal.total_current_assets}/>
        <TableRow  firstcell={this.props.trsl['total_assets']} class="table_row_special_2" values={this.props.re._bal.total_assets}/>

        <TableRow  firstcell={this.props.trsl['issued_capital']} values={this.props.re._bal.issued_capital}/>
        <TableRow  firstcell={this.props.trsl['other_equity_instr']} values={this.props.re._bal.other_equity_instruments}/>
        <TableRow  firstcell={this.props.trsl['other_equity_chan']} values={this.props.re._bal.other_equity_changes}/>
        <TableRow  firstcell={this.props.trsl['past_results']} values={this.props.re._bal.past_results}/>
        <TableRow  firstcell={this.props.trsl['net_income']} values={this.props.re._inc_st.net_profit}/>
        <TableRow  firstcell={this.props.trsl['total_equity']} class="table_row_special_2" values={this.props.re._bal.total_equity}/>

        <TableRow  firstcell={this.props.trsl['debt']} values={this.props.re._bal.debt}/>
        <TableRow  firstcell={this.props.trsl['other_non_liabilities']} values={this.props.re._bal.other_non_current_liabilities}/>
        <TableRow  firstcell={this.props.trsl['non_current_liabilities']} class="table_row_special_1" values={this.props.re._bal.total_non_current_liabilities}/>
        <TableRow  firstcell={this.props.trsl['suppliers']} values={this.props.re._bal.suppliers}/>
        <TableRow  firstcell={this.props.trsl['gov_payables']} values={this.props.re.gov.gov_payable}/>
        <TableRow  firstcell={this.props.trsl['shareholders_loan']} values={this.props.re._bal.shareholders_loan}/>
        <TableRow  firstcell={this.props.trsl['other_payables']} values={this.props.re._bal.other_accounts_payables}/>
        <TableRow  firstcell={this.props.trsl['other_current_liabilities']} values={this.props.re._bal.other_current_liabilities}/>
        <TableRow  firstcell={this.props.trsl['current_liabilities']} class="table_row_special_1" values={this.props.re._bal.total_current_liabilities}/>
        <TableRow  firstcell={this.props.trsl['total_liabilities']} class="table_row_special_2" values={this.props.re._bal.total_liabilities}/>
        <TableRow  firstcell={this.props.trsl['equity_liabilities']} class="table_row_special_2" values={this.props.re._bal.equity_liabilities}/>
      </Table>
    );
  }
}

class BalanceSheetHistoric extends React.Component{
  render(){
    return(
      <Table >
        <TableHeader years={this.props.h_year} firstcell={this.props.trsl['balance_sheet']}/>
        <TableRowHistoric  firstcell={this.props.trsl['tangible']} values={this.props.re._bal.tangible_assets}/>
        <TableRowHistoric  firstcell={this.props.trsl['intangible']} values={this.props.re._bal.intangible_assets}/>
        <TableRowHistoric  firstcell={this.props.trsl['other_non_assets']} values={this.props.re._bal.other_non_current_assets}/>
        <TableRowHistoric  firstcell={this.props.trsl['non_current_assets']} class="table_row_special_1" values={this.props.re._bal.total_non_current_assets}/>
        <TableRowHistoric  firstcell={this.props.trsl['iventories']} values={this.props.re._bal.inventories}/>
        <TableRowHistoric  firstcell={this.props.trsl['clients']} values={this.props.re._bal.clients}/>
        <TableRowHistoric  firstcell={this.props.trsl['gov_receivables']} values={this.props.re.gov.gov_receivable}/>
        <TableRowHistoric  firstcell={this.props.trsl['other_current_assets']} values={this.props.re._bal.other_current_assets}/>
        <TableRowHistoric  firstcell={this.props.trsl['cash']} values={this.props.re.cf.year_end_cash_flows}/>
        <TableRowHistoric  firstcell={this.props.trsl['current_assets']} class="table_row_special_1" values={this.props.re._bal.total_current_assets}/>
        <TableRowHistoric  firstcell={this.props.trsl['total_assets']} class="table_row_special_2" values={this.props.re._bal.total_assets}/>

        <TableRowHistoric  firstcell={this.props.trsl['issued_capital']} values={this.props.re._bal.issued_capital}/>
        <TableRowHistoric  firstcell={this.props.trsl['other_equity_instr']} values={this.props.re._bal.other_equity_instruments}/>
        <TableRowHistoric  firstcell={this.props.trsl['other_equity_chan']} values={this.props.re._bal.other_equity_changes}/>
        <TableRowHistoric  firstcell={this.props.trsl['past_results']} values={this.props.re._bal.past_results}/>
        <TableRowHistoric  firstcell={this.props.trsl['net_income']} values={this.props.re._inc_st.net_profit}/>
        <TableRowHistoric  firstcell={this.props.trsl['total_equity']} class="table_row_special_2" values={this.props.re._bal.total_equity}/>

        <TableRowHistoric  firstcell={this.props.trsl['debt']} values={this.props.re._bal.debt}/>
        <TableRowHistoric  firstcell={this.props.trsl['other_non_liabilities']} values={this.props.re._bal.other_non_current_liabilities}/>
        <TableRowHistoric  firstcell={this.props.trsl['non_current_liabilities']} class="table_row_special_1" values={this.props.re._bal.total_non_current_liabilities}/>
        <TableRowHistoric  firstcell={this.props.trsl['suppliers']} values={this.props.re._bal.suppliers}/>
        <TableRowHistoric  firstcell={this.props.trsl['gov_payables']} values={this.props.re.gov.gov_payable}/>
        <TableRowHistoric  firstcell={this.props.trsl['shareholders_loan']} values={this.props.re._bal.shareholders_loan}/>
        <TableRowHistoric  firstcell={this.props.trsl['other_payables']} values={this.props.re._bal.other_accounts_payables}/>
        <TableRowHistoric  firstcell={this.props.trsl['other_current_liabilities']} values={this.props.re._bal.other_current_liabilities}/>
        <TableRowHistoric  firstcell={this.props.trsl['current_liabilities']} class="table_row_special_1" values={this.props.re._bal.total_current_liabilities}/>
        <TableRowHistoric  firstcell={this.props.trsl['total_liabilities']} class="table_row_special_2" values={this.props.re._bal.total_liabilities}/>
        <TableRowHistoric  firstcell={this.props.trsl['equity_liabilities']} class="table_row_special_2" values={this.props.re._bal.equity_liabilities}/>
      </Table>
    );
  }
}

class CashStatement extends React.Component{
  render(){
    return(
      <Table  id="CASHSTATEMENT">
        <TableHeader years={this.props.h_year} firstcell={this.props.trsl['cash_st']}/>
        <TableRow  firstcell={this.props.trsl['received_clients']} values={this.props.re.cf.received_from_clients}/>
        <TableRow  firstcell={this.props.trsl['paid_suppliers']} values={this.props.re.cf.paid_to_suppliers}/>
        <TableRow  firstcell={this.props.trsl['paid_hr']} values={this.props.re.cf.payment_to_hr}/>
        <TableRow  firstcell={this.props.trsl['operation_cf']} class="table_row_special_1" values={this.props.re.cf.operations_cash_flows}/>
        <TableRow  firstcell={this.props.trsl['corporate_tax']} values={this.props.re.cf.corporate_tax_cash_flow}/>
        <TableRow  firstcell={this.props.trsl['other_pay_rec']} values={this.props.re.cf.other_payments_and_receivables}/>
        <TableRow  firstcell={this.props.trsl['operational_cf']} class="table_row_special_1" values={this.props.re.cf.operational_cash_flow}/>
        <TableRow  firstcell={this.props.trsl['non_current_cf']} values={this.props.re.cf.cash_flows_of_non_current_assets_liabilities}/>
        <TableRow  firstcell={this.props.trsl['investment_in_assets']} values={this.props.re.cf.payment_of_fixed_assets}/>
        <TableRow  firstcell={this.props.trsl['investment_cf']} class="table_row_special_1" values={this.props.re.cf.investment_cash_flows}/>
        <TableRow  firstcell={this.props.trsl['debt_increase']} values={this.props.re.cf.debt_increase}/>
        <TableRow  firstcell={this.props.trsl['equity_eq_increase']} values={this.props.re.cf.equity_and_equivalent_increase}/>
        <TableRow  firstcell={this.props.trsl['financing_costs']} values={this.props.re._inc_st.interest_cost}/>
        <TableRow  firstcell={this.props.trsl['debt_decrease']} values={this.props.re.cf.debt_decrease}/>
        <TableRow  firstcell={this.props.trsl['paid_dividends']} values={this.props.re.cf.paid_dividends}/>
        <TableRow  firstcell={this.props.trsl['equity_eq_decrease']} values={this.props.re.cf.equity_and_equivalent_decrease}/>
        <TableRow  firstcell={this.props.trsl['financing_cashflows']} class="table_row_special_1" values={this.props.re.cf.financing_cashflows}/>
        <TableRow  firstcell={this.props.trsl['cf_variation']} class="table_row_special_2" values={this.props.re.cf.cash_flow_variation}/>
        <TableRow  firstcell={this.props.trsl['initial_cash']} class="table_row_special_1" values={this.props.re.cf.initial_cash_flows}/>
        <TableRow  firstcell={this.props.trsl['end_cash']} class="table_row_special_2" values={this.props.re.cf.year_end_cash_flows}/>
      </Table>
    );
  }
}

class WorkingCapital extends React.Component{
  render(){
    return(
      <Table >
          <TableHeader years={this.props.h_year} firstcell={this.props.trsl['working_capital']}/>
          <TableRow  firstcell={this.props.trsl['iventories']} values={this.props.re._bal.inventories}/>
          <TableRow  firstcell={this.props.trsl['clients']} values={this.props.re._bal.clients}/>
          <TableRow  firstcell={this.props.trsl['gov_receivables']} values={this.props.re.gov.gov_receivable}/>
          <TableRow  firstcell={this.props.trsl['other_current_assets']} values={this.props.re._bal.other_current_assets}/>
          <TableRow  firstcell={this.props.trsl['working_capital_investent']} class="table_row_special_1" values={this.props.re.wc.wc_investment}/>
          <TableRow  firstcell={this.props.trsl['suppliers']} values={this.props.re._bal.suppliers}/>
          <TableRow  firstcell={this.props.trsl['gov_payables']} values={this.props.re.gov.gov_payable}/>
          <TableRow  firstcell={this.props.trsl['other_payables']} values={this.props.re._bal.other_accounts_payables}/>
          <TableRow  firstcell={this.props.trsl['other_current_liabilities']} values={this.props.re._bal.other_current_liabilities}/>
          <TableRow  firstcell={this.props.trsl['working_capital_financing']} class="table_row_special_1" values={this.props.re.wc.wc_financing}/>
          <TableRow  firstcell={this.props.trsl['working_capital_needs']} class="table_row_special_1"values={this.props.re.wc.wc_needs}/>
          <TableRow  firstcell={this.props.trsl['working_capital_variation']} class="table_row_special_2"values={this.props.re.wc.wc_variation}/>
      </Table>
    );
  }
}

class WorkingCapitalHistoric extends React.Component{
  render(){
    return(
      <Table >
          <TableHeader years={this.props.h_year} firstcell={this.props.trsl['working_capital']}/>
          <TableRowHistoric  firstcell={this.props.trsl['iventories']} values={this.props.re._bal.inventories}/>
          <TableRowHistoric  firstcell={this.props.trsl['clients']} values={this.props.re._bal.clients}/>
          <TableRowHistoric  firstcell={this.props.trsl['gov_receivables']} values={this.props.re.gov.gov_receivable}/>
          <TableRowHistoric  firstcell={this.props.trsl['other_current_assets']} values={this.props.re._bal.other_current_assets}/>
          <TableRowHistoric  firstcell={this.props.trsl['working_capital_investent']} class="table_row_special_1" values={this.props.re.wc.wc_investment}/>
          <TableRowHistoric  firstcell={this.props.trsl['suppliers']} values={this.props.re._bal.suppliers}/>
          <TableRowHistoric  firstcell={this.props.trsl['gov_payables']} values={this.props.re.gov.gov_payable}/>
          <TableRowHistoric  firstcell={this.props.trsl['other_payables']} values={this.props.re._bal.other_accounts_payables}/>
          <TableRowHistoric  firstcell={this.props.trsl['other_current_liabilities']} values={this.props.re._bal.other_current_liabilities}/>
          <TableRowHistoric  firstcell={this.props.trsl['working_capital_financing']} class="table_row_special_1" values={this.props.re.wc.wc_financing}/>
          <TableRowHistoric  firstcell={this.props.trsl['working_capital_needs']} class="table_row_special_1"values={this.props.re.wc.wc_needs}/>
          <TableRowHistoric  firstcell={this.props.trsl['working_capital_variation']} class="table_row_special_2"values={this.props.re.wc.wc_variation}/>
      </Table>
    );
  }
}

class CashFlows extends React.Component{
  render(){
    return(
      <>
        <Table >
          <TableHeader years={this.props.year} firstcell={this.props.trsl['cash_flows']}/>
          <TableRow firstcell={this.props.trsl['ebit']} values={this.props.re.fe.ebit}/>
          <TableRow firstcell={this.props.trsl['operationa_corporate_tax']} values={this.props.re.fe.operational_corporate_tax}/>
          <TableRow firstcell={this.props.trsl['depreciations']} values={this.props.re.fe.depreciations}/>
          <TableRow firstcell={this.props.trsl['noplat']} class="table_row_special_1" values={this.props.re.fe.noplat}/>
          <TableRow firstcell={this.props.trsl['working_capital_variation']}  values={this.props.re.fe.wc_variation}/>
          <TableRow firstcell={this.props.trsl['fixed_asset_investment']} values={this.props.re.fe.fixed_asset_investment}/>
          <TableRow firstcell={this.props.trsl['fcff']} class="table_row_special_1" values={this.props.re.fe.fcff}/>
          <TableRow firstcell={this.props.trsl['accumulated_fcff']} values={this.props.re.fe.fcff_accumulated}/>
          <TableRow firstcell={this.props.trsl['fcfe']} class="table_row_special_1" values={this.props.re.fe.fcfe}/>
          <TableRow firstcell={this.props.trsl['accumulated_fcfe']}  values={this.props.re.fe.fcfe_accumulated}/>
        </Table>
      </>
    );
  }
}

class StandAlone extends React.Component{
  render(){
    return(
      <>
        <Table >
          <TableHeader years={this.props.year} firstcell={this.props.trsl['stand_alone_analysis']}/>
          <TableRow firstcell={this.props.trsl['fcff']}  values={this.props.re.fe.fcff}/>
          <TableRow firstcell={this.props.trsl['accumulated_fcff']} values={this.props.re.fe.fcff_accumulated}/>
          <TableRow firstcell={this.props.trsl['discounted_fcff']} values={this.props.re._sap.fcff_discounted}/>
          <TableRow firstcell={this.props.trsl['accumulated_discounted_fcff']} class="table_row_special_1" values={this.props.re._sap.fcff_discounted_accumulated}/>
        </Table>
        <Table >
          <TableRowSpecial firstcell={this.props.trsl['perpetuity']} class="table_row_special_2" values={[""]}/>
          <TableRow firstcell={this.props.trsl['normalized_fcff']} values={[this.props.re.fe.fcff_normalized]}/>
          <TableRow firstcell={this.props.trsl['perpetuity']} values={[this.props.re._sap.perpetuity]}/>
          <TableRow firstcell={this.props.trsl['perpetuity_discounted']} values={[this.props.re._sap.perpetuity_discounted]}/>
          <TableRowSpecial firstcell={this.props.trsl['npv']} class="table_row_special_2" values={[""]}/>
          <TableRow firstcell={this.props.trsl['npv']} values={[this.props.re._sap.npv]}/>
          <TableRowSpecial firstcell={this.props.trsl['irr']} values={[(this.props.re._sap.irr*100).toFixed(2)]}/>
          <TableRowSpecial firstcell={this.props.trsl['payback_period']} values={[this.props.re._sap.payback]}/>
        </Table>
      </>
    );
  }
}

class LeveredProject extends React.Component{
  render(){
    return(
      <>
        <Table >
          <TableHeader years={this.props.year} firstcell={this.props.trsl['levered_analysis']}/>
          <TableRow firstcell={this.props.trsl['fcff']} values={this.props.re.fe.fcff}/>
          <TableRow firstcell={this.props.trsl['accumulated_fcff']} values={this.props.re.fe.fcff_accumulated}/>
          <TableRow firstcell={this.props.trsl['discounted_fcff']} values={this.props.re._lep.fcff_discounted}/>
          <TableRow firstcell={this.props.trsl['accumulated_discounted_fcff']} class="table_row_special_1" values={this.props.re._lep.fcff_discounted_accumulated}/>
        </Table>
        <Table >
          <TableRowSpecial firstcell={this.props.trsl['perpetuity']} class="table_row_special_2" values={[""]}/>
          <TableRow firstcell={this.props.trsl['normalized_fcff']} values={[this.props.re.fe.fcff_normalized]}/>
          <TableRow firstcell={this.props.trsl['perpetuity']} values={[this.props.re._lep.perpetuity]}/>
          <TableRow firstcell={this.props.trsl['perpetuity_discounted']} values={[this.props.re._lep.perpetuity_discounted]}/>
          <TableRowSpecial firstcell={this.props.trsl['npv']} class="table_row_special_2" values={[""]}/>
          <TableRow firstcell={this.props.trsl['npv']} values={[this.props.re._lep.npv]}/>
          <TableRowSpecial firstcell={this.props.trsl['irr']} values={[(this.props.re._lep.irr*100).toFixed(2)]}/>
          <TableRowSpecial firstcell={this.props.trsl['payback_period']} values={[this.props.re._lep.payback]}/>
        </Table>
      </>
    );
  }
}

class InvestorProject extends React.Component{
  render(){
    return(
      <>
        <Table >
          <TableHeader years={this.props.year} firstcell={this.props.trsl['investor_analysis']}/>
          <TableRow firstcell={this.props.trsl['fcfe']}  values={this.props.re.fe.fcfe}/>
          <TableRow firstcell={this.props.trsl['accumulated_fcfe']} values={this.props.re.fe.fcfe_accumulated}/>
          <TableRow firstcell={this.props.trsl['discounted_fcfe']} values={this.props.re._ivp.fcfe_discounted}/>
          <TableRow firstcell={this.props.trsl['accumulated_discounted_fcfe']} class="table_row_special_1" values={this.props.re._ivp.fcfe_discounted_accumulated}/>
        </Table>
        <Table >
          <TableRowSpecial firstcell={this.props.trsl['perpetuity']} class="table_row_special_2" values={[""]}/>
          <TableRow firstcell={this.props.trsl['normalized_fcfe']} values={[this.props.re.fe.fcff_normalized]}/>
          <TableRow firstcell={this.props.trsl['perpetuity']} values={[this.props.re._ivp.perpetuity]}/>
          <TableRow firstcell={this.props.trsl['perpetuity_discounted']} values={[this.props.re._ivp.perpetuity_discounted]}/>
          <TableRowSpecial firstcell={this.props.trsl['npv']} class="table_row_special_2" values={[""]}/>
          <TableRow firstcell={this.props.trsl['npv']} values={[this.props.re._ivp.npv]}/>
          <TableRowSpecial firstcell={this.props.trsl['irr']} values={[(this.props.re._ivp.irr*100).toFixed(2)]}/>
          <TableRowSpecial firstcell={this.props.trsl['payback_period']} values={[this.props.re._ivp.payback]}/>
        </Table>
      </>
    );
  }
}

class Indicators extends React.Component{
  render(){
    return(
      <>
        <Table >
          <TableHeader years={this.props.year} firstcell={this.props.trsl['ratios']}/>
          <TableRowPercentage firstcell={this.props.trsl['gross_margin']} values={this.props.re.ra.gross_margin}/>
          <TableRowPercentage firstcell={this.props.trsl['net_margin']} values={this.props.re.ra.net_margin}/>
          <TableRowPercentage firstcell={this.props.trsl['return_on_sales']} values={this.props.re.ra.ros}/>
          <TableRowPercentage firstcell={this.props.trsl['return_on_assets']} values={this.props.re.ra.roa}/>
          <TableRowPercentage firstcell={this.props.trsl['return_on_equity']} values={this.props.re.ra.roe}/>
          <TableRowSimple firstcell={this.props.trsl['equity_ratio']} values={this.props.re.ra.equity_ratio}/>
          <TableRowSimple firstcell={this.props.trsl['dar']} values={this.props.re.ra.dar}/>
          <TableRowSimple firstcell={this.props.trsl['dap']} values={this.props.re.ra.dap}/>
          <TableRowSimple firstcell={this.props.trsl['dos']} values={this.props.re.ra.dos}/>
          <TableRowPercentage firstcell={this.props.trsl['depreciation_rate']} values={this.props.re.ra.depreciation_rate}/>
          <TableRowPercentage firstcell={this.props.trsl['corporate_tax_rate']} values={this.props.re.ra.corporate_tax_rate}/>
        </Table>
      </>
    );
  }
}

class IndicatorsHistoric extends React.Component{
  render(){
    return(
      <>
        <Table >
          <TableHeader years={this.props.h_year} firstcell={this.props.trsl['ratios']}/>
          <TableRowHistoricPercentage firstcell={this.props.trsl['gross_margin']} values={this.props.re.ra.gross_margin}/>
          <TableRowHistoricPercentage firstcell={this.props.trsl['net_margin']} values={this.props.re.ra.net_margin}/>
          <TableRowHistoricPercentage firstcell={this.props.trsl['return_on_sales']} values={this.props.re.ra.ros}/>
          <TableRowHistoricPercentage firstcell={this.props.trsl['return_on_assets']} values={this.props.re.ra.roa}/>
          <TableRowHistoricPercentage firstcell={this.props.trsl['return_on_equity']} values={this.props.re.ra.roe}/>
          <TableRowHistoricPercentage firstcell={this.props.trsl['equity_ratio']} values={this.props.re.ra.equity_ratio}/>
          <TableRowHistoricPercentage firstcell={this.props.trsl['dar']} values={this.props.re.ra.dar}/>
          <TableRowHistoricPercentage firstcell={this.props.trsl['dap']} values={this.props.re.ra.dap}/>
          <TableRowHistoricPercentage firstcell={this.props.trsl['dos']} values={this.props.re.ra.dos}/>
          <TableRowHistoricPercentage firstcell={this.props.trsl['depreciation_rate']} values={this.props.re.ra.depreciation_rate}/>
          <TableRowHistoricPercentage firstcell={this.props.trsl['corporate_tax_rate']} values={this.props.re.ra.corporate_tax_rate}/>
        </Table>
      </>
    );
  }
}

class DebtCoverage extends React.Component{
  render(){
    return(
      <>
        <Table >
          <TableHeader years={this.props.year} firstcell={this.props.trsl['debt_coverage']}/>
          <TableRow firstcell={this.props.trsl['debt_interest']} class="table_row_special_1" values={this.props.re.ra.interest}/>
          <TableRowPercentage firstcell={this.props.trsl['ebtida_coverage']} values={this.props.re.ra.ebtida_interest_coverage_rate}/>
          <TableRowPercentage firstcell={this.props.trsl['fcff_coverage']} values={this.props.re.ra.fcff_interest_coverate_rate}/>
          <TableRowPercentage firstcell={this.props.trsl['cash_coverage']} values={this.props.re.ra.available_cash_interest_coverage_rate}/>
          <TableRow firstcell={this.props.trsl['debt_service']} class="table_row_special_1" values={this.props.re.ra.debt_service}/>
          <TableRowPercentage firstcell={this.props.trsl['ebtida_coverage']} values={this.props.re.ra.ebtida_debt_service_rate}/>
          <TableRowPercentage firstcell={this.props.trsl['fcff_coverage']} values={this.props.re.ra.fcff_debt_service_rate}/>
          <TableRowPercentage firstcell={this.props.trsl['cash_coverage']} values={this.props.re.ra.available_cash_debt_service_rate}/>
        </Table>
      </>
    );
  }
}

function Table (props){
  return(
    <table className='table'>
      {props.children}
    </table>
  )
}


class TableHeader extends React.Component{
  render(){
  const item = this.props.years
  const item_distr = item.map((key)=>
    <TableHeaderCell value={key}/>
  );
  return(
    <thead>
      <TableFirstHeaderCell value={this.props.firstcell}/>
      {item_distr}
    </thead>
  );
  }
}

class TableFirstHeaderCell extends React.Component{
  render(){
    return(
      <th className="table_header_cell table_cell_Left">
        {this.props.value}
      </th>
    );
  }
}

class TableHeaderCell extends React.Component{
  render(){
    return(
      <th className="table_header_cell">
        {this.props.value}
      </th>
    );
  }
}

class TableRow extends React.Component{
  render(){
    const item_holder= this.props.values
    const  item_distr = item_holder.map((value)=>
      <TableCell value={value} />
    )  
    return(
      <tr className={`${this.props.class} table_row`}>
        <TableFirstCell value={this.props.firstcell}/>
        {item_distr}
      </tr>
    );
  }
}

class TableRowSpecial extends React.Component{
  render(){
    const item_holder= this.props.values
    const item_distr = item_holder.map((key)=>
      <TableCellSpecial value={key} />
    )
    return(
      <tr className={this.props.class}>
        <TableFirstCell value={this.props.firstcell}/>
        {item_distr}
      </tr>
    );
  }
}

class TableRowPercentage extends React.Component{
  render(){
    const item_holder= this.props.values
    const  item_distr = item_holder.map((value)=>
      <TableCellPercentage value={value} />
    )  
    return(
      <tr className={this.props.class}>
        <TableFirstCell value={this.props.firstcell}/>
        {item_distr}
      </tr>
    );
  }
}

class TableRowSimple extends React.Component{
  render(){
    const item_holder= this.props.values
    const  item_distr = item_holder.map((value)=>
      <TableCellSimple value={value} />
    )  
    return(
      <tr className={this.props.class}>
        <TableFirstCell value={this.props.firstcell}/>
        {item_distr}
      </tr>
    );
  }
}

class TableRowHistoric extends React.Component{
  render(){
    const item_holder= this.props.values
    const  item_distr = item_holder.map((value,k)=>{
      if(k===0){
        return <TableCellHistoric value={value} />
      } else {
        return <TableCell value={value} />    
      }
    })
    return(
      <tr className={this.props.class}>
        <TableFirstCell value={this.props.firstcell}/>
        {item_distr}
      </tr>
    );
  }
}

class TableRowHistoricPercentage extends React.Component{
  render(){
    const item_holder= this.props.values
    const  item_distr = item_holder.map((value,k)=>{
      if(k===0){
        return <TableCellHistoricPercentage value={value} />
      } else {
        return <TableCellPercentage value={value} />    
      }
    })
    return(
      <tr className={this.props.class}>
        <TableFirstCell value={this.props.firstcell}/>
        {item_distr}
      </tr>
    );
  }
}

class TableFirstCell extends React.Component{
  render(){
    return(
    <td>
      {this.props.value}
    </td>
    );
  }
}

function TableCell (props){
  return(
    <UserContext.Consumer>
      {user => (
        <TableContext.Consumer>
          {ind => (
            <td className="table_simple_cell">
              {user.i18n.deciF(props.value,ind.decimals)}
            </td>
          )}
        </TableContext.Consumer>
      )}
    </UserContext.Consumer>
  );
}

function TableCellPercentage (props){
  return(
    <UserContext.Consumer>
      {user => (
        <TableContext.Consumer>
          {ind => (
            <td className="table_simple_cell">
              {user.i18n.percF(props.value,ind.currency)}
            </td>
          )}
        </TableContext.Consumer>
      )}
    </UserContext.Consumer>
  );
}

function TableCellSimple (props){
  return(
    <UserContext.Consumer>
      {user => (
        <TableContext.Consumer>
          {ind => (
            <td className="table_simple_cell">
              {user.i18n.deciF(props.value,true)}
            </td>
          )}
        </TableContext.Consumer>
      )}
    </UserContext.Consumer>
  );
}



function TableCellHistoric (props){
  return(
    <UserContext.Consumer>
      {user => (
        <TableContext.Consumer>
          {ind => (
            <td className="table_with_historic">
              {user.i18n.deciF(props.value,ind.decimals)}
            </td>
          )}
        </TableContext.Consumer>
      )}
    </UserContext.Consumer>
  );
}

function TableCellHistoricPercentage (props){
  return(
    <UserContext.Consumer>
      {user => (
        <TableContext.Consumer>
          {ind => (
            <td className="table_with_historic">
              {user.i18n.percF(props.value,ind.currency)}
            </td>
          )}
        </TableContext.Consumer>
      )}
    </UserContext.Consumer>
  );
}

function TableCellSpecial (props){
  return(
    <td className="table_simple_cell">
      {props.value}
    </td>
  );
}

export default DashboardTable;