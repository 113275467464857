import React from 'react';
import { Row, Col } from 'Support/grid'
import HrEntry from './hr';
import HrList from './hrlist';

class HrMain extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      to_render: "list",
      active_item:0
    }
    this.handleScreenNavigation=this.handleScreenNavigation.bind(this)
    this.changeScreenView=this.changeScreenView.bind(this)
  }

  changeScreenView=(window, item)=>{
    this.setState({
      active_item: item,
      to_render: window
    })
  }

  handleScreenNavigation(event){
    const value = event.target.value
    const name = event.target.name
    this.changeScreenView(name, value)
  }

  render(){
    const props={
      active_item:this.state.active_item,
      budget_id:this.props.budget_id,
      current_version:this.props.current_version,
      changeScreenView:this.changeScreenView,
      budget_settings:this.props.budget_settings,
      user_permission:this.props.user_permission,
      handleScreenNavigation:this.handleScreenNavigation
    }

    function torender(data, i){
      return data[i];
    }
    const pages ={
      edit: <HrEntry {...props}/>,
      list: <HrList {...props}/>
    }
    var i = this.state.to_render
    const renderizing = torender(pages,i)

    return(
      <Row>
      <Col xs={12}>
        {renderizing}
      </Col>
      </Row>
    );
  }
}

export default HrMain;