import React from 'react';
import {Button, Image} from 'react-bootstrap';
import {Grid,Row, Col} from 'Support/grid';
import UserContext from 'CONFIG/auth';
import casflo_logo from 'TEMPLATES/CASFLO/Images/casflo_logowhite.png';
import viseeon_logo from 'TEMPLATES/Viseeon/Images/viseeon_logo.png';
import EU from 'images/useful/EU.png';
import PT from 'images/useful/PT.jpeg';

class HeaderViseeon extends React.Component{
  render(){
    const user_c=this.props.user_c
    const avatar=this.props.avatar

    const screen_width = (window.innerWidth > 0) ? window.innerWidth : window.width;
    let show_Avatar=''
    if(screen_width>756){
      show_Avatar=
        <Col xs={8} md={1}>
          <Row bottom="xs" className="header_profile">
            <Col xs={7}>
              <Image 
              className="header_profile_image" 
              roundedCircle 
              src={require(`images/avatars/user-${avatar}.png`).default} />
            </Col>
          </Row>
        </Col>
    }

    return(
      <Grid fluid className="header_brand">
        <Row noGutters>
          <Col xs={12}>
            <Row middle="xs">
              <Col xs={6} md={5}>
                <a href='https://casflo-app.com' target='_blank'>
                  <Image className="header_logo" src={casflo_logo} />
                </a>
              </Col>
              <Col xs={6} md={2}>
                <a href='https://viseeon.pt' target='_blank'>
                  <Image className='header_logo'src={viseeon_logo}/>
                </a>
              </Col>
              {show_Avatar}
              <Col xs={12} md={4}>
                <Row>
                  <Col xs={4} md={4}>
                    <Button variant="outline-secondary" size="sm" block name="/app/main" onClick={this.props.handleEditItem}>{user_c.i18n.t('PRIVATE.Header.main_page')}</Button>
                    <Button variant="outline-secondary" size="sm" block name="/app/projects_list" onClick={this.props.handleEditItem}>{user_c.i18n.t('PRIVATE.Header.project')}</Button>
                  </Col>

                  <Col xs={4} md={4}>
                    <Button variant="outline-secondary" size="sm" block name="/app/subscriptions" onClick={this.props.handleEditItem}>{user_c.i18n.t('PRIVATE.Header.account')}</Button>
                    <Button variant="outline-secondary" size="sm" block name="/app/helpdesk" onClick={this.props.handleEditItem}>{user_c.i18n.t('PRIVATE.Header.helpdesk')}</Button>
                  </Col>
                  
                  <Col xs={4} md={4}>          
                    <Button variant="outline-secondary" size="sm" block name="/app/profile" onClick={this.props.handleEditItem}>{user_c.i18n.t('PRIVATE.Header.user')}</Button>
                    <Button variant="danger" size="sm" block onClick={this.props.handleLogOut}>{user_c.i18n.t('PRIVATE.Header.logout')}</Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Grid>
    );
  }
}

class FooterViseeon extends React.Component{
  static contextType = UserContext
  render(){
    let user_c= this.context
    return(
      <Row middle="xs" >
        <Col lg={3} md={4} xs={12}>
          <h6>VISEEON PLAN is a special edition of CASFLO APP for Viseeon Portugal</h6>
        </Col>
        <Col lg={5} md={4} xs={12}> </Col>
        <Col lg={2} md={2} xs={12}>
          <h6>CASFLO APP: made in Portugal </h6>
          <Image src={PT} className="footer_flag"/>
          <Image src={EU} className="footer_flag"/>
        </Col>
        <Col lg={2} md={2} xs={12}>
          <h6><a href="https://casflo-app.com/terms-of-service/" target="blank">{user_c.i18n.t('PRIVATE.Header.terms')}</a></h6>
        </Col>   
      </Row>
    )
  }
}

export {
  HeaderViseeon,
  FooterViseeon
}