import {dotMultiply, add,dotDivide} from 'mathjs';

function CorrectArray(input) {
  let arr=input
  for(var i=0;i<arr.length;i++){
    if(!Number.isFinite(arr[i])){
      arr[i]=0
    }
  }
  return arr
}

const calculateHistoric= (c,vat)=>{
  let vatter=1+vat/100
  c.h_inc_st.h_ebitda=add(
    c.h_inc_st.h_revenue,
    c.h_inc_st.h_operational_grants,
    dotMultiply(-1,c.h_inc_st.h_cogs),
    dotMultiply(-1,c.h_inc_st.h_operating_expenses),
    dotMultiply(-1,c.h_inc_st.h_hr),
    c.h_inc_st.h_other_gains,
    dotMultiply(-1,c.h_inc_st.h_other_costs)
  )
  c.h_inc_st.h_ebit=add(
    c.h_inc_st.h_ebitda,
    dotMultiply(-1,c.h_inc_st.h_depreciations)
  )
  c.h_inc_st.h_ebt=add(
    c.h_inc_st.h_ebit,
    dotMultiply(-1,c.h_inc_st.h_interest_cost)
  )
  c.h_inc_st.h_net_profit=add(
    c.h_inc_st.h_ebt,
    dotMultiply(-1,c.h_inc_st.h_corporate_tax)
  )
  c.h_bal.h_total_non_current_assets=add(
    c.h_bal.h_tangible_assets,
    c.h_bal.h_intangible_assets,
    c.h_bal.h_other_non_current_assets,
  )
  c.h_bal.h_total_current_assets=add(
    c.h_bal.h_inventories,
    c.h_bal.h_clients,
    c.h_bal.h_government_receivable,
    c.h_bal.h_other_current_assets,
    c.h_bal.h_cash,
  )
  c.h_bal.h_total_assets=add(
    c.h_bal.h_total_current_assets,
    c.h_bal.h_total_non_current_assets
  )
  c.h_bal.h_total_equity=add(
    c.h_bal.h_issued_capital,
    c.h_bal.h_other_equity_instruments,
    c.h_bal.h_other_equity_changes,
    c.h_bal.h_past_results,
    c.h_inc_st.h_net_profit,
  )
  c.h_bal.h_total_non_current_liabilities=add(
    c.h_bal.h_debt,
    c.h_bal.h_other_non_current_liabilities
  )
  c.h_bal.h_total_current_liabilities=add(
    c.h_bal.h_suppliers,
    c.h_bal.h_shareholders_loan,
    c.h_bal.h_government_payable,
    c.h_bal.h_other_accounts_payables,
    c.h_bal.h_other_current_liabilities,
  )
  c.h_bal.h_total_liabilities=add(
    c.h_bal.h_total_non_current_liabilities,
    c.h_bal.h_total_current_liabilities
  )
  c.h_bal.h_equity_liabilities=add(
    c.h_bal.h_total_equity,
    c.h_bal.h_total_liabilities
  )
  c.h_ratios.h_gross_margin=CorrectArray(dotDivide(add(dotMultiply(-1,c.h_inc_st.h_cogs),c.h_inc_st.h_revenue),c.h_inc_st.h_revenue))
  c.h_ratios.h_net_margin=CorrectArray(dotDivide(c.h_inc_st.h_net_profit,c.h_inc_st.h_revenue))
  c.h_ratios.h_cogs_rate=CorrectArray(dotDivide(c.h_inc_st.h_cogs,c.h_inc_st.h_revenue))
  c.h_ratios.h_dar=CorrectArray(dotDivide(dotMultiply(365,c.h_bal.h_clients),dotMultiply(c.h_inc_st.h_revenue,vatter)))
  c.h_ratios.h_dap=CorrectArray(dotDivide(dotMultiply(365,c.h_bal.h_suppliers),dotMultiply(add(c.h_inc_st.h_cogs,c.h_inc_st.h_operating_expenses),vatter)))
  c.h_ratios.h_dos=CorrectArray(dotDivide(dotMultiply(365,c.h_bal.h_inventories),c.h_inc_st.h_cogs))
  c.h_ratios.h_ros=CorrectArray(dotDivide(c.h_inc_st.h_ebit,c.h_inc_st.h_revenue))
  c.h_ratios.h_roa=CorrectArray(dotDivide(add(c.h_inc_st.h_ebit,dotMultiply(c.h_inc_st.h_interest_cost,-1)),c.h_bal.h_total_assets))
  c.h_ratios.h_roe=CorrectArray(dotDivide(c.h_inc_st.h_ebit,c.h_bal.h_total_equity))
  c.h_ratios.h_equity_ratio=CorrectArray(dotDivide(c.h_bal.h_total_equity,c.h_bal.h_total_assets))
  c.h_ratios.h_solvency=CorrectArray(dotDivide(c.h_bal.h_total_equity,c.h_bal.h_total_liabilities))
  c.h_ratios.h_liquidity=CorrectArray(dotDivide(c.h_bal.h_total_current_assets,c.h_bal.h_total_current_liabilities))
  c.h_ratios.h_average_cost_of_debt=CorrectArray(dotDivide(c.h_inc_st.h_interest_cost,c.h_bal.h_debt))
  c.h_ratios.h_interest_coverage=CorrectArray(dotDivide(c.h_inc_st.h_ebit,c.h_inc_st.h_interest_cost))
  c.h_ratios.h_depreciation_rate=CorrectArray(dotDivide(c.h_inc_st.h_depreciations,add(add(c.h_bal.h_tangible_assets,c.h_bal.h_intangible_assets),c.h_inc_st.h_depreciations)))
  c.h_ratios.h_corporate_tax_rate=CorrectArray(dotDivide(c.h_inc_st.h_corporate_tax,c.h_inc_st.h_ebt))
  return c
};

const calculateDetailedHistoric = (c,vat)=>{
  function adder(element){
    const sizer = [0,0,0]

    let totalizer=sizer
    const len = element.length
    if(len>0){
      for (var i=0; i<len; i++){
        totalizer=add(totalizer, element[i].value)
      }  
    }
  return totalizer
  }
  //income statement builder
  c.h_inc_st.h_revenue=adder(c.h_revs)
  c.h_inc_st.h_operating_expenses=adder(c.h_op_exps)
  c.h_inc_st.h_hr=adder(c.h_hrs)
  c.h_inc_st.h_other_gains=adder(c.h_ogs)
  c.h_inc_st.h_other_costs=adder(c.h_ocs)
  //balance
  c.h_bal.h_tangible_assets=adder(c.h_tas)
  c.h_bal.h_intangible_assets=adder(c.h_ias)
  c.h_bal.h_other_non_current_assets=adder(c.h_onca)
  c.h_bal.h_other_current_assets=adder(c.h_oca)
  c.h_bal.h_issued_capital=adder(c.h_isc)
  c.h_bal.h_other_equity_instruments=adder(c.h_oci)
  c.h_bal.h_other_equity_changes=adder(c.h_oec)
  c.h_bal.h_other_non_current_liabilities=adder(c.h_oncl)
  c.h_bal.h_shareholders_loan=adder(c.h_shl)
  c.h_bal.h_other_current_liabilities=adder(c.h_ocl)

  return calculateHistoric(c,vat)
}


const calculateVariableForVersion=(c,bas,years)=>{
  const lgt=years.length
  let bug={}
  bug.cogs_rate=new Array(lgt).fill(dotDivide(c.h_inc_st.h_cogs[bas],c.h_inc_st.h_revenue[bas]))
  bug.days_accounts_receivables=new Array(lgt).fill(dotDivide(dotMultiply(365,c.h_bal.h_clients[bas]),c.h_inc_st.h_revenue[bas]))
  bug.days_accounts_payables=new Array(lgt).fill(dotDivide(dotMultiply(365,c.h_bal.h_suppliers[bas]),add(c.h_inc_st.h_cogs[bas],c.h_inc_st.h_operating_expenses[bas])))
  bug.investment_days_accounts_payables=new Array(lgt).fill(dotDivide(dotMultiply(365,c.h_bal.h_suppliers[bas]),add(c.h_inc_st.h_cogs[bas],c.h_inc_st.h_operating_expenses[bas])))
  return bug
}

const calculateHistoricRevs=(go)=>{
  go.h_revs_total=[0,0,0]
  for(var i=0;i<go.h_revs.length;i++){
    go.h_revs[i]._rev_total_revenue=dotMultiply(dotMultiply(go.h_revs[i].rev_quantity_type,go.h_revs[i].rev_quantity),go.h_revs[i].rev_price)
    go.h_revs_total=add(go.h_revs_total,go.h_revs[i]._rev_total_revenue)
  }
  return go
}

export{
  calculateHistoric,
  calculateDetailedHistoric,
  calculateVariableForVersion,
  calculateHistoricRevs
}
