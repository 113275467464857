import axios from 'axios';
import Session from 'CONFIG/session';

const ACCESS_POINT=process.env.REACT_APP_ACCESS_POINT

const ApiConnect = axios.create({
    baseURL: ACCESS_POINT,
    timeout: 0,
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
      'Access-Control-Allow-Methods': 'PUT,GET,POST,DELETE,OPTIONS',
      'Authorization': Session.getToken()
    }
  }
)

ApiConnect.interceptors.request.use (
  function (config) {
    config.headers.Authorization =  Session.getToken()
    return config;
  },
  function (error) {
    return Promise.reject (error);
  }
);

const ApiPublicConnect = axios.create({
  baseURL: ACCESS_POINT,
  timeout: 0,
  headers: {
    'Content-Type': 'application/json; charset=UTF-8',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Methods': 'PUT,GET,POST,DELETE,OPTIONS',
    'Authorization': Session.getToken()
  }
})

export default ApiConnect;
export{ApiPublicConnect}