import React from 'react';
import { Row, Col  } from 'Support/grid'


import UserContext from 'CONFIG/auth';
import ApiConnect from 'CONFIG/axios';
import Loader from 'Support/loader';
import { DeleteButton, AddButton, RegularButtonSmall } from 'Support/listbuttons';
import {RangeSlider,SingleInputLargeForm} from 'Support/inputforms';
import {DetailedCard } from 'Support/warning_cards';

class PestelMain extends React.Component{
  static contextType = UserContext

  constructor(props) {
    super(props);
      this.state = {
      data: "",
      canva_id:""
    }
    this.handleAddItem=this.handleAddItem.bind(this)
    this.handleDelete=this.handleDelete.bind(this)
    this.handleEditItem=this.handleEditItem.bind(this)
    this.handleRangeSlider=this.handleRangeSlider.bind(this)
    this.handleSave=this.handleSave.bind(this)
  }

  componentDidMount(){
    this.updataData()
  }

  updataData=async()=>{
    const request={
      project_id: this.props.project_id,
      item_type: "pestel" 
    }
    try{
      const response = await ApiConnect.post('/api/project/canva/get', request)
      this.setState({
        data:response.data.item,
        canva_id:response.data.canva_id,
        loaded:true
      })
    }catch(error) {
      alert(error);
    }
  }

  handleAddItem(event){
    const value=event.target.value
    let go= this.state.data
    if(go===undefined){
      go={
        'political':[],
        'economic':[],
        'social':[],
        'technological':[],
        'environmental':[],
        'legal':[],
      }
    }
    go[value].push({
      descr:'',
      impact:0
    })
    this.setState({
      data:go
    })
  }

  handleDelete(event){
    let go= this.state.data
    go[event.target.name].splice(event.target.value,1)
    this.setState({
      data:go
    })
  }

  handleEditItem(event){
    let go= this.state.data
    go[event.target.name][event.target.id].descr=event.target.value
    this.setState({
      data:go
    })
  }

  handleRangeSlider(event){
    let go= this.state.data
    go[event.target.name][event.target.id].impact=event.target.value
    this.setState({
      data:go
    })
  }

  handleSave=async()=>{
    const request={
      canva_id: this.state.canva_id,
      item_type: "pestel",
      content:this.state.data 
    }
    try{
      const response = await ApiConnect.post('/api/project/canva/modify', request)
      this.props.activateAlert('ok_auto','Saved','', this.context)
    }catch(error) {
      alert(error);
    }
  }

  render(){
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_PROJECT.PROJECT_CANVA.PESTEL')

    const props ={
      trsl:trsl,
      data:this.state.data,
      p_name:this.props.project_data.project_name,
      user_permission:this.props.user_permission,
      template:user_c.template,
      handleAddItem:this.handleAddItem,
      handleDelete:this.handleDelete,
      handleEditItem:this.handleEditItem,
      handleRangeSlider:this.handleRangeSlider,
      handleSave:this.handleSave
    }
    function torender(data, i){
      return data[i];
      }  
    const pages ={
    true: 
    <ToDisplay {...props} />,
    false: <Loader loader_message="Let's get hands on the job!"/>
    }
    var i = this.state.loaded
    const renderizing = torender(pages,i)

    return(
      <div>
        {renderizing}
      </div>
    )
  }
}


class ToDisplay extends React.Component{
  render(){
    const props=this.props
    return(
      <div>
        <Header {...props} />
          <Row>
            <Col xs={12}md={9}>
              <Row className="canva_swot_swot_rows">
                <Col md={12} >
                  <PestelFields label={this.props.trsl['political']} element="political" {...props}/>
                </Col>
              </Row>
              <Row className="canva_swot_swot_rows">
                <Col md={12} >
                  <PestelFields label={this.props.trsl['economic']} element="economic" {...props}/>
                </Col>
              </Row>
              <Row className="canva_swot_swot_rows">
                <Col md={12} >
                  <PestelFields label={this.props.trsl['social']} element="social" {...props}/>
                </Col>
              </Row>
              <Row className="canva_swot_swot_rows">
                <Col md={12}>
                  <PestelFields label={this.props.trsl['technological']} element="technological" {...props}/>
                </Col>
              </Row>
              <Row className="canva_swot_swot_rows">
                <Col md={12}>
                  <PestelFields label={this.props.trsl['legal']} element="legal" {...props}/>
                </Col>
              </Row>
              <Row className="canva_swot_swot_rows">
                <Col md={12}>
                  <PestelFields label={this.props.trsl['environmental']} element="environmental" {...props}/>
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={3}>
                <h5>{this.props.trsl['legend']}</h5>
                <p>{this.props.trsl['hn']}: {this.props.trsl['hn_label']}</p>
                <p>{this.props.trsl['mn']}: {this.props.trsl['mn_label']}</p>
                <p>{this.props.trsl['ln']}: {this.props.trsl['ln_label']}</p>
                <p>{this.props.trsl['ni']}: {this.props.trsl['ni_label']}</p>
                <p>{this.props.trsl['lp']}: {this.props.trsl['lp_label']}</p>
                <p>{this.props.trsl['mp']}: {this.props.trsl['mp_label']}</p>
                <p>{this.props.trsl['hp']}: {this.props.trsl['hp_label']}</p>
            </Col>
          </Row>
      </div>
    );
  }
}

class Header extends React.Component{
  render(){
    let permission=true
    if(this.props.user_permission=="owner" || this.props.user_permission=="edit" ){
      permission= false
    }
    return(
      <div>
        <Row className="canva_header_row grid_middle">
          <Col xs={9}>
            <h4>{this.props.trsl['pestel']}</h4>
          </Col>
          <Col xs={3}>
            <RegularButtonSmall bk="bk_success" label={this.props.trsl['save_button']} onClick={this.props.handleSave} disabled={permission}/>
          </Col>
        </Row>
      </div>
    )
  }
}

class PestelFields extends React.Component{
  render(){
    let item_holder=[]
    if(this.props.data!==undefined){
      item_holder=this.props.data[this.props.element]
    }
    let item_distr=""
    if(item_holder!==undefined && item_holder.length>0){
      item_distr=item_holder.map((value,key)=>
        <PestelForm 
        trsl={this.props.trsl}
        element={this.props.element} 
        item={key} 
        value={value}
        handleDelete={this.props.handleDelete} 
        handleEditItem={this.props.handleEditItem}
        handleRangeSlider={this.props.handleRangeSlider} />
      )
    }

    return(
      <DetailedCard color='darkaccent'>
        <DetailedCard.Header color='darkaccent'>
          <Row>
            <Col xs={10}>
              <h5>{this.props.label}</h5>
            </Col>
            <Col xs={2} className="canva_align_right">
              <AddButton value={this.props.element} handleAddItem={this.props.handleAddItem} />
            </Col>
          </Row>
        </DetailedCard.Header>
        <DetailedCard.Body>
          <Col xs={12}>
            {item_distr}
          </Col>
        </DetailedCard.Body>
      </DetailedCard>
    );
  }
}

function PestelForm (props){
  const impact_level_options=[
    {value:-3,label:props.trsl['hn']},
    {value:-2,label:props.trsl['mn']},
    {value:-1,label:props.trsl['ln']},
    {value:0,label:props.trsl['ni']},
    {value:1,label:props.trsl['lp']},
    {value:2,label:props.trsl['mp']},
    {value:3,label:props.trsl['hp']},
  ]

  const mutate=props.item.toString()

  const handleSlider=(e)=>{
    const event=
    {
      target:{
        id:mutate,
        name:props.element,
        value:e.target.value
      }
    }
    
    props.handleRangeSlider(event)
  }

  return(
    <div className="canva_pestel_box blanktop_05">
      <Row >
        <Col xs={12} md={7}>
          <SingleInputLargeForm label={props.trsl['insert_text']} rows={1} value={props.value.descr} name={props.element} id={mutate} handleEditItemChange={props.handleEditItem}/>
        </Col>
        <Col xs={8} md={4}>
          <RangeSlider md={12} label={props.trsl['impact_level']} options={impact_level_options} min_range={-3}max_range={3} value={props.value.impact} onChange={handleSlider}/>
        </Col>
        <Col xs={4}md={1} className="canva_align_right blanktop_075">
          <DeleteButton name={props.element} value={props.item} handleDelete={props.handleDelete}/>
        </Col>

      </Row>
    </div>
  )
}

export default PestelMain;