import React, {useState} from 'react';
import { Image, Modal, Alert, Tooltip,OverlayTrigger} from 'react-bootstrap';
import UserContext from 'CONFIG/auth';

import user_img from 'images/useful/user.svg'
import sign_out_img from 'images/useful/sign-out-alt-solid.svg'
import delete_img from 'images/useful/delete.svg';
import duplicate_img from '../images/useful/duplicate.svg';
import edit_img from 'images/useful/edit.svg';
import delete_user_img from '../images/useful/remove_user.svg';
import up_img from '../images/useful/up.svg';
import down_img from '../images/useful/down.svg';
import add_img from '../images/useful/add.svg';
import view_img from '../images/useful/view.svg';
import check_img from '../images/useful/check.svg';
import expand_img from '../images/useful/expand.svg';
import expand_menu from '../images/useful/expand-arrows-alt-solid.svg'
import question_img from '../images/useful/question.svg';
import back_img from '../images/useful/back.svg';
import home_img from '../images/useful/home-solid.svg';

import './CSS/support.css'

function Button(props){
  const runEdit=()=>{ 
    const event={
      target:{
        value:props.value,
        name:props.name
      }
    }
    props.onClick(event)
  }
  return(
    <button disabled={props.disabled} className={`spt_regular_button ${props.bk}`} onClick={runEdit} >
      {props.label}
    </button>
  )
}

function RegularButton(props){
  const runEdit=()=>{ 
    const event={
      target:{
        value:props.value,
        name:props.name
      }
    }
    props.onClick(event)
  }
  return(
    <button disabled={props.disabled} className={`spt_regular_button bk_darkaccent`} onClick={runEdit} >
      {props.label}
    </button>
  )
}

function RegularButtonSmall(props){
  const runEdit=(a)=>{ 
    a.preventDefault()
    const event={
      target:{
        value:props.value,
        name:props.name,
        id:props.id
      }
    }
    props.onClick(event)
  }
  return(
    <button disabled={props.disabled} className={`spt_regular_button spt_regular_small ${props.bk}`} onClick={runEdit} >
      {props.label}
    </button>
  )
}

function RegularButtonFullHeight(props){
  const runEdit=()=>{ 
    const event={
      target:{
        value:props.value,
        name:props.name
      }
    }
    props.onClick(event)
  }
  return(
    <button disabled={props.disabled} className={`spt_regular_button spt_regular_full_height ${props.bk}`} onClick={runEdit} >
      {props.label}
    </button>
  )
}

function RegularLinkButton(props){
  return(
      <button disabled={props.disabled} className={`spt_regular_button ${props.bk}`} onClick={(e) => {e.preventDefault();
        window.location.href=props.href}} >
        {props.label}
      </button>

  )
}

function ActivateButtonPositiveNegative(props){
  const runEdit=()=>{ 
    const event={
      target:{
        value:props.value,
        name:props.name
      }
    }
    props.onClick(event)
  }

  const styles = {
    false:'spt_button_activate_inside_not_active',
    true:'spt_button_activate_inside_active'
  }

  return(
    <div className={`spt_button_activate`} onClick={runEdit} >
      <div className={styles[props.value]}>{props.label}</div>
    </div>
  )
}


function LogOutButton(props){
  const runEdit=()=>{
    const event={
      target:{
        value:props.value,
        name:props.name
      }
    }
    props.onClick(event)
  }
  return(
    <div onClick={runEdit}>
      <img src={sign_out_img} className='spt_hd_btn_img bk_danger'/>
    </div>
  )
}

class EditButton extends React.Component{
  static contextType = UserContext
  renderTooltip = (props) => {
    return (
      <Tooltip {...props}>
        {this.context.i18n.t('LIST_BUTTONS.edit')}
      </Tooltip>
      )
    }

  render(){
    const namer=(input)=>{
      if(input!==undefined){return input} else{return "edit"}
    }

    const handleEvent=()=>{
      const event={
        target:{
          name:namer(this.props.name),
          value:this.props.value
        }
      }
      this.props.handleEditItem(event)
    }

    return(
      <OverlayTrigger
      delay={{ show: 250, hide: 250 }}
      overlay={this.renderTooltip}
      >
        <button className="listbutton_regular_restricted_height bk_darkaccent" 
        onClick={handleEvent}
        disabled={this.props.permission}>
        <img className="listbutton_regular_img" src={edit_img}/>
        </button>
      </OverlayTrigger>
    );
  }
}

class EditVersionButton extends React.Component{
  static contextType = UserContext
  renderTooltip = (props) => {
    return (
      <Tooltip {...props}>
        {this.context.i18n.t('LIST_BUTTONS.edit_version')}
      </Tooltip>
      )
    }

  render(){
    const namer=(input)=>{
      if(input!==undefined){return input} else{return "edit"}
    }

    let disabled=true
    if(this.props.permission=="owner" || this.props.permission=="edit"){
      disabled= false
    }

    const handleEvent=()=>{
      const event={
        target:{
          name:namer(this.props.name),
          value:this.props.value
        }
      }
      this.props.handleEditItem(event)
    }

    return(
      <OverlayTrigger
      delay={{ show: 250, hide: 250 }}
      overlay={this.renderTooltip}
      >
        <button className="listbutton_regular_restricted_height bk_darkaccent"
        onClick={handleEvent}
        disabled={disabled}>
          <img className="listbutton_regular_img" 
          src={edit_img}/>
        </button>
      </OverlayTrigger>
    );
  }
}

class EditProjectButton extends React.Component{
  static contextType = UserContext
  renderTooltip = (props) => {
    return (
      <Tooltip {...props}>
        {this.context.i18n.t('LIST_BUTTONS.edit_project')}
      </Tooltip>
      )
    }

  render(){
    const namer=(input)=>{
      if(input!==undefined){return input} else{return "edit"}
    }

    const handleEvent=()=>{
      const event={
        target:{
          name:namer(this.props.name),
          value:this.props.value
        }
      }
      this.props.handleEditItem(event)
    }

    return(
      <OverlayTrigger
      delay={{ show: 250, hide: 250 }}
      overlay={this.renderTooltip}
      >
        <button className="listbutton_regular_restricted_height bk_darkaccent" onClick={handleEvent} disabled={this.props.permission}>
          <img className="listbutton_regular_img"  src={edit_img}/>
        </button>
      </OverlayTrigger>
    );
  }
}

class DeleteButton extends React.Component{
  static contextType = UserContext
  renderTooltip = (props) => {
    return (
      <Tooltip {...props}>
        {this.context.i18n.t('LIST_BUTTONS.delete')}
      </Tooltip>
    )
  }

  render(){
    const handleEvent=(event)=>{
      event={
        target:{
          name:this.props.name,
          value:this.props.value
        }
      }
      this.props.handleDelete(event)
    }

    return(
      <OverlayTrigger delay={{ show: 250, hide: 250 }} overlay={this.renderTooltip}>
        <button className="listbutton_regular_restricted_height bk_danger" onClick={handleEvent} disabled={this.props.permission}>
          <img className="listbutton_regular_img"  src={delete_img}/>
        </button>
      </OverlayTrigger>
  );
  }
}

function DeleteProjectButton (props){
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const renderTooltip = (props) => {
    return (
      <UserContext.Consumer>
         {({i18n}) => (
          <Tooltip {...props}>
            {i18n.t('LIST_BUTTONS.delete_project')}
          </Tooltip>
         )}
      </UserContext.Consumer>
      )
    }

  const handleEvent=()=>{
    const event={
      target:{
        name:"delete",
        value:props.value
      }
    }
    props.handleDelete(event)
    handleClose()
  }

  return(
    <>
      <OverlayTrigger delay={{ show: 250, hide: 250 }} overlay={renderTooltip}>
        <button className="listbutton_regular_restricted_height bk_danger" onClick={handleShow} disabled={props.permission}>
          <img className="listbutton_regular_img" src={delete_img} onClick="" />
        </button>
      </OverlayTrigger>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure you want to delete?</Modal.Title>
        </Modal.Header>
        <Modal.Body>If you delete, it will not be possible to recover this project</Modal.Body>
        <Modal.Footer>
          <RegularButton label='Yes, I am sure.' onClick={handleEvent}/>
          <RegularButton label='No, I do not want to delete the project' onClick={handleClose}/>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function DeleteVersionButton (props){
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Delete Version
    </Tooltip>
  )

  const handleEvent=()=>{
    const event={
      target:{
        name:"delete",
        value:props.value
      }
    }
    props.handleDelete(event)
    handleClose()
  }

  let disabled=true
  if(props.permission=="owner" || props.permission=="edit"){
    disabled= false
  }

  return(
    <>
    <OverlayTrigger delay={{ show: 250, hide: 250 }} overlay={renderTooltip}>
      <button className="listbutton_regular_restricted_height bk_danger"  onClick={handleShow} disabled={disabled}>
        <img className="listbutton_regular_img"  src={delete_img} />
      </button>
    </OverlayTrigger>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure you want to delete?</Modal.Title>
        </Modal.Header>
        <Modal.Body>If you delete, it will not be possible to recover the contents of this version</Modal.Body>
        <Modal.Footer>
          <RegularButton label='Yes, I am sure.' onClick={handleEvent}/>
          <RegularButton label='No, I do not want to delete the version' onClick={handleClose} />
        </Modal.Footer>
      </Modal>
    </>
  );
}

class DeleteUserButton extends React.Component{
  static contextType = UserContext
  renderTooltip = (props) => {
    return (
      <Tooltip {...props}>
        {this.context.i18n.t('LIST_BUTTONS.delete_user')}
      </Tooltip>
    )
  }

  render(){
    const handleEvent=()=>{
      const event={
        target:{
          name:"delete",
          value:this.props.value
        }
      }
      this.props.handleDelete(event)
    }
    
    return(
      <OverlayTrigger delay={{ show: 250, hide: 250 }} overlay={this.renderTooltip}>
        <button className="listbutton_regular bk_danger" onClick={handleEvent} >
          <Image 
          fluid 
          src={delete_user_img}        
          onClick={handleEvent}/>
        </button>
      </OverlayTrigger>
    );
  }
}

class DuplicateButton extends React.Component{
  static contextType = UserContext
  renderTooltip = (props) => {
    return (
      <Tooltip {...props}>
        {this.context.i18n.t('LIST_BUTTONS.duplicate')}
      </Tooltip>
    )
  }

  render(){
    const handleEvent=()=>{
      const event={
        target:{
          name:this.props.name,
          value:this.props.value
        }
      }
      this.props.handleDuplicate(event)
    }
    return(
      <OverlayTrigger delay={{ show: 250, hide: 250 }} overlay={this.renderTooltip}>
        <button className="listbutton_regular_restricted_height bk_warning"
        onClick={handleEvent}
        disabled={this.props.permission}>
        <img className="listbutton_regular_img" src={duplicate_img} />
        </button>
      </OverlayTrigger>
    );
  }
}

class DuplicateVersionButton extends React.Component{
  static contextType = UserContext
  renderTooltip = (props) => {
    return (
      <Tooltip {...props}>
        {this.context.i18n.t('LIST_BUTTONS.duplicate_version')}
      </Tooltip>
    )
  }

  render(){
    const handleEvent=()=>{
      const event={
        target:{
          name:"main",
          value:this.props.value
        }
      }
      this.props.handleDuplicate(event)
    }

    let disabled=true
    if(this.props.permission=="owner" || this.props.permission=="edit"){
      disabled= false
    }

    return(
      <OverlayTrigger delay={{ show: 250, hide: 250 }} overlay={this.renderTooltip}>
        <button className="listbutton_regular_restricted_height bk_warning" onClick={handleEvent} disabled={disabled}>
          <img className="listbutton_regular_img" src={duplicate_img}/>
        </button>
      </OverlayTrigger>
    );
  }
}

class UpButton extends React.Component{
  static contextType = UserContext
  renderTooltip = (props) => {
    return (
      <Tooltip {...props}>
        {this.context.i18n.t('LIST_BUTTONS.up')}
      </Tooltip>
    )
  }

  render(){
    const handleEvent=()=>{
      const event={
        target:{
          name:"up",
          value:this.props.value
        }
      }
      this.props.onClick(event)
    }
    return(
      <OverlayTrigger delay={{ show: 250, hide: 250 }} overlay={this.renderTooltip}>
        <button className="listbutton_regular_restricted_height bk_info" onClick={handleEvent} disabled={this.props.permission}>
          <img className="listbutton_regular_img" src={up_img} />
        </button>
      </OverlayTrigger>
    );
  }
}

class DownButton extends React.Component{
  static contextType = UserContext
  renderTooltip = (props) => {
    return (
      <Tooltip {...props}>
        {this.context.i18n.t('LIST_BUTTONS.down')}
      </Tooltip>
    )
  }

  render(){
    const handleEvent=()=>{
      const event={
        target:{
          name:"up",
          value:this.props.value
        }
      }
      this.props.onClick(event)
    }
    return(
      <OverlayTrigger delay={{ show: 250, hide: 250 }} overlay={this.renderTooltip}>
        <button className="listbutton_regular_restricted_height bk_info" onClick={handleEvent} disabled={this.props.permission}>
          <img className="listbutton_regular_img" src={down_img} />
        </button>
      </OverlayTrigger>
    );
  }
}

class AccessButton extends React.Component{
  static contextType = UserContext
  renderTooltip = (props) => {
    return (
      <Tooltip {...props}>
        {this.context.i18n.t('LIST_BUTTONS.access')}
      </Tooltip>
    )
  }
  render(){
    const handleEvent=()=>{
      const event={
        target:{
          name:this.props.name,
          value:this.props.value
        }
      }
      this.props.handleEditItem(event)
    }
    return(
      <OverlayTrigger delay={{ show: 250, hide: 250 }} overlay={this.renderTooltip}>
        <button className="listbutton_regular"
        variant="primary"
        size="sm" 
        onClick={handleEvent}>
          
          <access_img />       
        </button>
      </OverlayTrigger>
    );
  }
}

class AddButton extends React.Component{
  static contextType = UserContext
  renderTooltip = (props) => {
    return (
      <Tooltip {...props}>
        {this.context.i18n.t('LIST_BUTTONS.add')}
      </Tooltip>
    )
  }
  render(){
    const handleEvent=()=>{
      const event={
        target:{
          name:this.props.name,
          value:this.props.value
        }
      }
      this.props.handleAddItem(event)
    }
    return(
      <OverlayTrigger delay={{ show: 250, hide: 250 }} overlay={this.renderTooltip}>
        <button className="listbutton_regular_restricted_height bk_primary"  onClick={handleEvent}>
          <img className="listbutton_regular_img" src={add_img} />          
        </button>
      </OverlayTrigger>
    );
  }
}

class OKButton extends React.Component{
  static contextType = UserContext
  renderTooltip = (props) => {
    return (
      <Tooltip {...props}>
        {this.context.i18n.t('LIST_BUTTONS.add')}
      </Tooltip>
    )
  }
  render(){
    const handleEvent=(event)=>{
      event={
        target:{
          name:this.props.name,
          value:this.props.value
        }
      }
      this.props.handleOK(event)
    }
    return(
      <OverlayTrigger delay={{ show: 250, hide: 250 }} overlay={this.renderTooltip}>
        <button className="listbutton_regular"
        variant="primary"
        size="sm" 
        onClick={handleEvent}
        value={this.props.value}
        name="ok">
          <Image 
          fluid 
          src={check_img}    
          /> 
        </button>
      </OverlayTrigger>
    );
  }
}

class ExpandButton extends React.Component{
  static contextType = UserContext
  renderTooltip = (props) => {
    return (
      <Tooltip {...props}>
        {this.context.i18n.t('LIST_BUTTONS.expand_sub')}
      </Tooltip>
    )
  }
  renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Expand subscription
    </Tooltip>
  )
  render(){
    const handleEvent=()=>{
      const event={
        target:{
          name:"expand",
          value:this.props.value,
          id:this.props.id
        }
      }
      this.props.handleEditItem(event)
    }

    return(
      <OverlayTrigger delay={{ show: 250, hide: 250 }} overlay={this.renderTooltip}>
        <button className="listbutton_regular_restricted_height bk_warning" onClick={handleEvent}>
        <img className="listbutton_regular_img" 
          src={expand_img}/>
        </button>
      </OverlayTrigger>
    );
  }
}

class ViewButton extends React.Component{
  static contextType = UserContext
  renderTooltip = (props) => {
    return (
      <Tooltip {...props}>
        {this.context.i18n.t('LIST_BUTTONS.view_sub')}
      </Tooltip>
    )
  }
  render(){
    const handleEvent=()=>{
      const event={
        target:{
          name:"view",
          value:this.props.value
        }
      }
      this.props.handleEditItem(event)
    }

    return(
      <OverlayTrigger delay={{ show: 250, hide: 250 }} overlay={this.renderTooltip}>
        <button className="listbutton_regular_restricted_height bk_darkaccent" onClick={handleEvent}>
          <img className="listbutton_regular_img" src={view_img}/>
        </button>
      </OverlayTrigger>
    );
  }
}

class ViewProjectButton extends React.Component{
  static contextType = UserContext
  renderTooltip = (props) => {
    return (
      <Tooltip {...props}>
        {this.context.i18n.t('LIST_BUTTONS.edit_project')}
      </Tooltip>
      )
    }

  render(){
    const namer=(input)=>{
      if(input!==undefined){return input} else{return "edit"}
    }

    const handleEvent=()=>{
      const event={
        target:{
          name:namer(this.props.name),
          value:this.props.value
        }
      }
      this.props.handleEditItem(event)
    }

    return(
      <OverlayTrigger
      delay={{ show: 250, hide: 250 }}
      overlay={this.renderTooltip}
      >
        <button className="listbutton_regular_restricted_height bk_darkaccent"
        onClick={handleEvent}
        disabled={this.props.permission}>
        <img className="listbutton_regular_img" 
          src={view_img}    
          onClick=""/>
        </button>
      </OverlayTrigger>
    );
  }
}

class ViewButtonForObserver extends React.Component{
  static contextType = UserContext
  renderTooltip = (props) => {
    return (
      <Tooltip {...props}>
        {this.context.i18n.t('LIST_BUTTONS.view')}
      </Tooltip>
    )
  }
  render(){
    const handleEvent=()=>{
      const event={
        target:{
          name:"view",
          value:this.props.value
        }
      }
      this.props.handleEditItem(event)
    }

    return(
      <OverlayTrigger delay={{ show: 250, hide: 250 }} overlay={this.renderTooltip}>
        <button className="listbutton_regular_restricted_height bk_primary" onClick={handleEvent}>
          <img className="listbutton_regular_img"  src={view_img} />
        </button>
      </OverlayTrigger>
    );
  }
}

class ViewButtonForVersion extends React.Component{
  static contextType = UserContext
  renderTooltip = (props) => {
    return (
      <Tooltip {...props}>
        {this.context.i18n.t('LIST_BUTTONS.view')}
      </Tooltip>
    )
  }
  render(){
    const namer=(input)=>{
      if(input!==undefined){return input} else{return "edit"}
    }

    const handleEvent=()=>{
      const event={
        target:{
          name:namer(this.props.name),
          value:this.props.value
        }
      }
      this.props.onClick(event)
    }

    const showViewVersionButton=(show,handleEvent)=>{
      if(show==='view'){
        return(
          <OverlayTrigger delay={{ show: 250, hide: 250 }} overlay={this.renderTooltip}>
            <button className="listbutton_regular_restricted_height bk_primary" onClick={handleEvent}>
              <img className="listbutton_regular_img" src={view_img} />
            </button>
          </OverlayTrigger>
        )
      } else{return ""}
    }
    const render_view_version_button=showViewVersionButton(this.props.permission,handleEvent)

    return(
      <>
        {render_view_version_button}
      </>
    );
  }
}


class QuestionButton extends React.Component{
  static contextType = UserContext
  renderTooltip = (props) => {
    return (
      <Tooltip {...props}>
        {this.context.i18n.t('LIST_BUTTONS.question')}
      </Tooltip>
    )
  }
  
  render(){
    return(
        <button className="listbutton_regular_restricted_height bk_primary" 
        target="_blank"
        href={this.props.value}
        name="ok">
        <img className="listbutton_regular_img" 
          src={question_img}    
          name="ok"
          /> 
        </button>
      
    )
  }
}

class BackButton extends React.Component{
  static contextType = UserContext
  renderTooltip = (props) => {
    return (
      <Tooltip {...props}>
        {this.context.i18n.t('LIST_BUTTONS.back_project')}
      </Tooltip>
    )
  }
  render(){
    const namer=(input)=>{
      if(input!==undefined){return input} else{return "edit"}
    }
    const handleEvent=()=>{
      const event={
        target:{
          name:namer(this.props.name),
          value:this.props.value
        }
      }
      this.props.handleEditItem(event)
    }
    return(
      <OverlayTrigger delay={{ show: 250, hide: 250 }} overlay={this.renderTooltip}>
        <button className="listbutton_regular_restricted_height bk_darkaccent"
        onClick={handleEvent}>
        <img className="listbutton_regular_img" src={back_img}
          /> 
        </button>
      </OverlayTrigger>
    )
  }
}

class SaveBackButton extends React.Component{
  static contextType = UserContext
  renderTooltip = (props) => {
    return (
      <Tooltip {...props}>
        {this.context.i18n.t('LIST_BUTTONS.save')}
      </Tooltip>
    )
  }

  render(){
    return(
      <OverlayTrigger delay={{ show: 250, hide: 250 }} overlay={this.renderTooltip}>
        <button className="listbutton_unregular"
        variant="primary"
        size="sm" 
        onClick={""}
        value={this.props.value}
        name="ok">
          Save and back 
        </button>
      </OverlayTrigger>
    )
  }
}

function AlertOk(props) {
  const [show, setShow] = useState(false);

  if (show) {
    return (
      <Alert variant="success" onClose={() => setShow(false)} dismissible>
        <Alert.Heading>Great!</Alert.Heading>
        <p>
          {props.message}
        </p>
      </Alert>
    );
  }
  return <button onClick={() => setShow(true)}>Show Alert</button>;
}

class ChangeMenuButton extends React.Component{
  static contextType = UserContext
  renderTooltip = (props) => {
    return (
      <Tooltip {...props}>
        {this.context.i18n.t('LIST_BUTTONS.change_menu')}
      </Tooltip>
    )
  }
  render(){
    return(
      <OverlayTrigger delay={{ show: 250, hide: 250 }} overlay={this.renderTooltip}>
        <button className="listbutton_regular_restricted_height bk_darkaccent" 
        onClick={this.props.handleEditItem}
        value={this.props.value}
        name={this.props.name}>
        <img className="listbutton_regular_img" src={expand_menu} value={this.props.value} /> 
        </button>
      </OverlayTrigger>
    )
  }
}

////////HEADER BUTTONS
function UserButton(props){
  const runEdit=()=>{
    const event={
      target:{
        value:props.value,
        name:props.name
      }
    }
    props.onClick(event)
  }
  return(
    <div onClick={runEdit}>
      <img src={user_img} className='spt_hd_btn_img bk_darkaccent'/>
    </div>
  )
}

class MainMenuButton extends React.Component{
  static contextType = UserContext
  runEdit=()=>{
    const event={
      target:{
        value:this.props.value,
        name:this.props.name
      }
    }
    this.props.onClick(event)
  }
  render(){
    return(
        <div  onClick={this.runEdit}>
          <img src={home_img} className="spt_hd_btn_img bk_darkaccent" /> 
        </div>
    )
  }
}





export{
  Button,
  RegularButton,
  RegularButtonSmall,
  RegularButtonFullHeight,
  RegularLinkButton,
  ActivateButtonPositiveNegative,
  UserButton,
  LogOutButton,
  EditButton,
  EditVersionButton,
  EditProjectButton,
  DeleteButton,
  DeleteProjectButton,
  DeleteVersionButton,
  DeleteUserButton,
  DuplicateButton,
  DuplicateVersionButton,
  UpButton,
  DownButton,
  AccessButton,
  AddButton,
  OKButton,
  ExpandButton,
  ViewButton,
  ViewProjectButton,
  ViewButtonForObserver,
  ViewButtonForVersion,
  QuestionButton,
  BackButton,
  SaveBackButton,
  AlertOk,
  MainMenuButton,
  ChangeMenuButton
}