import React from 'react'
import './CSS/table.css'


function TableMaker(props){
  if(Array.isArray(props.data)){
    const header=<Table.HeaderRow header_first_row={props.data[0][0]} header_other_rows={props.data[0].slice(1)}/>
    const table_distr = props.data.slice(1).map( row => {
      return <Table.Row row_data={row} i18n={props.i18n}/>
    })
    return(
      <Table>
        {header}
        {table_distr}
      </Table>
    )
  } else return ""
}

function Table(props){
  return(
    <table className='spt_table'>
      {props.children}
    </table>
  )
}

Table.HeaderRow=TableHeaderRow
Table.Row=TableRow

function TableHeaderRow(props){
  const item = props.header_other_rows
  const len = ((.8)/item.length)
  const item_distr = item.map((key)=>
    <HeaderOtherCell value={key} len={len}/>
  );
  return(
    <thead>
      <HeaderFirstCell value={props.header_first_row}/>
      {item_distr}
    </thead>
  )
}
function HeaderFirstCell (props){
  return(
    <th className="spt_table_header" style={{width:'20%'}}>
      {props.value}
    </th>
  );
}

function HeaderOtherCell (props){
  return(
    <th className="spt_table_header" style={{width:props.len}}>
      {props.value}
    </th>
  );
}

function TableRow (props){
  const item_holder= props.row_data
  const  item_distr = item_holder.map((value,index)=>{
    if(index===0)
      return <TableFirstCell className="spt_table_first_cell" value={value} />
    else return <TableCell value={value} />
  })  
  return(
    <tr>
      {item_distr}
    </tr>
  );
}

function TableCell (props){
  return(
    <td className="spt_table_cell">
      {props.value}
    </td>
  );
}

function TableFirstCell (props){
  return(
    <td className="spt_table_first_cell">
      {props.value}
    </td>
  );
}

export {
  Table,
  TableMaker
};