import React from 'react';
import {Row, Col} from 'Support/grid';
import {Button, Form} from 'react-bootstrap';

import ApiConnect from 'CONFIG/axios';

import './admin_style.css';
import { EditButton, RegularButton, RegularButtonSmall } from 'Support/listbuttons.js';
import {SingleDateForm, SingleInputForm, SingleInputIntegerForm} from 'Support/inputforms';
import { MiniCard } from 'Support/warning_cards';
import { ToogleButtons } from 'Support/toogle_buttons';

const dateRenderizing=(input)=>{
  if(input!=undefined){
    return(
      new Intl.DateTimeFormat('pt-PT').format(new Date(input))
    )
  }
}

const booleanRenderizing=(input)=>{
  if(input===true){
    return 'True'
  } else if(input===false){
    return 'False'
  } else return ''
}

class AdminUsers extends React.Component{
  constructor(props){
    super(props);
    this.state = {
        screen_admin_users_list: 'list',
        active_user:0,
        active_subs:0,
        active_budget:0
    }
  }


  handleGetUserInfoAndShowUser=async (event)=>{
    let set_item=""
    if(event!==undefined){
      set_item=event.target.value
    }
    
    await this.setState({
      active_user:set_item,
      screen_admin_users_list:'view_user',
    })
  }

  handleGoToUsersList=()=>{
    this.setState({
      screen_admin_users_list:'list',
    })
  }

  handleGoToNewSubscription=()=>{
    this.setState({
      screen_admin_users_list:'new_subscription',
    })
  }

  handleGoToUser=()=>{
    this.setState({
      screen_admin_users_list:'view_user',
    })
  }

  handleGoToSub=(event)=>{
    const value=event.target.value
    this.setState({
      active_subs:value,
      screen_admin_users_list:'subscription'
    })
  }

  handleGoToBudget=(event)=>{
    const value=event.target.value
    this.setState({
      active_budget:value,
      screen_admin_users_list:'budget'
    })
  }

  render(){
    const props={
      active_user:this.state.active_user,
      active_subs:this.state.active_subs,
      active_budget:this.state.active_budget,
      handleGetUserInfoAndShowUser:this.handleGetUserInfoAndShowUser,
      handleGoToUsersList:this.handleGoToUsersList,
      handleGoToUser:this.handleGoToUser,
      handleGoToSub:this.handleGoToSub,
      handleGoToBudget:this.handleGoToBudget,
      handleGoToNewSubscription:this.handleGoToNewSubscription
    }

    const pages={
      list: <AdminUsersList {...props}/>,
      view_user:<ViewAdminUser {...props}/>,
      subscription:<ViewSubscription {...props}/>,
      new_subscription:<CreateNewSubscription {...props}/>,
      budget:<ManageBudget {...props}/>
    }
    function toRender(data, i){
      return data[i];
    } 
    const renderizing = toRender(pages,this.state.screen_admin_users_list)

    return(
      <>
        {renderizing}
      </>
    )
  }
}

class AdminUsersList extends React.Component{
  constructor(props){
    super(props);
    this.state = {
        data: undefined,
        filter:"",
        usable_data:undefined
    }
    this.handleSortRequest=this.handleSortRequest.bind(this);
  }

  componentDidMount(){
    this.updateAccountList()
  }

  updateAccountList=async()=>{
    const request={}
    try {
      const response = await ApiConnect.post('api/admin/users/get', request)
      this.setState({
        data:response.data,
        filter:"",
        usable_data:response.data,
        sorter:"_id"
      })
    } catch(error) {
        alert(error);
    }
  }

  filterTheTable=async(event)=>{

    const type_of_filter=event.target.name

    const filterItems = (arr, query) => {
      return arr.filter(el => {
        if(el[type_of_filter]!=undefined){
          return el[type_of_filter].toLowerCase().indexOf(query.toLowerCase()) !== -1
        } else return false
      }) 
    }
    let go=this.state.data
    let tester= event.target.value
    if(tester.length>0){
      go=filterItems(this.state.data,tester)
    }
    this.sortTheTable(go)
  }

  handleSortRequest=async(event)=>{
    await this.setState({
      sorter:event.target.name
    })
    this.sortTheTable(this.state.usable_data)
  }

  sortTheTable=(data)=>{
    const sortItems = (arr, sorter) => {
      return arr.sort(function (a, b) {
      if (a[sorter] > b[sorter]) {
        return 1;
      }
      if (a[sorter] < b[sorter]) {
        return -1;
      }
      return 0
    })}
    let bolas=this.state.sorter
    let go=sortItems(data,bolas)
    this.setState({
      usable_data:go
    })
  }

  render(){
    const props={
      handleSortRequest:this.handleSortRequest,
      data:this.state
    }
    function dateRender(in1){
      if(in1!==undefined){
        return Intl.DateTimeFormat('en-US').format(Date.parse(in1))
      } else return ''
    }

    const item_holder = this.state.usable_data
    let item_distr="There are no users"
    if(item_holder !==undefined){
      item_distr = item_holder.map((value,key) =>
        <Row className='admin_al_lists_rows grid_middle'>
          <Col xs={2}>
            <Row>
              <Col xs={3}>
                {key+1}
              </Col>
              <Col xs={9}>
                {value.user_type}
              </Col>
            </Row>
          </Col>
          <Col xs={2}>
            {value.user_name}
          </Col>
          <Col xs={2}>
            {value.user_email}
          </Col>
          <Col xs={1}>
            {value.user_status}
          </Col>
          <Col xs={1}>
            {value.user_source}
          </Col>
          <Col xs={1}>
            {value.user_country}
          </Col>
          <Col xs={1}>
            {dateRender(value.user_registration_date)}
          </Col>
          <Col xs={1}>
            {dateRender(value.user_lastlogin_date)}
          </Col>
          <Col xs={1}>
            <Row className='grid_middle'>
              <Col xs={6}>
                {value.user_number_of_logins}
              </Col>
              <Col xs={6}>
                <Row >      
                  <EditButton handleEditItem={this.props.handleGetUserInfoAndShowUser} value={value._id}/>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      );
    }

    return(
      <>
        <Row className='admin_al_filter_col'>
          <Col xs={12}>
            <h4>Filters</h4>
          </Col>
          <Col xs={3} >
            <SingleInputForm
            label='Filter by e-mail'
            name="user_email"
            handleEditItemChange={this.filterTheTable} />
          </Col>
          <Col xs={3}>
            <SingleInputForm
            label='Filter by name'
            name="user_name"
            handleEditItemChange={this.filterTheTable} />
          </Col>
          <Col xs={3}>
            <SingleInputForm
            label='Filter by User Type'
            name="user_type"
            handleEditItemChange={this.filterTheTable} />
          </Col>
          <Col xs={3}>
            <SingleInputForm
            label='Filter by Status'
            name="user_status"
            handleEditItemChange={this.filterTheTable} />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <TableHeader {...props} />
          </Col>

          <Col xs={12}>
            {item_distr}
          </Col>
        </Row>
      </>
    )
  }
}

class TableHeader extends React.Component{
  render(){
    return(
      <Row>
      <Col xs={2}>
        <Row>
          <Col xs={3}></Col>
          <Col xs={9}>
            <RegularButtonSmall bk='bk_primary'name="user_type" onClick={this.props.handleSortRequest} label='User Type'/>
          </Col>
        </Row>
    </Col>
      <Col xs={2}>
      <RegularButtonSmall bk='bk_primary'name="user_name" onClick={this.props.handleSortRequest} label='User name'/>
    </Col>
    <Col xs={2}>
      <RegularButtonSmall bk='bk_primary'name="user_email" onClick={this.props.handleSortRequest} label='E-mail'/>
    </Col>
    <Col xs={1}>
      <RegularButtonSmall bk='bk_primary'name="user_status" onClick={this.props.handleSortRequest} label='Status'/>
    </Col>
    <Col xs={1}>
      <RegularButtonSmall bk='bk_primary'name="user_source" onClick={this.props.handleSortRequest} label='Source'/>
    </Col>
    <Col xs={1}>
      <RegularButtonSmall bk='bk_primary'name="user_country" onClick={this.props.handleSortRequest} label='Country'/>
    </Col>
    <Col xs={1}>
      <RegularButtonSmall bk='bk_primary'name="user_registration_date" onClick={this.props.handleSortRequest} label='Register'/>
    </Col>
    <Col xs={1}>
      <RegularButtonSmall bk='bk_primary'name="user_lastlogin_date" onClick={this.props.handleSortRequest} label='Last login'/>
    </Col>
    <Col xs={1}>
      <Row>
        <Col xs={12}>
          <RegularButtonSmall bk='bk_primary' name="user_number_of_logins" onClick={this.props.handleSortRequest} label='Logins'/>
        </Col>
      </Row>
    </Col>
  </Row>
    )
  }
}

class ViewAdminUser extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      user_data: '',
      budgets:[]
    }
  }

  componentDidMount(){
    this.updateUser()
  }

  updateUser=async()=>{
    const request={
      user_to_manage:this.props.active_user
    }
    try {
      const response = await ApiConnect.post('api/admin/user/detail/get', request)
      this.setState({
        user_data:response.data[0],
        budgets:response.data[1]
      })
    } catch(error) {
        alert(error);
    }
  }

  render(){
    return(
      <>
        <Row className="blanktop_15">
          <Col xs={6}>
            <h3>User</h3>
          </Col>
          <Col xs={3}>
            <RegularButtonSmall bk='bk_primary' onClick={this.props.handleGoToUsersList} label='Back to users list'/>
          </Col>
          <Col xs={3}>
            <RegularButtonSmall bk='bk_primary' block onClick={this.props.handleGoToNewSubscription} label='New Subscription'/>
          </Col>
        </Row>
        <Row>
          <Col xs={3} className='admin_al_user_cols'>
            <h6>User ID</h6>
            {this.state.user_data._id}
          </Col>
          <Col xs={3} className='admin_al_user_cols'>
            <h6>User Name</h6>
            {this.state.user_data.user_name}
          </Col>
          <Col xs={3} className='admin_al_user_cols'>
            <h6>E-mail</h6>
            {this.state.user_data.user_email}
          </Col>
          <Col xs={3} className='admin_al_user_cols'>
            <h6>Status</h6>
            {this.state.user_data.user_status}
          </Col>
          <Col xs={3} className='admin_al_user_cols'>
            <h6>Type</h6>
            {this.state.user_data.user_type}
          </Col>
          <Col xs={3} className='admin_al_user_cols'>
            <h6>Source</h6>
          { this.state.user_data.user_source}
          </Col>
          <Col xs={3} className='admin_al_user_cols'>
            <h6>Birth</h6>
            {this.state.user_data.user_birth}
          </Col>
          <Col xs={3} className='admin_al_user_cols'>
            <h6>Country</h6>
            {this.state.user_data.user_country}
          </Col>
          <Col xs={3} className='admin_al_user_cols'>
            <h6>Creation</h6>
            {dateRenderizing(this.state.user_data.user_creation_date)}
          </Col>
          <Col xs={3} className='admin_al_user_cols'>
            <h6>Activation</h6>
            {dateRenderizing(this.state.user_data.user_registration_date)}
          </Col>
          <Col xs={3} className='admin_al_user_cols'>
            <h6>Last Login</h6>
            {dateRenderizing(this.state.user_data.user_lastlogin_date)}
          </Col>
          <Col xs={3} className='admin_al_user_cols'>
            <h6>Number of logins</h6>
            {this.state.user_data.user_number_of_logins}
          </Col>
          <Col xs={12} className='blanktop_15'>
            <h5> BP Subscriptions</h5>
            <ViewSubscriptionsOfUser item_holder={this.state.user_data.user_subscriptions} handleGoToSub={this.props.handleGoToSub}/>
          </Col>
          <Col xs={12} className='blanktop_15'>
            <h5> Budgets</h5>
            <ViewSBudgetsOfUser item_holder={this.state.budgets} handleGoToBudget={this.props.handleGoToBudget}/>
          </Col>
        </Row>
      </>
    )
  }
}

function ViewSubscriptionsOfUser(props){
  const item_holder = props.item_holder
  let item_distr="There are no available subscription"
  if(item_holder !==undefined){
    item_distr = item_holder.map((value) =>
      <Row className='admin_al_lists_rows' center='xs' middle='xs'>
        <Col xs={2}>
          {value._id}
        </Col>
        <Col xs={1}>
          {value.subs_type}
        </Col>
        <Col xs={1}>
          {value.subs_permission}
        </Col>
        <Col xs={1}>
          {booleanRenderizing(value.subs_used)}
        </Col>
        <Col xs={2}>
          {(value.subs_used_project)}
        </Col>
        <Col xs={1}>
          {booleanRenderizing(value.subs_duration_type)}
        </Col>
        <Col xs={1}>
          {dateRenderizing(value.subs_duration_deadline)}
        </Col>
        <Col xs={1}>
          {dateRenderizing(value.subs_validity)}
        </Col>
        <Col xs={1}>
          {booleanRenderizing(value.subs_used_and_archived)}
        </Col>
        <Col xs={1}>
          <Row>
            <EditButton value={value._id} handleEditItem={props.handleGoToSub}/>
          </Row>
        </Col>
      </Row>
    )
  }
  return(
    <>
      <ViewSubscriptionsOfUserListHeader />
      {item_distr}
    </>
  )
}

function ViewSubscriptionsOfUserListHeader(){
  return(
    <Row className='list_table_header'>
      <Col xs={2}>
        ID
      </Col>
      <Col xs={1}>
        Type
      </Col>
      <Col xs={1}>
        Permission
      </Col>
      <Col xs={1}>
        Used
      </Col>
      <Col xs={2}>
        Project
      </Col>
      <Col xs={1}>
        Flex/Fixed
      </Col>
      <Col xs={1}>
        Deadline
      </Col>
      <Col xs={1}>
        Validity
      </Col>
      <Col xs={1}>
        Archived
      </Col>
      <Col xs={1}>
        Edit
      </Col>
    </Row>
  )
}

class ViewSubscription extends React.Component{
  constructor(props){
    super(props);
    this.state = {
        data: "",
    }
    this.handleChangeSub=this.handleChangeSub.bind(this)
    this.handleChangeSubBool=this.handleChangeSubBool.bind(this)
    this.handleChangeSubFloat=this.handleChangeSubFloat.bind(this)
    this.handleChangeFixed=this.handleChangeFixed.bind(this)
    this.handleChangeFlex=this.handleChangeFlex.bind(this)
  }

  componentDidMount(){
    this.updateSub()
  }

  updateSub=async()=>{
    const request={
      user_sub:this.props.active_subs
    }
    try {
      const response = await ApiConnect.post('api/admin/sub/detail/get', request)
      this.setState({
        data:response.data,
      })
    } catch(error) {
        alert(error);
    }
  }

  handleChangeSub(event){
    const name=event.target.name
    const value=event.target.value
    let go= this.state.data
    go[name]=value
    this.setState({
      data:go
    })
  }

  handleChangeSubBool(event){
    const name=event.target.name
    const value=(event.target.value==='true')

    let go= this.state.data
    go[name]=value
    this.setState({
      data:go
    })

  }

  handleChangeSubFloat(event){
    const name=event.target.name
    const value=parseFloat(event.target.value)
    let go= this.state.data
    go[name]=value
    this.setState({
      data:go
    })
  }

  handleChangeFlex(event){
    const value=(event.target.value)
    let go= this.state.data
    if(this.state.data.subs_used===false){
      go.subs_duration_flex_days=value
      go.subs_duration_deadline=undefined
      this.setState({
        data:go
      })
    } else {alert('Used sub, cannot change')}
  }

  handleChangeFixed(event){
    const name=event.target.name
    const value=(event.target.value)
    let go= this.state.data
    go[name]=value
    go.subs_duration_type=false
    go.subs_duration_deadline=value
    this.setState({
      data:go
    })
  }

  handleSaveAndBack=async()=>{
    const request={
      data:this.state.data
    }
    try {
      await ApiConnect.post('api/admin/sub/change', request)
    } catch(error) {
      alert(error);
    }
    this.props.handleGoToUser()
  }
  
  render(){
    const renderBoolean=(input)=>{
      if(input==true){
        return 'True'
      } else {return 'False'}
    }

    const renderFixedDateOption=(input)=>{
      if(input==true){
        return (
          <Col xs={2}>
            <SingleInputIntegerForm
            label='Duration flex'
            value={this.state.data.subs_duration_flex_days}
            name='subs_duration_flex_days'
            handleinputeditChange ={this.handleChangeFlex}/>
          </Col>
        )
      } else {
        return(
          <Col xs={2}>
            <SubsFormDate
            label='Duration fixed'
            value={(this.state.data.subs_duration_fixed_day)}
            name='subs_duration_fixed_day'
            handleEditItemChange={this.handleChangeFixed}/>
          </Col>
        )
      }
    }
    
    return(
      <Row >
        <Col xs={6}>
          <h2>Subscription</h2>
        </Col>
        <Col xs={3}>
          <RegularButton bk='bk_primary' label='Back to user' onClick={this.props.handleGoToUser}/>
        </Col>
        <Col xs={3}>
          <RegularButton bk='bk_success' label='Save and back' onClick={this.handleSaveAndBack}/>
        </Col>
        <Col xs={12} className='admin_al_sub_main_cols'>
          <h5>Subscription static data</h5>
        </Col>
        <MiniCard xs={3} color='lightaccent' header='ID' body={this.state.data._id}/>
        <MiniCard xs={3} color='lightaccent' header='Creation date' body={dateRenderizing(this.state.data.subs_date)}/>
        <MiniCard xs={3} color='lightaccent' header='Origin' body={this.state.data.subs_origing}/>
        <MiniCard xs={3} color='lightaccent' header='Provider' body={this.state.data.subs_provider}/>
        <MiniCard xs={3} color='lightaccent' header='Used' body={renderBoolean(this.state.data.subs_used)}/>
        <MiniCard xs={3} color='lightaccent' header='Used project' body={this.state.data.subs_used_project}/>
        <MiniCard xs={3} color='lightaccent' header='Used date' body={dateRenderizing(this.state.data.subs_used_date)}/>
        <MiniCard xs={3} color='lightaccent' header='Used archived' body={renderBoolean(this.state.data.subs_used_and_archived)}/>
        <MiniCard xs={3} color='lightaccent' header='Used and archived date' body={dateRenderizing(this.state.data.subs_used_and_archived_date)}/>
        <MiniCard xs={3} color='lightaccent' header='Stripe ref' body={this.state.data.subs_stripe_event}/>
        <Col xs={12} className='blanktop_15'>
          <h5>Subscription setup changes</h5>
        </Col>
        <Col xs={2}>
          <SingleInputForm
          label='Type'
          value={this.state.data.subs_type}
          name='subs_type'
          handleEditItem={this.handleChangeSub}/>
        </Col>
        <Col xs={2}>
        <SingleInputForm
          label='Permission'
          value={this.state.data.subs_permission}/>
        </Col>
        <Col xs={2}>
          <SingleInputIntegerForm
            label='Number of colaborators'
            value={this.state.data.subs_number_of_colaborators}
            name='subs_number_of_colaborators'
            handleinputeditChange={this.handleChangeSubFloat}/>
        </Col>
        <Col xs={2}>
          <SingleInputIntegerForm
            label='Number of viewers'
            value={this.state.data.subs_number_of_viewers}
            name='subs_number_of_viewers'
            handleinputeditChange={this.handleChangeSubFloat}/>
        </Col>
        <Col xs={2}>
          <SingleInputIntegerForm
            label='Feasibility max versions'
            value={this.state.data.subs_feasibility_versions_max}
            name='subs_feasibility_versions_max'
            handleinputeditChange={this.handleChangeSubFloat}/>
        </Col>
        <Col xs={12} className='admin_al_sub_main_cols'>
          <h5>Subscription setup changes</h5>
        </Col>
        <Col xs={2}>
        <SubsDateType
          label='Duration type'
          value={this.state.data.subs_duration_type}
          name='subs_duration_type'
          handleEditItemChange={this.handleChangeSubBool}/>
        </Col>
        {renderFixedDateOption(this.state.data.subs_duration_type)}
        <Col xs={2}>
          <h6>Duration deadline</h6>
          {dateRenderizing(this.state.data.subs_duration_deadline)}
        </Col>
      </Row>
    )
  }
}

class CreateNewSubscription extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      data:{
        recipient_id: this.props.active_user,
        subs_type:'business_plan',
        subs_permission:'Full',
        subs_number_of_colaborators:5,
        subs_number_of_viewers:10,
        subs_feasibility_versions_max:6,
        subs_duration_type:true,
        subs_duration_flex_days:15,
        subs_duration_fixed_day:new Date(),
        subs_validity:new Date()
      }
    }
    this.handleChangeSub=this.handleChangeSub.bind(this)
    this.handleChangeSubBool=this.handleChangeSubBool.bind(this)
    this.handleChangeSubFloat=this.handleChangeSubFloat.bind(this)
    this.handleChangeFixed=this.handleChangeFixed.bind(this)
    this.handleChangeFlex=this.handleChangeFlex.bind(this)
    this.handleSubsValidity=this.handleSubsValidity.bind(this)
    this.handleCreateSub=this.handleCreateSub.bind(this)
  }

  handleChangeSub(event){
    const name=event.target.name
    const value=event.target.value
    let go= this.state.data
    go[name]=value
    this.setState({
      data:go
    })
  }

  handleChangeSubBool(event){
    const name=event.target.name
    const value=(event.target.value==='true')
    let go= this.state.data
    if(value){
      const today= new Date()
      go.subs_validity=new Date(today.setDate(today.getDate()+180))
    } else{
      go.subs_validity=go.subs_duration_fixed_day
    }
    
    go[name]=value
    this.setState({
      data:go
    })

  }

  handleChangeSubFloat(event){
    const name=event.target.name
    const value=parseFloat(event.target.value)
    let go= this.state.data
    go[name]=value
    this.setState({
      data:go
    })
  }

  handleChangeFlex(event){
    const value=(event.target.value)
    let go= this.state.data
    const today= new Date()
    go.subs_duration_flex_days=value
    go.subs_validity=new Date(today.setDate(today.getDate()+180))
    this.setState({
      data:go
    })
  }

  handleSubsValidity(event){
    const name=event.target.name
    const value=(event.target.value)
    let go= this.state.data
    go[name]=value
    this.setState({
      data:go
    })
  }

  handleChangeFixed(event){
    const name=event.target.name
    const value=(event.target.value)
    let go= this.state.data
    go[name]=value
    go.subs_duration_type=false
    go.subs_validity=value
    this.setState({
      data:go
    })
  }

  handleCreateSub=async()=>{
    window.confirm('Confirm new subscription?')
    const request={
      data:this.state.data
    }
    try {
      await ApiConnect.post('api/admin/sub/create', request)
      this.props.handleGoToUser()
    } catch(error) {
      alert(error);
    }
  }
  
  render(){
    const renderFixedDateOption=(input)=>{
      if(input==true){
        return (
          <Col xs={2}>
            <SingleInputIntegerForm
            label='Duration flex'
            value={this.state.data.subs_duration_flex_days}
            name='subs_duration_flex_days'
            handleinputeditChange ={this.handleChangeFlex}/>
          </Col>
        )
      } else {
        return(
          <Col xs={2}>
            <SubsFormDate
            label='Duration fixed'
            value={(this.state.data.subs_duration_fixed_day)}
            name='subs_duration_fixed_day'
            handleEditItemChange={this.handleChangeFixed}/>
          </Col>
        )
      }
    }
    
    return(
      <>
      <Row>
        <Col xs={9}>
          <h2>New Subscription</h2>
        </Col>
        <Col xs={3}>
          <Button size='sm' block onClick={this.props.handleGoToUser}>Back to user</Button>
        </Col>
        <Col xs={12} className='admin_al_sub_main_cols'>
          <h5>Subscription setup changes</h5>
        </Col>
        <Col xs={2}>
          <Form>
            <Form.Group>
              <Form.Label>Type</Form.Label>
              <Form.Control as='select' name='subs_type' value={this.state.data.subs_type} onChange={this.handleChangeSub}>
                <option value='business_plan'>Business Plan</option>
                <option value='feasibility_plan'>Feasibility Plan</option>
                <option value='canvas_plan'>Canvas Plan</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </Col>
        <Col xs={2}>
          <Form>
            <Form.Group>
              <Form.Label>Permission</Form.Label>
              <Form.Control as='select' name='subs_permission' value={this.state.data.subs_permission} onChange={this.handleChangeSub}>
                <option value='Full'>Full</option>
                <option value='Demonstration'>Demonstration</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </Col>
        <Col xs={2}>
          <SingleInputIntegerForm
            label='Number of colaborators'
            value={this.state.data.subs_number_of_colaborators}
            name='subs_number_of_colaborators'
            handleinputeditChange={this.handleChangeSubFloat}/>
        </Col>
        <Col xs={2}>
          <SingleInputIntegerForm
            label='Number of viewers'
            value={this.state.data.subs_number_of_viewers}
            name='subs_number_of_viewers'
            handleinputeditChange={this.handleChangeSubFloat}/>
        </Col>
        <Col xs={2}>
          <SingleInputIntegerForm
            label='Feasibility max versions'
            value={this.state.data.subs_feasibility_versions_max}
            name='subs_feasibility_versions_max'
            handleinputeditChange={this.handleChangeSubFloat}/>
        </Col>
        <Col xs={12} className='admin_al_sub_main_cols'>
          <h5>Subscription setup changes</h5>
        </Col>
        <Col xs={2}>
        <SubsDateType
          label='Duration type'
          value={this.state.data.subs_duration_type}
          name='subs_duration_type'
          handleEditItemChange={this.handleChangeSubBool}/>
        </Col>
        {renderFixedDateOption(this.state.data.subs_duration_type)}
        <Col xs={2}>
          <SubsFormDate
          label='Validity'
          value={this.state.data.subs_validity}
          name='subs_validity'
          handleEditItemChange={this.handleSubsValidity}/>
        </Col>
      </Row>
      <Row>
        <Col xs={9}></Col>
        <Col xs={3}>
          <Button block size='sm' variant='success' onClick={this.handleCreateSub}>Create new subscription</Button>
        </Col>
      </Row>
      </>
    )
  }
}

function SubsFormDate(props){
  let date=""
  const dateChecker=(input)=>{
    if(input===undefined){
      date=""
    } else {
      const dd=new Date(input)
      date=(dd.toISOString().substr(0,10))
    }
    return date
  }

  return(
    <>
    <Form>
      <Form.Group>
        <Form.Label>{props.label}</Form.Label>
        <Form.Control type='date' name={props.name} value={dateChecker(props.value)} onChange={props.handleEditItemChange}/>
      </Form.Group>
    </Form>
    </>
  )
}

function SubsDateType(props){
  return(
    <>
    <Form>
      <Form.Group>
        <Form.Label>{props.label}</Form.Label>
        <Form.Control name={props.name} property='Boolean' as='select' value={props.value} onChange={props.handleEditItemChange}>
          <option value={true}>Flex</option>
          <option value={false}>Fixed</option>
        </Form.Control>
      </Form.Group>
    </Form>
    </>
  )
}

/////////////////
//////BUDGETS////
/////////////////
function ViewSBudgetsOfUser(props){
  const item_holder = props.item_holder
  let item_distr="There are no available subscription"
  if(item_holder !==undefined){
    item_distr = item_holder.map((value) =>
      <Row className='grid_middle'>
        <Col xs={2}>
          {value._id}
        </Col>
        <Col xs={3}>
          {value.budget_name}
        </Col>
        <Col xs={3}>
          {dateRenderizing(value.budget_subscription.subs_validity)}
        </Col>
        <Col xs={3}>
          {booleanRenderizing(value.budget_template)}
        </Col>
        <Col xs={1}>
          <Row>
            <EditButton value={value._id} handleEditItem={props.handleGoToBudget}/>
          </Row>
        </Col>
      </Row>
    )
  }
  return(
    <>
      <ViewBudgetsOfUserListHeader />
      {item_distr}
    </>
  )
}

function ViewBudgetsOfUserListHeader(){
  return(
    <Row className='list_table_header_left'>
      <Col xs={2}>
        ID
      </Col>
      <Col xs={3}>
        Name
      </Col>
      <Col xs={3}>
        Validity
      </Col>
      <Col xs={3}>
        Template
      </Col>
    </Row>
  )
}

class ManageBudget extends React.Component{
  constructor(props){
    super(props);
    this.state = {
        data: "",
        loaded: false
    }
  }

  componentDidMount(){
    this.updateSub()
  }

  updateSub=async()=>{
    const request={
      budget_id:this.props.active_budget
    }
    try {
      const response = await ApiConnect.post('/api/admin/budget/detail/get', request)
      this.setState({
        data:response.data,
        loaded:true
      })
    } catch(error) {
        alert(error);
    }
  }

  handleChangeVersions=(event)=>{
    let go= this.state.data
    go.subs_max_versions = event.target.value
    this.setState({
      data:go
    })
  }

  handleChangeValidity=(event)=>{
    let go= this.state.data
    go.subs_validity = event.target.value
    this.setState({
      data:go
    })
  }

  handleChangeTemplate=(event)=>{
    let val=event.target.value
    if(val=="true")
    {val=true} 
    else {val=false}
    let go= this.state.data
    go.budget_template = val
    this.setState({
      data:go
    })
  }

  handleSaveAndBack=async()=>{
    const request={
      budget_id:this.props.active_budget,
      data:this.state.data
    }
    try {
      await ApiConnect.post('/api/admin/budget/detail/set', request)
      this.props.handleGoToUser()
    }
    catch(error) {
      alert(error);
    }
  }
  
  render(){
    const renderBoolean=(input)=>{
      if(input==true){
        return 'True'
      } else {return 'False'}
    }

    const card_width=3

    const toogle_data=[
      {value:false, option_label:"False"},
      {value:true, option_label:"True"},
    ]
    
    if(this.state.loaded===true){
      let d=new Date(this.state.data.subs_validity)
      const month =(d.getMonth()+1) 
      d= (`${d.getFullYear()}-${("0"+month).slice(-2)}-${("0"+d.getDate()).slice(-2)}`)


      return(
        <Row className='blanktop_10'>
          <Col xs={6}>
            <h2>Budget</h2>
          </Col>
          <Col xs={3}>
            <RegularButtonSmall bk='bk_warning' onClick={this.props.handleGoToUser} label='Back to user'/>
          </Col>
          <Col xs={3}>
            <RegularButtonSmall bk='bk_success' onClick={this.handleSaveAndBack} label='Saves and back to user'/>
          </Col>
          <Col xs={12} className='admin_al_sub_main_cols'>
            <h5>Subscription static data</h5>
          </Col>
          <MiniCard xs={card_width} color='lightaccent' header='ID' body={this.state.data.id}/>
          <MiniCard xs={card_width} color='lightaccent' header='Name' body={this.state.data.budget_name}/>
          <MiniCard xs={card_width} color='lightaccent' header='Creation date' body={dateRenderizing(this.state.data.budget_creation_date)}/>
          <MiniCard xs={card_width} color='lightaccent' header='Used' body={renderBoolean(this.state.data.budget_edited)}/>
          <MiniCard xs={card_width} color='lightaccent' header='Used date' body={dateRenderizing(this.state.data.budget_edited_date)}/>
          <MiniCard xs={card_width} color='lightaccent' header='Stripe ref' body={this.state.data.subs_stripe_event}/>
          <Col xs={12} className='admin_al_sub_main_cols'>
            <h5>Subscription setup changes</h5>
          </Col>
          <SingleInputIntegerForm xs={2} label='Feasibility max versions' value={this.state.data.subs_max_versions} handleinputeditChange={this.handleChangeVersions}/>
          <SingleDateForm label='Validity' value={d}  onChange={this.handleChangeValidity}/>
          <ToogleButtons xs={8} label="Template" toogle_data={toogle_data} selected={this.state.data.budget_template} handleToogleButton={this.handleChangeTemplate}/>
        </Row>
      )
    }
  }
}

export default AdminUsers;
