class InvestmentCreator{
  constructor(name, array_size, vatrate,hist,inv_existence_for_budget){
    this.inv_item_name=name
    this.inv_type="Tangible"
    this.inv_existence=inv_existence_for_budget
    this.inv_quantity=0
    this.inv_price=0
    this.inv_year=0
    this.inv_month=0
    this.inv_depreciation_rate=.5
    this.inv_vat_rate=vatrate
    this.inv_subsidy_option=false
    this.inv_subsidy_percentage=0
    this._inv_depreciation_months=0
    this._inv_monthly_depreciation=0
    this._inv_gross_value=new Array(array_size).fill(0)
    this._inv_acquisition=new Array(array_size).fill(0)
    this._inv_net_acquisition=new Array(array_size).fill(0)
    this._inv_subsidy_comparticipation=new Array(array_size).fill(0)
    this._inv_depreciations=new Array(array_size).fill(0)
    this._inv_acumulated_depreciation=new Array(array_size).fill(0)
    this._inv_depreciation=new Array(array_size).fill(0)
    this._inv_net_value=new Array(array_size).fill(0)
    this._inv_vat=new Array(array_size).fill(0)
    this._inv_receivable=new Array(array_size).fill(0)
    this._inv_hist=hist
  }
}

export default InvestmentCreator;