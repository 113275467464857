import React from 'react';
import {Grid, Row, Col } from 'Support/grid';

import UserContext from 'CONFIG/auth';

import Loader from 'Support/loader';
import {PeriodHeader, FormEdit,NonEditableForm, FormEditWithNameChange} from 'Support/inputforms'
import { WarningCard } from 'Support/warning_cards';
import { AddButton, DeleteButton, RegularButtonSmall } from 'Support/listbuttons';

class DetailedHistoricForms extends React.Component {
  static contextType = UserContext
  render() {
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_PROJECT.PROJECT_HISTORIC.Forms')

    const props ={
      trsl:trsl,
      handleScreenNavigation:this.props.handleScreenNavigation,
      handleSave:this.props.handleSave,
      handleEditChangeIncSt:this.props.handleEditChangeIncSt,
      handleEditChangeBs:this.props.handleEditChangeBs,
      handleAddDetailedElement:this.props.handleAddDetailedElement,
      handleDeleteDetailedElement:this.props.handleDeleteDetailedElement,
      handleEditDetailedChangeIncSt:this.props.handleEditDetailedChangeIncSt,
      handleDetailedElementNameChange:this.props.handleDetailedElementNameChange,
      data:this.props.data,
      feasibility_settings:this.props.feasibility_settings,
      user_permission:this.props.user_permission
  }

    function torender(data, i){
      return data[i];
    }  
    const pages ={
      true: 
      <ToDisplay {...props}/>,
      
      false: 
      <Loader loader_message="vers_edit"/>
    }
    var i = this.props.loaded
    const renderizing = torender(pages,i)

    return(
      <Row>
        {renderizing}
      </Row>
    )
  }
}

class ToDisplay extends React.Component {
  render(){
    const h_year_arr=[0,0,0]
    for(var i=0;i<h_year_arr.length;i++){
      h_year_arr[i]=parseFloat(this.props.feasibility_settings.starting_year)+parseFloat([i])-3
    }
    const props=this.props
    return(
      <Col xs={12}>
        <GlobalHeader {...props}/>
        <Row>
          <Col xs={10} className='blanktop_15'>
            <PeriodHeader label={this.props.trsl['is']} periods={h_year_arr} />
          </Col>
          <Col xs={12}>
            <IS {...props}/>
          </Col>
          <Col xs={10} className='blanktop_15'>
             <PeriodHeader label={this.props.trsl['bs']} periods={h_year_arr} />
            </Col>
            <Col xs={12}>
              <BS {...props}/>
              <Warnings assets={this.props.data.h_bal.h_total_assets} liabilities={this.props.data.h_bal.h_equity_liabilities} trsl={this.props.trsl}/>
          </Col>
          <Col className='blanktop_10'></Col>
        </Row>
      </Col>
    );
  }
}

class GlobalHeader extends React.Component{
  render(){
    let edit_ony_permission=true
    if(this.props.user_permission=="owner" || this.props.user_permission=="edit"){
      edit_ony_permission= false
    }
    return(
      <div>
        <Row className="feasibility_edit_globalheader_row">
          <Col xs={9}>
            <h4 className="headingtitle">{this.props.trsl['header']}</h4>
          </Col>
          <Col xs={3}>
            <RegularButtonSmall bk='bk_success' label={this.props.trsl['save']} onClick={this.props.handleSave}/>
          </Col>
        </Row>
      </div>
    );
  }
}

class IS extends React.Component{
  render(){
    const data=this.props.data

    const unit=new Intl.NumberFormat('ja-JP', { style: 'currency', currency: this.props.feasibility_settings.currency}).format(0).replace(/\d./g,"")

    const generic_detailed_props={
      data:this.props.data,
      total:data.h_inc_st, 
      handleAddDetailedElement:this.props.handleAddDetailedElement,
      handleDeleteDetailedElement:this.props.handleDeleteDetailedElement,
      handleForm:this.props.handleEditDetailedChangeIncSt,
      handleDetailedElementNameChange:this.props.handleDetailedElementNameChange
    }

    const generic_simple_props={
      handleForm:this.props.handleEditChangeIncSt,
      unit:unit
    }

    return(
      <>        
        <DetailedEdit label={this.props.trsl} id={'h_revenue'} name='h_revs' {...generic_detailed_props}/>
        <SimpleEdit label={this.props.trsl} id={'h_operational_grants'} total={data.h_inc_st.h_operational_grants} {...generic_simple_props}/>
        <SimpleEdit label={this.props.trsl} id={'h_cogs'} total={data.h_inc_st.h_cogs} {...generic_simple_props}/>
        <DetailedEdit label={this.props.trsl} id={'h_operating_expenses'} name='h_op_exps' {...generic_detailed_props}/>
        <DetailedEdit label={this.props.trsl} id={'h_hr'} name='h_hrs' {...generic_detailed_props}/>
        <DetailedEdit label={this.props.trsl} id={'h_other_gains'} name='h_ogs' {...generic_detailed_props}/>
        <DetailedEdit label={this.props.trsl} id={'h_other_costs'} name='h_ocs' {...generic_detailed_props}/>
        <NoEditTotals data={data.h_inc_st.h_ebitda} trsl={this.props.trsl} id='h_ebitda'/>
        <SimpleEdit label={this.props.trsl} id={'h_depreciations'} total={data.h_inc_st.h_depreciations} {...generic_simple_props}/>
        <NoEditTotals data={data.h_inc_st.h_ebit} trsl={this.props.trsl} id='h_ebit'/>
        <SimpleEdit label={this.props.trsl} id={'h_interest_cost'} total={data.h_inc_st.h_interest_cost} {...generic_simple_props}/>
        <NoEditTotals data={data.h_inc_st.h_ebt} trsl={this.props.trsl} id='h_ebt'/>
        <SimpleEdit label={this.props.trsl} id={'h_corporate_tax'} total={data.h_inc_st.h_corporate_tax} {...generic_simple_props}/>
        <NoEditTotals data={data.h_inc_st.h_net_profit} trsl={this.props.trsl} id='h_net_profit'/>
      </>
    )
  }
}


class BS extends React.Component{
  render(){
    const data = this.props.data

    const unit=new Intl.NumberFormat('ja-JP', { style: 'currency', currency: this.props.feasibility_settings.currency}).format(0).replace(/\d./g,"")

    const generic_detailed_props={
      data:this.props.data,
      total:data.h_bal, 
      handleAddDetailedElement:this.props.handleAddDetailedElement,
      handleDeleteDetailedElement:this.props.handleDeleteDetailedElement,
      handleForm:this.props.handleEditDetailedChangeIncSt,
      handleDetailedElementNameChange:this.props.handleDetailedElementNameChange
    }

    const generic_simple_props={
      handleForm:this.props.handleEditChangeBs,
      unit:unit
    }

    return(
      <>
        <DetailedEdit label={this.props.trsl} id={'h_tangible_assets'} name='h_tas' {...generic_detailed_props}/>
        <DetailedEdit label={this.props.trsl} id={'h_intangible_assets'} name='h_ias' {...generic_detailed_props}/>
        <DetailedEdit label={this.props.trsl} id={'h_other_non_current_assets'} name='h_onca' {...generic_detailed_props}/>
        <NoEditTotals data={data.h_bal.h_total_non_current_assets} trsl={this.props.trsl} id='h_total_non_current_assets'/>
        <SimpleEdit label={this.props.trsl} id={'h_inventories'} total={data.h_bal.h_inventories} {...generic_simple_props}/>
        <SimpleEdit label={this.props.trsl} id={'h_clients'} total={data.h_bal.h_clients} {...generic_simple_props}/>
        <SimpleEdit label={this.props.trsl} id={'h_government_receivable'} total={data.h_bal.h_government_receivable} {...generic_simple_props}/>
        <DetailedEdit label={this.props.trsl} id={'h_other_current_assets'} name='h_oca' {...generic_detailed_props}/>
        <SimpleEdit label={this.props.trsl} id={'h_cash'} total={data.h_bal.h_cash} {...generic_simple_props}/>
        <NoEditTotals data={data.h_bal.h_total_current_assets} trsl={this.props.trsl} id='h_total_current_assets'/>
        <NoEditTotals data={data.h_bal.h_total_assets} trsl={this.props.trsl} id='h_total_assets'/>
        <DetailedEdit label={this.props.trsl} id={'h_issued_capital'} name='h_isc' {...generic_detailed_props}/>
        <DetailedEdit label={this.props.trsl} id={'h_other_equity_instruments'} name='h_oci' {...generic_detailed_props}/>
        <DetailedEdit label={this.props.trsl} id={'h_other_equity_changes'} name='h_oec' {...generic_detailed_props}/>
        <SimpleEdit label={this.props.trsl} id={'h_past_results'} total={data.h_bal.h_past_results} {...generic_simple_props}/>
        <NoEditTotals data={data.h_inc_st.h_net_profit} trsl={this.props.trsl} id='h_net_profit'/>
        <NoEditTotals data={data.h_bal.h_total_equity} trsl={this.props.trsl} id='h_total_equity'/>
        <SimpleEdit label={this.props.trsl} id={'h_debt'} total={data.h_bal.h_debt} {...generic_simple_props}/>
        <DetailedEdit label={this.props.trsl} id={'h_other_non_current_liabilities'} name='h_oncl' {...generic_detailed_props}/>
        <NoEditTotals data={data.h_bal.h_total_non_current_liabilities} trsl={this.props.trsl} id='h_total_non_current_liabilities'/>
        <SimpleEdit label={this.props.trsl} id={'h_suppliers'} total={data.h_bal.h_suppliers} {...generic_simple_props}/>
        <DetailedEdit label={this.props.trsl} id={'h_shareholders_loan'} name='h_shl' {...generic_detailed_props}/>
        <SimpleEdit label={this.props.trsl} id={'h_government_payable'} total={data.h_bal.h_government_payable} {...generic_simple_props}/>
        <SimpleEdit label={this.props.trsl} id={'h_other_accounts_payables'} total={data.h_bal.h_other_accounts_payables} {...generic_simple_props}/>
        <DetailedEdit label={this.props.trsl} id={'h_other_current_liabilities'} name='h_ocl' {...generic_detailed_props}/>
        <NoEditTotals data={data.h_bal.h_total_current_liabilities} trsl={this.props.trsl} id='h_total_current_liabilities'/>
        <NoEditTotals data={data.h_bal.h_total_liabilities} trsl={this.props.trsl} id='h_total_liabilities'/>
        <NoEditTotals data={data.h_bal.h_equity_liabilities} trsl={this.props.trsl} id='h_equity_liabilities'/>
      </>
    )
  }
}

class Warnings extends React.Component{
  render() {
    function renderWarnings(assets,liabilties,trsl){ 
      let arr=[] 
      const t=trsl
      let item_distr
      for(var i=0;i<assets.length;i++){
        if(assets[i].toFixed(2)===liabilties[i].toFixed(2)){
          arr[i] =<Col xs={4}><WarningCard label1={t['data_forms']} label2={t['equal']} color='success'/></Col>
        } else{
          arr[i] =<Col xs={4}><WarningCard label1 ={t['data_forms']} label2={t['not_equal']} color='danger'/></Col>
        }
      }
      item_distr=arr.map(v=>
      <> {v}</>)
      return item_distr
    }
    return(
      <Row>
        <Col xs={2}></Col>
        <Col xs={8}>
          <Row>
            {renderWarnings(this.props.assets,this.props.liabilities,this.props.trsl)}
          </Row>
        </Col>
      </Row>
    )
  }
}

class DetailedEdit extends React.Component{
  handleForm=(id, item)=>{
    const name=this.props.name
    const value= item
    this.props.handleForm(id, value, name)
  }

  handleChangeName=(event)=>{
    const element=this.props.name
    const id=event.target.id
    this.props.handleDetailedElementNameChange(element,id, event.target.value)
  }

  render(){   
    const item_holder = this.props.data[this.props.name]
    let item_distr=''
    if(item_holder!==undefined){
      item_distr= item_holder.map((el,index) =>
        <>
          <Col xs={10}>
            <FormEditWithNameChange item_holder={el.value} id={index} label={el.name} name={this.props.name} handleForm={this.handleForm} handleNameChange={this.handleChangeName}/>
          </Col>
          <Col xs={2}>
            <Row >
              <DeleteButton name={this.props.name} value={index} handleDelete={this.props.handleDeleteDetailedElement}/>
            </Row>
          </Col>
        </>
      )
    }
    return(
      <Grid className='blanktop_05'>
      <Row className='bk_lightaccent middle'>
        <Col xs={10} className='blanktop_05'>
          <h5>{this.props.label[this.props.id]}</h5>
        </Col>
        <Col xs={2} className='blanktop_05'>
          <Row >
            <AddButton name={this.props.name} handleAddItem={this.props.handleAddDetailedElement}/>
          </Row>
        </Col>
        {item_distr}
        <Col xs={10}>
          <NonEditableForm item_holder={this.props.total[this.props.id]} label={this.props.label[this.props.id]} decimals={true} />
        </Col>
      </Row>
      </Grid>
    )
  }
}


class SimpleEdit extends React.Component{
  render(){
    return(
      <Grid className='blanktop_05'>
      <Row className='bk_lightaccent'>
        <Col xs={10} className='blanktop_05'>
          <h5>{this.props.label[this.props.id]}</h5>
        </Col>
        <Col xs={2} className='canva_swot_swot_cols_align'>
        </Col>
        <Col xs={10}>
          <FormEdit item_holder={this.props.total} id={this.props.id} label={this.props.label[this.props.id]} handleForm={this.props.handleForm} unit={this.props.unit}/>
        </Col>
      </Row>
      </Grid>
    )
  }
}

function NoEditTotals (props){
  return(
    <Grid className='blanktop_05'>
      <Row>
        <Col xs={10}>
          <NonEditableForm item_holder={props.data} id={props.id} label={props.trsl[props.id]} decimals={true} />
        </Col>
      </Row>
    </Grid>
  )
}


export default DetailedHistoricForms;