import React from 'react';
import { Nav,NavDropdown} from 'react-bootstrap';
import { Route, Routes } from "react-router-dom";

import {VersionHeaderBar} from './feasibility_headerbar';
import Dashboard from './0 Dashboard/dashboardmain';
import VersionMain from './1 Version_Settings/versionsmain';
import RevenuesAndCogsMain from './2 Revenues/revenuesandcogsmain';
import OperatingExpensesMain from './3 Operating_exp/operating_exp_main';
import HrMain from './4 HR/hrmain';
import OtherGainsCostMain from './5 Other_gains_costs/other_gains_cost_main';
import InvestmentMain from './6 Investment/investmentmain';
import ShareholdersMain from './7 Shareholders/shareholders_main';
import DebtMain from './8 Debt/debtmain';
import OtherBalanceMain from './9 Other_balance/other_balance_main';
import CocAssumptionMain from './10 Cost_of_capital/cost_of_capital_main';
import SensitivityAnalysis from './11-Sensitity analysis/sensitivity_analysis_main'

import Session from 'CONFIG/session';
import UserContext from 'CONFIG/auth';
import ApiConnect from 'CONFIG/axios';

import {Navigation} from 'Support/navigation'
import { Row, Col } from 'Support/grid';
import Loader from 'Support/loader';

import 'CSS/panes.css';

const route="/app/business_plans/feasibility/"

class FeasibilityPane extends React.Component{
  //Version Routing
  constructor(props) {
    super(props);
      this.state = {
        version_data:"",
        version_render: Session.getVersionRender(),
        tab_preference:false,
        version_loaded:false,
        years:""
    }
    this.handleSelect=this.handleSelect.bind(this);
  }

  screen_width = (window.innerWidth > 0) ? window.innerWidth : window.width;

  componentDidMount(){
    if(this.screen_width>756){
      this.setState({
        tab_preference:true,
        years: this.props.feasibility_settings.historic_years.concat(this.props.feasibility_settings.years)
      })
    }
    this.getVersion()
  }
  getVersion=async()=>{
    const request={
      version_id:this.props.version_id,
      project_id:this.props.project_id
    }
    try {
      const response = await ApiConnect.post('/api/version/get', request)
        this.setState({
          version_data:response.data,
          version_loaded:true
        })
    } catch(error) {
        alert(error);
    }
  }

  handleSelect(selectedKey) {
    this.setState({
      version_render: selectedKey
    })
    Session.setVersionRender(selectedKey)
    this.props.handlePrincipalRouting(route+selectedKey)
  }

  handleChangeFeasibilityHeader=()=>{
    let go=this.state.tab_preference
    this.setState({
      tab_preference:!go
    })
  }


  //Generic List functions
  handleLists={
    handleSaveSettings: async(version_data)=>{
      const request={
        project_id:this.props.project_id,
        version_id:this.props.version_id,
        content:version_data
      }
      try{
        this.props.activateAlert('loading')
        const response=await ApiConnect.post('/api/version/settings/update', request)
        this.setState({
          version_data:response.data
        })
        this.props.activateAlert('ok_auto','saved_h')
      } catch(error) {
        alert(error);
      }
    },

    handleAdd: async(element_to_add, type)=>{
      const request={
        item_type:type, 
        version_id: this.props.version_id, 
        user_id:this.props.user_id, 
        project_id:this.props.project_id ,
        content: element_to_add
      }  
      try{
        this.props.activateAlert('loading')
        const response= await ApiConnect.post(`/api/multitem/item/create`, request)
        let go = this.state.version_data
        go[type] =response.data
        this.setState({
          version_data:go
        })
        this.props.activateAlert('ok_auto','saved_h')
      } catch(error) {
        alert(error);
      }
    },

    handleSaveUpdater:(updated_version, type)=>{
      let go = this.state.version_data
      go[type] =updated_version
      this.setState({
        version_data:go
      })
    },

    handleDuplicateItem: async(event)=>{
      const type=event.target.name
      const request={
        project_id:this.props.project_id,
        version_id:this.props.version_id,
        item_id:event.target.value,
        item_type:type
      }
      try{
        this.props.activateAlert('loading')
        const response= await ApiConnect.post(`/api/multitem/item/duplicate`, request)
        let go = this.state.version_data
        go[type] =response.data
        this.setState({
          version_data:go
        })
        this.props.activateAlert('ok_auto','saved_h')
      } catch(error) {
        alert(error);
      }
    },
  
    handleDeleteItem:async(event)=>{
      const type=event.target.name
      const request={
        project_id:this.props.project_id,
        version_id:this.props.version_id,
        item_id:event.target.value,
        item_type:type
      }
      try{
        this.props.activateAlert('loading')
      const response= await ApiConnect.post(`/api/multitem/item/delete`, request)
      let go = this.state.version_data
      go[type] =response.data
      this.setState({
        version_data:go
      })
      this.props.activateAlert('ok_auto','deleted_h')
      } catch(error) {
        alert(error);
      }
    },
  
    handleChangLevel: async(event)=>{
      const type=event.target.name
      const position=event.target.id-1
      const new_position = parseInt(event.target.value)-1
      const request={
        project_id:this.props.project_id,
        version_id:this.props.version_id,
        item_type:type,
        position:position,
        new_position:new_position
      }
      try{
      this.props.activateAlert('loading')
      const response= await ApiConnect.post(`/api/multitem/item/changeposition`, request)
      let go = this.state.version_data
      go[type] =response.data
      this.setState({
        version_data:go
      })
      this.props.activateAlert('ok_auto','saved_h')
      } catch(error) {
        alert(error);
      }
    },

    handleCocSave: async(data)=>{
      const request={
        project_id:this.props.project_id,
        version_id:this.props.version_id,
        item_type:"coc",
        content:data
      }
      try{
        this.props.activateAlert('loading')
        await ApiConnect.post('/api/singleitem/save', request)
        let go = this.state.version_data
        go.coc = data
        this.setState({
          version_data: go
        })
        this.props.activateAlert('ok_auto','saved_h')
      } catch(error) {
        alert(error);
      }
    }
  }

  render(){
    const props={
      screen_width:this.screen_width,
      version_id:this.props.version_id,
      project_id:this.props.project_id,
      version_data:this.state.version_data,
      years:this.state.years,
      tab_preference:this.state.tab_preference,
      user_permission:this.props.project_data.user_permission,
      feasibility_settings:this.props.feasibility_settings,
      p_name:this.props.project_data.project_name,
      v_name:this.props.v_name,
      version_render:this.state.version_render,
      handleEditItem:this.props.handleEditItem,
      active_key:this.state.version_render,
      handleSelect:this.handleSelect,
      handleChangeFeasibilityHeader:this.handleChangeFeasibilityHeader,
      handleLists:this.handleLists
    }

    function torender(data, i){
      return data[i];
    }  
    const pages ={
      true: 
      <ToDisplay {...props}/>,
      
      false: 
      <Loader loader_message="vers_edit"/>
    }
    var i = this.state.version_loaded
    const renderizing = torender(pages,i)

    return(
      <>
        {renderizing}
      </>
    )
  }
}

class ToDisplay extends React.Component {
  render(){
    const props=this.props
    
    const panes={
      true:<Pane1 {...props}/>,
      false:<Pane2 {...props}/>
    }

    function selectPane(options,input){
      return options[input]
    }
    let render=selectPane(panes,this.props.tab_preference)
    return(
      <>
        <Row>
          <Col xs={12}>
            <VersionHeaderBar 
            p_name={this.props.p_name} 
            v_name={this.props.v_name} 
            version_render={this.props.version_render}
            screen_width={this.props.screen_width}
            handleEditItem={this.props.handleEditItem}
            handleChangeFeasibilityHeader={this.props.handleChangeFeasibilityHeader}/>
          </Col>
          {render}
        </Row>
      </>
    )
  }
}

class Pane1 extends React.Component{
  render(){
    const props=this.props
    return(
      <>
        <Col xs={2} className='bk_darkshade'>
          <Navigator active_key={this.props.active_key} handleSelect={this.props.handleSelect}/>
        </Col>
        <Col xs={10} className="pane_content">
          <PaneRoutes {...props}/>
        </Col>
      </>
    );
  }
}

class Pane2 extends React.Component{
  render(){
    const props=this.props
    return(
      <>
        <Col xs={12} className="pane_navigator2">
          <Navigation2 active_key={this.props.active_key} handleSelect={this.props.handleSelect}/>
        </Col>
        <Col xs={12}>
          <PaneRoutes {...props}/>
        </Col>
      </>
    );
  }
}

function PaneRoutes (props){
  return(
    <Routes>
      <Route index  element={<VersionMain {...props}/>}/>
      <Route path='version' element={<VersionMain {...props}/>}/>
      <Route path='revenues' element={<RevenuesAndCogsMain {...props}/>}/>
      <Route path={`operating_expenses`} element={<OperatingExpensesMain {...props}/>}/>
      <Route path={`hr`} element={<HrMain {...props}/>}/>
      <Route path={`other_gains_cost`} element={<OtherGainsCostMain {...props}/>}/>
      <Route path={`investment`} element={<InvestmentMain {...props}/>}/>
      <Route path={`shareholders`} element={<ShareholdersMain {...props}/>}/>
      <Route path={`debt`} element={<DebtMain {...props}/>}/>
      <Route path={`other_balance`} element={<OtherBalanceMain {...props}/>}/>
      <Route path={`cost_of_capital`} element={<CocAssumptionMain {...props}/>}/>
      <Route path={`dashboard`} element={<Dashboard {...props}/>}/>
      <Route path={`sensitivity_analysis`} element={<SensitivityAnalysis {...props}/>}/>
    </Routes>
  )
}


class Navigator extends React.Component {
  static contextType = UserContext
  render(){
    const props=this.props
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_PROJECT.PROJECT_FEASIBILITY.Navigation&Header')

    return(     
      <Navigation {...props} >
        <Navigation.NavigationMega>{trsl['assumptions']}</Navigation.NavigationMega>
        <Navigation.Item name='version'>{trsl['version']}</Navigation.Item>
        <Navigation.Item name='revenues'>{trsl['revenue']}</Navigation.Item>
        <Navigation.Item name='operating_expenses'>{trsl['operating_expenses']}</Navigation.Item>
        <Navigation.Item name='hr'>{trsl['hr']}</Navigation.Item>
        <Navigation.Item name='other_gains_cost'>{trsl['other_gains_cost']}</Navigation.Item>
        <Navigation.Item name='investment'>{trsl['investment']}</Navigation.Item>
        <Navigation.Item name='shareholders'>{trsl['shareholders']}</Navigation.Item>
        <Navigation.Item name='debt'>{trsl['debt']}</Navigation.Item>
        <Navigation.Item name='other_balance'>{trsl['other_balance']}</Navigation.Item>
        <Navigation.Item name='cost_of_capital'>{trsl['cost_of_capital']}</Navigation.Item>
        <Navigation.NavigationMega>{trsl['results']}</Navigation.NavigationMega>
        <Navigation.Item name='dashboard'>{trsl['dashboard']}</Navigation.Item>
        <Navigation.Item name='sensitivity_analysis'>{trsl['sensitivity_analysis']}</Navigation.Item>
      </Navigation>
    );
  }
}

class Navigation2 extends React.Component {
  static contextType = UserContext
  render(){
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_PROJECT.PROJECT_FEASIBILITY.Navigation&Header')
    const label=trsl[this.props.active_key]

    return(     
      <Nav variant="primary" activeKey={this.props.active_key} onSelect={this.props.handleSelect}>
        <NavDropdown title={label}>
          <NavDropdown.Item eventKey='version'>{trsl['version']}</NavDropdown.Item>
          <NavDropdown.Item eventKey='revenues'>{trsl['revenue']}</NavDropdown.Item>
          <NavDropdown.Item eventKey="operating_expenses">{trsl['operating_expenses']}</NavDropdown.Item>
          <NavDropdown.Item eventKey="hr">{trsl['hr']}</NavDropdown.Item>
          <NavDropdown.Item eventKey="other_gains_cost">{trsl['other_gains_cost']}</NavDropdown.Item>
          <NavDropdown.Item eventKey="investment">{trsl['investment']}</NavDropdown.Item>
          <NavDropdown.Item eventKey="shareholders">{trsl['shareholders']}</NavDropdown.Item>
          <NavDropdown.Item eventKey="debt">{trsl['debt']}</NavDropdown.Item>
          <NavDropdown.Item eventKey="other_balance">{trsl['other_balance']}</NavDropdown.Item>
          <NavDropdown.Item eventKey="cost_of_capital">{trsl['cost_of_capital']}</NavDropdown.Item>
          <NavDropdown.Item eventKey='dashboard'>{trsl['dashboard']}</NavDropdown.Item>
          <NavDropdown.Item eventKey='sensitivity_analysis'>{trsl['sensitivity_analysis']}</NavDropdown.Item>
        </NavDropdown>
      </Nav>
    );
  }
}

export default FeasibilityPane;