import React from 'react';
import { Row, Col } from 'Support/grid';

import UserContext from 'CONFIG/auth';

import Loader from 'Support/loader';
import {PeriodHeader, NonEditableForm} from 'Support/inputforms'

class HistoricAnalysis extends React.Component {
  static contextType = UserContext


  render() {
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_PROJECT.PROJECT_HISTORIC.Historic_analysis')

    const props ={
      user_c:user_c,
      trsl:trsl,
      handleScreenNavigation:this.props.handleScreenNavigation,
      data_is:this.props.data.h_inc_st,
      data_Analysis:this.props.data.h_bal,
      data_ratios:this.props.data.h_ratios,
      feasibility_settings:this.props.feasibility_settings,
      user_permission:this.props.user_permission
  }

    function torender(data, i){
      return data[i];
    }  
    const pages ={
      true: 
      <ToDisplay {...props}/>,
      
      false: 
      <Loader loader_message="vers_edit"/>
    }
    var i = this.props.loaded
    const renderizing = torender(pages,i)

    return(
      <Row>
        {renderizing}
      </Row>
    )
  }
}

class ToDisplay extends React.Component {
  render(){
    const h_year_arr=[0,0,0]
    for(var i=0;i<h_year_arr.length;i++){
      h_year_arr[i]=parseFloat(this.props.feasibility_settings.starting_year)+parseFloat([i])-3
    }
    const props=this.props
    return(
      <Col xs={12}>
        <GlobalHeader {...props}/>
        <Row>
          <Col xs={12} >
            <PeriodHeader 
            label={this.props.trsl['year']}
            periods={h_year_arr} />
          </Col>
          <Col xs={12} className="feasibility_list">
            <Analysis {...props}/>
          </Col>
          <Col className='blanktop_10'></Col>
        </Row>
      </Col>
    );
  }
}

class GlobalHeader extends React.Component{
  render(){
    let edit_ony_permission=true
    if(this.props.user_permission=="owner" || this.props.user_permission=="edit"){
      edit_ony_permission= false
    }
    return(
      <div>
        <Row className="feasibility_edit_globalheader_row">
          <Col xs={12}>
            <h4 className="headingtitle">{this.props.trsl['header']}</h4>
          </Col>
        </Row>
      </div>
    );
  }
}

class Analysis extends React.Component{
  render(){
    const nonEditableTesterFunction=(input,trsl,id,vt)=>{
      return(
        <div className='hist_analysis_rows'>
          <NonEditableForm 
          item_holder={input}
          id={id}
          label={trsl[id]}
          value_type={vt}
          />
        </div>
      )
    }
    return(
      <>
        <h5 className='hist_analysis_h5'>{this.props.trsl['profitability']}</h5>
        {nonEditableTesterFunction(this.props.data_ratios.h_gross_margin, this.props.trsl, 'h_gross_margin', 'percentage')}
        {nonEditableTesterFunction(this.props.data_ratios.h_cogs_rate, this.props.trsl, 'h_cogs_rate','percentage')}
        {nonEditableTesterFunction(this.props.data_ratios.h_net_margin, this.props.trsl, 'h_net_margin', 'percentage')}
        {nonEditableTesterFunction(this.props.data_ratios.h_ros, this.props.trsl, 'h_ros', 'percentage')}
        {nonEditableTesterFunction(this.props.data_ratios.h_roa, this.props.trsl, 'h_roa', 'percentage')}
        {nonEditableTesterFunction(this.props.data_ratios.h_roe, this.props.trsl, 'h_roe', 'percentage')}
        <h5 className='hist_analysis_h5'>{this.props.trsl['structure']}</h5>
        {nonEditableTesterFunction(this.props.data_ratios.h_equity_ratio, this.props.trsl, 'h_equity_ratio','percentage')}
        {nonEditableTesterFunction(this.props.data_ratios.h_solvency, this.props.trsl, 'h_solvency','percentage')}
        {nonEditableTesterFunction(this.props.data_ratios.h_liquidity, this.props.trsl, 'h_liquidity', 'percentage')}
        {nonEditableTesterFunction(this.props.data_ratios.h_average_cost_of_debt, this.props.trsl, 'h_average_cost_of_debt', 'percentage')}
        {nonEditableTesterFunction(this.props.data_ratios.h_interest_coverage, this.props.trsl, 'h_interest_coverage', 'percentage')}
        <h5 className='hist_analysis_h5'>{this.props.trsl['receivpay']}</h5>
        {nonEditableTesterFunction(this.props.data_ratios.h_dar, this.props.trsl, 'h_dar')}
        {nonEditableTesterFunction(this.props.data_ratios.h_dap, this.props.trsl, 'h_dap')}
        {nonEditableTesterFunction(this.props.data_ratios.h_dos, this.props.trsl, 'h_dos')}
        <h5 className='hist_analysis_h5'>{this.props.trsl['depreciations']}</h5>
        {nonEditableTesterFunction(this.props.data_ratios.h_depreciation_rate, this.props.trsl, 'h_depreciation_rate', 'percentage')}
        <h5 className='hist_analysis_h5'>{this.props.trsl['corporate_tax']}</h5>
        {nonEditableTesterFunction(this.props.data_ratios.h_corporate_tax_rate, this.props.trsl, 'h_corporate_tax_rate', 'percentage')}
      </>
    )
  }
}

export default HistoricAnalysis;