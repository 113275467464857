import {add, dotMultiply,dotDivide} from 'mathjs';

//check if about individual or group and provide number of owrkers


const salaryBasis=(c,settings)=>{
  let bug=new Array(c.hr_gross_salary.length).fill(1)
  if(c.hr_period>1){
    bug=dotMultiply(c.hr_worked_months,(settings.working_months/12))
  }
  return bug
}

const prizeBasis=(c)=>{
  let bug=new Array(c.hr_gross_salary.length).fill(1)
  if(c.hr_period>1){
    bug=c.hr_worked_months
  }
  return bug
}

//calculate gross salary
const cal_gross_salary =(c, basis)=>{
  if(c.hr_type===false){
    return dotMultiply(dotMultiply(c._hr_gross_salary_inflated,c.hr_number_of_workers),basis)
  } else{
    return dotMultiply(c._hr_gross_salary_inflated,basis)
  }
} 

const cal__prizes=(c, inflation, prize_basis)=>{
  if(c.hr_type===false){
    return dotMultiply(dotMultiply(dotMultiply(c.hr_prizes_bonus,inflation),c.hr_number_of_workers), prize_basis)
  } else{
    return dotMultiply(dotMultiply(c.hr_prizes_bonus,inflation), prize_basis)
  }
}

const calSubsidies=(c, inflation, prize_basis)=>{
  if(c.hr_type===false){
  return dotMultiply(dotMultiply(dotMultiply(c.hr_subsidy,inflation),c.hr_number_of_workers), prize_basis)
  } else{
    return dotMultiply(dotMultiply(c.hr_subsidy,inflation), prize_basis)
  }
}

const cal_insurances=(c, inflation, prize_basis)=>{
  if(c.hr_type===false){
  return dotMultiply(dotMultiply(dotMultiply(c.hr_insurances,inflation),c.hr_number_of_workers), prize_basis)
  } else{
    return dotMultiply(dotMultiply(c.hr_insurances,inflation), prize_basis)
  }
}

const cal_othercosts=(c, inflation, prize_basis)=>{
  if(c.hr_type===false){
  return dotMultiply(dotMultiply(dotMultiply(c.hr_othercosts,inflation),c.hr_number_of_workers), prize_basis)
  } else{
    return dotMultiply(dotMultiply(c.hr_othercosts,inflation), prize_basis)
  }
}

const calSocialSecurity = (c, settings)=>{
  let rate=dotMultiply(settings.corporate_social_security,0.01)  
  return dotMultiply(add(c._hr_gross_salary,c._hr_prizes_bonus),rate)
}

const cal_hr_total_cost=(c,settings)=>{
  let rate=add(1,dotMultiply(settings.corporate_social_security,0.01))
  let i = dotMultiply(add(c._hr_gross_salary,c._hr_prizes_bonus),rate)
  return add(i, c._hr_subsidy, c._hr_insurances, c._hr_othercosts)
}

  
const calculateHumanResources= (c,settings,inflation)=>{
  let salary_basis=salaryBasis(c,settings)
  let prize_basis=prizeBasis(c,settings)
  c._hr_gross_salary_inflated=dotMultiply(c.hr_gross_salary,inflation)
  c._hr_gross_salary=cal_gross_salary(c, salary_basis) 
  c._hr_prizes_bonus= cal__prizes(c, inflation, prize_basis)
  c._hr_social_security=calSocialSecurity(c,settings)
  c._hr_subsidy = calSubsidies(c, inflation, prize_basis)
  c._hr_insurances = cal_insurances(c, inflation, prize_basis)
  c._hr_othercosts = cal_othercosts(c, inflation, prize_basis)
  c._hr_total_cost=cal_hr_total_cost(c, settings)
  return c
};

function calRhIterate(hr,feasibility_settings,version_inflation){
  let new_stream=[]
  for(var i=0;i<hr.length;i++){
    new_stream[i]=calculateHumanResources(hr[i],feasibility_settings,version_inflation)
  }
  return new_stream
}

function calRhIterateSpecial(hr,feasibility_settings,version_inflation){
  let new_stream=[]
  for(var i=0;i<hr.length;i++){
    hr[i].hr_gross_salary=dotDivide(dotDivide(hr[i].hr_gross_salary,feasibility_settings.working_months),1+(feasibility_settings.corporate_social_security/100))
    new_stream[i]=calculateHumanResources(hr[i],feasibility_settings,version_inflation)
  }
  return new_stream
}

export {
  calRhIterate,
  calculateHumanResources,
  calRhIterateSpecial
}