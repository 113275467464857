import React from 'react'
import {Row, Col} from 'Support/grid'
import {Image} from 'react-bootstrap'

import audax_img from 'images/partners/audax_use.png'
import lispolis_img from 'images/partners/lispolis_use.png'
import fcsh_img from 'images/partners/nova_fcsh_use.png'
import tc_img from 'images/partners/territorios_criativos_use.png'
import btoc_img from 'images/partners/btoc_use.png'
import bazar_img from 'images/partners/bazaar_use.png'

function CasfloPartnerships(props){
  return(
    <>
      <Row className='main_screen_partnerships'>
        <Col xs={4} md={2} className='general_single_button blanktop_05'>
          <Image fluid src={audax_img} />
        </Col>
        <Col xs={4} md={2} className='general_single_button blanktop_05'>
          <Image fluid src={bazar_img} />
          </Col>
        <Col xs={4} md={2} className='general_single_button blanktop_05'>
          <Image fluid src={btoc_img} />
          </Col>
        <Col xs={4} md={2} className='general_single_button blanktop_05'>
          <Image fluid src={lispolis_img} />
        </Col>
        <Col xs={4} md={2} className='general_single_button blanktop_05'>
          <Image fluid src={fcsh_img} />
        </Col>
        <Col xs={4} md={2} className='general_single_button blanktop_05'>
          <Image fluid src={tc_img} />
        </Col>
      </Row>
    </>
  )
}

export default CasfloPartnerships