import React from 'react';
import {Grid, Row, Col} from 'Support/grid';
import { Route, Routes } from "react-router-dom";

import ProjectsList from './business_plan_list';
import AddProject from './business_plan_add';
import ProjectEdit from './bp_main';
import CanvaMain from '../Canva/canva_main';
import FeasibilityPane from '../Feasibility/feasibility_pane';
import FeasibilityHistoricPane from '../Feasibility_Historic/pane';
import FeasibilityDashboard from '../Feasibility/feasibility_dashboard';
import ProjectSettings from './settings';
import Exporting from '../Export Central/exportmain'
import ProjectColaboratorsViewers from './project_colaborators_viewers'
import {ProjectCreator} from './project_creator';

import UserContext from 'CONFIG/auth'
import Session from 'CONFIG/session'
import ApiConnect from 'CONFIG/axios';

import { SubscriptionManageInProject } from 'PRIVATE/ACCOUNT/subscription_manage';
import { Loader2 } from 'Support/loader';

const route="/app/business_plans/"


class BusinessPlanRoot extends React.Component{
  static contextType = UserContext
  constructor(props) {
    super(props);
    this.state = {
      project_id:Session.getBusinessPlanProjectId(),
      project_render_state: "edit",
      project_data:"",
      feasibility_settings:"",
      loaded_business_plan:false,
      version_id:Session.getVersionId(),
      version_name:Session.getVersionName(),
      versions_list: []
    }
    this.handleEditItem=this.handleEditItem.bind(this)
    this.handleGoToCanva=this.handleGoToCanva.bind(this)
  }

  async componentDidMount () {
    if(this.state.project_id !==null){
      await this.updateSettingsData()
      this.updateVersionData()
    }
  }

  handleSetBusinessPlanProject=async (event)=>{
    const name = event.target.name
    const value =event.target.value
    Session.setBusinessPlanProjectId(value)
    this.props.handlePrincipalRouting(route+name)
    await this.setState({
      project_id:value,
      loaded_business_plan:false
    })
    await this.updateVersionData()
    this.updateSettingsData()

  }

  handleNewProject =async(name, description, settings, subscription)=>{
    let content= new ProjectCreator(name, description, settings)
    const request ={
      content,
      user_id:this.props.user_id,
      subscription_id: subscription
    }    
    try{
        const response= await ApiConnect.post('/api/project/create', request)
        this.setState({ loaded:false})
        this.props.handlePrincipalRouting(route)
    } catch(error) {
        alert(`${error.response.status} ${error.response.data}`);
      }
  }

  updateSettingsData=async()=>{
    if(this.state.project_id.length>1){
      const request={
        project_id: this.state.project_id, 
      }
      try{
        const response = await ApiConnect.post('/api/project/data/get', request)
        this.setState({
          project_data:response.data.project_data,
          feasibility_settings:response.data.feasibility_settings,
          loaded_business_plan:true
        })
      }catch(error) {
        alert(error);
      }  
    }
  }

  handleEditItem=(event, versionizer,versionamer)=>{
    const name = event.target.name
    const version = versionizer
    const version_name =versionamer
    this.setState({
      version_id: version,
      project_render_state: name,
      version_name:version_name,
    })
    Session.setVersion(version,version_name)
    Session.setVersionRender("version")
    this.props.handlePrincipalRouting(route+name)
  }
 
  handleGoToSubscription=(event, versionizer,versionamer)=>{
    const name = event.target.name
    const version = versionizer
    const version_name =versionamer
    this.setState({
      version_id: version,
      project_render_state: name,
      version_name:version_name,
    })
    this.props.handlePrincipalRouting(route+name)
  }

  handleGoToCanva=(event)=>{
    const value = event.target.value
    const name = event.target.name
    this.setState({
      project_render_state: name
    })
    Session.setCanvaRender(value)
    this.props.handlePrincipalRouting(name)
  }

  handleGoToBudget=()=>{
    this.setState({
      project_render_state: 'budget'
    })
    this.props.handlePrincipalRouting(route+'budget')
  }

  handleGoToExporting=()=>{
    this.setState({
      project_render_state: 'exporting_central'
    })
    this.props.handlePrincipalRouting(route+'exporting_central')
  }

  handleExitFromGistoricToMain=()=>{
    this.updateVersionData()
    this.props.handlePrincipalRouting(route+'main')
  }

  handleExportAll=async()=>{
    const request={
      project_id:this.state.project_id
    }
    try{
      const response = await ApiConnect.post('/api/project/export/all', request)
    } catch(error) {
      this.props.activateAlert('error',error.response.status,error.response.data)
    }
  }

  //////VERSIONS
  updateVersionData=async()=>{
    const request={
      project_id:this.state.project_id
    }
    try{
      const response = await ApiConnect.post('/api/versions/get/all', request)
      this.setState({ 
        versions_list:response.data 
      });
    } catch(error) {
      this.props.activateAlert('error',error.response.status,error.response.data)
    }
  }
  handleAddVersion =async(name, description)=>{
    const request={
      project_id:this.state.project_id,
      version_name:name,
      version_description:description,
      size:this.state.feasibility_settings.number_of_years
    }
    try{
      const response= await ApiConnect.post(`/api/version/create`, request)
      this.props.activateAlert( 'ok_auto','',response.data)
      this.updateVersionData()
    } catch(error) {
      this.props.activateAlert( 'error',error.response.status,error.response.data)
    }
  }

  handleDeleteVersion=async(event)=>{
    const vgetter=event.target.value
    const pgetter=this.state.project_id
    try{
      const response= await ApiConnect.post(`/api/version/delete`, {version_id:vgetter, project_id:pgetter })
      this.props.activateAlert( 'ok_auto','',response.data)
      this.updateVersionData()
    } catch(error) {

      this.props.activateAlert( 'error',error.response.status,error.response.data)
    }
  }

  handleDuplicateVersion=async(event)=>{
    const vgetter=event.target.value
    const pgetter=this.state.project_id
    try{
      const response= await ApiConnect.post(`/api/version/duplicate`, {version_id:vgetter, project_id:pgetter })
      this.props.activateAlert('ok_auto','',response.data)
      this.updateVersionData()
    } catch(error) {
      this.props.activateAlert('error',error.response.status,error.response.data)
    }
  }
      
  render(){
    const props={
      project_id:this.state.project_id,
      version_id:this.state.version_id,
      project_data:this.state.project_data,
      feasibility_settings:this.state.feasibility_settings,
      v_name:this.state.version_name,
      versions_list:this.state.versions_list,
      project_render_state:this.state.project_render_state,
      loaded_business_plan:this.state.loaded_business_plan,
      handleSetBusinessPlanProject:this.handleSetBusinessPlanProject,
      handleNewProject:this.handleNewProject,
      handleEditItem:this.handleEditItem,
      handleGoToSubscription:this.handleGoToSubscription,
      handleGoToCanva:this.handleGoToCanva,
      updateSettingsData:this.updateSettingsData,
      handleGoToBudget:this.handleGoToBudget,
      handleGoToExporting:this.handleGoToExporting,
      handleAddVersion:this.handleAddVersion,
      handleDeleteVersion:this.handleDeleteVersion,
      handleDuplicateVersion:this.handleDuplicateVersion,
      handlePrincipalRouting:this.props.handlePrincipalRouting,
      handlePrincipalButtonRouting:this.props.handlePrincipalButtonRouting,
      handleExitFromGistoricToMain:this.handleExitFromGistoricToMain,
      activateAlert: this.props.activateAlert
    }
    return(

        <ToDisplay {...props}/>
    )
  }
}

function ToDisplay(props){
  return(
    <Grid >
      <Row className="project_main_placer">
        <Col xs={12}>
          <Routes>
            <Route index element={<ProjectsList {...props}/>} />
            <Route path='main' element={<ProjectEdit {...props} />}/>
            <Route path={`add`} element={<AddProject {...props} />}/>
            <Route path={`projects_list`} element={<ProjectsList {...props} />}/>
            <Route path={`canva/*`} element={<CanvaMain {...props}/>}/>            
            <Route path={`feasibility/*`} element={<FeasibilityPane {...props}/>}/>
            <Route path={`feasibility_historic/*`} element={<FeasibilityHistoricPane {...props} handleExitFromGistoricToMain={props.handleExitFromGistoricToMain}/>}/>
            <Route path={`settings`} element={<ProjectSettings {...props}/>}/>
            <Route path={`colaboratorsviewers`} element={<ProjectColaboratorsViewers {...props}/>}/>
            <Route path={`feasibilitydashboard`} element={<FeasibilityDashboard {...props}/>}/>
            <Route path={`exporting_central`} element={<Exporting {...props}/>}/>
            <Route path={`subscription`} element={<SubscriptionManageInProject {...props}/>}/>
          </Routes>
        </Col>
      </Row>
    </Grid>
  );
}

export default BusinessPlanRoot;