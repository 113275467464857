import React from 'react';
import { Row, Col } from 'Support/grid';

import UserContext from 'CONFIG/auth';

import Loader from 'Support/loader';
import {PeriodHeader, FormEdit,NonEditableForm} from 'Support/inputforms'
import { WarningCard } from 'Support/warning_cards';
import { RegularButtonSmall } from 'Support/listbuttons';

class HistoricIncomeStatement extends React.Component {
  static contextType = UserContext
  render() {
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_PROJECT.PROJECT_HISTORIC.Forms')

    const props ={
      trsl:trsl,
      handleSave:this.props.handleSave,
      handleScreenNavigation:this.props.handleScreenNavigation,
      handleEditChangeIncSt:this.props.handleEditChangeIncSt,
      handleEditChangeBs:this.props.handleEditChangeBs,
      data:this.props.data,
      feasibility_settings:this.props.feasibility_settings,
      user_permission:this.props.user_permission
  }

    function torender(data, i){
      return data[i];
    }  
    const pages ={
      true: 
      <ToDisplay {...props}/>,
      
      false: 
      <Loader loader_message="vers_edit"/>
    }
    var i = this.props.loaded
    const renderizing = torender(pages,i)

    return(
      <Row>
        {renderizing}
      </Row>
    )
  }
}

class ToDisplay extends React.Component {
  render(){
    const h_year_arr=[0,0,0]
    for(var i=0;i<h_year_arr.length;i++){
      h_year_arr[i]=parseFloat(this.props.feasibility_settings.starting_year)+parseFloat([i])-3
    }
    const props=this.props
    return(
      <Col xs={12}>
        <GlobalHeader {...props}/>
        <Row>
          <Col xs={12} >
            <PeriodHeader label={this.props.trsl['is']} periods={h_year_arr} />
            <IS {...props}/>
          </Col>
          <Col xs={12} className='blanktop_15'>
            <PeriodHeader label={this.props.trsl['bs']} periods={h_year_arr} />
            <BS {...props}/>
            <Warnings assets={this.props.data.h_bal.h_total_assets} liabilities={this.props.data.h_bal.h_equity_liabilities} trsl={this.props.trsl}/>
          </Col>
          <Col className='blanktop_10'></Col>
        </Row>
      </Col>
    );
  }
}

class GlobalHeader extends React.Component{
  render(){
    let edit_ony_permission=true
    if(this.props.user_permission=="owner" || this.props.user_permission=="edit"){
      edit_ony_permission= false
    }
    return(
      <div>
        <Row className="feasibility_edit_globalheader_row">
          <Col xs={9}>
            <h4 className="headingtitle">{this.props.trsl['header']}</h4>
          </Col>
          <Col xs={3}>
            <RegularButtonSmall bk='bk_success' label={this.props.trsl['save']} onClick={this.props.handleSave}/>
          </Col>
        </Row>
      </div>
    );
  }
}

class IS extends React.Component{
  render(){
    const data=this.props.data
    const testerFunction=(input,trsl,id)=>{
      if(input!=undefined){
        return(
          <FormEdit 
          item_holder={input}
          id={id}
          handleForm={this.props.handleEditChangeIncSt}
          label={trsl[id]}
          unit={new Intl.NumberFormat('ja-JP', { style: 'currency', currency: this.props.feasibility_settings.currency}).format(0).replace(/\d./g,"")}
          />
        )
      } else{
        return(
          <FormEdit 
          item_holder={[0,0,0]}
          id={id}
          handleForm={this.props.handleEditChangeIncSt}
          label={trsl[id]}
          unit={new Intl.NumberFormat('ja-JP', { style: 'currency', currency: this.props.feasibility_settings.currency}).format(0).replace(/\d./g,"")}
          />
        )
      }
    }
    const nonEditableTesterFunction=(input,trsl,id)=>{
      if(input!=undefined){
        return(
          <NonEditableForm 
          item_holder={input}
          id={id}
          label={trsl[id]}
          decimals={true}
          />
        )
      } else{
        return(
          <NonEditableForm 
          item_holder={[0,0,0]}
          id={id}
          label={trsl[id]}
          decimals={true}
          />
        )
      }
    }
    return(
      <>
        {testerFunction(data.h_inc_st.h_revenue, this.props.trsl, 'h_revenue')}
        {testerFunction(data.h_inc_st.h_operational_grants, this.props.trsl, 'h_operational_grants')}
        {testerFunction(data.h_inc_st.h_cogs, this.props.trsl, 'h_cogs')}
        {testerFunction(data.h_inc_st.h_operating_expenses, this.props.trsl, 'h_operating_expenses')}
        {testerFunction(data.h_inc_st.h_hr, this.props.trsl, 'h_hr')}
        {testerFunction(data.h_inc_st.h_other_gains, this.props.trsl, 'h_other_gains')}
        {testerFunction(data.h_inc_st.h_other_costs, this.props.trsl, 'h_other_costs')}
        {nonEditableTesterFunction(data.h_inc_st.h_ebitda, this.props.trsl, 'h_ebitda')}
        {testerFunction(data.h_inc_st.h_depreciations, this.props.trsl, 'h_depreciations')}
        {nonEditableTesterFunction(data.h_inc_st.h_ebit, this.props.trsl, 'h_ebit')}
        {testerFunction(data.h_inc_st.h_interest_cost, this.props.trsl, 'h_interest_cost')}
        {nonEditableTesterFunction(data.h_inc_st.h_ebt, this.props.trsl, 'h_ebt')}
        {testerFunction(data.h_inc_st.h_corporate_tax, this.props.trsl, 'h_corporate_tax')}
        {nonEditableTesterFunction(data.h_inc_st.h_net_profit, this.props.trsl, 'h_net_profit')}
      </>
    )
  }
}


class BS extends React.Component{
  render(){
    const data = this.props.data
    const testerFunction=(input,trsl,id)=>{
      if(input!=undefined){
        return(
          <FormEdit 
          item_holder={input}
          id={id}
          handleForm={this.props.handleEditChangeBs}
          label={trsl[id]}
          unit={new Intl.NumberFormat('ja-JP', { style: 'currency', currency: this.props.feasibility_settings.currency}).format(0).replace(/\d./g,"")}
          />
        )
      } else{
        return(
          <FormEdit 
          item_holder={[0,0,0]}
          id={id}
          handleForm={this.props.handleEditChangeBs}
          label={trsl[id]}
          unit={new Intl.NumberFormat('ja-JP', { style: 'currency', currency: this.props.feasibility_settings.currency}).format(0).replace(/\d./g,"")}
          />
        )
      }
    }
    const nonEditableTesterFunction=(input,trsl,id)=>{
      if(input!=undefined){
        return(
          <NonEditableForm 
          item_holder={input}
          id={id}
          label={trsl[id]}
          decimals={true}
          />
        )
      } else{
        return(
          <NonEditableForm 
          item_holder={[0,0,0]}
          id={id}
          label={trsl[id]}
          decimals={true}
          />
        )
      }
    }
    return(
      <>
        {testerFunction(data.h_bal.h_tangible_assets, this.props.trsl, 'h_tangible_assets')}
        {testerFunction(data.h_bal.h_intangible_assets, this.props.trsl, 'h_intangible_assets')}
        {testerFunction(data.h_bal.h_other_non_current_assets, this.props.trsl, 'h_other_non_current_assets')}
        {nonEditableTesterFunction(data.h_bal.h_total_non_current_assets, this.props.trsl, 'h_total_non_current_assets')}
        {testerFunction(data.h_bal.h_inventories, this.props.trsl, 'h_inventories')}
        {testerFunction(data.h_bal.h_clients, this.props.trsl, 'h_clients')}
        {testerFunction(data.h_bal.h_government_receivable, this.props.trsl, 'h_government_receivable')}
        {testerFunction(data.h_bal.h_other_current_assets, this.props.trsl, 'h_other_current_assets')}
        {testerFunction(data.h_bal.h_cash, this.props.trsl, 'h_cash')}
        {nonEditableTesterFunction(data.h_bal.h_total_current_assets, this.props.trsl, 'h_total_current_assets')}
        {nonEditableTesterFunction(data.h_bal.h_total_assets, this.props.trsl, 'h_total_assets')}
        {testerFunction(data.h_bal.h_issued_capital, this.props.trsl, 'h_issued_capital')}
        {testerFunction(data.h_bal.h_other_equity_instruments, this.props.trsl, 'h_other_equity_instruments')}
        {testerFunction(data.h_bal.h_other_equity_changes, this.props.trsl, 'h_other_equity_changes')}
        {testerFunction(data.h_bal.h_past_results, this.props.trsl, 'h_past_results')}
        {nonEditableTesterFunction(data.h_inc_st.h_net_profit, this.props.trsl, 'h_net_profit')}
        {nonEditableTesterFunction(data.h_bal.h_total_equity, this.props.trsl, 'h_total_equity')}
        {testerFunction(data.h_bal.h_debt, this.props.trsl, 'h_debt')}
        {testerFunction(data.h_bal.h_other_non_current_liabilities, this.props.trsl, 'h_other_non_current_liabilities')}
        {nonEditableTesterFunction(data.h_bal.h_total_non_current_liabilities, this.props.trsl, 'h_total_non_current_liabilities')}
        {testerFunction(data.h_bal.h_suppliers, this.props.trsl, 'h_suppliers')}
        {testerFunction(data.h_bal.h_shareholders_loan, this.props.trsl, 'h_shareholders_loan')}
        {testerFunction(data.h_bal.h_government_payable, this.props.trsl, 'h_government_payable')}
        {testerFunction(data.h_bal.h_other_accounts_payables, this.props.trsl, 'h_other_accounts_payables')}
        {testerFunction(data.h_bal.h_other_current_liabilities, this.props.trsl, 'h_other_current_liabilities')}
        {nonEditableTesterFunction(data.h_bal.h_total_current_liabilities, this.props.trsl, 'h_total_current_liabilities')}
        {nonEditableTesterFunction(data.h_bal.h_total_liabilities, this.props.trsl, 'h_total_liabilities')}
        {nonEditableTesterFunction(data.h_bal.h_equity_liabilities, this.props.trsl, 'h_equity_liabilities')}
      </>
    )
  }
}

class Warnings extends React.Component{
  render() {
    function renderWarnings(assets,liabilties,trsl){ 
      let arr=[] 
      const t=trsl
      let item_distr
      for(var i=0;i<assets.length;i++){
        if(assets[i].toFixed(2)===liabilties[i].toFixed(2)){
          arr[i] =<Col xs={4}><WarningCard label1={t['data_forms']} label2={t['equal']} color='success'/></Col>
        } else{
          arr[i] =<Col xs={4}><WarningCard label1 ={t['data_forms']} label2={t['not_equal']} color='danger'/></Col>
        }
      }
      item_distr=arr.map(v=>
      <> {v}</>)
      return item_distr
    }
    return(
      <Row>
        <Col xs={2}></Col>
        <Col xs={10}>
          <Row>
            {renderWarnings(this.props.assets,this.props.liabilities,this.props.trsl)}
          </Row>
        </Col>
      </Row>
    )
  }
}

export default HistoricIncomeStatement;