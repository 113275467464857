import React from 'react';

function SimpleHorizontalTab (props){
  function handleClick(value){
    const event={
      target:{
        name:value
      }
    }
    props.handleTabs(event)
  }


  const item_holder=props.options
  const selected=props.selected
  const item_distr=item_holder.map((el)=>{
    if(el.value===selected){
      return(
        <div className='spt_tabs_element bk_darkaccent' onClick={(e)=>{handleClick(el.value)}}>
          {el.label}
        </div>
      )
    } else{
        return(
          <div className='spt_tabs_element' onClick={(e)=>{handleClick(el.value)}}>
            {el.label}
          </div>
        )
      }
    }
  )
  return(
    <div className="spt_tabs_rows">
      {item_distr}
    </div>
  )
}

export{
  SimpleHorizontalTab
}