import React from 'react';
import { Row, Col} from 'Support/grid';
import {  RegularButtonFullHeight } from 'Support/listbuttons';
import { SingleInputForm, SingleInputLargeForm } from 'Support/inputforms';

const initial_state={
  version_name:"",
  version_description:""
}

class AddVersion extends React.Component{
  constructor(props) {
    super(props);
    this.state = initial_state
  }

  handleChange=(event)=>{
    const value=event.target.value;
    const name=event.target.name;
    let go=this.state;
    go[name]=value
    this.setState({
      go
    });
  }

  handleSubmitEvent=async(event)=>{
    await this.props.handleAdd(this.state.version_name, this.state.version_description);
    this.setState(
      initial_state
    )
  }

  render(){
    return (
      <Row >
          <Col xs={12} className='blanktop_15'>
            <h4>{this.props.trsl['add_version']}</h4>
            <Row>
            <SingleInputForm xs={12} md={12} label={this.props.trsl['version_name']} name="version_name" value={this.state.version_name} handleEditItemChange={this.handleChange}/>
            <SingleInputLargeForm xs={12} md={8} label={this.props.trsl['version_description']} maxlength={500} name="version_description" value={this.state.version_description} handleEditItemChange={this.handleChange}/>
            <Col xs={12}  md={4} className="blanktop_075 blankbottom_075">
              <RegularButtonFullHeight bk='bk_darkaccent' label={this.props.trsl['version_add_button']} disabled={this.props.permission} onClick={this.handleSubmitEvent}/>
            </Col>
            </Row>
          </Col>
      </Row>
    );
  }
}

export default AddVersion;