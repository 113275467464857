class RevenueCreator{
  constructor(rev_name, n_years,vat_rate,raw_materials,hist){
    this.rev_name=rev_name
    this.rev_quantity_type=1
    this.rev_price=new Array(n_years).fill(0)
    this._rev_price_inflated=new Array(n_years).fill(0)
    this.rev_quantity=new Array(n_years).fill(0)
    this.rev_quantity_growth=new Array(n_years).fill(0)
    this.rev_quantity_yearly_total = 0
    this.rev_price_yearly = 0
    this.rev_vat_rate=new Array(n_years).fill(vat_rate)
    this._rev_total_revenue=new Array(n_years).fill(0)
    this._rev_total_vat=new Array(n_years).fill(0)
    this._rev_receivable=new Array(n_years).fill(0)
    this.rev_cogs={
      cogs_type:raw_materials,
      s_cogs:{
        s_cogs_percentage:new Array(n_years).fill(0),
        s_cogs_vat_rate:new Array(n_years).fill(vat_rate),
      },
      c_cogs:[],
      _cogs_total:new Array(n_years).fill(0),
      _cogs_vat_total:new Array(n_years).fill(0),
      days_of_stock:new Array(n_years).fill(0),
      _inventory:new Array(n_years).fill(0),
      _cogs_payable:new Array(n_years).fill(0)
    }
    this._rev_hist=hist
    this.rev_observations=""
  }
  changeVAT(new_vat){
    this.revenue_vat_rate.fill(new_vat);
  }
}

class ComplexCogsCreator {
  constructor (complex_cogs_name,n_years,vat_rate) {
    this.c_cogs_item_name=complex_cogs_name
    this.c_cogs_item_value=new Array(n_years).fill(0)
    this._c_cogs_item_multiplied_value=new Array(n_years).fill(0)
    this.c_cogs_item_vat_rate=new Array(n_years).fill(vat_rate)
    this._c_cogs_item_total_vat=new Array(n_years).fill(0)
  }
}

export{ 
  RevenueCreator,
  ComplexCogsCreator
}
