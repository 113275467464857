import React from 'react';
import { Row, Col  } from 'Support/grid';


import UserContext from 'CONFIG/auth';
import ApiConnect from 'CONFIG/axios';
import Loader from 'Support/loader';

import { SingleInputForm, SingleInputIntegerForm, SingleInputLargeForm, Dropdown } from 'Support/inputforms';
import { ToogleButtons } from 'Support/toogle_buttons';

import countries from 'JSON/countries_pt.json';
import { RegularButtonSmall } from 'Support/listbuttons';

class Description extends React.Component{
  static contextType = UserContext

  constructor(props) {
    super(props);
      this.state = {
      data: "",
      canva_id:""
    }
    this.handleEditItem=this.handleEditItem.bind(this)
    this.handleSave=this.handleSave.bind(this)
  }

  componentDidMount(){
    this.updataData()
  }

  updataData=async()=>{
    const request={
      project_id: this.props.project_id,
      item_type: "description" 
    }
    try{
      const response = await ApiConnect.post('/api/project/canva/get', request)
      this.setState({
        data:response.data.item,
        canva_id:response.data.canva_id,
        loaded:true
      })
    }catch(error) {
      alert(error);
    }
  }

  handleEditItem(event){
    let go= this.state.data
    go[event.target.name]=event.target.value
    this.setState({
      data:go
    })
  }

  handleSave=async()=>{
    const request={
      canva_id: this.state.canva_id,
      item_type: "description",
      content:this.state.data 
    }
    try{
      const response = await ApiConnect.post('/api/project/canva/modify', request)
      this.props.activateAlert('ok_auto','Saved','', this.context)
    }catch(error) {
      alert(error);
    }
  }

  render(){
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_PROJECT.PROJECT_CANVA.Description')

    const props ={
      user_c:user_c,
      trsl:trsl,
      data:this.state.data,
      p_name:this.props.project_data.project_name,
      user_permission:this.props.user_permission,
      template:user_c.template,
      handleEditItem:this.handleEditItem,
      handleSave:this.handleSave
    }
    function torender(data, i){
      return data[i];
      }  
    const pages ={
    true: 
    <ToDisplay {...props} />,
    false: <Loader loader_message="Let's get hands on the job!"/>
    }
    var i = this.state.loaded
    const renderizing = torender(pages,i)

    return(
      <div>
        {renderizing}
      </div>
    )
  }
}


class ToDisplay extends React.Component{
  render(){
    const props=this.props
    return(
      <div>
        <Header {...props} />
        <Fields label={this.props.trsl['strength']} element="strengths" {...props} data={this.props.data}/>
      </div>
    );
  }
}

class Header extends React.Component{
  render(){
    let permission=true
    if(this.props.user_permission=="owner" || this.props.user_permission=="edit" ){
      permission= false
    }
    return(
      <div>
        <Row className="canva_header_row grid_middle">
          <Col xs={9}>
            <h4>{this.props.trsl['header']}</h4>
          </Col>
          <Col xs={3}>
            <RegularButtonSmall bk="bk_success" label={this.props.trsl['save_button']} onClick={this.props.handleSave} disabled={permission} />
          </Col>
        </Row>
      </div>
    )
  }
}

class Fields extends React.Component{
  render(){

    const situation_toogle = [
      {value:'startup', option_label:this.props.trsl['startup'], name:'situation'},
      {value:'scale-up', option_label:this.props.trsl['scale-up'], name:'situation'},
      {value:'entrepreneurship', option_label:this.props.trsl['entrepreneurship'], name:'situation'},
      {value:'established', option_label:this.props.trsl['established'], name:'situation'},
      {value:'unit', option_label:this.props.trsl['unit'], name:'situation'},
      {value:'department', option_label:this.props.trsl['department'], name:'situation'},
    ]

    //country selection
    const item_holder = countries[this.props.user_c.locale]
    let item_distr
    if(item_holder !==undefined){
      item_distr = Object.entries(item_holder).map(([key,value]) =>{
        return(
          {value:key, option_label:value}
        )
      })
    }
  
    const country_holder=(input)=>{
      let starter="PT"
      if(input===""){
      return(starter)
      } else{
        return(input)
      }
    }

    console.log(this.props.data)
    const country_selection=country_holder(this.props.data.incorporation_country)


    return(
      <Row >
          <ToogleButtons xs={12} label={this.props.trsl['situation']} toogle_data={situation_toogle} selected={this.props.data.situation} handleToogleButton={this.props.handleEditItem} />
          <SingleInputForm xs={12} label={this.props.trsl['formal_name']}  name={'formal_name'} value={this.props.data.formal_name} maxlength={200} handleEditItemChange={this.props.handleEditItem} />
          <SingleInputForm xs={12} md={5} label={this.props.trsl['incorporation']}  name={'incorporation'} value={this.props.data.incorporation} handleEditItemChange={this.props.handleEditItem}/>
          <Dropdown xs={12} md={4} label={this.props.trsl['incorporation_country']} name="incorporation_country" value={country_selection} options={item_distr} onChange={this.props.handleEditItem}/>
          <SingleInputIntegerForm xs={12} md={3} label={this.props.trsl['established_year']}  name={'established_year'} value={this.props.data.established_year } maxlength={4} handleinputeditChange={this.props.handleEditItem}/>
          <SingleInputForm xs={12} label={this.props.trsl['activity']}  name={'activity'} value={this.props.data.activity} handleEditItemChange={this.props.handleEditItem} />
          <SingleInputLargeForm xs={12}label={this.props.trsl['products']}  name={'products'} value={this.props.data.products} handleEditItemChange={this.props.handleEditItem} maxlength={2000}/>
          <SingleInputLargeForm xs={12}label={this.props.trsl['history']}  name={'history'} value={this.props.data.history} handleEditItemChange={this.props.handleEditItem} maxlength={2000}/>
      </Row>
    );
  }
}

export default Description;