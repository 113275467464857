import React from 'react';
import { Col} from 'Support/grid';
import { OverlayTrigger,Tooltip } from 'react-bootstrap';

function financingWarning(input, level){
  let bug="success"
  function setter(element){
    if(element<level){bug="danger"}
  }
  input.forEach(setter)
  return bug
}

function financingWarningForSingleValue(input, level){
  if(input<level ){
    return ("danger")
  } else {return("success")}
}

class DashboardWarning extends React.Component{
  render() {
    return(
      <>
        <Col xs={12}>
          <h3>{this.props.trsl['warnings']}</h3>
        </Col>
        <Warning warning_type='financing' trsl={this.props.trsl} color={financingWarning(this.props.data.results.cf.year_end_cash_flows,0)}/>
        <Warning warning_type='equity' trsl={this.props.trsl} color={financingWarning(this.props.data.results._bal.total_equity,0)}/>
        <Warning warning_type='net_income' trsl={this.props.trsl} color={financingWarning(this.props.data.results._inc_st.net_profit,0)}/>
        <Warning warning_type='npv' trsl={this.props.trsl} color={financingWarningForSingleValue(this.props.data.results.fe.npv,0)}/>
      </>
    );
  }
}

function Warning (props){
  const negatives={
    financing:'warning_neg_financing',
    equity:'warning_neg_equity',
    net_income:'warning_neg_net_income',
    npv:'warning_neg_npv'
  }
  function warning_textMarker(trsl,type){
    return trsl[negatives[type]]
  }
  const warning_text=warning_textMarker(props.trsl, props.warning_type)

  function renderTooltip(props){
    return(
      <Tooltip {...props}>
        {warning_text}
      </Tooltip>
    )
  }

  function renderOverlay(input){
    if(input=='danger'){
      return(
        <OverlayTrigger
        delay={{ show: 250, hide: 250 }}
        placement='bottom'
        overlay={renderTooltip}>
          <Col xs={6} md={3}>
            <div className={`warning_div_outside bk_${props.color}`} >
              <div className='warning_div_inside'>
                {props.trsl[props.warning_type]}
              </div>
            </div>
          </Col>
        </OverlayTrigger>
      )
    } else{
      return(
        <Col xs={6} md={3}>
          <div className={`warning_div_outside bk_${props.color}`}>
            <div className='warning_div_inside'>
              {props.trsl[props.warning_type]}
            </div>
          </div>
        </Col>
      )
    }
  }
  const renderizing=renderOverlay(props.color)
  
  return(
    <>
      {renderizing}
    </>
  );
}

export default DashboardWarning;