import React from 'react'

function BuyOptions(props){
  const handleClick=()=>{
    const event={
      target:{
        value:props.it.duration
      }
    }
    props.handleChangeDuration(event)
  }

  return(
    <div className='spt_bb_wrapper' onClick={handleClick}>
      <h5>
        {props.it.duration} {props.trsl['days']}
      </h5>
      <span className='spt_bb_pricer'>
        {props.user_c.i18n.currF(props.it.price,props.currency,true)}
      </span>
      <div>
      {props.trsl['default_versions']} 5
      </div>
    </div>
  )
}

function BuyOptionsSelected(props){
  const handleClick=()=>{
    const event={
      target:{
        value:props.it.duration
      }
    }
    props.handleChangeDuration(event)
  }

  return(
    <div className='spt_bb_wrapper bk_success' onClick={handleClick}>
      <h5>
        {props.it.duration} {props.trsl['days']}
      </h5>
      <span className='spt_bb_pricer'>
      {props.user_c.i18n.currF(props.it.price,props.currency,true)}
      </span>
      <div>
        {props.trsl['default_versions']} 5
      </div>
      <h6>{props.trsl['selected']}</h6>
    </div>
  )
}

function BuyOptionsDisabled(props){
  return(
    <div className='spt_bb_wrapper_disabled'>
      <h5>
        {props.it.duration} {props.trsl['days']}
      </h5>
      <span className='spt_bb_pricer'>
        {props.user_c.i18n.currF(props.it.price,props.currency,true)}
      </span>
      <div>
      {props.trsl['default_versions']} {props.it.versions}
      </div>
    </div>
  )
}

function BuyOptionExtraVersions(props){
  return(
    <div className='spt_bb_wrapper_disabled'>
      <h5>
        {props.trsl['extra_version']}
      </h5>
      <span className='spt_bb_pricer'>
        {props.user_c.i18n.currF(props.price,props.currency,true)}
      </span>
      <div>
        {props.trsl['extra_version_explain']}
      </div>
    </div>
  )
}

function CheckOptionProjectRevision(props){
  return(
    <div className='spt_bb_wrapper_disabled'>
      <h5>
        {props.trsl['project_revision']}
      </h5>
      <span className='spt_bb_pricer'>
        {props.user_c.i18n.currF(props.price,props.currency,true)}
      </span>
      <div>
        {props.trsl['project_revision_explain']}
      </div>
    </div>
  )
}

export {
  BuyOptions,
  BuyOptionsSelected,
  BuyOptionsDisabled,
  BuyOptionExtraVersions,
  CheckOptionProjectRevision
}

