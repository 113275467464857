import React from 'react';
import { Row, Col } from 'Support/grid';
import OperatingExpEntry from './operating_exp';
import OperatingExpList from './operating_exp_list';

class OperatingExpMain extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            to_render: "list",
            active_item:0
        }
        this.handleScreenNavigation=this.handleScreenNavigation.bind(this)
        this.changeScreenView=this.changeScreenView.bind(this)
    }

    changeScreenView=(window, item)=>{
        this.setState({
            active_item: item,
            to_render: window
        })
    }

    handleScreenNavigation(event){
        const value = event.target.value
        const name = event.target.name
        this.changeScreenView(name, value)
    }

    render(){
        const props={
            version_id:this.props.version_id,
            active_item:this.state.active_item,
            project_id:this.props.project_id,
            version_data:this.props.version_data,
            changeScreenView:this.changeScreenView,
            feasibility_settings:this.props.feasibility_settings,
            user_permission:this.props.user_permission,
            handleScreenNavigation:this.handleScreenNavigation,
            handleLists:this.props.handleLists
        }

        function torender(data, i){
            return data[i];
        }
        const pages ={
            edit: <OperatingExpEntry {...props}/>,
            list: <OperatingExpList {...props}/>
        }
        var i = this.state.to_render
        const renderizing = torender(pages,i)

    return(
        <Row>
            <Col xs={12}>
                {renderizing}
            </Col>
        </Row>
    );
  }
}

export default OperatingExpMain;