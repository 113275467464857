import React, {useContext} from 'react';
import UserContext from '../CONFIG/auth';
import {HeaderCASFLO} from 'TEMPLATES/CASFLO/header_footer'
import {FooterCASFLO} from 'TEMPLATES/CASFLO/header_footer'
import {HeaderViseeon} from 'TEMPLATES/Viseeon/header_footer'
import {FooterViseeon} from 'TEMPLATES/Viseeon/header_footer'
import {HeaderRegularBazaar} from 'TEMPLATES/BAZAAR/header_footer'
import {FooterBazaar} from 'TEMPLATES/BAZAAR/header_footer'

class Header extends React.Component{
  static contextType = UserContext


  handleLogOut=()=>{
    this.context.unSetUser()
  }

  handleLocale=(event)=>{
    this.context.setLocale(event.target.value)
  }

  render(){
    let user_c= this.context

    let avatar= user_c.user_avatar
    if(avatar===undefined){avatar= 1}

    const props={
      handlePrincipalButtonRouting:this.props.handlePrincipalButtonRouting,
      handleGoToGeneralRoot:this.props.handleGoToGeneralRoot,
      avatar:avatar,
      user_c:user_c,
      handleLogOut:this.handleLogOut,
      handleLocale:this.handleLocale
    }

    const headers={
      0:<HeaderCASFLO {...props}/>,
      '':<HeaderCASFLO {...props}/>,
      casflo:<HeaderCASFLO {...props}/>,
      viseeon:<HeaderCASFLO {...props}/>,
      bazaar: <HeaderRegularBazaar {...props}/>
    }

    function renderHeader(input){
      return( headers[input])
    }
    const renderizing= renderHeader(user_c.template)

    return(
      <>
        {renderizing}
      </>
    )
  }
}


function Footer(props){
  const context = useContext(UserContext)
  const footers={
    '':<FooterCASFLO {...props}/>,
    casflo:<FooterCASFLO {...props}/>,
    viseeon:<FooterCASFLO {...props}/>,
    bazaar: <FooterBazaar {...props}/>
  }

  function renderFooter(input){
    return( footers[input])
  }
  const renderizing= renderFooter(context.template)

  return(
    <>
      {renderizing}
    </>
  )
}


export{
  Header,
  Footer
};