const {sum, dotMultiply} = require('mathjs');

const calShareholderPremiumRate=(option,increase,premium)=>{
  let bug=new Array(increase.length).fill(0)
  if(option===true){
    for(var i=0;i<increase.length;i++){
      if(increase[i]>0 &&premium[i]>0){
        bug[i]=premium[i]/increase[i]
      } else{
        bug[i]=0
      }
    }
  }
  return bug
}

const calResetSharePremium=(option,premium)=>{
  let bug=new Array(premium.length).fill(0)
  if(option===true){
    bug=premium
  }
  return bug
}

const calAccumulatSharePremium=(option,premium)=>{
  let bug=new Array(premium.length).fill(0)
  if(option===true){
    for(var i=0;i<premium.length;i++){
      if(i===0){
        bug[i]=premium[i]
      }else {
        bug[i]=parseFloat(bug[i-1])+parseFloat(premium[i])
      }
    }
  }
  return bug
}

const accumulateShareholders=(t)=>{
  var accumulated=[]
  let i
  for(i=0;i<t.shareholders_increase.length;i++){
    if(i===0){
      accumulated[i]=sum(t.shareholders_increase[i],-t.shareholders_decrease[i])
    } else{
      accumulated[i]=sum(accumulated[i-1],t.shareholders_increase[i],-t.shareholders_decrease[i])
    }
  }
  return accumulated
}

const calculateShareholders= (c)=>{
  //accumulate each item operating expenses
  c._shareholders_total = accumulateShareholders(c)
  c.shareholders_share_premium=calResetSharePremium(c.shareholders_premium_option,c.shareholders_share_premium)
  c._shareholders_share_premium_rate=calShareholderPremiumRate(c.shareholders_premium_option,c.shareholders_increase,c.shareholders_share_premium)
  c._shareholders_share_premium_accumulated=calAccumulatSharePremium(c.shareholders_premium_option,c.shareholders_share_premium)
  c._shareholders_loan_interest=dotMultiply((c.shareholders_loan_interest_rate/100), c._shareholders_total)

  return c
};

function calShareholdersIteration(shareholders){
    let new_stream=[]
    for(var i=0;i<shareholders.length;i++){
      new_stream[i]=calculateShareholders(shareholders[i])
    }
    return new_stream
  }

export {
    calculateShareholders,
    calShareholdersIteration
}