import React from 'react';
import{Grid, Row, Col} from 'Support/grid'
import {Card, Button} from 'react-bootstrap';

import './partners.css'
import PartnershipPacks from './partners_packs';
import PartnersManage from './partners_manage';

import UserContext from 'CONFIG/auth';
import ApiConnect from 'CONFIG/axios';

class PartnerMain extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      screen: "partners_packs",
      partnership_pack_id:'',
    }
    this.handleGoToManage=this.handleGoToManage.bind(this)
    this.handleGoToPack=this.handleGoToPack.bind(this)
    this.handleAddReceiver=this.handleAddReceiver.bind(this)
  }

  handleGoToPack=()=>{
    this.setState({
      screen:"partners_packs"
    })
  }

  handleGoToManage=(event)=>{
    const value=event.target.value
    this.setState({
      screen:'partners_manage',
      partnership_pack_id:value
    })
  }

  handleAddReceiver=async(input)=>{
    const request={
      partnership_pack_id:this.state.partnership_pack_id,
      receiver_email:input
    }
    try {
      const response = await ApiConnect.post('/api/user/partnership/receiver/create', request)
      alert('Added')
    } catch(error) {
      alert(error);
    }
  }
  

  render(){
    const props={
      handleGoToPack:this.handleGoToPack,
      partnership_pack_id:this.state.partnership_pack_id,
      handleGoToManage:this.handleGoToManage,
      handleAddReceiver:this.handleAddReceiver
    }
    
    const pages={
      partners_packs: <PartnershipPacks {...props}/>,
      partners_manage:<PartnersManage {...props}/>
    }
    function toRender(data, i){
      return data[i];
    } 
    const renderizing = toRender(pages,this.state.screen)

    return(
      <Grid fluid>
        <PartnershipHeader handleEditItem={this.props.handleEditItem}/>
        <Row>
          <Col xs={12}>
            <Card className='admin_main'>
              <Card.Body>
                  {renderizing}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Grid>
    );
  }
}

class PartnershipHeader extends React.Component{
  static contextType = UserContext
  render(){
    const user_c= this.context
    const trsl= user_c.i18n.t('PRIVATE_PARTNERSHIPS.PARTNERSHIP_MAIN')
    
    return(
      <Row className="edit_profile_main_placer">
        <Col xs={10}>
            <h1>{trsl['partnership_title']} </h1>
        </Col>
        <Col xs={2}>
          <Button block name="main" onClick={this.props.handleEditItem}>
          {trsl['return_button']}
          </Button>
      </Col>
      </Row>
    )
  }
}

export default PartnerMain;