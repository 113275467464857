import React from 'react';

import UserContext from 'CONFIG/auth';

import { WarningCard } from 'Support/warning_cards';
import {Row, Col} from 'Support/grid';
import { RegularButton } from 'Support/listbuttons';



class SubscriptioninProject extends React.Component{
  static contextType = UserContext

  constructor(props){
    super(props);
    this.state = {
      selected_subscription:undefined,
      subscription_data:'',
      subscription_data_loaded:false
    }
  }

  render() {
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_SUBSCRIPTION.Subscription_in_project')

    let color
    let label1
    let label2
    const today=new Date()
    const deadline_date=Date.parse(this.props.project_data.project_subscription_end_date)
    const datediference=parseFloat((deadline_date-today)/(1000 * 60 * 60 * 24))
    if(datediference<=0){
      color="danger"
      label1=trsl['sub_not_valid']
      label2=trsl['sub_expired']
    } else if(datediference<=2){
      color="warning"
      label1=trsl['sub_valid']
      label2=`${trsl['sub_days_to_expire']} ${parseInt(datediference)}`
    } else{
      color="success"
      label1=trsl['sub_valid']
      label2=`${trsl['sub_days_to_expire']} ${parseInt(datediference)}`
    }



    const showExtensionPossibility=(checker)=>{
      if(checker==='owner' || checker==='manage_sub'){
        return(
          <>
            <RegularButton label={trsl['manage_sub']} bk='bk_primary' onClick={this.props.handleGoToSubscription} name="subscription" value={this.props.project_id} />
          </>
        )
      } else{
        return(
          <>
            <RegularButton label={trsl['manage_sub']} bk='bk_primary' onClick={this.props.handleGoToSubscription} name="subscription" value={this.props.project_id} disabled={true}/>
          </>
        )
      }
    }

    const extension_renderizing=showExtensionPossibility(this.props.project_data.user_permission)

    return(
      <Row>
        <Col xs={12}>
          <WarningCard label1={label1} label2={label2} color={color}/>
          <div className='blanktop_10'>
            {trsl['type']}: {trsl[this.props.project_data.project_subscription_type]}
          </div>
          <div>
          {trsl['validity']}: {user_c.i18n.dateF(this.props.project_data.project_subscription_end_date)}
          </div>
          <div>
          {trsl['permission']}: {trsl[this.props.project_data.project_subscription_permission]}
          </div>
        </Col>
        <Col xs={12} className="subscription_project_button">
          {extension_renderizing}
        </Col>
      </Row>      
    )
  }
}

export {
  SubscriptioninProject
};