import React from 'react';
import ApiConnect from 'CONFIG/axios';
import UserContext from 'CONFIG/auth';

import {Row, Col} from 'Support/grid';
import {Card, Button, Collapse} from 'react-bootstrap';

import {BeforeStripeCheckout,StripeSubsSelector, StripeCurrencySelector} from './subscription_stripe';
import { RegularButton, RegularButtonSmall } from 'Support/listbuttons';


class SubscriptionBuy extends React.Component{
  static contextType = UserContext

  constructor(props){
    super(props);
    this.state = {
        data: '',
        subscriptions_types:[],
        subscriptions_features:"",
        selected_subscription:"",
        currency:"eur",
        stripe_disabled:"true"
    }
    this.handleCurrencySelection=this.handleCurrencySelection.bind(this)
  }

  componentDidMount(){
    this.getSubscriptionsTypesList()
  }

  getSubscriptionsTypesList=async()=>{
    try{
      const request={
        currency:this.state.currency
      }
      const response = await ApiConnect.post('api/user/subscription/options/get', request)
      this.setState({
        subscriptions_types:response.data.subs_selection,
        subscriptions_features:response.data.subs_features
      })
    } catch(error) {
      alert(error);
    }
  }

  handleCheckoutSession=async(event)=>{
    const value=event.target.value
    try{
      const request={
        currency:this.state.currency,
        subs_type:event.target.name,
        selected:event.target.value
      }
      const response = await ApiConnect.post('/api/user/subscription/get', request)
      this.setState({
        data:response.data,
        selected_subscription:value,
        stripe_disabled:false
      })
    } catch(error) {
      alert(error);
    }
  }

  handleCurrencySelection=async(event)=>{
    this.setState({
      currency:event.target.value,
      data:"",
      selected_subscription:"",
      stripe_disabled:true
    })
  }

  goToList(){
    const event={
      target:{
        name:"list"
      }
    }
    this.props.handleScreenNavigation(event)
  }


  render(){
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_SUBSCRIPTION.Subscription_buy')

    const props={
    trsl:trsl,
    subscriptions_types:this.state.subscriptions_types,
    subscriptions_features:this.state.subscriptions_features,
    currency:this.state.currency,
    selected:this.state.selected_subscription,
    handleCheckoutSession:this.handleCheckoutSession}

    return(
      <>
      <Row>
        <Col xs={12}>
          <Header handleEditItem={this.props.handleScreenNavigation} trsl={trsl}/>
        </Col>
      </Row>
      <Row className="subscription_buy_box1">
        <Col xs={12}>
          <Card>
            <Card.Body>
              <Row>
                <Col xs={12} md={8}>
                  <BusinessPlanSelector {...props}/>
                  <BudgetSelector {...props}/>
                </Col>
                <Col xs={12} md={1}></Col>
                <Col xs={12} md={3}>
                  <h4>{trsl['buying_cart']}</h4>
                  <StripeCurrencySelector handleCurrencySelection={this.handleCurrencySelection}/>
                  <BeforeStripeCheckout data={this.state.data} stripe_disabled={this.state.stripe_disabled} />
                </Col>
            </Row>
          </Card.Body>
        </Card>
        </Col>
      </Row>
      </>
    )
  }
}

class Header extends React.Component{
  static contextType = UserContext
  render(){
    return(
      <Row>
      <Col xs={12} md={10}>
          <h1>{this.props.trsl['main_title']}</h1>
      </Col>

      <Col xs={12} md={2}>
          <RegularButton bk='bk_primary' label={this.props.trsl['back']} name="list" onClick={this.props.handleEditItem} />
      </Col>
  </Row>
    );
  }
}

class BusinessPlanSelector extends React.Component{
  render(){
    const item_holder=this.props.subscriptions_types.business_plan
    let item_distr=""
    if(item_holder!=undefined){
      item_distr=item_holder.map((value)=>{
      return(
      <>
        <StripeSubsSelector 
        id={value.id}
        period={value.period}
        name={value.product_name} 
        price={value.price[this.props.currency]} 
        selected={this.props.selected}
        handleCheckoutSession={this.props.handleCheckoutSession}
        currency= {this.props.currency}
        subs_type="business_plan" />
      </>
      
    )})}
    return(
      <Row className="subsciption_buy_options_row">
        <Col xs={12}>
          <h4>{this.props.trsl['bp_subscriptions']}</h4>
        </Col>
        {item_distr}
      </Row>
    )
  }
}

class BudgetSelector extends React.Component{
  render(){
    const item_holder=this.props.subscriptions_types.budget
    let item_distr=""
    if(item_holder!=undefined){
      item_distr=item_holder.map((value)=>{
      return(
        <>
          <StripeSubsSelector
          id={value.id}
          period={value.period}
          name={value.product_name} 
          price={value.price[this.props.currency]} 
          selected={this.props.selected}
          handleCheckoutSession={this.props.handleCheckoutSession}
          currency= {this.props.currency}
          subs_type="budget" />
        </>
        )
      })
    }

    return(
      <Row className="subsciption_buy_options_row">
        <Col xs={12}>
          <h4>{this.props.trsl['budget_subscriptions']}</h4>
        </Col>
          {item_distr}
      </Row>
    )
  }
}

class CompareOptions extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      show: false
    }
  }

  handleShow=()=>{
    let go=this.state.show
    this.setState({
      show:!go
    })
  }

  render(){
    const trsl=this.props.trsl
    function show_features(insert){
      let result=""
      if (typeof insert === 'object' && insert !== null){
        result=<OptionsTable value={insert} trsl={trsl}/>
      }
      return result      
    }
    const rendering=show_features(this.props.subscriptions_features)

    return(
      <>
        <Row className="subscription_compare_button_row">
          <Col xs={12}>
            <RegularButtonSmall bk="bk_warning" label={this.props.trsl['compare_subs']} onClick={this.handleShow}/>
          </Col>
        </Row>
        <Collapse in={this.state.show}>
          <div>
            {rendering}
          </div>
        </Collapse>
      </>
    )
  }
}

function OptionsTable(props){
  const fea=props.value.feasibility_plan_features
  const bus=props.value.budget_features
  const can=props.value.canvas_plan_features
  return(
    <>
      <Row className="subscription_buy_compate_features_header">
      <OptionsCels1Column value={<h4>{props.trsl['features']}</h4>} />
      <OptionsCels value={<h4>{props.trsl['canvas_plan']}</h4>} />
      <OptionsCels value={<h4>{props.trsl['feasibility_plan']}</h4>} />
      <OptionsCels value={<h4>{props.trsl['business_plan']}</h4>} />
      </Row>
      <Row className="subscription_buy_options_main_row">
        <Col xs={12}>
          <h5>{props.trsl['general_features']}</h5>
        </Col>
      </Row>
      <Row className="subscription_buy_options_cells_row">
      <OptionsCels1Column value={props.trsl['number_projects']} />
      <OptionsCels value={can.general.project} />
      <OptionsCels value={fea.general.project} />
      <OptionsCels value={bus.general.project} />
      </Row>
      <Row className="subscription_buy_options_cells_row">
      <OptionsCels1Column value={props.trsl['colaborators']} />
      <OptionsCels value={can.general.colaborators} />
      <OptionsCels value={fea.general.colaborators} />
      <OptionsCels value={bus.general.colaborators} />
      </Row>
      <Row className="subscription_buy_options_cells_row">
      <OptionsCels1Column value={props.trsl['viewers']} />
      <OptionsCels value={can.general.viewers} />
      <OptionsCels value={fea.general.viewers} />
      <OptionsCels value={bus.general.viewers} />
      </Row>
      <Row className="subscription_buy_options_cells_row">
        <OptionsCels1Column value={props.trsl['feasibility_module']} />
        <OptionsCels value={props.trsl['no']} />
        <OptionsCels value={props.trsl['yes']} />
        <OptionsCels value={props.trsl['yes']} />
      </Row>
      <Row className="subscription_buy_options_cells_row">
        <OptionsCels1Column value={props.trsl['canvas_module']} />
        <OptionsCels value={props.trsl['yes']} />
        <OptionsCels value={props.trsl['no']} />
        <OptionsCels value={props.trsl['yes']} />
      </Row>
      <Row className="subscription_buy_options_cells_row">
      <OptionsCels1Column value={props.trsl['learning_center']} />
      <OptionsCels value={props.trsl['yes']} />
      <OptionsCels value={props.trsl['yes']} />
      <OptionsCels value={props.trsl['yes']} />
      </Row>
      <Row className="subscription_buy_options_main_row">
        <Col xs={12}>
          <h5>{props.trsl['feasibility_features']}</h5>
        </Col>
      </Row>
      <Row className="subscription_buy_options_cells_row">
      <OptionsCels1Column value={props.trsl['number_of_simulations']} />
      <OptionsCels value={props.trsl['-']} />
      <OptionsCels value={props.trsl['unlimited']} />
      <OptionsCels value={props.trsl['unlimited']} />
      </Row>
      <Row className="subscription_buy_options_cells_row">
      <OptionsCels1Column value={props.trsl['number_of_versions']} />
      <OptionsCels value={props.trsl['-']} />
      <OptionsCels value={fea.feasibility.versions} />
      <OptionsCels value={bus.feasibility.versions} />
      </Row>
      <Row className="subscription_buy_options_cells_row">
      <OptionsCels1Column value={props.trsl['forecasting']} />
      <OptionsCels value={props.trsl['-']} />
      <OptionsCels value={props.trsl['forecasting1']} />
      <OptionsCels value={props.trsl['forecasting1']} />
      </Row>
      <Row className="subscription_buy_options_cells_row">
      <OptionsCels1Column value={props.trsl['templates']} />
      <OptionsCels value={props.trsl['-']} />
      <OptionsCels value={props.trsl['templates1']} />
      <OptionsCels value={props.trsl['templates1']} />
      </Row>
      <Row className="subscription_buy_options_cells_row">
      <OptionsCels1Column value={props.trsl['raw_material_input']} />
      <OptionsCels value={props.trsl['-']} />
      <OptionsCels value={props.trsl['raw_material_input1']} />
      <OptionsCels value={props.trsl['raw_material_input1']} />
      </Row>
      <Row className="subscription_buy_options_cells_row">
      <OptionsCels1Column value={props.trsl['employee_simulations']} />
      <OptionsCels value={props.trsl['-']} />
      <OptionsCels value={props.trsl['employee_simulations1']} />
      <OptionsCels value={props.trsl['employee_simulations1']} />
      </Row>
      <Row className="subscription_buy_options_cells_row">
      <OptionsCels1Column value={props.trsl['exporting']} />
      <OptionsCels value={props.trsl['-']} />
      <OptionsCels value={props.trsl['yes']} />
      <OptionsCels value={props.trsl['yes']} />
      </Row>
      <Row className="subscription_buy_options_main_row">
        <Col xs={12}>
          <h5>{props.trsl['canvas_feature']}</h5>
        </Col>
      </Row>
      <Row className="subscription_buy_options_cells_row">
      <OptionsCels1Column value={props.trsl['swot']} />
      <OptionsCels value={props.trsl['yes']} />
      <OptionsCels value={props.trsl['-']} />
      <OptionsCels value={props.trsl['yes']} />
      </Row>
      <Row className="subscription_buy_options_cells_row">
      <OptionsCels1Column value={props.trsl['bus_modl_canvas']} />
      <OptionsCels value={props.trsl['yes']} />
      <OptionsCels value={props.trsl['-']} />
      <OptionsCels value={props.trsl['yes']} />
      </Row>
      <Row className="subscription_buy_options_cells_row">
      <OptionsCels1Column value={props.trsl['exporting']} />
      <OptionsCels value={props.trsl['yes']} />
      <OptionsCels value={props.trsl['-']} />
      <OptionsCels value={props.trsl['yes']} />
      </Row>
    </>

  )
}

function OptionsCels1Column(props){
  return(
    <Col xs={3}>
      {props.value}
    </Col>
  )
}

function OptionsCels(props){
  return(
    <Col xs={3} className="subscription_buy_options_cells_col">
      {props.value}
    </Col>
  )
}

export default SubscriptionBuy;