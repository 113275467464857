import React  from 'react';
import {Col, Row} from 'Support/grid';
import {Card, Badge, Button} from 'react-bootstrap';

import ApiConnect from 'CONFIG/axios';
import UserContext from 'CONFIG/auth';

import {DeleteUserButton, RegularButton} from 'Support/listbuttons';
import {AddUser} from 'Support/add_item';

class ProjectColaboratorsViewers extends React.Component{
  static contextType = UserContext

  render(){
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_PROJECT.PROJECT_MAIN.Project_colaborators_viewers')
    const props={
      project_data:this.props.project_data,
      project_id:this.props.project_id,
      trsl:trsl,
      handleEditItem:this.props.handleEditItem,
    }
  
    return(
      <Row>
        <Col xs={12}>
          <ColaboratorsViewersHeader {...props} />
        </Col>
        <Col className="project_settings_rows" xs={12}>  
          <ProjectColaborators {...props} />
        </Col>
        <Col className="project_settings_rows" xs={12}>  
          <ProjectViewers {...props} />
        </Col>
      </Row>
    );
  }
}

class ColaboratorsViewersHeader extends React.Component{
  render(){
    return(
      <Row>
        <Col xs={10}>
          <h2>{this.props.trsl['col_vie']}</h2>
        </Col>
        <Col xs={2}>
          <RegularButton name="main" bk="bk_darkaccent" label={this.props.trsl['back']} onClick={this.props.handleEditItem} />
        </Col>  
      </Row>
    );
  }
}

class ProjectColaborators extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      colaborators:[],
      number_of_colaborators:0,
      loaded:false
    }
    this.handleAddColaborator=this.handleAddColaborator.bind(this);
    this.handleDeleteColaborator=this.handleDeleteColaborator.bind(this);
  }
  componentDidMount(){
    this.updateList()
  }

  updateList=async()=>{
    const request={ 
      project_id:this.props.project_id,
    } 
    try {
      const response = await ApiConnect.post('/api/project/colaborators', request)
      this.setState({
        colaborators:response.data,
        number_of_colaborators:response.data.length,
        loaded:true
      })
    } catch(error) {
      alert(error);
    }
  }

  handleAddColaborator=async(item)=>{
    const request={ 
      project_id:this.props.project_id,
      colaborator_email:item
    }
    try{
      const response = await ApiConnect.post('/api/project/colaborator/add', request)
      alert(response.data)
      this.updateList()
    } catch(error) {
      alert(error);
    }
  }

  handleDeleteColaborator=async(event)=>{
    const request={ 
      project_id:this.props.project_id,
      colaborator_id:event.target.value
    }
    try{
      const response = await ApiConnect.post('/api/project/colaborator/delete', request)
      alert(response.data)
      this.updateList()
    } catch(error) {
      alert(error);
    }
  }

  render(){
    const props={
      handleAdd:this.handleAddColaborator,
      handleDelete:this.handleDeleteColaborator,
      item_holder:this.state.colaborators,
      trsl:this.props.trsl
    }

    return(
      <Card>
        <Card.Header>
          <Row>
            <Col xs={9}>
              <h4>{this.props.trsl['colaborators']}</h4>
            </Col>
            <Col xs={3}>
              <h4>
                <Badge variant="primary">{this.props.trsl['colaborating']} {this.state.number_of_colaborators}/{this.props.project_data.project_colaborator_max}</Badge>
              </h4>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col xs={12}>
              <AddUser {...props}/>
            </Col>
            <Col xs={12}>
              <ProjectColaboratorsViewersHeader {...props}/>
              <ProjectColaboratorsViewersList {...props}/>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }
}

class ProjectViewers extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      viewers:[],
      number_of_viewers:0,
      loaded:false
    }
    this.handleAddViewer=this.handleAddViewer.bind(this);
    this.handleDeleteViewer=this.handleDeleteViewer.bind(this);
  }
  componentDidMount(){
    this.updateList()
  }

  updateList=async()=>{
    const request={ 
      project_id:this.props.project_id,
    } 
    try {
      const response = await ApiConnect.post('/api/project/viewers', request)
      this.setState({
        viewers:response.data,
        number_of_viewers:response.data.length,
        loaded:true
      })
    } catch(error) {
      alert(error);
    }
  }

  handleAddViewer=async(item)=>{
    const request={
      project_id:this.props.project_id,
      viewer_email:item
    }
    try{
      const response = await ApiConnect.post('/api/project/viewer/add', request)
      alert(response.data)
      this.updateList()
    } catch(error) {
      alert(error);
    }
  }

  handleDeleteViewer=async(event)=>{
    const request={
      project_id:this.props.project_id,
      viewer_id:event.target.value
    }
    try{
      const response = await ApiConnect.post('/api/project/viewer/delete', request)
      alert(response.data)
      this.updateList()
    } catch(error) {
      alert(error);
    }
  }

  render(){
    const props={
      handleAdd:this.handleAddViewer,
      handleDelete:this.handleDeleteViewer,
      item_holder:this.state.viewers,
      trsl:this.props.trsl
    }

    return(
      <Card>
        <Card.Header>
          <Row>
            <Col xs={9}>
              <h4>{this.props.trsl['viewers']}</h4>
            </Col>
            <Col xs={3}>
              <h4>
                <Badge variant="primary">{this.props.trsl['viewing']} {this.state.number_of_viewers}/{this.props.project_data.project_viewer_max}</Badge>
              </h4>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col xs={12}>
              <AddUser add_item={this.props.trsl['add_viewer']}  {...props}/>
            </Col>
            <Col xs={12}>
              <ProjectColaboratorsViewersHeader {...props}/>
              <ProjectColaboratorsViewersList {...props}/>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }
}

class ProjectColaboratorsViewersHeader extends React.Component{
  render(){
    return(
      <Row className="list_table_header">
        <Col xs={4}>
          {this.props.trsl['user_email']}
        </Col>

        <Col xs={3}>
          {this.props.trsl['user_name']}
        </Col>

        <Col xs={2}>
          {this.props.trsl['user_status']}
        </Col>

        <Col xs={2}>
          {this.props.trsl['colaboration_status']}
        </Col>

        <Col xs={1}>
          Remove user
        </Col>
      </Row>
    );
  }
}

class ProjectColaboratorsViewersList extends React.Component{
  render(){
    const item_holder = this.props.item_holder
    let item_distr=this.props.trsl['no_col_view']
    if(item_holder !==undefined && item_holder.length>0){
      item_distr = item_holder.map((value) =>{
        let namer
        if(value.name===undefined){namer ="no user name"}else{namer=value.name}
        let u_status
        if(value.user_status=="Active"){u_status="Registered"}else{u_status="Not registered"}
        let c_status
        if(value.accepted===true){c_status="Active"}else{c_status="Not active"}
        return(
          <Row className="feasibility_list">
            <Col xs={4}>
              {value.email}
            </Col>

            <Col xs={3}>
              {namer}
            </Col>

            <Col xs={2}>
              {u_status}
            </Col>

            <Col xs={2}>
              {c_status}
            </Col>

            <Col xs={1} center="xs">
              <DeleteUserButton value={value.identifier} handleDelete={this.props.handleDelete}/>
            </Col>
          </Row>
        )
      })
    }
    return(
      <div>{item_distr}</div>
      
    );
  }
}

export default ProjectColaboratorsViewers;