import React, {useEffect, useRef}  from 'react';
import * as Plot from '@observablehq/plot'
import {max, sum,mean} from 'd3'
import * as d3 from 'd3'


const general_attributes={'font-size': 16}
const left_margin_basic_padding=15

function chartAttributes(el, attrs) {
  for(var key in attrs) {
    el.setAttribute(key, attrs[key]);
  }
}


const characterWidths = [
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0.2796875,
  0.2765625,
  0.3546875,
  0.5546875,
  0.5546875,
  0.8890625,
  0.665625,
  0.190625,
  0.3328125,
  0.3328125,
  0.3890625,
  0.5828125,
  0.2765625,
  0.3328125,
  0.2765625,
  0.3015625,
  0.5546875,
  0.5546875,
  0.5546875,
  0.5546875,
  0.5546875,
  0.5546875,
  0.5546875,
  0.5546875,
  0.5546875,
  0.5546875,
  0.2765625,
  0.2765625,
  0.584375,
  0.5828125,
  0.584375,
  0.5546875,
  1.0140625,
  0.665625,
  0.665625,
  0.721875,
  0.721875,
  0.665625,
  0.609375,
  0.7765625,
  0.721875,
  0.2765625,
  0.5,
  0.665625,
  0.5546875,
  0.8328125,
  0.721875,
  0.7765625,
  0.665625,
  0.7765625,
  0.721875,
  0.665625,
  0.609375,
  0.721875,
  0.665625,
  0.94375,
  0.665625,
  0.665625,
  0.609375,
  0.2765625,
  0.3546875,
  0.2765625,
  0.4765625,
  0.5546875,
  0.3328125,
  0.5546875,
  0.5546875,
  0.5,
  0.5546875,
  0.5546875,
  0.2765625,
  0.5546875,
  0.5546875,
  0.221875,
  0.240625,
  0.5,
  0.221875,
  0.8328125,
  0.5546875,
  0.5546875,
  0.5546875,
  0.5546875,
  0.3328125,
  0.5,
  0.2765625,
  0.5546875,
  0.5,
  0.721875,
  0.5,
  0.5,
  0.5,
  0.3546875,
  0.259375,
  0.353125,
  0.5890625
]
const avg=mean(characterWidths.filter((d) => d))

function measureText  (str, fontSize = 16) {
  if(isNaN(str)===false){
    str = str.toFixed(1).toString()
  }
  return sum(str, (cur) => characterWidths[cur.charCodeAt(0)] ?? avg) * fontSize
}


  

function BarGraph({ data }) {
  const ref = useRef();
  const left_margin = max(data, (d) =>{ return measureText( d.y)})

  useEffect(() => {
    const bchart = Plot.plot({
      marginLeft: left_margin+left_margin_basic_padding,
      height: 350,
      marks: [
        Plot.barY(data, {
          x: 'x',
          y: "y",
          fill: 'var(--primary)',
          channels: {order: {value: 'order'}},
          sort: {x:'order'},
        })
      ],
      y: {
        grid: true,
        label:"",
        labelArrow: "none",
      },
      x:{
        label:"",
        type:"band",
      },

    });
    chartAttributes(bchart,general_attributes)
    ref.current.append(bchart);
    return () => bchart.remove();
  }, [data]);

  return(
    <>
    <div ref={ref}>
    </div>
    </>
  )
}

function StackedBarGraph({ data }) {
  const ref = useRef();

  const left_margin = max(data[0], (d) =>{ return measureText( d.y)})

  useEffect(() => {
    const chart = Plot.plot({
      color: {range: data[1], legend: true},
      marks: [
        Plot.barY(data[0], {
          x: 'x', 
          y: "y",
          channels: {order: {value: 'order'}, color: {value: 'color'}},
          sort: {x:'order'},
          fill: 'legend',
        })
      ],
      y: {
        grid: true,
        label:"",
        labelArrow: "none"
      },
      x:{
        label:"",
        type:"band"
      },
      marginLeft: left_margin+left_margin_basic_padding,
      height: 350,
    });
    chartAttributes(chart,general_attributes)
    ref.current.append(chart);
    return () => chart.remove();
  }, [data]);

  return(
    <>
    <div ref={ref}>
    </div>
    </>
  )
}


function LineGraphs({ data }) {
  const ref = useRef();

  const left_margin = max(data, (d) =>{ return measureText( d.y)})

  useEffect(() => {
    const chart = Plot.plot({
      marks: [
        Plot.line(data, {
          x: 'x',
          y: "y",
          stroke: 'var(--primary)',
          strokeWidth:3,
          channels: {order: {value: 'order'}},
          sort: {x:'order'},
        }),
      ],
      y: {
        grid: true,
        label:"",
        labelArrow: "none",
        domain: [0, 1]
      },
      x:{
        label:"",
        type:"point"
      },
      marginLeft: left_margin+left_margin_basic_padding,
      height: 350,
    });
    chartAttributes(chart,general_attributes)
    ref.current.append(chart);
    return () => chart.remove();
  }, [data]);

  return(
    <>
    <div ref={ref}>
    </div>
    </>
  )
}


function MultipleLineGraphs({ data }) {
  const ref = useRef();

  const left_margin = max(data[0], (d) =>{ return measureText( d.y)})

  useEffect(() => {
    const mlchart = Plot.plot({
      color: {range: data[1], legend: true},
      marks: [
        Plot.line(data[0], {
          x: 'x',
          y: "y",
          strokeWidth:3,
          channels: {order: {value: 'order'}, color: {value: 'color'}},
          sort: {x:'order'},
          stroke:"legend"
        }),
        Plot.ruleY(0),
      ],
      
      y: {
        grid: true,
        label:"",
        labelArrow: "none",
      },
      x:{
        label:"",
        type:"point"
      },
      marginLeft: left_margin+left_margin_basic_padding,
      height: 350,
    });
    chartAttributes(mlchart,general_attributes)
    ref.current.append(mlchart);
    
    return () => mlchart.remove();
  }, [data]);
  

  return(
    <>
    <div ref={ref}>
    </div>
    </>
  )
}

function RadarGraph({points} ) {
  const ref = useRef();

  const longitude = d3.scalePoint(new Set(Plot.valueof(points, "key")), [180, -180]).padding(0.5).align(1)

  useEffect(() => {
    const radarchart = Plot.plot({

      projection: {
        type: "azimuthal-equidistant",
        rotate: [0, -90],
        domain: d3.geoCircle().center([0, 90]).radius(7)()
      },

      marks: [
        Plot.geo([5, 4, 3, 2, 1, 0], {
          geometry: (r) => d3.geoCircle().center([0, 90]).radius(r)(),
          stroke: "black",
          fill: "black",
          strokeOpacity: 0.3,
          fillOpacity: 0.03,
          strokeWidth: 0.5
        }),
        //tick labels
        Plot.text([0, 1, 2, 3, 4, 5], {
          x: 180,
          y: (d) => 90 - d,
          dx: 2,
          textAnchor: "start",
          text: (d) => `${d}`,
          fill: "currentColor",
          stroke: "white",
          fontSize: 12
        }),
        // white axes
        Plot.link(longitude.domain(), {
          x1: longitude,
          y1: 90 - 5,
          x2: 0,
          y2: 90,
          stroke: "black",
          strokeOpacity: 0.75,
          strokeWidth: 2.5
        }),
        // axes labels
        Plot.text(longitude.domain(), {
          x: longitude,
          y: 90 - 6,
          text: Plot.identity,
          lineWidth: 12
        }),
        // points
        Plot.dot(points, {
          x: ({ key }) => longitude(key),
          y: ({ value }) => 90 - value,
          fill: "name",
          stroke: "white"
        }),
        Plot.area(points, {
          x1: ({ key }) => longitude(key),
          y1: ({ value }) => 90 - value,
          x2: 0,
          y2: 90,
          fill: "name",
          fillOpacity: 0.3,
          stroke: "name",
          curve: "cardinal-closed"
        }),
      ],

      height: 350,
    });
    chartAttributes(radarchart,general_attributes)
    ref.current.append(radarchart);
    
    return () => radarchart.remove();
  }, [points]);
  

  return(
    <>
    <div ref={ref}>
    </div>
    </>
  )
}



///////////////////UTILITIES
function dataTransformForGrouping(y_input,x_input,legend, color){
  let bug= []
  let count=0
  for (var j=0;j<y_input.length;j++){
    for (var i=0;i<y_input[j].length;i++){
      bug[count]={
        x:x_input[i].toString(),
        y:y_input[j][i],
        legend:legend[j],
        order: i
      }
      count ++
    }
  }
  return ([bug,color])
}

function dataTransform(y_input,x_input){
  let bug= []
  function colorSetter(input){
    if(input>=0){
      return 1
    } else{
      return 0
    }
  }
  for (var i=0;i<y_input.length;i++){
    bug[i]={
      x:x_input[i].toString(),
      y:y_input[i],
      color:colorSetter(y_input[i])
    }
  }
  return bug
}

export {
  BarGraph,
  StackedBarGraph,
  LineGraphs,
  MultipleLineGraphs,
  RadarGraph,
  dataTransformForGrouping,
  dataTransform
}