import React from 'react';
import {Navigation} from 'Support/navigation'
import UserContext from 'CONFIG/auth';

class CanvaNavigation extends React.Component {
  static contextType = UserContext
  render(){
    const props=this.props
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_PROJECT.PROJECT_CANVA.Navigation')
    return(     
      <Navigation {...props}>
        <Navigation.Item name='description'>{trsl['description']}</Navigation.Item>
        <Navigation.Item name='pestel'>{trsl['pestel']}</Navigation.Item>
        <Navigation.Item name='swot'>{trsl['swot']}</Navigation.Item>
        <Navigation.Item name='bmc'>{trsl['bmc']}</Navigation.Item>
        <Navigation.Item name='competitive'>{trsl['competitive']}</Navigation.Item>
      </Navigation>
    );
  }
}

export default CanvaNavigation;
