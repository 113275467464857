import React from 'react';
import {AssumptionHeaderSaveReturn} from 'PRIVATE/PROJECT/Feasibility/feasibility_items';
import { Row, Col } from 'Support/grid';
import {FormEdit, NonEditableForm, PeriodHeader, SingleInputFormFlat, Observations} from 'Support/inputforms'
import ApiConnect from 'CONFIG/axios';
import UserContext from 'CONFIG/auth';
import Loader from 'Support/loader';
import {calculateOtherBalance} from 'CALCULATIONS/cal_other_balance';

class OtherBalanceEntry extends React.Component {
  static contextType = UserContext

  constructor(props) {
    super(props);
    this.state = {
      data: "",
      loaded:false,
      years:this.props.feasibility_settings.historic_years.concat(this.props.feasibility_settings.years)
    }
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleObservationsChange = this.handleObservationsChange.bind(this);
  }

  componentDidMount= async()=>{
    const request={
    project_id:this.props.project_id,
    version_id:this.props.version_id,
    user_id:this.props.user_id,
    item_id:this.props.active_item,
    item_type:"other_balance"
    }
    try {
      const response = await ApiConnect.post('/api/multitem/unique/get', request)
        this.setState({
          data:response.data.item,
          loaded:true
        })
    } catch(error) {
        alert(error);
    }
  }

  handleEditChange=(id, item)=>{
    let go = this.state.data;
    go[id]=item;
    calculateOtherBalance(go)
    this.setState({data:go})
  }

  handleNameChange(event){
    const value = event.target.value;
    let go = this.state.data;
    go.other_balance_item_name=value;
    this.setState({data:go })
  }

  handleObservationsChange(event){
    const value = event.target.value;
    let go = this.state.data;
    go.other_balance_obs=value;
    this.setState({data:go })
  }

  async handleSave(){
    const request={
      user_id:this.props.user_id,
      project_id:this.props.project_id,
      version_id:this.props.version_id,
      item_id:this.props.active_item,
      item_type:"other_balance",
      content:this.state.data
    }
    try{
      const response = await ApiConnect.post('/api/multitem/item/modify', request)
      this.props.changeScreenView("list",0)
      this.props.handleLists.handleSaveUpdater(response.data,'other_balance')
    } catch(error) {
        alert(error);
    }
  }

  render() {
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_PROJECT.PROJECT_FEASIBILITY.Individual_edits')

    const props ={
      trsl:trsl,
      handleScreenNavigation:this.props.handleScreenNavigation,
      handleEditChange:this.handleEditChange,
      handleSave:this.handleSave,
      handleNameChange:this.handleNameChange,
      handleObservationsChange:this.handleObservationsChange,
      data:this.state.data,
      years:this.state.years,
      feasibility_settings:this.props.feasibility_settings,
      user_permission:this.props.user_permission,
      multi_option:this.props.multi_option,
      multitabs:this.props.multitabs,
  }

    function torender(data, i){
      return data[i];
    }  
    const pages ={
      true: <ToDisplay {...props}/>,
      false:<Loader loader_message="o_bc_edit"/>
    }
    var i = this.state.loaded
    const renderizing = torender(pages,i)

    return(
      <div>
        {renderizing}
      </div>
    )
  }
}

class ToDisplay extends React.Component {
  render(){
    const props=this.props
    return(
      <>
        <GlobalHeader {...props}/>
        <Row>
          <Col xs={12}>
            <PeriodHeader label={this.props.trsl['year']} periods={this.props.years}/>
          </Col>
          <Col xs={12}>
            <IncreaseEdit {...props}/>
            <DecreaseEdit {...props}/>
            <Accumulated {...props}/>
          </Col>
          <Col Col xs={12}>
            <Observations name="other_balance_obs" value={this.props.data.other_balance_obs} label="Observations" handleObservationsChange={this.props.handleObservationsChange}/>
          </Col>
        </Row>
      </>
    );
  }
}

class GlobalHeader extends React.Component{
  render(){
    const item_holder=this.props.multitabs
    const selected=this.props.multi_option
    const element_name =item_holder.find(el => el.type.indexOf(selected) !== -1).item_trsl

    return(
      <div>
        <AssumptionHeaderSaveReturn title={this.props.trsl[element_name]} user_permission={this.props.user_permission} back={this.props.trsl['back_button']} save={this.props.trsl['save_back_button']} handleSave={this.props.handleSave} handleScreenNavigation={this.props.handleScreenNavigation}/>
        <Row className="feasibility_edit_globalheader_row" middle="xs">
            <SingleInputFormFlat 
            xs={12} md={12}
            value={this.props.data.other_balance_item_name} 
            label= {this.props.trsl['other_ba_name']} 
            handleEditItemChange={this.props.handleNameChange}/>
        </Row>
      </div>
    );
  }
}

class IncreaseEdit extends React.Component{
  render(){ 
    return(
      <FormEdit 
      use_historic={this.props.feasibility_settings.use_historic}
      item_holder={this.props.data.other_balance_increase}
      id="other_balance_increase"
      handleForm={this.props.handleEditChange}
      label={this.props.trsl['other_ba_increase']}
      unit={new Intl.NumberFormat('ja-JP', { style: 'currency', currency: this.props.feasibility_settings.currency}).format(0).replace(/\d./g,"")}
      />
    );
  }
}

class DecreaseEdit extends React.Component{
  render(){ 
    return(
      <FormEdit 
      use_historic={this.props.feasibility_settings.use_historic}
      item_holder={this.props.data.other_balance_decrease}
      id="other_balance_decrease"
      handleForm={this.props.handleEditChange}
      label={this.props.trsl['other_ba_decrease']}
      unit={new Intl.NumberFormat('ja-JP', { style: 'currency', currency: this.props.feasibility_settings.currency}).format(0).replace(/\d./g,"")}
      />
    );
  }
}

class Accumulated extends React.Component{
  render(){ 
    return(
      <NonEditableForm
      item_holder={this.props.data._other_balance_accumulated}
      label={this.props.trsl['other_ba_accumulated']}
      value_type="currency"
      currency={this.props.feasibility_settings.currency}
      />
    );
  }
}


export default OtherBalanceEntry;