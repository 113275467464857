import React from 'react';
import { Row, Col } from 'Support/grid';
import UserContext from 'CONFIG/auth';
import ApiConnect from 'CONFIG/axios';
import Session from 'CONFIG/session';

import {ColaborateRequestNotification} from './colaborate_notifications';
import {EditProjectButton, DeleteProjectButton, ViewProjectButton, RegularButton} from 'Support/listbuttons';
import Loader from 'Support/loader';

class ProjectsList extends React.Component{
  static contextType = UserContext

  constructor(props) {
    super(props);
    this.state = {
      loaded:false,
      owned_projects:undefined,
      colaborating_projects:undefined,
      viewing_projects:undefined
    }
    this.handleDeleteProject=this.handleDeleteProject.bind(this)
    this.handleMoveToBuySubs=this.handleMoveToBuySubs.bind(this)
  }

  componentDidMount() {
    Session.setHistory(this.props.location)
    this.updateListData()
  }

  updateListData=async()=>{
    const request={}
    try{
      const response= await ApiConnect.post('/api/project/owner', request)
      this.setState({ 
        owned_projects: response.data.response_by_owner,
        colaborating_projects: response.data.response_by_colaborator,
        viewer_projects:response.data.response_by_viewer,
        loaded:true
      });
    } catch (error) {
      alert(error);
    }
  }
    handleDeleteProject= async(event)=>{
        const value=event.target.value
        const ugetter=this.props.user_id
        const pgetter=value
        try{
            const response =await ApiConnect.post('/api/project/delete', {user_id:ugetter,project_id:pgetter})
            alert(response.data);
            this.updateListData()
        }catch(error) {
            alert(error);
        }
    }

  handleMoveToBuySubs=()=>{
    this.props.history.push('/app/subscriptions/buy')
  }

  render(){
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_PROJECT.PROJECT_MAIN.Project_list')

      const props={
        trsl:trsl,
        handleSetBusinessPlanProject:this.props.handleSetBusinessPlanProject,
        handlePrincipalButtonRouting:this.props.handlePrincipalButtonRouting,
        handleDeleteProject:this.handleDeleteProject,
        handleMoveToBuySubs:this.handleMoveToBuySubs,
        owned_projects:this.state.owned_projects,
        colaborating_projects:this.state.colaborating_projects,
        viewer_projects:this.state.viewer_projects,
        user_id:this.props.user_id
      }

        function torender(data, i){
            return data[i];
          }  
        const pages ={
        true: <ToDisplay {...props}/>,
        false: <LoaderWraper />
        }
        var i = this.state.loaded
        const renderizing = torender(pages,i)
    
        return(
            <div>
              {renderizing}
            </div>
        )
    }
}

function LoaderWraper (props){
  return(
    <>
      <Loader loader_message="projects_list"/>
    </>
  )
}

class ToDisplay extends React.Component{
  
  render(){
    const props=this.props
    return(
      <>
        <Row>
          <Col xs={12} md={9} >
            <h1>
              {this.props.trsl['projects_list']}
            </h1>
          </Col>
          <Col xs={12} md={3}>
            <RegularButton name="business_plans/add" bk='bk_darkaccent' label={this.props.trsl['add']} onClick={this.props.handlePrincipalButtonRouting}/>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <ProjectListItem cl_type="owned_projects" label={this.props.trsl['your_projects']} {...props} no_projects={this.props.trsl['no_own_projects']}/>
            <ProjectColViewListItem cl_type="colaborating_projects" label={this.props.trsl['colaborate_projects']} {...props} no_projects={this.props.trsl['no_col_projects']}/>
            <ProjectColViewListItem cl_type="viewer_projects" label={this.props.trsl['view_projects']} {...props} no_projects={this.props.trsl['no_vie_projects']}/>
          </Col>
        </Row>
        <ColaborateRequestNotification {...props}/>
      </>
    );
  }
}

class ProjectListItem extends React.Component{
  static contextType = UserContext

  render(){
    let user_c= this.context

    const item_holder = this.props[this.props.cl_type]
    let item_distr
    if(Array.isArray(item_holder) && item_holder.length>0){
      item_distr = item_holder.map((value) =>{
        let button_to_display=''
        if(this.props.cl_type=='viewer_projects'){
          button_to_display= <ViewProjectButton name="main" handleEditItem={this.props.handleSetBusinessPlanProject} value={value.project_id}/>
        } else {
          button_to_display=<EditProjectButton name="main" handleEditItem={this.props.handleSetBusinessPlanProject} value={value.project_id}/>
        }

        return(      
          <>
            <Row className="grid_middle list_item">
              <Col xs={12} md={4}>
                {value.project_name}
              </Col>
              <Col xs={12} md={3}>
                {value.owner}
              </Col>                         
              <Col xs={12} md={3}>
                {user_c.i18n.dateF(value.validity)}
              </Col>
              <Col xs={12} md={2}>
                <Row center="xs">
                  {button_to_display}
                  <DeleteProjectButton value={value.project_id} handleDelete={this.props.handleDeleteProject}/>
                </Row>
              </Col>
            </Row>
          </>
        )}
      );
    } else {
      item_distr =
      <Row>
        <Col xs={12}>
          {this.props.no_projects}
        </Col>
      </Row>
    }

    return(
      <div className='blanktop_15'>
        <h5>{this.props.label}</h5>
        <Col xs={12} className="general_list">
          <ProjectListHeader label={this.props.label} trsl={this.props.trsl}/>
          {item_distr}
        </Col>
      </div>
    );
  }
}

class ProjectColViewListItem extends React.Component{
  static contextType = UserContext

  render(){
    let user_c= this.context

    const item_holder = this.props[this.props.cl_type]
    let item_distr
    if(Array.isArray(item_holder) && item_holder.length>0){
      item_distr = item_holder.map((value) =>{
        let button_to_display=''
        if(this.props.cl_type=='viewer_projects'){
          button_to_display= <ViewProjectButton name="main" handleEditItem={this.props.handleSetBusinessPlanProject} value={value.project_id}/>
        } else {
          button_to_display=<EditProjectButton name="main" handleEditItem={this.props.handleSetBusinessPlanProject} value={value.project_id}/>
        }
        return(
          <>
            <Row className="grid_middle list_item">
              <Col xs={6} md={4}>
                {value.project_name}
              </Col>
              <Col xs={6} md={2}>
                {value.owner}
              </Col>                          
              <Col xs={6} md={2}>
              </Col>
              <Col xs={6} md={2}>
              </Col>
              <Col xs={6} md={2}>
                <Row center="xs">
                  {button_to_display}
                </Row>
              </Col>
            </Row>
          </>
        )}
      );
    } else {
      item_distr =
      <Row>
        <Col xs={12}>
          {this.props.no_projects}
        </Col>
      </Row>
    }

    return(
      <div className='blanktop_15'>
        <h5>{this.props.label}</h5>
      <Col xs={12} className="general_list">
        <ProjectColViewListHeader label={this.props.label} trsl={this.props.trsl}/>
          {item_distr}
      </Col>
      </div>
    );
  }
}

function ProjectListHeader (props){
  return(
    <Row className="list_table_header_left">
      <Col xs={6} md={4} className="list_table_header_inside">
        {props.trsl['projects']}
      </Col>
      <Col xs={6} md={3} className="list_table_header_inside">
        {props.trsl['owner']}
      </Col>

      <Col xs={6} md={3} className="list_table_header_inside">
        {props.trsl['validity']}
      </Col>
      <Col xs={6} md={2} className="list_table_header_inside">
        {props.trsl['action']}
      </Col>
    </Row>
  );
}

function ProjectColViewListHeader (props){
  return(
    <Row className="list_table_header_left">
      <Col xs={6} md={4} className="list_table_header_inside">
        {props.trsl['projects']}
      </Col>
      <Col xs={6} md={2} className="list_table_header_inside">
        {props.trsl['owner']}
      </Col>
      <Col xs={6} md={2} className="list_table_header_inside">
        {props.trsl['validity']}
      </Col>
      <Col xs={6} md={2} className="list_table_header_inside">
        {props.trsl['permission']}
      </Col>
      <Col xs={6} md={2} className="list_table_header_inside">
        {props.trsl['action']}
      </Col>
    </Row>
  );
}


export default ProjectsList;