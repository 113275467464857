import React from 'react'
import { Row, Col} from 'Support/grid';

import 'CSS/panes.css';

function Panel (props){
  return(
    <>
      <Row className="headerbar_panes">
        <Col xs={12} className="headerbar_row">
          {props.headerbar}
        </Col>
        <Col xs={2} className="pane_navigator">
          {props.left}
        </Col>
        <Col xs={10} className="pane_content">
          {props.right}
        </Col>
      </Row>
    </>
  )
}

export default Panel