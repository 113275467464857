import React from 'react';
import {Grid, Row, Col} from 'Support/grid';
import {Image, Form, Card} from 'react-bootstrap';

import {ApiPublicConnect} from 'CONFIG/axios';
import UserContext from '../CONFIG/auth';

import logo2 from 'images/logo/logo.png';
import './public.css';

import {ActivationRequestViseeon} from 'TEMPLATES/Viseeon/public_templates'
import { RegularButton, RegularLinkButton } from 'Support/listbuttons';
import { SingleInputEmailForm } from 'Support/inputforms';


class ActivationRequest extends React.Component{
  constructor(props){
    super(props);
    this.state={     
      toast:{
        e_mail:"",
        show:false,
        toast_header:"",
        toast_body:"",
      }
    }
  }

  static contextType = UserContext

  handleSetEmail=(event)=>{
    this.setState({
      e_mail:event.target.value
    })
  }

  handleResend=async(event)=>{
    const request={
      email:this.state.e_mail
    }
    try{
      const response= await ApiPublicConnect.post('/user/resend_activation', request);
    } catch (error) {
      let error_body
      if(error.response!=undefined){error_body = error.response.data}
      this.AlertFunctions.setAlert(
        true,
        "activation_request_err_h",
        error_body
      )
    }
  }

  //TOAST ALERT
  AlertFunctions={
    setAlert:(show, header,body)=>{
      this.setState({
        toast:{
          show:show,
          header: header,
          body:body
        }
      })
    },
    unsetalert:()=>{
      this.setState({
        toast:{
          show:false
        }
      })
    }
  }
  render(){
    let user_c= this.context
    
    const props={
      e_mail:this.state.e_mail,
      logged:this.state.logged,
      handleResend:this.handleResend,
      handleSetEmail:this.handleSetEmail,
      toast:this.state.toast,
      AlertFunctions:this.AlertFunctions
    }
    return(
      <>
        <ActivationRequestCASFLO {...props}/>
      </>
    )
  }
}

class ActivationRequestCASFLO extends React.Component{
  static contextType = UserContext
  render(){
    let user_c= this.context
    return(
      <Grid fluid className="main_block public_box">
        <Row >
          <Col xs={12}>
            {this.props.logged}
          </Col>
          <Col xs={12} md={4}>
          </Col>
          <Col xs={12} md={4}>
          <Image src={logo2} className="public_logo"/>
          <Card>
            <Card.Header>
              {user_c.i18n.t('PUBLIC.Activation_request.recovery')}
            </Card.Header>
            <Card.Body>
              <Card.Text>
                <p>{user_c.i18n.t('PUBLIC.Activation_request.text')}</p>
              </Card.Text>
              <SingleInputEmailForm value={this.props.e_mail} handleEditItemChange={this.props.handleSetEmail} label={user_c.i18n.t('PUBLIC.Activation_request.enter_email')} placeholder={user_c.i18n.t('PUBLIC.Activation_request.enter_email')}/>
                <RegularButton bk="bk_primary" onClick={this.props.handleResend}  label={user_c.i18n.t('PUBLIC.Activation_request.button_recovery')} />
            </Card.Body>
          </Card>
          </Col>
          <Col xs={12} md={4}></Col>
        </Row>
        <Row className="public_second_row public_white_font">
          <Col xs={12 }md={4}></Col>
          <Col xs={12} md={4} className='blanktop_15'>
            <h6>{user_c.i18n.t('PUBLIC.Activation_request.alternatives_to_recovery')}</h6>
          </Col>
          <Col xs={12} md={4}></Col>
          <Col xs={12} d={4}></Col>
          <Col xs={12} md={2} className='blanktop_05'>
            <RegularLinkButton bk="bk_lightaccent" href="/register"  label={user_c.i18n.t('PUBLIC.Activation_request.button_register')}/>
          </Col>
          <Col xs={12} md={2} className='blanktop_05'>
            <RegularLinkButton bk="bk_lightaccent" href="/login" label={user_c.i18n.t('PUBLIC.Activation_request.button_login')}/>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default ActivationRequest;