import React from 'react';
import {Col, Row } from 'Support/grid'

import UserContext from 'CONFIG/auth';

import {SubscriptioninBudget} from 'PRIVATE/BUDGET PROJECT/Budget Project Main/subscription';
import AddVersion from './version_add';
import {EditVersionButton, DeleteVersionButton, DuplicateVersionButton, ViewButtonForVersion, RegularButton} from 'Support/listbuttons';
import { ToogleButtons } from 'Support/toogle_buttons';
import Loader from 'Support/loader';

class BudgetEdit extends React.Component{
  static contextType = UserContext

  render(){
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_BUDGET.MAIN.Budget_edit')

    const props ={
      trsl:trsl,
      budget_id:this.props.budget_id,
      handleGoToInProject:this.props.handleGoToInProject,
      handleBudgetRouting:this.props.handleBudgetRouting,
      budget_data:this.props.budget_data,
      budget_subscription:this.props.budget_subscription,
      budget_versions:this.props.budget_versions,
      permission:this.props.permission,
      updateSettingsData:this.props.updateSettingsData,
      handleAddVersion:this.props.handleAddVersion,
      handleDeleteVersion:this.props.handleDeleteVersion,
      handleDuplicateVersion:this.props.handleDuplicateVersion,
      handleSetExportingRawData:this.props.handleSetExportingRawData,
      handleReseExportingPass:this.props.handleReseExportingPass
    }
    
    function torender(data, i){
      return data[i];
    }  
    const pages ={
    true: <ToDisplay {...props}/>,
    false: <LoaderWraper />
    }
    var i = this.props.budget_loaded
    const renderizing = torender(pages,i)

    return(
        <div>
          {renderizing}
        </div>
    )
  }
}

function LoaderWraper (props){
  return(
    <>
      <Loader loader_message="projects_list"/>
    </>
  )
}


class ToDisplay extends React.Component{

  render(){
    const props=this.props

    return(
      <Row  >
        <Col xs={12} md={3} className='bk_primary'>
          <GeneralSettings {...props} />
        </Col>
        <Col xs={12} md={9}>
          <VersionsManagement {...props} />
          <Exporting {...props}/>
        </Col>
      </Row>
    );
  }
}

class VersionsManagement extends React.Component{
  render(){
    const props={
      trsl:this.props.trsl,
      handleBudgetRouting:this.props.handleBudgetRouting,
      budget_data:this.props.budget_data,
      budget_versions:this.props.budget_versions,
      permission:this.props.permission,
      handleAddVersion:this.props.handleAddVersion,
      handleDeleteVersion:this.props.handleDeleteVersion,
      handleDuplicateVersion:this.props.handleDuplicateVersion,

      permission:this.props.budget_data.user_permission,
    }
    let disabled=true
    if(this.props.budget_data.user_permission=="owner" || this.props.budget_data.user_permission=="edit"){
      disabled= false
      if(this.props.budget_versions.length==this.props.budget_subscription.subs_max_versions){
        disabled=true
      }
    }  


    function showAddVersion(trsl,handleAdd,disabled){
        return(
          <>
            <AddVersion trsl={trsl} handleAdd={handleAdd} permission={disabled}/>
          </>
        )
    }
    return(
      <>
        <Row>
          <Col xs={12} md={12}>
            <h2>{this.props.trsl['budget_header']}</h2>
          </Col>
        </Row>       
        <Row>
          <Col xs={12} md={8}>
            <BudgetVersions {...props} />
            {showAddVersion(this.props.trsl,this.props.handleAddVersion, disabled)}            
          </Col>
          <Col xs={12} md={4}>
            <VersionsGauge trsl={this.props.trsl} version_number={this.props.budget_versions.length} version_max={this.props.budget_subscription.subs_max_versions}/>  
          </Col>

        </Row>
      </>

    )
  }
}

class BudgetVersions extends React.Component{
  static contextType = UserContext
  render(){
    let user_c= this.context
    const item_holder = this.props.budget_versions
    let item_distr
    if(item_holder.length<1){
      item_distr=this.props.trsl['no_versions']
    } else{
      item_distr = item_holder.map((value) =>
      <>
        <Row  className="grid_middle list_item">
          <Col xs={6} md={5}>
            {value.name}
          </Col>
          <Col xs={6} md={2}>
            {value.year}
          </Col>
          <Col xs={6} md={2}>
            {user_c.i18n.dateF(value.edited_date)}
          </Col>
          <Col xs={6} md={3}>
            <Row className="grid_center"> 
              <EditVersionButton permission={this.props.permission} handleEditItem={()=>{this.props.handleBudgetRouting("edit",value.id,value.name,value.year)}}/>
              <DeleteVersionButton value={value.id} permission={this.props.permission} handleDelete={this.props.handleDeleteVersion}/>
              <DuplicateVersionButton value={value.id} permission={this.props.permission} handleDuplicate={this.props.handleDuplicateVersion}/>
              <ViewButtonForVersion value={this.props.project_id} name="feasibility/version" permission={this.props.permission} onClick={(e)=>{this.props.handleBudgetRouting("edit",value.id,value.name,value.year)}} />
            </Row>
          </Col>
        </Row>
      </>
      );
    }

    return(
      <Col xs={12} className="general_list">
        <Row className="list_table_header_left">
          <Col xs={6}md={5} className="list_table_header_inside">
            {this.props.trsl['version_name']}
          </Col>
          <Col xs={6}md={2} className="list_table_header_inside">
            {this.props.trsl['version_year']}
          </Col>
          <Col xs={6} md={2} className="list_table_header_inside">
            {this.props.trsl['last_edit_version']}
          </Col>
          <Col xs={6} md={3}className="list_table_header_inside">{this.props.trsl['action']}</Col>
        </Row>
        {item_distr}
      </Col>
    );
  }
}

class Exporting extends React.Component{
  render(){

    let disabled=true
    if(this.props.budget_data.user_permission=="owner"){
      disabled= false
    }

    const toogle_option=[
      {value:false, option_label:this.props.trsl['deactivated'], disabled:disabled},
      {value:true, option_label:this.props.trsl['active'],disabled:disabled}
    ]

    return(
      <>
        <h2 className='blanktop_15'>{this.props.trsl['exporting']}</h2>          
        <Row className='blanktop_10'>
          <ToogleButtons xs={12} md={12} label={this.props.trsl['activate_raw_export']} toogle_data={toogle_option} selected={this.props.budget_data.budget_exporting.raw_exporting} handleToogleButton={this.props.handleSetExportingRawData} />
          <Col xs={12} md={7}>
            <ShowRawExporting view_disabled={disabled} exporting={this.props.budget_data.budget_exporting} trsl={this.props.trsl} handleReseExportingPass={this.props.handleReseExportingPass}/>
          </Col>
          <Col xs={12} md={5}>
            <ExportingNotice view_disabled={disabled} exporting={this.props.budget_data.budget_exporting} trsl={this.props.trsl} />
          </Col>
          <Col xs={4} className='blanktop_05'></Col>
        </Row>
      </>
    )
  }
}

function ShowRawExporting(props){
  function copyRawExportLink(event){
    if(event.target.name=="simple"){
      navigator.clipboard.writeText(`https://api.casflo-app.com/exporting/${event.target.value}`); 
    } else {
      navigator.clipboard.writeText(`https://api.casflo-app.com/exporting/${event.target.value}/${props.exporting.exporting_key}/${props.exporting.exporting_pass}`);
    }
  }
  if(props.view_disabled==false && props.exporting.raw_exporting===true) {
    return(
      <Row>
        <Col xs={12} className='blanktop_10'>
          <h3>{props.trsl['credentials']}</h3>
        </Col>
        <Col xs={12}>
          <h6 className='blanktop_10'>{props.trsl['project_id']}</h6>
          {props.exporting.exporting_key}
        </Col>
        <Col xs={12}><div className='break_line'>
          <h6 className='blanktop_10'>{props.trsl['api_key']}</h6>
          {props.exporting.exporting_pass}</div>
        </Col>
        <Col xs={12} className='blanktop_05'>
          <RegularButton label={props.trsl['reset_pass']} bk="bk_primary" onClick={props.handleReseExportingPass}/>
        </Col>
        <Col xs={12} className='blanktop_15'>
          <h3>{props.trsl['available_tables']}</h3>
        </Col>
        <Col xs={12} className='blanktop_05'>
          <h4 >1. {props.trsl['exporting_all']}</h4>
          <h6 >{props.trsl['exporting_link']}</h6>
            <div className='break_line'>{`https://api.casflo-app.com/exporting/raw_exports/`}</div>
          <Row>
            <Col xs={12} className='blanktop_05'>
              <RegularButton label={props.trsl['copy_link']} name="simple" value="raw_exports" bk="bk_primary" onClick={copyRawExportLink}/>
            </Col>
          </Row>
          <h6 >{props.trsl['exporting_full_link']}</h6>
          <div className='break_line'>{`https://api.casflo-app.com/exporting/raw_exports/${props.exporting.exporting_key}/${props.exporting.exporting_pass}`}</div>
          <Row>
            <Col xs={12} className='blanktop_05'>
              <RegularButton label={props.trsl['copy_full_link']} name="" value="raw_exports" bk="bk_primary" onClick={copyRawExportLink}/>
            </Col>
          </Row>
        </Col>
        <Col xs={12} className='blanktop_10'>
          <h4 >2. {props.trsl['exporting_detailed_revenues']}</h4>
          <h6 >{props.trsl['exporting_link']}</h6>
          <div className='break_line'>{`https://api.casflo-app.com/exporting/revenues/`}</div>
          <Row>
            <Col xs={12} className='blanktop_05'>
              <RegularButton label={props.trsl['copy_link']} name="simple" value="revenues" bk="bk_primary" onClick={copyRawExportLink}/>
            </Col>
          </Row>
          <h6 >{props.trsl['exporting_full_link']}</h6>
          <div className='break_line'>{`https://api.casflo-app.com/exporting/revenues/${props.exporting.exporting_key}/${props.exporting.exporting_pass}`}</div>
          <Row>
            <Col xs={12} className='blanktop_05'>
              <RegularButton label={props.trsl['copy_full_link']} name="" value="revenues" bk="bk_primary" onClick={copyRawExportLink}/>
            </Col>
          </Row>
        </Col>        
      </Row>
    )
  } else return <></>
}

function ExportingNotice(props){
  if(props.view_disabled==false && props.exporting.raw_exporting===true) {
    return(
      <>
        <div className='warning_div_outside bk_info'>
          <div className='warning_div_header bk_info'>
            <h3>{props.trsl['exporting_notice']}</h3>
          </div>
          <div className='warning_div_inside'>
            <Row className='blanktop_10'>
              <Col xs={1}>
                <h6>1</h6>
              </Col>
              <Col xs={11}>
                <p>{props.trsl['exporting_notice_1']}</p>
              </Col>
              <Col xs={1}>
                  <h6>2</h6>
                </Col>
                <Col xs={11}>
                <p>{props.trsl['exporting_notice_2']}</p>
              </Col>
              <Col xs={1}>
                <h6>3</h6>
                </Col>
                <Col xs={11}>
                <p>{props.trsl['exporting_notice_3']}</p>
              </Col>
              <Col xs={1}>
                  <h6>4</h6>
                </Col>
                <Col xs={11}>
                <p>{props.trsl['exporting_notice_4']}</p>
              </Col>
              <Col xs={1}>
                  <h6>5</h6>
              </Col>
              <Col xs={11}>
                <p>{props.trsl['exporting_notice_5']}</p>
              </Col>
              <Col xs={1}>
                  <h6>6</h6>
              </Col>
              <Col xs={11}>
                <p>{props.trsl['exporting_notice_6']}</p>
              </Col>
            </Row>
          </div>
        </div>
      </>
    )
  } else return <></>
}

class GeneralSettings extends React.Component{
  render(){
    const props=this.props

    let disabled_settings=true
    if(this.props.permission=="owner" ){
      disabled_settings= false
    }

    return(
      <div >
        <h1>{this.props.budget_data.budget_name}</h1>
        <div className="blanktop_10">
          <RegularButton label={this.props.trsl['edit_settings']} bk='bk_primary' onClick={this.props.handleGoToInProject} name="settings" value={this.props.budget_id} disabled={disabled_settings}/>
        </div>
        <div className="project_edit_settings_row">
          <h3>{this.props.trsl['subscription']}</h3>
          <SubscriptioninBudget {...props}/>
        </div>
        <Col className='blanktop_10'></Col>
      </div>
    )
  }
}

function VersionsGauge(props){
  const progressCalculator=(v1,v2)=>{
    return ((v1/v2*100).toString()+ "%")
  }
  return(
    <div className='project_edit_gauge'>
      <div className='project_edit_gauge_t1'><h5>{props.trsl['version_max']}</h5></div>
      <div className='project_edit_gauge_t2'>{props.version_number}/{props.version_max}</div>
      <div className='project_edit_gauge_main'>
        <div className='project_edit_gauge_progress'style={{width: progressCalculator(props.version_number,props.version_max)}}></div>
      </div>
    </div>
  )
}


export default BudgetEdit;