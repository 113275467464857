import React from 'react';
import { Row, Col} from 'Support/grid'
import ReactGA from 'react-ga4';

import {BudgetSubscriptionSelect} from 'PRIVATE/BUDGET PROJECT/Budget Project Main/subscription';
import {Stripe} from 'PRIVATE/ACCOUNT/subscription_stripe';
import {BudgetNameDescription, ProjetFeasibilitySettings} from './settings';
import Loader from 'Support/loader';
import Panel from 'Support/panes';
import {CreateToogleButtons} from 'Support/toogle_buttons';

import ApiConnect from 'CONFIG/axios';
import UserContext from 'CONFIG/auth';
import {BudgetProjectCreator} from './budget_creator';

import 'CSS/project_style.css';
import default_values from 'JSON/countries_support.json';
import { RegularButton } from 'Support/listbuttons';

const initial_state={
  active_page:0,
  buy_button_disabled:true,
  buy_data_id: '',
  budget_name:"",
  project_description:"",
  budget_settings:{
    country:"PT",
    currency:"EUR",
    corporate_social_security:default_values.countries['PT'].corporate_social_security,
    irc:default_values.countries['PT'].irc,
    vat:default_values.countries['PT'].vat,
    vat_payment:default_values.countries['PT'].vat_payment,
    working_months:default_values.countries['PT'].working_months,
    raw_materials:true,
    employees:true,
    nature_or_functions:true,
    use_general_vat_rate:true,
    decimal_on_reports:false,
    use_historic:false,
    centers:[]  
  },
  pricing:"",
  loaded_pricing:false,
  subscription:{
    amount_to_pay:14.70,
    selected_duration:30,
    selected_number_of_versions:5,
    currency:"eur",
  }
}

class AddBudget extends React.Component{
  static contextType = UserContext

  constructor(props) {
    super(props);
    this.state = initial_state
    this.handleEditSettingsChange=this.handleEditSettingsChange.bind(this)
    this.handleChangeNameDescription=this.handleChangeNameDescription.bind(this)
    this.handleNameDescriptionMove=this.handleNameDescriptionMove.bind(this)
    this.handleCountryChange=this.handleCountryChange.bind(this)
  }

  componentDidMount=async()=>{
    this.getInititalData()
  }

  getInititalData=async()=>{
    const request={
    }
    try {
      const response = await ApiConnect.post('/api/budget_project/buy/getoptions', request)
      this.setState({
        pricing: response.data,
        loaded_pricing:true
      })
    } catch(error) {
        alert(error);
    }
  }

  //HANDLE FEATURE SELECTION
  calculateValue=(duration,versions)=>{
    const base=this.state.pricing.find(x => x.currency===this.state.subscription.currency).base
    const dur=base.durations.find(x =>x.duration==duration)
    const version_price=base.extra_version.price
    return (Number(dur.price) + Number(version_price)*(Number(versions) - Number(dur.versions))).toFixed(2)
  }

  handleChangeDuration=(event)=>{
    var val =this.calculateValue(event.target.value,this.state.subscription.selected_number_of_versions)
    let go= this.state.subscription
    go.amount_to_pay=val
    go.selected_duration=event.target.value
    this.setState({
      subscription:go
    })
  }

  handleChangeNumberOfVersions=(event)=>{
    var val =this.calculateValue(this.state.subscription.selected_duration, event.target.value)
    let go= this.state.subscription
    go.amount_to_pay=val
    go.selected_number_of_versions=event.target.value
    this.setState({
      subscription:go
    })
  }

  handleCurrencySelection=(event)=>{
    let go= this.state.subscription
    go.currency=event.target.value
    this.setState({
      subscription:go
    })
  }

  //HANDLE NAME AND DESCRIPTION AND MOVE FORWARD
  handleChangeNameDescription(event){
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]:value
    })
  }

  handleNameDescriptionMove(event){
    if (this.state.budget_name==="") {
      window.alert("You must give a name to your project")
    } else {
      this.setState({
        active_page:this.state.active_page+Number(event.target.value)
      })
    }
  }

  //HANDLE SETTINGS
  handleCreateMoveForward=(event)=>{
    this.setState({
      active_page:this.state.active_page+Number(event.target.value)
    })
  }

  handleEditSettingsChange=async(event)=>{
    const name = event.target.name;
    const value = event.target.value;
    let go = this.state.budget_settings;
    go[name]=value;
    this.setState({
      budget_settings:go
    })
  }

  handleCountryChange=async(event)=>{
    function validateInputs(input){
      if(input!==undefined){
        return input
      } else{
        return 0
      }
    }

    function validateVatInputs(input){
      if(input!==undefined){
        return input
      } else{
        return 30
      }
    }

    function validateMonthsInputs(input){
      if(input!==undefined){
        return input
      } else{
        return 12
      }
    }

    const name = event.target.name;
    const value = event.target.value;
    let go = this.state.budget_settings;
    go[name]=value;
    go.irc=validateInputs(default_values.countries[value].irc)
    go.corporate_social_security=validateInputs(default_values.countries[value].corporate_social_security)
    go.vat=validateInputs(default_values.countries[value].vat)
    go.vat_payment=validateVatInputs(default_values.countries[value].vat_payment)
    go.working_months=validateMonthsInputs(default_values.countries[value].working_months)
    this.setState({
      budget_settings:go
    })
  }

  // SUBMIT THE NEW PROJECT

  handleSubmitEvent=async(event)=>{
    let content= new BudgetProjectCreator(this.state.budget_name, this.state.project_description, this.state.budget_settings)
    const request ={
      content,
      user_id:this.props.user_id,
      subscription: this.state.subscription,
      currency:this.state.subscription.currency,
      selected_duration:this.state.subscription.selected_duration,
      selected_number_of_versions:this.state.subscription.selected_number_of_versions,
    }    
    try{
      const response=await ApiConnect.post('/api/budget_project/create', request)
      this.setState({
        buy_data_id:response.data.id,
        buy_button_disabled:false
      })
      ReactGA.event({category: "Budget Project", action: "Created Project"})

    } catch(error) {
      alert(`${error.response.status} ${error.response.data}`);
    }
  }


  render(){
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_BUDGET.MAIN.Budget_settings+Add_budget')

    const props={
      user_c:user_c,
      trsl:trsl,
      buy_button_disabled:this.state.buy_button_disabled,
      buy_data_id:this.state.buy_data_id,
      pricing:this.state.pricing,
      budget_name:this.state.budget_name,
      active_page:this.state.active_page,
      project_description:this.state.project_description,
      budget_settings:this.state.budget_settings,
      subscription:this.state.subscription,
      handleChangeDuration:this.handleChangeDuration,
      handleChangeNumberOfVersions:this.handleChangeNumberOfVersions,
      handleEditSettingsChange:this.handleEditSettingsChange,
      handleChangeNameDescription:this.handleChangeNameDescription,
      handleNameDescriptionMove:this.handleNameDescriptionMove,
      handleCreateMoveForward:this.handleCreateMoveForward,
      handleCountryChange:this.handleCountryChange,
      handleCurrencySelection:this.handleCurrencySelection,
      handleSubmitEvent:this.handleSubmitEvent,
      handleGoToInProject:this.props.handleGoToInProject
    }

    function torender(data, i){
      return data[i];
    } 

    const pages={
      true: <AddBudgetMaster {...props}/>,
      false: <Loader loader_message="setting_up_projects"/>
    }
    var i = this.state.loaded_pricing
    const rendering=torender(pages,i)

    return(
      <>
        {rendering}
      </>
    )
  }
}



function AddBudgetMaster (props){
  const keys=[
    {option_label:props.trsl['features']},
    {option_label:props.trsl['name']},
    {option_label:props.trsl['settings']},
    {option_label:props.trsl['finish']}
  ]

  function torender(data, i){
    return data[i];
  }
  const pages ={
    0: <BudgetSubscriptionSelect {...props}/>,
    1: <AddBudgetName {...props}/>,
    2: <AddBudgetGeneralSettings {...props}/>,
    3: <AddBudgetFinish {...props}/>
    
  }
  var i = props.active_page
  const renderizing = torender(pages,i)

  return(
    <Panel
      headerbar={<ProjectAddHeader {...props}/>}
      left={<CreateToogleButtons toogle_data={keys} selected={props.active_page}/>}
      right={renderizing}
    />
  )
}


class ProjectAddHeader extends React.Component{
  render(){
    return(
      <Row>
        <Col xs={12} md={10}>
          <h2>{this.props.trsl['create_project_title']}</h2>
        </Col>
        <Col xs={6} md={2}>
          <RegularButton bk='bk_primary' label={this.props.trsl['back_button']} name="list" onClick={this.props.handleGoToInProject}/>
        </Col>  
      </Row>
    );
  }
}

class AddBudgetName extends React.Component{
  render(){
    const props=this.props
    return (
      <>
      <Row>
        <Col xs={12}>
          <h2>{this.props.trsl['name']}</h2>
        </Col>
        <Col xs={12}>
          <BudgetNameDescription {...props} />  
        </Col>
        <Col xs={6}>
            <RegularButton bk='bk_primary'  value={-1} onClick={this.props.handleCreateMoveForward} label={this.props.trsl['previous']} />
          </Col>
          <Col xs={6}>
            <RegularButton bk='bk_primary'  value={1} onClick={this.props.handleNameDescriptionMove} label={this.props.trsl['next']} />
          </Col>
      </Row>
      </>
    );
  }
}


class AddBudgetGeneralSettings extends React.Component{
  render(){
    const props=this.props
    
    return (
      <>
      <Row>        
        <Col xs={12}>
          <h2>{this.props.trsl['settings']}</h2>
        </Col>
        <Col xs={12}>
          <ProjetFeasibilitySettings {...props}/>
        </Col>
        <Col xs={6}>
            <RegularButton bk='bk_primary' value={-1} onClick={this.props.handleCreateMoveForward} label={this.props.trsl['previous']} />
          </Col>
          <Col xs={6}>
            <RegularButton bk='bk_primary' value={1} onClick={this.props.handleCreateMoveForward} label={this.props.trsl['next']} />
          </Col>
      </Row>
      </>
    );
  }
}

class AddBudgetFinish extends React.Component{
  render(){
    return (
      <>
        <Row>
          <Col xs={12}>
            <h2>{this.props.trsl['finish']}</h2>
          </Col>
          <Col xs={4}>
            <h4>{this.props.trsl['exclamation']}</h4>
            {this.props.trsl['finish_creation_statement']}
            <h6>{this.props.trsl['budget_name']}:  {this.props.budget_name}</h6>
            <h6>{this.props.trsl['price']}: {this.props.subscription.amount_to_pay}</h6>
          </Col>
        </Row>
        <Row className='blanktop_10'>
        <Col xs={4}>
            <RegularButton bk='bk_primary' value={-1} onClick={this.props.handleCreateMoveForward} label={this.props.trsl['previous']} />
          </Col>
          <Col xs={4}>
            <RegularButton bk='bk_primary' onClick={this.props.handleSubmitEvent} label={this.props.trsl['confirm_project']} />
          </Col>
          <Col xs={4}>
              <Stripe session={this.props.buy_data_id} stripe_disabled={this.props.buy_button_disabled}/>
          </Col>
        </Row>
      </>
    );
  }
}


export default AddBudget;