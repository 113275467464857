import React from 'react';

import {Grid, Row, Col} from 'Support/grid';
import {Table} from 'Support/table';

import ApiConnect from 'CONFIG/axios';
import Session from 'CONFIG/session';
import UserContext from '../CONFIG/auth';

function InterestRatesData(props){

  return(
    <>
      <Grid fluid className="main_block">
        <Row>
          <Col xs={12}>
          <h1>Interest Rates</h1>
          </Col>
          <Col xs={12}>
          <Table>
            <Table.HeaderRow header_first_row='lalal' header_other_rows={[1,2,3]}/>
          </Table>
          </Col>
        </Row>
      </Grid>
    </>
  )
}

export default InterestRatesData;