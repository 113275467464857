import React from 'react';
import {Row, Col} from 'Support/grid'
import {Button} from 'react-bootstrap';

import ApiConnect from 'CONFIG/axios';

import './admin_style.css';



class AdminSuperChange extends React.Component{
  executeSuper=async()=>{
    const request={}
    try {
      await ApiConnect.post('api/admin/superchange', request)
    } catch(error) {
      alert(error);
    }
  }
  render(){
    return(
        <Row>
          <Col xs={4}>
          </Col>
          <Col xs={4}>
            <Button block onClick={this.executeSuper}>Execute Super</Button>
          </Col>
          <Col xs={4}>
          </Col>
        </Row>
    )
  }
}


export default AdminSuperChange;


