import React from 'react'
import {Row, Col} from 'Support/grid'
import {Button} from 'react-bootstrap'

import ApiConnect from 'CONFIG/axios';
import UserContext from 'CONFIG/auth';

import {AddUser} from 'Support/add_item';
import Loader from 'Support/loader';
import { DeleteButton } from 'Support/listbuttons';

const dateRenderizing=(input)=>{
  if(input!=undefined){
    return(
      new Intl.DateTimeFormat('en-US').format(new Date(input))
    )
  }
}

const booleanRenderizing=(input)=>{
  if(input===true){
    return 'true'
  } else if(input===false){
    return 'false'
  } else return ''
}

class PartnersManage extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      partnership_data: "",
      loaded:false
    }
  }

  componentDidMount(){
    this.handleGetPartnershipPackDataList()
  }

  handleGetPartnershipPackDataList=async()=>{
    const request={
      partnership_id:this.props.partnership_pack_id
    }
    try {
      const response = await ApiConnect.post('/api/user/partnership/details/get', request)
      this.setState({
        partnership_data:response.data,
        loaded:true
      })
    } catch(error) {
        alert(error);
    }
  }

  render(){
    const trsl=''
    const props={
      partnership_data:this.state.partnership_data,
      trsl:trsl,
      handleGoToPack:this.props.handleGoToPack,
      partnership_pack_id:this.props.partnership_pack_id,
      handleAddReceiver:this.props.handleAddReceiver
    }
    function torender(data, i){
      return data[i];
    }  
    const pages ={
      true: <ToDisplay {...props}/>,
      false: <Loader loader_message="rev_edit"/>
    }
    var i = this.state.loaded
    const renderizing = torender(pages,i)
    return(
      <>
        {renderizing}
      </>
    )
  }
}

class ToDisplay extends React.Component{
  static contextType = UserContext

  render(){
    const user_c= this.context
    const trsl= user_c.i18n.t('PRIVATE_PARTNERSHIPS.PARTNERSHIP_MANAGE')

    const props=this.props
    return(
      <>
        <PartnershipDetails {...props} trsl={trsl}/>
        <AddUser handleAdd={props.handleAddReceiver} trsl={trsl}/>
        <PartnershipUsers {...props} trsl={trsl}/>
      </>
    )
  }
}

function PartnershipDetails(props){
  const renderFixedDateOption=(input)=>{
    if(input==true){
      return (
        <Col xs={3} className='admin_al_user_cols'>
          <h6>{props.trsl['pm_flex']}</h6>
          {props.partnership_data.pts_duration_flex_days}
        </Col>
      )
    } else {
      return(
        <Col xs={3} className='admin_al_user_cols'>
          <h6>{props.trsl['pm_fixed']}</h6>
          {dateRenderizing(props.partnership_data.pts_duration_fixed_day)}
        </Col>
      )
    }
  }

  function renderDateType(input){
    if(input===true){
      return props.trsl['pm_flex_setting']
    } else{
      return props.trsl['pm_fixed_setting']
    }
  }
  return(
    <>
      <Row>
        <Col xs={9}>
          <h3>{props.trsl['pm_partnership']}</h3>
        </Col>
        <Col xs={3}>
          <Button size='sm' block onClick={props.handleGoToPack}>{props.trsl['pm_back_button']}</Button>
        </Col>
      </Row>
      <Row>
        <Col xs={3} className='admin_al_user_cols'>
          <h6>{props.trsl['pm_partnership_id']}</h6>
          {props.partnership_data._id}
        </Col>
        <Col xs={3} className='admin_al_user_cols'>
          <h6>{props.trsl['pm_partnership_description']}</h6>
          {props.partnership_data.pts_name}
        </Col>
        <Col xs={3} className='admin_al_user_cols'>
          <h6>{props.trsl['pm_partnership_type']}</h6>
          {props.trsl[props.partnership_data.pts_type]}
        </Col>
        <Col xs={3} className='admin_al_user_cols'>
          <h6>{props.trsl['pm_partnership_permission']}</h6>
          {props.trsl[props.partnership_data.pts_permission]}
        </Col>
        <Col xs={3} className='admin_al_user_cols'>
          <h6>{props.trsl['pm_partnership_subs']}</h6>
          {props.partnership_data.pts_number_subs}
        </Col>
        <Col xs={3} className='admin_al_user_cols'>
          <h6>{props.trsl['pm_partnership_collaborators']}</h6>
          {props.partnership_data.pts_number_of_colaborators}
        </Col>
        <Col xs={3} className='admin_al_user_cols'>
          <h6>{props.trsl['pm_partnership_viewers']}</h6>
          {props.partnership_data.pts_number_of_viewers}
        </Col>
        <Col xs={3} className='admin_al_user_cols'>
          <h6>{props.trsl['pm_partnership_versions']}</h6>
          {props.partnership_data.pts_number_of_versions}
        </Col>
        <Col xs={3} className='admin_al_user_cols'>
          <h6>{props.trsl['pm_partnership_duration_type']}</h6>
          {renderDateType(props.partnership_data.pts_duration_type)}
        </Col>
        {renderFixedDateOption(props.partnership_data.pts_duration_type)}
        <Col xs={3} className='admin_al_user_cols'>
          <h6>{props.trsl['pm_partnership_validity']}</h6>
          {dateRenderizing(props.partnership_data.pts_validity)}
        </Col>
      </Row>
    </>
  )
}

function PartnershipUsers(props){

  const handleDeleteReceiver=async(event)=>{
    const request={
      partnership_pack_id:props.partnership_pack_id,
      pts_id:event.target.value
    }
    try {
      const response = await ApiConnect.post('/api/user/partnership/receiver/delete', request)
      alert('Deleted')
    } catch(error) {
      alert(error);
    }
  }

  const item_holder = props.partnership_data.pts_receivers
  let item_distr=props.trsl['pm_partnership_no_users']
  if(item_holder !==undefined){
    item_distr = item_holder.map((value,key) =>
      <Row className='admin_al_lists_rows' middle='xs'>
        <Col xs={1}>
          {key+1}
        </Col>
        <Col xs={2}>
          {value.pts_receiver.user_name}
        </Col>
        <Col xs={2}>
          {value.pts_receiver.user_email}
        </Col>
        <Col xs={2}>
          {props.trsl[value.pts_receiver.user_status]}
        </Col>
        <Col xs={2}>
          {value.pts_sub._id}
        </Col>
        <Col xs={1}>
          {props.trsl[value.pts_sub.subs_used]}
        </Col>
        <Col xs={1}>
          {dateRenderizing(value.pts_sub.subs_used_date)}
        </Col>
          <DeleteButton value={value._id} permission={value.pts_sub.subs_used} handleDelete={handleDeleteReceiver}/>
        <Col xs={1}>
        </Col>
      </Row>
    )
  }
  return(
    <>
      <TableHeader {...props}/>
      {item_distr}
    </>
  )
}

function TableHeader(props){
  return(
    <Row className='list_table_header' middle='xs'>
      <Col xs={1}></Col>
      <Col xs={2}>{props.trsl['pm_partnership_users_name']}</Col>
      <Col xs={2}>{props.trsl['pm_partnership_users_email']}</Col>
      <Col xs={2}>{props.trsl['pm_registered_casflo']}</Col>
      <Col xs={2}>{props.trsl['pm_partnership_users_id']}</Col>
      <Col xs={1}>{props.trsl['pm_partnership_users_used']}</Col>
      <Col xs={1}>{props.trsl['pm_partnership_users_date_used']}</Col>
      <Col xs={1}></Col>
      </Row>
  )
}

export default PartnersManage