import React, {useState} from 'react';
import { Grid, Row, Col } from 'Support/grid';
import {ComplexCogsCreator} from '../../../../CONFIG/CREATORS/revenue_creator';

import {AssumptionHeaderSaveReturn} from 'PRIVATE/PROJECT/Feasibility/feasibility_items';

import {FormEdit, NonEditableForm, PeriodSelect, PeriodHeader, SingleInputFormFlat, Observations} from 'Support/inputforms'
import {DeleteButton} from 'Support/listbuttons.js';
import {AddItem} from 'Support/add_item';
import Loader from 'Support/loader';
import UserContext from 'CONFIG/auth';
import ApiConnect from 'CONFIG/axios';
import {calculateRevenues} from 'CALCULATIONS/cal_revenues';
import { ToogleButtons } from 'Support/toogle_buttons';


class RevenueEntry extends React.Component {
  static contextType = UserContext

  constructor(props) {
    super(props);
    this.state = {
      data: "",
      inflation:"",
      loaded:false,
      years:this.props.feasibility_settings.historic_years.concat(this.props.feasibility_settings.years)
    }
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleSimpleCogsChange = this.handleSimpleCogsChange.bind(this);
    this.handleObservationsChange = this.handleObservationsChange.bind(this);
    this.handleCogsInputChange=this.handleCogsInputChange.bind(this);
    this.handleSimpleCogsChange=this.handleSimpleCogsChange.bind(this);
    this.handleAddComplexCog=this.handleAddComplexCog.bind(this);
    this.handleEditComplexCog=this.handleEditComplexCog.bind(this);
    this.handleDeleteComplexCog=this.handleDeleteComplexCog.bind(this);
    this.handleDaysOfStock=this.handleDaysOfStock.bind(this);
  }


  //GENERAL FUNCTIONS
  componentDidMount= async()=>{
    const request={
    project_id:this.props.project_id,
    version_id:this.props.version_id,
    user_id:this.props.user_id,
    item_id:this.props.active_item,
    item_type:"revenues"
    }
    try {
      const response = await ApiConnect.post('/api/multitem/unique/get', request)
      this.setState({
        data:response.data.item,
        inflation:response.data.version_inflation,
        loaded:true
      })
    } catch(error) {
        alert(error);
    }
  }

  handleNameChange(event){
    const value = event.target.value;
    let go = this.state.data;
    go.rev_name=value;
    this.setState({data:go })
  }

  handleObservationsChange(event){
    const value = event.target.value;
    let go = this.state.data;
    go.rev_observations=value;
    this.setState({data:go })
  }

  handleSave=async()=>{
    const request={
      project_id:this.props.project_id,
      version_id:this.props.version_id,
      item_id:this.props.active_item,
      item_type:"revenues",
      content:this.state.data
    }
    try{
      const response = await ApiConnect.post('/api/multitem/item/modify', request)
      this.props.changeScreenView("list",0)
      this.props.handleLists.handleSaveUpdater(response.data,'revenues')
    } catch(error) {
        alert(error);
    }
  }


  //REVENUES FUNCTIONS
  handleEditChange=(id,item)=>{
    let go = this.state.data;
    go[id]=item;
    calculateRevenues(go, this.props.feasibility_settings,this.state.inflation)
    this.setState({data:go})
  }

  handlePeriodChange=async(event)=>{
    const value = event.target.value;
    let go = this.state.data;
    go.rev_quantity_type=value;
    calculateRevenues(go, this.props.feasibility_settings,this.state.inflation)
    this.setState({data:go})
  }

  //COGS
  handleCogsInputChange(event){
    let val=event.target.value
    if(val=="false"){val=false} else {val=true}
    let go = this.state.data
    go.rev_cogs.cogs_type=val
    calculateRevenues(go, this.props.feasibility_settings,this.state.inflation)
    this.setState({data:go})
  }

  //SIMPLE COGS
  handleSimpleCogsChange=(id,item)=>{
    let go = this.state.data
    go.rev_cogs.s_cogs[id]=item;
    calculateRevenues(go, this.props.feasibility_settings,this.state.inflation)
    this.setState({data:go})
  }

  //COMPLEX COGS
  //add a new complex cog
  handleAddComplexCog =(item)=>{
    let new_item=new ComplexCogsCreator(item, this.props.feasibility_settings.number_of_years+this.props.feasibility_settings.number_of_historic_years,this.props.feasibility_settings.vat)
    let go = this.state.data
    go.rev_cogs.c_cogs.push(new_item)
    this.setState({data:go})
  }
  //edit complex cog
  handleEditComplexCog=async(id, item)=>{
    let go = this.state.data;
    go.rev_cogs.c_cogs[id].c_cogs_item_value=item;
    calculateRevenues(go, this.props.feasibility_settings,this.state.inflation)
    this.setState({data:go})
  }
  
  //delete a complex cog
  handleDeleteComplexCog=async(event)=>{
    let index =event.target.value
    let go = this.state.data
    go.rev_cogs.c_cogs.splice(index,1)
    calculateRevenues(go, this.props.feasibility_settings,this.state.inflation)
    this.setState({data:go})
  }


  //EXECUTE DAYS OF STOCK
  handleDaysOfStock=async(id, item)=>{
    let go = this.state.data;
    go.rev_cogs[id]=item;
    calculateRevenues(go, this.props.feasibility_settings,this.state.inflation)
    this.setState({data:go})
  }

  render() {
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_PROJECT.PROJECT_FEASIBILITY.Individual_edits')

    const props ={
      trsl:trsl,
      handleScreenNavigation:this.props.handleScreenNavigation,
      feasibility_settings:this.props.feasibility_settings,
      user_permission:this.props.user_permission,
      data:this.state.data,
      years:this.state.years,
      handleNameChange:this.handleNameChange,
      handleObservationsChange:this.handleObservationsChange,
      handleSave:this.handleSave,
      handleEditChange:this.handleEditChange,
      handlePeriodChange:this.handlePeriodChange,
      handleCogsInputChange:this.handleCogsInputChange,
      handleSimpleCogsChange:this.handleSimpleCogsChange,
      handleAddComplexCog:this.handleAddComplexCog,
      handleEditComplexCog:this.handleEditComplexCog,
      handleDeleteComplexCog:this.handleDeleteComplexCog,
      handleDaysOfStock:this.handleDaysOfStock,
    }

    function torender(data, i){
      return data[i];
    }  
    const pages ={
      true: <ToDisplay {...props}/>,
      false: <Loader loader_message="rev_edit"/>
    }
    var i = this.state.loaded
    const renderizing = torender(pages,i)

    return(
      <>
        {renderizing}
      </>
    )
  }
}

class ToDisplay extends React.Component {
  render(){
    const props=this.props

    const revs_render =  {
      false:<Rev1 {...props}/>,
      true:<Rev2 {...props}/>
    }
    const cogs_render = {
      true: <SimpleCogs {...props}/>,
      false: <CogsEntry {...props}/>
    }

    return(
      <>
        <GlobalHeader {...props}/>
        <Row>
          <Col xs={12} className='blanktop_15'>
            <h5>{this.props.trsl['rev_sales_header']}</h5>
          </Col>
          {revs_render[this.props.data._rev_hist]}
          <Col xs={12} className='blanktop_15'>
            <h5>{this.props.trsl['cogs_header']}</h5>
            {cogs_render[this.props.data._rev_hist]}
          </Col>
          <Col xs={12}>
            <StockAssumptions {...props}/>
          </Col>
          <Col xs={12} className="observations_row">
            <Observations name="rev_observations" value={this.props.data.rev_observations} handleObservationsChange={this.props.handleObservationsChange}/>
          </Col>
        </Row>
      </>
    );
  }
}

class GlobalHeader extends React.Component{
  render(){
    const type_of_calculation_options=[
      {value:"pure_raw",option_label:this.props.trsl['raw_inputs']},
      {value:"full_value_growth",option_label:this.props.trsl['raw_inputs']}
    ]
    return(
      <>
        <AssumptionHeaderSaveReturn title={this.props.trsl['rev_header']} user_permission={this.props.user_permission} back={this.props.trsl['back_button']} save={this.props.trsl['save_back_button']} handleSave={this.props.handleSave} handleScreenNavigation={this.props.handleScreenNavigation}/>
        <Row className="feasibility_edit_globalheader_row">
          <SingleInputFormFlat xs={12} md={12} value={this.props.data.rev_name} label= {this.props.trsl['rev_name']} placeholder={this.props.trsl['rev_name']} handleEditItemChange={this.props.handleNameChange}/>
        </Row>         
      </>
    );
  }
}



//REV 1 Introduction forms
class Rev1 extends React.Component{
  render(){
    const props=this.props
    return(
      <>
      <PeriodSelect xs={12} label={this.props.trsl['rev_quantities_per']} name="rev_quantity_type" handlePeriodChange={this.props.handlePeriodChange} selected={this.props.data.rev_quantity_type}/>
      <PeriodHeader label={this.props.trsl['year']} periods={this.props.years}/>
      <Col xs={12}>
        <QuantityEdit {...props}/>
        <QuantityGrowthNoEdit {...props}/>
        <PriceEdit {...props}/>
        <PriceWithInflation {...props}/>
        <VatEdit {...props} />
        <TotalRevenue {...props}/>
        <VatValue {...props}/>
      </Col>  
      </>
    )
  }
}

class PriceEdit extends React.Component{
  render(){
    return(
      <FormEdit
      use_historic={this.props.feasibility_settings.use_historic}
      item_holder={this.props.data.rev_price}
      id="rev_price"
      handleForm={this.props.handleEditChange}
      label={this.props.trsl['rev_price']}
      unit={new Intl.NumberFormat('ja-JP', { style: 'currency', currency: this.props.feasibility_settings.currency}).format(0).replace(/\d./g,"")}
      />
    );
  }
}

class PriceWithInflation extends React.Component{
  render(){ 
    return(
      <NonEditableForm
      s_value={this.props.s_value}
      item_holder={this.props.data._rev_price_inflated}
      label={this.props.trsl['rev_price_inflated']}
      value_type="currency"
      currency={this.props.feasibility_settings.currency}
      />
    );
  }
}


class QuantityEdit extends React.Component{
  render(){ 
    return(
      <FormEdit 
      use_historic={this.props.feasibility_settings.use_historic}
      item_holder={this.props.data.rev_quantity}
      id="rev_quantity"
      handleForm={this.props.handleEditChange}
      label={this.props.trsl['rev_quantity']}
      unit="U"
      />
    );
  }
}

class QuantityGrowthNoEdit extends React.Component{
  render(){ 
    return(
      <NonEditableForm
      s_value={this.props.s_value}
      item_holder={this.props.data.rev_quantity_growth}
      label={this.props.trsl['growth_rate']}
      value_type="percentage"
      />
    );
  }
}

class VatEdit extends React.Component{
  render(){
    const vatRendering=(input)=>{
      if(input===false){
        return(
          <FormEdit 
          use_historic={this.props.feasibility_settings.use_historic}
          item_holder={this.props.data.rev_vat_rate}
          id="rev_vat_rate"
          handleForm={this.props.handleEditChange}
          label={this.props.trsl['vat_rate']}
          unit="%"
          />
        )
      }
    }
    let renderizing=""
    renderizing=vatRendering(this.props.feasibility_settings.use_general_vat_rate)
    return(
      <>
        {renderizing}
      </>
    );
  }
}

class TotalRevenue extends React.Component{
  render(){ 
    return(
      <NonEditableForm
      item_holder={this.props.data._rev_total_revenue}
      label={this.props.trsl['rev_revenue']}
      value_type="currency"
      currency={this.props.feasibility_settings.currency}
      />
    );
  }
}

class VatValue extends React.Component{
  render(){ 
    return(
      <NonEditableForm
      item_holder={this.props.data._rev_total_vat}
      label={this.props.trsl['vat_total']}
      value_type="currency"
      currency={this.props.feasibility_settings.currency}
      />
    );
  }
}


//REV 2 Introduction forms
class Rev2 extends React.Component{
  render(){
    const props=this.props
    return(
      <Col xs={12}>
                    <PeriodHeader label={this.props.trsl['year']} periods={this.props.years}/>
        <Rev2Growth {...props}/>
        <VatEdit {...props} />
        <TotalRevenue {...props}/>
        <VatValue {...props}/>
      </Col>  
    )
  }
}

class Rev2Growth extends React.Component{
  render(){ 
    return(
      <FormEdit
      use_historic={this.props.feasibility_settings.use_historic}
      item_holder={this.props.data.rev_quantity_growth}
      id="rev_quantity_growth"
      handleForm={this.props.handleEditChange}
      label={this.props.trsl['growth_rate']}
      unit="%"
      />
    );
  }
}

//
/////COGS/////
//

class CogsEntry extends React.Component {
  render() {
    const props=this.props
    
    function torender(data, i){
      return data[i];
    }
    const pages ={
      true: <SimpleCogs {...props}/>,
      false: <ComplexCogs {...props}/>
    }
    var i = this.props.data.rev_cogs.cogs_type
    const renderizing = torender(pages,i)
    
    return(
        <Row >
          <CogsHeader {...props} />
          <Col xs={12}>
            {renderizing}
          </Col>
          <Col xs={11} className="feasibility_list">
        </Col>
        <Col xs={1}> </Col>
        </Row>

    );
  }
}

class CogsHeader extends React.Component{
  render(){
    const toogle_options=[
      {option_label:this.props.trsl['cogs_option1'],value:true},
      {option_label:this.props.trsl['cogs_option2'],value:false},
    ]
    return(
      <Col xs={12}>
        <Row>
          <ToogleButtons xs={12} md={12} label={this.props.trsl['cogs_options']} selected={this.props.data.rev_cogs.cogs_type} toogle_data={toogle_options} handleToogleButton={this.props.handleCogsInputChange}/>
        </Row>
      </Col>
    );
  }
}

class SimpleCogs extends React.Component{
  render(){
    const props=this.props
    return(
      <>
      <Row>
        <Col xs={12} >
          <PeriodHeader label={this.props.trsl['year']} periods={this.props.years}/>
        </Col>
        <Col xs={12} className="feasibility_list">
          <SimpleCogsPercentageEdit {...props}/>
          <SimpleCogsVatRate {...props}/>
          <SimpleCogsValue {...props} />
          <SimpleCogsVatValue {...props}/>
        </Col>
      </Row>
      </>
    );
  }
}

class SimpleCogsPercentageEdit extends React.Component{
  render(){
    return(
      <FormEdit 
      use_historic={this.props.feasibility_settings.use_historic}
      item_holder={this.props.data.rev_cogs.s_cogs.s_cogs_percentage} 
      id="s_cogs_percentage"
      handleForm={this.props.handleSimpleCogsChange}
      label={this.props.trsl['cogs_raw%']}
      unit="%"
      />
    );
  }
}

class SimpleCogsVatRate extends React.Component{
  render(){
    const vatRendering=(input)=>{
      if(input===false){
        return(
          <FormEdit 
          use_historic={this.props.feasibility_settings.use_historic}
          item_holder={this.props.data.rev_cogs.s_cogs.s_cogs_vat_rate} 
          id="s_cogs_vat_rate"
          handleForm={this.props.handleSimpleCogsChange}
          label={this.props.trsl['vat_rate']}
          unit="%"
          />
        )
      }
    }
    let renderizing=""
    renderizing=vatRendering(this.props.feasibility_settings.use_general_vat_rate)
    return(
      <>
        {renderizing}
      </>
    );
  }
}

class SimpleCogsValue extends React.Component{
  render(){ 
    return(
      <NonEditableForm
      item_holder={this.props.data.rev_cogs._cogs_total}
      label={this.props.trsl['cogs_complex_component_cost_inflated']}
      value_type="currency"
      currency={this.props.feasibility_settings.currency}
      />
    );
  }
}

class SimpleCogsVatValue extends React.Component{
  render(){ 
    return(
      <NonEditableForm
      item_holder={this.props.data.rev_cogs._cogs_vat_total}
      label={this.props.trsl['vat_total']}
      value_type="currency"
      currency={this.props.feasibility_settings.currency}
      />
    );
  }
}

function ComplexCogs (props){
  const [c_cogs_render, setRender] = useState("list");
  const [item, setItem] = useState("");
  const handleChange = (val) => {
    setItem(val)
    setRender("edit")
  }

  function torender(data, i){
    return data[i];
  }  
  const pages ={
    list: <ComplexCogsList {...props} handleChange={handleChange}/>,
    edit: <ComplexCogsEdit {...props}/>
  }
  var i = c_cogs_render
  const renderizing = torender(pages,i)

  return(
    <div>
      {renderizing}
    </div>
  )
}

class ComplexCogsList extends React.Component{
  render(){    
    const props=this.props
    const data = this.props.data.rev_cogs.c_cogs
    const data_distr = data.map((keys,value) =>
      <Row middle="xs" className="non_editable_list">
        <Col xs={11}>
          <FormEdit
          use_historic={this.props.feasibility_settings.use_historic}
          item_holder={keys.c_cogs_item_value}
          id={value}        
          handleForm={this.props.handleEditComplexCog}
          label={keys.c_cogs_item_name}
          unit={new Intl.NumberFormat('ja-JP', { style: 'currency', currency: this.props.feasibility_settings.currency}).format(0).replace(/\d./g,"")}
          />
        </Col>

        <Col xs={1}>
          <Row>
            <DeleteButton handleDelete={this.props.handleDeleteComplexCog} value={value}/>
          </Row>
        </Col>
      </Row>
    );
    return(
      <>
        <Row className="feasibility_revenues_add_item_cogs">       
          <Col xs={6}>
            <AddItem add_item={this.props.trsl["cogs_add_component"]} user_permission={this.props.user_permission} handleAdd={this.props.handleAddComplexCog} />
          </Col>
          <Col xs={11} >
            <PeriodHeader label={this.props.trsl['year']} periods={this.props.years}/>
          </Col>
          <Col xs={1}></Col>
          <Col className="feasibility_list" xs={12}>
            {data_distr}
            
          </Col>
          <Col className="feasibility_list" xs={11}>
            <ComplexCogsVatRate {...props}/>
            <ComplexCogsValue {...props} />
            <ComplexCogsVatValue {...props}/>
          </Col>
        </Row>
      </>
    );
  }
}

class ComplexCogsEdit extends React.Component {
  render(){
    const props=this.props
    return(
      <Grid fluid className="feasibility_box"> 
        <Col xs={12} className="list_table_header">
          <PeriodHeader label={this.props.trsl['year']} periods={this.props.years}/>
        </Col>
      </Grid>
    )
  }
}


class ComplexCogsVatRate extends React.Component{
  render(){
    const vatRendering=(input)=>{
      if(input===false){
        return(
          <FormEdit 
          use_historic={this.props.feasibility_settings.use_historic}
          item_holder={this.props.data.rev_cogs.s_cogs.s_cogs_vat_rate} 
          id="s_cogs_vat_rate"
          handleForm={this.props.handleSimpleCogsChange}
          label={this.props.trsl['vat_rate']}
          unit="%"
          />
        )
      }
    }
    let renderizing=""
    renderizing=vatRendering(this.props.feasibility_settings.use_general_vat_rate)
    return(
      <>
        {renderizing}
      </>
    );
  }
}


class ComplexCogsValue extends React.Component{
  render(){ 
    return(
      <NonEditableForm
      item_holder={this.props.data.rev_cogs._cogs_total}
      label={this.props.trsl['cogs_complex_component_cost_inflated']}
      value_type="currency"
      currency={this.props.feasibility_settings.currency}
      />
    );
  }
}

class ComplexCogsVatValue extends React.Component{
  render(){ 
    return(
      <NonEditableForm
      item_holder={this.props.data.rev_cogs._cogs_vat_total}
      label={this.props.trsl['vat_total']}
      value_type="currency"
      currency={this.props.feasibility_settings.currency}
      />
    );
  }
}


class StockAssumptions extends React.Component{
  render(){
    const props=this.props

    return(
      <>
        <Col sm={12} className="blanktop_15">
          <h5>{this.props.trsl['stock_header']}</h5>
        </Col>
        <Row >
          <Col xs={12} >
            <PeriodHeader label={this.props.trsl['year']} periods={this.props.years}/>
          </Col>
          <Col xs={12} >
            <DaysOfStock {...props} />
            <Inventory {...props} />
          </Col>
        </Row>
      </>
    )
  }
}

class DaysOfStock extends React.Component{
  render(){
    return(
      <FormEdit 
      use_historic={this.props.feasibility_settings.use_historic}
      item_holder={this.props.data.rev_cogs.days_of_stock} 
      id="days_of_stock"
      handleForm={this.props.handleDaysOfStock}
      label={this.props.trsl['stock_days']}
      unit="D"
      />
    );
  }
}

class Inventory extends React.Component{
  render(){ 
    return(
      <NonEditableForm
      item_holder={this.props.data.rev_cogs._inventory}
      label={this.props.trsl['stock_total']}
      value_type="currency"
      currency={this.props.feasibility_settings.currency}
      />
    );
  }
}


export default RevenueEntry;