import { utilityPestelRow, utilityaddBulletText } from "./utilities";

function MakeSlideCanvas (pptx, props, theme){
  if(props.data.canvas!==null){
    //Description
    if(props.options.canvas.description===true){
      var description_0 = pptx.addSlide('SEPARATOR');
      description_0.addText(
        props.trsl['description'],{ placeholder: "title1" }
      )

      var description_1 = pptx.addSlide("NO TITLE");
      description_1.addText( props.trsl['formal_name'],{ x:"5%", y:1, w:'95%', h:0.25, valign:"top", color: theme.cs.primary, fontSize:14, fontFace:theme.ft.head, autoFit:true })
      description_1.addText( props.data.canvas.description.formal_name,{ x:"5%", y:1.25, w:'95%', h:0.25, valign:"top", color: theme.cs.primary, fontSize:10, fontFace:theme.ft.body, autoFit:true })
      description_1.addText( props.trsl['incorporation'],{ x:"5%", y:1.75, w:'95%', h:0.25, valign:"top", color: theme.cs.primary, fontSize:14, fontFace:theme.ft.head, autoFit:true })
      description_1.addText( props.data.canvas.description.incorporation,{ x:"5%", y:2, w:'95%', h:0.25, valign:"top", color: theme.cs.primary, fontSize:10, fontFace:theme.ft.body, autoFit:true })
      description_1.addText( props.trsl['incorporation_country'],{ x:"5%", y:2.5, w:'95%', h:0.25, valign:"top", color: theme.cs.primary, fontSize:14, fontFace:theme.ft.head, autoFit:true })
      description_1.addText( props.data.canvas.description.incorporation_country,{ x:"5%", y:2.75, w:'95%', h:0.25, valign:"top", color: theme.cs.primary, fontSize:10, fontFace:theme.ft.body, autoFit:true })
      description_1.addText( props.trsl['established_year'],{ x:"5%", y:3.25, w:'95%', h:0.25, valign:"top", color: theme.cs.primary, fontSize:14, fontFace:theme.ft.head, autoFit:true })
      description_1.addText( props.data.canvas.description.established_year,{ x:"5%", y:3.5, w:'95%', h:0.25, valign:"top", color: theme.cs.primary, fontSize:10, fontFace:theme.ft.body, autoFit:true })
      description_1.addText( props.trsl['activity'],{ x:"5%", y:4, w:'95%', h:0.25, valign:"top", color: theme.cs.primary, fontSize:14, fontFace:theme.ft.head, autoFit:true })
      description_1.addText( props.data.canvas.description.activity,{ x:"5%", y:4.25, w:'95%', h:0.25, valign:"top", color: theme.cs.primary, fontSize:10, fontFace:theme.ft.body, autoFit:true })

      var description_2 = pptx.addSlide("ONE TITLE");
      description_2.addText(props.trsl['products'],{ placeholder: "title1" })
      description_2.addText( props.data.canvas.description.products,{ x:"5%", y:1, w:'95%', h:0.25, valign:"top", color: theme.cs.primary, fontSize:10, fontFace:theme.ft.body, autoFit:true })

      var description_3 = pptx.addSlide("ONE TITLE");
      description_3.addText(props.trsl['history'],{ placeholder: "title1" })
      description_3.addText( props.data.canvas.description.history,{ x:"5%", y:1, w:'95%', h:0.25, valign:"top", color: theme.cs.primary, fontSize:10, fontFace:theme.ft.body, autoFit:true })
    }
    

    //PESTEL
    if(props.options.canvas.pestel===true){
      var pestel0 = pptx.addSlide('SEPARATOR');
      pestel0.addText(
        props.trsl['pestel'],{ placeholder: "title1" }
      )
      var pestel1 = pptx.addSlide("ONE TITLE");
      pestel1.addText(
        props.trsl['pestel'],{ placeholder: "title1" }
      )
      pestel1.addTable(utilityPestelRow(props.trsl['political'],props.data.canvas.pestel.political,theme,14,10), { x: "2.5%", y: 0.8, w:'30%', fontFace: theme.ft.body})
      pestel1.addTable(utilityPestelRow(props.trsl['economic'],props.data.canvas.pestel.economic,theme,14,10), { x: "35%", y: 0.8, w:'30%', fontFace: theme.ft.body})
      pestel1.addTable(utilityPestelRow(props.trsl['social'],props.data.canvas.pestel.social,theme,14,10), { x: "67.5%", y: 0.8, w:'30%', fontFace: theme.ft.body})

      var pestel2 = pptx.addSlide("ONE TITLE");
      pestel2.addText(
        props.trsl['pestel'],{ placeholder: "title1" }
      )
      pestel2.addTable(utilityPestelRow(props.trsl['technological'],props.data.canvas.pestel.technological,theme,14,10), { x: "2.5%", y: 0.8, w:'30%', fontFace: theme.ft.body})
      pestel2.addTable(utilityPestelRow(props.trsl['environmental'],props.data.canvas.pestel.environmental,theme,14,10), { x: "35%", y: 0.8, w:'30%', fontFace: theme.ft.body})
      pestel2.addTable(utilityPestelRow(props.trsl['legal'],props.data.canvas.pestel.legal,theme,14,10), { x: "67.5%", y: 0.8, w:'30%', fontFace: theme.ft.body})
    }
    
    //SWOT
    if(props.options.canvas.swot===true){
      var swot0 = pptx.addSlide('SEPARATOR');
      swot0.addText(
        props.trsl['swot'],{ placeholder: "title1" }
      )
      var swot1 = pptx.addSlide("ONE TITLE");
      swot1.addText(
        props.trsl['swot'],{ placeholder: "title1" }
      )
      swot1.addText(
        props.trsl['strength'],{ x:"5%", y:1, w:'45%', h:0.5, valign:"top", color: theme.cs.primary, fontSize:20, fontFace:"Calibri Light", autoFit:true }
      )
      swot1.addText(
        utilityaddBulletText(props.data.canvas.swot.strengths),{ x:"5%", y:1.5, w:'45%', h:4, valign:"top", fontSize:14, color: theme.cs.darktext, bullet:true, autoFit:true }
      )
      swot1.addText(
        props.trsl['weaknesses'],{ x:"50%", y:1, w:'45%', h:0.5, valign:"top", color: theme.cs.primary, fontSize:20, fontFace:"Calibri Light", autoFit:true }
      )
      swot1.addText(
        utilityaddBulletText(props.data.canvas.swot.weaknesses),{ x:"50%", y:1.5, w:'45%', h:4, valign:"top", fontSize:14, color: theme.cs.darktext, bullet:true, autoFit:true }
      )

      var swot2 = pptx.addSlide("ONE TITLE");
      swot2.addText(
        props.trsl['swot'],{ placeholder: "title1" }
      )
      swot2.addText(
        props.trsl['opportunities'],{ x:"5%", y:1, w:'45%', h:0.5, valign:"top", color: theme.cs.primary, fontSize:20, fontFace:"Calibri Light", autoFit:true }
      )
      swot2.addText(
        utilityaddBulletText(props.data.canvas.swot.opportunities),{ x:"5%", y:1.5, w:'45%', h:4, valign:"top", fontSize:14, color: theme.cs.darktext, bullet:true, autoFit:true }
      )
      swot2.addText(
        props.trsl['threats'],{ x:"50%", y:1, w:'45%', h:0.5, valign:"top", color: theme.cs.primary, fontSize:20, fontFace:"Calibri Light", autoFit:true }
      )
      swot2.addText(
        utilityaddBulletText(props.data.canvas.swot.threats),{ x:"50%", y:1.5, w:'45%', h:4, valign:"top", fontSize:14, color: theme.cs.darktext, bullet:true, autoFit:true }
      )
    }

    //BMC
    if(props.options.canvas.bmc===true){
      var slidebmc0 = pptx.addSlide("SEPARATOR");
      slidebmc0.addText(
        props.trsl['bmc'],{ placeholder: "title1" }
      )
      var slide_bmc = pptx.addSlide("ONE TITLE");
      slide_bmc.addText(
        props.trsl['bmc'],{ placeholder: "title1" }
      )
      slide_bmc.addText(
        props.trsl['key_partners'],{ x:"5%", y:0.75, w:'17.75%', h:0.25, valign:"top", color:theme.cs.darktext, fontSize:12, fontFace:"Calibri Light", autoFit:true }
      )
      slide_bmc.addText(
        utilityaddBulletText(props.data.canvas.bmc.key_partners),{ x:"5%", y:1, w:'17.75%', h:2.75, valign:"top", fontSize:8, fill:theme.cs.lightaccent, autoFit:true }
      )
      slide_bmc.addText(
        props.trsl['key_resource'],{ x:"23%", y:0.75, w:'17.75%', h:0.25, valign:"top", color:theme.cs.darktext, fontSize:12, fontFace:"Calibri Light", autoFit:true }
      )
      slide_bmc.addText(
        utilityaddBulletText(props.data.canvas.bmc.key_resource),{ x:"23%", y:1, w:'17.75%', h:1.25, valign:"top", fontSize:8, fill:theme.cs.lightaccent, autoFit:true }
      )
      slide_bmc.addText(
        props.trsl['key_activities'],{ x:"23%", y:2.25, w:'17.75%', h:0.25, valign:"top", color:theme.cs.darktext, fontSize:12, fontFace:"Calibri Light", autoFit:true }
      )
      slide_bmc.addText(
        utilityaddBulletText(props.data.canvas.bmc.key_activities),{ x:"23%", y:2.5, w:'17.75%', h:1.25, valign:"top", fontSize:8, fill:theme.cs.lightaccent, autoFit:true }
      )
      slide_bmc.addText(
        props.trsl['value_proposition'],{ x:"41%", y:0.75, w:'17.75%', h:0.25, valign:"top", color:theme.cs.darktext, fontSize:12, fontFace:"Calibri Light", autoFit:true }
      )
      slide_bmc.addText(
        utilityaddBulletText(props.data.canvas.bmc.value_proposition),{ x:"41%", y:1, w:'17.75%', h:2.75, valign:"top", fontSize:8, fill:theme.cs.lightaccent, autoFit:true }
      )
      slide_bmc.addText(
        props.trsl['client_relationship'],{ x:"59%", y:0.75, w:'17.75%', h:0.25, valign:"top", color:theme.cs.darktext, fontSize:12, fontFace:"Calibri Light", autoFit:true }
      )
      slide_bmc.addText(
        utilityaddBulletText(props.data.canvas.bmc.client_relationship),{ x:"59%", y:1, w:'17.75%', h:1.25, valign:"top", fontSize:8, fill:theme.cs.lightaccent, autoFit:true }
      )
      slide_bmc.addText(
        props.trsl['client_channels'],{ x:"59%", y:2.25, w:'17.75%', h:0.25, valign:"top", color:theme.cs.darktext, fontSize:12, fontFace:"Calibri Light", autoFit:true }
      )
      slide_bmc.addText(
        utilityaddBulletText(props.data.canvas.bmc.client_channels),{ x:"59%", y:2.5, w:'17.75%', h:1.25, valign:"top", fontSize:8, fill:theme.cs.lightaccent, autoFit:true }
      )
      slide_bmc.addText(
        props.trsl['client_segment'],{ x:"77%", y:0.75, w:'17.75%', h:0.25, valign:"top", color:theme.cs.darktext, fontSize:12, fontFace:"Calibri Light", autoFit:true }
      )
      slide_bmc.addText(
        utilityaddBulletText(props.data.canvas.bmc.client_segment),{ x:"77%", y:1, w:'17.75%', h:2.75, valign:"top", fontSize:8, fill:theme.cs.lightaccent, autoFit:true }
      )
      slide_bmc.addText(
        props.trsl['costs'],{ x:"5%", y:3.75, w:'44,75%', h:0.25, valign:"top", color:theme.cs.darktext, fontSize:12, fontFace:"Calibri Light", autoFit:true }
      )
      slide_bmc.addText(
        utilityaddBulletText(props.data.canvas.bmc.costs),{ x:"5%", y:4, w:'44.75%', h:1, valign:"top", fontSize:8, fill:theme.cs.lightaccent, autoFit:true }
      )
      slide_bmc.addText(
        props.trsl['revenues'],{ x:"50%", y:3.75, w:'44.75%', h:0.25, valign:"top", color:theme.cs.darktext, fontSize:12, fontFace:"Calibri Light", autoFit:true }
      )
      slide_bmc.addText(
        utilityaddBulletText(props.data.canvas.bmc.revenues),{ x:"50%", y:4, w:'44.75%', h:1, valign:"top", fontSize:8, fill:theme.cs.lightaccent, autoFit:true }
      )
    }

    //Competititve environment
    if(props.options.canvas.competitive===true){
      var competitive_0 = pptx.addSlide('SEPARATOR');
      competitive_0.addText(props.trsl['competitive'],{ placeholder: "title1" })

      const competitive_1_data=[
        {
          name:"buse",
          labels: [props.trsl['clients'], props.trsl['suppliers'], props.trsl['competition'], props.trsl['entrants'], props.trsl['substitutes']],
          values: [props.data.canvas.competitive.client_score, props.data.canvas.competitive.suppliers_score, props.data.canvas.competitive.competition_score, props.data.canvas.competitive.entrants_score, props.data.canvas.competitive.substitutes_score]
        }
      ]
      var competitive_1 = pptx.addSlide("ONE TITLE");
      competitive_1.addText(
        props.trsl['competitive'],{ placeholder: "title1" }
      )
      competitive_1.addChart(pptx.ChartType.radar,competitive_1_data, {x:"10%", y:"25%", w:"80%", h:"60%", chartColors:[theme.cs.primary], catAxisLabelFontFace:"Lato", chartArea: { fill: { color: "FFFFFF" } }})
      competitive_1.addText(props.trsl['competitive_0'],{ x:"10%", y:"70%", w:'30%', h:.5, color:theme.cs.primary, fontSize:10, fontFace: theme.ft.body  })
      competitive_1.addText(props.trsl['competitive_5'],{ x:"60%", y:"70%", w:'30%', h:.5, color:theme.cs.primary, fontSize:10, fontFace: theme.ft.body  })
    }
  }
}

export {MakeSlideCanvas}