import React from 'react';
import ReactGA from 'react-ga4';

import {Grid, Row, Col} from 'Support/grid';
import {Image, Button} from 'react-bootstrap';

import UserContext from 'CONFIG/auth';
import {ApiPublicConnect} from 'CONFIG/axios';
import Session from 'CONFIG/session';

import countries from 'JSON/countries_pt.json';

import FirstLogin from './first_pass';

import {SingleInputForm, SingleInputEmailForm,SingleInputPasswordForm,SingleDateForm, Dropdown} from 'Support/inputforms';
import { RegularButton, RegularLinkButton } from 'Support/listbuttons';
import {RegisterViseeon} from 'TEMPLATES/Viseeon/public_templates'
import {RegisterBazaar} from 'TEMPLATES/BAZAAR/public_templates'
import logo2 from 'images/logo/logo.png';
import './public.css';
import { ToogleButtons } from 'Support/toogle_buttons';

class Register extends React.Component{
  static contextType = UserContext

  constructor(props) {
    super(props);
    this.state = {
      locale:Session.getLocale(),
      username:"",
      email:"",
      type_password:"",
      retype_password:"",
      birth:"",
      country:"PT",
      terms:false,
      communication:false,
      render_register:"register"
    }
    this.handleChange=this.handleChange.bind(this)
    this.handleSubmit=this.handleSubmit.bind(this)
    this.handleCheck=this.handleCheck.bind(this)
    this.moveToApp=this.moveToApp.bind(this)
    this.handleLanguage=this.handleLanguage.bind(this)
  }

  handleLanguage=(event)=>{
    const user_c=this.context
    user_c.setLocale(event.target.value)  
    this.setState({
      locale:event.target.value
    })
  }

  handleChange=(event)=>{
    let go =this.state
    go[event.target.name]=event.target.value
    this.setState({go})
  }
  
  handleCheck=(event)=>{

    let go =this.state
    go[event.target.name]=!go[event.target.name]
    this.setState({go})
  }

  handleSubmit=async(event)=>{
    var url=(window.location.href).toLowerCase()
    let source='casflo'

    const possible_sources=[
      {viseeon:'viseeon-plan'},
      {bazaar:'bazaar'}
    ]

    for(var i=0;i<possible_sources.length;i++){
      if(url.includes(Object.values(possible_sources[i]))){
        source=Object.keys(possible_sources[i])[0]
      }
    }
    
    if(this.state.terms===true){
      const request={
        locale:this.state.locale,
        name:this.state.username,
        password:this.state.type_password,
        email:this.state.email,
        birth:this.state.birth,
        gender:this.state.gender,
        country:this.state.country,
        communication:this.state.communication,
        source:source
      }

      try{
        const contexts = this.context
        const response= await ApiPublicConnect.post('/user/create', request)
        contexts.setUser(response.headers.token,response.data.locale, response.data.user_type,response.data.user_avatar,response.data.template)
        ReactGA.event({category: "Register", action: "Registered"})
        this.setState({
          render_register:"prepare"
        })
      } catch (error) {
        this.props.activateAlert('error','register_without_conditions_err_h',error.response.data)
      }
    } else {
      this.props.activateAlert('error','register_without_conditions_err_h','register_without_conditions_err_b')
    }
  }

  moveToApp(){
    window.location.href=('/app')
  }

  render(){
    const user_c=this.context
    const template=user_c.template
    const props={
      toErase:this.toErase,
      locale:this.state.locale,
      username:this.state.username,
      email:this.state.email,
      type_password:this.state.type_password,
      retype_password:this.state.retype_password,
      birth:this.state.birth,
      gender:this.state.gender,
      country:this.state.country,
      terms:this.state.terms,
      communication:this.state.communication,
      render_register:this.state.render_register,
      handleChange:this.handleChange,
      handleCheck:this.handleCheck,
      handleSubmit:this.handleSubmit,
      handleLanguage:this.handleLanguage
    }

    function torender(data, i){
      return data[i];
      }  
    const pages ={
    register: <RegisterRender {...props} />,
    prepare: <FirstLogin moveToApp={this.moveToApp} template={template}/>
    }
    const renderizing = torender(pages,this.state.render_register)

    return(
      <>{renderizing}</>
    )
  }
}

class RegisterRender extends React.Component{
  static contextType = UserContext
  render(){
    const user_c= this.context
    const trsl=user_c.i18n.t('PUBLIC.Register')

    const register_props={
      user_c:user_c,
      toErase:this.props.toErase,
      username:this.props.username,
      email:this.props.email,
      type_password:this.props.type_password,
      retype_password:this.props.retype_password,
      birth:this.props.birth,
      gender:this.props.gender,
      country:this.props.country,
      terms:this.props.terms,
      communication:this.props.communication,
      render_register:this.props.render_register,
      handleChange:this.props.handleChange,
      handleCheck:this.props.handleCheck,
      handleSubmit:this.props.handleSubmit,
    }

    const props={
      trsl:trsl,
      register_form:<RegisterForm trsl={trsl} {...register_props}/>,
      change_languages:<ChangeLanguages locale={this.props.locale} trsl={trsl} handleLanguage={this.props.handleLanguage}/>,
      go_login:<GoLogin trsl={trsl}/>,
      go_activation:<GoActivationRequest trsl={trsl}/>,
      go_password:<GoPassword trsl={trsl}/>
    }

    if(user_c.template==='viseeon'){
      return <RegisterViseeon {...props}/>
    } else if(user_c.template==='bazaar'){
      return <RegisterBazaar {...props}/>
    } else {
      return <RegisterCasflo {...props} />
    }
  }
}

class RegisterCasflo extends React.Component{
  render(){
    const props=this.props
    return(
      <>
      <Grid fluid className="main_block public_box">
        <Row>
          <Col xs={12} md={1}></Col>
          <Col xs={12}md={3}>
            <Image src={logo2} className="public_logo"/>
            <div className="register_Welcome">
              {this.props.trsl['main_string']}
            </div>
            <div className='blanktop_15'></div>
          </Col>
          <Col xs={12}md={1}></Col>
          <Col xs={12}md={6} className="public_use">
            {this.props.change_languages}
            {this.props.register_form}
            <Row className='blanktop_15'>
              <Col xs={12}>
                <h5>{this.props.trsl['alternatives_to_register']}</h5>
              </Col>
              <Col xs={12} md={4} className='blanktop_05'>
                {this.props.go_login}
              </Col>
              <Col xs={12} md={4} className='blanktop_05'>
                {this.props.go_password}           
              </Col>
              <Col xs={12} md={4} className='blanktop_05'>
                {this.props.go_activation}        
              </Col>
            </Row>
          </Col>
        </Row>
      </Grid>
      </>
    );
  }
}             
              
              

function RegisterForm(props){
  const item_holder = countries[props.user_c.locale]
  let item_distr
  if(item_holder !==undefined){
    item_distr = Object.entries(item_holder).map(([key,value]) =>{
      return(
        {value:key, option_label:value}
      )
    })
  }

  const country_holder=(input)=>{
    let starter="PT"
    if(input===""){
    return(starter)
    } else{
      return(input)
    }
  }
  const country_selection=country_holder(props.country)


  const term_options=[
    {value:false,option_label:props.trsl["no"],name:"terms"},
    {value:true,option_label:props.trsl["yes"], name:"terms"}
  ]

  const communication_options=[
    {value:false,option_label:props.trsl["no"],name:"communication"},
    {value:true,option_label:props.trsl["yes"], name:"communication"}
  ]

  return(
    <Row className='blanktop_15'>
      <Col xs={12}>
        <h1>{props.trsl['title']}</h1>
      </Col>
      <SingleInputForm xs={12} md={6} label={props.trsl['enter_name']} name="username" value={props.username} handleEditItemChange={props.handleChange}/>
      <SingleInputEmailForm xs={12} md={6} label={props.trsl['enter_email']} name="email" value={props.email} handleEditItemChange={props.handleChange}/>
      <SingleInputPasswordForm xs={12} md={6} label={props.trsl['password']} name="type_password" value={props.type_password} handleinputeditChange={props.handleChange}/>
      <SingleInputPasswordForm xs={12} md={6} label={props.trsl['re-password']} name="retype_password" value={props.retype_password} handleinputeditChange={props.handleChange}/>
      <SingleDateForm xs={12} md={6} label={props.trsl['birth']} name="birth" value={props.birth} onChange={props.handleChange} />
      <Dropdown xs={12} md={6} label={props.trsl['country']} name="country" value={country_selection} options={item_distr} onChange={props.handleChange}/>
      <Col xs={12}>
        <a href="https://casflo-app.com/terms-of-service/" target="blank">{props.trsl['terms']}</a>
      </Col>
      <ToogleButtons xs={12} md={12} label={props.trsl['terms_accept']} toogle_data={term_options} selected={props.terms}  handleToogleButton={props.handleCheck}/>
      <ToogleButtons xs={12} md={12} label={props.trsl['communication']} name="communication" toogle_data={communication_options} selected={props.communication} handleToogleButton={props.handleCheck}/>
      <Col xs={12}>
        <RegularButton label={props.trsl['button_register']} bk="bk_primary" onClick={props.handleSubmit}/>
      </Col>
    </Row>
  )
}

function ChangeLanguages(props){
  const options=[
    {value:'en',option_label:"English"},
    {value:'pt',option_label:"Português"}
  ]
  return(
    <Row>
      <ToogleButtons xs={12} md={12} label={props.trsl['language']} toogle_data={options} selected={props.locale}  handleToogleButton={props.handleLanguage}/>
    </Row>
  )
}

function GoLogin(props){
  return(
    <RegularLinkButton bk="bk_lightaccent" href="/login"  label={props.trsl['button_login']}/>
  )
}

function GoActivationRequest(props){
  return(
    <RegularLinkButton bk="bk_lightaccent"  href="/activationrequest"label={props.trsl['button_recover_activation']}/> 
  )
}

function GoPassword(props){
  return(
    <RegularLinkButton bk="bk_lightaccent"  href="/password"label={props.trsl['button_recover_password']}/>
  )
}

export default Register;