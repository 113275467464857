import { add, multiply, pow} from 'mathjs';

function decideTypeOfDiscount(c, settings){
  const wacc=c._coc_wacc
  const simple_cost=c.coc_simple_discount_rate
  let discount=wacc
  if(settings.financial_type=="simple"){
    discount=simple_cost
  }
  return discount
}

function calYearlyDiscount(c,years,factor_type, settings){
  const discount=decideTypeOfDiscount(c,settings)
  let bug=[]
  if(factor_type===false){
    for(var i=0;i<years;i++){
      bug[i]=(1/(pow((1+(discount/100)),(i))))
    }
  } else{
    for(var i=0;i<years;i++){
      bug[i]=(1/(pow((1+(discount/100)),(i+1))))
    }
  }
  return bug
}

function calYearlyEquityDiscount(c,years,factor_type){
  let bug=[]
  if(factor_type===false){
    for(var i=0;i<years;i++){
      bug[i]=(1/(pow((1+(c._coc_equity_cost/100)),(i))))
    }
  } else{
    for(var i=0;i<years;i++){
      bug[i]=(1/(pow((1+(c._coc_equity_cost/100)),(i+1))))
    }
  }
  return bug
}

function calPerpetuityCalculator(c, settings){
  const discount=decideTypeOfDiscount(c, settings)
  return ((discount-c.coc_residual_value_growth)/100)
}

function calPerpetuityEquityCalculator(c){
  return ((c._coc_equity_cost-c.coc_residual_value_growth)/100)
}

function calPerpetuityDiscount(c, years, settings){
  let bug
  const discount=decideTypeOfDiscount(c, settings)
  if(c.coc_residual_discount_type===false){
    bug=(1/(pow((1+(discount/100)),(years-1))))
  } else{
    bug=(1/(pow((1+(discount/100)),(years))))
  }
  return bug
}

function calPerpetuityEquityDiscount(c, years){
  let bug
  if(c.coc_residual_discount_type===false){
    bug=(1/(pow((1+(c._coc_equity_cost/100)),(years-1))))
  } else{
    bug=(1/(pow((1+(c._coc_equity_cost/100)),(years))))
  }
  return bug
}

 const calculateCoc=(c, settings)=>{
   const n_years=settings.number_of_years
  c._coc_equity_cost=add(c.coc_risk_free,multiply(c.coc_beta, c.coc_risk_premium))

  c._coc_debt_cost=multiply(c.coc_average_debt_interest,add(1,(-settings.irc)/100))

  c._coc_debt_weight=add(100,-c.coc_equity_weight)

  c._coc_wacc=add(multiply(c.coc_equity_weight/100, c._coc_equity_cost),multiply(c._coc_debt_weight/100,c._coc_debt_cost))

  //WACC
  c._coc_yearly_discount_factor=calYearlyDiscount(c, n_years,c.coc_residual_discount_type, settings)
  
  c._coc_perpetuity_calculator=calPerpetuityCalculator(c, settings)

  c._coc_perpetuity_discount_factor=calPerpetuityDiscount(c, n_years, settings)

  //Equity
  if(settings.financial_type != "simple"){
    c._coc_yearly_equity_discount_factor=calYearlyEquityDiscount(c, n_years,c.coc_residual_discount_type)
    c._coc_perpetuity_equity_calculator=calPerpetuityEquityCalculator(c)
    c._coc_perpetuity_equity_discount_factor=calPerpetuityEquityDiscount(c, n_years)
  }


  return c
}
export default calculateCoc;