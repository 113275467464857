import React from 'react';
import {Table} from 'react-bootstrap';
import { Row, Col} from 'Support/grid'
import UserContext from 'CONFIG/auth';
import { ToogleButtons } from 'Support/toogle_buttons';

const TableContext= React.createContext();

class DashboardTable extends React.Component {
  static contextType = UserContext
  constructor(props) {
    super(props);
    this.state = {
      active_table:"is"
    }
  }
  handleChangeTable=(event)=>{
    this.setState({
      active_table:event.target.value
    })
  }

  render(){
    const props={
      re:this.props.data.results,
      trsl:this.props.trsl,
      periods:this.props.periods
    }

    const toogle_data=[
      {value:'is', option_label:this.props.trsl['income_st']},
      {value:'is_acc', option_label:this.props.trsl['income_st_acc']},
      {value:'cs', option_label:this.props.trsl['cash_st']},
      {value:'cs_acc', option_label:this.props.trsl['cash_st_acc']}
    ]

    const forecasts_table={
      is:<IncomeStatement {...props}/>,
      is_acc:<IncomeStatementAccumulated {...props}/>,
      cs:<CashStatement {...props}/>,
      cs_acc:<CashStatementAccumulated {...props}/>
    }

    return(
      <TableContext.Provider value={{decimals:this.props.budget_settings.decimal_on_reports}}>
        <h4>{this.props.trsl['reports'] + " - " + this.props.budget_settings.currency}</h4>
        <Row>
        <ToogleButtons xs={12} toogle_data={toogle_data} label={this.props.trsl['select_report']} selected={this.state.active_table} handleToogleButton={this.handleChangeTable}/>
          <Col xs={12} className='blanktop_10'>
            {forecasts_table[this.state.active_table]}
          </Col>
        </Row>
      </TableContext.Provider>
    );
  }
}

class IncomeStatement extends React.Component{
  render(){
    return(
      <Table size="sm">
          <TableHeader periods={this.props.periods} trsl={this.props.trsl}/>
          <TableRow  firstcell={this.props.trsl['revenues']} values={this.props.re._inc_st.revenue} />
          <TableRow  firstcell={this.props.trsl['subsidies']} values={this.props.re._inc_st.operational_grants}/>
          <TableRow  firstcell={this.props.trsl['cogs']} values={this.props.re._inc_st.cogs}/>
          <TableRow  firstcell={this.props.trsl['operating_exp']} values={this.props.re._inc_st.operating_expenses}/>
          <TableRow  firstcell={this.props.trsl['employees']} values={this.props.re._inc_st.hr}/>
          <TableRow  firstcell={this.props.trsl['other_revenues']} values={this.props.re._inc_st.other_gains}/>
          <TableRow  firstcell={this.props.trsl['other_costs']} values={this.props.re._inc_st.other_costs}/>
          <TableRow  firstcell={this.props.trsl['ebitda']} class="table_row_special_1" values={this.props.re._inc_st.ebitda}/>
          <TableRow  firstcell={this.props.trsl['depreciations']} values={this.props.re._inc_st.depreciations}/>
          <TableRow  firstcell={this.props.trsl['ebit']} class="table_row_special_1" values={this.props.re._inc_st.ebit}/>
          <TableRow  firstcell={this.props.trsl['financing_costs']}  values={this.props.re._inc_st.interest_cost}/>
          <TableRow  firstcell={this.props.trsl['ebt']} class="table_row_special_1" values={this.props.re._inc_st.ebt}/>
          <TableRow  firstcell={this.props.trsl['corporate_tax']} values={this.props.re._inc_st.corporate_tax}/>
          <TableRow  firstcell={this.props.trsl['net_income']} class="table_row_special_1" values={this.props.re._inc_st.net_profit}/>
      </Table>
    );
  }
}

class IncomeStatementAccumulated extends React.Component{
  render(){
    return(
      <Table size="sm">
          <TableHeader periods={this.props.periods} trsl={this.props.trsl}/>
          <TableRow  firstcell={this.props.trsl['revenues']} values={this.props.re._inc_st_acc.revenue} />
          <TableRow  firstcell={this.props.trsl['subsidies']} values={this.props.re._inc_st_acc.operational_grants}/>
          <TableRow  firstcell={this.props.trsl['cogs']} values={this.props.re._inc_st_acc.cogs}/>
          <TableRow  firstcell={this.props.trsl['operating_exp']} values={this.props.re._inc_st_acc.operating_expenses}/>
          <TableRow  firstcell={this.props.trsl['employees']} values={this.props.re._inc_st_acc.hr}/>
          <TableRow  firstcell={this.props.trsl['other_revenues']} values={this.props.re._inc_st_acc.other_gains}/>
          <TableRow  firstcell={this.props.trsl['other_costs']} values={this.props.re._inc_st_acc.other_costs}/>
          <TableRow  firstcell={this.props.trsl['ebitda']} class="table_row_special_1" values={this.props.re._inc_st_acc.ebitda}/>
          <TableRow  firstcell={this.props.trsl['depreciations']} values={this.props.re._inc_st_acc.depreciations}/>
          <TableRow  firstcell={this.props.trsl['ebit']} class="table_row_special_1" values={this.props.re._inc_st_acc.ebit}/>
          <TableRow  firstcell={this.props.trsl['financing_costs']}  values={this.props.re._inc_st_acc.interest_cost}/>
          <TableRow  firstcell={this.props.trsl['ebt']} class="table_row_special_1" values={this.props.re._inc_st_acc.ebt}/>
          <TableRow  firstcell={this.props.trsl['corporate_tax']} values={this.props.re._inc_st_acc.corporate_tax}/>
          <TableRow  firstcell={this.props.trsl['net_income']} class="table_row_special_1" values={this.props.re._inc_st_acc.net_profit}/>
      </Table>
    );
  }
}

class CashStatement extends React.Component{
  render(){
    return(
      <Table size="sm" >
        <TableHeader periods={this.props.periods} trsl={this.props.trsl}/>
        <TableRow  firstcell={this.props.trsl['received_clients']} values={this.props.re.cf.received_from_clients}/>
        <TableRow  firstcell={this.props.trsl['paid_suppliers']} values={this.props.re.cf.paid_to_suppliers}/>
        <TableRow  firstcell={this.props.trsl['paid_hr']} values={this.props.re.cf.payment_to_hr}/>
        <TableRow  firstcell={this.props.trsl['other_pay_rec']} values={this.props.re.cf.other_payments_and_receivables}/>
        <TableRow  firstcell={this.props.trsl['operational_cf']} class="table_row_special_1" values={this.props.re.cf.operational_cash_flow}/>
        <TableRow  firstcell={this.props.trsl['investment_cf']} class="table_row_special_1" values={this.props.re.cf.investment_in_assets}/>
        <TableRow  firstcell={this.props.trsl['debt_increase']} values={this.props.re.cf.debt_increase}/>
        <TableRow  firstcell={this.props.trsl['financing_costs']} values={this.props.re.cf.interest_cost}/>
        <TableRow  firstcell={this.props.trsl['debt_decrease']} values={this.props.re.cf.debt_decrease}/>
        <TableRow  firstcell={this.props.trsl['financing_cashflows']} class="table_row_special_1" values={this.props.re.cf.financing_cashflows}/>
        <TableRow  firstcell={this.props.trsl['cf_variation']} class="table_row_special_2" values={this.props.re.cf.cash_flow_variation}/>
      </Table>
    );
  }
}

class CashStatementAccumulated extends React.Component{
  render(){
    return(
      <Table size="sm" >
        <TableHeader periods={this.props.periods} trsl={this.props.trsl}/>
        <TableRow  firstcell={this.props.trsl['received_clients']} values={this.props.re.cf_acc.received_from_clients}/>
        <TableRow  firstcell={this.props.trsl['paid_suppliers']} values={this.props.re.cf_acc.paid_to_suppliers}/>
        <TableRow  firstcell={this.props.trsl['paid_hr']} values={this.props.re.cf_acc.payment_to_hr}/>
        <TableRow  firstcell={this.props.trsl['other_pay_rec']} values={this.props.re.cf_acc.other_payments_and_receivables}/>
        <TableRow  firstcell={this.props.trsl['operational_cf']} class="table_row_special_1" values={this.props.re.cf_acc.operational_cash_flow}/>
        <TableRow  firstcell={this.props.trsl['investment_cf']} class="table_row_special_1" values={this.props.re.cf_acc.investment_in_assets}/>
        <TableRow  firstcell={this.props.trsl['debt_increase']} values={this.props.re.cf_acc.debt_increase}/>
        <TableRow  firstcell={this.props.trsl['financing_costs']} values={this.props.re.cf_acc.interest_cost}/>
        <TableRow  firstcell={this.props.trsl['debt_decrease']} values={this.props.re.cf_acc.debt_decrease}/>
        <TableRow  firstcell={this.props.trsl['financing_cashflows']} class="table_row_special_1" values={this.props.re.cf_acc.financing_cashflows}/>
        <TableRow  firstcell={this.props.trsl['cf_variation']} class="table_row_special_2" values={this.props.re.cf_acc.cash_flow_variation}/>
      </Table>
    );
  }
}


class TableHeader extends React.Component{
  render(){
  const item = this.props.periods
  const len = ((.8)/item.length)
  const item_distr = item.map((key)=>
    <TableHeaderCell value={key} len={len}/>
  );
  return(
    <thead>
      <TableFirstHeaderCell value={this.props.trsl['h_year']}/>
      {item_distr}
    </thead>
  );
  }
}

class TableFirstHeaderCell extends React.Component{
  render(){
    return(
      <th style={{width:'20%'}}>
        {this.props.value}
      </th>
    );
  }
}

class TableHeaderCell extends React.Component{
  render(){
    return(
      <th className="table_header_cell"style={{width:this.props.len}}>
        {this.props.value}
      </th>
    );
  }
}

class TableRow extends React.Component{
  render(){
    const item_holder= this.props.values
    const  item_distr = item_holder.map((value)=>
      <TableCell value={value} />
    )  
    return(
      <tr className={this.props.class}>
        <TableFirstCell value={this.props.firstcell}/>
        {item_distr}
      </tr>
    );
  }
}

class TableFirstCell extends React.Component{
  render(){
    return(
    <td>
      {this.props.value}
    </td>
    );
  }
}

function TableCell (props){
  return(
    <UserContext.Consumer>
      {user => (
        <TableContext.Consumer>
          {ind => (
            <td className="table_simple_cell">
              {user.i18n.deciF(props.value,ind.decimals)}
            </td>
          )}
        </TableContext.Consumer>
      )}
    </UserContext.Consumer>
  );
}

class TableRowHistoric extends React.Component{
  render(){
    const item_holder= this.props.values
    const  item_distr = item_holder.map((value,k)=>{
      if(k===0){
        return <TableCellHistoric value={value} />
      } else {
        return <TableCell value={value} />    
      }
    })
    return(
      <tr className={this.props.class}>
        <TableFirstCell value={this.props.firstcell}/>
        {item_distr}
      </tr>
    );
  }
}

function TableCellHistoric (props){
  return(
    <UserContext.Consumer>
      {user => (
        <TableContext.Consumer>
          {ind => (
            <td className="table_with_historic">
              {user.i18n.currF(props.value,ind.currency,ind.decimals)}
            </td>
          )}
        </TableContext.Consumer>
      )}
    </UserContext.Consumer>
  );
}

class TableRowSpecial extends React.Component{
  render(){
    const item_holder= this.props.values
    const item_distr = item_holder.map((key)=>
      <TableCellSpecial value={key} />
    )
    return(
      <tr className={this.props.class}>
        <TableFirstCell value={this.props.firstcell}/>
        {item_distr}
      </tr>
    );
  }
}

function TableCellSpecial (props){
  return(
    <td className="table_simple_cell">
      {props.value}
    </td>
  );
}

export default DashboardTable;