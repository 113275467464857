import React from 'react';
import { Row, Col } from 'Support/grid'

import ApiConnect from 'CONFIG/axios';
import UserContext from 'CONFIG/auth';

import Loader from 'Support/loader';
import {FormEdit, PeriodHeader, SingleInputForm,SingleInputLargeForm, Selectacble} from 'Support/inputforms'
import { RegularButtonFullHeight } from 'Support/listbuttons';
import { AssumptionHeaderSave } from '../budget_items';

class VersionMain extends React.Component {
  static contextType = UserContext

  constructor(props) {
    super(props);
    this.state = {
      data: "",
      loaded:false
    }
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount= async()=>{
    const request={
      budget_id:this.props.budget_id,
      version_id:this.props.current_version.id,
    }
    try {
      const response = await ApiConnect.post('/api/budget_project/version/settings_get', request)
        this.setState({
          data:response.data,
          loaded:true
        })
    } catch(error) {
        alert(error);
    }
  }

  handleEditChange=async(id,item)=>{
    let go = this.state.data;
    go.settings[id]=item;
    this.setState({data:go})
  }

  handleResetSeasonality=()=>{
    let go = this.state.data;
    go.settings.seasonality=[1/12*100,1/12*100,1/12*100,1/12*100,1/12*100,1/12*100,1/12*100,1/12*100,1/12*100,1/12*100,1/12*100,1/12*100];
    this.setState({data:go})
  }

  handleRelevelSeasonality=()=>{
    let go = this.state.data;
    let total=this.state.data.settings.seasonality.reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    for (var i=0;i<12;i++){
      go.settings.seasonality[i]=parseFloat(go.settings.seasonality[i]/total)*100
    }
    total=this.state.data.settings.seasonality.reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    if(Number.isNaN(total)){
      go.settings.seasonality=[1/12*100,1/12*100,1/12*100,1/12*100,1/12*100,1/12*100,1/12*100,1/12*100,1/12*100,1/12*100,1/12*100,1/12*100];
    }
    this.setState({data:go})
  }

  handleNameChange(event){
    const value = event.target.value;
    const name = event.target.name;
    let go = this.state.data;
    go[name]=value;
    this.setState({data:go })
  }

  async handleSave(){
    const request={
      budget_id:this.props.budget_id,
      version_id:this.props.current_version.id,
      content:this.state.data
    }
    const total=this.state.data.settings.seasonality.reduce((a, b) => parseFloat(a) + parseFloat(b), 0)

    if(total.toFixed(2)==="100.00"){
      try{
        await ApiConnect.post('/api/budget_project/version/settings_set', request)
      } catch(error) {
          alert(error);
      }
    } else{
      alert('Seasonality wrong')
    }
  }

  render() {
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_BUDGET.INSIDEPROJECT.Individual_edits')

    let p_base=['m1','m2','m3','m4','m5','m6','m7','m8','m9','m10','m11','m12']
    let periods=[]
    for(var i=0; i < p_base.length ;i++){
      periods[i]=trsl[p_base[i]]
    }

    const props ={
      trsl:trsl,
      periods:periods,
      handleScreenNavigation:this.props.handleScreenNavigation,
      handleEditChange:this.handleEditChange,
      handleSave:this.handleSave,
      handleNameChange:this.handleNameChange,
      handleResetSeasonality:this.handleResetSeasonality,
      handleRelevelSeasonality:this.handleRelevelSeasonality,
      data:this.state.data,
      user_permission:this.props.user_permission
  }

    function torender(data, i){
      return data[i];
    }  
    const pages ={
      true: 
      <ToDisplay {...props}/>,
      
      false: 
      <Loader loader_message="vers_edit"/>
    }
    var i = this.state.loaded
    const renderizing = torender(pages,i)

    return(
      <Row>
        {renderizing}
      </Row>
    )
  }
}

class ToDisplay extends React.Component {
  render(){
    const props=this.props
    return(
      <Col xs={12}>
        <GlobalHeader {...props}/>
        <Seasonality {...props}/>
      </Col>
    );
  }
}

class GlobalHeader extends React.Component{
  render(){
    const props=this.props

    return(
      <div>
        <AssumptionHeaderSave {...props} />
        <Row className="feasibility_edit_globalheader_row">
          <SingleInputForm xs={12} md={6} name='name' value={this.props.data.name} label= {this.props.trsl['vs_name']} handleEditItemChange={this.props.handleNameChange}/>
          <SingleInputForm xs={12} md={6} name='year' value={this.props.data.year} label= {this.props.trsl['vs_year']} handleEditItemChange={this.props.handleNameChange}/>
          <SingleInputLargeForm xs ={12} md={12} name='description' value={this.props.data.description} label= {this.props.trsl['vs_description']} handleEditItemChange={this.props.handleNameChange}/>
        </Row>
      </div>
    );
  }
}

class Seasonality extends React.Component{
  render(){
    const props=this.props
    return(
      <Row> 
        <Col xs={12} md={6}>
          <h6>{this.props.trsl['vs_seasonality']}</h6>
        </Col>       
        <Col xs={12} >
          <PeriodHeader label={this.props.trsl['period']} periods={this.props.periods}/>
        </Col>
        <Col xs={12} className="feasibility_list">
          <SeasonalitySetter {...props}/>
        </Col>
        <Col xs={12} md={6}>
          <SeasonalityWarning {...props}/>
        </Col>
        <Col xs={6} md={3} className='blanktop_075'>
          <RegularButtonFullHeight label={this.props.trsl['vs_seasonality_level']} bk='bk_primary' onClick={this.props.handleRelevelSeasonality}/>
        </Col>
        <Col xs={6} md={3} className='blanktop_075'>
          <RegularButtonFullHeight label={this.props.trsl['vs_seasonality_reset']} bk='bk_primary' onClick={this.props.handleResetSeasonality}/>
        </Col>
      </Row>
    )
  }
}


class SeasonalitySetter extends React.Component{
  render(){ 
    return(
      <FormEdit
      use_historic={false}
      item_holder={this.props.data.settings.seasonality}
      id="seasonality"
      handleForm={this.props.handleEditChange}
      label={this.props.trsl['vs_seasonality']}
      unit="%"
      />
    );
  }
}

function SeasonalityWarning(props){
  let level= props.data.settings.seasonality.reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
  let bk="bk_success"
  if(parseFloat(level).toFixed(2)!=100){ bk="bk_danger"}
  return(
    <div className={`warning_div_outside ${bk}`}>
      <div className={`warning_div_inside`}>
        <h6>{props.trsl['vs_seasonality_total']}</h6>
        {`${parseFloat(level).toFixed(2)}%`}
      </div>
    </div>
  )
}

class SelectCorporateTaxRate extends React.Component{
  render(){
    const options=[
      {value:true,option_label:this.props.trsl['vs_general_irc']},
      {value:false,option_label:this.props.trsl['vs_edited_irc']}
    ]

    const handleChange = (val) => {
      const event={
        target:{
          value:val,
          name:"use_general_irc"
        }
      }
      this.props.handleIRCSelection(event)}

    const valueChecker=(input)=>{
      if(input===undefined || input===true){
        return true
      } else return false
    }

    if(this.props.feasibility_settings.use_historic!==true){
      return(
        <>    
          <Selectacble
          xs={12} md={12} 
          label={this.props.trsl['vs_irc']}
          options={options}
          selected={valueChecker(this.props.data.version_settings.use_general_irc)}
          handleChange={handleChange} />
        </>
      )
    } else return ''
  }
}

export default VersionMain;