import React from 'react';

import ApiConnect from 'CONFIG/axios';

import HrCreator from 'CONFIG/CREATORS/hr_creator';
import {SinglesFeasibilityListItems} from '../budget_items';
import {calTotalsForLists} from 'CALCULATIONS/cal_totals'

import Loader from 'Support/loader';

class HrList extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      data:[],
      loaded:false,
      toast:{
        show:false,
        toast_header:"",
        toast_body:"",
      },
      totals:[]
    }
    this.handleAdd=this.handleAdd.bind(this)
    this.updateListData=this.updateListData.bind(this)
    this.handleDeleteItem=this.handleDeleteItem.bind(this)
    this.handleDuplicateItem=this.handleDuplicateItem.bind(this)
  }

    componentDidMount() {
        this.updateListData()
    }

  updateListData=async()=>{
    const request={
      budget_id:this.props.budget_id,
      version_id:this.props.current_version.id,
      item_type:"human_resources"
    }
    try{
      const response = await ApiConnect.post('/api/budget_project/multitem/all/get', request)
      this.setState({ 
        data:response.data,
        loaded:true,
        totals:calTotalsForLists(response.data,this.props.budget_settings)
      });
    }
    catch (error) {
      alert(error);
    }
  }

  handleAdd =async(item)=>{
    let months=12
    const request={
      budget_id:this.props.budget_id,
      version_id:this.props.current_version.id,
      item_type:"human_resources",
      content: new HrCreator(
        item,
        months,
        this.props.budget_settings.employees,
        this.props.budget_settings.corporate_social_security
      )
    }
    try{
      const response= await ApiConnect.post(`/api/budget_project/multitem/item/create`, request)
      const event={
        target:{
          name:'edit',
          value:response.data
        }
      }
      this.props.handleScreenNavigation(event)
    } catch(error) {
      alert(error);
    }
  }

  handleDuplicateItem =async(event)=>{
    const request={
      budget_id:this.props.budget_id,
      version_id:this.props.current_version.id,
      item_type:"human_resources",
      item_id:event.target.value
    }
    try{
      const response= await ApiConnect.post(`/api/budget_project/multitem/item/duplicate`, request)
      this.updateListData()
      this.AlertFunctions.setAlert(response.data)
    } catch(error) {
      alert(error);
      }
  }

  handleDeleteItem =async(event)=>{
    const request={
      budget_id:this.props.budget_id,
      version_id:this.props.current_version.id,
      item_type:"human_resources",
      item_id:event.target.value
    }
    try{
      const response= await ApiConnect.post(`/api/budget_project/multitem/item/delete`, request)
      this.updateListData()
      this.AlertFunctions.setAlert(response.data)
    } catch(error) {
      alert(error);
      }
  }

  handleUpLevel= async(event)=>{
    const request={
      budget_id:this.props.budget_id,
      version_id:this.props.current_version.id,
      item_type:"human_resources",
      item_id:event.target.value
    }
    try{
    const response= await ApiConnect.post(`/api/budget_project/multitem/item/uplevel`, request)
      this.updateListData()
    } catch(error) {
      alert(error);
    }
  }

  handleDownLevel= async(event)=>{
    const request={
      budget_id:this.props.budget_id,
      version_id:this.props.current_version.id,
      item_type:"human_resources",
      item_id:event.target.value
    }
    try{
    const response= await ApiConnect.post(`/api/budget_project/multitem/item/downlevel`, request)
      this.updateListData()
    } catch(error) {
      alert(error);
    }
  }

  //TOAST ALERT
  AlertFunctions={
    setAlert:(header,body)=>{
      this.setState({
        toast:{
          show:true,
          header: header,
          body:body
        }
      })
    },
    unsetalert:()=>{
      this.setState({
        toast:{
          show:false
        }
      })
    }
  }
  

  render(){
    function torender(data, i){
      return data[i];
    }  
    const pages ={
    true: 
    <SinglesFeasibilityListItems 
    data={this.state.data} 
    totals={this.state.totals}
    budget_settings={this.props.budget_settings}
    user_permission={this.props.user_permission}
    handleAdd={this.handleAdd} 
    handleScreenNavigation={this.props.handleScreenNavigation} 
    handleItem={this.handleDeleteItem}
    handleDeleteItem={this.handleDeleteItem}
    handleDuplicateItem={this.handleDuplicateItem}
    handleEditItem={this.props.handleDuplicateItem}
    handleUpLevel={this.handleUpLevel}
    handleDownLevel={this.handleDownLevel}
    toast={this.state.toast}
    AlertFunctions={this.AlertFunctions}
    title='hr_title'
    add_item='add_hr'
    />,
    false: <Loader loader_message="hr_list"
    />
    }
    var i = this.state.loaded
    const renderizing = torender(pages,i)

    return(
        <div>
            {renderizing}
        </div>
    )
  }
}

export default HrList;