import React, {useContext} from 'react'
import {Button} from 'react-bootstrap'
import {Grid, Row, Col} from 'Support/grid'

import 'TEMPLATES/BAZAAR/CSS/main_screen.css'
import UserContext from 'CONFIG/auth'

function MainScreenBazaarRender(props){
  const user_c=useContext(UserContext)
  function showPartnershipButton(props,input){
    if(input==='SuperAdministrator' || input==='Administrator' || input==='Partner'){
      return(
        <Col xs={6} md={3}>
          <Button block className="intro_button" name="/app/partners" value={0} onClick={props.handlePrincipalButtonRouting}>
            {props.trsl['partnership_button']}
          </Button>
        </Col>
      )
    }
  }
  const parternship_button=showPartnershipButton(props,user_c.user_type)

  return(
    <>
    <div className="placer2 brand_background">
      <Grid fluid>

        <Row center='xs' middle="xs">
          <Col xs={12} md={3}></Col>
          <Col xs={12} md={6} className='brand_social_message c_darktext'>
            <h4>{user_c.i18n.t('TEMPLATES.Bazaar.message')}</h4>
          </Col>
          <Col xs={12} md={3}></Col>
            <Col xs={6} md={3}>
              <Button block className="intro_button"  name="/app/business_plans/" value={0} onClick={props.handlePrincipalButtonRouting}>
                {props.trsl['bp_button']}
              </Button>
            </Col>
            <Col xs={6} md={3}>
              <Button block className="intro_button" name="/app/subscriptions" value={0} onClick={props.handlePrincipalButtonRouting}>
                {props.trsl['subscriptions_button']}
              </Button>
            </Col>
            <Col xs={6} md={3}>
              <Button block className="intro_button" name="templates" value={0} onClick={props.handlePrincipalButtonRouting}>
                {props.trsl['templates']}
              </Button>
            </Col>
            <Col xs={6} md={3}>
              <Button block className="intro_button" name="/app/budget_project" value={0} onClick={props.handlePrincipalButtonRouting}>
                {props.trsl['budget_button']}
              </Button>
            </Col>
            {parternship_button}
            <Col xs={6} md={3}>
              <Button block className="intro_button" name="/app/helpdesk" value={0} onClick={props.handlePrincipalButtonRouting}>
                {props.trsl['helpdesk_button']}
              </Button>
            </Col>
          </Row>
        </Grid>
      </div>
    </>
  )
}


export {
  MainScreenBazaarRender,
}