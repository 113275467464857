import React from 'react';
import {Col, Row } from 'Support/grid';

import UserContext from 'CONFIG/auth';

import {EditVersionButton, DeleteVersionButton, DuplicateVersionButton, ViewButtonForVersion, RegularButton} from 'Support/listbuttons';
import AddVersion from './project_version_add';
import {SubscriptioninProject} from 'PRIVATE/ACCOUNT/subscription_in_project';

import Loader from 'Support/loader';

class ProjectEdit extends React.Component{
  static contextType = UserContext

  render(){
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_PROJECT.PROJECT_MAIN.Project_edit')
    const props =this.props

    function torender(data, i){
      return data[i];
      }  
    const pages ={
    true: 
    <ToDisplay {...props} trsl={trsl}/>,
    false: <LoaderWraper/>
    }
    var i = this.props.loaded_business_plan
    const renderizing = torender(pages,i)

    return(
      <div>
        {renderizing}
      </div>
    )
  }
}

function LoaderWraper (props){
  return(
    <>
      <Loader loader_message="projects_edit"/>
    </>
  )
}

class ToDisplay extends React.Component{
  render(){
    const props=this.props
    const renderFeasibility=(input)=>{
      if(input==="business_plan" || input==="feasibility_plan"){
        return <FeasibilityEdit {...props}/>
      }
    }
    const renderizing_feasibility=renderFeasibility(this.props.project_data.project_type)

    const renderCanvas=(input)=>{
      if(input==="business_plan" || input==="canvas_plan"){
        return <CanvasEdit {...props}  handleGoToCanva={this.props.handleGoToCanva}/>
      }
    }
    const renderizing_canvas=renderCanvas(this.props.project_data.project_type)

    return(
      <Row noGutters className="project_edit_placer">
        <Col xs={12} md={3} className='bk_primary'>
          <GeneralSettings {...props} />
        </Col>
        <Col xs={12} md={9} className=''>
          {renderizing_feasibility}
          {renderizing_canvas}
          <ProjectExport {...props}/>
          <div className='blanktop_10'></div>
        </Col>
      </Row>
    );
  }
}

class FeasibilityEdit extends React.Component{
  render(){
    const version_number=this.props.versions_list.length

    const props={
      trsl:this.props.trsl,
      handleDeleteVersion:this.props.handleDeleteVersion,
      handleDuplicateVersion:this.props.handleDuplicateVersion,
      handleEditItem:this.props.handleEditItem,
      versions_list:this.props.versions_list,
      project_id:this.props.project_id,
      project_data:this.props.project_data,
      feasibility_settings:this.props.feasibility_settings,
      handleAdd:this.props.handleAdd,
      permission:this.props.project_data.user_permission,
    }
    let disabled=true
    if(this.props.project_data.user_permission=="owner" || this.props.project_data.user_permission=="edit"){
      disabled= false
    }

    let showHistoricButton=''
    if((this.props.project_data.user_permission=="owner" || this.props.project_data.user_permission=="edit") && this.props.feasibility_settings.use_historic===true){
      showHistoricButton=(
        <> 
          <RegularButton label={this.props.trsl['manage_historic_button']} bk='bk_darkaccent' name="feasibility_historic" value={this.props.project_id} disabled={disabled} onClick={this.props.handleEditItem} />
        </>
      )
    }
    


    function showAddVersion(input,trsl,handleAddVersion,disabled){
      if(input!==true){
        return(
          <>
            <AddVersion trsl={trsl} handleAdd={handleAddVersion} permission={disabled}/>
          </>
        )
      }
    }


    return(
      <>
        <Row>
          <Col xs={12} md={12}>
            <h2>{this.props.trsl['feasibility_header']}</h2>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={8}>
            <FeasibilityVersions {...props} /> 
            {showAddVersion(this.props.feasibility_settings.use_historic, this.props.trsl,this.props.handleAddVersion, disabled)}
              {showHistoricButton}
          </Col>
          <Col xs={12} md={4} >
          <CenariosGauge trsl={this.props.trsl} version_number={version_number} version_max={this.props.feasibility_settings.feasibility_version_max}/>
          <RegularButton label={this.props.trsl['feasibility_dash']} bk='bk_darkaccent' onClick={this.props.handleEditItem} name="feasibilitydashboard" value={this.props.project_id} disabled={disabled} />
            </Col>
        </Row>
      </>
    )
  }
}

class FeasibilityVersions extends React.Component{
  static contextType = UserContext
  render(){
    let user_c= this.context
    const item_holder = this.props.versions_list
    let item_distr
    if(item_holder.length<1){
      item_distr=this.props.trsl['no_versions']
    } else{
      item_distr = item_holder.map((value) =>
      <>
        <Row className="list_item grid_middle">
          <Col xs={12} md={6}>
            {value.version_name}
          </Col>
          <Col xs={12} md={3}>
          {user_c.i18n.dateF(value.version_edited_date)}
          </Col>
          <Col xs={12} md={3}>
            <Row center="xs">   
              <EditVersionButton value={this.props.project_id} name="feasibility" permission={this.props.permission} handleEditItem={(e)=>{this.props.handleEditItem(e,value.version_id,value.version_name)}}/>
              <DeleteVersionButton value={value.version_id} permission={this.props.permission} handleDelete={this.props.handleDeleteVersion}/>
              <DuplicateVersionButton value={value.version_id} permission={this.props.permission} handleDuplicate={this.props.handleDuplicateVersion}/>
              <ViewButtonForVersion value={this.props.project_id} name="feasibility/version" permission={this.props.permission} onClick={(e)=>{this.props.handleEditItem(e,value.version_id,value.version_name)}} />
            </Row>
          </Col>
        </Row>
      </>
      );
    }

    return(
      <Col xs={12}md={12}className="general_list">
        <Row className="list_table_header_left">
          <Col xs={12}md={6} className="list_table_header_inside">
            {this.props.trsl['feasibility_versions']}
          </Col>
          <Col xs={12} md={3} className="list_table_header_inside">
            {this.props.trsl['last_edit_version']}
          </Col>
          <Col xs={12} md={3} className="list_table_header_inside">
          </Col>
        </Row>
        {item_distr}
      </Col>
    );
  }
}

class CanvasEdit extends React.Component{
  render(){

    let permission=true
    if(this.props.project_data.user_permission=="owner" || this.props.project_data.user_permission=="edit" || this.props.project_data.user_permission=="view"){
      permission= false
    }

    return(
      <>
        <h2 className='blanktop_15'>{this.props.trsl['canvas']}</h2>       
        <Row >
          <Col xs={12 } md={4} className='blanktop_05'>
            <RegularButton label={this.props.trsl['description']} bk='bk_darkaccent' name="business_plans/canva/description" value="description" onClick={this.props.handleGoToCanva} disabled={permission} />
          </Col>
          <Col xs={12} md={4} className='blanktop_05'>
            <RegularButton label={this.props.trsl['pestel']} bk='bk_darkaccent' name="business_plans/canva/pestel" value="pestel" onClick={this.props.handleGoToCanva} disabled={permission}/>
          </Col>
          <Col xs={12} md={4} className='blanktop_05'>
            <RegularButton label={this.props.trsl['swot']} bk='bk_darkaccent' name="business_plans/canva/swot" value="swot" onClick={this.props.handleGoToCanva} disabled={permission} />
          </Col>
          <Col xs={12 } md={4} className='blanktop_05'>
            <RegularButton label={this.props.trsl['bmc']} bk='bk_darkaccent' name="business_plans/canva/bmc" value="bmc" onClick={this.props.handleGoToCanva} disabled={permission} />
          </Col>
          <Col xs={12 } md={4} className='blanktop_05'>
            <RegularButton label={this.props.trsl['competitive']} bk='bk_darkaccent' name="business_plans/canva/competitive" value="competitive" onClick={this.props.handleGoToCanva} disabled={permission} />
          </Col>
        </Row>
      </>
    )
  }
}

class ImageUpload extends React.Component{
  state={
    selected_file:null
  }

  onFileChange = event => {
    if(event.target.files[0].size > 1048576) {
      alert("File too big")
    } else {
      this.setState({ selectedFile: event.target.files[0] });
    }
  };
  render(){
    return(
      <Col xs={12}>
        <input type="file" onChange={this.onFileChange} />
      </Col>
    )
  }
}


class ProjectExport extends React.Component{
  render(){
    return(
      <>
        <h2 className='blanktop_15'>{this.props.trsl['exporting']}</h2>          
        <Row className='blanktop_10'>
          <Col xs={12} md={4}>
            <RegularButton label={this.props.trsl['go_to_export']} bk='bk_darkaccent'onClick={this.props.handleGoToExporting} />
          </Col>
          <Col xs={12} md={4}>
          </Col>
        </Row>
      </>
    )
  }
}

class GeneralSettings extends React.Component{
  render(){
    const props=this.props

    let permission=true
    if(this.props.project_data.user_permission=="owner" ){
      permission= false
    }

    return(
      <div>
        <h1>{this.props.project_data.project_name}</h1>
        <div className='blanktop_15'></div>
          <RegularButton label={this.props.trsl['edit_settings']} bk='bk_primary' onClick={this.props.handleEditItem} name="settings" value={this.props.project_id} disabled={permission} />
          <div className="project_edit_settings_row">
            <h5>{this.props.trsl['subscription']}</h5>
            <SubscriptioninProject {...props}/>
          </div>
          <h5>{this.props.trsl['col_view']}</h5>
          <div className='blanktop_05'>
            {this.props.trsl['colaborators']} {this.props.project_data.project_colaborators}/{this.props.project_data.project_colaborator_max}
          </div>
          <div>
            {this.props.trsl['viewers']}{this.props.project_data.project_viewers}/{this.props.project_data.project_viewer_max}
          </div>
          <div className='blanktop_05'></div> 
          <RegularButton label={this.props.trsl['manage_col_view']} bk='bk_primary' onClick={this.props.handleEditItem} name="colaboratorsviewers" value={this.props.project_id} disabled={permission}/>
          <Col className='blanktop_10'></Col>
      </div>
    )
  }
}

function CenariosGauge(props){
  const progressCalculator=(v1,v2)=>{
    return ((v1/v2*100).toString()+ "%")
  }
  return(
    <div className='project_edit_gauge'>
      <div className='project_edit_gauge_t1'><h5>{props.trsl['version_max']}</h5></div>
      <div className='project_edit_gauge_t2'>{props.version_number}/{props.version_max}</div>
      <div className='project_edit_gauge_main'>
        <div className='project_edit_gauge_progress'style={{width: progressCalculator(props.version_number,props.version_max)}}></div>
      </div>
    </div>
  )
}

export default ProjectEdit;