import {sum} from 'mathjs';

const calculateOtherGainsCosts= (c)=>{
  const accumulate=(t)=>{
  var accumulated=[]
  var i
  for(i=0;i<t.other_gains_cost_value.length;i++){
  if(i===0){
  accumulated[i]=+t.other_gains_cost_value[i]
  } else {
  accumulated[i]=sum(accumulated[i-1],t.other_gains_cost_value[i])
  }
  }
  return accumulated
  }
  //accumulate each item operating expenses
  c._other_gains_cost_accumulated = accumulate(c)

 return c
};

export{  calculateOtherGainsCosts}