import React from 'react';
import { Row, Col } from 'Support/grid';
import ShareholdersEntry from './shareholders';
import ShareholdersList from './shareholders_list';

class ShareholdersMain extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      to_render: "list",
      active_item:0,
      multi_option:'issued_capital',
    }
    this.handleScreenNavigation=this.handleScreenNavigation.bind(this)
    this.changeScreenView=this.changeScreenView.bind(this)
    this.handleChangeType=this.handleChangeType.bind(this)
  }

  changeScreenView=(window, item)=>{
    this.setState({
      active_item: item,
      to_render: window
    })
  }

  handleScreenNavigation(event){
    const value = event.target.value
    const name = event.target.name
    this.changeScreenView(name, value)
  }

  handleChangeType=(event)=>{
    this.setState({ 
      multi_option:event.target.name
    });
  }

  render(){
    const shareholders_items=[
      {
        item_trsl:'sh_issued_capital',
        add_item_trsl:'add_sh_issued_capital',
        type:'issued_capital',
      },
      {
        item_trsl:'sh_other_equity_instruments',
        add_item_trsl:'add_sh_other_equity_instruments',
        type:'other_equity_instruments'
      },
      {
        item_trsl:'sh_shareholders_loan',
        add_item_trsl:'add_sh_shareholders_loan',
        type:'shareholders_loan'
      }
    ]

    const props={
      version_id:this.props.version_id,
      active_item:this.state.active_item,
      project_id:this.props.project_id,
      version_data:this.props.version_data,
      changeScreenView:this.changeScreenView,
      feasibility_settings:this.props.feasibility_settings,
      user_permission:this.props.user_permission,
      handleScreenNavigation:this.handleScreenNavigation,
      multi_option:this.state.multi_option,
      handleChangeType:this.handleChangeType,
      multitabs:shareholders_items,
      handleLists:this.props.handleLists
    }

    function torender(data, i){
      return data[i];
    }
    const pages ={
      edit: <ShareholdersEntry {...props}/>,
      list: <ShareholdersList {...props}/>
    }
    var i = this.state.to_render
    const renderizing = torender(pages,i)

    return(
      <Row>
      <Col xs={12}>
        {renderizing}
      </Col>
      </Row>
    );
  }
}

export default ShareholdersMain;