import React from 'react';
import ReactGA from 'react-ga4';

import {Row, Col} from 'Support/grid';

import ApiConnect from 'CONFIG/axios';
import Session from 'CONFIG/session';
import UserContext from '../CONFIG/auth';

import {LoginViseeon} from 'TEMPLATES/Viseeon/public_templates'
import {LoginBazaar} from 'TEMPLATES/BAZAAR/public_templates'
import {LoginCASFLO } from 'TEMPLATES/CASFLO/public_templates'
import { SingleInputEmailForm,SingleInputPasswordForm } from 'Support/inputforms';
import { ToogleButtons } from 'Support/toogle_buttons';

import './public.css';
import { Button, RegularLinkButton } from 'Support/listbuttons';


class Login extends React.Component{
  static contextType = UserContext

  constructor(props) {
    super(props);
    this.state = {
      locale:Session.getLocale(),
      email:"",
      pass:""
    }
  }
  

  handleChange=(event)=>{
    let go =this.state
    go[event.target.name]=event.target.value
    this.setState({go})
  }

  handeLogIn=async()=>{
    const request={
      email:this.state.email,
      password:this.state.pass
    }
    try{
      this.props.activateAlert('loading')
      const contexts = this.context
      const response= await ApiConnect.post('/user/login', request);
      contexts.setUser(response.headers.token,response.data.locale, response.data.user_type,response.data.user_avatar,response.data.template)
      ReactGA.event({category: "Login", action: "Successfull login"})
      window.location.href=('/app')
    } catch (error) {
      if(error.response !==undefined){
        ReactGA.event({category: "Login", action: "Unsucessfull"})
        this.props.activateAlert('error','login_err_h',error.response.data)
      } else {
        this.props.activateAlert('error','login_err_h','general_error')
        ReactGA.event({category: "Login", action: "Unsucessfull login"})
      }
    }
  }

  handleLanguage=(event)=>{
    const user_c=this.context
    user_c.setLocale(event.target.value) 
    this.setState({
      locale:event.target.value
    })
  }

  render(){
    let user_c= this.context
    const trsl=user_c.i18n.t('PUBLIC.login')

    const props={
      trsl:trsl,
      login:<GoLogin trsl={trsl} email={this.state.email} pass={this.state.pass} handleChange={this.handleChange} handeLogIn={this.handeLogIn}/>,
      go_register:<GoRegister trsl={trsl} />,
      go_password:<GoPassword trsl={trsl} />,
      go_activation:<GoActivation trsl={trsl}/>,
      change_languages:<ChangeLanguages trsl={trsl} locale={this.state.locale} handleLanguage={this.handleLanguage}/>
    }
    if(user_c.template==='viseeon'){
      return(<LoginViseeon {...props}/>
      )
    } else if(user_c.template==='bazaar'){
      return(<LoginBazaar {...props}/>
      )
    } else {
      return <LoginCASFLO {...props}/>
    }
  }
}

function GoLogin(props){
  return(
    <Row>
      <SingleInputEmailForm xs={12} md={12} label={props.trsl['enter_email']} name="email" value={props.email} handleEditItemChange={props.handleChange}/>
      <SingleInputPasswordForm xs={12} md={12} label={props.trsl['enter_password']} name="pass" value={props.pass} handleinputeditChange={props.handleChange}/>
      <Col xs={12}>
        <Button label={props.trsl['button_login']} bk="bk_success" onClick={props.handeLogIn}/>
      </Col>
    </Row>
  )
}

function GoRegister(props){
  return <Col xs={12} className='blanktop_05'><RegularLinkButton bk="bk_darkaccent" href="/register" label={props.trsl['button_register']} /></Col>
}

function GoPassword(props){
  return <Col xs={12} className='blanktop_05'><RegularLinkButton bk="bk_lightaccent" href="/password" label={props.trsl['button_recover_password']} /></Col>
}

function GoActivation(props){
  return <Col xs={12} className='blanktop_05'><RegularLinkButton bk="bk_lightaccent" href="/activationrequest" label={props.trsl['resend_activation']} /></Col>
}

function ChangeLanguages(props){
  const options=[
    {value:'en',option_label:"English"},
    {value:'pt',option_label:"Português"}
  ]
  return(
    <Row>
      <ToogleButtons xs={12} md={12} label={props.trsl['language']} toogle_data={options} selected={props.locale}  handleToogleButton={props.handleLanguage}/>
    </Row>
  )
}

export default Login;