import React from 'react';
import { Row, Col } from 'Support/grid'
import {Button, Collapse} from 'react-bootstrap';

import {calResults} from 'CALCULATIONS/cal_incomestatement';

import ApiConnect from 'CONFIG/axios';
import Loader from 'Support/loader';
import {Navigation} from 'Support/navigation'
import UserContext from 'CONFIG/auth';

import {DashboardHeaderBar} from './feasibility_headerbar';
import FeasibilityCompareAll from './feasibility_graphs';
import DashboardWarning from './0 Dashboard/dashboard_warnings';
import DashboardTable from './0 Dashboard/dashboard_tables';
import Graphs from './0 Dashboard/dashboard_graphs';

import color_sets from 'JSON/brand_color_sets.json';

import './0 Dashboard/dashboard_style.css'

class FeasibilityDashboard extends React.Component{
  static contextType = UserContext

  constructor(props) {
    super(props);
    this.state = {
      data:"",
      single_project_data:'',
      type_of_dashboard:"compare",
      selected_project:-1,
      loaded:false,
      warnings:true,
      visuals:true,
      reports:true
    }
    this.manageBoard=this.manageBoard.bind(this)
    this.handleSelect=this.handleSelect.bind(this)
  }

  componentDidMount() {
    this.updateListData()
  }
  
  //GENERAL FUNCTIONS
  updateListData=async()=>{
    const request={
      project_id:this.props.project_id,
      settings:this.props.project_data.project_settings
    }
    try {
      const response = await ApiConnect.post('/api/versions/results/get', request)
      let edited_data=response.data
      for(var i=0;i<edited_data.length;i++){
        edited_data[i]=await calResults(edited_data[i],this.props.feasibility_settings)
      }
      this.setState({
        data:edited_data,
        loaded:true
      })
    } catch(error) {
        alert(error);
    }
  }

  //handle which version to display
  handleSelect(selectedKey) {
    let typer="compare"
    if(selectedKey!==0){
      typer="single"
      this.setState({
        selected_project: selectedKey-1,
        single_project_data:this.state.data[selectedKey-1].results,
        type_of_dashboard:typer
      })
    } else{
      this.setState({
        selected_project: selectedKey-1,
        type_of_dashboard:typer
      })
    }
  }

  //MANAGE BOARDS FUNCTIONS
  manageBoard=(event)=>{
    let setter
    const item=event.target.name
    if(this.state[item]===true) {setter= false}
    else {setter=true}
    this.setState({
      [item]:setter
    })
  }
  
  render() {
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_PROJECT.PROJECT_FEASIBILITY.Dashboards')
    
    const active_color_set=color_sets[user_c.template]
    const stackpalette=[
      active_color_set.primary, 
      active_color_set.lightaccent,
      active_color_set.darkaccent,
      active_color_set.lightshade,
      active_color_set.darkshade,
      active_color_set.secondary]

    const props={
      trsl:trsl,
      data:this.state.data,
      single_project_data:{
        results:this.state.single_project_data
      },
      type_of_dashboard:this.state.type_of_dashboard,
      project_data:this.props.project_data,
      feasibility_settings:this.props.feasibility_settings,
      selected_project:this.state.selected_project,
      manageBoard:this.manageBoard,
      warnings:this.state.warnings,
      visuals:this.state.visuals,
      reports:this.state.reports,
      handleSelect:this.handleSelect,
      handleEditItem:this.props.handleEditItem,
      stackpalette:stackpalette
    }
    function torender(data, i){
      return data[i];
    }
    const pages ={
      true: <ToDisplay {...props}/>,
      false: <Loader loader_message="Incoming results!"/>
    }
    var i = this.state.loaded
    const renderizing = torender(pages,i)

    return(
      <div>{renderizing}</div>
    );
  }
}

class ToDisplay extends React.Component{
  render(){
    const props=this.props

    function torender(data, i){ return data[i] }
    const pages ={
      compare: <FeasibilityCompareAll {...props}/>,
      single: <FeasibilitySingleVersion {...props}/>
    }
    var i = this.props.type_of_dashboard
    const renderizing = torender(pages,i)
    
    return(
      <Row >
        <Col xs={12}>
          <DashboardHeaderBar p_name={this.props.project_data.project_name} trsl={this.props.trsl} handleEditItem={this.props.handleEditItem}/>
        </Col>
        <Col xs={2} >
          <FeasibilityDashboardNavigation {...props}/>
        </Col>
        <Col xs={10}>
          {renderizing}
        </Col>
      </Row>
    );
  }
}


class FeasibilityDashboardNavigation extends React.Component {
  render(){
    const item_holder=this.props.data
    const item_distr=item_holder.map((value,k)=>{
      return <Navigation.Item name={k+1}>{value.version_name}</Navigation.Item>
    })

    return(     
      <Navigation handleSelect={this.props.handleSelect} active_key={this.props.selected_project+1}>
        <Navigation.NavigationMega>{this.props.trsl['compare_all']}</Navigation.NavigationMega>
        <Navigation.Item name={0}>{this.props.trsl['all_versions']}</Navigation.Item>
        <Navigation.NavigationMega>{this.props.trsl['individual_version']}</Navigation.NavigationMega>
        {item_distr}
      </Navigation>
    );
  }
}

class FeasibilitySingleVersion extends React.Component {
  static contextType = UserContext
  render(){
    let user_c= this.context
    const locale=user_c.locale
    
    const active_color_set=color_sets[user_c.template]
    const stackpalette=[
      active_color_set.primary, 
      active_color_set.lightaccent,
      active_color_set.darkaccent,
      active_color_set.lightshade,
      active_color_set.darkshade,
      active_color_set.secondary]
    
    let data_to_project=this.props.data[this.props.selected_project]
    
    const props={
      trsl:this.props.trsl,
      data:this.props.single_project_data,
      feasibility_settings:this.props.feasibility_settings,
      manageBoard:this.manageBoard,
      warnings:this.props.warnings,
      visuals:this.props.visuals,
      reports:this.props.reports,
      stackpalette:this.props.stackpalette,
      years:this.props.feasibility_settings.historic_years.concat(this.props.feasibility_settings.years),
    }

    const buttonColor=(input)=>{
      if(input===true){ return "primary"
      } else{
        return "outline-secondary"
      }
    }

    return(
      <>
        <Row className="feasibility_dashboard_header_row">
          <Col xs={6}>
            <h3>{data_to_project.version_name}</h3>
          </Col>
          <Col xs={6}>
            <Row>
              <Col xs={4}>
                <Button 
                block
                size="sm"
                name="warnings" 
                value={this.props.warnings} 
                onClick={this.props.manageBoard}
                variant={buttonColor(this.props.warnings)}>
                  {this.props.trsl['warnings']}
                </Button>
              </Col>
              <Col xs={4}>
                <Button 
                block
                size="sm"
                name="visuals" 
                value={this.props.visuals} 
                onClick={this.props.manageBoard}
                variant={buttonColor(this.props.visuals)}>
                  {this.props.trsl['visuals']}
                </Button>
              </Col>
              <Col xs={4}>
                <Button 
                block
                size="sm"
                name="reports" 
                value={this.props.reports} 
                onClick={this.props.manageBoard}
                variant={buttonColor(this.props.reports)}>
                  {this.props.trsl['reports']}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Collapse in={this.props.warnings}>
          <Row className="dashboard_main_separator">
            <DashboardWarning {...props}/>
          </Row>
        </Collapse>
        <Collapse in={this.props.visuals}>
          <Row className="dashboard_main_separator">
            <Col xs={12}>
              <Graphs {...props}/>
            </Col>
          </Row>
        </Collapse>
        <Collapse in={this.props.reports}>
          <Row className="dashboard_main_separator">
            <Col xs={12}>
              <DashboardTable {...props}/>
            </Col>
          </Row>
        </Collapse>
      </>
    )
  }
}

export default FeasibilityDashboard;