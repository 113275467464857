import React from 'react';
import {  Route, Routes } from "react-router-dom";
import { Row, Col } from 'Support/grid'

import Session from 'CONFIG/session';

import CanvaNavigation from './canva_nav';
import {CanvaHeaderBar} from './canva_headerbar';
import SwotMain from './swot/swot';
import BMCMain from './bmc/bmc';
import PestelMain from './pestel/pestel';
import Description from './description/description';
import CompetitiveMain from './competitive environment/competitive';
import 'CSS/panes.css';
import 'CSS/canva_style.css';

const route="/app/business_plans/canva/"

class CanvaMain extends React.Component{
  constructor(props) {
    super(props);
      this.state = {
      canva_render_state: Session.getCanvaRender(),
    }
    this.handleSelect=this.handleSelect.bind(this);
  }

  componentDidMount(){
  }

  handleSelect(selectedKey) {
    this.setState({
      canva_render_state: selectedKey
    })
    Session.setCanvaRender(selectedKey)
    this.props.handlePrincipalRouting(route+selectedKey)
  }

  render(){
    const props={
      version_id:this.props.version_id,
      project_id:this.props.project_id,
      project_data:this.props.project_data,
      user_permission:this.props.project_data.user_permission
    }

    return(
        <Row>
          <Col xs={12}>
            <CanvaHeaderBar p_name={this.props.project_data.project_name} v_name={this.props.v_name} handleEditItem={this.props.handleEditItem}/>
          </Col>
          <Col xs={2} className='bk_darkshade'>
            <CanvaNavigation active_key={this.state.canva_render_state} handleSelect={this.handleSelect}/>
          </Col>
          <Col xs={10} >
            <Routes>
              <Route index element={<Description {...props}/>}/>
              <Route path={`description`} element={<Description {...props}/>}/>    
              <Route path={`swot`} element={<SwotMain {...props}/>}/>
              <Route path={`pestel`} element={<PestelMain {...props}/>}/>
              <Route path={`bmc`} element={<BMCMain {...props}/>}/>  
              <Route path={`competitive`} element={<CompetitiveMain {...props}/>}/>  
            </Routes>
          </Col>
        </Row>
    );
  }
}

export default CanvaMain;