import React from 'react';
import {Grid, Row, Col} from 'Support/grid';
import {Image, Button} from 'react-bootstrap';
import ReactGA from 'react-ga4';

import UserContext from 'CONFIG/auth';
import {ApiPublicConnect} from 'CONFIG/axios';

import logo2 from 'images/logo/logo2.png';
import './public.css';

import {ActivateViseeon} from 'TEMPLATES/Viseeon/public_templates'


class Activate extends React.Component{
  constructor(props){
    super(props);
    this.state={
      activation_status:false
    }
  }

  componentDidMount(){
    this.autoActivate()
  }


  autoActivate=async()=>{
  var activ_u=window.location.href
  const index_0=activ_u.search("/activate/")
  const cut_1=activ_u.slice(index_0+10)
  const index_1=cut_1.search("/")
  const user_name=cut_1.substr(0,index_1)
  const decoded_user_name=decodeURI(user_name)
  const cut_2=cut_1.slice(index_1+1)
  const index_2=cut_2.search("/")
  const user_email=cut_2.substr(0,index_2)
  const id=cut_2.slice(index_2+1)
    const request={
      name:decoded_user_name,
      email:user_email,
      id:id
    }
    try{
      await ApiPublicConnect.post('/user/activate', request);
      ReactGA.event({category: "Register", action: "Fully registered"})
      this.setState({
        activation_status:true
      })
    } catch (error) {
    }
  }

  handleLanguage=()=>{
    const user_c=this.context
    const bla='en'
    user_c.setLocale(bla)  
  }

  static contextType = UserContext
  render(){
    let user_c= this.context

    const props={
      activation_status:this.state.activation_status,
    }
    if(user_c.template==='viseeon'){
      return(
        <> 
          <ActivateViseeon {...props}/>
        </>
      )
    } else{
      return(
        <>
          <ActivateCASFLO {...props}/>
        </>
      )
    }
  }
}

class ActivateCASFLO extends React.Component{
  static contextType = UserContext
  render(){
    let user_c= this.context
    function renderizing_proceed(input){
      if(input===true){
        return(
          <div className="activate_div">
            <h2>{user_c.i18n.t('PUBLIC.Activation_done.activation_completed')}</h2>
            <Button block href="/login" >
              {user_c.i18n.t('PUBLIC.Activation_done.button')}
            </Button> 
          </div>
        )
      } else{
        return(
          <div className="activate_div">
            <h2>{user_c.i18n.t('PUBLIC.Activation_done.activation_in_process')}</h2>
          </div>
        )
      }
    }

    const renderizing=renderizing_proceed(this.props.activation_status)
    return(
      <Grid fluid className="main_block public_box">
        <Row >
          <Col md={4}>          
          </Col>
          <Col md={4}>
            <Image src={logo2} className="public_logo"/>
            {renderizing}
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default Activate;