import React from 'react'
import  {Grid,Col, Row} from 'Support/grid'
import {Image} from 'react-bootstrap'

import casflo_logo from './Images/casflo_logowhite.png'

import 'TEMPLATES/CASFLO/CSS/public.css'
import 'TEMPLATES/CASFLO/CSS/effects.css'

class LoginCASFLO extends React.Component{
  render(){
    return(
      <>
        <Grid fluid className="main_block public_box">
          <Row>
          <Col xs={12} md={1}></Col>
          <Col xs={12} md={5}>
            <div className='center middle'>
              <Image src={casflo_logo} className="public_logo"/>
            </div>
          </Col>
          <Col xs={12} md={1}></Col>
          <Col xs={12} md={4} className="public_use">
            {this.props.change_languages}

              <h1>{this.props.trsl['login']}</h1>
    
            {this.props.login}
            <Row className='blanktop_15'>
              <Col xs={12}>
                <h5>{this.props.trsl['alternatives_to_login']}</h5>
              </Col>
              {this.props.go_register}
              {this.props.go_password}
              {this.props.go_activation}
            </Row>
            </Col>
            <Col md={1}></Col>
          </Row>
        </Grid>
      </>
    );
  }
}
export {
  LoginCASFLO, 
}