import React, {useState, useContext} from 'react';
import {Row, Col} from 'Support/grid';

import ApiConnect from 'CONFIG/axios';

import './admin_style.css';
import {TableMaker} from 'Support/table';
import UserContext from 'CONFIG/auth';


function AdminImport(props) {
  const [file, setFile] = useState();
  const [buffer_data, setBufferData]=useState(0)
  const [interest_rate_data, setInterestRateData]=useState(0);

  const reader = new FileReader();
  const i18n = useContext(UserContext).i18n

  const handleOnChange = (e) => {
      setFile(e.target.files[0]);
  };

  const getInterestRates= async(e)=>{
    await handleCSV(e)
    for (var i =1; i<buffer_data.length; i++){
      for (var j = 1; j< buffer_data[i].length;j++){
        buffer_data[i][j]=i18n.percF(buffer_data[i][j])
      }
    }
    setInterestRateData(buffer_data)
    setBufferData(0)
  }

  const handleCSV = async(e) => {
    const processCSV = (str, delim=';') => {
      const headers = str.slice(0,str.indexOf('\n')).split(delim);
      const rows = str.replaceAll(',',".").slice(str.indexOf('\n')+1).split('\n');

      let changed_data = []
      changed_data.push(headers)
      rows.map(row =>{
        changed_data.push(row.split(delim));
      })
      for (var i =1; i<changed_data.length; i++){
        for (var j = 1; j< changed_data[i].length;j++){
          changed_data[i][j]=parseFloat(changed_data[i][j])
        }
        setBufferData(changed_data)
      }
    }

    e.preventDefault();
    let csvOutput=''
    if (file) {
      console.log(reader)
      reader.onload = await function (event) {
        csvOutput = event.target.result;
        processCSV(csvOutput)
      };
      reader.readAsText(file)
    }  
  };

  
  return (
    <>
    <Col xs={12}>
        <h1>REACTJS CSV IMPORT EXAMPLE </h1>
        <form>
          <input type={"file"} id={"csvFileInput"} accept={".csv"} onChange={handleOnChange}/>
          <button onClick={(e) => {getInterestRates(e);}}> IMPORT INTEREST RATES</button>
        </form>
      </Col>
      <Col xs={6}>
        <TableMaker data={interest_rate_data} i18n={i18n}/>
      </Col>
    </>
  );
}




export default AdminImport;


