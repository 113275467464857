import React from 'react';

import {Grid, Row, Col} from 'Support/grid';
import Table from 'Support/table';

function AdminImportData(props){
  return(
    <h1>LALA</h1>
  )
}

export default AdminImportData;