import React from "react";
import {Col, Row} from 'Support/grid';
import {round} from 'mathjs';
import FileSaver from 'file-saver';
import {write,utils} from 'xlsx';
import { RegularButton } from 'Support/listbuttons';

function firstRowLooper(el2, el3){
  let bug=[]
  bug.push(el2)
  var i
  for(i=0;i<el3.length;i++){
    bug.push(el3[i].toString())
  }
  return bug
}

function elLooper(first_el,el1, el2, el3){
  let bug=new Object()
  bug[el2]= first_el
  var i
  for(i=0;i<el1.length;i++){
    let upi=el3[i]
    bug[upi]=round(el1[i])
  }
  return bug
}

function elLooperNoRound(first_el,el1, el2, el3){
  let bug=new Object()
  bug[el2]= first_el
  var i
  for(i=0;i<el1.length;i++){
    let upi=el3[i]
    bug[upi]=(el1[i])
  }
  return bug
}

function elLooperSpecial(first_el,el1, el2, el3){
  let bug=new Object()
  let upi=el3[0]
  bug[el2]= first_el
  bug[upi]=el1
  return bug
}


class ExcelDownload extends React.Component{
  render(){
    const props={          
      trsl:this.props.trsl,
      data:this.props.data,
      p_name:this.props.p_name,
      v_name:this.props.v_name
    }

    const cs_seletor={
      false: <CashStatementDownload {...props} years={this.props.years}/>,
      true: <>{''}</>
    }

    const sa_selector={
      true: <StandAloneDownload {...props} years={this.props.years}/>,
      false: <>{''}</>
    }
    const le_selector={
      true: <LeveredDownload {...props} years={this.props.years}/>,
      false: <>{''}</>
    }
    const iv_selector={
      true: <InvestorDownload {...props} years={this.props.years}/>,
      false: <>{''}</>
    }
    return(
      <Row>
        <Col xs={12}>
          <h5>XLSX</h5>
        </Col>
        <ISDownload {...props} years={this.props.h_years}/>
        <BalanceDownload {...props} years={this.props.h_years}/>
        {cs_seletor[this.props.use_historic]}
        <WorkingCapitalDownload {...props} years={this.props.h_years}/>
        <Ratios {...props} years={this.props.h_years}/>
        <CashFlowsDownload {...props} years={this.props.years}/>
        {sa_selector[this.props.feasibility_settings.financial_standalone_project]}
        {le_selector[this.props.feasibility_settings.financial_levered_project]}
        {iv_selector[this.props.feasibility_settings.financial_investor]}
        <DebtCoverage {...props} years={this.props.years}/>
      </Row>
    )
  }
}

class ISDownload extends React.Component {
  release_IS=()=>{
    const year=this.props.trsl['year']
    const years=this.props.years
    const header=firstRowLooper(year,years)
    const income_statement_data = [
      elLooper(this.props.trsl['revenues'], this.props.data.results._inc_st.revenue, year,years),
      elLooper(this.props.trsl['subsidies'], this.props.data.results._inc_st.operational_grants, year,years),
      elLooper(this.props.trsl['cogs'], this.props.data.results._inc_st.cogs, year,years),
      elLooper(this.props.trsl['operating_exp'], this.props.data.results._inc_st.operating_expenses, year,years),
      elLooper(this.props.trsl['employees'], this.props.data.results._inc_st.hr, year,years),
      elLooper(this.props.trsl['other_revenues'], this.props.data.results._inc_st.other_gains, year,years),
      elLooper(this.props.trsl['other_costs'], this.props.data.results._inc_st.other_costs, year,years),
      elLooper(this.props.trsl['ebitda'], this.props.data.results._inc_st.ebitda, year,years),
      elLooper(this.props.trsl['depreciations'], this.props.data.results._inc_st.depreciations, year,years),
      elLooper(this.props.trsl['ebit'], this.props.data.results._inc_st.ebit, year,years),
      elLooper(this.props.trsl['financing_costs'], this.props.data.results._inc_st.interest_cost, year,years),
      elLooper(this.props.trsl['ebt'], this.props.data.results._inc_st.ebt, year,years),
      elLooper(this.props.trsl['corporate_tax'], this.props.data.results._inc_st.corporate_tax, year,years),
      elLooper(this.props.trsl['net_income'], this.props.data.results._inc_st.net_profit, year,years),
    ]
    return [income_statement_data,header]
  }
  render() {
    return(
      <ExportCSV 
      dataFunction={this.release_IS} 
      fileName={this.props.trsl['income_st']} 
      p_name={this.props.p_name}
      v_name={this.props.v_name} />
    )
  }
}

class BalanceDownload extends React.Component {
  release_balance=()=>{
    const year=this.props.trsl['year']
    const years=this.props.years
    const header=firstRowLooper(year,years)
    const balance_data = [
      elLooper(this.props.trsl['tangible'], this.props.data.results._bal.tangible_assets, year,years),
      elLooper(this.props.trsl['intangible'], this.props.data.results._bal.intangible_assets, year,years),
      elLooper(this.props.trsl['other_non_assets'], this.props.data.results._bal.other_non_current_assets, year,years),
      elLooper(this.props.trsl['non_current_assets'], this.props.data.results._bal.total_non_current_assets, year,years),
      elLooper(this.props.trsl['iventories'], this.props.data.results._bal.inventories, year,years),
      elLooper(this.props.trsl['clients'], this.props.data.results._bal.clients, year,years),
      elLooper(this.props.trsl['gov_receivables'], this.props.data.results.gov.gov_receivable, year,years),
      elLooper(this.props.trsl['other_current_assets'], this.props.data.results._bal.other_current_assets, year,years),
      elLooper(this.props.trsl['cash'], this.props.data.results.cf.year_end_cash_flows, year,years),
      elLooper(this.props.trsl['current_assets'], this.props.data.results._bal.total_current_assets, year,years),
      elLooper(this.props.trsl['total_assets'], this.props.data.results._bal.total_assets, year,years),
      elLooper(this.props.trsl['issued_capital'], this.props.data.results._bal.issued_capital, year,years),
      elLooper(this.props.trsl['other_equity_instr'], this.props.data.results._bal.other_equity_instruments, year,years),
      elLooper(this.props.trsl['other_equity_chan'], this.props.data.results._bal.other_equity_changes, year,years),
      elLooper(this.props.trsl['past_results'], this.props.data.results._bal.past_results, year,years),
      elLooper(this.props.trsl['net_income'], this.props.data.results._inc_st.net_profit, year,years),
      elLooper(this.props.trsl['total_equity'], this.props.data.results._bal.total_equity, year,years),
      elLooper(this.props.trsl['debt'], this.props.data.results._bal.debt, year,years),
      elLooper(this.props.trsl['other_non_liabilities'], this.props.data.results._bal.other_non_current_liabilities, year,years),
      elLooper(this.props.trsl['non_current_liabilities'], this.props.data.results._bal.total_non_current_liabilities, year,years),
      elLooper(this.props.trsl['suppliers'], this.props.data.results._bal.suppliers, year,years),
      elLooper(this.props.trsl['gov_payables'], this.props.data.results.gov.gov_payable, year,years),
      elLooper(this.props.trsl['shareholders_loan'], this.props.data.results._bal.shareholders_loan, year,years),
      elLooper(this.props.trsl['other_payables'], this.props.data.results._bal.other_accounts_payables, year,years),
      elLooper(this.props.trsl['other_current_liabilities'], this.props.data.results._bal.other_current_liabilities, year,years),
      elLooper(this.props.trsl['current_liabilities'], this.props.data.results._bal.total_current_liabilities, year,years),
      elLooper(this.props.trsl['total_liabilities'], this.props.data.results._bal.total_liabilities, year,years),
      elLooper(this.props.trsl['equity_liabilities'], this.props.data.results._bal.equity_liabilities, year,years),
    ]
    return [balance_data,header]
  }
  render() {
    return(
      <ExportCSV 
      dataFunction={this.release_balance} 
      fileName={this.props.trsl['balance_sheet']}
      p_name={this.props.p_name}
      v_name={this.props.v_name} />
    )
  }
}

class CashStatementDownload extends React.Component {
  release_cash_st=()=>{
    const year=this.props.trsl['year']
    const years=this.props.years
    const header=firstRowLooper(year,years)
    const cash_statement_data = [
      elLooper(this.props.trsl['received_clients'], this.props.data.results.cf.received_from_clients, year,years),
      elLooper(this.props.trsl['paid_suppliers'], this.props.data.results.cf.paid_to_suppliers, year,years),
      elLooper(this.props.trsl['paid_hr'], this.props.data.results.cf.payment_to_hr, year,years),
      elLooper(this.props.trsl['operation_cf'], this.props.data.results.cf.operations_cash_flows, year,years),
      elLooper(this.props.trsl['corporate_tax'], this.props.data.results.cf.corporate_tax_cash_flow, year,years),
      elLooper(this.props.trsl['other_pay_rec'], this.props.data.results.cf.other_payments_and_receivables, year,years),
      elLooper(this.props.trsl['operational_cf'], this.props.data.results.cf.operational_cash_flow, year,years),
      elLooper(this.props.trsl['non_current_cf'], this.props.data.results.cf.cash_flows_of_non_current_assets_liabilities, year,years),
      elLooper(this.props.trsl['investment_in_assets'],this.props.data.results.cf.payment_of_fixed_assets,year,years),
      elLooper(this.props.trsl['investment_cf'], this.props.data.results.cf.investment_cash_flows, year,years),
      elLooper(this.props.trsl['debt_increase'], this.props.data.results.cf.debt_increase, year,years),
      elLooper(this.props.trsl['equity_eq_increase'], this.props.data.results.cf.equity_and_equivalent_increase, year,years),
      elLooper(this.props.trsl['financing_costs'], this.props.data.results._inc_st.interest_cost, year,years),
      elLooper(this.props.trsl['debt_decrease'], this.props.data.results.cf.debt_decrease, year,years),
      elLooper(this.props.trsl['equity_eq_decrease'], this.props.data.results.cf.equity_and_equivalent_decrease, year,years),
      elLooper(this.props.trsl['financing_cashflows'], this.props.data.results.cf.financing_cashflows,year,years),
      elLooper(this.props.trsl['cf_variation'], this.props.data.results.cf.cash_flow_variation, year,years),
      elLooper(this.props.trsl['initial_cash'], this.props.data.results.cf.initial_cash_flows, year,years),
      elLooper(this.props.trsl['end_cash'], this.props.data.results.cf.year_end_cash_flows, year,years),
    ]
    return [cash_statement_data,header]
  }
  render() {
    return(
      <ExportCSV 
      dataFunction={this.release_cash_st} 
      fileName={this.props.trsl['cash_st']}
      p_name={this.props.p_name}
      v_name={this.props.v_name}/>
    )
  }
}

class WorkingCapitalDownload extends React.Component {
  releaseWorkingCapital=()=>{
    const year=this.props.trsl['year']
    const years=this.props.years
    const header=firstRowLooper(year,years)
    const working_capital_data = [
      elLooper(this.props.trsl['iventories'], this.props.data.results._bal.inventories, year,years),
      elLooper(this.props.trsl['clients'], this.props.data.results._bal.clients, year,years),
      elLooper(this.props.trsl['gov_receivables'], this.props.data.results.gov.gov_receivable, year,years),
      elLooper(this.props.trsl['other_current_assets'], this.props.data.results._bal.other_current_assets, year,years),
      elLooper(this.props.trsl['working_capital_investent'], this.props.data.results.wc.wc_investment, year,years),
      elLooper(this.props.trsl['suppliers'], this.props.data.results._bal.suppliers, year,years),
      elLooper(this.props.trsl['gov_payables'], this.props.data.results.gov.gov_payable, year,years),
      elLooper(this.props.trsl['other_payables'], this.props.data.results._bal.other_accounts_payables, year,years),
      elLooper(this.props.trsl['other_current_liabilities'], this.props.data.results._bal.other_current_liabilities, year,years),
      elLooper(this.props.trsl['working_capital_financing'], this.props.data.results.wc.wc_financing, year,years),
      elLooper(this.props.trsl['working_capital_needs'], this.props.data.results.wc.wc_needs, year,years),
      elLooper(this.props.trsl['working_capital_variation'], this.props.data.results.wc.wc_variation, year,years),
    ]
    return [working_capital_data,header]
  }
  render() {
    return(
      <ExportCSV 
      dataFunction={this.releaseWorkingCapital} 
      fileName={this.props.trsl['working_capital']}
      p_name={this.props.p_name}
      v_name={this.props.v_name}/>
    )
  }
}

class Ratios extends React.Component {
  releaseWorkingCapital=()=>{
    const year=this.props.trsl['year']
    const years=this.props.years
    const header=firstRowLooper(year,years)
    const ratios_data = [
      elLooperNoRound(this.props.trsl['gross_margin'], this.props.data.results.ra.gross_margin, year,years),
      elLooperNoRound(this.props.trsl['net_margin'], this.props.data.results.ra.net_margin, year,years),
      elLooperNoRound(this.props.trsl['return_on_sales'], this.props.data.results.ra.ros, year,years),
      elLooperNoRound(this.props.trsl['return_on_assets'], this.props.data.results.ra.roa, year,years),
      elLooperNoRound(this.props.trsl['return_on_equity'], this.props.data.results.ra.roe, year,years),
      elLooperNoRound(this.props.trsl['equity_ratio'], this.props.data.results.ra.equity_ratio, year,years),
      elLooperNoRound(this.props.trsl['dar'], this.props.data.results.ra.dar, year,years),
      elLooperNoRound(this.props.trsl['dap'], this.props.data.results.ra.dap, year,years),
      elLooperNoRound(this.props.trsl['dos'], this.props.data.results.ra.dos, year,years),
      elLooperNoRound(this.props.trsl['depreciation_rate'], this.props.data.results.ra.depreciation_rate, year,years),
      elLooperNoRound(this.props.trsl['corporate_tax_rate'], this.props.data.results.ra.corporate_tax_rate, year,years),
    ]
    return [ratios_data,header]
  }
  render() {
    return(
      <ExportCSV 
      dataFunction={this.releaseWorkingCapital} 
      fileName={this.props.trsl['ratios']}
      p_name={this.props.p_name}
      v_name={this.props.v_name}/>
    )
  }
}

class CashFlowsDownload extends React.Component {
  releaseCashFlows=()=>{
    const year=this.props.trsl['year']
    const years=this.props.years
    const header=firstRowLooper(year,years)
    const cash_flows_data = [
      elLooper(this.props.trsl['ebt'], this.props.data.results.fe.ebit, year,years),
      elLooper(this.props.trsl['operationa_corporate_tax'], this.props.data.results.fe.operational_corporate_tax, year,years),
      elLooper(this.props.trsl['depreciations'], this.props.data.results.fe.depreciations, year,years),
      elLooper(this.props.trsl['noplat'], this.props.data.results.fe.noplat, year,years),
      elLooper(this.props.trsl['working_capital_variation'], this.props.data.results.fe.wc_variation, year,years),
      elLooper(this.props.trsl['fixed_asset_investment'], this.props.data.results.fe.fixed_asset_investment, year,years),
      elLooper(this.props.trsl['fcff'], this.props.data.results.fe.fcff, year,years),
      elLooper(this.props.trsl['accumulated_fcff'], this.props.data.results.fe.fcff_accumulated, year,years),
      elLooper(this.props.trsl['fcfe'], this.props.data.results.fe.fcfe, year,years),
      elLooper(this.props.trsl['accumulated_fcfe'], this.props.data.results.fe.fcfe_accumulated, year,years),
    ]
    return [cash_flows_data,header]
  }
  releaseNPV=()=>{
    const year=this.props.trsl['year']
    const years=["1"]
    const header=firstRowLooper(year,years)
    const npv_data = [
      elLooper(this.props.trsl['normalized_fcff'],this.props.data.results.fe.fcff_normalized,year,years)
    ]
    return [npv_data,header]
  }
  render() {
    return(
      <ExportCSV 
      dataFunction= {this.releaseCashFlows} 
      fileName={this.props.trsl['cash_flows']}
      p_name={this.props.p_name}
      v_name={this.props.v_name}/>
    )
  }
}

class StandAloneDownload extends React.Component {
  releaseCashFlows=()=>{
    const year=this.props.trsl['year']
    const years=this.props.years
    const header=firstRowLooper(year,years)
    const cash_flows_data = [
      elLooper(this.props.trsl['fcff'], this.props.data.results.fe.fcff, year,years),
      elLooper(this.props.trsl['accumulated_fcff'], this.props.data.results.fe.fcff_accumulated, year,years),
      elLooper(this.props.trsl['discounted_fcff'], this.props.data.results._sap.fcff_discounted, year,years),
      elLooper(this.props.trsl['accumulated_discounted_fcff'], this.props.data.results._sap.fcff_discounted_accumulated, year,years),
      elLooper("","",year,years),
      elLooper(this.props.trsl['perpetuity'],"",year,years),
      elLooperSpecial(this.props.trsl['normalized_fcff'],this.props.data.results.fe.fcff_normalized,year,years),
      elLooperSpecial(this.props.trsl['perpetuity'],this.props.data.results._sap.perpetuity,year,years),
      elLooperSpecial(this.props.trsl['perpetuity_discounted'],[this.props.data.results._sap.perpetuity_discounted],year,years),
      elLooper("","",year,years),
      elLooper(this.props.trsl['npv'],"",year,years),
      elLooperSpecial(this.props.trsl['npv'],this.props.data.results._sap.npv,year,years),
      elLooperSpecial(this.props.trsl['irr'],this.props.data.results._sap.irr*100,year,years),
      elLooperSpecial(this.props.trsl['payback_period'],this.props.data.results._sap.payback,year,years),
    ]
    return [cash_flows_data,header]
  }
  render() {
    return(
      <ExportCSV 
      dataFunction= {this.releaseCashFlows} 
      fileName={this.props.trsl['stand_alone_analysis']}
      p_name={this.props.p_name}
      v_name={this.props.v_name}/>
    )
  }
}

class LeveredDownload extends React.Component {
  releaseCashFlows=()=>{
    const year=this.props.trsl['year']
    const years=this.props.years
    const header=firstRowLooper(year,years)
    const cash_flows_data = [
      elLooper(this.props.trsl['fcff'], this.props.data.results.fe.fcff, year,years),
      elLooper(this.props.trsl['accumulated_fcff'], this.props.data.results.fe.fcff_accumulated, year,years),
      elLooper(this.props.trsl['discounted_fcff'], this.props.data.results._lep.fcff_discounted, year,years),
      elLooper(this.props.trsl['accumulated_discounted_fcff'], this.props.data.results._lep.fcff_discounted_accumulated, year,years),
      elLooper("","",year,years),
      elLooper(this.props.trsl['perpetuity'],"",year,years),
      elLooperSpecial(this.props.trsl['normalized_fcff'],this.props.data.results.fe.fcff_normalized,year,years),
      elLooperSpecial(this.props.trsl['perpetuity'],this.props.data.results._lep.perpetuity,year,years),
      elLooperSpecial(this.props.trsl['perpetuity_discounted'],[this.props.data.results._lep.perpetuity_discounted],year,years),
      elLooper("","",year,years),
      elLooper(this.props.trsl['npv'],"",year,years),
      elLooperSpecial(this.props.trsl['npv'],this.props.data.results._lep.npv,year,years),
      elLooperSpecial(this.props.trsl['irr'],this.props.data.results._lep.irr*100,year,years),
      elLooperSpecial(this.props.trsl['payback_period'],this.props.data.results._lep.payback,year,years),
    ]
    return [cash_flows_data,header]
  }
  render() {
    return(
      <ExportCSV 
      dataFunction= {this.releaseCashFlows} 
      fileName={this.props.trsl['levered_analysis']}
      p_name={this.props.p_name}
      v_name={this.props.v_name}/>
    )
  }
}

class InvestorDownload extends React.Component {
  releaseCashFlows=()=>{
    const year=this.props.trsl['year']
    const years=this.props.years
    const header=firstRowLooper(year,years)
    const cash_flows_data = [
      elLooper(this.props.trsl['fcff'], this.props.data.results.fe.fcfe, year,years),
      elLooper(this.props.trsl['accumulated_fcff'], this.props.data.results.fe.fcfe_accumulated, year,years),
      elLooper(this.props.trsl['discounted_fcfe'], this.props.data.results._ivp.fcfe_discounted, year,years),
      elLooper(this.props.trsl['accumulated_discounted_fcfe'], this.props.data.results._ivp.fcfe_discounted_accumulated, year,years),
      elLooper("","",year,years),
      elLooper(this.props.trsl['perpetuity'],"",year,years),
      elLooperSpecial(this.props.trsl['normalized_fcff'],this.props.data.results.fe.fcfe_normalized,year,years),
      elLooperSpecial(this.props.trsl['perpetuity'],this.props.data.results._ivp.perpetuity,year,years),
      elLooperSpecial(this.props.trsl['perpetuity_discounted'],[this.props.data.results._ivp.perpetuity_discounted],year,years),
      elLooper("","",year,years),
      elLooper(this.props.trsl['npv'],"",year,years),
      elLooperSpecial(this.props.trsl['npv'],this.props.data.results._ivp.npv,year,years),
      elLooperSpecial(this.props.trsl['irr'],this.props.data.results._ivp.irr*100,year,years),
      elLooperSpecial(this.props.trsl['payback_period'],this.props.data.results._ivp.payback,year,years),
    ]
    return [cash_flows_data,header]
  }
  render() {
    return(
      <ExportCSV 
      dataFunction= {this.releaseCashFlows} 
      fileName={this.props.trsl['investor_analysis']}
      p_name={this.props.p_name}
      v_name={this.props.v_name}/>
    )
  }
}

class DebtCoverage extends React.Component {
  releaseWorkingCapital=()=>{
    const year=this.props.trsl['year']
    const years=this.props.years
    const header=firstRowLooper(year,years)
    const ratios_data = [
      elLooper(this.props.trsl['debt_interest'], this.props.data.results.ra.interest, year,years),
      elLooperNoRound(this.props.trsl['ebtida_coverage'], this.props.data.results.ra.ebtida_interest_coverage_rate, year,years),
      elLooperNoRound(this.props.trsl['fcff_coverage'], this.props.data.results.ra.fcff_interest_coverate_rates, year,years),
      elLooperNoRound(this.props.trsl['cash_coverage'], this.props.data.results.ra.available_cash_interest_coverage_rate, year,years),
      elLooper(this.props.trsl['debt_service'], this.props.data.results.ra.debt_service, year,years),
      elLooperNoRound(this.props.trsl['ebtida_coverage'], this.props.data.results.ra.ebtida_debt_service_rate, year,years),
      elLooperNoRound(this.props.trsl['fcff_coverage'], this.props.data.results.ra.fcff_debt_service_rate, year,years),
      elLooperNoRound(this.props.trsl['cash_coverage'], this.props.data.results.ra.available_cash_debt_service_rate, year,years),
    ]
    return [ratios_data,header]
  }
  render() {
    return(
      <ExportCSV 
      dataFunction={this.releaseWorkingCapital} 
      fileName={this.props.trsl['debt_coverage']}
      p_name={this.props.p_name}
      v_name={this.props.v_name}/>
    )
  }
}



const ExportCSV = ({fileName, dataFunction, p_name, v_name}) => {

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  
  const date=new Date()
  const primarydata=[
    {
      Date:date,
      Project:p_name,
      Version:v_name
    }
  ]

  const exportToCSV = (fileName) => {
    const tdata=dataFunction()
      const ws = utils.json_to_sheet(primarydata)
      utils.sheet_add_json(ws, tdata[0],{header:tdata[1], origin: "A4"});
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], {type: fileType});
      FileSaver.saveAs(data, fileName + fileExtension);
  }

  return (
    <Col xs={6} md={3} className="dashboard_exportbutton">
      <RegularButton bk="bk_primary" onClick={(e) => exportToCSV(fileName, dataFunction)} label={fileName} />
    </Col>
  )
}

export default ExcelDownload;