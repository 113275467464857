import React from 'react';
import {AssumptionHeaderSaveReturn} from 'PRIVATE/PROJECT/Feasibility/feasibility_items';
import { Row, Col } from 'Support/grid';
import { SingleInputFormFlat, FormEdit, SingleInputNumberForm, SingleInputIntegerForm, PeriodHeader, NonEditableForm, Dropdown, Observations} from 'Support/inputforms'
import ApiConnect from 'CONFIG/axios';
import UserContext from 'CONFIG/auth';
import Loader from 'Support/loader';
import {calculateDebt} from 'CALCULATIONS/cal_debt';
import {add} from 'mathjs'
import { ToogleButtons } from 'Support/toogle_buttons';

class InvestmentEntry extends React.Component {
  static contextType = UserContext

  constructor(props) {
    super(props);
    this.state = {
      data: "",
      loaded:false,
      years:this.props.feasibility_settings.historic_years.concat(this.props.feasibility_settings.years)
    }
    this.handleEditChange = this.handleEditChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleGracePeriodChange = this.handleGracePeriodChange.bind(this)
    this.handleSave = this.handleSave.bind(this);
    this.handleObservationsChange = this.handleObservationsChange.bind(this);
  }

  componentDidMount= async()=>{
    const request={
    project_id:this.props.project_id,
    version_id:this.props.version_id,
    user_id:this.props.user_id,
    item_id:this.props.active_item,
    item_type:"debt"
    }
    try {
      const response = await ApiConnect.post('/api/multitem/unique/get', request)
        this.setState({
          data:response.data.item,
          loaded:true
        })
    } catch(error) {
        alert(error);
    }
  }

  handleEditChange=async(event)=>{
    const name = event.target.name;
    const value = event.target.value;
    let go = this.state.data;
    let years= this.props.feasibility_settings.number_of_years;
    if(this.props.feasibility_settings.use_historic===true){
      years+=this.props.feasibility_settings.number_of_historic_years
    }
    go[name]=value;
    calculateDebt(go, years)
    this.setState({data:go})
  }

  handleNameChange(event){
    const value = event.target.value;
    let go = this.state.data;
    go.debt_name=value;
    this.setState({data:go })
  }

  handleGracePeriodChange(event){
    let val=event.target.value
    let go = this.state.data;
    if(val==="false") {val = false} else {val=true} 
    go.debt_grace_period_bool=val;
    let years= this.props.feasibility_settings.number_of_years;
    if(this.props.feasibility_settings.use_historic===true){
      years+=this.props.feasibility_settings.number_of_historic_years
    }
    calculateDebt(go, years)
    this.setState({data:go })
  }
  handleRowEdit=(id, item)=>{
    let go = this.state.data;
    go[id]=item;
    let years= this.props.feasibility_settings.number_of_years;
    if(this.props.feasibility_settings.use_historic===true){
      years+=this.props.feasibility_settings.number_of_historic_years
    }
    calculateDebt(go, years)
    this.setState({data:go})
  }

  handleRowEqualizer=async(event)=>{
    const id = event.target.id;
    const value = event.target.value;
    let go = this.state.data;
    if(this.props.feasibility_settings.use_historic===true){
      go[id].fill(value,this.props.feasibility_settings.number_of_historic_years)
    } else{
      go[id].fill(value);
    }    
    let years= this.props.feasibility_settings.number_of_years+this.props.feasibility_settings.number_of_historic_years;
    calculateDebt(go, years)
    this.setState({data:go})
  }

  handleTypeOfPaymentChange=(event)=>{
    let val=event.target.value
    let go = this.state.data;
    go.debt_reibursement_type=val;
    if(val===2){
      go._debt_reimbursement.fill(0) 
    }
    let years= this.props.feasibility_settings.number_of_years;
    if(this.props.feasibility_settings.use_historic===true){
      years+=this.props.feasibility_settings.number_of_historic_years
    }
    calculateDebt(go, years)
    this.setState({data:go })
  }

  handleTypeOfInterestChange=(event)=>{
    let val=event.target.value
    let go = this.state.data;
    go.interest_rate_type=val;
    let years= this.props.feasibility_settings.number_of_years;
    if(this.props.feasibility_settings.use_historic===true){
      years+=this.props.feasibility_settings.number_of_historic_years
    }
    calculateDebt(go, years)
    this.setState({data:go })
  }

  handleObservationsChange(event){
    const value = event.target.value;
    let go = this.state.data;
    go.debt_obs=value;
    this.setState({data:go })
  }

  async handleSave(){
    const request={
      user_id:this.props.user_id,
      project_id:this.props.project_id,
      version_id:this.props.version_id,
      item_id:this.props.active_item,
      item_type:"debt",
      content:this.state.data
    }
    try{
      const response = await ApiConnect.post('/api/multitem/item/modify', request)
      this.props.changeScreenView("list",0)
      this.props.handleLists.handleSaveUpdater(response.data,'debt')
    } catch(error) {
        alert(error);
    }
  }

  render() {
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_PROJECT.PROJECT_FEASIBILITY.Individual_edits')

    const props ={
      trsl:trsl,
      handleScreenNavigation:this.props.handleScreenNavigation,
      handleEditChange:this.handleEditChange,
      handleRowEdit:this.handleRowEdit,
      handleRowEqualizer:this.handleRowEqualizer,
      handleSave:this.handleSave,
      handleNameChange:this.handleNameChange,
      handleTypeOfPaymentChange:this.handleTypeOfPaymentChange,
      handleTypeOfInterestChange:this.handleTypeOfInterestChange,
      handleGracePeriodChange:this.handleGracePeriodChange,
      handlePeriodChange:this.handlePeriodChange,
      handleObservationsChange:this.handleObservationsChange,
      data:this.state.data,
      years:this.state.years,
      feasibility_settings:this.props.feasibility_settings,
      user_permission:this.props.user_permission
  }

    function torender(data, i){
      return data[i];
    }  
    const pages ={
      true: <ToDisplay {...props}/>,
      false: <Loader loader_message="debt_edit"/>
    }
    var i = this.state.loaded
    const renderizing = torender(pages,i)

    return(
      <div>
        {renderizing}
      </div>
    )
  }
}

class ToDisplay extends React.Component {
  render(){
    const props=this.props
    const show_debt={
      0:<ConstantCapitalDebtEdit {...props}/>,
      2:<ProjectStyleDebtEdit {...props}/>
    }
    const renderizing=show_debt[this.props.data.debt_reibursement_type]
    return(
      <>
        <Header {...props} />
        <Row className="feasibility_edit_globalheader_row">
          <TypeOfReimbursement {...props}/>
          <TypeOfInterestRate {...props}/>
        </Row>        
        {renderizing}
        <Row>
          <Col xs={12} className="feasibility_list">
            <Observations className="feasibility_item_list" name="debt_obs" value={this.props.data.op_exp_obs} label="Observations" handleObservationsChange={this.props.handleObservationsChange}/>
          </Col>
        </Row>
      </>
    );
  }
}

class Header extends React.Component{
  render(){
    return(
      <>
        <AssumptionHeaderSaveReturn title={this.props.trsl['debt_header']} user_permission={this.props.user_permission} back={this.props.trsl['back_button']} save={this.props.trsl['save_back_button']} handleSave={this.props.handleSave} handleScreenNavigation={this.props.handleScreenNavigation}/>
        <Row className="feasibility_edit_globalheader_row">
            <SingleInputFormFlat xs={12} md={12} value={this.props.data.debt_name} label= {this.props.trsl['debt_name']} handleEditItemChange={this.props.handleNameChange}/>
        </Row>
      </>
    );
  }
}

class ConstantCapitalDebtEdit extends React.Component{
  render(){
    const props=this.props
    let item_holder= this.props.feasibility_settings.years
    if(this.props.feasibility_settings.use_historic===true){
      item_holder=this.props.feasibility_settings.historic_years.concat(this.props.feasibility_settings.years)
    }
    const item_distr = item_holder.map((value, key) =>{
      if(key<this.props.feasibility_settings.number_of_historic_years){
        return {disabled:true,value:key, option_label:value}
      } else {
        return {value:key, option_label:value}
      }
    })

    const options_months=[
      {value: 1, option_label: this.props.trsl['month_jan']},
      {value: 2, option_label: this.props.trsl['month_feb']},
      {value: 3, option_label: this.props.trsl['month_mar']},
      {value: 4, option_label: this.props.trsl['month_apr']},
      {value: 5, option_label: this.props.trsl['month_may']},
      {value: 6, option_label: this.props.trsl['month_jun']},
      {value: 7, option_label: this.props.trsl['month_jul']},
      {value: 8, option_label: this.props.trsl['month_aug']},
      {value: 9, option_label: this.props.trsl['month_sep']},
      {value: 10, option_label: this.props.trsl['month_oct']},
      {value: 11, option_label:this.props.trsl['month_nov']},
      {value: 12, option_label:this.props.trsl['month_dec']}
    ]

    return(
      <>
        <Row>
          <Col xs={12}>
            <Row>    
              <Dropdown xs={6} md={2}label={this.props.trsl['debt_year']} name="debt_year" value={this.props.data.debt_year} options={item_distr} onChange={this.props.handleEditChange} />
              <Dropdown xs={6} md={2}label={this.props.trsl['debt_month']} name="debt_month" value={this.props.data.debt_month} options={options_months} onChange={this.props.handleEditChange} />
              <SingleInputIntegerForm 
              xs={6} md={2}
              label={this.props.trsl['debt_duration']}
              name="debt_duration"
              value={this.props.data.debt_duration}
              handleinputeditChange={this.props.handleEditChange}
              />
              <SingleInputNumberForm
              xs={6} md={2}
              label={this.props.trsl['debt_amount']}
              name="debt_amount"
              value={this.props.data.debt_amount}
              handleinputeditChange={this.props.handleEditChange}
              unit={new Intl.NumberFormat('ja-JP', { style: 'currency', currency: this.props.feasibility_settings.currency}).format(0).replace(/\d./g,"")}
              />
              <FixedInterestRate {...props}/>
            </Row>
          </Col>
          <GracePeriod {...props}/>        
          <Col xs={12} >
            <PeriodHeader label={this.props.trsl['year']} periods={this.props.years}/>
          </Col>
          <Col xs={12}>
            <FloatingInterestRate {...props}/>
            <InitialAmount {...props}/>
            <Reimbursement {...props}/>
            <FinalAmount {...props}/>
            <Interest {...props}/>
            <DebtService {...props}/>
          </Col>
        </Row>
      </>
    );
  }
}


class ProjectStyleDebtEdit extends React.Component{
  render(){
    const props=this.props
    let item_holder = this.props.feasibility_settings.historic_years
    if(this.props.feasibility_settings.use_historic===true){
      item_holder=this.props.feasibility_settings.historic_years.concat(this.props.feasibility_settings.years)
    }
    const item_distr = item_holder.map((value, key) =>{
      if(key<this.props.feasibility_settings.number_of_historic_years){
        return <option disabled value={key}>{value}</option>
      } else {
        return <option value={key}>{value}</option>
      }
    })    
    return(
      <>
        <Row>
          <FixedInterestRate {...props}/>  
        </Row>
        <Row className="list_table_header">
          <Col xs={12} >
            <PeriodHeader label={this.props.trsl['year']} periods={this.props.years}/>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <InitialAmount {...props}/>
            <ProjectStyleDisbursement {...props}/>
            <ProjectStyleReimbursement {...props}/>
            <FloatingInterestRate {...props}/>
            <FinalAmount {...props}/>
            <Interest {...props}/>
            <DebtService {...props}/>
          </Col>
        </Row>
      </>
    );
  }
}

class TypeOfReimbursement extends React.Component{
  render(){
    const options=[
      {value:0,option_label:this.props.trsl['debt_constant_notional_reibumrsement']},
      {value:2,option_label:this.props.trsl['detb_presonalized_reimbursement']}
    ]
    function undefinedToFalse(input){
      if(input===undefined){
        return 0
      } else return input
    }
    return(
      <ToogleButtons xs={12} md={6} label={this.props.trsl['debt_reibursement_type']} toogle_data={options} selected={undefinedToFalse(this.props.data.debt_reibursement_type)} handleToogleButton={this.props.handleTypeOfPaymentChange} />
    )
  }
}

class TypeOfInterestRate extends React.Component{
  render(){
    const options=[
      {value:'fixed',option_label:this.props.trsl['debt_fixed_interest']},
      {value:'floating',option_label:this.props.trsl['debt_floating_interest']}
    ]
    return(
      <ToogleButtons xs={12} md={6} label={this.props.trsl['debt_interest_type']} toogle_data={options} selected={this.props.data.interest_rate_type} handleToogleButton={this.props.handleTypeOfInterestChange} />
    )
  }
}

function FixedInterestRate (props){
  if (props.data.interest_rate_type==='fixed') {
    return(
      <SingleInputNumberForm xs={6} md={2} label={props.trsl['debt_interest_rate']} name="debt_interest_rate" value={props.data.debt_interest_rate} handleinputeditChange={props.handleEditChange} unit='%'/>
    )
  } else return ""
}

function FloatingInterestRate (props){
  if (props.data.interest_rate_type==='floating') {
    return(
      <FormEdit 
      use_historic={props.feasibility_settings.use_historic}
      item_holder={props.data.debt_interest_rate_float}
      id="debt_interest_rate_float"
      handleForm={props.handleRowEdit}
      label={props.trsl['debt_interest_rate']}
      unit='%'
      />
    )
  } else return ""
}

class GracePeriod extends React.Component{
  render(){
    const props=this.props

    const options=[
      {value:true,option_label:this.props.trsl['debt_grace_period_yes']},
      {value:false,option_label:this.props.trsl['debt_grace_period_no']}
    ]

    function undefinedToFalse(input){
      if(input===undefined){
        return false
      } else return input
    }

    function torender(data){
      if(data!==true){
        return ""
      }  else{
        return <GracePeriodValue {...props}/>
      }
    }
    const renderizing = torender(this.props.data.debt_grace_period_bool)

    if(props.data.debt_reibursement_type===2){
      return ""
    } else {
      return(
        <Col xs={12}>
          <Row>
            <Col xs={12}>
              <h5>{this.props.trsl['debt_grace_period']}</h5>
            </Col>
            <ToogleButtons xs={12} md={12} toogle_data={options} label={this.props.trsl['debt_grace_period_question']} selected={undefinedToFalse(this.props.data.debt_grace_period_bool)} handleToogleButton={this.props.handleGracePeriodChange}/>
            {renderizing}
          </Row>
        </Col>
      )
    }
  }
}

class GracePeriodValue extends React.Component{
  render(){
    return(
        <SingleInputIntegerForm
        xs={6} md={4}
        label={this.props.trsl['debt_grace_period_duration']}
        name="debt_grace_period_month"
        value={this.props.data.debt_grace_period_month}
        handleinputeditChange={this.props.handleEditChange}
        />
    )
  }
}

class InitialAmount extends React.Component{
  render(){ 
    return(
      <NonEditableForm
      item_holder={this.props.data._debt_initial_debt} 
      label={this.props.trsl['debt_initial_amount']}
      value_type="currency"
      currency={this.props.feasibility_settings.currency}
      />
    );
  }
}

class Reimbursement extends React.Component{
  render(){ 
    return(
      <NonEditableForm
      item_holder={this.props.data._debt_reimbursement} 
      s_value={this.props.s_value} 
      label={this.props.trsl['debt_reimbursement']}
      value_type="currency"
      currency={this.props.feasibility_settings.currency}
      />
    );
  }
}

class Interest extends React.Component{
  render(){ 
    return(
      <NonEditableForm
      item_holder={this.props.data._debt_interest} 
      s_value={this.props.s_value} 
      label={this.props.trsl['debt_interest']}
      value_type="currency"
      currency={this.props.feasibility_settings.currency}
      />
    );
  }
}

class FinalAmount extends React.Component{
  render(){ 
    return(
      <NonEditableForm
      item_holder={this.props.data._debt_final_debt} 
      s_value={this.props.s_value} 
      label={this.props.trsl['debt_final']}
      value_type="currency"
      currency={this.props.feasibility_settings.currency}
      />
    );
  }
}

class DebtService extends React.Component{
  render(){ 
    return(
      <NonEditableForm
      item_holder={add(this.props.data._debt_interest,this.props.data._debt_reimbursement)} 
      s_value={this.props.s_value} 
      label={this.props.trsl['debt_service']}
      value_type="currency"
      currency={this.props.feasibility_settings.currency}
      />
    );
  }
}

class ProjectStyleReimbursement extends React.Component{
  render(){
    return(
      <FormEdit 
      use_historic={this.props.feasibility_settings.use_historic}
      item_holder={this.props.data._debt_reimbursement}
      id="_debt_reimbursement"
      handleForm={this.props.handleRowEdit}
      label={this.props.trsl['debt_reimbursement']}
      unit={new Intl.NumberFormat('ja-JP', { style: 'currency', currency: this.props.feasibility_settings.currency}).format(0).replace(/\d./g,"")}
      />
    );
  }
}

class ProjectStyleDisbursement extends React.Component{
  render(){
    return(
      <FormEdit 
      use_historic={this.props.feasibility_settings.use_historic}
      item_holder={this.props.data._debt_disbursement}
      id="_debt_disbursement"
      handleForm={this.props.handleRowEdit}
      label={this.props.trsl['debt_disbursement']}
      unit={new Intl.NumberFormat('ja-JP', { style: 'currency', currency: this.props.feasibility_settings.currency}).format(0).replace(/\d./g,"")}
      />
    );
  }
}

export default InvestmentEntry;