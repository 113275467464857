import React, { useEffect } from 'react';
import UserContext from 'CONFIG/auth';

import './CSS/support.css'

class Alerts extends React.Component{
  static contextType = UserContext
  
  render(){
    const trsl = this.context.i18n.t('ALERT_ERRORS')
    const props={
      type:this.props.alert_system.type,
      header:this.props.alert_system.header,
      body:this.props.alert_system.body,
      trsl:trsl,
      disactivateAlert:this.props.disactivateAlert
    }
    if(this.props.alert_system.show===true){
      if(this.props.alert_system.type==="error"){
        return <NoHideAlert {...props} bk='bk_danger' />
      } else if(this.props.alert_system.type==="ok"){
        return <NoHideAlert {...props} bk='bk_success' />
      } else if(this.props.alert_system.type==="ok_auto"){
        return <AlertWithHide {...props} bk='bk_success' />
      } else if(this.props.alert_system.type==="loading")
        return <LoadingAlert {...props} bk='bk_info' />
    } else return ""
  }
}

function NoHideAlert (props){
  let header=props.trsl[props.header]
  if(!header) header=props.header

  let body=props.trsl[props.body]
  if(!body) header=props.body

  const exitAlert=()=>{
    props.disactivateAlert()
  }

  return(
    <div className={`${props.bk} alert_css`} onClick={exitAlert}>
      <h5 >{header}</h5>
      {body}
    </div>
  )
}

function AlertWithHide (props){

  let header=props.trsl[props.header]
  if(!header) header=props.header

  let body=props.trsl[props.body]
  if(!body) body=props.body

  const exitAlert=()=>{
    props.disactivateAlert()
  }

  useEffect(() => {
    const timer= setTimeout(exitAlert,2000) 
    return () => clearTimeout(timer);
  },[])
  return(
    <div className={`${props.bk} alert_css`} onClick={exitAlert}>
      <h5 >{header}</h5>
      {body}
    </div>
  )
}


function LoadingAlert (props){
  let header=props.trsl['updating_h']
  return(
    <div className={`${props.bk} alert_css`} >
      <h5 >{header}</h5>
    </div>
  )
}

export {
  Alerts
}