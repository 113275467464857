import React,  {useContext, useState} from 'react';
import {Col,Row } from 'Support/grid';
import { loadStripe } from '@stripe/stripe-js';

import UserContext from 'CONFIG/auth';
import { ToogleButtons } from 'Support/toogle_buttons';
import { RegularButton, RegularButtonSmall } from 'Support/listbuttons';
import { Card } from 'Support/warning_cards';


const STRIPE_PK=process.env.REACT_APP_STRIPE_PK
const stripePromise = loadStripe(STRIPE_PK);

function Stripe(props) {
  const user_c = useContext(UserContext)
  const trsl=user_c.i18n.t('PRIVATE_SUBSCRIPTION.Subscription_stripe')

  const handleClick = async (event) => {
    if (props.data===""){
      alert(trsl['before_checkout'])
    } else{
      // Call your backend to create the Checkout Session—see previous step
      const sessionId = props.session;
      // When the customer clicks on the button, redirect them to Checkout.
      const stripe = await stripePromise
      
      const { error } = await stripe.redirectToCheckout({
        sessionId,
      });
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `error.message`.
    }
  };
  
  const button_state=()=>{if(props.stripe_disabled===true){return true}}
  return (
    <RegularButton bk='bk_primary' label={trsl['proceed_button']} role="link" onClick={handleClick} disabled={props.stripe_disabled} />
  );
}

function StripeSubsSelector (props){
  const user_c = useContext(UserContext)
  const trsl=user_c.i18n.t('PRIVATE_SUBSCRIPTION.Subscription_stripe')
  let color="darkshade"
  let text_color=""
  let select_text=trsl['select']
  const setColor=(checker1,checker2)=>{
    if(checker1==checker2){
      color= "success"
      text_color="white"
      select_text=trsl['selected']
    }
  }
  setColor(props.id,props.selected)
  const header=props.name
  const body=
    <>
      <h5>{trsl['duration']}{props.period}{trsl['days']}</h5>
      <span className='spt_bb_pricer'>{user_c.i18n.currF(props.price, props.currency,true)}</span>
      <RegularButtonSmall bk='bk_primary' label={select_text} value={props.id} name={props.subs_type} onClick={props.handleCheckoutSession}/>
    </>
  

  return(
    <Col xs={6} md={3}>
      <Card color={color} header={header} body={body}/>
    </Col>
  )
}

function StripeCurrencySelector(props){
  const [selected, setSelected] = useState('eur')
  const user_c = useContext(UserContext)
  const trsl=user_c.i18n.t('PRIVATE_SUBSCRIPTION.Subscription_stripe')

  const options=[
    {value:'eur',option_label:'EUR'},
    {value:'usd',option_label:'USD'},
    {value:'gbp',option_label:'GBP'},
    {value:'chf',option_label:'CHF'},
  ]

  const handleChange= (event)=>{
    setSelected(event.target.value)
    props.handleCurrencySelection(event)
  }

  return(
    <Row>
      <ToogleButtons xs={12} m={12} selected={selected} toogle_data={options} label={trsl["select_currency"]} handleToogleButton={handleChange}/>
    </Row>
  )
}

class BeforeStripeCheckout extends React.Component{
  static contextType = UserContext

  render(){
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_SUBSCRIPTION.Subscription_stripe')
    let price_render=""
    if(this.props.data.price!=undefined){
      price_render= user_c.i18n.currF(this.props.data.price/100,this.props.data.currency,true)
    }
    return(
      <Row>
        <Col xs={5}>
          <h5>{trsl['subscription']}</h5>
        </Col>
        <Col xs={7}>
          {this.props.data.subscription}
        </Col>
        <Col xs={5}>
          <h5>{trsl['price']}</h5>
        </Col>
        <Col xs={7}>
          {price_render}
        </Col>
        <Col xs={12}>
          <Stripe session={this.props.data.id} stripe_disabled={this.props.stripe_disabled}/>
        </Col>      
      </Row>
    )
  }
}

export {
  Stripe,
  StripeSubsSelector,
  StripeCurrencySelector,
  BeforeStripeCheckout
}