import React from 'react';
import { Row, Col } from 'Support/grid'
import { Collapse} from 'react-bootstrap';

import DashboardTable from './dashboard_tables';
import Graphs from './dashboard_graphs';
import ExcelDownload from './dashboard_excel';
import {GenerateVersionPPTReport} from 'PRIVATE/BUDGET PROJECT/Budget Project Edit/export_ppt';

import {calResults} from 'CALCULATIONS/Budget_calculations/cal_incomestatement';

import ApiConnect from 'CONFIG/axios';
import UserContext from 'CONFIG/auth';
import Loader from 'Support/loader';
import { Dropdown } from 'Support/inputforms';

import color_sets from 'JSON/brand_color_sets.json';
import 'CSS/dashboard_style.css'
import { RegularButtonSmall } from 'Support/listbuttons';

class Dashboard extends React.Component{
  static contextType = UserContext

  constructor(props) {
    super(props);
    this.state = {
      data:"",
      center_filter:"---",
      loaded:false,
      visuals:true,
      reports:true,
      center_options:[]
    }
    this.manageBoard=this.manageBoard.bind(this)
  }

  componentDidMount() {
    this.updateListData()
  }
  
  //GENERAL FUNCTIONS
  updateListData=async()=>{
    const request={
      budget_id:this.props.budget_id,
      version_id:this.props.current_version.id,
    }
    try {
      const response = await ApiConnect.post('/api/budget_project/getdata', request)
      let data=response.data.version_data
      if(data.results===undefined){
        data.results={}
      }
      if(data.results._inc_st===undefined){
        data.results._inc_st={}
      }
      if(data.results._inc_st_acc===undefined){
        data.results._inc_st_acc={}
      }
      if(data.results.cf===undefined){
        data.results.cf={}
      }
      if(data.results.cf_acc===undefined){
        data.results.cf_acc={}
      }
      data=await calResults(data,this.props.budget_settings,this.state.center_filter)
      this.setState({
        data:data,
        loaded:true,
        center_options: this.props.budget_settings.centers
      })
    } catch(error) {
        alert(error);
    }
  }

  //MANAGE BOARDS FUNCTIONS
  manageBoard=(event)=>{
    let setter
    const item=event.target.name
    if(this.state[item]===true) {setter= false}
    else {setter=true}
    this.setState({
      [item]:setter
    })
  }

  handleCenterChange=async(event)=>{
    const value = event.target.value;
    let data=await calResults(this.state.data,this.props.budget_settings,value)
    this.setState({
      data:data,
      center_filter:value
    })
  }
  
  render() {
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_BUDGET.INSIDEPROJECT.Dashboards')

    let p_base=['m1','m2','m3','m4','m5','m6','m7','m8','m9','m10','m11','m12']
    let periods=[]
    for(var i=0; i < p_base.length ;i++){
      periods[i]=trsl[p_base[i]]
    }


    const props={
      trsl:trsl,
      data:this.state.data,
      budget_settings:this.props.budget_settings,
      periods:periods,
      budget_name:this.props.budget_name,
      current_version: this.props.current_version,
      manageBoard:this.manageBoard,
      handleCenterChange:this.handleCenterChange,
      visuals:this.state.visuals,
      reports:this.state.reports,
      center_filter:this.state.center_filter,
      center_options:this.state.center_options,
      user_permission:this.props.user_permission
    }
    function torender(data, i){
      return data[i];
    }
    const pages ={
      true: <ToDisplay {...props}/>,
      false: <Loader loader_message="single_dashboard"/>
    }
    var i = this.state.loaded
    const renderizing = torender(pages,i)

    return(
      <div>{renderizing}</div>
    );
  }
}

class ToDisplay extends React.Component{
  static contextType = UserContext
  render(){
    let user_c= this.context
    const locale=user_c.locale
    
    const active_color_set=color_sets[user_c.template]
    const stackpalette=[
      active_color_set.primary, 
      active_color_set.lightaccent,
      active_color_set.darkaccent,
      active_color_set.lightshade,
      active_color_set.darkshade,
      active_color_set.secondary]

    const buttonColor=(input)=>{
      if(input===true){ return "bk_primary"
      } else{
        return "bk_lightshade"
      }
    }
    const props=this.props

    let show_exportables=''
    if(this.props.user_permission==='owner' || this.props.user_permission==='edit'){
      show_exportables=
      <>
        <h4>{this.props.trsl['exporting']}</h4>
          <ExcelDownload 
          trsl={this.props.trsl} 
          data={this.props.data}
          periods={this.props.periods}
          p_name={this.props.budget_name}
          v_name={this.props.v_name}/>
          <GenerateVersionPPTReport trsl={this.props.trsl} data={this.props.data} periods={this.props.periods} settings={this.props.budget_settings} p_name={this.props.budget_name} current_version={this.props.current_version}/>
      </>
    }

    return(
      <>
        <Row className="dashboard_header_row">
          <Col xs={12} md={8}>
            <h3>{this.props.trsl['dashboard']}</h3>
          </Col>
          <Col xs={12} md={4}>
            <Row>
              <Col xs={6} md={6}>
                <RegularButtonSmall name="visuals" value={this.props.visuals} onClick={this.props.manageBoard} label={this.props.trsl['visuals']} bk={buttonColor(this.props.visuals)} />
              </Col>
              <Col xs={6} md={6}>
                <RegularButtonSmall name="reports" value={this.props.reports} onClick={this.props.manageBoard} label={this.props.trsl['reports']} bk={buttonColor(this.props.reports)} />
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={12}>
            <CenterDashboardFiltering {...props}/>
          </Col>
        </Row>
        <Collapse in={this.props.visuals}>
          <Row className="dashboard_main_separator">
            <Col xs={12}>
              <Graphs {...props} stackpalette={stackpalette}/>
            </Col>
          </Row>
        </Collapse>
        <Collapse in={this.props.reports}>
          <Row className="dashboard_main_separator">
            <Col xs={12}>
              <DashboardTable {...props}/>
            </Col>
          </Row>
        </Collapse>
        <Row>
          <Col xs={12}>
            {show_exportables}
          </Col>
        </Row>
      </>
    );
  }
}

function CenterDashboardFiltering(props){

  let options_item_holder = (props.center_options) 
  let options_item_distr=[{value:undefined,option_label:'---'}]
  if(options_item_holder !==undefined){
    options_item_holder.map(key =>{
      options_item_distr.push({value:key,option_label:key})
    })
  }
  return(
    <>
      <Dropdown xs={12} md={6} label={props.trsl['centers']} name="centers" value={props.center_filter} options={options_item_distr} onChange={props.handleCenterChange} />
    </>)

}



export default Dashboard;