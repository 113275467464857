import React from 'react';
import {Row, Col} from 'Support/grid';
import ReactGA from 'react-ga4';
import {StripeCurrencySelector, Stripe} from 'PRIVATE/ACCOUNT/subscription_stripe';
import {BuyOptions,BuyOptionsSelected} from 'Support/buy_options';
import { Dropdown } from 'Support/inputforms';
import Loader from 'Support/loader';

import ApiConnect from 'CONFIG/axios';
import UserContext from 'CONFIG/auth';
import { RegularButton } from 'Support/listbuttons';

const initial_state={
  buy_button_disabled:true,
  buy_data_id: '',
  pricing:"",
  loaded_pricing:false,
  subscription:{
    amount_to_pay:0,
    selected_duration:0,
    selected_number_of_versions:5,
    currency:"eur",
    future_validity:""
  },
  disabled_proceed:true
}

class ChangeBudgetSubscription extends React.Component{
  static contextType = UserContext

  constructor(props) {
    super(props);
    this.state = initial_state
  }

  componentDidMount=async()=>{
    this.getInititalData()
    const validity= this.calculateValidity(this.props.budget_subscription.subs_validity,30)
    let go=this.state.subscription
    go.future_validity=validity
    this.setState({
      subscription:go
    })
  }

  getInititalData=async()=>{
    const request={
    }
    try {
      const response = await ApiConnect.post('/api/budget_project/buy/getoptions', request)
      this.setState({
        pricing: response.data,
        loaded_pricing:true
      })
      ReactGA.event({category: "Budget Project", action: "Change Projet subscription"})
    } catch(error) {
        alert(error);
    }
  }
  calculateValue=(duration,versions)=>{
    const base=this.state.pricing.find(x => x.currency==="eur").base
    const dur=base.durations.find(x =>x.duration==duration)
    const version_price=base.extra_version.price
    return (Number(dur.price) + Number(version_price)*(Number(versions) - Number(dur.versions))).toFixed(2)
  }
  calculateValidity=(date,duration)=>{
    date=new Date(date)
    let new_validity=new Date()
    const today=new Date()
    if(today> date){
      new_validity=new Date(today.setDate(today.getDate()+Number(duration))) 
    } else {
      new_validity=new Date(date.setDate(date.getDate()+Number(duration)))
    }
    return new_validity
  }

  handleChangeDuration=(event)=>{
    var val =this.calculateValue(event.target.value,this.state.subscription.selected_number_of_versions)
    var validity= this.calculateValidity(this.props.budget_subscription.subs_validity,event.target.value)
    let go= this.state.subscription
    go.amount_to_pay=val
    go.selected_duration=event.target.value
    go.future_validity=validity
    var disabled_proceed=''
    if(val>0){
      disabled_proceed=false
    } else disabled_proceed=true
    this.setState({
      subscription:go,
      disabled_proceed:disabled_proceed
    })
  }

  handleChangeNumberOfVersions=(event)=>{
    var val =this.calculateValue(this.state.subscription.selected_duration, event.target.value)
    if(event.target.value>=this.props.budget_versions.length){
      let go= this.state.subscription
      go.amount_to_pay=val
      go.selected_number_of_versions=event.target.value
      var disabled_proceed=''
      if(val>0){
        disabled_proceed=false
      } else disabled_proceed=true
      this.setState({
        subscription:go,
        disabled_proceed:disabled_proceed
      })
    }
  }

  handleCurrencySelection=(event)=>{
    let go= this.state.subscription
    go.currency=event.target.value
    this.setState({
      subscription:go
    })
  }

  handleSubmitEvent=async(event)=>{
    event.preventDefault();
    const request ={
      budget_id: this.props.budget_id,
      currency:this.state.subscription.currency,
      selected_duration:this.state.subscription.selected_duration,
      selected_number_of_versions:this.state.subscription.selected_number_of_versions,
    }    
    try{
      if(this.state.subscription.selected_number_of_versions>= this.props.budget_versions.length){
        const response=await ApiConnect.post('/api/budget_project/changesubscription', request)
        this.setState({
          buy_data_id:response.data.id,
          buy_button_disabled:false
        })
      }
    } catch(error) {
      alert(`${error.response.status} ${error.response.data}`);
    }
  }

  render(){
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_BUDGET.MAIN.Budget_settings+Add_budget')

    const props={
      trsl:trsl,
      user_c:user_c,
      buy_button_disabled:this.state.buy_button_disabled,
      buy_data_id:this.state.buy_data_id,
      pricing:this.state.pricing,
      subscription:this.state.subscription,
      disabled_proceed:this.state.disabled_proceed,
      budget_subscription:this.props.budget_subscription,
      handleChangeDuration:this.handleChangeDuration,
      handleChangeNumberOfVersions:this.handleChangeNumberOfVersions,
      handleCurrencySelection:this.handleCurrencySelection,
      handleSubmitEvent:this.handleSubmitEvent
    }
    function torender(data, i){
      return data[i];
    } 

    const pages={
      true: <ExtendSubscription {...props}/>,
      false: <Loader loader_message="setting_up_projects"/>
    }
    var i = this.state.loaded_pricing
    const rendering=torender(pages,i)

    return(
      <>
        {rendering}
      </>
    )
  }
}

class ExtendSubscription extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render(){
    let base = this.props.pricing.find(x => x.currency===this.props.subscription.currency).base
    base.durations.splice(3,1,{duration:0, price:0, versions:5})
    
    const version_range = [...Array(21).keys()].map(x => { return({value:x + 5,option_label:`${this.props.trsl['versions_selected']}: ${x + 5} ---- ${this.props.trsl['extra_versions']}: ${x } ---- ${this.props.trsl['price']}: ${this.props.user_c.i18n.currF(x * base.extra_version.price,this.props.subscription.currency,true)}`})})
    
    const duration_distr= base.durations.map(it=>{
      if(it.duration==this.props.subscription.selected_duration){
        return <BuyOptionsSelected  it={it} trsl={this.props.trsl} user_c={this.props.user_c} currency={this.props.subscription.currency} handleChangeDuration={this.props.handleChangeDuration}/>
      } else return <BuyOptions  it={it} trsl={this.props.trsl} user_c={this.props.user_c} currency={this.props.subscription.currency} handleChangeDuration={this.props.handleChangeDuration}/>
    })
    return(
      <>
        <Row>
          <Col xs={12}>
            <h2>{this.props.trsl['change_subscription']}</h2>
          </Col>
          <Col xs={9}>
            <Row>
              <Col xs={12}>
                <h4>{this.props.trsl['duration']}</h4>
                <div className='add_project_buy_btn_wrapper'>
                  {duration_distr}
                </div>
              </Col>
              <Col xs={12}>
              <h4>{this.props.trsl['extend_versions']}</h4>
                <Row>
                  <Dropdown label={this.props.trsl['select_versions']} options={version_range} onChange={this.props.handleChangeNumberOfVersions}/>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={3}>
            <StripeCurrencySelector handleCurrencySelection={this.props.handleCurrencySelection}/>
            <div className='add_project_cost_wrapper'>
              <h3>{this.props.trsl['current_features']}</h3>
              <h6>{this.props.trsl['validity']}: {this.props.user_c.i18n.dateF(this.props.budget_subscription.subs_validity)}</h6>
              <h6>{this.props.trsl['versions']}: {this.props.budget_subscription.subs_max_versions}</h6>
              <div className='blanktop_15'></div>
              <h3>{this.props.trsl['future_features']}</h3>
              <h6>{this.props.trsl['added_duration']}: {this.props.subscription.selected_duration}</h6>
              <h6>{this.props.trsl['versions']}: {this.props.subscription.selected_number_of_versions}</h6>
              <h6>{this.props.trsl['validity']}: {this.props.user_c.i18n.dateF(this.props.subscription.future_validity)}</h6>
              <h6>{this.props.trsl['price']}: {this.props.user_c.i18n.currF(this.props.subscription.amount_to_pay,this.props.subscription.currency,true)}</h6>
            </div>
          </Col>
          <Col xs={6} className='blanktop_15'>
            <RegularButton bk='bk_primary' onClick={this.props.handleSubmitEvent} disabled={this.props.disabled_proceed} label={this.props.trsl['confirm_extension']} />
          </Col>
          <Col xs={6} className='blanktop_15'>
              <Stripe session={this.props.buy_data_id} stripe_disabled={this.props.buy_button_disabled}/>
          </Col>
        </Row>
      </>
    )
  }
}



class BudgetSubscriptionSelect extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render(){
    const base = this.props.pricing.find(x => x.currency===this.props.subscription.currency).base
    
    const version_range = [...Array(21).keys()].map(x => { return({value:x + 5,option_label:`${this.props.trsl['versions']}: ${x + 5} ::: ${this.props.trsl['extra_versions']}: ${x} ::: ${x * base.extra_version.price} ${this.props.subscription.currency}`})})
    
    const duration_distr= base.durations.map(it=>{
      if(it.duration==this.props.subscription.selected_duration){
        return <BuyOptionsSelected  it={it} user_c={this.props.user_c} trsl={this.props.trsl} currency={this.props.subscription.currency} handleChangeDuration={this.props.handleChangeDuration}/>
      } else return <BuyOptions  it={it} user_c={this.props.user_c} trsl={this.props.trsl} currency={this.props.subscription.currency} handleChangeDuration={this.props.handleChangeDuration}/>
    })
    return(
      <>
        <Row>
          <Col xs={12}>
            <h2>{this.props.trsl['features']}</h2>
          </Col>
          <Col xs={9}>
            <Row>
              <Col xs={12}>
                <h4>{this.props.trsl['duration']}</h4>
                <div className='add_project_buy_btn_wrapper'>
                  {duration_distr}
                </div>
              </Col>
              <Col xs={12}>
              <h4>{this.props.trsl['extend_versions']}</h4>
                <div className='add_project_buy_btn_wrapper'>
                  <Dropdown label={this.props.trsl['select_versions']} options={version_range} onChange={this.props.handleChangeNumberOfVersions}/>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={3}>
            <StripeCurrencySelector handleCurrencySelection={this.props.handleCurrencySelection}/>
            <div className='add_project_cost_wrapper'>
              <h3>{this.props.trsl['features']}</h3>
              <h6>{this.props.trsl['duration']}: {this.props.subscription.selected_duration}</h6>
              <h6>{this.props.trsl['versions_selected']}: {this.props.subscription.selected_number_of_versions}</h6>
              <h6>{this.props.trsl['price']}: {this.props.user_c.i18n.currF(this.props.subscription.amount_to_pay,this.props.subscription.currency,true)}</h6>
            </div>
          </Col>
          <Col xs={6}>
            <RegularButton bk='bk_primary' disabled label={this.props.trsl['previous']} />
          </Col>
          <Col xs={6}>
            <RegularButton bk='bk_primary' value={1} onClick={this.props.handleCreateMoveForward} label={this.props.trsl['next']}/>
          </Col>
        </Row>
      </>
    )
  }
}


class SubscriptioninBudget extends React.Component{
  static contextType = UserContext

  constructor(props){
    super(props);
    this.state = {
      extend:false,
      replace:false,
      view:false,
      selected_subscription:undefined,
      subscription_data:'',
      subscription_data_loaded:false
    }
    this.handleOpen=this.handleOpen.bind(this)
    this.handleClose=this.handleClose.bind(this)
    this.handleChangeSubscription=this.handleChangeSubscription.bind(this)
  }

  handleOpen(event){
    this.setState({
      [event.target.name]:true
    })
  }
  handleClose(event){
    this.setState({
      extend:false,
      replace:false,
      view:false
    })
  }

  handleChangeSubscription=async(event)=>{
    const value=event.target.value;
    this.setState({
      selected_subscription:value,
    });
    const request={
      subscription_id:value
    }
    try {
      const response = await ApiConnect.post('/api/user/subscription/unique/get', request)
      this.setState({
        subscription_data:response.data,
        subscription_data_loaded:true
      })
    } catch(error) {
        alert(error);
    }
  }

  render() {
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_SUBSCRIPTION.Subscription_in_project')

    let color
    let label1
    let label2
    const today=new Date()
    const deadline_date=Date.parse(this.props.budget_subscription.subs_validity)
    const datediference=parseFloat((deadline_date-today)/(1000 * 60 * 60 * 24))
    if(datediference<=0){
      color="danger"
      label1=trsl['sub_not_valid']
      label2=trsl['sub_expired']
    } else if(datediference<=2){
      color="warning"
      label1=trsl['sub_valid']
      label2=`${trsl['sub_days_to_expire']} ${parseInt(datediference)}`
    } else{
      color="success"
      label1=trsl['sub_valid']
      label2=`${trsl['sub_days_to_expire']} ${parseInt(datediference)}`
    }

    const showExtensionPossibility=(checker2)=>{
      if(checker2==='owner' || checker2==='manage_sub' || checker2==="Demo"){
        return <RegularButton label={trsl['extend_sub']} bk='bk_primary' name="extend_subs" onClick={this.props.handleGoToInProject} />
      } else{
        return <RegularButton label={trsl['extend_sub']} bk='bk_primary' name="extend_subs" onClick={this.props.handleGoToInProject} disabled={true} />
      }
    }

    const extension_renderizing=showExtensionPossibility(this.props.permission)

    return(
      <Row>
        <Col xs={12}>
          <Warning label1={label1} label2={label2} color={color}/>
          <div className='blanktop_10'>
            {trsl['validity']}: {user_c.i18n.dateF(this.props.budget_subscription.subs_validity)}
          </div>
          <div>
          {trsl['permission']}: {trsl[this.props.budget_subscription.subs_permission]}
          </div>
        </Col>
        <Col xs={12} className="blanktop_05">
          {extension_renderizing}
        </Col>
      </Row>      
    )
  }
}

class Warning extends React.Component{
  render(){
    return(
      <div className={`warning_div_outside bk_${this.props.color}`}>
        <div className={`warning_div_header bk_${this.props.color}`}>
          <h6>{this.props.label1}</h6>
        </div>
        <div className='warning_div_inside'>
          {this.props.label2}
        </div>
      </div>
    );
  }
}



export {
  ChangeBudgetSubscription,
  BudgetSubscriptionSelect,
  SubscriptioninBudget
}