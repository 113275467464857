import React from 'react';
import { Row, Col } from 'Support/grid';
import { Collapse} from 'react-bootstrap';

import DashboardWarning from './dashboard_warnings';
import DashboardTable from './dashboard_tables';
import Graphs from './dashboard_graphs';
import ExcelDownload from './dashboard_excel';
import {GenerateVersionPPTReport} from 'PRIVATE/PROJECT/Export Central/export_ppt';

import {calResults} from 'CALCULATIONS/cal_incomestatement';

import UserContext from 'CONFIG/auth';
import Loader from 'Support/loader';

import color_sets from 'JSON/brand_color_sets.json';
import 'CSS/dashboard_style.css'
import { ActivateButtonPositiveNegative, } from 'Support/listbuttons';

class Dashboard extends React.Component{
  static contextType = UserContext

  constructor(props) {
    super(props);
    this.state = {
      data:"",
      loaded:false,
      warnings:true,
      visuals:true,
      reports:true
    }
    this.manageBoard=this.manageBoard.bind(this)
  }

  componentDidMount() {
    this.updateListData()
  }
  
  //GENERAL FUNCTIONS
  updateListData=async()=>{
    const request={
      version_id:this.props.version_id,
      project_id:this.props.project_id,
      settings:this.props.feasibility_settings
    }
    try {
      const edited_data=await calResults(this.props.version_data,this.props.feasibility_settings)
      this.setState({
        data:edited_data,
        loaded:true
      })
    } catch(error) {
        alert(error);
    }
  }

  //MANAGE BOARDS FUNCTIONS
  manageBoard=(event)=>{
    let setter
    const item=event.target.name
    if(this.state[item]===true) {setter= false}
    else {setter=true}
    this.setState({
      [item]:setter
    })
  }
  
  render() {
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_PROJECT.PROJECT_FEASIBILITY.Dashboards')
    const props={
      trsl:trsl,
      data:this.state.data,
      feasibility_settings:this.props.feasibility_settings,
      p_name:this.props.p_name,
      v_name:this.props.v_name,
      manageBoard:this.manageBoard,
      warnings:this.state.warnings,
      visuals:this.state.visuals,
      reports:this.state.reports,
      user_permission:this.props.user_permission,
      years:this.props.feasibility_settings.historic_years.concat(this.props.feasibility_settings.years)
    }
    function torender(data, i){
      return data[i];
    }
    const pages ={
      true: <ToDisplay {...props}/>,
      false: <Loader loader_message="single_dashboard"/>
    }
    var i = this.state.loaded
    const renderizing = torender(pages,i)

    return(
      <div>{renderizing}</div>
    );
  }
}

class ToDisplay extends React.Component{
  static contextType = UserContext
  render(){
    let user_c= this.context
    const locale=user_c.locale
    
    const active_color_set=color_sets[user_c.template]
    const stackpalette=[
      active_color_set.primary, 
      active_color_set.lightaccent,
      active_color_set.darkaccent,
      active_color_set.lightshade,
      active_color_set.darkshade,
      active_color_set.secondary]

    const props=this.props

    let show_exportables=''
    if(this.props.user_permission==='owner' || this.props.user_permission==='edit'){
      show_exportables=
      <>
        <h3>
          {this.props.trsl['exporting']}
        </h3>
        <div className='blanktop_10'>
          {this.props.trsl['exporting_notice']}
        </div>
        <ExcelDownload 
        trsl={this.props.trsl} 
        data={this.props.data}
        feasibility_settings={this.props.feasibility_settings}
        use_historic={this.props.feasibility_settings.user_historic}
        years={this.props.feasibility_settings.years}
        h_years={this.props.feasibility_settings.historic_years.concat(this.props.feasibility_settings.years)}
        p_name={this.props.p_name}
        v_name={this.props.v_name}/>
        <GenerateVersionPPTReport
        trsl={user_c.i18n.t('PRIVATE_PROJECT.Exporting')} 
        org={user_c.template}
        data={this.props.data}
        years={this.props.feasibility_settings.years}
        settings={this.props.feasibility_settings}
        locale={locale}
        p_name={this.props.p_name}
        v_name={this.props.v_name} />
      </>
    }

    return(
      <>
        <Row className="dashboard_header_row grid_middle">
          <Col xs={12} md={6}>
            <h2>{this.props.trsl['dashboard']}</h2>
          </Col>
          <Col xs={12} md={6}>
            <Row>
              <Col xs={4}>
                <ActivateButtonPositiveNegative label={this.props.trsl['warnings']} name="warnings" value={this.props.warnings} onClick={this.props.manageBoard}  />                  
              </Col>
              <Col xs={4} md={4}>
                <ActivateButtonPositiveNegative name="visuals" value={this.props.visuals} onClick={this.props.manageBoard} label={this.props.trsl['visuals']} />
              </Col>
              <Col xs={4} md={4}>
                <ActivateButtonPositiveNegative name="reports" value={this.props.reports} onClick={this.props.manageBoard} label={this.props.trsl['reports']} />
              </Col>
            </Row>
          </Col>
        </Row>
        <Collapse in={this.props.warnings}>
          <Row className="dashboard_main_separator">
            <DashboardWarning {...props}/>
          </Row>
        </Collapse>
        <Collapse in={this.props.visuals}>
          <Row className="dashboard_main_separator">
            <Col xs={12}>
              <Graphs {...props} stackpalette={stackpalette}/>
            </Col>
          </Row>
        </Collapse>
        <Collapse in={this.props.reports}>
          <Row className="dashboard_main_separator">
            <Col xs={12}>
              <DashboardTable {...props}/>
            </Col>
          </Row>
        </Collapse>
        <Row>
          <Col xs={12}>
            {show_exportables}
          </Col>
        </Row>
      </>
    );
  }
}

export default Dashboard;