import React from 'react';
import {Image} from 'react-bootstrap';
import {Grid,Row, Col} from 'Support/grid';
import UserContext from 'CONFIG/auth';
import casflo_logo from 'TEMPLATES/CASFLO/Images/casflo_horizontal_white.png';
import {UserButton,LogOutButton, MainMenuButton} from 'Support/listbuttons';
import EU from 'images/useful/EU.png';
import PT from 'images/useful/PT.jpeg';


class HeaderCASFLO extends React.Component{
  render(){
    const avatar=this.props.avatar

    const screen_width = (window.innerWidth > 0) ? window.innerWidth : window.width;
    if(screen_width>756){
        <Col xs={8} md={1}>
          <Row className="header_profile">
            <Col xs={7}>
              <button name="" onClick={this.props.handlePrincipalButtonRouting}>
              <img 
              className="header_profile_image" 
              src={require(`images/avatars/user-${avatar}.png`).default} />
              </button>
            </Col>
          </Row>
        </Col>
    }
    return(    
      <Grid fluid className="header_brand">
        <Row noGutters>
          <Col xs={12}>
            <Row middle="xs">
              <Col xs={6} md={9} onClick={this.props.handleGoToGeneralRoot}>
              <button className='header_logo' onClick={this.props.handlePrincipalButtonRouting}>
                <img className='header_logo_img'src={casflo_logo} />
                </button>
              </Col>
              <Col xs={6} md={3}>
                <div className='hd_div'>
                  <MainMenuButton name="" onClick={this.props.handlePrincipalButtonRouting}/>
                  <UserButton name="/app/profile" onClick={this.props.handlePrincipalButtonRouting}/>
                  <LogOutButton name="/app/profile" onClick={this.props.handleLogOut}/>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Grid>
    );
  }
}

class FooterCASFLO extends React.Component{
  static contextType = UserContext
  render(){
    let user_c= this.context
    return(
      <Row middle="xs" >
        <Col lg={2} md={2} xs={6}>
          <h6>{user_c.i18n.t('PRIVATE.Header.made')}</h6>
        </Col>
        <Col lg={6} md={6} xs={6}>   
          <Image src={PT} className="footer_flag"/>
          <Image src={EU} className="footer_flag"/>
        </Col>
        <Col lg={4} md={4}xs={12}>
          <h6><a href="https://casflo-app.com/terms-of-service/" target="blank">{user_c.i18n.t('PRIVATE.Header.terms')}</a></h6>
        </Col>      
      </Row>
    )
  }
}



export {
  HeaderCASFLO,
  FooterCASFLO
}