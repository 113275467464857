import React from 'react';
import {Grid, Row, Col} from 'Support/grid';
import {Image, Form,Button, Card} from 'react-bootstrap';


import ApiConnect from 'CONFIG/axios';
import UserContext from '../CONFIG/auth';

import {PasswordRecoveryNewPasswordViseeon} from 'TEMPLATES/Viseeon/public_templates'

import logo2 from 'images/logo/logo.png';
import './public.css';


class PasswordRecoveryNewPassword extends React.Component{
  static contextType = UserContext

  constructor(props){
    super(props);
    this.state={
      password:"",
      retype_password:"",
      email:"",
      token:"",
      toast:{
        show:false,
        toast_header:"",
        toast_body:"",
      }
    }
    this.handleRecoveryRequest=this.handleRecoveryRequest.bind(this);
  }

  componentDidMount(){
    this.autoActivate()
  }

  autoActivate=async()=>{
  var activ_u=window.location.href
  const index_0=activ_u.search("/passwordrecovery/")
  const cut_1=activ_u.slice(index_0+18)
  const index_1=cut_1.search("/")
  const email=cut_1.substr(0,index_1)
  const token=cut_1.slice(index_1+1)
    this.setState ({
      email:email,
      token:token,
    })
  }

  handleChange=(event)=>{
    let go =this.state
    go[event.target.name]=event.target.value
    this.setState({go})
  }

  handleRecoveryRequest=async(event)=>{
    event.preventDefault();
    const request={
      password:this.state.password,
      email:this.state.email,
      token:this.state.token
    }
    try{
      await ApiConnect.post('/user/resetpass', request);
      window.location.href=('/login')  
    } catch (error) {
      if(error.response !==undefined){
        this.props.activateAlert('error',"pass_req_err_h",error.response.data)
      } else {this.props.activateAlert('error','pass_req_err_h','general_error')}
    }
  }

  handleLanguage=()=>{
    const user_c=this.context
    const bla='en'
    user_c.setLocale(bla)  
  }

  AlertFunctions={
    setAlert:(show, header,body)=>{
      this.setState({
        toast:{
          show:show,
          header: header,
          body:body,
        }
      })
    },
    unsetalert:()=>{
      this.setState({
        toast:{
          show:false
        }
      })
    }
  }

  render(){
    let user_c= this.context

    const props={
      logged:this.state.logged,
      handleRecoveryRequest:this.handleRecoveryRequest,
      handleChange:this.handleChange,
      toast:this.state.toast,
      AlertFunctions:this.AlertFunctions
    }
    if(user_c.template==='viseeon'){
      return(
        <> aa
          <PasswordRecoveryNewPasswordViseeon {...props}/>
        </>
      )
    } else{
      return(
        <>aa
          <PasswordRecoveryNewPasswordCASFLO {...props}/>
        </>
      )
    }
  }
}

class PasswordRecoveryNewPasswordCASFLO extends React.Component{
  static contextType = UserContext
  render(){
    let user_c= this.context
    return(
      <Grid fluid className="main_block public_box">
        <Row >
          <Col xs={12}>
            {this.props.logged}
          </Col>
          <Col md={4}>
            
          </Col>
          <Col md={4}>
          <Image src={logo2} className="public_logo"/>
          <Card>
            <Card.Header>
              {user_c.i18n.t('PUBLIC.Recovery_request.recovery')}
            </Card.Header>
            <Card.Body>
              <Form onSubmit={this.props.handleRecoveryRequest}>
                <Form.Group onChange={this.props.handleChange}>
                  <Form.Control required type="password" name="password" placeholder={user_c.i18n.t('PUBLIC.Recovery_request.type_password')} />
                  <Form.Control required type="password" name="retype_password" placeholder={user_c.i18n.t('PUBLIC.Recovery_request.retype_password')} />
                </Form.Group>
                <Button block variant="primary" type="submit" >
                {user_c.i18n.t('PUBLIC.Recovery_request.button_recovery')}
                </Button>
              </Form>
              <Card.Text>
                <h6>{user_c.i18n.t('USER.Edit_profile.password_rules')}</h6>
                {user_c.i18n.t('PUBLIC.Recovery_request.password_rules')}
              </Card.Text>
            </Card.Body>
          </Card>
          </Col>
          <Col md={4}></Col>
        </Row>
        <Row className="public_second_row public_white_font">
          <Col md={4}></Col>
          <Col md={4}>
            <h6>{user_c.i18n.t('PUBLIC.Recovery.alternatives_to_recovery')}</h6>
          </Col>
          <Col md={4}></Col>
          <Col md={4}></Col>
          <Col md={2}>
            <Button block variant="secondary" href="/register" >
              {user_c.i18n.t('PUBLIC.Recovery.button_register')}
            </Button>
          </Col>
          <Col md={2}>
            <Button block variant="secondary" href="/login">
            {user_c.i18n.t('PUBLIC.Recovery.button_login')}
            </Button>         
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default PasswordRecoveryNewPassword;