import React from 'react';
import { Row} from 'Support/grid'
import {dotDivide, subtract, dotMultiply} from 'mathjs';

import { BarGraph, StackedBarGraph, LineGraphs,dataTransformForGrouping } from 'Support/graphs';
import { MiniCard } from 'Support/warning_cards';

const graph_width=6
const graphcard_color='white'

const CurrencyContext= React.createContext();

function dataTransformWithColor(y_input,x_input){
  let bug= []
  function colorSetter(input){
    if(input>=0){
      return 1
    } else{
      return 0
    }
  }
  for (var i=0;i<y_input.length;i++){
    bug[i]={
      x:x_input[i],
      y:y_input[i],
      order: i,
      color:colorSetter(y_input[i])
    }
  }
  return bug
}

function validateRatios(input){
  let array_to_return=new Array(input.length).fill(0)
  for(var i=0;i<input.length;i++){
    if(isFinite(input[i])){
      array_to_return[i]=input[i]
    } else {
      array_to_return[i]=0
    }
  }
  return array_to_return
}


class Graphs extends React.Component{

  render(){
    const props=this.props

    return(
      <CurrencyContext.Provider value={this.props.budget_settings.currency}>
        <h4>{this.props.trsl['visuals']}</h4>
            <Row>
              <RevenueGraph {...props} />
              <RevenueGraphAccumulated {...props} />
              <EBITDAGraph {...props} />
              <EBITDAGraphAccumulated {...props} />
              <CostsGraph {...props} />
              <CostsGraphAccumulated {...props} />
              <GrossMarginGraph {...props} />
            </Row>
      </CurrencyContext.Provider>
    );
  }
}

class RevenueGraph extends React.Component{
  render(){
    const datur=dataTransformWithColor(this.props.data.results._inc_st.revenue,this.props.periods)
    return(
      <MiniCard xs={12} md={graph_width} color={graphcard_color} header={this.props.trsl['revenues']} body={<BarGraph data={datur} />}/>
    );
  }
}

class EBITDAGraph extends React.Component{
  render(){
    const datur=dataTransformWithColor(this.props.data.results._inc_st.ebitda,this.props.periods)
    return(
      <MiniCard xs={12} md={graph_width} color={graphcard_color} header={this.props.trsl['ebitda']} body={<BarGraph  data={datur} />}/>
    );
  }
}

class RevenueGraphAccumulated extends React.Component{
  render(){
    const datur=dataTransformWithColor(this.props.data.results._inc_st_acc.revenue,this.props.periods)
    return(
      <MiniCard xs={12} md={graph_width} color={graphcard_color} header={this.props.trsl['accumulated_revenue']} body={<BarGraph  data={datur} />}/>
    );
  }
}

class EBITDAGraphAccumulated extends React.Component{
  render(){
    const datur=dataTransformWithColor(this.props.data.results._inc_st_acc.ebitda,this.props.periods)
    return(
      <MiniCard xs={12} md={graph_width} color={graphcard_color} header={this.props.trsl['ebitda_acc']} body={<BarGraph  data={datur} />}/>
    );
  }
}

function CostsGraph(props){
  const data=[props.data.results._inc_st.cogs, props.data.results._inc_st.operating_expenses, props.data.results._inc_st.hr]
  const legend = [props.trsl['cogs'], props.trsl['operating_exp'], props.trsl['employees']] 
  const color= [props.stackpalette[0], props.stackpalette[1], props.stackpalette[2]]
  const set1=dataTransformForGrouping(data, props.periods, legend, color)
  return(
    <MiniCard xs={12} md={graph_width} color={graphcard_color} header={props.trsl['costs']} body={<StackedBarGraph data={set1}/>}/>
  );
}

function CostsGraphAccumulated (props) {
  const data=[props.data.results._inc_st_acc.cogs, props.data.results._inc_st_acc.operating_expenses, props.data.results._inc_st_acc.hr]
  const legend = [props.trsl['cogs'], props.trsl['operating_exp'], props.trsl['employees']] 
  const color= [props.stackpalette[0], props.stackpalette[1], props.stackpalette[2]]
  const set1=dataTransformForGrouping(data, props.periods, legend, color)
  return(
    <MiniCard xs={12} md={graph_width} color={graphcard_color} header={props.trsl['costs_acc']} body={<StackedBarGraph data={set1}/>}/>
  );
}

function GrossMarginGraph(props){
  const result1=dotMultiply(subtract(dotDivide(props.data.results._inc_st.cogs,props.data.results._inc_st.revenue),1),-1)
  const result=validateRatios(result1)
  const datur=dataTransformWithColor(result,props.periods)
  return(
    <MiniCard xs={12} md={graph_width} color={graphcard_color} header={props.trsl['gross_margin']} body={<LineGraphs data={datur} for_min={result}/>}/>
  );
}


export default Graphs;