import React from 'react';
import {Grid, Row, Col} from 'Support/grid'
import { Route, Routes } from "react-router-dom";

import BugetList from './list';
import AddBudget from './add';
import BudgetEdit from './main';
import BudgetSettings from './settings';
import BudgetPane from '../Budget Project Edit/budget_pane';
import {ChangeBudgetSubscription} from './subscription';
import {BudgetProjectCreator} from './budget_creator';
import ShowTemplates from './templates'

import UserContext from 'CONFIG/auth'
import Session from 'CONFIG/session'
import ApiConnect from 'CONFIG/axios';

const route="/app/budget_project/"

class BudgetRoot extends React.Component{
  static contextType = UserContext
  constructor(props) {
    super(props);
    this.state = {
      budget_id:Session.getBudget(),
      budget_loaded:false,
      budget_data:"",
      budget_settings:"",
      budget_subscription:'',
      budget_versions:[],
      current_version:{
        id:"",
        name:"",
        year:""
      },
      permission:'',
      list:{
        owned_projects:undefined,
        colaborating_projects:undefined,
        viewing_projects:undefined,
        list_loaded:false
      }
      
    }
  }

  componentDidMount=async()=>{
    if(this.state.budget_id !== null && this.state.budget_id != "undefined"){
      await this.handleGetProjectData(this.state.budget_id)
      this.setState({
        budget_loaded:true
      })
    } 
      this.updateListData()
  }

  updateListData=async()=>{
    const request={}
    try{
      const response= await ApiConnect.post('/api/budget_project/list', request)
      this.setState({
        list:{
          list_loaded:true,
          owned_projects: response.data.response_by_owner,
          colaborating_projects: response.data.response_by_colaborator,
          viewer_projects:response.data.response_by_viewer,  
        } 
      });
    } catch (error) {
      alert(error);
    }
  }

  handleGetProjectData=async(value)=>{
    const request={
      budget_id: value, 
    }
    try {
      const response = await ApiConnect.post('/api/budget_project/get_main', request)
      this.setState({
        budget_id:value,
        budget_data:response.data.budget_data,
        budget_settings:response.data.budget_settings,
        budget_subscription:response.data.budget_subscription,
        budget_versions:response.data.budget_versions,
        permission:response.data.permission
      })
    }catch(error) {
      alert(error);
    }
  }

  handleGoToBudget=async(event)=>{
    const value = event.target.value
    const name = event.target.name
    await this.handleGetProjectData(value)
    this.setState({
      budget_loaded:true
    })
    Session.setVersionRender("revenue")
    Session.setBudget(value)
    this.props.handlePrincipalRouting(route+name)
  }

  handleGoToInProject=(event)=>{
    const name = event.target.name
    this.props.handlePrincipalRouting(route+name)
  }


  handleBudgetRouting=async(value,id,name,year)=>{

    Session.setVersionRender("revenue")
    this.props.handlePrincipalRouting(route+value)
    this.setState({
      current_version:{
        id:id,
        name:name,
        year:year
      }
    })
  }

  handleGoToAddProject=()=>{
    this.props.handlePrincipalRouting(route+'add')
  }

  handleNewProject =async(name, description, settings, subscription)=>{
    let content= new BudgetProjectCreator(name, description, settings)
    const request ={
      content,
      user_id:this.props.user_id,
      subscription: subscription
    }    
    try{
      await ApiConnect.post('/api/budget_project/create', request)
      this.setState({ 
        budget_root_render: "main",
        list:{
          owned_projects:undefined,
          colaborating_projects:undefined,
          viewing_projects:undefined,
          list_loaded:false
        }
      })
      this.props.handlePrincipalRouting(route)
      this.updateListData()
    } catch(error) {
      alert(`${error.response.status} ${error.response.data}`);
    }
  }

  handleDeleteProject= async(event)=>{
    const value=event.target.value
    const pgetter=value
    try{
        const response =await ApiConnect.post('/api/budget_project/delete', {budget_id:pgetter})
        alert(response.data);
        this.updateListData()
    }catch(error) {
        alert(error);
    }
}

  //////
  //Version functions
  /////
  handleAddVersion =async(name,year, description)=>{
    const request={
      budget_id: this.state.budget_id,
      v_name:name,
      v_year:year,
      v_description:description,
    }
    try{
      const response= await ApiConnect.post(`/api/budget_project/version/create`, request)
      this.props.activateAlert( 'ok_auto','','created')
      this.handleGetProjectData(this.state.budget_id)
    } catch(error) {
      this.props.activateAlert( 'error',error.response.status,error.response.data)
    }
  }

  handleDeleteVersion=async(event)=>{
    const vgetter=event.target.value
    try{
      const response= await ApiConnect.post(`/api/budget_project/version/delete`, {version_id:vgetter, budget_id:this.state.budget_id })
      this.props.activateAlert( 'ok_auto','','deleted')
      this.handleGetProjectData(this.state.budget_id)
    } catch(error) {
      this.props.activateAlertt( 'error',error.response.status,error.response.data)
    }
  }

  handleDuplicateVersion=async(event)=>{
    const vgetter=event.target.value
    const pgetter=this.state.budget_id
    try{
      const response= await ApiConnect.post(`/api/budget_project/version/duplicate`, {version_id:vgetter, budget_id:pgetter })
      this.props.activateAlert('ok_auto','','duplicated')
      this.handleGetProjectData(this.state.budget_id)
    } catch(error) {
      this.props.activateAlert('error',error.response.status,error.response.data)
    }
  }

  handleSetExportingRawData=async(value)=>{
    const request={
      budget_id: this.state.budget_id, 
    }
    try {
      const response = await ApiConnect.post('/api/budget_project/exporting/set_raw', request)
      let go= this.state.budget_data
      go.budget_exporting=response.data
      this.setState({
        budget_data:go,
      })
    }catch(error) {
      alert(error);
    }
  }

  handleReseExportingPass=async(value)=>{
    const request={
      budget_id: this.state.budget_id, 
    }
    try {
      const response = await ApiConnect.post('/api/budget_project/exporting/reset_key', request)
      let go= this.state.budget_data
      go.budget_exporting=response.data
      this.setState({
        budget_data:go,
      })
    }catch(error) {
      alert(error);
    }
  }

       
  render(){
    const props={
      budget_id:this.state.budget_id,
      budget_loaded:this.state.budget_loaded,
      budget_data:this.state.budget_data,
      budget_settings:this.state.budget_settings,
      budget_subscription:this.state.budget_subscription,
      budget_versions:this.state.budget_versions,
      current_version:this.state.current_version,
      permission:this.state.permission,
      list:this.state.list,
      handleGoToBudget:this.handleGoToBudget,
      handleGoToInProject:this.handleGoToInProject,
      handleBudgetRouting:this.handleBudgetRouting,
      handleGoToAddProject:this.handleGoToAddProject,
      handleNewProject:this.handleNewProject,
      handleDeleteProject:this.handleDeleteProject,
      handleAddVersion:this.handleAddVersion,
      handleDeleteVersion:this.handleDeleteVersion,
      handleDuplicateVersion:this.handleDuplicateVersion,
      handlePrincipalRouting:this.props.handlePrincipalRouting,
      handleSetExportingRawData:this.handleSetExportingRawData,
      handleReseExportingPass:this.handleReseExportingPass,
      AF:this.props.AF
    }

    return(
      <div>
        <ToDisplay {...props} />
      </div>
    )
  }
}

class ToDisplay extends React.Component{
  render(){
    const props=this.props

    return(
      <Grid >
        <Row className="blanktop_10">
          <Col xs={12}>
            <Routes>
              <Route index element={<BugetList {...props}/>}/>
              <Route path={`list`} element={<BugetList {...props}/>}/>
              <Route path={`main`} element={<BudgetEdit {...props}/>}/>
              <Route path={`settings`} element={<BudgetSettings {...props}/>}/>
              <Route path={`edit/*`} element={<BudgetPane {...props}/>}/>
              <Route path={`add`} element={<AddBudget {...props}/>}/>
              <Route path={`extend_subs`} element={<ChangeBudgetSubscription {...props}/>}/>
              <Route path={`templates`} element={<ShowTemplates {...props}/>}/>
            </Routes>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default BudgetRoot;