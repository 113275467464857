import React from 'react';
import {Form, Button, Collapse} from 'react-bootstrap';
import {Row, Col} from 'Support/grid';
import { SingleInputFormWithConfirm } from './inputforms';
import { RegularButton, RegularButtonSmall } from './listbuttons';

import UserContext from 'CONFIG/auth';

class AddItem extends React.Component{
  static contextType = UserContext
  constructor(props) {
    super(props);
    this.state = {
      open:false,
      item_name:""
    }
    this.handleOpen=this.handleOpen.bind(this)
    this.handleChange=this.handleChange.bind(this)
    this.handleSubmitEvent=this.handleSubmitEvent.bind(this)
  }

    handleOpen(event){
      const newer=!(this.state.open);
      this.setState({
        open:newer
      })
    }

  handleChange(event){
    const value=event.target.value;
    this.setState({
      item_name:value
    });
  }

  handleSubmitEvent(event){
    this.props.handleAdd(this.state.item_name, this.props.element2);
    this.setState({
      item_name:"",
      open:false
    })
  }

  render(){
    let user_c= this.context

    let edit_ony_permission=true
    if(this.props.user_permission=="owner" || this.props.user_permission=="edit"){
      edit_ony_permission= false
    }
    return (
      <Row>
        <SingleInputFormWithConfirm xs={12} md={12} label={this.props.add_item} btn_label={user_c.i18n.t('ADD_ITEM.add')} bk='bk_darkaccent' name="add_item" placeholder={user_c.i18n.t('ADD_ITEM.name_it')} handleEditItemChange={this.handleChange} onClick={this.handleSubmitEvent} disabled={this.props.disabled}/>
      </Row>
    );
  }
}

class AddUser extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      open:false,
      item_name:""
    }
    this.handleOpen=this.handleOpen.bind(this)
    this.handleChange=this.handleChange.bind(this)
    this.handleSubmitEvent=this.handleSubmitEvent.bind(this)
  }

    handleOpen(event){
        const newer=!(this.state.open);
        this.setState({
            open:newer
        })
    }

    handleChange(event){
        const value=event.target.value.toLowerCase();
        this.setState({
            item_name:value
        });
    }

    handleSubmitEvent(event){
        event.preventDefault();
        this.props.handleAdd(this.state.item_name);
        this.setState({
            item_name:"",
            open:false
        })
    }

  render(){
    return (
      <>
        <Col className="add_item_block" xs={7}>
          <RegularButtonSmall label={this.props.trsl['add_user']} bk='bk_primary' onClick={this.handleOpen}/>
        </Col>
        <Col xs={5}></Col>
        <Collapse in={this.state.open}>
        <Form onSubmit={this.handleSubmitEvent}>
            <>
              <Form.Group as={Row}>
                <Col xs={5}>
                  <Form.Label>{this.props.trsl['enter_user_email']}</Form.Label>
                  <Form.Control required type="email" name="add_email" onChange={this.handleChange}/>
                </Col>
                <Col xs={2}>
                  <Button block variant="primary" type="submit">
                    {this.props.trsl['add_button']}
                  </Button>
                </Col>
              </Form.Group>
            </>
          </Form>
        </Collapse>
      </>
    );
  }
}


export {
    AddItem,
    AddUser
};