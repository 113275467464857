import {add,dotMultiply} from "mathjs"

function editValues(input,locale,maximum){
  return (new Intl.NumberFormat(locale, { maximumFractionDigits:maximum,minimumFractionDigits:0 }).format(input))
}

function addBulletText(input){
  const bulletizer=input.map(value =>{
    return {text: value,options:{breakLine:true}} 
  })
  return bulletizer
}

function accumulateArrayElements(input){
  let sum=[]
  if(input!==undefined){
    for(var i=0;i<input.length;i++){
      if(i===0){
        sum[i]=input[i]
      } else{
        sum[i] =add(input[i],sum[i-1])
      }
    }
  }
  return sum
}

function rowCreator(label,inputs,bkcolor,fsize,tcolor,maximum_digits){
  const table_row=[]
  const col_size=(4/inputs.length)
  table_row[0]={text: label, options: { align: "left", fill:bkcolor, colW:1, fontSize:fsize,color:tcolor }}
  for(let i=1;i<(inputs.length+1);i++){
    table_row[i]={text: editValues(inputs[i-1],'en',maximum_digits), options: { align: "right", fill:bkcolor, colW:col_size, fontSize:fsize, color:tcolor}}
  }
  return table_row
}

function rowCreatorForHeader(label,inputs,fsize,tcolor, bcolor){
  const table_row=[]
  const col_size=(4/inputs.length)
  table_row[0]={text: label, options: { 
    align: "left", 
    fill:"", 
    colW:1, 
    fontSize:fsize,
    color:tcolor,
    border:{type:'dash',color:bcolor,pt:.5}
  }}
  for(let i=1;i<(inputs.length+1);i++){
    let val =""
    if(isNaN(inputs[i-1])){val=inputs[i-1]} else {val=(inputs[i-1]).toFixed(0)}
    table_row[i]={text: val, options: {
      align: "center", 
      fill:"", 
      colW:col_size, 
      fontSize:fsize, 
      color:tcolor,
      border:{type:'dash',color:bcolor,pt:.5}
    }}
  }
  return table_row
}

function sizeOnizer(inputs){
  const table_row_size=[]
  table_row_size[0]=2
  for(let i=1;i<(inputs.length+1);i++){
    table_row_size[i]=(7.5/inputs.length)
  }
  return table_row_size
}

function TableHeader(trsl,periods,theme){
  return(
    rowCreatorForHeader(trsl,periods,12,theme.cs.darktext,"")
  )
}

function TableRow(trsl,values,theme){
  return(
    rowCreator(trsl,values,"",7,theme.cs.darktext,0)
  )
}

function TableRowL1(trsl,values, theme){
  return(
    rowCreator(trsl,values,theme.cs.lightaccent,7,theme.cs.darktext,0)
  )
}

function TableRowL2(trsl,values, theme){
  return(
    rowCreator(trsl,values,theme.cs.darkaccent,7,theme.cs.lighttext,0)
  )
}

function TableRowDecimals(trsl,values,tcolor){
  let n_arr=dotMultiply(values,100)
  return(
    rowCreator(trsl,n_arr,'',8,tcolor,2)
  )
}

function PestelRow(label,inputs,bkcolor,f1,f2,theme){
  const color_setup={
    "-3":"ff9800",
    "-2":"f9ac5d",
    "-1":"ecc199",
    "0":"d5d5d5",
    "1":"aac7b3",
    "2":"7eb992",
    "3":"4baa71",
  }
  const table_row=[]
  table_row[0]=[{text: label, options: { align: "center", fill:bkcolor, fontSize:f1, color:theme.cs.lighttext }}]
  for(let i=1;i<(inputs.length+1);i++){
    table_row[i]=[{text: inputs[i-1].descr, options: { align: "center", fill:color_setup[inputs[i-1].impact], fontSize:f2}}]
  }
  return table_row
}

export {
  editValues,
  addBulletText,
  accumulateArrayElements,
  sizeOnizer,
  TableHeader,
  TableRowL1,
  TableRowL2,
  TableRow,
  TableRowDecimals,
  PestelRow
}