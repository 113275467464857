import React from 'react';
import {Grid, Row, Col} from 'Support/grid'
import { Image} from 'react-bootstrap';

import CasfloPartnerships from './casflo_partnerships';

import UserContext from 'CONFIG/auth';
import './main_screen_style.css'

import instagram_img from 'images/useful/instagram.svg';
import facebook_img from 'images/useful/facebook.svg';
import linkedin_img from 'images/useful/linkedin.svg';

import {MainScreenViseeonRender} from 'TEMPLATES/Viseeon/main_screen'
import {MainScreenBazaarRender} from 'TEMPLATES/BAZAAR/main_screen'
import { Button, RegularButton, RegularLinkButton } from 'Support/listbuttons';
import { DetailedCard } from 'Support/warning_cards';

class MainScreen extends React.Component{
  static contextType = UserContext

  render(){
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE.Main')

    const props={
      user_c:user_c,
      trsl:trsl,
      handlePrincipalButtonRouting:this.props.handlePrincipalButtonRouting
    }
    if(user_c.template==='bazaar'){
      return(
        <>
          <MainScreenBazaarRender {...props}/>
          <LearningCenterResources trsl={trsl}/>
        </>
      )
    } else {
      return(
        <>
          <MainScreenOptions {...props}/>
          <SocialCasflo />
          <LearningCenterResources trsl={trsl}/>
        </>
      )
    }
  }
}

function MainScreenOptions(props){
  const showSuperAdminButton=(input)=>{
    if(input==='SuperAdministrator'){
      return(
        <Col xs={12} md={3} className='blanktop_05'>
          <Button label={props.trsl['app_manager_button']} bk='bk_info' className="intro_screen_button" name="/app/manager" value={0} onClick={props.handlePrincipalButtonRouting}/>
        </Col>
      )
    }
  }
  const render_super_admin=showSuperAdminButton(props.user_c.user_type)

  function showPartnershipButton(input){
    if(input==='SuperAdministrator' || input==='Administrator' ||input==='Partner'){
      return(
        <Col xs={12} md={3} className='blanktop_05'>
          <Button label={props.trsl['partnership_button']} bk='bk_info' className="intro_screen_button" name="/app/partners" value={0} onClick={props.handlePrincipalButtonRouting}/>
        </Col>  
      )
    }
  }
  const show_partnership= showPartnershipButton(props.user_c.user_type)

  const screen_width = (window.innerWidth > 0) ? window.innerWidth : window.width;

  let show_left_intro=''
  let background_image=''
  if(screen_width>756){
    show_left_intro=(
        <div className="main_screen_Welcome">
          {props.trsl['main_string']}
        </div>
    )
    background_image='intro_screen_main_row'
  }

  return(
    <div className={`placer2 ${background_image}`}>
      <Grid>
        <Row>
          <Col xs={12} md={6} >
            <BP trsl={props.trsl} handlePrincipalButtonRouting={props.handlePrincipalButtonRouting}/>
          </Col>
          <Col xs={12} md={6} >
            <Budget trsl={props.trsl} handlePrincipalButtonRouting={props.handlePrincipalButtonRouting}/>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12} className='blanktop_15'>
            <h1>{props.trsl['user']}</h1>
          </Col>
          <Col xs={12} md={3} className='blanktop_05'>
            <Button label={props.trsl['user_button']} bk='bk_info'className="intro_screen_button" name="/app/profile" value={0} onClick={props.handlePrincipalButtonRouting}/>
          </Col>
          <Col xs={12} md={3} className='blanktop_05'>
            <Button label={props.trsl['helpdesk_button']} bk='bk_info' className="intro_screen_button" name="/app/helpdesk" value={0} onClick={props.handlePrincipalButtonRouting}/>
          </Col>
          <Col xs={12} md={3} className='blanktop_05'>
            <Button label={props.trsl['pricing']} bk='bk_info' className="intro_screen_button" name="/app/pricing" value={0} onClick={props.handlePrincipalButtonRouting}/>
          </Col>
          {show_partnership}
          {render_super_admin}
        </Row>
      </Grid>
    </div>
  )
}

function BP(props){
  return(
    <DetailedCard color='lightaccent'>
      <DetailedCard.Header  color='darkshade'>
        <h1>{props.trsl['bp']}</h1>
      </DetailedCard.Header>
      <DetailedCard.Body>
        <div className='blanktop_15'>
          <RegularButton label={props.trsl['bp_button']} className="intro_screen_button"  name="/app/business_plans/" value={0} onClick={props.handlePrincipalButtonRouting}/>              
        </div>
        <div className='blanktop_05'>
          <RegularButton label={props.trsl['subscriptions_button']} className="intro_screen_button" name="/app/subscriptions" value={0} onClick={props.handlePrincipalButtonRouting}/>
        </div>
        <div className='blanktop_05'>
          <RegularButton label={props.trsl['bp_templates']} className="intro_screen_button" name="/app/templates" value={0} onClick={props.handlePrincipalButtonRouting}/>
        </div>
      </DetailedCard.Body>
    </DetailedCard>
  )
}

function Budget(props){
  return(
    <DetailedCard color='lightaccent'>
      <DetailedCard.Header  color='darkshade'>
        <h1>{props.trsl['budgets']}</h1>
      </DetailedCard.Header>
      <DetailedCard.Body>
        <div className='blanktop_15'>
          <RegularButton label={props.trsl['budget_button']} className="intro_screen_button"  name="/app/budget_project" value={0} onClick={props.handlePrincipalButtonRouting}/>
        </div>
        <div className='blanktop_05'>
          <RegularButton label={props.trsl['budget_templates']} className="intro_screen_button" name="/app/budget_project/templates" value={0} onClick={props.handlePrincipalButtonRouting}/>
        </div>
      </DetailedCard.Body>
    </DetailedCard>
  )
}

function PublicData(props){
  return(
    
      <Col xs={12} md={4}>
        <DetailedCard color='lightaccent'>
          <DetailedCard.Header  color='darkshade'>
        <h1>{props.trsl['user']}</h1>
        </DetailedCard.Header>
        <DetailedCard.Body>
        <div className='blanktop_15'>
          <RegularButton label={props.trsl['user_button']}  className="intro_screen_button" name="/app/data_research/interest_rates" value={0} onClick={props.handlePrincipalButtonRouting}/>
        </div>
        </DetailedCard.Body>
        </DetailedCard>
      </Col>

  )
}

class SocialCasflo extends React.Component{
  render(){
    return(
      <div className="main_sreen_social">
        <Grid fluid>
          <Row >
          <Col xs={12} md={12}>
            <h1>
              CASFLO PARTNERS
            </h1>
          </Col>
            <Col xs={12} md={12}>
              <CasfloPartnerships />
            </Col>
            <Col xs={12} md={12} className='blanktop_15'>
              <h1 >
                Social CASFLO
              </h1>
            </Col>
            <Col xs={12} md={3} className='blanktop_10'></Col>
            <Col xs={4} md={2} className='blanktop_10'>
              <a href="https://facebook.com/casflo-app" target="_blank">
                <Image fluid src={facebook_img}/>
              </a>
            </Col>
            <Col xs={4} md={2} className='blanktop_10'>
              <a href="https://instagram.com/casflo-app" target="_blank">
                <Image fluid src={instagram_img} />
              </a>
            </Col>
            <Col xs={4} md={2} className='blanktop_10'>
              <a href="https://linkedin.com/casflo-app" target="_blank">
                <Image fluid src={linkedin_img}/>
              </a>
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}


class LearningCenterResources extends React.Component{
  render(){
    return(
      <div>
        <Grid fluid>
          <Row className="placer2">
            <Col xs={12} md={12}>
                  <h1>{this.props.trsl['learning']}</h1>
                  <MainScreenTutorials trsl={this.props.trsl}/>
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}

class MainScreenTutorials extends React.Component{
  render(){
    return(
      <div className="main_screen_lc_section">
        <h3>
          {this.props.trsl['manuals']}
        </h3>
        <Row>
          <Col xs={12} md={3}>
            <RegularLinkButton bk='bk_darkshade'
            target="_blank" 
            href="https://casflo-app.com/learning-center/manuals/user-manual/" label={this.props.trsl['user_manual']}/>
          </Col>
          <Col xs={12} md={3}>
          <RegularLinkButton bk='bk_darkshade'
            target="_blank" 
            href="https://casflo-app.com/learning-center/manuals/accounting-and-reporting/" label={this.props.trsl['accounting_manual']}/>
          </Col>
          <Col xs={12} md={3}>
          <RegularLinkButton bk='bk_darkshade'
            target="_blank"
            href="https://casflo-app.com/learning-center/manuals/financial-analysis/" label={this.props.trsl['p_analysis_manual']}/>
          </Col>
          <Col xs={12}md={3}>
          <RegularLinkButton bk='bk_darkshade'
            target="_blank"
            href="https://casflo-app.com/learning-center/manuals/tax-and-social-security/" label={this.props.trsl['taxes_manual']}/>
          </Col>
        </Row>
        </div>
    )
  }
}

export default MainScreen;