import React from 'react';
import {Col, Row, Grid} from 'Support/grid';
import {Form, Button, Card, Collapse } from 'react-bootstrap';

import UserContext from 'CONFIG/auth';
import ApiConnect from 'CONFIG/axios';

import Loader from 'Support/loader';

import currencies_base from 'JSON/currencies.json';
import countries from 'JSON/countries_pt.json';
import {SingleInputForm,SingleInputFormWithConfirm,SingleInputLargeForm, SingleInputNumberForm, SingleInputIntegerForm, Selectacble, Dropdown} from 'Support/inputforms';
import{ToogleButtons} from 'Support/toogle_buttons';
import { RegularButton,DeleteButton} from 'Support/listbuttons.js';
import default_values from 'JSON/countries_support.json';

class BudgetSettings extends React.Component{
  static contextType = UserContext

  constructor(props) {
    super(props);
    this.state = {
      budget_data:"",
      budget_settings:"",
      loaded:false,
    }
  }

  componentDidMount(){
    this.updateSettingsData()
  }

  updateSettingsData=async()=>{
    const request={
      budget_id: this.props.budget_id, 
    }
    try{
      const response = await ApiConnect.post('/api/budget_project/data/get_settings', request)
      this.setState({
        budget_data:response.data.budget_data,
        budget_settings:response.data.budget_settings,
        loaded:true
      })
    }catch(error) {
      alert(error);
    }
  }

  handleSaveSettingsData=async()=>{
    const request={
      budget_id: this.props.budget_id, 
      budget_data:this.state.budget_data,
      budget_settings:this.state.budget_settings
    }
    try{
      await ApiConnect.post('/api/budget_project/settings/change', request)
    }catch(error) {
      alert(error);
    }
  }

  handleEditSettingsChange=async(event)=>{
    const name = event.target.name;
    const value = event.target.value;
    let go = this.state.budget_settings;
    go[name]=value;
    this.setState({
      budget_settings:go
    })
  }


  handleDeleteItem=async(event)=>{
    let go = this.state.budget_settings;
    go.centers.splice(event.target.value,1)
    this.setState({
      budget_settings:go
    })
  }

  handleChangeNameDescription=(event)=>{
    const name = event.target.name;
    const value = event.target.value;
    let go=this.props.budget_data
    go[name]=value
    this.setState({
      budget_data:go
    })
  }

  handleChangeStartingYear=(event)=>{
    const value=Number(event.target.value);
    const number=this.state.budget_settings.number_of_years
    const years= this.calculateYears(number, value)
    let go = this.state.budget_settings;
    go.starting_year=value
    go.years=years
    this.setState({
      budget_settings:go
    })
  }

  calculateYears(number, start){
    let all_years=[]
    for(let i=0; i<number;i++){
      all_years[i]=start + i;
    }
    return all_years
  }
  
  handleCountryChange=async(event)=>{
    const name = event.target.name;
    const value = event.target.value;
    let go = this.state.budget_settings;
    go[name]=value;
    const years= this.calculateYears(this.state.budget_settings.number_of_years, this.state.budget_settings.starting_year)
    go.years=years
    if(default_values.countries[value].irc!=undefined){
      go.irc=default_values.countries[value].irc
      go.corporate_social_security=default_values.countries[value].corporate_social_security
      go.vat=default_values.countries[value].vat
      go.vat_payment=default_values.countries[value].vat_payment
      go.working_months=default_values.countries[value].working_months
      go.currency=default_values.countries[value].currency
    } else{
      go.irc=0
      go.corporate_social_security=0
      go.vat=0
      go.vat_payment=0
      go.working_months=12
    }
    this.setState({
      budget_settings:go
    })
  }


  handleSaveAndBack=async()=>{
    await this.handleSaveSettingsData()
    let e={
      target:{
        name:"main"
      }
    }
    this.props.handleGoToInProject(e)
  }

  render(){
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_BUDGET.MAIN.Budget_settings+Add_budget')

    const props={
      trsl:trsl,
      budget_name:this.state.budget_data.budget_name,
      project_description:this.state.budget_data.project_description,
      budget_settings:this.state.budget_settings,
      budget_data:this.state.budget_data,
      handleEditSettingsChange:this.handleEditSettingsChange,
      handleDeleteItem:this.handleDeleteItem,
      handleChangeStartingYear:this.handleChangeStartingYear,
      handleChangeNameDescription:this.handleChangeNameDescription,
      handleCountryChange:this.handleCountryChange,
      handleSaveAndBack:this.handleSaveAndBack,
      handleGoToInProject:this.props.handleGoToInProject,
    }

    function torender(data, i){
      return data[i];
    }  
    const pages ={
      true: 
      <ToDisplay {...props} />,
      false: <Loader loader_message="Searching for your settings!"/>
      }
      var i = this.state.loaded
      const renderizing = torender(pages,i)

    return(
      <>
      {renderizing}
      </>
    )
  }
}

class ToDisplay extends React.Component{
  render(){
    const props= this.props

    return(
      <>
        <Row>
          <Col xs={12}>
            <BudgetSettingsHeader {...props}/>
          </Col>
        </Row>
        <Row>
          <Col className="project_settings_rows" xs={12}>
            <Card>
              <Card.Header>
                <h4>{this.props.trsl['general_settings']}</h4>
              </Card.Header>
              <Card.Body>
                <BudgetNameDescription {...props} />
              </Card.Body>
            </Card>
          </Col>
          <Col className="project_settings_rows" xs={12}>
            <Card>
              <Card.Header>
                <h4>{this.props.trsl['fea_settings']}</h4>
              </Card.Header>
              <Card.Body>
                <ProjetFeasibilitySettings {...props}/>
              </Card.Body>
            </Card>
          </Col>
          <Col className="project_settings_rows" xs={12}>
            <Card>
              <Card.Header>
                <h4>{this.props.trsl['centers_of_activity']}</h4>
              </Card.Header>
              <Card.Body>
                <Centers {...props}/>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

class BudgetSettingsHeader extends React.Component{
  render(){
    return(
      <Row>
        <Col xs={12} md={8}>
          <h2>{this.props.trsl['project_settings']}</h2>
        </Col>
        <Col xs={6} md={2}>
          <RegularButton bk='bk_primary' label={this.props.trsl['back_button']} name="main" onClick={this.props.handleGoToInProject} />
        </Col>  
        <Col xs={6} md={2}>
          <RegularButton bk='bk_primary' label={this.props.trsl['save_back_button']} name="" onClick={this.props.handleSaveAndBack} />
        </Col>
      </Row>
    );
  }
}

class BudgetNameDescription extends React.Component{
  render(){
    return(
      <Row>
        <SingleInputForm
        xs={12} md={12}
        name="budget_name" 
        value={this.props.budget_name} 
        label={this.props.trsl['budget_name']}
        placehandler={this.props.trsl['budget_name']}
        handleEditItemChange={this.props.handleChangeNameDescription}/>
        <SingleInputLargeForm
        xs={12} md={12}
        name="project_description"
        value={this.props.project_description} 
        label={this.props.trsl['budget_descritpion']}
        placehandler={this.props.trsl['budget_descritpion']}
        maxlength={500}
        handleEditItemChange={this.props.handleChangeNameDescription}/>
      </Row>
    );
  }
}

class ProjetFeasibilitySettings extends React.Component{
  render(){
    const props=this.props

    return(
      <Row>
        <Col xs={12}>
          <ProjetMainAssumptions {...props}/>
        </Col>
        <Col xs={12}>
          <ProjetTaxAssumptions {...props}/>
        </Col>
        <Col xs={12}>
          <ProjetInputAssumptions {...props}/>
        </Col>
      </Row>
    );
  }
}

class ProjetTaxAssumptions extends React.Component{

  static contextType = UserContext

  render(){
    const props=this.props

    const checkNumber=(event)=>{
      const functions=this.props.handleEditSettingsChange
      const validator=parseFloat(event.target.value)
      if(validator>=0 && validator<=100){
        functions(event)
      } else{
        alert(this.props.trsl['alert_values'])
      }
    }
    return(
      <Row>
        <Col xs={12}>
          <h4>{this.props.trsl['budget_settings']}</h4>    
        </Col>
        <Col xs={12}>
          <h5>{this.props.trsl['profit_sales']}</h5> 
          <Row>           
            <SingleInputNumberForm xs={12} sm={6} md={3}name="irc" value={this.props.budget_settings.irc} label={this.props.trsl['corporate_tax']} handleinputeditChange={checkNumber} unit='%'/>
            <SingleInputNumberForm xs={12} sm={6} md={3}name="gross_revenue_tax" value={this.props.budget_settings.gross_revenue_tax} label={this.props.trsl['gross_revenue_tax']} handleinputeditChange={checkNumber} unit='%'/>
          </Row>
        </Col>
        <Col xs={12}>
        <h5>{this.props.trsl['vat']}</h5> 
          <Row>
            <VAT {...props}/>
            <SingleInputNumberForm xs={12} sm={6} md={3} name="vat" value={this.props.budget_settings.vat} label={this.props.trsl['vat_rate']} handleinputeditChange={checkNumber} unit='%'/>
            <SingleInputIntegerForm xs={12} sm={6} md={3} name="vat_payment" value={this.props.budget_settings.vat_payment} label={this.props.trsl['vat_payment']} handleinputeditChange={this.props.handleEditSettingsChange}/>          
          </Row>  
        </Col>
        <Col xs={12}>
          <h5>{this.props.trsl['other_taxes_and_fees']}</h5>
          <Row>           
            <SingleInputNumberForm xs={12} sm={6} md={3}name="corporate_social_security" value={this.props.budget_settings.corporate_social_security} label={this.props.trsl['social_security']} handleinputeditChange={checkNumber} unit='%'/>
            <SingleInputIntegerForm xs={12} sm={6} md={3}name="working_months"  value={this.props.budget_settings.working_months} label={this.props.trsl['salary_month']} handleinputeditChange={checkNumber}/>
          </Row>
        </Col>
      </Row>
    );
  }
}

class ProjetMainAssumptions extends React.Component{
  render(){
    const props = this.props

    return(
      <Row>
        <Col xs={12}>
          <h4>{this.props.trsl['forecasting_country']}</h4>
        </Col>
          <Country {...props}/>
          <Currency {...props}/>
        <Col xs={1}></Col>
        <Col xs={12} md={6}>
          {this.props.trsl['country_notice']}
        </Col>
        <Col xs={2}></Col>
        <Col md={3}>    

        </Col>
      </Row>
    )
  }
}

class ProjetInputAssumptions extends React.Component{
  render(){
    const props = this.props

    return(
      <Row>
        <Col xs={12}>
          <h4>{this.props.trsl['input_type']}</h4>
        </Col>
          <RawMaterials {...props}/>
          <Employees {...props}/>
          <DecimalReports {...props}/>
      </Row>
    );
  }
}

class Country extends React.Component{

  static contextType = UserContext

  render(){
    let user_c= this.context

    const item_holder = countries[user_c.locale]
    let item_distr
    if(item_holder !==undefined){
      item_distr = Object.entries(item_holder).map(([key,value]) =>{
        return(
          {value:key, option_label:value}
        )
      })
    }

    const country_holder=(input)=>{
      let starter="PT"
      if(input===""){
      return(starter)
      } else{
        return(input)
      }
    }
    const country_selection=country_holder(this.props.budget_settings.country)

    return(
      <Dropdown xs={12} md={3} label={this.props.trsl['country']} name="country" value={country_selection} options={item_distr} onChange={this.props.handleCountryChange} />
    )
  }
}

class Currency extends React.Component{
  render(){
    const item_holder = currencies_base
    let item_distr
    if(item_holder !==undefined){
      item_distr = Object.entries(item_holder).map(([key,value]) =>{
        return(
          {value:key, option_label:value}
        )
      })
    }
    return(
      <Dropdown xs={12} md={3} label={this.props.trsl['currency']} name="currency" value={this.props.budget_settings.currency} options={item_distr} onChange={this.props.handleEditSettingsChange} />
    )
  }
}

class RawMaterials extends React.Component{
  render(){
    const options=[
      {value:true,option_label:this.props.trsl['percentage']},
      {value:false,option_label:this.props.trsl['components']}
    ]
    const handleChange = (val) => {
      if(val.target.value==="true"){val=true}
      else{val=false}
      const event={
        target:{
          value:val,
          name:"raw_materials"
        }
      }
      this.props.handleEditSettingsChange(event)
    }
    return(
      <ToogleButtons xs={12} md={6} label={this.props.trsl['raw_materials']} toogle_data={options} selected={this.props.budget_settings.raw_materials} handleToogleButton={handleChange}/>
    )
  }
}

class Employees extends React.Component{
  render(){
    const options=[
      {value:true,option_label:this.props.trsl['categories']},
      {value:false,option_label:this.props.trsl['worker']}
    ]
    const handleChange = (val) => {
      if(val.target.value==="true"){val=true}
      else{val=false}
      const event={
        target:{
          value:val,
          name:"employees"
        }
      }
      this.props.handleEditSettingsChange(event)}
    return(
      <ToogleButtons xs={12} md={6} label={this.props.trsl['employees_input']} toogle_data={options} selected={this.props.budget_settings.employees} handleToogleButton={handleChange}/>
    )
  }
}

class VAT extends React.Component{
  render(){
    const options=[
      {value:true,option_label:this.props.trsl['general_vat']},
      {value:false,option_label:this.props.trsl['every_item_vat']}
    ]
    const handleChange = (val) => {
      if(val.target.value==="true"){val=true}
      else{val=false}
      const event={
        target:{
          value:val,
          name:"use_general_vat_rate"
        }
      }
      this.props.handleEditSettingsChange(event)}
    return( 
      <ToogleButtons xs={12} md={12} label={this.props.trsl['vat_input']} toogle_data={options} selected={this.props.budget_settings.use_general_vat_rate} handleToogleButton={handleChange}/>
    )
  }
}

class DecimalReports extends React.Component{
  render(){
    const options=[
      {value:true,option_label:this.props.trsl['decimals_true']},
      {value:false,option_label:this.props.trsl['decimals_false']}
    ]
    const handleChange = (val) => {
      if(val.target.value==="true"){val=true}
      else{val=false}
      const event={
        target:{
          value:val,
          name:"decimal_on_reports"
        }
      }
      this.props.handleEditSettingsChange(event)
    }
    return(
      <ToogleButtons xs={12} md={6} label={this.props.trsl['decimals']} toogle_data={options} selected={this.props.budget_settings.decimal_on_reports} handleToogleButton={handleChange}/>
    )
  }
}


class Centers extends React.Component{
  handleCentersAdd=(val)=>{
    let arr=this.props.budget_settings.centers
    arr.push(val)
    const event={
      target:{
        value:arr,
        name:"centers"
      }
    }
    this.props.handleEditSettingsChange(event)
  }

  render(){
    const props = this.props

    const item_holder = this.props.budget_settings.centers
    let item_distr
    if(item_holder !==undefined){
      item_distr = item_holder.map((key,value) =>{
        return(
          <Row className=" grid_middle list_item">
            <Col xs={11}>
              {key} 
              </Col>
              <Col xs={1}>
                <Row>
                <DeleteButton handleDelete={this.props.handleDeleteItem} value={value}/>
                </Row>
            </Col>
          </Row>
        )
      })
    }

    return(
      <Row>
        <Col xs={12} md={4}>
          <AddCenter {...props} handleCentersAdd={this.handleCentersAdd}/>
        </Col>
        <Col xs={12} md={8} className='general_list'>
          <CentersHeader {...props}/>
          {item_distr}
        </Col>
      </Row>
    )
  }
}

class AddCenter extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      new_center:""
    }
  }

  handleChange=(event)=>{
    const value=event.target.value;
    const name=event.target.name;
    let go=this.state;
    go[name]=value
    this.setState({
        go
    });
  }

  handleSubmitEvent=async()=>{
    await this.props.handleCentersAdd(this.state.new_center);
    this.setState(
      {new_center:""}
    )
  }
  render(){
    return (
      <Row >
          <SingleInputFormWithConfirm xs={12} md={12} label={this.props.trsl['add_center']} btn_label={this.props.trsl['confirm_center']} bk='bk_primary' placeholder={this.props.trsl['center_name']} name="new_center" value={this.state.new_center} handleEditItemChange={this.handleChange} onClick={this.handleSubmitEvent}/>
      </Row>
    );
  }
}

function CentersHeader (props){
  return(
    <Row className="list_table_header_left">
      <Col xs={10} md={11} className="list_table_header_inside">
        {props.trsl['centers']}
      </Col>
      <Col xs={2} md={1} className="list_table_header_inside">
        {props.trsl['actions']}
      </Col>
    </Row>
  );
}


export default BudgetSettings;

export{
  BudgetSettingsHeader,
  BudgetNameDescription,
  ProjetFeasibilitySettings
}