import React from 'react';
import { AssumptionHeaderSaveReturn } from '../budget_items';
import { Row, Col } from 'Support/grid'

import {SingleInputNumberForm, FormEdit, NonEditableForm, Dropdown, PeriodHeader, SingleInputForm, Observations} from 'Support/inputforms'
import { ToogleButtons } from 'Support/toogle_buttons';
import ApiConnect from 'CONFIG/axios';
import UserContext from 'CONFIG/auth';
import Loader from 'Support/loader';
import {calculateOperatingExpenses} from 'CALCULATIONS/Budget_calculations/cal_operating_expenses';

class OperatingExpEntry extends React.Component {
  static contextType = UserContext

  constructor(props) {
    super(props);
    this.state = {
      data: "",
      inflation:[1,1,1,1,1,1,1,1,1,1,1,1],
      seasonality:[],
      loaded:false
    }
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleObservationsChange = this.handleObservationsChange.bind(this);
    this.handlePeriodChange = this.handlePeriodChange.bind(this);
  }

  componentDidMount= async()=>{
    const request={
    budget_id:this.props.budget_id,
    version_id:this.props.current_version.id,
    item_id:this.props.active_item,
    item_type:"operating_expenses"
    }
    try {
      const response = await ApiConnect.post('/api/budget_project/multitem/unique/get', request)
        this.setState({
          data:response.data.item,
          seasonality:response.data.seasonality,
          loaded:true
        })
    } catch(error) {
        alert(error);
    }
  }

  handleEditChange=(id, item)=>{
    let go = this.state.data;
    go[id]=item;
    calculateOperatingExpenses(go, this.props.budget_settings,this.state.inflation,this.state.seasonality)
    this.setState({data:go})
  }  
  
  handleInputTypeChange=async(event)=>{
    const value = event.target.value;
    let go = this.state.data;
    go.op_exp_set_type=value;
    calculateOperatingExpenses(go, this.props.budget_settings,this.state.inflation,this.state.seasonality)
    this.setState({data:go})
  }

  handleNameChange(event){
    const value = event.target.value;
    let go = this.state.data;
    go.op_exp_name=value;
    this.setState({data:go })
  }

  handlePeriodChange(event){
    const value = event.target.value;
    let go = this.state.data;
    go.op_exp_period_type=value;
    calculateOperatingExpenses(go, this.props.budget_settings,this.state.inflation, this.state.seasonality)
    this.setState({data:go})
  }

  handleChangeYearlyValue=(event)=>{
    const value = event.target.value;
    let go = this.state.data;
    go[event.target.name]=value;
    calculateOperatingExpenses(go, this.props.budget_settings,this.state.inflation, this.state.seasonality)
    this.setState({data:go})
  }

  handleCenterChange=async(event)=>{
    const value = event.target.value;
    let go = this.state.data;
    go.op_exp_center=value;
    this.setState({data:go})
  }

  handleObservationsChange(event){
    const value = event.target.value;
    let go = this.state.data;
    go.op_exp_obs=value;
    this.setState({data:go })
  }

  async handleSave(){
    const request={
      budget_id:this.props.budget_id,
      version_id:this.props.current_version.id,
      item_id:this.props.active_item,
      item_type:"operating_expenses",
      content:this.state.data
    }
    try{
      await ApiConnect.post('/api/budget_project/multitem/item/modify', request)
      this.props.changeScreenView("list",0)
    } catch(error) {
        alert(error);
    }
  }

  render() {
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_BUDGET.INSIDEPROJECT.Individual_edits')

    let p_base=['m1','m2','m3','m4','m5','m6','m7','m8','m9','m10','m11','m12']
    let periods=[]
    for(var i=0; i < p_base.length ;i++){
      periods[i]=trsl[p_base[i]]
    }

    const props ={
      trsl:trsl,
      handleScreenNavigation:this.props.handleScreenNavigation,
      handleEditChange:this.handleEditChange,
      handleSave:this.handleSave,
      handleNameChange:this.handleNameChange,
      handlePeriodChange:this.handlePeriodChange,
      handleChangeYearlyValue:this.handleChangeYearlyValue,
      handleInputTypeChange:this.handleInputTypeChange,
      handleCenterChange:this.handleCenterChange,
      handleObservationsChange:this.handleObservationsChange,
      data:this.state.data,
      periods:periods,
      budget_settings:this.props.budget_settings,
      user_permission:this.props.user_permission
  }

    function torender(data, i){
      return data[i];
    }  
    const pages ={
      true: <ToDisplay {...props}/>,
      false: <Loader loader_message="op_exp_edit"/>
    }
    var i = this.state.loaded
    const renderizing = torender(pages,i)

    return(
      <>
        {renderizing}
      </>
    )
  }
}

class ToDisplay extends React.Component {
  render(){
    const props=this.props

    const op_exp_set_options=[
      {value:"default",option_label:this.props.trsl['op_exp_use_monthly_value']},
      {value:"seasonality",option_label:this.props.trsl['op_exp_use_yearly_value_seasonality']},
      {value:"fixed",option_label:this.props.trsl["op_exp_use_yearly_value_evenly"]}
    ]

    const quantitiy_period_options=[
      {value:30,option_label:this.props.trsl['daily']},
      {value:4.2,option_label:this.props.trsl['weekly']},
      {value:1,option_label:this.props.trsl['monthly']},
    ]

    let quantity_period_render=<ToogleButtons xs={12} md={12} label={this.props.trsl['op_exp_quantities']} toogle_data={quantitiy_period_options} handleToogleButton={this.props.handlePeriodChange} selected={this.props.data.op_exp_period_type}/>
    let value_render=
      <>
        <ValueEdit {...props}/>
        <VatEdit {...props} />
      </>
    let yearly_amount=<></>
    let totals=<></>

  if(this.props.data.op_exp_set_type==="fixed" ||this.props.data.op_exp_set_type==="seasonality"){
    value_render=<></>
    quantity_period_render=<></>
    totals=<TotalExpense {...props}/>
    yearly_amount=<SingleInputNumberForm xs={12} md={6} label={this.props.trsl['op_exp_total']} name='op_exp_yearly_total' value={this.props.data.op_exp_yearly_total} handleinputeditChange={this.props.handleChangeYearlyValue}/>
  }

    return(
      <>
        <GlobalHeader {...props}/>
        <Row>
        <ToogleButtons xs={12} md={12} label={this.props.trsl['op_exp_input_style']} toogle_data={op_exp_set_options} handleToogleButton={this.props.handleInputTypeChange} selected={this.props.data.op_exp_set_type}/>
        {quantity_period_render}
        {yearly_amount}
          <Col xs={12} >
            <PeriodHeader label={this.props.trsl['period']} periods={this.props.periods}/>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="feasibility_list">
            {value_render}
            {totals}
            <VatValue {...props}/>
          </Col>
          <Observations xs={12} className="feasibility_item_list" name="op_exp_obs" value={this.props.data.op_exp_obs} label="Observations" handleObservationsChange={this.props.handleObservationsChange}/>
        </Row>
      </>
    );
  }
}

class GlobalHeader extends React.Component{
  render(){
    const props=this.props

    const options_item_holder = this.props.budget_settings.centers
    let options_item_distr=[{value:"",option_label:''}]
    if(options_item_holder !==undefined){
      options_item_holder.map(key =>{
        options_item_distr.push({value:key,option_label:key})
      })
    }

    return(
      <>
        <AssumptionHeaderSaveReturn {...props}/>
        <Row className="feasibility_edit_globalheader_row" > 
            <SingleInputForm xs={12} md={5}value={this.props.data.op_exp_name} label={this.props.trsl['op_exp_name']} handleEditItemChange={this.props.handleNameChange}/>
            <Dropdown xs={12} md={7} label={this.props.trsl['center']} name="centers" value={this.props.data.op_exp_center} options={options_item_distr} onChange={this.props.handleCenterChange} />
        </Row>
      </>
    );
  }
}

class ValueEdit extends React.Component{
  render(){ 
    return(
      <FormEdit 
      use_historic={this.props.budget_settings.use_historic}
      item_holder={this.props.data.op_exp_value}
      id="op_exp_value"
      handleForm={this.props.handleEditChange}
      label={this.props.trsl['op_exp_value']}
      unit={new Intl.NumberFormat('ja-JP', { style: 'currency', currency: this.props.budget_settings.currency}).format(0).replace(/\d./g,"")}
      />
    );
  }
}

class ValueInflated extends React.Component{
  render(){ 
    return(
      <NonEditableForm
      item_holder={this.props.data._op_exp_value_inflated}
      label={this.props.trsl['op_exp_value_inflated']}
      value_type="currency"
      currency={this.props.budget_settings.currency}
      />
    );
  }
}

class VatEdit extends React.Component{
  render(){
    const vatRendering=(input)=>{
      if(input===false){
        return(
          <FormEdit 
          use_historic={this.props.budget_settings.use_historic}
          item_holder={this.props.data.op_exp_vat_rate}
          id="op_exp_vat_rate"
          handleForm={this.props.handleEditChange}
          label={this.props.trsl['vat_rate']}
          unit="%"
          />
        )
      }
    }
    let renderizing=""
    renderizing=vatRendering(this.props.budget_settings.use_general_vat_rate)
    return(
      <>
        {renderizing}
      </>
    );
  }
}


class TotalExpense extends React.Component{
  render(){ 
    return(
      <NonEditableForm
      item_holder={this.props.data._op_exp_total}
      label={this.props.trsl['op_exp_value']}
      value_type="currency"
      currency={this.props.budget_settings.currency}
      />
    );
  }
}

class VatValue extends React.Component{
  render(){ 
    return(
      <NonEditableForm
      item_holder={this.props.data._op_exp_vat_total}
      label={this.props.trsl['vat_total']}
      value_type="currency"
      currency={this.props.budget_settings.currency}
      />
    );
  }
}

export default OperatingExpEntry;