import React from 'react';
import {Row, Col} from 'Support/grid';

import {AssumptionHeaderSave} from 'PRIVATE/PROJECT/Feasibility/feasibility_items';

import {SingleInputNumberForm, SingleInputIntegerForm,NonEditableForm,PeriodHeader,DisabledSingleInputNumberForm,Selectacble} from 'Support/inputforms';
import { ToogleButtons } from 'Support/toogle_buttons';

class CocCAPM extends React.Component{
  render(){
    const props=this.props

    function torender(data, i){
      return data[i];
    }  
    const pages ={
      complex: <CAPM {...props}/>,
      simple: <SimpleEquityCost {...props}/>
    }
    const renderizing = torender(pages,this.props.feasibility_settings.financial_type)

    return(
      <>
        <GlobalHeader {...props}/>
        {renderizing}
      </>
    );
  }
}

class GlobalHeader extends React.Component{
  render(){
    return(
      <>
        <AssumptionHeaderSave title={this.props.trsl['feas_assum_header']} user_permission={this.props.user_permission} save={this.props.trsl['save_button']} handleSave={this.props.handleSave} />
      </>
    )
  }
}

function CAPM (props){
  return(
    <>
      <CostOfEquity {...props}/>
      <CostOfDebt {...props}/>
      <WACC {...props}/>
    </>
  )

}


class CostOfEquity extends React.Component{
  render(){
    return(
      <Row className="feasibility_feasibility_rows">
        <Col xs={12}>
          <h5>{this.props.trsl['feas_assum_cequity']}</h5>
          <h6>{`${this.props.trsl['feas_assum_risk_free']} + ${this.props.trsl['feas_assum_beta']} x (${this.props.trsl['feas_assum_risk_premium']}) = ${this.props.trsl['feas_assum_c_o_equity']}`}</h6>
        </Col>
          <SingleInputNumberForm 
          xs={6} md={2}
          handleinputeditChange={this.props.handleCocChange}
          label={this.props.trsl['feas_assum_risk_free']}
          placeholder={this.props.trsl['feas_assum_risk_free']}
          name="coc_risk_free"
          value={this.props.data.coc_risk_free}
          unit='%'
          />
          <SingleInputNumberForm 
          xs={6} md={2}
          handleinputeditChange={this.props.handleCocChange}
          label={this.props.trsl['feas_assum_beta']}
          placeholder={this.props.trsl['feas_assum_beta']}
          name="coc_beta"
          value={this.props.data.coc_beta}
          unit='β'
          />
          <SingleInputNumberForm 
          xs={6} md={2}
          handleinputeditChange={this.props.handleCocChange}
          label={this.props.trsl['feas_assum_risk_premium']}
          placeholder={this.props.trsl['feas_assum_risk_premium']}
          name="coc_risk_premium"
          value={this.props.data.coc_risk_premium}
          unit='%'
          />
        <DisabledSingleInputNumberForm
        xs={6} md={2}
          label={this.props.trsl['feas_assum_c_o_equity']}
          value={this.props.data._coc_equity_cost}
          unit='%'
          />
      </Row>
    );
  }
}

class CostOfDebt extends React.Component{
  render(){
    return(
      <Row className="feasibility_feasibility_rows">
        <Col xs={12}>
          <h5>{this.props.trsl['feas_assum_cdebt_h']}</h5>
          <h6>{`${this.props.trsl['feas_assum_interest']} x (1 - ${this.props.trsl['feas_assum_tshield']}) = ${this.props.trsl['feas_assum_c_debt']}`}</h6>
        </Col>
          <SingleInputNumberForm
          xs={6} md={2}
          handleinputeditChange={this.props.handleCocChange}
          label={this.props.trsl['feas_assum_interest']}
          placeholder={this.props.trsl['feas_assum_interest']}
          name="coc_average_debt_interest"
          value={this.props.data.coc_average_debt_interest}
          unit='%'
          />
          <DisabledSingleInputNumberForm 
          xs={6} md={2}
          label={this.props.trsl['feas_assum_tshield']}
          value={this.props.feasibility_settings.irc}
          unit='%'
          />
          <DisabledSingleInputNumberForm 
          xs={6} md={2}
          label={this.props.trsl['feas_assum_c_debt']}
          value={this.props.data._coc_debt_cost}
          unit='%'
          />
      </Row>
    );
  }
}

class WACC extends React.Component{
  render(){
    return(
      <Row className="feasibility_feasibility_rows">
        <Col xs={12}>
          <h5>{this.props.trsl['feas_assum_wacc_h']}</h5>
          <h6>{`${this.props.trsl['feas_assum_equity_weight']} x ${this.props.trsl['feas_assum_c_o_equity']} + ${this.props.trsl['feas_assum_debt_weight']} x ${this.props.trsl['feas_assum_c_debt']} = ${this.props.trsl['feas_assum_wacc']}`}</h6>
        </Col>
          <SingleInputNumberForm
          xs={6} md={2}
          handleinputeditChange={this.props.handleCocChange}
          placeholder="Equity weight"
          name="coc_equity_weight"
          label={this.props.trsl['feas_assum_equity_weight']}
          value={this.props.data.coc_equity_weight}
          unit='%'
          />
          <DisabledSingleInputNumberForm 
          xs={6} md={2}
          label={this.props.trsl['feas_assum_c_o_equity']}
          value={this.props.data._coc_equity_cost}
          unit='%'
          />
          <DisabledSingleInputNumberForm 
          xs={6} md={2}
          label={this.props.trsl['feas_assum_debt_weight']}
          value={this.props.data._coc_debt_weight}
          unit='%'
          />
          <DisabledSingleInputNumberForm 
          xs={6} md={2}
          label={this.props.trsl['feas_assum_c_debt']}
          value={this.props.data._coc_debt_cost}
          unit='%'
          />
          <DisabledSingleInputNumberForm
          xs={6} md={2} 
          label={this.props.trsl['feas_assum_wacc']}
          value={this.props.data._coc_wacc}
          unit='%'
          />
      </Row>
    );
  }
}

class SimpleEquityCost extends React.Component{
  render(){
    return(
      <Col xs={6} md={3}>
        <SingleInputNumberForm 
        handleinputeditChange={this.props.handleCocChange}
        label={this.props.trsl['feas_assum_simple_discount']}
        placeholder={this.props.trsl['feas_assum_simple_discount']}
        name="coc_simple_discount_rate"
        value={this.props.data.coc_simple_discount_rate}
        /> 
      </Col>
    )
  }
}

class PerpetuityRender extends React.Component{
  render(){
    const options=[
      {value:'perpetuity',option_label:this.props.trsl['feas_assum_perpetuity']},
      {value:"limited",option_label:this.props.trsl['feas_assum_limited_years']},
      {value:"user_input",option_label:this.props.trsl['fea_assum_user_input']},
      {value:"none",option_label:this.props.trsl['feas_assum_none']}
    ]
    const props=this.props

    function torender(data, i){
      return data[i];
    }  
    const pages ={
      perpetuity: <Perpetuity {...props}/>,
      limited: <LimitedPerpetuity {...props}/>,
      user_input: <PerpetuityUserInput {...props}/>
    }

    const renderizing = torender(pages,this.props.data.coc_residual_value_type)
    return(
      <Row className="feasibility_feasibility_main_rows">
        <Col xs={12} >
          <h4>{this.props.trsl['feas_assum_residual_value']}</h4>
          </Col>
          <ToogleButtons xs={12} md={12} label={this.props.trsl['feas_assum_residual_options']} toogle_data={options} selected={this.props.data.coc_residual_value_type} handleToogleButton={this.props.handleResidualValue}/>
          <Col xs={12}>
            {renderizing}
          </Col>
      </Row>
    )
  }
}

class Perpetuity extends React.Component{
  render(){
    return(
      <Row className="feasibility_feasibility_rows">
        <Col xs={12}>
          <h5>{this.props.trsl['feas_assum_perpetuity']}</h5>
        </Col>
          <SingleInputNumberForm
          xs={6} md={4}
          handleinputeditChange={this.props.handleCocChange}
          label={this.props.trsl['feas_assum_perpetuity_growth']}
          placeholder={this.props.trsl['feas_assum_perpetuity_growth']}
          name="coc_residual_value_growth"
          value={this.props.data.coc_residual_value_growth}
          unit='%'
          /> 

    </Row>
    )
  }
}

class LimitedPerpetuity extends React.Component{
  render(){
    return(
      <Row className="feasibility_feasibility_rows">
        <Col xs={12}>
          <h5>{this.props.trsl['feas_assum_limited_years']}</h5>
        </Col>
          <SingleInputNumberForm 
          xs={6} md={4}
          handleinputeditChange={this.props.handleCocChange}
          label={this.props.trsl['feas_assum_perpetuity_growth']}
          placeholder={this.props.trsl['feas_assum_perpetuity_growth']}
          name="coc_residual_value_growth"
          value={this.props.data.coc_residual_value_growth}
          unit='%'
          /> 
          <SingleInputIntegerForm 
          xs={6} md={4}
          handleinputeditChange={this.props.handleCocChange}
          label={this.props.trsl['feas_assum_years_residual']}
          placeholder={this.props.trsl['feas_assum_years_residual']}
          name="coc_residual_value_years"
          value={this.props.data.coc_residual_value_years}
          /> 
      </Row>
    )
  }
}

class PerpetuityUserInput extends React.Component{
  render(){
    return(
      <Row className="feasibility_feasibility_rows">
        <Col xs={12}>
          <h5>{this.props.trsl['feas_assum_perpetuity']}</h5>
        </Col>
          <SingleInputNumberForm 
          xs={6} md={4}
          handleinputeditChange={this.props.handleCocChange}
          label={this.props.trsl['fea_assum_user_input']}
          placeholder={this.props.trsl['fea_assum_user_input']}
          name="coc_resuidual_value_user_input"
          value={this.props.data.coc_resuidual_value_user_input}
          unit={new Intl.NumberFormat('ja-JP', { style: 'currency', currency: this.props.feasibility_settings.currency}).format(0).replace(/\d./g,"")}
          /> 

    </Row>
    )
  }
}

function ZeroYearPicker(props){
  const options=[
    {value:true,option_label:props.trsl['fea_assum_discount_without_zero']},
    {value:false,option_label:props.trsl['fea_assum_discount_with_zero']}
  ]
  return(
    <Row className="feasibility_feasibility_main_rows">
    <Col xs={12} >
      <h4>{props.trsl['fea_assum_discount']}</h4>
    </Col>
      <ToogleButtons xs={12} md={12} label={props.trsl['fea_assum_discount_start']} toogle_data={options} selected={props.data.coc_residual_discount_type} handleToogleButton={props.handleDiscountType}/>
  </Row>
  )
}

class DiscountFactor extends React.Component{
  render(){ 
    let equity_factor_render= <NonEditableForm item_holder={this.props.data._coc_yearly_equity_discount_factor} label={this.props.trsl['fea_assum_discount_fcfe_factor']} decimals={true} />
    if(this.props.feasibility_settings.financial_type==='simple') equity_factor_render=<></>
    return(
      <Col xs={12}>
        <Row className="feasibility_feasibility_main_rows">
          <Col xs={12} >        
          <Row >
            <Col xs={12} >
              <PeriodHeader label={this.props.trsl['year']} periods={this.props.feasibility_settings.years}/>
            </Col>
          </Row>
          <NonEditableForm item_holder={this.props.data._coc_yearly_discount_factor} label={this.props.trsl['fea_assum_discount_fcff_factor']} decimals={true} />
          {equity_factor_render}
        </Col>
      </Row>
      </Col>
    );
  }
}


export{ 
  CocCAPM,
  PerpetuityRender,
  ZeroYearPicker,
  DiscountFactor
};