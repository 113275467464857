import {dotMultiply, add} from 'mathjs';

import {calculateHumanResources}  from './cal_human_resources'
import {calResults} from './cal_incomestatement'
import {calculateRevenues}  from './cal_revenues'
import {calculateOperatingExpenses} from './cal_operating_expenses'
import {calculateInvestment}  from'./cal_investment'
import {calculateDebt}  from './cal_debt'


const IterateSensitivityAnalysisVersion=async(version, feasibility_settings, switches, factors)=>{
  let version2=JSON.parse(JSON.stringify(version))
  if(feasibility_settings.use_historic!==true){
    //rev prices
    if(switches.rev_price_switch===true){
      for(var i=0;i<version2.revenues.length;i++){
        version2.revenues[i].rev_price=dotMultiply(version2.revenues[i].rev_price,add(1,(factors.rev_price_factor/100)))
        version2.revenues[i]=calculateRevenues(version2.revenues[i],feasibility_settings,version2.version_settings._inflation_factor)
      }
    }
    //rev quantities
    if(switches.rev_quantity_switch===true){
      for(var i=0;i<version2.revenues.length;i++){
        version2.revenues[i].rev_quantity=dotMultiply(version2.revenues[i].rev_quantity,add(1,(factors.rev_quantity_factor/100)))
        version2.revenues[i]=calculateRevenues(version2.revenues[i],feasibility_settings,version2.version_settings._inflation_factor)
      }
    }
    //inventory
    if(switches.inventory_switch===true){
      for(var i=0;i<version2.revenues.length;i++){
        version2.revenues[i].rev_cogs.days_of_stock=dotMultiply(version2.revenues[i].rev_cogs.days_of_stock,add(1,(factors.inventory_factor/100)))
        version2.revenues[i]=calculateRevenues(version2.revenues[i],feasibility_settings,version2.version_settings._inflation_factor)
      }
    }
    //cogs
    if(switches.rev_cogs_switch===true){
      for(var i=0;i<version2.revenues.length;i++){
        if(version2.revenues[i].rev_cogs.cogs_type===true){
          version2.revenues[i].rev_cogs.s_cogs.s_cogs_percentage=dotMultiply(version2.revenues[i].rev_cogs.s_cogs.s_cogs_percentage,add(1,(factors.rev_cogs_factor/100)))
        }
        if(version2.revenues[i].rev_cogs.cogs_type===false){
          for (var j=0;j<version2.revenues[i].rev_cogs.c_cogs.length;j++){
            version2.revenues[i].rev_cogs.c_cogs[j].c_cogs_item_value=dotMultiply(version2.revenues[i].rev_cogs.c_cogs[j].c_cogs_item_value,add(1,(factors.rev_cogs_factor/100)))
          }
        }
        version2.revenues[i]=calculateRevenues(version2.revenues[i],feasibility_settings,version2.version_settings._inflation_factor)
      }
    }
    //operating expenses
    if(switches.op_exp_price_switch===true){
      for(var i=0;i<version2.operating_expenses.length;i++){
        version2.operating_expenses[i].op_exp_value=dotMultiply(version2.operating_expenses[i].op_exp_value,add(1,(factors.op_exp_factor/100)))
        version2.operating_expenses[i]=calculateOperatingExpenses(version2.operating_expenses[i],feasibility_settings,version2.version_settings._inflation_factor)
      }
    }
    //hr
    if(switches.hr_switch===true){
      for(var i=0;i<version2.human_resources.length;i++){
        version2.human_resources[i].hr_gross_salary=dotMultiply(version2.human_resources[i].hr_gross_salary,add(1,(factors.hr_factor/100)))
        version2.human_resources[i]=calculateHumanResources(version2.human_resources[i],feasibility_settings,version2.version_settings._inflation_factor)
      }
    }
    //investment price
    if(switches.inv_price_switch===true){
      for(var i=0;i<version2.investments.length;i++){
        version2.investments[i].inv_price=dotMultiply(version2.investments[i].inv_price,add(1,(factors.inv_price_factor/100)))
        version2.investments[i]=calculateInvestment(version2.investments[i],feasibility_settings.number_of_years,feasibility_settings)
      }
    }
    //investment quantity
    if(switches.inv_quantity_switch===true){
      for(var i=0;i<version2.investments.length;i++){
        version2.investments[i].inv_quantity=dotMultiply(version2.investments[i].inv_quantity,add(1,(factors.inv_quantity_factor/100)))
        version2.investments[i]=calculateInvestment(version2.investments[i],feasibility_settings.number_of_years,feasibility_settings)
      }
    }
    //debt cost
    if(switches.debt_cost_switch===true){
      for(var i=0;i<version2.debt.length;i++){
        version2.debt[i].debt_interest_rate=dotMultiply(version2.debt[i].debt_interest_rate,add(1,(factors.debt_cost_factor/100)))
        version2.debt[i]=calculateDebt(version2.debt[i],feasibility_settings.number_of_years)
      }
    }
    //debt value
    if(switches.debt_value_switch===true){
      for(var i=0;i<version2.debt.length;i++){
        version2.debt[i].debt_amount=dotMultiply(version2.debt[i].debt_amount,add(1,(factors.debt_value_factor/100)))
        version2.debt[i]=calculateDebt(version2.debt[i],feasibility_settings.number_of_years)
      }
    }
    //receivables
    if(switches.receivables_switch===true){
      version2.version_settings.days_accounts_receivables=dotMultiply(version2.version_settings.days_accounts_receivables,add(1,(factors.receivables_factor/100)))
    }
    //payables
    if(switches.payables_switch===true){
      version2.version_settings.days_accounts_payables=dotMultiply(version2.version_settings.days_accounts_payables,add(1,(factors.payables_factor/100)))
    }
    //investment payables
    if(switches.investment_payables_switch===true){
      version2.version_settings.investment_days_accounts_payables=dotMultiply(version2.version_settings.investment_days_accounts_payables,add(1,(factors.investment_payables_factor/100)))
    }
  } else {
    //rev prices
    if(switches.rev_price_switch===true){
      for(var i=0;i<version2.revenues.length;i++){
        const fixer=version2.revenues[i].rev_price[0]
        version2.revenues[i].rev_price=dotMultiply(version2.revenues[i].rev_price,add(1,(factors.rev_price_factor/100)))
        version2.revenues[i].rev_price[0]=fixer
        version2.revenues[i]=calculateRevenues(version2.revenues[i],feasibility_settings,version2.version_settings._inflation_factor)
        
      }
    }
    //rev quantities
    if(switches.rev_quantity_switch===true){
      for(var i=0;i<version2.revenues.length;i++){
        const fixer=version2.revenues[i].rev_quantity[0]
        version2.revenues[i].rev_quantity=dotMultiply(version2.revenues[i].rev_quantity,add(1,(factors.rev_quantity_factor/100)))
        version2.revenues[i].rev_quantity[0]=fixer
        version2.revenues[i]=calculateRevenues(version2.revenues[i],feasibility_settings,version2.version_settings._inflation_factor)
      }
    }
    //inventory
    if(switches.inventory_switch===true){
      for(var i=0;i<version2.revenues.length;i++){
        const fixer=version2.revenues[i].rev_cogs.days_of_stock[0]
        version2.revenues[i].rev_cogs.days_of_stock=dotMultiply(version2.revenues[i].rev_cogs.days_of_stock,add(1,(factors.inventory_factor/100)))
        version2.revenues[i].rev_cogs.days_of_stock[0]=fixer
        version2.revenues[i]=calculateRevenues(version2.revenues[i],feasibility_settings,version2.version_settings._inflation_factor)
      }
    }
    //cogs
    if(switches.rev_cogs_switch===true){
      for(var i=0;i<version2.revenues.length;i++){
        if(version2.revenues[i].rev_cogs.cogs_type===true){
          const fixer=version2.revenues[i].rev_cogs.s_cogs.s_cogs_percentage[0]
          version2.revenues[i].rev_cogs.s_cogs.s_cogs_percentage=dotMultiply(version2.revenues[i].rev_cogs.s_cogs.s_cogs_percentage,add(1,(factors.rev_cogs_factor/100)))
          version2.revenues[i].rev_cogs.s_cogs.s_cogs_percentage[0]=fixer
        }
        if(version2.revenues[i].rev_cogs.cogs_type===false){
          for (var j=0;j<version2.revenues[i].rev_cogs.c_cogs.length;j++){
            const fixer=version2.revenues[i].rev_cogs.c_cogs[j].c_cogs_item_value[0]
            version2.revenues[i].rev_cogs.c_cogs[j].c_cogs_item_value=dotMultiply(version2.revenues[i].rev_cogs.c_cogs[j].c_cogs_item_value,add(1,(factors.rev_cogs_factor/100)))
            version2.revenues[i].rev_cogs.c_cogs[j].c_cogs_item_value[0]=fixer
          }
        }
        version2.revenues[i]=calculateRevenues(version2.revenues[i],feasibility_settings,version2.version_settings._inflation_factor)
      }
    }
    //operating expenses
    if(switches.op_exp_price_switch===true){
      for(var i=0;i<version2.operating_expenses.length;i++){
        const fixer=version2.operating_expenses[i].op_exp_value[0]
        version2.operating_expenses[i].op_exp_value=dotMultiply(version2.operating_expenses[i].op_exp_value,add(1,(factors.op_exp_factor/100)))
        version2.operating_expenses[i].op_exp_value[0]=fixer
        version2.operating_expenses[i]=calculateOperatingExpenses(version2.operating_expenses[i],feasibility_settings,version2.version_settings._inflation_factor)
      }
    }
    //hr
    if(switches.hr_switch===true){
      for(var i=0;i<version2.human_resources.length;i++){
        const fixer=version2.human_resources[i].hr_gross_salary[0]
        version2.human_resources[i].hr_gross_salary=dotMultiply(version2.human_resources[i].hr_gross_salary,add(1,(factors.hr_factor/100)))
        version2.human_resources[i].hr_gross_salary[0]=fixer
        version2.human_resources[i]=calculateHumanResources(version2.human_resources[i],feasibility_settings,version2.version_settings._inflation_factor)
      }
    }
    //investment price
    if(switches.inv_price_switch===true){
      for(var i=0;i<version2.investments.length;i++){
        if(version2.investments[i].inv_year!==0){
          version2.investments[i].inv_price=dotMultiply(version2.investments[i].inv_price,add(1,(factors.inv_price_factor/100)))
          version2.investments[i]=calculateInvestment(version2.investments[i],parseInt(feasibility_settings.number_of_years)+parseInt(feasibility_settings.number_of_historic_years),feasibility_settings)
        }
      }
    }
    //investment quantity
    if(switches.inv_quantity_switch===true){
      for(var i=0;i<version2.investments.length;i++){
        if(version2.investments[i].inv_year!==0){
          version2.investments[i].inv_quantity=dotMultiply(version2.investments[i].inv_quantity,add(1,(factors.inv_quantity_factor/100)))
          version2.investments[i]=calculateInvestment(version2.investments[i],parseInt(feasibility_settings.number_of_years)+parseInt(feasibility_settings.number_of_historic_years),feasibility_settings)
        }
      }
    }
    //debt cost
    if(switches.debt_cost_switch===true){
      for(var i=0;i<version2.debt.length;i++){
        if(version2.debt[i].debt_year!==0){
          version2.debt[i].debt_interest_rate=dotMultiply(version2.debt[i].debt_interest_rate,add(1,(factors.debt_cost_factor/100)))
          version2.debt[i]=calculateDebt(version2.debt[i],parseInt(feasibility_settings.number_of_years)+parseInt(feasibility_settings.number_of_historic_years))
        }
      }
    }
    //debt value
    if(switches.debt_value_switch===true){
      for(var i=0;i<version2.debt.length;i++){
        if(version2.debt[i].debt_year!==0){
          version2.debt[i].debt_amount=dotMultiply(version2.debt[i].debt_amount,add(1,(factors.debt_value_factor/100)))
          version2.debt[i]=calculateDebt(version2.debt[i],parseInt(feasibility_settings.number_of_years)+parseInt(feasibility_settings.number_of_historic_years))
        }
      }
    }
    //receivables
    if(switches.receivables_switch===true){
      const fixer=version2.version_settings.days_accounts_receivables[0]
      version2.version_settings.days_accounts_receivables=dotMultiply(version2.version_settings.days_accounts_receivables,add(1,(factors.receivables_factor/100)))
      version2.version_settings.days_accounts_receivables[0]=fixer
    }
    //payables
    if(switches.payables_switch===true){
      const fixer=version2.version_settings.days_accounts_payables[0]
      version2.version_settings.days_accounts_payables=dotMultiply(version2.version_settings.days_accounts_payables,add(1,(factors.payables_factor/100)))
      version2.version_settings.days_accounts_receivables[0]=fixer
    }
    //investment payables
    if(switches.investment_payables_switch===true){
      const fixer=version2.version_settings.investment_days_accounts_payables[0]
      version2.version_settings.investment_days_accounts_payables=dotMultiply(version2.version_settings.investment_days_accounts_payables,add(1,(factors.investment_payables_factor/100)))
      version2.version_settings.investment_days_accounts_payables[0]=fixer
    }
  }




  version2=await calResults(version2,feasibility_settings)
  
  return version2
}
export default IterateSensitivityAnalysisVersion
