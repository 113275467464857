import React from 'react';
import { Grid, Row, Col} from 'Support/grid';
import {Form,Button, Card} from 'react-bootstrap';
import { SingleInputForm, SingleInputLargeForm,Dropdown } from 'Support/inputforms';

import UserContext from 'CONFIG/auth';
import ApiConnect from 'CONFIG/axios';

class HelpDesk extends React.Component {
  static contextType = UserContext

  constructor(props) {
    super(props);
    this.state = {
      text:"",
      subject:"",
      type:"starter",
      toast:{
        show:false,
        toast_header:"",
        toast_body:"",
      }
    }
  }

  handleSendHelpdeskRequest=async(event)=>{
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE.Helpdesk')
    event.preventDefault()
    const go_text=event.target.text
    const request={
      text:this.state.text,
      subject:this.state.subject,
      type:this.state.type
    }
    try{
      if(request.text.length>0 && request.type.length>0 && request.subject.length!=="starter"){
        const response = await ApiConnect.post('/api/user/helpdesk/request', request)
        this.AlertFunctions.setAlert(trsl['sent'])
      }
    } catch(error){
      alert(error)
    }
  }

  handleChangeRequest=(event)=>{
    let go=event.target.name
    let value=event.target.value
    this.setState({
      [go]:value
    })
  }

  AlertFunctions={
    setAlert:(header,body)=>{
      this.setState({
        toast:{
          show:true,
          header: header,
          body:body
        }
      })
    },
    unsetalert:()=>{
      this.setState({
        toast:{
          show:false
        }
      })
    }
  }

  render(){
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE.Helpdesk')

    const options=[
      {value:"starter",option_label:trsl['--Select an option --'],disabled:false},
      {value:"commercial",option_label:trsl['commercial'],disabled:false},
      {value:"invoicing",option_label:trsl['invoicing'],disabled:false},
      {value:"technical",option_label:trsl['technical'],disabled:false},
      {value:"project_revision",option_label:trsl['project_revision'],disabled:false}
    ]

    return(
      <Grid fluid>
        <Row className="helpdesk_header">
          <Col xs={12}>
            <h1>{trsl['helpdesk']} </h1>
          </Col>
          <Col xs={12}>
            <Card>
              <Card.Header>
                {trsl['header']}
              </Card.Header>
              <Card.Body>
                <Row>
                  <SingleInputForm xs={12} md={6} label={trsl['subject']} name="subject" value={this.state.subject} handleEditItemChange={this.handleChangeRequest}/>
                  <Dropdown xs={12} md={6} label={trsl['type_of_help']} name="type" options={options} value={this.state.type} onChange={this.handleChangeRequest}/>
                  <SingleInputLargeForm xs={12} md={12} label={trsl['text']} name="text" value={this.state.text} handleEditItemChange={this.handleChangeRequest}/>        
                  <Col md={3}>
                    <Button variant="primary" onClick={this.handleSendHelpdeskRequest}>
                      {trsl['send']}
                    </Button>
                  </Col>
                  <Col md={4}>
                    {trsl['note']}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Grid>
    )
  }
}

export default HelpDesk;