import React from 'react';
import {Form, Button} from 'react-bootstrap';
import { Grid,Row, Col} from 'Support/grid';

import UserContext from 'CONFIG/auth';
import ApiConnect from 'CONFIG/axios';
import Session from 'CONFIG/session';

import {SingleInputForm,SingleInputPasswordForm, Dropdown,SingleDateForm} from 'Support/inputforms';
import { ToogleButtons } from 'Support/toogle_buttons';

import countries from 'JSON/countries_pt.json';
import 'CSS/user_style.css'
import { RegularButton } from 'Support/listbuttons';

class Editprofile extends React.Component {
  static contextType = UserContext

  constructor(props) {
    super(props);
    this.state = {
      data:"",
      change_Password_Is_On: false,
      delete_user_is_on:false,
    }
    this.ActivatePassword=this.ActivatePassword.bind(this);
    this.ActivateDeleteUser=this.ActivateDeleteUser.bind(this);
    this.handleEditUser=this.handleEditUser.bind(this);;
    this.handleSubmitChangeInUser=this.handleSubmitChangeInUser.bind(this);
    this.handleSelectAvatar=this.handleSelectAvatar.bind(this);
  }

componentDidMount(){
  this.getUserData()
}

getUserData=async()=>{
  const request={ }
  try{
    const response= await ApiConnect.post(`/api/user/get`, request)
    this.setState({
      data:response.data
    })
  } catch(error) {
    alert(error.data);
  }
}


handleSubmitChangeInUser=async()=>{
  const request={
    data:this.state.data
  }
  const contexts = this.context
  try{
    const response= await ApiConnect.post(`/api/user/modify`, request);
    const event=
      {target:
        {name:"main"
      }
    }
    contexts.setUser(Session.getToken(), Session.getLocale(), "", this.state.data.user_avatar,contexts.template)
    this.props.handlePrincipalButtonRouting(event)
  } catch(error) {
    alert(error.data);
  }
}

handleEditUser(event){
    const item =event.target.name
    const value = event.target.value;
    let go = this.state.data;
    go[item]=value
    this.setState({
      data:go
    })
  }


  handleSelectAvatar=(event)=>{
    const value = event.target.value;
    let go = this.state.data;
    go.user_avatar=value;
    this.setState({
      data:go
    })
  }

  handleLocale=async(event)=>{
    let go = this.state.data
    go.user_locale=event.target.value
    const request={
      data:go
    }
    const contexts = this.context
    try{
      await ApiConnect.post(`/api/user/language`, request);
      contexts.setUser(Session.getToken(), go.user_locale, "", this.state.data.user_avatar,contexts.template)
    } catch(error) {
    }
  }


  ActivatePassword(){
    this.setState({
      change_Password_Is_On: !this.state.change_Password_Is_On
    });
  }

  ActivateDeleteUser(){
    this.setState({
      delete_user_is_on: !this.state.delete_user_is_on
    });
  }

  render(){
    let user_c= this.context
    const trsl=user_c.i18n.t('USER.Edit_profile')

    const props={
      trsl:trsl,
      data:this.state.data,
      change_Password_Is_On:this.state.change_Password_Is_On,
      handleEditUser:this.handleEditUser,
      handlePrincipalButtonRouting:this.props.handlePrincipalButtonRouting,
      ActivatePassword:this.ActivatePassword,
      handleSubmitChangeInUser:this.handleSubmitChangeInUser,
      handleSelectAvatar:this.handleSelectAvatar,
      handleLocale:this.handleLocale
    }

    let RenderDeleteUser;
    if(this.state.delete_user_is_on){
      RenderDeleteUser=<DeleteUser {...props}/>;
    }
    else{
      RenderDeleteUser="";
    }
    
    return(
      <Grid fluid>
        <Row className="edit_profile_main_placer">
          <Col xs={12}>
            <ProfileHeader {...props}/>
          </Col>
        </Row>
        <Row className="edit_profile_box1">
          <Col xs={12}>
                <Row>
                  <Col xs={12} >
                    <Profiledata {...props} />
                    <LanguageChange {...props} />
                    <InvoincingData {...props} />
                    <ChangePasswordButton {...props}/>
                    <DeleteUserButton onClick={this.ActivateDeleteUser}/>
                    {RenderDeleteUser}
                  </Col>
                  <Col xs={12} md={1}></Col>
                  <Col xs={12} md={4}>
                  </Col>
                </Row>
          </Col>
        </Row>
      </Grid>
    );
  }
}

class ProfileHeader extends React.Component{
  static contextType = UserContext
  render(){
    let user_c= this.context
    
    return(
      <Row>
      <Col xs={12} md={8}>
        <h1>{this.props.trsl["edit_profile"]} </h1>
      </Col>
      <Col xs={6} md={2}>
          <RegularButton bk="bk_primary" name="main" label={user_c.i18n.t('USER.Edit_profile.back')} onClick={this.props.handlePrincipalButtonRouting}/>
      </Col>
      <Col xs={6} md={2}>
          <RegularButton bk="bk_primary" label={user_c.i18n.t('USER.Edit_profile.save')}  onClick={this.props.handleSubmitChangeInUser} />
      </Col>
  </Row>
    );
  }
}

class Profiledata extends React.Component{
  static contextType = UserContext  
  render(){
    let user_c= this.context

    const item_holder = countries[user_c.locale]
    let item_distr
    if(item_holder !==undefined){
      item_distr = Object.entries(item_holder).map(([key,value]) =>{
        return(
          {value:key, option_label:value}
        )
      })
    }

    const country_holder=(input)=>{
      let starter="PT"
      if(input===""){
      return(starter)
      } else{
        return(input)
      }
    }
    const country_selection=country_holder(this.props.data.user_country)

    return(
      <Row>
        <Col xs={12} md={12}>
          <h3>{user_c.i18n.t('USER.Edit_profile.profile_data')}</h3>
        </Col>
          <SingleInputForm 
          md={6} xs={12}
          name="user_name" 
          value={this.props.data.user_name} 
          handleEditItemChange={this.props.handleEditUser} 
          label={user_c.i18n.t('USER.Edit_profile.name')} />
          <SingleInputForm 
          md={6} xs={12}
          name="user_email" 
          value={this.props.data.user_email} 
          handleEditItemChange={this.props.handleEditUser}
          label={user_c.i18n.t('USER.Edit_profile.email')} />          
          <Dropdown label={user_c.i18n.t('USER.Edit_profile.country')} name="user_country" value={country_selection} options={item_distr} onChange={this.props.handleEditUser}/>
          <SingleDateForm 
          md={6} xs={12}
          name="functiontype"  
          value={this.props.data.user_birth} 
          label={user_c.i18n.t('USER.Edit_profile.date_of_birth')} />
        <Col xs={12} md={6} className="edit_profile_single_input_forms">

        </Col>
      </Row>
    );
  }
}

class InvoincingData extends React.Component{
  static contextType = UserContext  
  render(){
    let user_c= this.context
    const item_holder = countries[user_c.locale]
    let item_distr
    if(item_holder !==undefined){
      item_distr = Object.entries(item_holder).map(([key,value]) =>{
        return(
          {value:key, option_label:value}
        )
      })
    }

    const country_holder=(input)=>{
      let starter=this.props.data.user_country
      if(input===""){
      return(starter)
      } else{
        return(input)
      }
    }
    const country_selection=country_holder(this.props.data.user_invoice_country)

    const options=[
      {value:true, option_label:this.props.trsl["individual"]},
      {value:false, option_label:this.props.trsl["corporate"]}
    ]
    const handleChange = (e) => {
      const event={
        target:{
          value:e.target.value,
          name:'user_invoice_corporate'
        }
      }
      this.props.handleEditUser(event)
    }

    let invoice_selection=true
    if(this.props.data.user_invoice_corporate===false ||this.props.data.user_invoice_corporate==="false"){
      invoice_selection=false
    }

    return(
      <Row className="edit_profile_customization">
        <Col xs={12} md={12}>
          <h3>{user_c.i18n.t('USER.Edit_profile.invoicing')}</h3>
        </Col>
          <ToogleButtons xs={12} md={12} label={this.props.trsl["type_of_invoice"]} toogle_data={options} selected={invoice_selection}  handleToogleButton={handleChange}/>
          
          <SingleInputForm 
          xs={12} md={8}
          name="user_invoice_name" 
          value={this.props.data.user_invoice_name} 
          handleEditItemChange={this.props.handleEditUser} 
          label={user_c.i18n.t('USER.Edit_profile.invocing_name')} />
          <SingleInputForm 
          xs={12} md={4}
          name="user_invoice_vat_id" 
          value={this.props.data.user_invoice_vat_id} 
          handleEditItemChange={this.props.handleEditUser}
          label={user_c.i18n.t('USER.Edit_profile.invoicing_vat')} />
          <SingleInputForm 
          xs={12} md={8}
          name="user_invoice_address" 
          value={this.props.data.user_invoice_address} 
          handleEditItemChange={this.props.handleEditUser}
          label={user_c.i18n.t('USER.Edit_profile.invoicing_address')} />
          <Dropdown xs={12} md={4} label={user_c.i18n.t('USER.Edit_profile.country')} name="user_invoice_country" value={country_selection} options={item_distr} onChange={this.props.handleEditUser}/>
      </Row>
    );
  }
}

class ProfilePicture extends React.Component{
  static contextType = UserContext
  render(){
    let user_c= this.context
    return(
      <Row>
        <Col xs={12}>
          <h3>{user_c.i18n.t('USER.Edit_profile.select_an_avatar')}</h3>
        </Col>
        <Col xs={12}>
          <Avatar data={this.props.data} handleSelectAvatar={this.props.handleSelectAvatar}/>
        </Col>
      </Row>
    )
  }
}

class Avatar extends React.Component{
  render(){
    const list_of_avatars=[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24]
    const renderizing=list_of_avatars.map((value)=>{
      return(
        <>
          <SingleAvatar avatname={value} data={this.props.data} handleSelectAvatar={this.props.handleSelectAvatar}/>
        </>
      )
    })
    
    return(
      <Form>
        <Row center="xs">
          {renderizing}
        </Row>
      </Form>
    )
  }
}

function SingleAvatar (props){
  return(
    <Col xs={3} md={2} className="edit_profile_avatar_row">
      <img 
      className="edit_profile_avatar"
      src={require(`images/avatars/user-${props.avatname}.png`).default} 
       />
      <Form.Check
      type="radio"
      value={props.avatname}
      checked={props.data.user_avatar == props.avatname}
      onChange={props.handleSelectAvatar}  />
    </Col>
  )
}

function LanguageChange(props){
  const options=[
    {value:'pt',option_label:props.trsl["i18n_pt"]},
    {value:"en",option_label:props.trsl["i18n_en"]}
  ]
  return(
    <Row>
      <Col xs={12} className="edit_profile_customization">
        <h3>{props.trsl["other_options"]}</h3>
      </Col>
        <ToogleButtons xs={12} md={12} label={props.trsl["change_language"]} toogle_data={options} selected={props.data.user_locale} handleToogleButton={props.handleLocale}/>

    </Row>
  )
}

class ChangePasswordButton extends React.Component{
  static contextType = UserContext
  render(){
    const props=this.props
    let user_c= this.context

    let RenderPassword;
    if(this.props.change_Password_Is_On){
      RenderPassword=<PasswordForm {...props}/>;
    }
    else{
      RenderPassword="";
    }

    return(
      <Row className="edit_profile_customization">
        <Col xs={12} md={12} >
          <h3>{user_c.i18n.t('USER.Edit_profile.password')}</h3>
        </Col>
        <Col xs={12} md={12} >
          <div className="edit_profile_password_area">
            <Button block size="sm" onClick={this.props.ActivatePassword}>
              {user_c.i18n.t('USER.Edit_profile.change_password')}
            </Button>
            {RenderPassword}
          </div>
        </Col>
      </Row>
    );
  }
}

class PasswordForm extends React.Component { 
  constructor(props) {
    super(props);
    this.state = {
      old_password:"",
      new_password:"",
      retype_new_password:"",
      different_pass:false
    }
    this.handleEditUserPassword=this.handleEditUserPassword.bind(this)
  }

  handleSubmitNewPassword=async()=>{
    let props=this.props
    const request={
      old_password:this.state.old_password,
      new_password: this.state.new_password,
      retype_new_password:this.state.retype_new_password
    }
    try{
      const r= window.confirm(props.trsl['window_confirm'])
      let response=""
      if(r===true){
        if(
          this.state.old_password>0 ||
          this.state.new_password>7 ||
          this.state.different_pass===false
          ){
          response= await ApiConnect.post(`/api/user/password/modify`, request)
          alert(response.data)
        } else {
          alert ('Cannot change the password, please check the password rules')
        }
      }
    } catch(error) {
      alert(`${error.response.status} ${error.response.data}`);
    }
  }

  handleEditUserPassword=async(event)=>{
    const item =event.target.name
    const value = event.target.value;
    let go = this.state;
    go[item]=value
    if(go.new_password===go.retype_new_password){
      go.different_pass=false
    } else{
      go.different_pass=true
    }
    this.setState({
      data:go
    })
  }

  
  render(){
    const props=this.props
    function renderDifferentPassword(input){
      if(input===false){
        return(
          <div className="edit_profile_password_match">
            {props.trsl['password_matching']}
          </div>
        )
      } else{
        return(
          <div className="edit_profile_password_doesnot_match">
            {props.trsl['password_not_matching']}
        </div>
        )
      }
    }
    const renderizing=renderDifferentPassword(this.state.different_pass)

    return(
      <Row className="profile_password_col">
        <Col md={6} >
          <Row>
            <SingleInputPasswordForm
            xs={12} sm={12} md={12}
            label={this.props.trsl['type_old_password']}
            name="old_password" 
            value={this.state.old_password} 
            handleinputeditChange={this.handleEditUserPassword} />
            <SingleInputPasswordForm
            xs={12} sm={12} md={12}
            label={this.props.trsl['type_new_password']}
            name="new_password" 
            value={this.state.new_password} 
            handleinputeditChange={this.handleEditUserPassword} />
            <SingleInputPasswordForm
            xs={12} sm={12} md={12}
            label={this.props.trsl['retype_new_password']}
            name="retype_new_password" 
            value={this.state.retype_new_password} 
            handleinputeditChange={this.handleEditUserPassword} />
            <Col xs={12}>
              <Button block className="edit_profile_save_password_button" onClick={this.handleSubmitNewPassword}>
                {this.props.trsl['save_new_pass']}
              </Button>
            </Col>
          </Row>
        </Col>
        <Col md={6}>
          <h6>{this.props.trsl['password_rules']}</h6>
          {this.props.trsl['password_rules_text']}
          {renderizing}
        </Col> 
      </Row>
    );
  }
}

class DeleteUserButton extends React.Component{
  static contextType = UserContext
  render(){
    let user_c= this.context
    return(
      <Row className="edit_profile_customization">
        <Col xs={12} md={12} >
          <h3>{user_c.i18n.t('USER.Edit_profile.delete_user_button')}</h3>
        </Col>
        <Col xs={12} md={12} >
        <Button block size="sm" variant="danger" onClick={this.props.onClick} className="edit_profile_password_button">
          {user_c.i18n.t('USER.Edit_profile.delete_user_button')}
        </Button>
        </Col>
      </Row>
    );
  }
}

class DeleteUser extends React.Component{
  static contextType = UserContext
  
  constructor(props) {
    super(props);
    this.state = {
      delete_user_option:false
    }
    this.handleChecker=this.handleChecker.bind(this)
    this.handleSubmitDelete=this.handleSubmitDelete.bind(this)
  }

  handleChecker(){
    let go=this.state.delete_user_option
    this.setState({
      delete_user_option:!go
    })
  }

  handleSubmitDelete=async()=>{
    try{
      const response= await ApiConnect.post(`/api/user/delete`)
      alert(response.data)
      const user_c= this.context
      user_c.unSetUser()
    } catch(error){
      alert(error)
    }
  }

  render(){
    let user_c= this.context
    return(
      <div className="edit_profile_delete_user_area">
        <p>
          {user_c.i18n.t('USER.Edit_profile.delete_user')}
        </p>
        <Form>
          <Form.Check 
          type="switch"
          checked={this.state.delete_user_option}
          id="switch"
          onChange={this.handleChecker}
          label={user_c.i18n.t('USER.Edit_profile.delete_user_understanding')}/>
          <div className="edit_profile_delete_user_submit">
            <Button disabled={!this.state.delete_user_option} onClick={this.handleSubmitDelete}>
              {user_c.i18n.t('USER.Edit_profile.delete_user_button')}
            </Button>
          </div>   
        </Form>
      </div>
    )
  }
}

export default Editprofile;