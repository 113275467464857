class BudgetProjectCreator{
  constructor(name, description, settings){
    this.name=name
    this.description=description
    this.country=settings.country
    this.currency=settings.currency
    this.irc=settings.irc
    this.corporate_social_security=settings.corporate_social_security
    this.vat=settings.vat
    this.vat_payment=settings.vat_payment
    this.working_months=settings.working_months
    this.raw_materials=settings.raw_materials
    this.employees=settings.employees
    this.nature_or_functions=settings.nature_or_functions
    this.use_general_vat_rate=settings.use_general_vat_rate
    this.decimal_on_reports=settings.decimal_on_reports
    this.use_historic=settings.use_historic
  }
}

export {
  BudgetProjectCreator
};