import React from 'react';
import { Form, ToggleButton, ToggleButtonGroup, InputGroup,Button } from 'react-bootstrap';
import { Row, Col, Col_NoGutter } from 'Support/grid';

import UserContext from 'CONFIG/auth';
import './CSS/support.css'

import { RegularButtonSmall } from './listbuttons';
import { ToogleButtons } from './toogle_buttons';

const checker=(reference)=>{
  if (isNaN(reference)){
  return 0
  } else{return reference}
}

class FormEdit extends React.Component {
  handleEqualizerForward=(event)=>{
    event.preventDefault()
    const id = this.props.id;
    const value = event.target.value;
    let go = this.props.item_holder;
    if(this.props.use_historic===true){
      go.fill(value,1+parseInt(event.target.name))
    } else{
      go.fill(value, parseInt(event.target.name));
    } 
    this.props.handleForm(id,go)
  }

  handleEqualizerBackward=(event)=>{
    event.preventDefault()
    const id = this.props.id;
    const value = event.target.value;
    let go = this.props.item_holder;
    if(this.props.use_historic===true){
      go.fill(value,1,parseInt(event.target.name))
    } else{
      go.fill(value, 0, parseInt(event.target.name));
    } 
    this.props.handleForm(id,go)
  }

  handleChange=(event)=>{
    event.preventDefault()
    const value =(this.sanitizeNumber(event.target.value))
    const name = event.target.name;
    const id = this.props.id;
    let go = this.props.item_holder;
    go[name]=value;
    this.props.handleForm(id,go)
  }

  sanitizeNumber=(str)=>{
    let sanitizer
    if((/d*(\.\d{3})/g).test(str)){
      sanitizer =str.replace('.','')
      sanitizer=(parseFloat(sanitizer)/100).toFixed(2)
    }
    else if((/d*(\.\d{1})/g).test(str)){
      sanitizer =str.replace('.','')
      sanitizer=(parseFloat(sanitizer)/100).toFixed(2)
    } else{
      sanitizer=(checker(parseFloat(str))).toFixed(2)
    }
    return(sanitizer)
  }

  render(){
    const item_holder = this.props.item_holder
    let item_distr
    if(item_holder !==undefined && !this.props.use_historic){
      item_distr = item_holder.map((value, key) =>
        <InputEdit 
        value={value} 
        name={key}
        handleChange={this.handleChange}
        handleEqualizerForward={this.handleEqualizerForward}
        handleEqualizerBackward={this.handleEqualizerBackward}
        />
      )
    } else if (item_holder !==undefined) {
      item_distr = item_holder.map((value, key) =>{
        if(key<1){
          return(
            <InputEdit 
            disabled={true}
            value={value} 
            name={key}
            handleChange={this.handleChange}
            handleEqualizerForward={this.handleEqualizerForward}
            handleEqualizerBackward={this.handleEqualizerBackward}
            />
          )
        }
        else{
          return(
            <InputEdit 
            value={value} 
            name={key}
            handleChange={this.handleChange}
            handleEqualizerForward={this.handleEqualizerForward}
            handleEqualizerBackward={this.handleEqualizerBackward}
            />
          )
        }
      })
    } else {
      item_distr = "Item does not exist"
    }
  
    return(
      <Form>
        <Row className="input_form_edit_row grid_middle">
          <Col xs={12} md={2}>
            {this.props.label} - {this.props.unit}
          </Col>
          <Col xs={12} md={10} className="support_non_editable_form_row">
            {item_distr}
          </Col>
        </Row>
      </Form>
    );
  }
}

class FormEditWithNameChange extends React.Component {
  handleEqualizerForward=(event)=>{
    event.preventDefault()
    const id = this.props.id;
    const value = event.target.value;
    let go = this.props.item_holder;
    if(this.props.use_historic===true){
      go.fill(value,1+parseInt(event.target.name))
    } else{
      go.fill(value, parseInt(event.target.name));
    } 
    this.props.handleForm(id,go)
  }

  handleEqualizerBackward=(event)=>{
    event.preventDefault()
    const id = this.props.id;
    const value = event.target.value;
    let go = this.props.item_holder;
    if(this.props.use_historic===true){
      go.fill(value,1,parseInt(event.target.name))
    } else{
      go.fill(value, 0, parseInt(event.target.name));
    } 
    this.props.handleForm(id,go)
  }

  handleChange=(event)=>{
    event.preventDefault()
    const value =(this.sanitizeNumber(event.target.value))
    const name = event.target.name;
    const id = this.props.id;
    let go = this.props.item_holder;
    go[name]=value;
    this.props.handleForm(id,go)
  }

  sanitizeNumber=(str)=>{
    let sanitizer
    if((/d*(\.\d{3})/g).test(str)){
      sanitizer =str.replace('.','')
      sanitizer=(parseFloat(sanitizer)/100).toFixed(2)
    }
    else if((/d*(\.\d{1})/g).test(str)){
      sanitizer =str.replace('.','')
      sanitizer=(parseFloat(sanitizer)/100).toFixed(2)
    } else{
      sanitizer=(checker(parseFloat(str))).toFixed(2)
    }
    return(sanitizer)
  }

  render(){
    const item_holder = this.props.item_holder
    let item_distr
    if(item_holder !==undefined && !this.props.use_historic){
      item_distr = item_holder.map((value, key) =>
        <InputEdit 
        value={value} 
        name={key}
        handleChange={this.handleChange}
        handleEqualizerForward={this.handleEqualizerForward}
        handleEqualizerBackward={this.handleEqualizerBackward}
        />
      )
    } else if (item_holder !==undefined) {
      item_distr = item_holder.map((value, key) =>{
        if(key<1){
          return(
            <InputEdit 
            disabled={true}
            value={value} 
            name={key}
            handleChange={this.handleChange}
            handleEqualizerForward={this.handleEqualizerForward}
            handleEqualizerBackward={this.handleEqualizerBackward}
            />
          )
        }
        else{
          return(
            <InputEdit 
            value={value} 
            name={key}
            handleChange={this.handleChange}
            handleEqualizerForward={this.handleEqualizerForward}
            handleEqualizerBackward={this.handleEqualizerBackward}
            />
          )
        }
      })
    } else {
      item_distr = "Item does not exist"
    }
  
    return(
      <Form>
        <Row className="input_form_edit_row grid_middle">
          <Col xs={12} md={2}>
            <div className="support_non_editable_form_row_item" >
              <div className="input_edit_element_form_group" >
                <input className="input_edit_element" type='text' value={this.props.label} name={this.props.name} id={this.props.id} onChange={this.props.handleNameChange} />
              </div>
            </div>
          </Col>
          <Col xs={12} md={10} className="support_non_editable_form_row">
            {item_distr}
          </Col>
        </Row>
      </Form>
    );
  }
}

class FormEditInflation extends React.Component {
  handleEqualizerForward=(event)=>{
    event.preventDefault()
    const id = this.props.id;
    const value = event.target.value;
    let go = this.props.item_holder;
    if(this.props.use_historic===true){
      go.fill(value,1+parseInt(event.target.name))
    } else{
      go.fill(value, parseInt(event.target.name));
    } 
    this.props.handleForm(id,go)
  }

  handleEqualizerBackward=(event)=>{
    event.preventDefault()
    const id = this.props.id;
    const value = event.target.value;
    let go = this.props.item_holder;
    if(this.props.use_historic===true){
      go.fill(value,1,parseInt(event.target.name))
    } else{
      go.fill(value, 0, parseInt(event.target.name));
    } 
    this.props.handleForm(id,go)
  }

  handleChange=(event)=>{
    event.preventDefault()
    const value =(this.sanitizeNumber(event.target.value))
    const name = event.target.name;
    const id = this.props.id;
    let go = this.props.item_holder;
    go[name]=value;
    this.props.handleForm(id,go)
  }

  sanitizeNumber=(str)=>{
    let sanitizer
    if((/d*(\.\d{3})/g).test(str)){
      sanitizer =str.replace('.','')
      sanitizer=(parseFloat(sanitizer)/100).toFixed(2)
    }
    else if((/d*(\.\d{1})/g).test(str)){
      sanitizer =str.replace('.','')
      sanitizer=(parseFloat(sanitizer)/100).toFixed(2)
    } else{
      sanitizer=(checker(parseFloat(str))).toFixed(2)
    }
    return(sanitizer)
  }
  render(){
      const item_holder = this.props.item_holder
      let item_distr
      if(item_holder !==undefined){
        item_distr = item_holder.map((value, key) =>{
          if(key===0){
            return(
              <div className="support_non_editable_form_row_item" ></div>
            )
          } else{
            return(
              <InputEdit
              value={value} 
              name={key}
              id={this.props.id}
              handleChange={this.handleChange}
              handleEqualizerForward={this.handleEqualizerForward}
              handleEqualizerBackward={this.handleEqualizerBackward}
              unit={this.props.unit}
              />
            )
          }
        });
      } else {
        item_distr = "Item does not exist"
      }

  return(
      <Form>
          <Row middle="xs" className="input_form_edit_row grid_middle">
            <Col xs={12} md={2}>
              {this.props.label}
            </Col >
            <Col xs={12} md={10} className="support_non_editable_form_row">
              {item_distr}
            </Col>
          </Row>
      </Form>
    );
  }
}

class InputEdit extends React.Component{
  static contextType = UserContext
  
  constructor(props) {
    super(props);
    this.state = {
      focus:false
    }
  }

  handleFocus=()=>{
    let go=this.state.focus
    this.setState({
      focus:!go
    })
  }

  render(){
    let user_c= this.context
    let val
    if(this.state.focus){
      val=(parseFloat(checker(this.props.value))).toFixed(2)
    } else{
      val=user_c.i18n.deciF(checker(this.props.value),true)
    }

    return(
      <div className="support_non_editable_form_row_item" >
        <div className="input_edit_element_form_group" >
          <button disabled={this.props.disabled} value={this.props.value} name={this.props.name} className="spt_equal_button_form_group" onClick={this.props.handleEqualizerBackward}>«</button>
          <input className="input_edit_element" type='text' value={val} name={this.props.name} onChange={this.props.handleChange} onFocus={this.handleFocus} onBlur={this.handleFocus} disabled={this.props.disabled} />
          <button disabled={this.props.disabled} value={this.props.value} name={this.props.name} className="spt_equal_button_form_group" onClick={this.props.handleEqualizerForward}>»</button>
        </div>
      </div>
    );
  }
}

class FormEditInteger extends React.Component {
  handleEqualizerForward=(event)=>{
    event.preventDefault()
    const id = this.props.id;
    const value = event.target.value;
    let go = this.props.item_holder;
    if(this.props.use_historic===true){
      go.fill(value,1+parseInt(event.target.name))
    } else{
      go.fill(value, parseInt(event.target.name));
    } 
    this.props.handleForm(id,go)
  }

  handleEqualizerBackward=(event)=>{
    event.preventDefault()
    const id = this.props.id;
    const value = event.target.value;
    let go = this.props.item_holder;
    if(this.props.use_historic===true){
      go.fill(value,1,parseInt(event.target.name))
    } else{
      go.fill(value, 0, parseInt(event.target.name));
    } 
    this.props.handleForm(id,go)
  }

  handleChange=(event)=>{
    event.preventDefault()
    const name = event.target.name;
    const id = this.props.id;
    let go = this.props.item_holder;
    go[name]=event.target.value;
    this.props.handleForm(id,go)
  }

  render(){
      const item_holder = this.props.item_holder
      let item_distr
      if(item_holder !==undefined){
        item_distr = item_holder.map((value, key) =>
            <InputEditInteger 
            value={value} 
            name={key}
            id={this.props.id}
            handleChange={this.handleChange}
            handleEqualizerForward={this.handleEqualizerForward}
            handleEqualizerBackward={this.handleEqualizerBackward}
            unit={this.props.unit}
            max={this.props.max}
            min={this.props.min}
            step={this.props.step}
            />
        );
      } else {
        item_distr = "Item does not exist"
      }

    return(
      <Form>
        <Row middle="xs" className="input_form_edit_row grid_middle">
          <Col xs={2}>
            {this.props.label}
          </Col>
          <Col xs={10} className="support_non_editable_form_row">
            {item_distr}
          </Col>
        </Row>
      </Form>
    );
  }
}
class InputEditInteger extends React.Component{
  render(){
    let val=12
    if(this.props.value >= this.props.min && this.props.value <=this.props.max) {val = this.props.value}
    return(
      <div className="support_non_editable_form_row_item" >
      <div className="input_edit_element_form_group">
        <button disabled={this.props.disabled} value={this.props.value} name={this.props.name} className="spt_equal_button_form_group" onClick={this.props.handleEqualizerBackward}>«</button>
        <input className="input_edit_element" type='number' value={val} name={this.props.name} onChange={this.props.handleChange} onFocus={this.handleFocus} onBlur={this.handleFocus} disabled={this.props.disabled} />
        <button disabled={this.props.disabled} value={this.props.value} name={this.props.name} className="spt_equal_button_form_group" onClick={this.props.handleEqualizerForward}>»</button>
      </div>
    </div>

    );
  }
}

class NonEditableForm extends React.Component {
  render(){
    const item_holder = this.props.item_holder   
    let item_distr
    if(item_holder !==undefined){
      item_distr = item_holder.map((value) =>
        <NonEditableInput 
        value={value} 
        value_type={this.props.value_type}
        currency={this.props.currency}
        decimals={this.props.decimals}
        />
      );
    } else {
      item_distr="no item"
    }
    
    return(
      <Row middle='xs'className="non_editable_form_row">
        <Col xs={12} md={2}>
          {this.props.label}
        </Col>
        <Col xs={12} md={10} className="support_non_editable_form_row">
          {item_distr}
        </Col>
      </Row>
    );
  }
}

class NonEditableInput extends React.Component{

  static contextType = UserContext

  render(){
    let user_c= this.context
    const checkvalue = checker(this.props.value)
    let val
    if(this.props.value_type==="currency"){
      val=user_c.i18n.currF(checkvalue, this.props.currency,this.props.decimals)
    } else if(this.props.value_type==="percentage"){
      val=user_c.i18n.percF(checkvalue)
    } else{
      val= user_c.i18n.deciF(checkvalue,this.props.decimals)
    }
    return(
      <div className="support_non_editable_form_row_item" >
        {val}
      </div>
    );
  }
}

function FeasibilityList (props) {
  const item_holder = props.item_holder 
  let item_distr
  if(item_holder !==undefined){
    item_distr = item_holder.map((value) =>
      <NonEditableInputForList 
      value={value} 
      value_type={props.value_type}
      currency={props.currency}
      />
    );
  } else {
    item_distr="no item"
  }
  
  return(
    <>
      <Col xs={props.label_xs} md={props.label_md} className="support_non_editable_form_row">
        {props.label}
      </Col>
      <Col xs={props.values_xs} md={props.values_md} className="support_non_editable_form_row">
        {item_distr}
      </Col>
    </>
  );
}

function FeasibilityListTotal (props) {
  const item_holder = props.item_holder 
  let item_distr
  if(item_holder !==undefined){
    item_distr = item_holder.map((value) =>
      <NonEditableInputForList 
      value={value} 
      value_type={props.value_type}
      currency={props.currency}
      />
    );
  } else {
    item_distr="no item"
  }
  
  return(
    <>
      <Col xs={props.label_xs} md={props.label_md} className="support_non_editable_form_row_total">
        {props.label}
      </Col>
      <Col xs={props.values_xs} md={props.values_md} className="support_non_editable_form_row_total">
        {item_distr}
      </Col>
    </>
  );
}

class BudgetListTotal extends React.Component {
  render(){
    const item_holder = this.props.item_holder 
    let item_distr_month
    if(item_holder !==undefined){
      item_distr_month = item_holder.map((value,key) =>
        <Col xs={3}>
          <NonEditableInputForBudgetList 
          value={value} 
          value_type={this.props.value_type}
          currency={this.props.currency}
          period={this.props.trsl[this.props.periods[key]]}
          />
        </Col>
      );
    } else {
      item_distr_month="no item"
    }

    let item_distr_total
    if(item_holder !==undefined){
      item_distr_total = 
        <Col xs={3}>
          <NonEditableInputForList 
          value={item_holder.reduce((accumulator, currentValue) => {return accumulator + currentValue},0)} 
          value_type={this.props.value_type}
          currency={this.props.currency}
          />
        </Col>
    } else {
      item_distr_month="no item"
    }
    
    if(this.props.toogle === false){
      return(
        <>
          <Col xs={12} md={2} className="support_non_editable_form_row_total">
            {this.props.label}
          </Col>
          <Col xs={12} md={5} className="support_non_editable_form_row_total">
            <Row>
              {item_distr_month}
            </Row>
          </Col>
        </>
      );
    } else return(
      <>
      <Col xs={12} md={2} className="support_non_editable_form_row_total">
        {this.props.label}
      </Col>
      <Col xs={12} md={5} className="support_non_editable_form_row_total">
        <Row>
          {item_distr_total}
        </Row>
      </Col>
    </>
    )
  }
}

class BudgetList extends React.Component {
  render(){
    const item_holder = this.props.item_holder 
    let item_distr_month
    if(item_holder !==undefined){
      item_distr_month = item_holder.map((value,key) =>
        <Col xs={3}>
          <NonEditableInputForBudgetList 
          value={value} 
          value_type={this.props.value_type}
          currency={this.props.currency}
          period={this.props.trsl[this.props.periods[key]]}
          />
        </Col>
      );
    } else {
      item_distr_month="no item"
    }

    let item_distr_total
    if(item_holder !==undefined){
      item_distr_total = 
        <Col xs={3}>
          <NonEditableInputForList 
          value={item_holder.reduce((accumulator, currentValue) => {return accumulator + currentValue},0)} 
          value_type={this.props.value_type}
          currency={this.props.currency}
          />
        </Col>
    } else {
      item_distr_month="no item"
    }
    
    if(this.props.toogle === false){
      return(
        <>
          <Col xs={12} md={2} className="support_non_editable_form_row_total">
            {this.props.label}
          </Col>
          <Col xs={12} md={5} className="support_non_editable_form_row_total">
            <Row>
              {item_distr_month}
            </Row>
          </Col>
        </>
      );
    } else return(
      <>
      <Col xs={12} md={2} className="support_non_editable_form_row_total">
        {this.props.label}
      </Col>
      <Col xs={12} md={5} className="support_non_editable_form_row_total">
        <Row>
          {item_distr_total}
        </Row>
      </Col>
    </>
    )
  }
}



class NonEditableInputForList extends React.Component{

  static contextType = UserContext

  render(){
    let user_c= this.context
    const checkvalue = checker(this.props.value)
    let val
    if(this.props.value_type==="currency"){
      val=user_c.i18n.currF(checkvalue, this.props.currency,this.props.decimals)
    } else if(this.props.value_type==="percentage"){
      val=user_c.i18n.percF(checkvalue)
    } else{
      val= user_c.i18n.deciF(checkvalue)
    }
    return(
      <div className="support_non_editable_form_row_item" >
        {val}
      </div>
    );
  }
}

class NonEditableInputForBudgetList extends React.Component{

  static contextType = UserContext

  render(){
    let user_c= this.context
    const checkvalue = checker(this.props.value)
    let val
    if(this.props.value_type==="currency"){
      val=user_c.i18n.currF(checkvalue, this.props.currency,this.props.decimals)
    } else if(this.props.value_type==="percentage"){
      val=user_c.i18n.percF(checkvalue)
    } else{
      val= user_c.i18n.deciF(checkvalue)
    }
    return(
      <div className="support_non_editable_form_row_item" >
        {`${val}: ${this.props.period}`}
      </div>
    );
  }
}

class SingleInputForm extends React.Component {
  render(){
    return(
      <Col xs={this.props.xs} sm={this.props.sm} md={this.props.md} >
        <Col xs={12} className='support_input_group'>
            <Row className="grid_middle">
              <Col xs={12} className='support_input_forms_vertical_label'>
                {this.props.label}
              </Col>
              <input
                type="text"
                name={this.props.name}
                value={this.props.value}
                placeholder={this.props.placeholder}
                onChange={this.props.handleEditItemChange}/>
            </Row>
          </Col>
        </Col>
    );
  }
}

class SingleInputFormWithConfirm extends React.Component {
  render(){
    return(
      <Col xs={this.props.xs} sm={this.props.sm} md={this.props.md}>
        <Col xs={12} className='support_input_group'>
          <Row middle='xs'>
            <Col xs={12} className='support_input_forms_vertical_label'>
              {this.props.label}
            </Col>
            <input
              type="text"
              name={this.props.name}
              value={this.props.value}
              placeholder={this.props.placeholder}
              onChange={this.props.handleEditItemChange}/>
            <RegularButtonSmall label={this.props.btn_label} bk={this.props.bk} name={this.props.name} value={this.props.value} onClick={this.props.onClick} disabled={this.props.disabled}/>
          </Row>
        </Col>
      </Col>
    );
  }
}

class SingleDateForm extends React.Component {
  render(){
    return(
      <Col xs={this.props.xs} sm={this.props.sm} md={this.props.md}>
        <Col xs={12} className='support_input_group'>
        <Row middle='xs'>
          <Col xs={12} className='support_input_forms_vertical_label'>
            {this.props.label}
          </Col>
          <Col xs={12} >
            <form>
              <input
                name={this.props.name}
                type="date" 
                value={this.props.value}
                onChange={this.props.onChange}/>
            </form>
          </Col>
        </Row>
        </Col>
      </Col>
    );
  }
}

class SingleInputEmailForm extends React.Component {
  render(){
    return(
      <Col xs={this.props.xs} sm={this.props.sm} md={this.props.md}>
        <Col xs={12} className='support_input_group'>
        <Row>
          <Col xs={12} className='support_input_forms_vertical_label'>
            {this.props.label}
          </Col>
          <input
            type="email"
            name={this.props.name}
            value={this.props.value}
            placeholder={this.props.placeholder}
            onChange={this.props.handleEditItemChange}/>
        </Row>
        </Col>
      </Col>
    );
  }
}

class SingleInputLargeForm extends React.Component {
  render(){
    let len = 0
    if(this.props.value !== undefined){
      len=this.props.value.length
    }
    return(
      <Col xs={this.props.xs} sm={this.props.sm} md={this.props.md}>
        <Col xs={12} className='support_input_group'>
          <Row middle='xs'>
            <Col xs={12} className='support_input_forms_vertical_label'>
              {this.props.label}
            </Col>
            <textarea
            rows={this.props.rows}
            name={this.props.name}
            id={this.props.id}
            value={this.props.value}
            maxlength={this.props.maxlength}
            placeholder={this.props.placeholder}
            onChange={this.props.handleEditItemChange}/>    
            <Col xs={12} className='bk_info'>
              {len} / {this.props.maxlength}
            </Col>   
          </Row>
        </Col>
      </Col>
    );
  }
}

class SingleInputFormFlat extends React.Component {
  render(){
    return(
      <Col xs={this.props.xs} sm={this.props.sm} md={this.props.md} >
        <Col xs={12} className='support_input_group'>
          <Row middle='xs'>
            <Col xs={12} md={4} className='support_input_forms_horizontal_label'>
              {this.props.label}
            </Col>
            <Col xs={12} md={8} >
              <input
              type="text"
              name={this.props.name}
              value={this.props.value}
              placeholder={this.props.placeholder}
              onChange={this.props.handleEditItemChange}/>
            </Col>
          </Row>
          </Col>
      </Col>
    );
  }
}

class SingleInputPasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible:false
    }
  }

  handleVisiblePassword=()=>{
    let go=""
    if(this.state.visible===false){
      go=true
    } else {
      go= false
    }
    this.setState({
      visible:go
    })
  }

  render(){
    function showPassword(input){
      let bug=false
      if(input===true){
        bug="text"
      } else {
        bug="password"
      }
      return bug
    }
    const show=showPassword(this.state.visible)

    return(
      <Col xs={this.props.xs} sm={this.props.sm} md={this.props.md} >
        <Col xs={12} className='support_input_group'>
          <Row >
            <Col xs={12} className='support_input_forms_vertical_label'>
              {this.props.label}
            </Col>
            <input
            type={show}
            name={this.props.name}
            value={this.props.value}
            placeholder={this.props.placeholder}
            onChange={this.props.handleinputeditChange}/>
            <RegularButtonSmall bk="bk_darkaccent" label={"I/O"} onClick={this.handleVisiblePassword} />
            </Row>
          </Col>
        </Col>
    );
  }
}

class SingleInputNumberForm extends React.Component {
  static contextType = UserContext
  
  constructor(props) {
    super(props);
    this.state = {
      focus:false
    }
  }
  sanitizeNumber=(str)=>{
    let sanitizer
    sanitizer=str.replace(/\D/g,'')
    sanitizer=parseFloat(sanitizer)/100
    sanitizer =sanitizer.toFixed(2)
    return sanitizer
  }

  handleChange=(event)=>{
    event.target.value=(this.sanitizeNumber(event.target.value))
    this.props.handleinputeditChange(event)
  }

  handleFocus=()=>{
    let go=this.state.focus
    this.setState({
      focus:!go
    })
  }
  render(){
    let user_c= this.context
    let val
    if(this.state.focus){
      val=(parseFloat(checker(this.props.value))).toFixed(2)
    } else{
      val=user_c.i18n.deciF(checker(this.props.value),true)
    }
  
    return(
      <Col xs={this.props.xs} sm={this.props.sm} md={this.props.md}>
        <Col xs={12} className='support_input_group'>
          <Row middle='xs'>
            <Col xs={12} className='support_input_forms_vertical_label'>
              {this.props.label}
            </Col>
            <Col xs={10}>
            <input
              className="support_forms_align_right"
              name={this.props.name}
              value={val}
              placeholder={this.props.placeholder}
              onFocus={this.handleFocus}
              onBlur={this.handleFocus}
              onChange={this.handleChange}/>
            </Col>
            <Col xs={2} className="grid_middle">
              {this.props.unit}
            </Col>
          </Row>
        </Col>
      </Col>
    );
  }
}

class DisabledSingleInputNumberForm extends React.Component {
  static contextType = UserContext
  
  constructor(props) {
    super(props);
    this.state = {
      focus:false
    }
  }
  sanitizeNumber=(str)=>{
    let sanitizer
    sanitizer=str.replace(/\D/g,'')
    sanitizer=parseFloat(sanitizer)/100
    sanitizer =sanitizer.toFixed(2)
    return sanitizer
  }

  render(){
    let user_c= this.context
    let val
    if(this.state.focus){
      val=(parseFloat(checker(this.props.value))).toFixed(2)
    } else{
      val=user_c.i18n.deciF(checker(this.props.value),true)
    }
  
    return(
      <Col xs={this.props.xs} sm={this.props.sm} md={this.props.md}>
        <Col xs={12} className='support_input_group_disabled'>
          <Row middle='xs'>
            <Col xs={12} className='support_input_forms_vertical_label_disabled'>
              {this.props.label}
            </Col>
            <Col xs={10} >
                <input
                  className="support_forms_align_right"
                  readOnly
                  disabled
                  name={this.props.name}
                  value={val}
                  placeholder={this.props.placeholder}/>
            </Col>
            <Col xs={2} className="grid_middle">
              {this.props.unit}
            </Col>
          </Row>
        </Col>
      </Col>
    );
  }
}

class SingleInputNumberFormFlat extends React.Component {
  static contextType = UserContext
  
  constructor(props) {
    super(props);
    this.state = {
      focus:false
    }
  }
  sanitizeNumber=(str)=>{
    let sanitizer
    sanitizer=str.replace(/\D/g,'')
    sanitizer=parseFloat(sanitizer)/100
    sanitizer =sanitizer.toFixed(2)
    return sanitizer
  }

  handleChange=(event)=>{
    event.target.value=(this.sanitizeNumber(event.target.value))
    this.props.handleinputeditChange(event)
  }

  handleFocus=()=>{
    let go=this.state.focus
    this.setState({
      focus:!go
    })
  }
  render(){
    let user_c= this.context
    let val
    if(this.state.focus){
      val=(parseFloat(checker(this.props.value))).toFixed(2)
    } else{
      val=user_c.i18n.deciF(checker(this.props.value),true)
    }
    return(
      <Col xs={this.props.xs} md={this.props.md} className='support_input_group'>
          <Col xs={8} className='support_input_forms_horizontal_label'>
            {this.props.label}
          </Col>
          <Col xs={4} className='support_input_forms_single_flat_item'>
          <input
            type='text'
            className="support_forms_align_right"
            readOnly={this.props.readOnly}
            name={this.props.name}
            value={val}
            placeholder={this.props.placeholder}
            onChange={this.handleChange}
            onFocus={this.handleFocus}
            onBlur={this.handleFocus}/>
          </Col>
        </Col>
    );
  }
}

class SingleInputIntegerForm extends React.Component {
  sanitizeNumber=(str)=>{
    let sanitizer
    sanitizer=str.replace(/\D/g,'')
    sanitizer=parseFloat(sanitizer)
    sanitizer =sanitizer.toFixed(0)
    return sanitizer
  }

  handleChange=(event)=>{
    event.target.value=(this.sanitizeNumber(event.target.value))
    this.props.handleinputeditChange(event)
  }
  render(){
    const val = checker(this.props.value)
    return(
      <Col xs={this.props.xs} md={this.props.md}>
        <Col xs={12} className='support_input_group'>
          <Row >
            <Col xs={12} className='support_input_forms_vertical_label'>
              {this.props.label}
            </Col>
            <input
              className="support_forms_align_right"
              type="text"
              readOnly={this.props.readOnly}
              name={this.props.name}
              value={val}
              placeholder={this.props.placeholder}
              onChange={this.handleChange}/>
          </Row>
        </Col>
      </Col>
    );
  }
}

function Dropdown (props) {
  const item_holder = props.options
  const item_distr = item_holder.map((v) =>
    <option value={v.value} disabled={v.disabled}>{v.option_label}</option>
  )
  return(
    <Col xs={props.xs} md={props.md}>
      <Col xs={12} className='support_input_group'>
        <Row>
          <Col xs={12} className='support_input_forms_vertical_label'>
            {props.label}
          </Col>
              <select name={props.name} value={props.value} onChange={props.onChange}>
                {item_distr}
            </select>
        </Row>
      </Col>
    </Col>
  );
}

function DropdownSimple (props) {
  const item_holder = props.options
  const item_distr = item_holder.map((v) =>
    <option value={v.value} disabled={v.disabled}>{v.option_label}</option>
  )

  function runCommand(e){
    const event={
      target:{
        value:e.target.value,
        name:props.name,
        id:props.value
      }
    }
    props.onChange(event)

  }
  return(
    <Col xs={props.xs} md={props.md}>
      <Col xs={12} className='support_input_group_simple'>
        <Row>
              <select value={props.value} onChange={runCommand}>
                {item_distr}
            </select>
        </Row>
      </Col>
    </Col>
  );
}

class RadioSelect extends React.Component{
  render(){
    const item_holder = this.props.item_holder
      let item_distr
      if(item_holder !==undefined){
        item_distr = item_holder.map((value, key) =>{
          return(
            <ToggleButton value={key}>{value}</ToggleButton>
          )
        })
      }

    return (
      <ToggleButtonGroup type="checkbox" value={this.props.selected} onChange={this.props.handleChange}>
        {item_distr}
      </ToggleButtonGroup>
    );
  }
} 

class PeriodSelect extends React.Component{
  static contextType = UserContext
  render(){
    let user_c= this.context

    const toogle_data=[
      {option_label:user_c.i18n.t('INPUT_FORMS.day'), value:365, name:this.props.name},
      {option_label:user_c.i18n.t('INPUT_FORMS.week'), value:52, name:this.props.name},
      {option_label:user_c.i18n.t('INPUT_FORMS.month'), value:12, name:this.props.name},
      {option_label:user_c.i18n.t('INPUT_FORMS.year'), value:1, name:this.props.name}
    ]

    return(
      <ToogleButtons xs={12} md={12} label={this.props.label} selected={this.props.selected} toogle_data={toogle_data} handleToogleButton={this.props.handlePeriodChange}/>
    );
  }
}

class PeriodSelect2 extends React.Component{
  static contextType = UserContext
  render(){
    let user_c= this.context

    const toogle_data=[
      {option_label:user_c.i18n.t('INPUT_FORMS.month'), value:12, name:this.props.name},
      {option_label:user_c.i18n.t('INPUT_FORMS.year'), value:1, name:this.props.name}
    ]

    return(     
      <ToogleButtons xs={this.props.xs} md={this.props.md} label={this.props.label} selected={this.props.selected} toogle_data={toogle_data} handleToogleButton={this.props.handlePeriodChange}/>
    );
  }
}

class PeriodSelectSubYear extends React.Component{
  static contextType = UserContext
  render(){
    let user_c= this.context

    return(
      <Col xs={this.props.xs} md={this.props.md}>
      <Col xs={12}>
      <Row className='support_selects'>
        <Col xs={12} md={4} className='support_input_forms_single_label'>
          {this.props.label}
        </Col>
        <Col xs={12} md={8} className='support_selects_tabs'>
          <ToggleButtonGroup
          size="sm" 
          type="radio" 
          name={this.props.name} 
          value={this.props.selected} 
          onChange={this.props.handlePeriodChange}>
            <ToggleButton value={365}>{user_c.i18n.t('INPUT_FORMS.day')}</ToggleButton>
            <ToggleButton value={52}>{user_c.i18n.t('INPUT_FORMS.week')}</ToggleButton>
            <ToggleButton value={12}>{user_c.i18n.t('INPUT_FORMS.month')}</ToggleButton>
          </ToggleButtonGroup>
        </Col>
      </Row>
      </Col>
      </Col>
    );
  }
}

function Selectacble (props){
  const item_holder = props.options
  const item_distr = item_holder.map((v) =>
    <ToggleButton value={v.value} >{v.option_label}</ToggleButton>
  )
  return(
    <Col xs={props.xs} md={props.md}>
      <Col xs={12}>
        <Row className='support_selectable'>
          <Col xs={12} md={12} className='support_input_forms_vertical_label'>
            {props.label}
          </Col>
          <Col xs={12} md={12} className='support_selects_tabs'>
            <ToggleButtonGroup size="sm" name="radio" value={props.selected} onChange={props.handleChange} >
              {item_distr}
            </ToggleButtonGroup>
          </Col>
        </Row>
      </Col>
    </Col>
  )
}

function RangeSlider(props){
  const item_holder=props.options
  const item_distr= item_holder.map((v)=>{
    return(
      <div className='support_range_label'>{v.label}</div>
    )
  })
  return(
    <>
    <Col xs={props.xs} md={props.md}>
      <Col xs={12} className='support_input_group' >
        <Row >
          <Col xs={12} md={12} className='support_input_forms_vertical_label'>
            {props.label}
          </Col>
          <div className='support_range'>
          <input type="range" id="typeinp"  min="-3" max="3" step='1' value={props.value} onChange={props.onChange}/>
          </div>
          {item_distr}
        </Row>
      </Col>
    </Col>
    </>
  )
}

class PeriodHeader extends React.Component{
  render(){
    const item_holder = this.props.periods
    const item_distr = item_holder.map((value) =>
      <div className='support_year_header_div_distr spt_year_header_div'>
        {value}
      </div>)
    return(
        <Col xs={12} md={12}>
          <Row middle="xs" className="list_table_header">
          <Col xs={12} md={2} className='spt_year_header_div'>
            {this.props.label}
          </Col>
          <Col xs={12} md={10} className="support_year_header_col_flex">
            {item_distr}
          </Col>
          </Row>
        </Col>
    );
  }
}

function PeriodHeaderWithEdit (props){
    const item_holder = props.periods
    const item_distr = item_holder.map((value) =>
      <div className='support_year_header_div_distr spt_year_header_div'>
          {value}
      </div>)
    return(
      <Col xs={12} md={12}>
        <Row middle="xs" className="list_table_header">
          <Col xs={props.label_xs} md={props.label_md} className='spt_year_header_div'>
            {props.label}
          </Col>
          <Col xs={props.values_xs} md={props.values_md} className="support_year_header_col_flex">
            {item_distr}
          </Col>
          <Col xs={props.actions_xs} md={props.actions_md} className='spt_year_header_div'>
            <div onClick={props.handleSortRequest}>
              {props.edit_label}
            </div>
          </Col>
        </Row>
      </Col>
    );
}

class BudgetPeriodHeaderWithEdit extends React.Component{
  render(){
    return(
      <Col xs={12} md={12}>
        <Row middle="xs" className="list_table_header">
          <Col xs={12} md={2} className='spt_year_header_div'>
              {this.props.label1}
          </Col>
          <Col xs={12} md={5} className="support_year_header_col_flex spt_year_header_div">
          {this.props.label2}
          </Col>
          <Col xs={12} md={2} className='support_year_header_div_distr spt_year_header_div'>
            <div >
            {this.props.label3}
            </div>
          </Col>
          <Col xs={12} md={3} className='spt_year_header_div'>
            <div>
            {this.props.label4}
            </div>
          </Col>
        </Row>
      </Col>
    );
  }
}

class Observations extends React.Component{
  static contextType = UserContext
  render(){
    let user_c= this.context
    return(
      <Col xs={this.props.xs} sm={this.props.sm} md={this.props.md}>
        <Col xs={12} className='support_input_group'>
          <Row middle='xs' className='support_vertical_inputs'>
            <Col xs={12} className='support_input_forms_vertical_label'>
              {user_c.i18n.t('INPUT_FORMS.observations')}
            </Col>
        <form>
            <textarea name={this.props.name} value={this.props.value} rows="4" onChange={this.props.handleObservationsChange}/>
        </form>
        </Row>
        </Col>
      </Col>
    )
  }
}


function FilterList (props){
  return(
    <Col xs={props.xs} sm={props.sm} md={props.md}>
      <Col xs={12} className='support_input_group'>
        <Row middle='xs'>
          <Col xs={12} className='support_input_forms_vertical_label'>
            {props.label}
          </Col>
          <input type='text' placeholder={props.placeholder} onChange={props.filterTheTable}/>
        </Row>
      </Col>
    </Col>
  )
}

export{ 
  FormEdit,
  FormEditWithNameChange,
  FormEditInteger,
  FormEditInflation,
  NonEditableForm,
  FeasibilityList,
  FeasibilityListTotal,
  BudgetListTotal,
  BudgetList,
  SingleInputForm,
  SingleInputFormWithConfirm,
  SingleDateForm,
  SingleInputEmailForm,
  SingleInputLargeForm,
  SingleInputFormFlat,
  SingleInputNumberForm,
  DisabledSingleInputNumberForm,
  SingleInputNumberFormFlat,
  SingleInputIntegerForm,
  SingleInputPasswordForm,
  Dropdown,
  DropdownSimple,
  RadioSelect,
  PeriodSelect,
  PeriodSelect2,
  PeriodSelectSubYear,
  Selectacble,
  RangeSlider,
  PeriodHeader,
  PeriodHeaderWithEdit,
  BudgetPeriodHeaderWithEdit,
  Observations,
  FilterList
}