import React from 'react';
import { Row, Col } from 'Support/grid'
import {Button} from 'react-bootstrap';
import pptxgen from "pptxgenjs";
import Templates from "EXPORTS/ppt_master_slides"
import {  editValues,addBulletText, accumulateArrayElements, sizeOnizer, TableHeader, TableRowL1, TableRowL2, TableRow} from 'EXPORTS/exports_support'
import {multiply,add} from "mathjs"
import { RegularButton } from 'Support/listbuttons';


function BudgetPowerPoint(props){
  let pptx = new pptxgen();

  pptx.author = 'CASFLO';
  pptx.company = 'CASFLO';

  let theme
  if(props.org==='viseeon'){
    theme=new Templates.viseeon()
  } else if (props.org==='bazaar') {
    theme=new Templates.bazaar()
  } else{
    theme=new Templates.casflo[props.selected_template]()
  }

  pptx.defineSlideMaster(theme.cover)
  pptx.defineSlideMaster(theme.separator)
  pptx.defineSlideMaster(theme.no_title)
  pptx.defineSlideMaster(theme.one_title)
  pptx.defineSlideMaster(theme.two_title)
  pptx.defineSlideMaster(theme.end)
  
  //SLIDE 1 - COVER
  var cover_slide = pptx.addSlide('COVER');
  cover_slide.addText(
    props.p_name, { placeholder: "title1" }
  )
  cover_slide.addText(
    props.trsl['header'],{ placeholder: "title2" }
  )
  
  if(props.data.budget!== null && props.data.budget.budget_versions.length>0){
    for(var i=0;i<props.data.budget.budget_versions.length;i++){
      if(props.selected_versions.includes(i.toString())){
        let v_data=props.data.budget.budget_versions[i]

        //VERSION Introduction
        var v_slide = pptx.addSlide('NO TITLE');
        v_slide.addText(
          v_data.v_name,{ x:0, y:1, w:'90%', h:2.5, align:'right', color:theme.cs.primary, fontSize:30, fontFace: theme.ft.head  }
        )
        v_slide.addText(
          props.trsl['year']+': ' + v_data.v_year ,{ x:0, y:3.5, w:'90%', h:0.5, align:'right', color:theme.cs.primary, fontSize:16, fontFace:"Lato "}
        )
        v_slide.addText(
          props.trsl['values_in']+ props.settings.currency,{ x:0, y:4, w:'90%', h:0.5, align:'right', color:theme.cs.primary, fontSize:12, fontFace: theme.ft.body  }
        )

        //PRODUCTS LIST
        if(props.options.budget.revenues_list===true){
          var products_list_slide=pptx.addSlide('TWO TITLE');
          products_list_slide.addText(props.trsl['revenues'], { placeholder: "title1" })
          products_list_slide.addText(props.trsl['products_and_services'], { placeholder: "title2" })
          const produts_list_data=getProducts(v_data)
          if(produts_list_data.length>0){
            products_list_slide.addTable(produts_list_data,
            { colW:9,
              newSlideStartY:1.5,
              x: .2, 
              y: 1.5,
              fontFace: theme.ft.body,
              fontSize:12,
              color:theme.cs.darktext,
              autoPage:true,
              autoPageLineWeight:1
            })
          }
        }

        //PRODUCT SLIDES
        if(props.options.budget.revenues_assumptions===true){
          if(v_data.revenues.length>0){
            for(var i=0;i<v_data.revenues.length;i++){
              let peridiocity={
                1:props.trsl['yearly'],
                12:props.trsl['monthly'],
                52:props.trsl['weekly'],
                365:props.trsl['daily'],      
              }
              let product_slide=pptx.addSlide('TWO TITLE')
              product_slide.addText(props.trsl['revenues'], { placeholder: "title1" })
              product_slide.addText(v_data.revenues[i].rev_name, { placeholder: "title2" })
              let prod_rows = [
                TableHeader (props.trsl['months'], props.periods, theme),
                TableRow ( props.trsl['prices'], v_data.revenues[i].rev_price, theme),
                TableRow ( props.trsl['quantities'], v_data.revenues[i].rev_quantity, theme),
                TableRowL1 ( props.trsl['total_revenue'], v_data.revenues[i]._rev_total_revenue, theme),
                TableRowL2 ( props.trsl['accumulated_revenue'], accumulateArrayElements(v_data.revenues[i]._rev_total_revenue), theme),
                TableRow ( props.trsl['cogs'], v_data.revenues[i].rev_cogs._cogs_total, theme),
                TableRow ( props.trsl['iventories'], v_data.revenues[i].rev_cogs._inventory, theme),
              ]
              product_slide.addTable(prod_rows, { x: .2, y: 1.75, fontFace: theme.ft.body, colW:sizeOnizer(props.periods) });
              product_slide.addText(props.trsl['observations'],
                {
                  x:.2,
                  y:3.75,
                  h:0.5,
                  fontFace: "Lato Light",
                  fontSize:20,
                  color:theme.cs.darktext
                })
              product_slide.addText(v_data.revenues[i].rev_observations,
              {
                x:.2,
                y:4.25,
                h:1,
                fontFace: theme.ft.body,
                fontSize:14,
                color:theme.cs.darktext,
                valign:'top'
              })
            }
          }
        }

        //REVENUE SLIDE
        if(props.options.budget.revenues_graph===true){
          var revenue_slide=pptx.addSlide("ONE TITLE");
          revenue_slide.addText(props.trsl['revenues'], { placeholder: "title1"})
          let revenue_chart = [
            {
              name: props.trsl['revenues'],
              labels: props.periods,
              values: v_data.results._inc_st.revenue,
            }
          ]
          revenue_slide.addChart(pptx.ChartType.bar, revenue_chart, { 
            x: .2, 
            y: 1, 
            w: 9.5, 
            h: 4.25, 
            chartColors:[theme.cs.primary],
            catAxisLabelFontFace:"Lato",
            catAxisLabelcolor:theme.cs.primary,
            valAxisLabelFontFace:'Lato',
            valAxisMinVal:0,
            valGridLine:{color:theme.cs.primary, style:'dot'},
            valAxisLabelColor:theme.cs.primary,
            barGapWidthPct:50,
            showLabel:true,
            showValue:true,
            dataLabelPosition: 'outEnd',
            dataLabelFormatCode:'# ###',
            dataLabelColor:theme.cs.darkaccent,
            dataLabelFontFace:'Lato',
            dataLabelFontSize:10
          })
        }if(props.options.budget.revenues_graph===true){
          var accumulated_revenue_slide=pptx.addSlide("ONE TITLE");
          accumulated_revenue_slide.addText(props.trsl['accumulated_revenue'], { placeholder: "title1"})
          let accumulated_revenue_chart = [
            {
              name: props.trsl['accumulated_revenue'],
              labels: props.periods,
              values: accumulateArrayElements(v_data.results._inc_st.revenue),
            }
          ]
          accumulated_revenue_slide.addChart(pptx.ChartType.bar, accumulated_revenue_chart, { 
            x: .2, 
            y: 1, 
            w: 9.5, 
            h: 4.25, 
            chartColors:[theme.cs.primary],
            catAxisLabelFontFace:"Lato",
            catAxisLabelcolor:theme.cs.primary,
            valAxisLabelFontFace:'Lato',
            valAxisMinVal:0,
            valGridLine:{color:theme.cs.primary, style:'dot'},
            valAxisLabelColor:theme.cs.primary,
            barGapWidthPct:50,
            showLabel:true,
            showValue:true,
            dataLabelPosition: 'outEnd',
            dataLabelFormatCode:'# ###',
            dataLabelColor:theme.cs.darkaccent,
            dataLabelFontFace:'Lato',
            dataLabelFontSize:10
          })
        }

        //COSTS SLIDE
        if(props.options.budget.costs===true){
          var costs_slide=pptx.addSlide("ONE TITLE");
          costs_slide.addText(props.trsl['costs'], { placeholder: "title1" })
          let cost_chart = [
            {
              name: props.trsl['cogs'],
              labels: props.periods,
              values: v_data.results._inc_st.cogs,
            },
            {
              name: props.trsl['operating_exp'],
              labels: props.periods,
              values: v_data.results._inc_st.operating_expenses,
            },
            {
              name: props.trsl['employees'],
              labels: props.periods,
              values: v_data.results._inc_st.hr,
            },
            {
              name: props.trsl['other_costs'],
              labels: props.periods,
              values: v_data.results._inc_st.other_costs,
            }
          ]
          costs_slide.addChart(pptx.ChartType.bar, cost_chart, { 
            x: .2, 
            y: 1, 
            w: 9.5, 
            h: 4.25, 
            barGrouping:'stacked',
            chartColors:[theme.cs.primary,theme.cs.darkaccent,theme.cs.darkshade,theme.cs.lightaccent],
            catAxisLabelFontFace:"Lato",
            catAxisLabelcolor:theme.cs.primary,
            valAxisLabelFontFace:'Lato',
            valAxisMinVal:0,
            valGridLine:{color:theme.cs.primary, style:'dot'},
            valAxisLabelColor:theme.cs.primary,
            barGapWidthPct:50,
            showLegend:true,
            legendFontFace:'Lato',
            legendFontSize:10,
            legendColor:theme.cs.darktext,
            legendPos:'b'
          })
        }


        //OPEX SLIDE
        if(props.options.budget.opex===true){
          var opex_slide = pptx.addSlide("ONE TITLE");
          opex_slide.addText(props.trsl['operating_exp'],{ placeholder: "title1" })

          let opex_array=[]
          for(var i=0;i<v_data.operating_expenses.length;i++){
            opex_array[i]=TableRow (v_data.operating_expenses[i].op_exp_name, v_data.operating_expenses[i]._op_exp_total,theme)
          }
          let opex_rows = [
            TableHeader (props.trsl['operating_exp'], props.periods, theme)
          ]
          Array.prototype.push.apply(opex_rows, opex_array)
          opex_slide.addTable(opex_rows, { x: .2, y: 1.25, fontFace: theme.ft.body, colW:sizeOnizer(props.periods) });
        }
          
        //HR SLIDES
        if(props.options.budget.hr===true){
          var hr_slide1 = pptx.addSlide("ONE TITLE");
          hr_slide1.addText(props.trsl['employees'],{ placeholder: "title1" })

          let hr_array1=[]
          for(var i=0;i<v_data.human_resources.length;i++){
            if(v_data.human_resources[i].hr_type===false){
              hr_array1[i]=TableRowL1 (v_data.human_resources[i].hr_name, v_data.human_resources[i]._hr_total_cost)
            } else{
              const arr=new Array(props.periods.length).fill(1)
              hr_array1[i]=TableRow(v_data.human_resources[i].hr_name,arr,theme)
            }
          }
          let hr_rows1 = [
            TableHeader (props.trsl['employees_number'], props.periods, theme)
          ]
          Array.prototype.push.apply(hr_rows1, hr_array1)
          hr_slide1.addTable(hr_rows1, { x: .2, y: 1.25, fontFace: theme.ft.body, colW:sizeOnizer(props.periods) });

          //HR SLIDE SALARY
          var hr_slide3 = pptx.addSlide("ONE TITLE");
          hr_slide3.addText(props.trsl['employees'],{ placeholder: "title1" })

          let hr_array3=[]
          for(var i=0;i<v_data.human_resources.length;i++){
            if(v_data.human_resources[i].hr_type===false){
              hr_array3[i]=TableRowL1 (v_data.human_resources[i].hr_name, v_data.human_resources[i].hr_gross_salary)
            } else{
              hr_array3[i]=TableRow(v_data.human_resources[i].hr_name,v_data.human_resources[i].hr_gross_salary,theme)
            }
          }
          let hr_rows3 = [
            TableHeader (props.trsl['employees_salary'], props.periods, theme)
          ]
          Array.prototype.push.apply(hr_rows3, hr_array3)
          hr_slide3.addTable(hr_rows3, { x: .2, y: 1.25, fontFace: theme.ft.body, colW:sizeOnizer(props.periods) });

          //HR SLIDE Prizes
          var hr_slide4 = pptx.addSlide("ONE TITLE");
          hr_slide4.addText(props.trsl['employees'],{ placeholder: "title1" })

          let hr_array4=[]
          for(var i=0;i<v_data.human_resources.length;i++){
            if(v_data.human_resources[i].hr_type===false){
              hr_array4[i]=TableRowL1 (v_data.human_resources[i].hr_name, v_data.human_resources[i].hr_prizes_bonus)
            } else{
              hr_array4[i]=TableRow(v_data.human_resources[i].hr_name,v_data.human_resources[i].hr_prizes_bonus,theme)
            }
          }
          let hr_rows4 = [
            TableHeader (props.trsl['employees_prizes'], props.periods, theme)
          ]
          Array.prototype.push.apply(hr_rows4, hr_array4)
          hr_slide4.addTable(hr_rows4, { x: .2, y: 1.25, fontFace: theme.ft.body, colW:sizeOnizer(props.periods) });

          //HR SLIDE Subsidies
          var hr_slide5 = pptx.addSlide("ONE TITLE");
          hr_slide5.addText(props.trsl['employees'],{ placeholder: "title1" })

          let hr_array5=[]
          for(var i=0;i<v_data.human_resources.length;i++){
            if(v_data.human_resources[i].hr_type===false){
              hr_array5[i]=TableRowL1 (v_data.human_resources[i].hr_name, v_data.human_resources[i].hr_subsidy)
            } else{
              hr_array5[i]=TableRow(v_data.human_resources[i].hr_name,v_data.human_resources[i].hr_subsidy,theme)
            }
          }
          let hr_rows5 = [
            TableHeader (props.trsl['employees_subsidies'], props.periods, theme)
          ]
          Array.prototype.push.apply(hr_rows5, hr_array5)
          hr_slide5.addTable(hr_rows5, { x: .2, y: 1.25, fontFace: theme.ft.body, colW:sizeOnizer(props.periods) });

          //HR SLIDE 1
          var hr_slide = pptx.addSlide("ONE TITLE");
          hr_slide.addText(props.trsl['employees'],{ placeholder: "title1" })

          let hr_array=[]
          for(var i=0;i<v_data.human_resources.length;i++){
            if(v_data.human_resources[i].hr_type===false){
              hr_array[i]=TableRowL1 (v_data.human_resources[i].hr_name, v_data.human_resources[i]._hr_total_cost)
            } else{
              hr_array[i]=TableRow(v_data.human_resources[i].hr_name,v_data.human_resources[i]._hr_total_cost,theme)
            }
          }
          let hr_rows = [
            TableHeader (props.trsl['employees_cost'], props.periods, theme)
          ]
          Array.prototype.push.apply(hr_rows, hr_array)
          hr_slide.addTable(hr_rows, { x: .2, y: 1.25, fontFace: theme.ft.body, colW:sizeOnizer(props.periods) });

          //HR SLIDE2
          var hr_slide2 = pptx.addSlide("ONE TITLE");
          hr_slide2.addText(props.trsl['employees_number'],{ placeholder: "title1" })
          let hr_count=new Array(props.periods.length).fill(0)
          for(var i=0;i<v_data.human_resources.length;i++){
            if(v_data.human_resources[i].hr_type===false){
              for(var j=0;j<v_data.human_resources[i].hr_number_of_workers.length;j++){
                hr_count[j]+=v_data.human_resources[i].hr_number_of_workers[j]
              }
            } else{
              for(var j=0;j<v_data.human_resources[i].hr_number_of_workers.length;j++){
                hr_count[j]+=1
              }
            }
          }
          let hr_count_chart = [
            {
              name: props.trsl['employees_number'],
              labels: props.periods,
              values: hr_count,
            }
          ]
          hr_slide2.addChart(pptx.ChartType.bar, hr_count_chart, { 
            x: .2, 
            y: 1, 
            w: 9.5, 
            h: 4.25, 
            chartColors:[theme.cs.primary],
            catAxisLabelFontFace:"Lato",
            catAxisLabelcolor:theme.cs.primary,
            valAxisLabelFontFace:'Lato',
            valAxisMinVal:0,
            valGridLine:{color:theme.cs.primary, style:'dot'},
            valAxisLabelColor:theme.cs.primary,
            barGapWidthPct:50,
          })
        }
        

        //INVESTMENT SLIDE
        if(props.options.budget.investment===true){
          var investment_slide = pptx.addSlide("ONE TITLE");
          var investments=v_data.investments.filter(item => item.inv_existence==="new")
          investment_slide.addText(props.trsl['investment'],{ placeholder: "title1" })
          investment_slide.addText(props.trsl['new_investment'] + sumInvestments(investments,props.locale), { 
            x: .2, 
            y: 1,
            h:1, 
            fontFace: theme.ft.body,
            fontSize:50,
            color:theme.cs.primary
          })
          investment_slide.addText(props.trsl['investment_subsidy'] + sumInvestmentSubsidy(investments,props.locale), { 
            x: .2, 
            y: 3,
            h:1, 
            fontFace: theme.ft.body,
            fontSize:16,
            color:theme.cs.primary
          })
          investment_slide.addText(props.trsl['year_investment'],{ 
            x: '70%', 
            y: 1,
            w:'25%', 
            fontFace: theme.ft.body,
            fontSize:16,
            color:theme.cs.darktext
          })
          investment_slide.addText(addBulletText(sumYearlyInvestments(investments,props.periods,props.locale)),{ 
            x: '70%', 
            y: 1.2,
            h:3,
            w:'25%', 
            fontFace: theme.ft.body,
            fontSize:16,
            valign:'top',
            color:theme.cs.darktext
          })

          //INVESTMENT LIST
          var investment_list_slide=pptx.addSlide("ONE TITLE");
          investment_list_slide.addText(props.trsl['new_investment'], { placeholder: "title1" })
          let inv_list_data=getInvestments(v_data,props.periods)
          if(inv_list_data.length>0){
            investment_list_slide.addTable(inv_list_data,
            {
              colW:9,
              newSlideStartY:1,
              x: .2, 
              y: 1,
              fontFace: theme.ft.body,
              fontSize:9,
              color:theme.cs.darktext,
              autoPage:true    
            })
          }
        }


        //FINANCING SLIDE
        if(props.options.budget.financing===true){
          var financing_slide = pptx.addSlide("ONE TITLE");
          financing_slide.addText(props.trsl['financing'], { placeholder: "title1" })
          financing_slide.addText(addBulletText([
            props.trsl['issued_capital'] +': '+sumEquities(v_data.shareholders,'issued_capital',props.locale),
            '',
            props.trsl['share_premium'] +': '+sumSharePremium(v_data.shareholders,'issued_capital',props.locale),
            '',
            props.trsl['other_equity_instr'] +': '+sumEquities(v_data.shareholders,'other_equity_instruments', props.locale),
            '',
            props.trsl['shareholders_loan_1'] +': '+sumEquities(v_data.shareholders,'shareholders_loan', props.locale),
            '',
            props.trsl['debt'] +': '+sumDebt(v_data.debt,props.locale),
          ]), { 
            x: .2, 
            y: 1,
            h:4, 
            fontFace: theme.ft.body,
            fontSize:16,
            color:theme.cs.darktext
          })
        }

        //ISSUED CAPITAL SLIDE
        if(props.options.budget.shareholders===true){
          if(v_data.shareholders.length>0 && v_data.shareholders.filter((e)=>e.shareholders_type=='issued_capital').length>0){
            var ic_slide=pptx.addSlide("ONE TITLE");
            ic_slide.addText(props.trsl['shareholders_ic_participations'], { placeholder: "title1" })
            let ic_chart = createArrayForICSlide(v_data.shareholders,'issued_capital',props.periods)
            ic_slide.addChart(pptx.ChartType.bar, ic_chart, { 
              x: .2, 
              y: 1, 
              w: 9.5, 
              h: 4.25, 
              chartColors:[theme.cs.primary,theme.cs.darkaccent,theme.cs.darkshade,theme.cs.lightaccent],
              catAxisLabelFontFace:"Lato",
              catAxisLabelcolor:theme.cs.primary,
              valAxisLabelFontFace:'Lato',
              valAxisMinVal:0,
              valGridLine:{color:theme.cs.primary, style:'dot'},
              valAxisLabelColor:theme.cs.primary,
              barGrouping:'stacked',
              barGapWidthPct:50,
              showLegend:true,
              legendFontFace:'Lato',
              legendFontSize:10,
              legendColor:theme.cs.darktext,
              legendPos:'b'
              })
            }
          }

          //DEBT SLIDES
          if(props.options.budget.debt===true){
            var debts=v_data.debt.filter(item => item.debt_existence==="new")
            if(debts.length>0){
              for(var i=0;i<debts.length;i++){
                let debt_slide=pptx.addSlide("TWO TITLE")
                debt_slide.addText(props.trsl['debt-new'], { placeholder: "title1" })
                debt_slide.addText(debts[i].debt_name, { placeholder: "title2" })
                debt_slide.addText(props.trsl['debt_amount'] + debts[i].debt_amount,
                {
                  x:.2,
                  y:1.5,
                  h:.25,
                  w:'30%',
                  fontFace: theme.ft.body,
                  fontSize:12,
                  color:theme.cs.darktext
                })
                debt_slide.addText(props.trsl['debt_duration']+debts[i].debt_duration,
                {
                  x:4,
                  y:1.5,
                  h:.25,
                  w:'30%',
                  fontFace: theme.ft.body,
                  fontSize:12,
                  color:theme.cs.darktext
                })
                debt_slide.addText(props.trsl['debt_interest_rate']+ debts[i].debt_interest_rate,
                {
                  x:6,
                  y:1.5,
                  h:.25,
                  w:'30%',
                  fontFace: theme.ft.body,
                  fontSize:12,
                  color:theme.cs.darktext
                })
                let debt_rows = [
                  TableHeader (props.trsl['months'], props.periods,theme),
                  TableRow ( props.trsl['initial_debt'], debts[i]._debt_initial_debt,theme),
                  TableRow ( props.trsl['debt_reimbursement'], debts[i]._debt_reimbursement,theme),
                  TableRow ( props.trsl['final_debt'], debts[i]._debt_final_debt,theme),
                  TableRow ( props.trsl['debt_interest'], debts[i]._debt_interest,theme),
                  TableRowL1 ( props.trsl['debt_service'], add(debts[i]._debt_reimbursement,debts[i]._debt_interest),theme)
                ]
                debt_slide.addTable(debt_rows, { x: .2, y: 1.75, fontFace: theme.ft.body, colW:sizeOnizer(props.periods) });
                debt_slide.addText(props.trsl['observations'],
                  {
                    x:.2,
                    y:3.25,
                    h:0.5,
                    fontFace: "Lato Light",
                    fontSize:25,
                    color:theme.cs.darktext
                  })
                  debt_slide.addText(debts[i].debt_obs,
                {
                  x:.2,
                  y:3.75,
                  h:1.5,
                  fontFace: theme.ft.body,
                  fontSize:14,
                  color:theme.cs.darktext,
                  valign:'top'
                })
              }
            }
          }

          //RESULTS SLIDE
          var results_slide = pptx.addSlide("NO TITLE");
          results_slide.addText(
            props.trsl['results'],{ x:0, y:1.5, w:'100%', h:1.5, align:'center', color:theme.cs.primary, fontSize:70, fontFace: theme.ft.head  }
          )

        //INCOME STATEMENT SLIDE
        if(props.options.budget.is===true){
          var income_statemente_slide = pptx.addSlide("ONE TITLE");
          income_statemente_slide.addText(props.trsl['income_st'], { placeholder: "title1" })
          let is_rows = [
            TableHeader (props.trsl['months'], props.periods,theme),
            TableRow ( props.trsl['revenues'], v_data.results._inc_st.revenue, theme),
            TableRow ( props.trsl['subsidies'],  v_data.results._inc_st.operational_grants, theme),
            TableRow ( props.trsl['cogs'],   v_data.results._inc_st.cogs, theme),
            TableRow ( props.trsl['operating_exp'], v_data.results._inc_st.operating_expenses, theme),
            TableRow ( props.trsl['employees'],   v_data.results._inc_st.hr, theme),
            TableRow ( props.trsl['other_revenues'], v_data.results._inc_st.other_gains, theme),
            TableRow ( props.trsl['other_costs'], v_data.results._inc_st.other_costs, theme),
            TableRowL1 ( props.trsl['ebitda'],v_data.results._inc_st.ebitda, theme),
            TableRow ( props.trsl['depreciations'], v_data.results._inc_st.depreciations, theme),
            TableRowL1 ( props.trsl['ebit'], v_data.results._inc_st.ebit, theme),
            TableRow ( props.trsl['financing_costs'], v_data.results._inc_st.interest_cost, theme),
            TableRowL1 ( props.trsl['ebt'], v_data.results._inc_st.ebt, theme),
            TableRow ( props.trsl['corporate_tax'], v_data.results._inc_st.corporate_tax, theme),
            TableRowL2 ( props.trsl['net_income'], v_data.results._inc_st.net_profit, theme)  
          ];
          income_statemente_slide.addTable(is_rows, { x: .2, y: 0.8, fontFace: theme.ft.body, colW:sizeOnizer(props.periods) }); 

          //ACCUMULATED
          var income_statemente_slide_acc = pptx.addSlide("ONE TITLE");
          income_statemente_slide_acc.addText(props.trsl['income_st_acc'], { placeholder: "title1" })
          let is_rows_acc = [
            TableHeader (props.trsl['months'], props.periods,theme),
            TableRow ( props.trsl['revenues'], v_data.results._inc_st_acc.revenue, theme),
            TableRow ( props.trsl['subsidies'],  v_data.results._inc_st_acc.operational_grants, theme),
            TableRow ( props.trsl['cogs'],   v_data.results._inc_st_acc.cogs, theme),
            TableRow ( props.trsl['operating_exp'], v_data.results._inc_st_acc.operating_expenses, theme),
            TableRow ( props.trsl['employees'],   v_data.results._inc_st_acc.hr, theme),
            TableRow ( props.trsl['other_revenues'], v_data.results._inc_st_acc.other_gains, theme),
            TableRow ( props.trsl['other_costs'], v_data.results._inc_st_acc.other_costs, theme),
            TableRowL1 ( props.trsl['ebitda'],v_data.results._inc_st_acc.ebitda, theme),
            TableRow ( props.trsl['depreciations'], v_data.results._inc_st_acc.depreciations, theme),
            TableRowL1 ( props.trsl['ebit'], v_data.results._inc_st_acc.ebit, theme),
            TableRow ( props.trsl['financing_costs'], v_data.results._inc_st_acc.interest_cost, theme),
            TableRowL1 ( props.trsl['ebt'], v_data.results._inc_st_acc.ebt, theme),
            TableRow ( props.trsl['corporate_tax'], v_data.results._inc_st_acc.corporate_tax, theme),
            TableRowL2 ( props.trsl['net_income'], v_data.results._inc_st_acc.net_profit, theme)   
          ];
          income_statemente_slide_acc.addTable(is_rows_acc, { x: .2, y: 0.8, fontFace: theme.ft.body, colW:sizeOnizer(props.periods) }); 
        }

        //BALANCE - ASSETS SLIDE
        if(props.options.budget.bs===true){
          var assets_slide = pptx.addSlide("ONE TITLE");
          assets_slide.addText(props.trsl['balance_sheet'], { placeholder: "title1" })
          let assets_rows = [
            TableHeader (props.trsl, props.periods, theme.cs.darkaccent),
            TableRow (props.trsl['tangible'], v_data.results._bal.tangible_assets,theme.cs.darktext),
            TableRow (props.trsl['intangible'], v_data.results._bal.intangible_assets,theme.cs.darktext),
            TableRow (props.trsl['other_non_assets'], v_data.results._bal.other_non_current_assets,theme.cs.darktext),
            TableRowL1 (props.trsl['non_current_assets'], v_data.results._bal.total_non_current_assets,theme.cs.darktext),
            TableRow (props.trsl['iventories'], v_data.results._bal.inventories,theme.cs.darktext),
            TableRow (props.trsl['clients'], v_data.results._bal.clients,theme.cs.darktext),
            TableRow (props.trsl['gov_receivables'], v_data.results.gov.gov_receivable,theme.cs.darktext),
            TableRow (props.trsl['other_current_assets'], v_data.results._bal.other_current_assets,theme.cs.darktext),
            TableRow (props.trsl['cash'], v_data.results.cf.year_end_cash_flows,theme.cs.darktext),
            TableRowL1 (props.trsl['current_assets'], v_data.results._bal.total_current_assets,theme.cs.darktext),
            TableRowL2 (props.trsl['total_assets'], v_data.results._bal.total_assets,theme.cs.darktext),

          ];
          assets_slide.addTable(assets_rows, { x: .2, y: 0.8, fontFace: theme.ft.body, colW:sizeOnizer(props.periods) });

          //BALANCE - EQUITY + LIABILITIES SLIDE
          var eq_lia_slide = pptx.addSlide("ONE TITLE");
          eq_lia_slide.addText(props.trsl['balance_sheet'], { placeholder: "title1" })
          let rows = [
            TableHeader (props.trsl, props.periods, theme.cs.darkaccent),
            TableRow (props.trsl['issued_capital'], v_data.results._bal.issued_capital,theme.cs.darktext),
            TableRow (props.trsl['other_equity_instr'], v_data.results._bal.other_equity_instruments,theme.cs.darktext),
            TableRow (props.trsl['other_equity_chan'], v_data.results._bal.other_equity_changes,theme.cs.darktext),
            TableRow (props.trsl['past_results'], v_data.results._bal.past_results,theme.cs.darktext),
            TableRow (props.trsl['net_income'], v_data.results._inc_st.net_profit,theme.cs.darktext),
            TableRowL2 (props.trsl['total_equity'], v_data.results._bal.total_equity,theme.cs.darktext),
            TableRow (props.trsl['debt'], v_data.results._bal.debt,theme.cs.darktext),
            TableRow (props.trsl['other_non_liabilities'], v_data.results._bal.other_non_current_liabilities,theme.cs.darktext),
            TableRowL1 (props.trsl['non_current_liabilities'], v_data.results._bal.total_non_current_liabilities),
            TableRow (props.trsl['suppliers'], v_data.results._bal.suppliers,theme.cs.darktext),
            TableRow (props.trsl['gov_payables'], v_data.results.gov.gov_payable,theme.cs.darktext),
            TableRow (props.trsl['shareholders_loan'], v_data.results._bal.shareholders_loan,theme.cs.darktext),
            TableRow (props.trsl['other_payables'], v_data.results._bal.other_accounts_payables,theme.cs.darktext),
            TableRow (props.trsl['other_current_liabilities'], v_data.results._bal.other_current_liabilities,theme.cs.darktext),
            TableRow (props.trsl['current_liabilities'], v_data.results._bal.total_current_liabilities,theme.cs.darktext),
            TableRowL1 (props.trsl['total_liabilities'], v_data.results._bal.total_liabilities,theme.cs.darktext),
            TableRowL2 (props.trsl['equity_liabilities'], v_data.results._bal.equity_liabilities,theme.cs.darktext),
          ];
          eq_lia_slide.addTable(rows, { x: .2, y: 0.8, fontFace: theme.ft.body, colW:sizeOnizer(props.periods) });
        }

        //CASH STATEMENT SLIDE
        if(props.options.budget.cs===true){
          var cash_statemet_slide = pptx.addSlide("ONE TITLE");
          cash_statemet_slide.addText(props.trsl['cash_st'], { placeholder: "title1" })
          let cs_rows = [
            TableHeader (props.trsl['months'], props.periods, theme),
            TableRow (props.trsl['received_clients'], v_data.results.cf.received_from_clients,theme),
            TableRow (props.trsl['paid_suppliers'], v_data.results.cf.paid_to_suppliers,theme),
            TableRow (props.trsl['paid_hr'], v_data.results.cf.payment_to_hr,theme),
            TableRow (props.trsl['other_pay_rec'], v_data.results.cf.other_payments_and_receivables,theme),
            TableRowL1 (props.trsl['operational_cf'], v_data.results.cf.operational_cash_flow,theme),
            TableRow (props.trsl['investment_cf'], v_data.results.cf.investment_in_assets,theme),
            TableRow (props.trsl['debt_increase'], v_data.results.cf.debt_increase,theme),
            TableRow (props.trsl['financing_costs'], v_data.results._inc_st.interest_cost,theme),
            TableRow (props.trsl['debt_decrease'], v_data.results.cf.debt_decrease,theme),
            TableRowL1 (props.trsl['financing_cashflows'], v_data.results.cf.financing_cashflows,theme),
            TableRowL2 (props.trsl['cf_variation'], v_data.results.cf.cash_flow_variation,theme),
          ];
          cash_statemet_slide.addTable(cs_rows, { x: .2, y: 0.8, fontFace: theme.ft.body, colW:sizeOnizer(props.periods) });

          //accumulated
          var cash_statemet_slide_acc = pptx.addSlide("ONE TITLE");
          cash_statemet_slide_acc.addText(props.trsl['cash_st_acc'], { placeholder: "title1" })
          let cs_rows_acc = [
            TableHeader (props.trsl['months'], props.periods, theme),
            TableRow (props.trsl['received_clients'], v_data.results.cf_acc.received_from_clients,theme),
            TableRow (props.trsl['paid_suppliers'], v_data.results.cf_acc.paid_to_suppliers,theme),
            TableRow (props.trsl['paid_hr'], v_data.results.cf_acc.payment_to_hr,theme),
            TableRow (props.trsl['other_pay_rec'], v_data.results.cf_acc.other_payments_and_receivables,theme),
            TableRowL1 (props.trsl['operational_cf'], v_data.results.cf_acc.operational_cash_flow,theme),
            TableRow (props.trsl['investment_cf'], v_data.results.cf_acc.investment_in_assets,theme),
            TableRow (props.trsl['debt_increase'], v_data.results.cf_acc.debt_increase,theme),
            TableRow (props.trsl['financing_costs'], v_data.results._inc_st_acc.interest_cost,theme),
            TableRow (props.trsl['debt_decrease'], v_data.results.cf_acc.debt_decrease,theme),
            TableRowL1 (props.trsl['financing_cashflows'], v_data.results.cf_acc.financing_cashflows,theme),
            TableRowL2 (props.trsl['cf_variation'], v_data.results.cf_acc.cash_flow_variation,theme),
          ];
          cash_statemet_slide_acc.addTable(cs_rows_acc, { x: .2, y: 0.8, fontFace: theme.ft.body, colW:sizeOnizer(props.periods) });
        }

        //WORKING CAPITAL STATEMENT SLIDE
        if(props.options.budget.wc===true){
          var working_capital_slide = pptx.addSlide("ONE TITLE");
          working_capital_slide.addText(props.trsl['working_capital'], { placeholder: "title1" })
          let wc_rows = [
            TableHeader (props.trsl, props.periods, theme.cs.darkaccent,theme.cs.darktext),
            TableRow (props.trsl['iventories'], v_data.results._bal.inventories,theme.cs.darktext),
            TableRow (props.trsl['clients'], v_data.results._bal.clients,theme.cs.darktext),
            TableRow (props.trsl['gov_receivables'], v_data.results.gov.gov_receivable,theme.cs.darktext),
            TableRow (props.trsl['other_current_assets'], v_data.results._bal.other_current_assets,theme.cs.darktext),
            TableRowL1 (props.trsl['working_capital_investent'], v_data.results.wc.wc_investment,theme.cs.darktext),
            TableRow (props.trsl['suppliers'], v_data.results._bal.suppliers,theme.cs.darktext),
            TableRow (props.trsl['gov_payables'], v_data.results.gov.gov_payable,theme.cs.darktext),
            TableRow (props.trsl['other_payables'], v_data.results._bal.other_accounts_payables,theme.cs.darktext),
            TableRow (props.trsl['other_current_liabilities'], v_data.results._bal.other_current_liabilities,theme.cs.darktext),
            TableRowL1 (props.trsl['working_capital_financing'], v_data.results.wc.wc_financing,theme.cs.darktext),
            TableRowL1 (props.trsl['working_capital_needs'], v_data.results.wc.wc_needs,theme.cs.darktext),
            TableRowL2 (props.trsl['working_capital_variation'], v_data.results.wc.wc_variation,theme.cs.darktext),
          ];
          working_capital_slide.addTable(wc_rows, { x: .2, y: 0.8, fontFace: theme.ft.body, colW:sizeOnizer(props.periods) });
        }

        //FEASIBILITY ANALISYS SLIDE
        if(props.options.budget.fea===true){
          var feasibilitya_analisys_slide = pptx.addSlide("ONE TITLE");
          feasibilitya_analisys_slide.addText(props.trsl['cash_flows'], { placeholder: "title1" })
          let fea_rows = [
            TableHeader (props.trsl, props.periods, theme.cs.darkaccent),
            TableRow (props.trsl['ebt'], v_data.results._inc_st.ebit,theme.cs.darktext),
            TableRow (props.trsl['operationa_corporate_tax'], v_data.results.fe.operational_corporate_tax,theme.cs.darktext),
            TableRow (props.trsl['depreciations'], v_data.results._inc_st.depreciations,theme.cs.darktext),
            TableRowL1 (props.trsl['noplat'], v_data.results.fe.noplat,theme.cs.darktext),
            TableRow (props.trsl['working_capital_variation'], v_data.results.wc.wc_variation,theme.cs.darktext),
            TableRow (props.trsl['fixed_asset_investment'], v_data.results.fe.fixed_asset_investment,theme.cs.darktext),
            TableRowL1 (props.trsl['fcff'], v_data.results.fe.fcff,theme.cs.darktext),
            TableRow (props.trsl['accumulated_fcff'], v_data.results.fe.fcff_accumulated,theme.cs.darktext),
            TableRow (props.trsl['discounted_fcff'], v_data.results.fe.fcff_discounted,theme.cs.darktext),
            TableRowL1 (props.trsl['accumulated_discounted_fcff'], v_data.results.fe.fcff_discounted_accumulated,theme.cs.darktext),
          ];
          feasibilitya_analisys_slide.addTable(fea_rows, { x: .2, y: 0.8, fontFace: theme.ft.body, colW:sizeOnizer(props.periods) });
          feasibilitya_analisys_slide.addText(props.trsl['residual_value'] + ': ' + editValues(v_data.results.fe.perpetuity_discounted,props.locale),{ x:.2, y:4, fontSize:18, fontFace:"Lato",color:theme.cs.primary } )

          //MAIN RESULTS 
          var main_results_slide = pptx.addSlide("ONE TITLE");
          main_results_slide.addText(props.trsl['main_results'], { placeholder: "title1" })
          main_results_slide.addText(
            [
              {text: props.trsl['npv'] +': ', options: {color:theme.cs.primary, fontSize:50, fontFace: theme.ft.body }},
              {text: editValues(v_data.results.fe.npv,props.locale), options:{color:theme.cs.darktext, fontSize:50, fontFace: theme.ft.head  }}
            ],
            { x:'5%', y:1, w:'90%', h:1, align:'left'}
          )
          main_results_slide.addText(
            [
              {text: props.trsl['irr'] +': ', options: {color:theme.cs.primary, fontSize:50, fontFace: theme.ft.body }},
              {text: (v_data.results.fe.irr*100).toFixed(2)+'%', options:{color:theme.cs.darktext, fontSize:50, fontFace: theme.ft.head  }}
            ],
            { x:'5%', y:2, w:'90%', h:1, align:'left'}
          )
          main_results_slide.addText(
            [
              {text: props.trsl['payback_period'] +': ', options: {color:theme.cs.primary, fontSize:50, fontFace: theme.ft.body }},
              {text: v_data.results.fe.payback, options:{color:theme.cs.darktext, fontSize:50, fontFace: theme.ft.head  }}
            ],
            { x:'5%', y:3, w:'90%', h:1, align:'left'}
          )
        }
      }
    }
  }

  var end_slide = pptx.addSlide("END");
  

  pptx.writeFile('CASFLO EXPORT.pptx')
}


function getProducts(input){
  let the_array=[]
  if(input.revenues.length>0){
    for(var i=0;i<input.revenues.length;i++){
      the_array[i]=[{text: input.revenues[i].rev_name, options: {align: "left"}}]
    }
  }
  return the_array
}

function getInvestments(input,periods){
  let the_array=[]
  input.investments = input.investments.filter(item => item.inv_existence==="new")
  if(input.investments.length>0){
    for(var i=0;i<input.investments.length;i++){
      the_array[i]=[{text: `${periods[input.investments[i].inv_year]} - ${input.investments[i].inv_item_name}: ${multiply(input.investments[i].inv_price,input.investments[i].inv_quantity)}`}]
    }
  }
  return the_array
}

function sumInvestments(input,locale){
  let sum=0
  if(input.length>0){
    for(var i=0;i<input.length;i++){
      sum +=(parseFloat(input[i].inv_quantity*input[i].inv_price))
    }
  }
  return editValues(sum,locale) 
}

function sumYearlyInvestments(input,periods,locale){
  let sum=new Array(periods.length).fill(0)
  if(input.length>0){
    for(var j=0;j<periods.length;j++){
      for(var i=0;i<input.length;i++){
        if(input[i].inv_year===j)
        sum[j] +=(parseFloat(input[i].inv_quantity*input[i].inv_price))
      }
    }
  }
  let return_array=[]
  for(var e=0;e<periods.length;e++){
    return_array[e]=periods[e]+': '+ editValues(sum[e],locale)
  }
  return return_array 
}

function sumInvestmentSubsidy(input,locale){
  let sum=0
  if(input!==undefined){
    for(var i=0;i<input.length;i++){
      sum +=(parseFloat(input[i]._inv_subsidy_comparticipation))
    }
  }
  return editValues(sum,locale) 
}

function createArrayForICSlide(input,slicer,periods){
  let bug=[]
  if(input!==undefined){
    for(var i=0;i<input.length;i++){
      if(input[i].shareholders_type===slicer){
        bug[i]={
          name:input[i].shareholders_item_name,
          labels:periods,
          values:input[i]._shareholders_total
        }
      }
    }
  }  
  var filtered_bug = bug.filter(function (el) {
    return el != null;
  });
  return filtered_bug
}

function sumEquities(input,slicer,locale){
  let sum=0
  if(input!==undefined){
    for(var i=0;i<input.length;i++){
      if(input[i].shareholders_type===slicer){
        for(var j =0;j<input[i].shareholders_increase.length;j++){
          sum +=parseFloat(input[i].shareholders_increase[j])  
        }
      }
    }
  }
  return editValues(sum,locale) 
}

function sumSharePremium(input,slicer,locale){
  let sum=0
  if(input!==undefined){
    for(var i=0;i<input.length;i++){
      if(input[i].shareholders_type===slicer){
        for(var j =0;j<input[i].shareholders_increase.length;j++){
          sum +=parseFloat(input[i].shareholders_share_premium[j])  
        }
      }
    }
  }
  return editValues(sum,locale) 
}

function sumDebt(input,locale){
  let sum=0
  if(input.length>0){
    for(var i=0;i<input.length;i++){
      for(var j =0;j<input[i]._debt_disbursement.length;j++){ 
        sum +=(parseFloat(input[i]._debt_disbursement[j]))
      }
    }
  }
  return editValues(sum,locale) 
}


class GenerateReportPPTX extends React.Component{
  
  handleGeneratePPTX=async()=>{ 
    const props={
      trsl:this.props.trsl,
      selected_template:this.props.selected_template,
      org:this.props.org,
      data:this.props.data,
      options:this.props.options,
      selected_versions:this.props.selected_versions,
      periods:this.props.periods,
      locale:this.props.locale,
      settings:this.props.settings,
      p_name:this.props.p_name,
      v_name:this.props.v_name
    }
    BudgetPowerPoint(props)
  }

  render(){
    return(
      <Row>
        <Col xs={12} className="export_button">
          <Button size="sm" block onClick={this.handleGeneratePPTX}>{this.props.trsl['exporting_custom']}</Button>
        </Col>
      </Row>
    )
  }
}

class GenerateVersionPPTReport extends React.Component{
  ppt_options={
    general:{
      project_description:false,
      historic:false
    },
    budget:{
      version_description:false,
      revenues_list:true,
      revenues_assumptions:true,
      revenues_graph:true,
      accumulated_revenues_graph:true,
      hr:true,
      costs:true,
      opex:true,
      investment:true,
      financing:false,
      debt:true,
      is:true,
      cs:true,
    }
  }

  
  handleGeneratePPTX=async()=>{
    let data_for_ppt={
      budget:{
        budget_versions:[this.props.data]
      }
    }
    data_for_ppt.budget.budget_versions[0].v_name=this.props.current_version.name
    data_for_ppt.budget.budget_versions[0].v_year=this.props.current_version.year


    const props={
      trsl:this.props.trsl,
      selected_template:0,
      org:this.props.org,
      data:data_for_ppt,
      options:this.ppt_options,
      selected_versions:['0'],
      periods:this.props.periods,
      locale:this.props.locale,
      settings:this.props.settings,
      p_name:this.props.p_name,
    }
    BudgetPowerPoint(props)
  }

  render(){
    return(
      <Row>
        <Col xs={6} className="export_button">
          <h6>PPTX</h6>
          <RegularButton onClick={this.handleGeneratePPTX} bk='bk_primary' label={this.props.trsl['exporting_pptx']} />
        </Col>
      </Row>
    )
  }
}

export {
  GenerateReportPPTX,
  GenerateVersionPPTReport
};