class OperatingExpensesCreator{
  constructor(name, n_years,vat_rate,hist){
    this.op_exp_name=name
    this.op_exp_period_type=1
    this.op_exp_value=new Array(n_years).fill(0)
    this.op_exp_yearly_total=0
    this.op_exp_value_inflated=new Array(n_years).fill(0)
    this._op_exp_total=new Array(n_years).fill(0)
    this.op_exp_vat_rate=new Array(n_years).fill(vat_rate)
    this._op_exp_vat_total=new Array(n_years).fill(0)
    this._op_exp_payable=new Array(n_years).fill(0)
    this._rev_hist=hist
    this.op_exp_obs=""
  }
}

export default OperatingExpensesCreator;