import React, {useContext, useState} from 'react';
import {Button,Form,Modal} from 'react-bootstrap';
import { Grid,Row, Col } from 'Support/grid'

import Loader from 'Support/loader';
import ApiConnect from 'CONFIG/axios';
import UserContext from 'CONFIG/auth';
import {calTotalsForLists} from 'CALCULATIONS/cal_totals'

import OperatingExpensesCreator from 'CONFIG/CREATORS/operating_exp_creator';
import {SinglesFeasibilityListItems} from '../budget_items';

class OperatingExpList extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      data:[],
      loaded:false,
      totals:[]
    }
    this.handleAdd=this.handleAdd.bind(this)
    this.updateListData=this.updateListData.bind(this)
    this.handleDeleteItem=this.handleDeleteItem.bind(this)
    this.handleDuplicateItem=this.handleDuplicateItem.bind(this)
  }

  componentDidMount() {
    this.updateListData()
  }

  updateListData(){
    const request={
      budget_id:this.props.budget_id,
      version_id:this.props.current_version.id,
      user_id:this.props.user_id,
      item_type:"operating_expenses"
      }
    ApiConnect.post('/api/budget_project/multitem/all/get', request)
    .then(res => {
      this.setState({ 
        data:res.data,
        loaded:true,
        totals:calTotalsForLists(res.data)
      });
    })
    .catch(function (error) {
      alert(error);
    })
  }

  handleAdd =async(item)=>{
    let months=12
    const request={
      budget_id:this.props.budget_id,
      version_id:this.props.current_version.id,
      item_type:"operating_expenses",
      content: new OperatingExpensesCreator(
        item,
        months,
        this.props.budget_settings.vat
        )
    }
    try{
      const response= await ApiConnect.post(`/api/budget_project/multitem/item/create`, request)
      const event={
        target:{
          name:'edit',
          value:response.data
        }
      }
      this.props.handleScreenNavigation(event)
    } catch(error) {
      alert(error);
    }
  }
  handleQuickAdd=async(items)=>{
    let months=12
    const op_exp_to_create=items.filter(i=>i.active===true)
    
    if(op_exp_to_create.length>0){
      let arr=[]
      for(var i =0;i<op_exp_to_create.length;i++){
        arr[i]=new OperatingExpensesCreator(
          op_exp_to_create[i].op_exp_name,
          months,
          this.props.budget_settings.vat
        )
      }
      const request={
        budget_id:this.props.budget_id,
        version_id:this.props.current_version.id,
        item_type:"operating_expenses",
        content:arr
      }
      try{
        const response= await ApiConnect.post(`/api/budget_project/multitem/item/specialcreate`, request)
        this.setState({ 
          data:response.data
        })
      } catch(error) {
        alert(error);
      }
    }
  }

    handleDuplicateItem =async(event)=>{
        const request={
            budget_id:this.props.budget_id,
            version_id:this.props.current_version.id,
            item_type:"operating_expenses",
            item_id:event.target.value
        }
        try{
            const response= await ApiConnect.post(`/api/budget_project/multitem/item/duplicate`, request)
            this.updateListData()
        } catch(error) {
            alert(error);
          }
    }

    handleDeleteItem =async(event)=>{
        const request={
            budget_id:this.props.budget_id,
            version_id:this.props.current_version.id,
            item_type:"operating_expenses",
            item_id:event.target.value
        }
        try{
            const response= await ApiConnect.post(`/api/budget_project/multitem/item/delete`, request)
            this.updateListData()
        } catch(error) {
      alert(error);
    }
  }

  handleUpLevel= async(event)=>{
    const request={
      budget_id:this.props.budget_id,
      version_id:this.props.current_version.id,
      item_type:"operating_expenses",
      item_id:event.target.value
    }
    try{
    const response= await ApiConnect.post(`/api/budget_project/multitem/item/uplevel`, request)
      this.updateListData()
    } catch(error) {
      alert(error);
    }
  }

  handleDownLevel= async(event)=>{
    const request={
      budget_id:this.props.budget_id,
      version_id:this.props.current_version.id,
      item_type:"operating_expenses",
      item_id:event.target.value
    }
    try{
    const response= await ApiConnect.post(`/api/budget_project/multitem/item/downlevel`, request)
      this.updateListData()
    } catch(error) {
      alert(error);
    }
  }



  render(){
    function torender(data, i){
      return data[i];
    }  
    const pages ={
    true: 
    <SinglesFeasibilityListItems 
    data={this.state.data} 
    totals={this.state.totals}
    budget_settings={this.props.budget_settings}
    user_permission={this.props.user_permission}
    handleAdd={this.handleAdd} 
    handleScreenNavigation={this.props.handleScreenNavigation} 
    handleItem={this.handleDeleteItem}
    handleDeleteItem={this.handleDeleteItem}
    handleDuplicateItem={this.handleDuplicateItem}
    handleUpLevel={this.handleUpLevel}
    handleDownLevel={this.handleDownLevel}
    handleEditItem={this.props.handleDuplicateItem}
    title='op_exp_title'
    add_item='add_op_exp'
    />,
    false: <Loader loader_message="op_exp_list"/>
    }
    var i = this.state.loaded
    const renderizing = torender(pages,i)
    
    return(
      <div>
        {renderizing}
        <OpExpQuickAdd handleQuickAdd={this.handleQuickAdd}/>
      </div>
    )
  }
}

function OpExpQuickAdd (props){
  const user_c = useContext(UserContext);
  const trsl=user_c.i18n.t('PRIVATE_PROJECT.PROJECT_FEASIBILITY.Op_exp_quick_adds')
  const initial_state=[
    {t:'production',type:'outsourcing', active:false, op_exp_name:trsl['outsourcing']},
    {t:'production',type:'subcontracting', active:false, op_exp_name: trsl['subcontracting']},
    {t:'production',type:'machine', active:false, op_exp_name: trsl['machine']},
    {t:'production',type:'assistance', active:false, op_exp_name: trsl['assistance']},
    {t:'production',type:'commercialrent', active:false, op_exp_name: trsl['commercialrent']},
    {t:'production',type:'factory_rent', active:false, op_exp_name: trsl['factory_rent']},
    {t:'production',type:'warehouse_rent', active:false, op_exp_name: trsl['warehouse_rent']},
    {t:'production',type:'logistis', active:false, op_exp_name: trsl['logistis']},
    {t:'production',type:'call_center', active:false, op_exp_name: trsl['call_center']},
    {t:'advisory',type:'business', active:false, op_exp_name: trsl['business']},
    {t:'advisory',type:'legal', active:false, op_exp_name: trsl['legal']},
    {t:'advisory',type:'accounting', active:false, op_exp_name: trsl['accounting']},
    {t:'advisory',type:'financial', active:false, op_exp_name: trsl['financial']},
    {t:'advisory',type:'technical', active:false, op_exp_name: trsl['technical']},
    {t:'advisory',type:'auditing', active:false, op_exp_name: trsl['auditing']},
    {t:'advisory',type:'patent', active:false, op_exp_name: trsl['patent']},
    {t:'advisory',type:'brand', active:false, op_exp_name: trsl['brand']},
    {t:'utilities',type:'water', active:false, op_exp_name: trsl['water']},
    {t:'utilities',type:'electricity', active:false, op_exp_name: trsl['electricity']},
    {t:'utilities',type:'gas', active:false, op_exp_name: trsl['gas']},
    {t:'utilities',type:'fuel', active:false, op_exp_name: trsl['fuel']},
    {t:'office',type:'office_rent', active:false, op_exp_name: trsl['office_rent']},
    {t:'office',type:'maintenance', active:false, op_exp_name: trsl['maintenance']},
    {t:'office',type:'coworking', active:false, op_exp_name: trsl['coworking']},
    {t:'office',type:'room_renting', active:false, op_exp_name: trsl['room_renting']},
    {t:'office',type:'gardening', active:false, op_exp_name: trsl['gardening']},
    {t:'office',type:'cleaning_services', active:false, op_exp_name: trsl['cleaning_services']},
    {t:'office',type:'communications ', active:false, op_exp_name: trsl['communications']},
    {t:'office',type:'office_materials', active:false, op_exp_name: trsl['office_materials']},
    {t:'office',type:'printing', active:false, op_exp_name: trsl['printing']},
    {t:'office',type:'documentation', active:false, op_exp_name: trsl['documentation']},
    {t:'office',type:'document', active:false, op_exp_name: trsl['document']},
    {t:'office',type:'amenities', active:false, op_exp_name: trsl['amenities']},
    {t:'it',type:'web_development', active:false, op_exp_name: trsl['web_development']},
    {t:'it',type:'web_hosting', active:false, op_exp_name: trsl['web_hosting']},
    {t:'it',type:'other_hosting', active:false, op_exp_name: trsl['other_hosting']},
    {t:'it',type:'app_development', active:false, op_exp_name: trsl['app_development']},
    {t:'it',type:'business_suits', active:false, op_exp_name: trsl['business_suits']},
    {t:'it',type:'it_advisory', active:false, op_exp_name: trsl['it_advisory']},
    {t:'it',type:'other_it_services', active:false, op_exp_name: trsl['other_it_services']},
    {t:'marketing',type:'seo', active:false, op_exp_name: trsl['seo']},
    {t:'marketing',type:'design', active:false, op_exp_name: trsl['design']},
    {t:'marketing',type:'printing_services', active:false, op_exp_name: trsl['printing_services']},
    {t:'marketing',type:'event_planning', active:false, op_exp_name: trsl['event_planning']},
    {t:'marketing',type:'event_hosting', active:false, op_exp_name: trsl['event_hosting']},
    {t:'marketing',type:'event_decoration', active:false, op_exp_name: trsl['event_decoration']},
    {t:'marketing',type:'event_catering', active:false, op_exp_name: trsl['event_catering']},
    {t:'marketing',type:'market_research', active:false, op_exp_name: trsl['market_research']},
    {t:'marketing',type:'communication_agencies', active:false, op_exp_name: trsl['communication_agencies']},
    {t:'marketing',type:'brand_agencies', active:false, op_exp_name: trsl['brand_agencies']},
    {t:'marketing',type:'conferences', active:false, op_exp_name: trsl['conferences']},
    {t:'marketing',type:'sponsorships', active:false, op_exp_name: trsl['sponsorships']},
    {t:'travel',type:'travel_fees', active:false, op_exp_name: trsl['travel_fees']},
    {t:'travel',type:'public_transportation', active:false, op_exp_name: trsl['public_transportation']},
    {t:'travel',type:'taxis', active:false, op_exp_name: trsl['taxis']},
    {t:'travel',type:'hotel', active:false, op_exp_name: trsl['hotel']},
    {t:'travel',type:'restaurant', active:false, op_exp_name: trsl['restaurant']},
    {t:'travel',type:'representation', active:false, op_exp_name: trsl['representation']},
    {t:'others',type:'insurance', active:false, op_exp_name: trsl['insurance']},
    {t:'others',type:'commissions', active:false, op_exp_name: trsl['commissions']},
    {t:'others',type:'royalties', active:false, op_exp_name: trsl['royalties']},
    {t:'others',type:'client_gifts', active:false, op_exp_name: trsl['client_gifts']},
    {t:'others',type:'vehicle_renting', active:false, op_exp_name: trsl['vehicle_renting']},
    {t:'others',type:'security', active:false, op_exp_name: trsl['security']},
    {t:'others',type:'health_plans', active:false, op_exp_name: trsl['health_plans']},
    {t:'others',type:'perks', active:false, op_exp_name: trsl['perks']},
    {t:'others',type:'facility_services', active:false, op_exp_name: trsl['facility_services']},
    {t:'others',type:'other_services', active:false, op_exp_name: trsl['other_services']},
  ]

  const [open,setOpen] =useState(false)
  const [values,setValues]=useState(initial_state)

  const handleOpen=()=>{
    let go=!open
    setOpen(go)
    setValues(initial_state)
  }

  const handleCreateDecreateValue=async(event)=>{
    const name=event.target.name
    let go=Array.from(values)
    const ind=go.findIndex(i=>i.type===name)
    go[ind].active=!go[ind].active
    setValues(go)
  }

  const handleAddItem=()=>{
    let go=!open
    setOpen(go)
    setValues(initial_state)
    props.handleQuickAdd(values)
  }

  return(
    <Row>
      <Col xs={8}></Col>
      <Col xs={4} className='feasibility_quick_add_button'>
        <Button block size='sm' onClick={handleOpen}>{trsl['options_header']}</Button>
      </Col>
      <Col xs={12}>
        <Modal show={open} size="xl" onHide={handleOpen}>
          <Modal.Header closeButton>
            {trsl['options_header']}
          </Modal.Header>
          <Modal.Body>
            <Grid fluid>
              <Row>
                <Col xs={6}>
                  {trsl['explanation']}
                </Col>
                <Col xs={6}></Col>
              </Row>
              <AggregateQuickOption trsl={trsl} elements={values} header='production' handleCreateDecreateValue={handleCreateDecreateValue}/>
              <AggregateQuickOption trsl={trsl} elements={values} header='advisory' handleCreateDecreateValue={handleCreateDecreateValue}/>
              <AggregateQuickOption trsl={trsl} elements={values} header='utilities' handleCreateDecreateValue={handleCreateDecreateValue}/>
              <AggregateQuickOption trsl={trsl} elements={values} header='office' handleCreateDecreateValue={handleCreateDecreateValue}/>
              <AggregateQuickOption trsl={trsl} elements={values} header='it' handleCreateDecreateValue={handleCreateDecreateValue}/>
              <AggregateQuickOption trsl={trsl} elements={values} header='marketing' handleCreateDecreateValue={handleCreateDecreateValue}/>
              <AggregateQuickOption trsl={trsl} elements={values} header='travel' handleCreateDecreateValue={handleCreateDecreateValue}/>
              <AggregateQuickOption trsl={trsl} elements={values} header='others' handleCreateDecreateValue={handleCreateDecreateValue}/>
              <Row>
                <Col xs={12}>
                  <Button block onClick={handleAddItem}>{trsl['add_button']}</Button>
                </Col>
              </Row>
            </Grid>
            </Modal.Body>
        </Modal>
      </Col>
    </Row>
  )
}

function AggregateQuickOption(props){
  let renderizing=''
  renderizing=props.elements.map((val)=>{
    if(val.t===props.header){
      return(
        <QuickAddLines 
        trsl={props.trsl} 
        data={val} 
        handleCreateDecreateValue={props.handleCreateDecreateValue}/>
      )
    }
  })
  return(
    <Row>
      <Col xs={12} className='feasibility_quick_add_h4_op_ex'>
        <h4>{props.trsl[props.header]}</h4>
      </Col>
      {renderizing}
    </Row>
  )

}

function QuickAddLines(props){
  return(
      <Col xs={6} className='feasibility_quick_add_op_exp'>
        <Row middle='xs'>
          <Col xs={1}>
            <Form.Check 
              type="switch"
              checked={props.data.active}
              id={props.data.type}
              name={props.data.type}
              value={props.trsl[props.data.type]}
              onChange={props.handleCreateDecreateValue}
            />
          </Col>
          <Col xs={11}>
            <h5>{props.data.op_exp_name}</h5>
          </Col>
        </Row>
      </Col>
  )
}


export default OperatingExpList;