import React from 'react';
import { Row, Col } from 'Support/grid';
import {dotMultiply, dotDivide} from 'mathjs';

import {calRevIteration} from 'CALCULATIONS/cal_revenues';
import {calOpExpIterate} from 'CALCULATIONS/cal_operating_expenses';
import {calRhIterateSpecial} from 'CALCULATIONS/cal_human_resources';
import {calOtherGainsCostsIteration} from 'CALCULATIONS/cal_other_gains_costs';
import {calInvestmentIteration} from 'CALCULATIONS/cal_investment';
import {calShareholdersIteration} from 'CALCULATIONS/cal_shareholders';
import {calDebtIteration} from 'CALCULATIONS/cal_debt';
import {calOtherBalanceIteration} from 'CALCULATIONS/cal_other_balance';
import calInflationFactor from 'CALCULATIONS/version_cal';

import ApiConnect from 'CONFIG/axios';
import UserContext from 'CONFIG/auth';
import Creators from 'CONFIG/CREATORS/creators'

import Loader from 'Support/loader';
import {PeriodHeader, FormEdit, NonEditableForm,SingleInputFormFlat,SingleInputLargeForm} from 'Support/inputforms'
import {SetAlert} from 'Support/alert'
import { RegularButton } from 'Support/listbuttons';

const supportFunctions={
  testerFunction:(input,props,id,unit)=>{
    if(input!=undefined){
      return(
        <FormEdit
        use_historic={true}
        item_holder={input}
        id={id}
        handleForm={props.handleEditChange}
        handleEqualizer={props.handleEqualizer}
        label={props.trsl[id]}
        unit={unit}
        />
      )
    }
  },
  nonEditableTesterFunction:(input,trsl,id)=>{
    if(input!=undefined){
      return(
        <NonEditableForm 
        item_holder={new Array(this.props.feasibility_settings.years.length).fill(input[input.length-1])}
        id={id}
        label={trsl[id]}
        unit={new Intl.NumberFormat('ja-JP', { style: 'currency', currency: this.props.feasibility_settings.currency}).format(0).replace(/\d./g,"")}
        />
      )
    } else{
      return(
        <NonEditableForm 
        item_holder={new Array(this.props.feasibility_settings.years).fill(0)}
        id={id}
        label={trsl[id]}
        unit={new Intl.NumberFormat('ja-JP', { style: 'currency', currency: this.props.feasibility_settings.currency}).format(0).replace(/\d./g,"")}
        />
      )
    }
  }
}

const iterateThroughBSRows=(ipt1,arr_size)=>{
  let returnable=new Array(arr_size)
  for(var i=0; i<returnable.length;i++){
    if(i===0){
      returnable[0]=ipt1[2]
    } else {
      returnable[i]=0
    }
  }
  return returnable
}
const iterateThroughISRows=(ipt1,arr_size)=>{
  let returnable=new Array(arr_size)
  for(var i=0; i<returnable.length;i++){
    returnable[i]=ipt1[2]
  }
  return returnable
}

class VersionFromHistoric extends React.Component {
  static contextType = UserContext

  constructor(props) {
    super(props);
    this.state = {
      version_details:{
        version_name:'',
        version_description:""
      },
      version_settings:{
        inflation:[],
        _inflation_factor:[],
        days_accounts_receivables:[],
        days_accounts_payables:[],
        investment_days_accounts_payables:[],
        version_corporate_tax_rate:[],   
      },
      number_of_historic_years:this.props.feasibility_settings.number_of_historic_years
    }
    this.handleEditChange = this.handleEditChange.bind(this);

    this.handleEqualizer=this.handleEqualizer.bind(this);
  }

  componentDidMount= async()=>{
    const array_size=this.props.feasibility_settings.number_of_years+this.state.number_of_historic_years
    const h_size=this.state.number_of_historic_years
    const data=this.props.data
    this.setState({
      version_settings:{
        inflation:new Array(this.props.feasibility_settings.number_of_years+this.state.number_of_historic_years).fill(0),
        _inflation_factor:calInflationFactor(iterateThroughISRows(new Array(this.props.feasibility_settings.number_of_years+this.state.number_of_historic_years).fill(0),array_size)),
        days_accounts_receivables:iterateThroughISRows(data.h_ratios.h_dar,array_size),
        days_accounts_payables:iterateThroughISRows(data.h_ratios.h_dap,array_size),
        investment_days_accounts_payables:iterateThroughISRows(data.h_ratios.h_dap,array_size),
        version_corporate_tax_rate:dotMultiply(iterateThroughISRows(data.h_ratios.h_corporate_tax_rate,array_size),100)
      }
    })    
  }

  handleEditChange=(id, item)=>{
    let go = this.state.version_settings;
    go[id]=item
    this.setState({data:go})
  }

  handleEqualizer=async(event)=>{
    const id = event.target.id;
    const value = event.target.value;
    let go = this.state.version_settings;
    go[id].fill(value,1);
    this.setState({data:go})
  }

  handleNameChange=(event)=>{
    const value = event.target.value;
    let go = this.state.version_details;
    go.version_name=value;
    this.setState({version_details:go })
  }

  handleNewVersion=async()=>{
    if(this.props.feasibility_settings.type_of_historic_input==="detailed"){
      await this.handleDetailedNewVersionFromHistoric()
    } else {
      await this.handleSimpleNewVersionFromHistoric()
    }
  }

  handleSimpleNewVersionFromHistoric=async()=>{
    const verifyBalanceSheet=(assets,eq_liabilities)=>{
      let ok=true
      for(var i=0;i<assets.length;i++){
        if(assets[i].toFixed(0)!==eq_liabilities[i].toFixed(0)){
          ok=false
        }
      }
      return ok
    }
    if(this.state.version_details.version_name.length>0 && verifyBalanceSheet(this.props.data.h_bal.h_total_assets,this.props.data.h_bal.h_equity_liabilities)){
      const array_size=this.props.feasibility_settings.number_of_years+this.state.number_of_historic_years
      const h_size=this.state.number_of_historic_years
      const last_element_picker=3

      //version settings
      let version_settings={
        inflation:this.state.version_settings.inflation,
        _inflation_factor:this.state.version_settings._inflation_factor,
        days_accounts_receivables:this.state.version_settings.days_accounts_receivables,
        days_accounts_payables:this.state.version_settings.days_accounts_payables,
        investment_days_accounts_payables:this.state.version_settings.investment_days_accounts_payables,
        version_corporate_tax_rate:this.state.version_settings.version_corporate_tax_rate,
        use_general_irc:false  
      }
      const version_historic_data={
        h_inventories:this.props.data.h_bal.h_inventories[last_element_picker-1],
        h_clients:this.props.data.h_bal.h_clients[last_element_picker-1],
        h_government_receivable:this.props.data.h_bal.h_government_receivable[last_element_picker-1],
        h_cash:this.props.data.h_bal.h_cash[last_element_picker-1],
        h_other_equity_changes:this.props.data.h_bal.h_other_equity_changes[last_element_picker-1],
        h_past_results:this.props.data.h_bal.h_past_results[last_element_picker-1],
        h_suppliers:this.props.data.h_bal.h_suppliers[last_element_picker-1],
        h_government_payable:this.props.data.h_bal.h_government_payable[last_element_picker-1],
        h_other_accounts_payables:this.props.data.h_bal.h_other_accounts_payables[last_element_picker-1],
      }
      
      //revenues
      let revenues=[new Creators.RevenueCreator(
        'historic data', 
        array_size,
        this.props.feasibility_settings.vat,
        this.props.feasibility_settings.raw_materials,
        true
        )]
      revenues[0].rev_quantity_type=1
      revenues[0].rev_quantity=iterateThroughISRows(this.props.data.h_inc_st.h_revenue,array_size)
      revenues[0].rev_price.fill(1)
      revenues[0].rev_cogs.cogs_type=true
      revenues[0].rev_cogs.s_cogs.s_cogs_percentage=dotMultiply(iterateThroughISRows(this.props.data.h_ratios.h_cogs_rate,array_size),100)
      revenues[0].rev_cogs.days_of_stock=iterateThroughISRows(this.props.data.h_ratios.h_dos,array_size)
      revenues=calRevIteration(revenues,this.props.feasibility_settings,version_settings._inflation_factor)
      
      //operating_expenses
      let operating_expenses=[new Creators.OperatingExpensesCreator(
        'historic data',
        array_size,
        this.props.feasibility_settings.vat,
        true
      )]
      operating_expenses[0].op_exp_period_type=1
      operating_expenses[0].op_exp_value=iterateThroughISRows(this.props.data.h_inc_st.h_operating_expenses,array_size)
      operating_expenses=calOpExpIterate(operating_expenses,this.props.feasibility_settings,version_settings._inflation_factor)
      
      //hr
      let hr=[new Creators.HrCreator(
        'historic data', 
        array_size,
        this.props.feasibility_settings.employees,
        this.props.feasibility_settings.corporate_social_security,
        true
      )]
      hr[0].hr_gross_salary=iterateThroughISRows(this.props.data.h_inc_st.h_hr,array_size)
      hr=calRhIterateSpecial(hr,this.props.feasibility_settings,version_settings._inflation_factor)
      
      //Other Gains
      let other_gain=[new Creators.OtherGainsCostCreator(
        'historic data',
        array_size,
        'other_gain',
        true
      )]
      other_gain[0].other_gains_cost_value=iterateThroughISRows(this.props.data.h_inc_st.h_other_gains,array_size)
      other_gain=calOtherGainsCostsIteration(other_gain)

      //Other Costs
      let other_cost=[new Creators.OtherGainsCostCreator(
        'historic data',
        array_size,
        'other_cost',
        true
      )]
      other_cost[0].other_gains_cost_value=iterateThroughISRows(this.props.data.h_inc_st.h_other_costs,array_size)
      other_cost=calOtherGainsCostsIteration(other_cost)

      //Operational Subsidies
      let operational_subsidy=[new Creators.OtherGainsCostCreator(
        'historic data',
        array_size,
        'operational_subsidy',
        true
      )]
      operational_subsidy[0].other_gains_cost_value=iterateThroughISRows(this.props.data.h_inc_st.h_operational_grants,array_size)
      operational_subsidy=calOtherGainsCostsIteration(operational_subsidy)
      
      //investment
      let h_tangible_assets=[
        new Creators.InvestmentCreator(
          'historic data',
          array_size,
          this.props.feasibility_settings.vat,
          true
        )
      ]
      h_tangible_assets[0].inv_year=0
      h_tangible_assets[0].inv_price=dotDivide(this.props.data.h_bal.h_tangible_assets[last_element_picker-1],1-this.props.data.h_ratios.h_depreciation_rate[last_element_picker-1])
      h_tangible_assets[0].inv_quantity=1
      h_tangible_assets[0].inv_month=0
      h_tangible_assets[0].inv_depreciation_rate=this.props.data.h_ratios.h_depreciation_rate[last_element_picker-1]
      h_tangible_assets=calInvestmentIteration(h_tangible_assets,this.props.feasibility_settings)

      let h_intangible_assets=[
        new Creators.InvestmentCreator(
          'historic data',
          array_size,
          this.props.feasibility_settings.vat,
          true
        )
      ]
      h_intangible_assets[0].inv_type='Intangible'
      h_intangible_assets[0].inv_year=0
      h_intangible_assets[0].inv_price=dotDivide(this.props.data.h_bal.h_intangible_assets[last_element_picker-1],1-this.props.data.h_ratios.h_depreciation_rate[last_element_picker-1])
      h_intangible_assets[0].inv_quantity=1
      h_intangible_assets[0].inv_month=0
      h_intangible_assets[0].inv_depreciation_rate=this.props.data.h_ratios.h_depreciation_rate[last_element_picker-1]
      h_intangible_assets=calInvestmentIteration(h_intangible_assets,this.props.feasibility_settings)

      //Issued Capital
      let issued_capital=[new Creators.ShareholdersCreator(
        'historic data',
        array_size,
        'issued_capital',
        true
      )]
      issued_capital[0].shareholders_increase=iterateThroughBSRows(this.props.data.h_bal.h_issued_capital,array_size)
      issued_capital=calShareholdersIteration(issued_capital)

      //Other Equity Instruments
      let other_equity_instruments=[new Creators.ShareholdersCreator(
        'historic data',
        array_size,
        'other_equity_instruments',
        true
      )]
      other_equity_instruments[0].shareholders_increase=iterateThroughBSRows(this.props.data.h_bal.h_other_equity_instruments,array_size)
      other_equity_instruments=calShareholdersIteration(other_equity_instruments)

      //shareholders_loan
      let shareholders_loan=[new Creators.ShareholdersCreator(
        'historic data',
        array_size,
        'shareholders_loan',
        true
      )]
      shareholders_loan[0].shareholders_increase=iterateThroughBSRows(this.props.data.h_bal.h_shareholders_loan,array_size)
      shareholders_loan=calShareholdersIteration(shareholders_loan)

      //debt
      let debt=[
        new Creators.DebtCreator(
          'historic data',
          array_size,
          0,
          true
        )
      ]
      debt[0].debt_reibursement_type=2
      debt[0].interest_rate_type="fixed"
      debt[0]._debt_disbursement[0]=this.props.data.h_bal.h_debt[last_element_picker-1]
      debt[0].debt_interest_rate=this.props.data.h_ratios.h_average_cost_of_debt[last_element_picker-1]*100
      debt=calDebtIteration(debt,this.props.feasibility_settings)
      
      //non_current_assets
      let non_current_assets=[new Creators.OtherBalanceCreator(
        'historic data',
        array_size,
        'non_current_assets',
        true
      )]
      non_current_assets[0].other_balance_increase=iterateThroughBSRows(this.props.data.h_bal.h_other_non_current_assets,array_size)
      non_current_assets=calOtherBalanceIteration(non_current_assets)
      //current_assets
      let current_assets=[new Creators.OtherBalanceCreator(
        'historic data',
        array_size,
        'current_assets',
        true
      )]
      current_assets[0].other_balance_increase=iterateThroughBSRows(this.props.data.h_bal.h_other_current_assets,array_size)
      current_assets=calOtherBalanceIteration(current_assets)
      //non_current_liabilities
      let non_current_liabilities=[new Creators.OtherBalanceCreator(
        'historic data',
        array_size,
        'non_current_liabilities',
        true
      )]
      non_current_liabilities[0].other_balance_increase=iterateThroughBSRows(this.props.data.h_bal.h_other_non_current_liabilities,array_size)
      non_current_liabilities=calOtherBalanceIteration(non_current_liabilities)
      //current_liabilities
      let current_liabilities=[new Creators.OtherBalanceCreator(
        'historic data',
        array_size,
        'current_liabilities',
        true
      )]
      current_liabilities[0].other_balance_increase=iterateThroughBSRows(this.props.data.h_bal.h_other_current_liabilities,array_size)
      current_liabilities=calOtherBalanceIteration(current_liabilities)


      //send request
      const request={
        project_id:this.props.project_id,
        version_size:array_size,
        version_name:this.state.version_details.version_name,
        version_descripion:this.state.version_details.version_description,
        version_settings,
        version_historic_data,
        revenues,
        operating_expenses,
        human_resources:hr,
        other_gain,
        other_cost,
        operational_subsidy,
        h_tangible_assets,
        h_intangible_assets,
        issued_capital,
        other_equity_instruments,
        shareholders_loan,
        debt,
        non_current_assets,
        current_assets,
        non_current_liabilities,
        current_liabilities
      }
      try {
        await ApiConnect.post('/api/version/createfromhistoric', request)
        const event={
          target:{
            name:'main'
          }
        }
        this.props.handleExitFromGistoricToMain()
      } catch(error) {
        alert(error);
      }
    } else {
      this.props.activateAlert('error','new_version_error','new_version_historic', this.context)
    }
  }

  handleDetailedNewVersionFromHistoric=async()=>{
    const data = this.props.data
    const verifyBalanceSheet=(assets,eq_liabilities)=>{
      let ok=true
      for(var i=0;i<assets.length;i++){
        if(assets[i].toFixed(0)!==eq_liabilities[i].toFixed(0)){
          ok=false
        }
      }
      return ok
    }
    if(this.state.version_details.version_name.length>0 && verifyBalanceSheet(data.h_bal.h_total_assets,data.h_bal.h_equity_liabilities)){
      const array_size=this.props.feasibility_settings.number_of_years+this.state.number_of_historic_years
      const h_size=this.state.number_of_historic_years
      const last_element_picker=3

      //version settings
      let version_settings={
        inflation:this.state.version_settings.inflation,
        _inflation_factor:this.state.version_settings._inflation_factor,
        days_accounts_receivables:this.state.version_settings.days_accounts_receivables,
        days_accounts_payables:this.state.version_settings.days_accounts_payables,
        investment_days_accounts_payables:this.state.version_settings.investment_days_accounts_payables,
        version_corporate_tax_rate:this.state.version_settings.version_corporate_tax_rate,
        use_general_irc:false  
      }
      const version_historic_data={
        h_inventories:data.h_bal.h_inventories[last_element_picker-1],
        h_clients:data.h_bal.h_clients[last_element_picker-1],
        h_government_receivable:data.h_bal.h_government_receivable[last_element_picker-1],
        h_cash:data.h_bal.h_cash[last_element_picker-1],
        h_other_equity_changes:data.h_bal.h_other_equity_changes[last_element_picker-1],
        h_past_results:data.h_bal.h_past_results[last_element_picker-1],
        h_suppliers:data.h_bal.h_suppliers[last_element_picker-1],
        h_government_payable:data.h_bal.h_government_payable[last_element_picker-1],
        h_other_accounts_payables:data.h_bal.h_other_accounts_payables[last_element_picker-1],
      }

      //revenues
      let revenues=[]
      for (var i=0;i <data.h_revs.length;i++){
        revenues[i]=new Creators.RevenueCreator( data.h_revs[i].name,  array_size, this.props.feasibility_settings.vat, this.props.feasibility_settings.raw_materials, true)
        revenues[i].rev_quantity_type=1
        revenues[i].rev_quantity=iterateThroughISRows(data.h_revs[i].value,array_size)
        revenues[i].rev_price.fill(1)
        revenues[i].rev_cogs.cogs_type=true
        revenues[i].rev_cogs.s_cogs.s_cogs_percentage=dotMultiply(iterateThroughISRows(data.h_ratios.h_cogs_rate,array_size),100)
        revenues[i].rev_cogs.days_of_stock=iterateThroughISRows(data.h_ratios.h_dos,array_size)
      }
      revenues=calRevIteration(revenues,this.props.feasibility_settings,version_settings._inflation_factor)
      console.log(1)
      //operating_expenses
      let operating_expenses=[]
      for (var i=0;i <data.h_op_exps.length;i++){
        operating_expenses[i] = new Creators.OperatingExpensesCreator( data.h_op_exps[i].name, array_size, this.props.feasibility_settings.vat, true)
        operating_expenses[i].op_exp_period_type=1
        operating_expenses[i].op_exp_value=iterateThroughISRows(data.h_op_exps[i].value,array_size)
      }
      operating_expenses=calOpExpIterate(operating_expenses,this.props.feasibility_settings,version_settings._inflation_factor)
      
      //hr
      let hr=[]
      for (var i=0;i <data.h_hrs.length;i++){
        hr[i] = new Creators.HrCreator(data.h_hrs[i].name,  array_size, this.props.feasibility_settings.employees, this.props.feasibility_settings.corporate_social_security, true)
        hr[i].hr_gross_salary=iterateThroughISRows(data.h_hrs[i].value,array_size)
      }
      hr=calRhIterateSpecial(hr,this.props.feasibility_settings,version_settings._inflation_factor)
      
      //Other Gains
      let other_gain=[]
      for (var i=0;i <data.h_ogs.length;i++){
        other_gain[i]=new Creators.OtherGainsCostCreator( data.h_ogs[i].name,array_size, 'other_gain', true)
        other_gain[i].other_gains_cost_value=iterateThroughISRows(data.h_ogs[i].value, array_size)
      }
      other_gain=calOtherGainsCostsIteration(other_gain)

      //Other Costs
      let other_cost=[]
      for (var i=0;i <data.h_ocs.length;i++){
        other_cost[i]=new Creators.OtherGainsCostCreator(data.h_ocs[i].name, array_size,'other_cost',true)
        other_cost[i].other_gains_cost_value=iterateThroughISRows(data.h_ocs[i].value, array_size)
      }
      other_cost=calOtherGainsCostsIteration(other_cost)

      //Operational Subsidies
      let operational_subsidy=[new Creators.OtherGainsCostCreator(
        'historic data',
        array_size,
        'operational_subsidy',
        true
      )]
      operational_subsidy[0].other_gains_cost_value=iterateThroughISRows(data.h_inc_st.h_operational_grants,array_size)
      operational_subsidy=calOtherGainsCostsIteration(operational_subsidy)

      //investment
      let h_tangible_assets=[]
      for (var i=0;i <data.h_tas.length;i++){
        h_tangible_assets[i] = new Creators.InvestmentCreator( data.h_tas[i].name, array_size, this.props.feasibility_settings.vat, true)
        h_tangible_assets[i].inv_year=0
        h_tangible_assets[i].inv_price=dotDivide(data.h_tas[i].value[last_element_picker-1],1-data.h_ratios.h_depreciation_rate[last_element_picker-1])
        h_tangible_assets[i].inv_quantity=1
        h_tangible_assets[i].inv_month=0
        h_tangible_assets[i].inv_depreciation_rate=data.h_ratios.h_depreciation_rate[last_element_picker-1]
      }
      h_tangible_assets=calInvestmentIteration(h_tangible_assets,this.props.feasibility_settings)

      let h_intangible_assets=[]
      for (var i=0;i <data.h_ias.length;i++){
        h_intangible_assets[i] = new Creators.InvestmentCreator( data.h_ias[i].name, array_size, this.props.feasibility_settings.vat, true)
        h_intangible_assets[i].inv_type='Intangible'
        h_intangible_assets[i].inv_year=0
        h_intangible_assets[i].inv_price=dotDivide(data.h_ias[i].value[last_element_picker-1],1-data.h_ratios.h_depreciation_rate[last_element_picker-1])
        h_intangible_assets[i].inv_quantity=1
        h_intangible_assets[i].inv_month=0
        h_intangible_assets[i].inv_depreciation_rate=data.h_ratios.h_depreciation_rate[last_element_picker-1]
      }
      h_intangible_assets=calInvestmentIteration(h_intangible_assets,this.props.feasibility_settings)

      //Issued Capital
      let issued_capital=[]
      for (var i=0;i <data.h_isc.length;i++){
        issued_capital[i] = new Creators.ShareholdersCreator( data.h_isc[i].name, array_size, 'issued_capital', true)
        issued_capital[i].shareholders_increase=iterateThroughBSRows(data.h_isc[i].value,array_size)
      }
      issued_capital=calShareholdersIteration(issued_capital)

      //Other Equity Instruments
      let other_equity_instruments=[]
      for (var i=0;i <data.h_oci.length;i++){
        other_equity_instruments[i] = new Creators.ShareholdersCreator( data.h_oci[i].name, array_size, 'other_equity_instruments', true )
        other_equity_instruments[i].shareholders_increase=iterateThroughBSRows(data.h_oci[i].value,array_size)
      }
      other_equity_instruments=calShareholdersIteration(other_equity_instruments)

      //shareholders_loan
      let shareholders_loan=[]
      for (var i=0;i <data.h_shl.length;i++){
        shareholders_loan[i] = new Creators.ShareholdersCreator( data.h_shl[i].name, array_size, 'shareholders_loan', true )
        shareholders_loan[i].shareholders_increase=iterateThroughBSRows(data.h_shl[i].value, array_size)
      }
      shareholders_loan=calShareholdersIteration(shareholders_loan)

      //debt
      let debt=[
        new Creators.DebtCreator(
          'historic data',
          array_size,
          0,
          true
        )
      ]
      debt[0].debt_reibursement_type=2
      debt[0].interest_rate_type="fixed"
      debt[0]._debt_disbursement[0]=this.props.data.h_bal.h_debt[last_element_picker-1]
      debt[0].debt_interest_rate=this.props.data.h_ratios.h_average_cost_of_debt[last_element_picker-1]*100
      debt=calDebtIteration(debt,this.props.feasibility_settings)
      
      //non_current_assets
      let non_current_assets=[]
      for (var i=0;i <data.h_onca.length;i++){
        non_current_assets[i] = new Creators.OtherBalanceCreator( data.h_onca[i].name, array_size, 'non_current_assets', true)
        non_current_assets[i].other_balance_increase=iterateThroughBSRows(data.h_onca[i].value, array_size)
      }
      non_current_assets=calOtherBalanceIteration(non_current_assets)
      
      //current_assets
      let current_assets=[]
      for (var i=0;i <data.h_oca.length;i++){
        current_assets[i] = new Creators.OtherBalanceCreator(data.h_oca[i].name, array_size, 'current_assets', true)
        current_assets[i].other_balance_increase=iterateThroughBSRows(data.h_oca[i].value, array_size)
      }
      current_assets=calOtherBalanceIteration(current_assets)
      
      //non_current_liabilities
      let non_current_liabilities=[]
      for (var i=0;i <data.h_oncl.length;i++){
        non_current_liabilities[i] = new Creators.OtherBalanceCreator( data.h_oncl[i].name, array_size, 'non_current_liabilities', true)
      non_current_liabilities[i].other_balance_increase=iterateThroughBSRows(data.h_oncl[i].value, array_size)
      }
      non_current_liabilities=calOtherBalanceIteration(non_current_liabilities)
      
      //current_liabilities
      let current_liabilities=[]
      for (var i=0;i <data.h_ocl.length;i++){
        current_liabilities[i] = new Creators.OtherBalanceCreator( data.h_ocl[i].name, array_size, 'current_liabilities', true)
        current_liabilities[i].other_balance_increase=iterateThroughBSRows(data.h_ocl[i].value, array_size)
      }
      current_liabilities=calOtherBalanceIteration(current_liabilities)


      //send request
      const request={
        project_id:this.props.project_id,
        version_size:array_size,
        version_name:this.state.version_details.version_name,
        version_descripion:this.state.version_details.version_description,
        version_settings,
        version_historic_data,
        revenues,
        operating_expenses,
        human_resources:hr,
        other_gain,
        other_cost,
        operational_subsidy,
        h_tangible_assets,
        h_intangible_assets,
        issued_capital,
        other_equity_instruments,
        shareholders_loan,
        debt,
        non_current_assets,
        current_assets,
        non_current_liabilities,
        current_liabilities
      }
      try {
        await ApiConnect.post('/api/version/createfromhistoric', request)
        const event={
          target:{
            name:'main'
          }
        }
        this.props.handleExitFromGistoricToMain()
      } catch(error) {
        alert(error);
      }
    } else {
      this.props.activateAlert('error','new_version_error','new_version_historic', this.context)
    }
  }

  render() {
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_PROJECT.PROJECT_HISTORIC.Version_from_historic')
    const props ={
      trsl:trsl,
      handleScreenNavigation:this.props.handleScreenNavigation,
      handleEditChange:this.handleEditChange,
      handleNewVersion:this.handleNewVersion,
      handleEqualizer:this.handleEqualizer,
      handleNameChange:this.handleNameChange,
      data:this.props.data,
      version_settings:this.state.version_settings,
      version_details:this.state.version_details,
      feasibility_settings:this.props.feasibility_settings,
      user_permission:this.props.user_permission
  }

    function torender(data, i){
      return data[i];
    }  
    const pages ={
      true: 
      <ToDisplay {...props}/>,
      
      false: 
      <Loader loader_message="vers_edit"/>
    }
    var i = this.props.loaded
    const renderizing = torender(pages,i)

    return(
      <Row>
        {renderizing}
      </Row>
    )
  }
}

class ToDisplay extends React.Component {
  render(){
    const h_year_arr=this.props.feasibility_settings.historic_years.concat(this.props.feasibility_settings.years)

    const props=this.props
    return(
      <Col xs={12}>
        <GlobalHeader {...props}/>
        <Row>
          <Col xs={12}>
            <h4>{this.props.trsl['version_settings']}</h4>
          </Col>
          <Col xs={12} >
            <PeriodHeader 
            label={this.props.trsl['year']}
            periods={h_year_arr} />
          </Col>
          <Col xs={12} className="feasibility_list">
            <ForVersionSettings {...props}/>
            <RegularButton bk='bk_primary' label={this.props.trsl['create_version_button']} onClick={this.props.handleNewVersion} />
          </Col>
          <Col className='blanktop_10'></Col>
        </Row>
      </Col>
    );
  }
}

class GlobalHeader extends React.Component{
  render(){
    let edit_ony_permission=true
    if(this.props.user_permission=="owner" || this.props.user_permission=="edit"){
      edit_ony_permission= false
    }
    return(
      <div>
        <Row className="feasibility_edit_globalheader_row">
          <Col xs={12}>
            <h4 className="headingtitle">{this.props.trsl['header']}</h4>
          </Col>
          <Col xs={12}>
            <SingleInputFormFlat name='version_name' value={this.props.version_details.version_name} label= {this.props.trsl['vs_name']} handleEditItemChange={this.props.handleNameChange}/>
          </Col>
          <Col xs={12}>
            <SingleInputLargeForm name='version_description' value={this.props.version_details.version_description} label= {this.props.trsl['vs_description']} maxlength={500} handleEditItemChange={this.props.handleNameChange}/>
          </Col>
        </Row>
      </div>
    );
  }
}

function ForVersionSettings (props){
    return(
      <>
        {supportFunctions.testerFunction(props.version_settings.inflation, props, 'inflation', '%')}
        {supportFunctions.testerFunction(props.version_settings.days_accounts_receivables, props, 'days_accounts_receivables','D')}
        {supportFunctions.testerFunction(props.version_settings.days_accounts_payables, props, 'days_accounts_payables','D')}
        {supportFunctions.testerFunction(props.version_settings.investment_days_accounts_payables, props, 'investment_days_accounts_payables','D')}
        {supportFunctions.testerFunction(props.version_settings.version_corporate_tax_rate, props, 'version_corporate_tax_rate','%')}
      </>
    )
}

export default VersionFromHistoric;