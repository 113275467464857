import React from 'react';
import {Row, Col, Grid} from 'Support/grid';
import { Route, Routes } from "react-router-dom";
import {SubscriptionManage, SubscriptionView} from './subscription_manage';
import SubscriptionsList from './subscription_list';
import SubscriptionBuy from './subscription_buy';
import SubscriptionThankYou from './subscription_thank_you';

import Session from 'CONFIG/session'

import './subscription_style.css';

const route="/app/subscriptions/"

class AccountMain extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      to_render: "list",
      subscription_id:0,
      subs_type:"",
    }
    this.handleScreenNavigation=this.handleScreenNavigation.bind(this)
    this.changeScreenView=this.changeScreenView.bind(this)
  }

  componentDidMount(){
    Session.setHistory(this.props.location)
  }

  changeScreenView=async(window, item,subs_type)=>{
    await this.setState({
      subscription_id: item,
      to_render: window,
      subs_type:subs_type
    })
    this.props.handlePrincipalRouting(route+this.state.to_render)
  }

  handleScreenNavigation(event){
    const value = event.target.value
    const name = event.target.name
    const id= event.target.id
    this.changeScreenView(name, value, id)
  }


  render(){
    const props={
      handleScreenNavigation:this.handleScreenNavigation,
      subscription_id:this.state.subscription_id
    }

    return(
      <Grid fluid>
        <Row noGutters className="subscription_placer">
          <Col xs={12}>
            <Routes>
              <Route index element={<SubscriptionsList {...props}/>}/>
              <Route path={`list`} element={<SubscriptionsList {...props}/>}/>
              <Route path={`view`} element={<SubscriptionView {...props}/>}/>            
              <Route path={`expand`} element={<SubscriptionManage {...props}/>}/>
              <Route path={`buy`} element={<SubscriptionBuy {...props}/>}/>
              <Route path={`acquired`} element={<SubscriptionThankYou {...props}/>}/>
            </Routes>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default AccountMain;