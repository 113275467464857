import React from 'react';
import { Nav,NavDropdown } from 'react-bootstrap';
import { Row, Col } from 'Support/grid';
import { Route, Routes } from "react-router-dom";
import {HistoricHeaderBar} from '../Feasibility/feasibility_headerbar';
import HistoricIncomeStatement from './simple';
import DetailedHistoricForms from './detailed';
import HistoricAnalysis from './analysis';
import VersionFromHistoric from './version_from_historic'
import {Navigation} from 'Support/navigation'

import {calculateHistoric, calculateDetailedHistoric} from '../../../CALCULATIONS/historic_cal'
import ApiConnect from 'CONFIG/axios';

import Session from 'CONFIG/session';
import UserContext from 'CONFIG/auth';

import 'CSS/feasibility_style.css';

const route="/app/business_plans/feasibility_historic/"

class FeasibilityHistoricPane extends React.Component{
  //Version Routing
  constructor(props) {
    super(props);
      this.state = {
      version_render: Session.getVersionRender(),
      tab_preference:true,
      data: "",
      loaded:false,
      edited:false,
    }
    this.handleSelect=this.handleSelect.bind(this);
    this.handleChangeFeasibilityHeader=this.handleChangeFeasibilityHeader.bind(this)
  }

  componentDidMount= async()=>{
    const request={
    project_id:this.props.project_id,
    }
    try {
      const response = await ApiConnect.post('/api/project/feasibility/historic/get', request)
      let go = response.data
      go=calculateHistoric(go,this.props.feasibility_settings.vat)
        this.setState({
          data:go,
          loaded:true,
          version_render:'forms'
        })
        Session.setVersionRender('income_statement')
    } catch(error) {
        alert(error);
    }
  }

  handleSave=async ()=>{
    const request={
      project_id:this.props.project_id,
      content:this.state.data
    }
    try{
      await ApiConnect.post('/api/project/feasibility/historic/save', request)
    } catch(error) {
        alert(error);
    }
  }


  //EDITIONS ON SIMPLE EDIT
  handleEditChangeIncSt=(id, item)=>{
    let go = this.state.data;
    go.h_inc_st[id]=item
    go=calculateHistoric(go,this.props.feasibility_settings.vat)
    this.setState({
      data:go,
      edited:true
    })
  }

  handleEditChangeBs=(id, item)=>{
    let go = this.state.data;
    go.h_bal[id]=item
    go=calculateHistoric(go,this.props.feasibility_settings.vat)
    this.setState({
      data:go,
      edited:true
    })
  }

  //EDITIONS ON DETAILED EDIT
  handleAddDetailedElement=async(event)=>{
    const element=event.target.name
    let go= this.state.data
    go[element].push({value:[0,0,0]})
    go=calculateDetailedHistoric(go,this.props.feasibility_settings.vat)
    this.setState({
      data:go
    })
  }

  handleDeleteDetailedElement=async(event)=>{
    const element=event.target.name
    let go= this.state.data
    go[element].splice(parseInt(event.target.value), 1)
    go=calculateDetailedHistoric(go,this.props.feasibility_settings.vat)
    this.setState({
      data:go
    })
  }

  handleEditDetailedChangeIncSt=async(id, value, name)=>{
    let go = this.state.data;
    go[name][id].value=value
    go=calculateDetailedHistoric(go,this.props.feasibility_settings.vat)
    this.setState({
      data:go,
      edited:true
    })
  }

  handleDetailedElementNameChange=(element,id,value)=>{
    let go = this.state.data;
    go[element][id].name=value
    this.setState({
      data:go,
      edited:true
    })
  }

  handleEditDetailedChangeBs=(id, item)=>{
    let go = this.state.data;
    go.h_bal[id]=item
    go=calculateHistoric(go,this.props.feasibility_settings.vat)
    this.setState({
      data:go,
      edited:true
    })
  }

  handleSelect(selectedKey) {
    this.setState({
      version_render: selectedKey
    })
    Session.setVersionRender(selectedKey)
    this.props.handlePrincipalRouting(route+selectedKey)
  }

  handleChangeFeasibilityHeader(){
    let go=this.state.tab_preference
    this.setState({
      tab_preference:!go
    })
  }

  render(){
    const props={
      data:this.state.data,
      loaded:this.state.loaded,
      version_id:this.props.version_id,
      project_id:this.props.project_id,
      user_id:this.props.user_id,
      user_permission:this.props.project_data.user_permission,
      feasibility_settings:this.props.feasibility_settings,
      p_name:this.props.project_data.project_name,
      v_name:this.props.v_name,
      version_render:this.state.version_render,
      handleExitFromGistoricToMain:this.props.handleExitFromGistoricToMain,
      active_key:this.state.version_render,
      handleSave:this.handleSave,
      handleSelect:this.handleSelect,
      handleChangeFeasibilityHeader:this.handleChangeFeasibilityHeader,
      handleEditChangeIncSt:this.handleEditChangeIncSt,
      handleEditChangeBs:this.handleEditChangeBs,
      handleAddDetailedElement:this.handleAddDetailedElement,
      handleDeleteDetailedElement:this.handleDeleteDetailedElement,
      handleEditDetailedChangeIncSt:this.handleEditDetailedChangeIncSt,
      handleDetailedElementNameChange:this.handleDetailedElementNameChange,
      activateAlert: this.props.activateAlert
    }

    const panes={
      true:<Pane1 {...props}/>,
      false:<Pane2 {...props}/>
    }

    function selectPane(options,input){
      return options[input]
    }
    let render=selectPane(panes,this.state.tab_preference)
    
    return(
      <>
        {render}
      </>
    )
  }
}

class Pane1 extends React.Component{
  render(){
    const props=this.props
    return(
      <>
        <Row className="feasibility_headerbar_panes">
          <Col xs={12}>
            <HistoricHeaderBar 
            p_name={this.props.p_name} 
            v_name={this.props.v_name} 
            version_render={this.props.version_render}
            handleExitFromGistoricToMain={this.props.handleExitFromGistoricToMain}
            handleChangeFeasibilityHeader={this.props.handleChangeFeasibilityHeader}/>
          </Col>
          <Col xs={2} className="bk_darkshade">
            <Navigation3 active_key={this.props.active_key} handleSelect={this.props.handleSelect}/>
          </Col>
          <Col xs={10} className="pane_content">
            <FeasibilityHistoriRoutes {...props}/>
          </Col>
        </Row>
      </>
    );
  }
}

class Pane2 extends React.Component{
  render(){
    const props=this.props
    return(
      <>
        <Row className="feasibility_headerbar_panes">
          <Col xs={12}>
            <HistoricHeaderBar 
            p_name={this.props.p_name} 
            v_name={this.props.v_name} 
            version_render={this.props.version_render}
            handleExitFromGistoricToMain={this.props.handleExitFromGistoricToMain}
            handleChangeFeasibilityHeader={this.props.handleChangeFeasibilityHeader}/>
          </Col>
          <Col xs={12} className="feasibility_pane_navigator2">
            <Navigation2 active_key={this.props.active_key} handleSelect={this.props.handleSelect}/>
          </Col>
          <Col xs={12}>
            <FeasibilityHistoriRoutes {...props}/>
          </Col>
        </Row>
      </>
    );
  }
}

function FeasibilityHistoriRoutes (props){
  const forms={
    simple:<HistoricIncomeStatement {...props}/>,
    detailed:<DetailedHistoricForms {...props}/>
  }
  return(
    <Routes>
      <Route index element={forms[props.feasibility_settings.type_of_historic_input]}/>
      <Route path={`forms`} element={forms[props.feasibility_settings.type_of_historic_input]}/>
      <Route path={`balance_sheet`} element={<DetailedHistoricForms {...props}/>}/>
      <Route path={`historic_analysis`} element={<HistoricAnalysis {...props}/>}/>
      <Route path={`version_from_historic`} element={<VersionFromHistoric {...props}/>}/>
    </Routes>
  )
}

class Navigation2 extends React.Component {
  static contextType = UserContext
  render(){
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_PROJECT.PROJECT_FEASIBILITY.Navigation&Header')
    const label=trsl[this.props.active_key]

    return(     
      <Nav variant="primary" activeKey={this.props.active_key} onSelect={this.props.handleSelect}>
        <NavDropdown title={label}>
          <NavDropdown.Item eventKey='forms'>{trsl['forms']}</NavDropdown.Item>
          <NavDropdown.Item eventKey='balance_sheet'>{trsl['balance_st']}</NavDropdown.Item>
          <NavDropdown.Item eventKey='historic_analysis'>{trsl['ratios']}</NavDropdown.Item>
          <NavDropdown.Item eventKey='version_from_historic'>{trsl['boot_strap_version']}</NavDropdown.Item>
        </NavDropdown>
      </Nav>
    );
  }
}

class Navigation3 extends React.Component {
  static contextType = UserContext
  render(){
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_PROJECT.PROJECT_HISTORIC.Navigation&Header')

    return(
      <Navigation handleSelect={this.props.handleSelect} active_key={this.props.active_key}>
        <Navigation.Item name='forms'>{trsl['forms']}</Navigation.Item>
        <Navigation.Item name='historic_analysis'>{trsl['ratios']}</Navigation.Item>
        <Navigation.Item name='version_from_historic'>{trsl['boot_strap_version']}</Navigation.Item>
      </Navigation> 
    );
  }
}

export default FeasibilityHistoricPane;