import React from 'react';
import './CSS/grid.css'

function Grid(props){
  function getProps(){
    if(props.className !== undefined){
      return props.className
    } else return ""
  }

  return(
    <div className={`grid_container ${getProps(props)}`}>
      {props.children}
    </div>
  )
}

function Row(props){
  function getProps(){
    if(props.className !== undefined){
      return props.className
    } else return ""
  }
  return(
    <div className={`grid_row ${getProps(props)}`}>
      {props.children}
    </div>
  )
}

function Col (props){
  let xs=""
  let sm=""
  let md=""
  let lg=""
  let xl=""
  let other_props=""
  function getProps(){
    if(props.xs !== undefined){
      xs=`xs${props.xs}`
    }
    if(props.sm !== undefined){
      sm=`sm${props.sm}`
    }
    if(props.md !== undefined){
      md=`md${props.md}`
    }
    if(props.lg !== undefined){
      lg=`lg${props.lg}`
    }
    if(props.xl !== undefined){
      xl=`xl${props.xl}`
    }
    if(props.className !== undefined){
      other_props=props.className
    }
  }
  getProps(props)

  return(
    <div className={`${xs} ${sm} ${md} ${lg} ${xl} ${other_props} grid_col`}>
      {props.children}
    </div>
  )
}

function Col_NoGutter (props){
  let xs=""
  let sm=""
  let md=""
  let lg=""
  let xl=""
  let other_props=""
  function getProps(){
    if(props.xs !== undefined){
      xs=`xs${props.xs}`
    }
    if(props.sm !== undefined){
      sm=`sm${props.sm}`
    }
    if(props.md !== undefined){
      md=`md${props.md}`
    }
    if(props.lg !== undefined){
      lg=`lg${props.lg}`
    }
    if(props.xl !== undefined){
      xl=`xl${props.xl}`
    }
    if(props.className !== undefined){
      other_props=props.className
    }
  }
  getProps(props)

  return(
    <div className={`${xs} ${sm} ${md} ${lg} ${xl} ${other_props} grid_col`}>
      {props.children}
    </div>
  )
}

export{
  Grid,
  Row,
  Col,
  Col_NoGutter
}