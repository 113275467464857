import React from 'react';
import { ToggleButtonGroup,ToggleButton, Collapse} from 'react-bootstrap';
import ReactGA from 'react-ga4';
import { Row, Col,Grid} from 'Support/grid';

import {ShowSubscription, SelectSubscription} from 'PRIVATE/ACCOUNT/subscription_manage';

import {ProjectNameDescription, ProjetFeasibilitySettings,ProjectYearAssumptions} from 'PRIVATE/PROJECT/Project main/settings';
import ProjectTemplates from './project_templates'
import Loader from 'Support/loader';
import { CreateToogleButtons } from 'Support/toogle_buttons';
import Panel from 'Support/panes';
import { RegularButton } from 'Support/listbuttons';
import ApiConnect from 'CONFIG/axios';
import UserContext from 'CONFIG/auth';

import 'CSS/project_style.css';
import default_values from 'JSON/countries_support.json';


function calculateYears(number, start){
  let all_years=[]
  for(let i=0; i<number;i++){
    all_years[i]=start + i;
  }
  return all_years
}

const initial_state={
  subscription_id:undefined,
  active_page:0,
  project_type:'business_plan',
  project_template_collapse:false,
  use_project_template:false,
  unused_subscriptions:"",
  subscriptions_load:false,
  subscription_data:"",
  subscription_data_loaded:false,
  project_name:"",
  project_description:"",
  feasibility_settings:{
    number_of_years:5,
    starting_year:Number(new Date().getFullYear()),
    years:calculateYears(5,new Date().getFullYear()),
    country:"PT",
    currency:"EUR",
    corporate_social_security:default_values.countries['PT'].corporate_social_security,
    irc:default_values.countries['PT'].irc,
    vat:default_values.countries['PT'].vat,
    vat_payment:default_values.countries['PT'].vat_payment,
    working_months:default_values.countries['PT'].working_months,
    raw_materials:true,
    employees:true,
    nature_or_functions:true,
    use_general_vat_rate:true,
    decimal_on_reports:false,
    use_historic:false,
    financial_type: 'complex', 
    financial_standalone_project:false, 
    financial_levered_project: true, 
    financial_investor: false
  }
}

class AddProject extends React.Component{
  static contextType = UserContext

  constructor(props) {
    super(props);
    this.state = initial_state
    this.handleEditSettingsChange=this.handleEditSettingsChange.bind(this)
    this.handleProjectSelection=this.handleProjectSelection.bind(this)
    this.handleChangeSubscription=this.handleChangeSubscription.bind(this)
    this.handleSubsriptionMoveForward=this.handleSubsriptionMoveForward.bind(this)
    this.handleChangeNameDescription=this.handleChangeNameDescription.bind(this)
    this.handleNameDescriptionMove=this.handleNameDescriptionMove.bind(this)
    this.handleChangeUseTemplate=this.handleChangeUseTemplate.bind(this)
    this.handleSettingsMove=this.handleSettingsMove.bind(this)
    this.handleCountryChange=this.handleCountryChange.bind(this)
    this.handleEnableDisableHistoric=this.handleEnableDisableHistoric.bind(this)
    this.handleSubmitEvent=this.handleSubmitEvent.bind(this)
    this.handleChangeNumberOfYears=this.handleChangeNumberOfYears.bind(this)
    this.handleChangeStartingYear=this.handleChangeStartingYear.bind(this)
  }

  componentDidMount=async()=>{
    this.getInititalData()
  }

  getInititalData=async()=>{
    const request={
    }
    try {
      const response = await ApiConnect.post('/api/user/subscriptions/list', request)
      this.setState({
        unused_subscriptions:response.data.unused_subscriptions,
        subscriptions_load:true,
      })
    } catch(error) {
        alert(error);
    }
  }

  //UTILITIES FOR OPENING AND MOVING FORWARD ON THE PANES

  //HANDLE PROJECT SELECTION AND MOVE FORWARD
  handleProjectSelection(event){
    const selection=event.target.value
    const template_collapse=(selection=== "business_plan" || selection==="feasibility_plan") ? true:false; 
    this.setState({
      project_type:selection,
      project_template_collapse:template_collapse,
      active_page:this.state.active_page+1
    });
  }
  

  //HANDLE SUBSCRIPTION SELECTION AND MOVE FORWARD
  handleChangeSubscription=async(event)=>{
    const value=event.target.value;
    if(value!=='null'){
      await this.setState({
        subscription_id:value,
      });
      this.updateSubscription()
    } else {
      this.setState({
        subscription_id:undefined,
        subscription_data:"",
        subscription_data_loaded:false,  
      })
    }
  }

  handleSubsriptionMoveForward(){
    if(this.state.subscription_id!==undefined){
      this.setState({
        active_page:this.state.active_page+1
      }) 
    } else {
      window.alert('You must choose a subscription first')
    }
  }

  
  handleSettingsMoveBackward=()=>{
    this.setState({
      active_page:this.state.active_page-1
    })
  }

  updateSubscription =async()=>{
    const request={
      subscription_id:this.state.subscription_id
    }
    try {
      const response = await ApiConnect.post('/api/user/subscription/unique/get', request)
      this.setState({
        subscription_data:response.data,
        subscription_data_loaded:true
      })
    } catch(error) {
        alert(error);
    }
  }

  //HANDLE NAME AND DESCRIPTION AND MOVE FORWARD
  handleChangeNameDescription(event){
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]:value
    })
  }

  handleFinancialType=(event)=>{
    let go=this.state.feasibility_settings
    let val= event.target.value
    if(val==="simple"){
      go.financial_type='simple'
      go.financial_standalone_project=false
      go.financial_levered_project=true
      go.financial_investor=false
    } else{
      go.financial_type='complex'
    }
    this.setState({
      projefeasibility_settingsct_data:go
    })
  }

  handleChangeUseTemplate(event){
    this.setState({
      use_project_template:event
    })
  }

  handleNameDescriptionMove(){
    if (this.state.project_name==="") {
      window.alert("You must give a name to your project")
    } else if(this.state.project_type==="business_plan" ||this.state.project_type==="feasibility_plan"){
      this.setState({
        active_page:this.state.active_page+1
      })
    } else {
      this.setState({
        active_page:this.state.active_page+1
      })
    }
  }

  //HANDLE SETTINGS
  handleSettingsMove(){
    this.setState({
      active_page:this.state.active_page+1
    })
  }

  handleEditSettingsChange=async(event)=>{
    const name = event.target.name;
    const value = event.target.value;
    let go = this.state.feasibility_settings;
    go[name]=value;
    const years= calculateYears(this.state.feasibility_settings.number_of_years, this.state.feasibility_settings.starting_year)
    go.years=years
    this.setState({
      feasibility_settings:go
    })
  }

  handleChangeStartingYear(event){
    const value=Number(event.target.value);
    const number=this.state.feasibility_settings.number_of_years
    const years= calculateYears(number, value)
    let go = this.state.feasibility_settings;
    go.starting_year=value
    go.years=years
    this.setState({
      feasibility_settings:go
    })
  }

  handleChangeNumberOfYears(event){
    const value=event.target.value;
    const start=this.state.feasibility_settings.starting_year
    const years= calculateYears(value, start)
    let go = this.state.feasibility_settings;
    go.number_of_years=value
    go.years=years
    this.setState({
      feasibility_settings:go
    })
  }

  handleCountryChange=async(event)=>{
    function validateInputs(input){
      if(input!==undefined){
        return input
      } else{
        return 0
      }
    }

    function validateVatInputs(input){
      if(input!==undefined){
        return input
      } else{
        return 30
      }
    }

    function validateMonthsInputs(input){
      if(input!==undefined){
        return input
      } else{
        return 12
      }
    }

    const name = event.target.name;
    const value = event.target.value;
    let go = this.state.feasibility_settings;
    go[name]=value;
    const years= calculateYears(this.state.feasibility_settings.number_of_years, this.state.feasibility_settings.starting_year)
    go.years=years
    go.irc=validateInputs(default_values.countries[value].irc)
    go.corporate_social_security=validateInputs(default_values.countries[value].corporate_social_security)
    go.vat=validateInputs(default_values.countries[value].vat)
    go.vat_payment=validateVatInputs(default_values.countries[value].vat_payment)
    go.working_months=validateMonthsInputs(default_values.countries[value].working_months)
    this.setState({
      feasibility_settings:go
    })
  }

  handleEnableDisableHistoric=async(event)=>{
    const value = event.target.value;
    const go= this.state.feasibility_settings
    go.use_historic=value
    this.setState({
      feasibility_settings:go
    })
  }

  // SUBMIT THE NEW PROJECT

  handleSubmitEvent=async(event)=>{
    try{
      await this.props.handleNewProject(
        this.state.project_name, 
        this.state.project_description, 
        this.state.feasibility_settings,
        this.state.subscription_id);
      this.setState({
        initial_state
      })
      ReactGA.event({category: "Business Plan Project", action: "Create Project"})
    } catch{
    }

  }


  render(){
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_PROJECT.PROJECT_MAIN.Project_settings+Add_project')

    const props={
      trsl:trsl,
      project_name:this.state.project_name,
      active_page:this.state.active_page,
      unused_subscriptions:this.state.unused_subscriptions,
      subscriptions_load:this.state.subscriptions_load,
      subscription_data:this.state.subscription_data,
      subscription_data_loaded:this.state.subscription_data_loaded,
      project_description:this.state.project_description,
      feasibility_settings:this.state.feasibility_settings,
      project_type:this.state.project_type,
      project_template_collapse:this.state.project_template_collapse,
      use_project_template:this.state.use_project_template,
      subscription_id:this.state.subscription_id,
      handleEditSettingsChange:this.handleEditSettingsChange,
      handleProjectSelection:this.handleProjectSelection,
      handleMoveToBuySubs:this.props.handleMoveToBuySubs,
      handleChangeSubscription:this.handleChangeSubscription,
      handleSubsriptionMoveForward:this.handleSubsriptionMoveForward,
      handleSettingsMoveBackward:this.handleSettingsMoveBackward,
      handleChangeNameDescription:this.handleChangeNameDescription,
      handleFinancialType:this.handleFinancialType,
      handleChangeUseTemplate:this.handleChangeUseTemplate,
      handleNameDescriptionMove:this.handleNameDescriptionMove,
      handleSettingsMove:this.handleSettingsMove,
      handleChangeStartingYear:this.handleChangeStartingYear,
      handleChangeNumberOfYears:this.handleChangeNumberOfYears,
      handleCountryChange:this.handleCountryChange,
      handleEnableDisableHistoric:this.handleEnableDisableHistoric,
      handleSubmitEvent:this.handleSubmitEvent,
      handlePrincipalButtonRouting:this.props.handlePrincipalButtonRouting
    }

    function torender(data, i){
      return data[i];
    } 

    const pages={
      true: <AddProjectMaster {...props}/>,
      false: <Loader loader_message="setting_up_projects"/>
    }
    var i = this.state.subscriptions_load
    const rendering=torender(pages,i)

    return(
      <>
        {rendering}
      </>
    )
  }
}

function AddProjectMaster (props){
  const keys=[
    {option_label:props.trsl['features']},
    {option_label:props.trsl['subscriptions']},
    {option_label:props.trsl['name']},
    {option_label:props.trsl['settings']},
    {option_label:props.trsl['finish']}
  ]

  function torender(data, i){
    return data[i];
  }
  const pages ={
    0: <AddProjectSelectProject {...props}/>,
    1: <AddProjectSubscription {...props}/>,
    2: <AddProjectName {...props}/>,
    3: <AddProjectGeneralSettings {...props}/>,
    4: <AddProjectFinish {...props}/>
    
  }
  var i = props.active_page
  const renderizing = torender(pages,i)

  return(
    <Panel
      headerbar={<ProjectAddHeader {...props}/>}
      left={<CreateToogleButtons toogle_data={keys} selected={props.active_page}/>}
      right={renderizing}
    />
  )
}

class ProjectAddHeader extends React.Component{
  render(){
    return(
      <Row>
        <Col xs={12} md={10}>
          <h2>{this.props.trsl['create_project_title']}</h2>
        </Col>
        <Col xs={6} md={2}>
          <RegularButton bk='bk_darkaccent' name="business_plans" onClick={this.props.handlePrincipalButtonRouting} label={this.props.trsl['back_button']} />
        </Col>  
      </Row>
    );
  }
}

class AddProjectSelectProject extends React.Component{
  render(){
    function countSubscriptions(obj, indexer){
      const now =Date.now()
      let obji=obj.filter(({subscription_validity})=>new Date(subscription_validity).getTime()>new Date(now).getTime())
      let counter=0
      let i
      for (i=0;i<obji.length;i++) {
        if(obji[i].subscription_type===indexer){
          counter=counter+1
        }
      }
      return counter
    }

    return (
      <>
        <Row around="xs" >
          <CardForProjectType
          trsl={this.props.trsl}
          value='business_plan'
          subs_number={countSubscriptions(this.props.unused_subscriptions,"business_plan")}
          handleProjectSelection={this.props.handleProjectSelection}
          handleMoveToBuySubs={this.props.handleMoveToBuySubs}
          />
        </Row>
      </>
    );
  }
}

class CardForProjectType extends React.Component{
  render(){
    const propas=this.props
    function setDisabled(item){
      if(item===0){
        return(
        <RegularButton bk='bk_darkaccent' value={propas.value} block onClick={propas.handleMoveToBuySubs} label={propas.trsl['go_to_buy_subs_button']}/>
        )
      } else {
        return(
          <RegularButton bk='bk_darkaccent' value={propas.value} block  onClick={propas.handleProjectSelection} label={propas.trsl['proceed']} />
        )
      }
    }

    let color="bk_danger"
    let message=this.props.trsl['message_negative']
    if (this.props.subs_number>0){
      color="bk_success"
      message=""
    }
    
    const button_proceed=setDisabled(this.props.subs_number)
    return(
      <Col xs={12}>
          <h3>{this.props.trsl['features']}</h3>
          {this.props.trsl['business_plan_description']}
          <Row>
            <Col xs={6} lg={3} className='blanktop_10'>
              <h5>{this.props.trsl['t2']}</h5>
              {this.props.trsl['te2']}
            </Col>
            <Col xs={6} lg={3} className='blanktop_10'>
              <h5>{this.props.trsl['t3']}</h5>
              {this.props.trsl['te3']}
            </Col>
            <Col xs={6} lg={3} className='blanktop_10'>
              <h5>{this.props.trsl['t4']}</h5>
              {this.props.trsl['te4']}
            </Col>
            <Col xs={6} lg={3} className='blanktop_10'>
              <h5>{this.props.trsl['t5']}</h5>
              {this.props.trsl['te5']}
            </Col>
          </Row>
          <div className='blanktop_15'></div>
          <Warning bk={color}>
            {<h6>{`${this.props.trsl['available']} ${this.props.subs_number}`}</h6>}
          </Warning>          
          {message}
          <Row>
            <Col xs={6}></Col>
            <Col xs={6} className='blanktop_10'>
              {button_proceed}
            </Col>
          </Row>
    </Col>
    )
  }
}

function Warning(props){
  return(
    <div className={`warning_div_outside bk_info`}>
      <div className={`warning_div_inside ${props.bk}`}>
        {props.children}
      </div>
    </div>
  )
}

class AddProjectSubscription extends React.Component{
  render(){

    const props=this.props
    const item_holder = this.props.subscription_id
    let item_distr
    if(item_holder !==undefined){
        item_distr = <ShowSubscription data={this.props.subscription_data} data_loaded={this.props.subscription_data_loaded}/>   
    } else {
      item_distr=this.props.trsl['select_sub_to_proceed']
    }

    const showButton=(input)=>{
      if(input===undefined){
        return(
          <RegularButton bk='bk_darkaccent' onClick={this.props.handleSubsriptionMoveForward} disabled label={this.props.trsl['use_sub']} />
        )
      } else{
        return(
          <RegularButton bk='bk_darkaccent' onClick={this.props.handleSubsriptionMoveForward} label={this.props.trsl['use_sub']} />
        )
      }
    }
    const button=showButton(this.props.subscription_id)

    return(
      <>
        <Row>
          <Col xs={12}>
            <h3>{this.props.trsl['subscriptions']}</h3>
          </Col>
            <SelectSubscription {...props}/>
          <Col xs={12}>
            {item_distr}
          </Col>
        </Row>
        <Row className='blanktop_10'>
          <Col xs={6}>
            <RegularButton bk='bk_darkaccent' onClick={this.props.handleSettingsMoveBackward} label={this.props.trsl['previous']}/>
          </Col>
          <Col xs={6}>
              {button}
          </Col>
        </Row>
      </>
    )
  }
}

class AddProjectName extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      open:this.props.project_template_collapse
    }
  }

  render(){
    const props=this.props
    
    let renderizing=""
    const showTemplates=(input)=>{
      if(input===true){
        return <ProjectTemplates {...props}/>
      }
    }
    renderizing=showTemplates(this.props.use_project_template)

    const holdtemplatesforlateruse=(
      <Row>
      <Col xs={12}>
        <Collapse in={this.state.open} mountOnEnter={true}>
          <div>
            <h6>{this.props.trsl['use_template_question']}</h6>
            <ToggleButtonGroup type="radio" name="template" value={this.props.use_project_template} onChange={this.props.handleChangeUseTemplate}>
              <ToggleButton value={false}>{this.props.trsl['no']}</ToggleButton>
              <ToggleButton value={true}>{this.props.trsl['yes']}</ToggleButton>
            </ToggleButtonGroup>
            {renderizing}
          </div>
        </Collapse>
      </Col>
    </Row>
    )

    return (
      <>
      <Row>
        <Col xs={12}>
          <ProjectNameDescription {...props} />  
        </Col>
        </Row>
        <Row className='blanktop_10'>
        <Col xs={6}>
          <RegularButton bk='bk_darkaccent'  onClick={this.props.handleSettingsMoveBackward} label={this.props.trsl['previous']}/>
        </Col>
        <Col xs={6}>
          <RegularButton bk='bk_darkaccent'  onClick={this.props.handleNameDescriptionMove} label={this.props.trsl['next']}/>
        </Col>
      </Row>
      </>
    );
  }
}


class AddProjectGeneralSettings extends React.Component{
  render(){
    const props=this.props
    
    return (
      <Grid fluid>
      <Row>
        <Col xs={12}>
          <ProjectYearAssumptions {...props}/>
        </Col>
        <Col xs={12}>
          <ProjetFeasibilitySettings {...props}/>
        </Col>
      </Row>
      <Row className='blanktop_10'>
        <Col xs={6}>
          <RegularButton bk='bk_darkaccent'  onClick={this.props.handleSettingsMoveBackward} label={this.props.trsl['previous']}/>          
        </Col>
        <Col xs={6}>
          <RegularButton bk='bk_darkaccent'  onClick={this.props.handleSettingsMove} label={this.props.trsl['next']}/>
        </Col>
      </Row>
      </Grid>
    );
  }
}

class AddProjectFinish extends React.Component{
  render(){
    return (
      <>
        <Row center="xs">
          <Col xs={6}>
            <h4>{this.props.trsl['exclamation']}</h4>
            {this.props.trsl['finish_creation_statement']}
          </Col>
        </Row>
        <Row className='blanktop_10'>
          <Col xs={6}>
            <RegularButton bk='bk_darkaccent'onClick={this.props.handleSettingsMoveBackward} label={this.props.trsl['previous']} />          
          </Col>
          <Col xs={6}>
            <RegularButton bk='bk_darkaccent'onClick={this.props.handleSubmitEvent} label={this.props.trsl['execute_creation']} />
          </Col>
        </Row>
      </>
    );
  }
}

export default AddProject;