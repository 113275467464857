import React from 'react';
import {Row, Col} from 'Support/grid'
import {Button, Form} from 'react-bootstrap';

import ApiConnect from 'CONFIG/axios';

import './admin_style.css';
import { EditButton,DeleteButton } from 'Support/listbuttons.js';
import {SingleInputForm, SingleInputIntegerForm} from 'Support/inputforms';

const dateRenderizing=(input)=>{
  if(input!=undefined){
    return(
      new Intl.DateTimeFormat('en-US').format(new Date(input))
    )
  }
}

const booleanRenderizing=(input)=>{
  if(input===true){
    return 'True'
  } else if(input===false){
    return 'False'
  } else return ''
}

class AdminPartnerUsers extends React.Component{
  constructor(props){
    super(props);
    this.state = {
        screen_admin_partners: 'list',
        active_partner:0,
        active_partnership:0,
    }
    this.handleGetUserInfoAndShowUser=this.handleGetUserInfoAndShowUser.bind(this)
    this.handleGoToUsersList=this.handleGoToUsersList.bind(this)
    this.handleGoToUser=this.handleGoToUser.bind(this)
    this.handleGoToPartnership=this.handleGoToPartnership.bind(this)
    this.handleGoToNewPartnership=this.handleGoToNewPartnership.bind(this)
  }


  handleGetUserInfoAndShowUser=async (event)=>{
    let set_item=""
    if(event!==undefined){
      set_item=event.target.value
    }
    
    await this.setState({
      active_partner:set_item,
      screen_admin_partners:'view_partner',
    })
  }

  handleGoToUsersList=()=>{
    this.setState({
      screen_admin_partners:'list',
    })
  }

  handleGoToNewPartnership=()=>{
    this.setState({
      screen_admin_partners:'new_partnership',
    })
  }

  handleGoToUser=()=>{
    this.setState({
      screen_admin_partners:'view_partner',
    })
  }

  handleGoToPartnership=(event)=>{
    const value=event.target.value
    this.setState({
      active_partnership:value,
      screen_admin_partners:'partnership'
    })
  }

  handleDeletePartnership=async (event)=>{
    const request={
      partnership_id:event.target.value,
      user_id:this.state.active_partner
    }
    try {
      await ApiConnect.post('/api/admin/partnership/delete', request)
      this.handleGoToUser()
    } catch(error) {
        alert(error);
    }
  }

  render(){
    const props={
      active_partner:this.state.active_partner,
      active_partnership:this.state.active_partnership,
      handleGetUserInfoAndShowUser:this.handleGetUserInfoAndShowUser,
      handleGoToUsersList:this.handleGoToUsersList,
      handleGoToUser:this.handleGoToUser,
      handleGoToPartnership:this.handleGoToPartnership,
      handleGoToNewPartnership:this.handleGoToNewPartnership
    }

    const pages={
      list: <AdminPartnersList {...props}/>,
      view_partner:<AdminPartnersPartner {...props}/>,
      partnership:<ViewPartnership {...props}/>,
      new_partnership:<CreateNewParternship {...props}/>
    }
    function toRender(data, i){
      return data[i];
    } 
    const renderizing = toRender(pages,this.state.screen_admin_partners)

    return(
      <>
        {renderizing}
      </>
    )
  }
}

class AdminPartnersList extends React.Component{
  constructor(props){
    super(props);
    this.state = {
        data: undefined,
        filter:"",
        usable_data:undefined
    }
    this.handleSortRequest=this.handleSortRequest.bind(this);
  }

  componentDidMount(){
    this.updateAccountList()
  }

  updateAccountList=async()=>{
    const request={}
    try {
      const response = await ApiConnect.post('api/admin/partners/get', request)
      this.setState({
        data:response.data,
        filter:"",
        usable_data:response.data,
        sorter:"_id"
      })
    } catch(error) {
        alert(error);
    }
  }

  filterTheTable=async(event)=>{
    const type_of_filter=event.target.name
    const filterItems = (arr, query) => {
      return arr.filter(el => el[type_of_filter].toLowerCase().indexOf(query.toLowerCase()) !== -1)
    }
    let go=this.state.data
    let tester= event.target.value
    if(tester.length>0){
      go=filterItems(this.state.data,tester)
    }
    this.sortTheTable(go)
  }

  handleSortRequest=async(event)=>{
    await this.setState({
      sorter:event.target.name
    })
    this.sortTheTable(this.state.usable_data)
  }

  sortTheTable=(data)=>{
    const sortItems = (arr, sorter) => {
      return arr.sort(function (a, b) {
      if (a[sorter] > b[sorter]) {
        return 1;
      }
      if (a[sorter] < b[sorter]) {
        return -1;
      }
      return 0
    })}
    let bolas=this.state.sorter
    let go=sortItems(data,bolas)
    this.setState({
      usable_data:go
    })
  }

  render(){
    const props={
      handleSortRequest:this.handleSortRequest,
      data:this.state
    }

    const item_holder = this.state.usable_data
    let item_distr="There are no users"
    if(item_holder !==undefined){
      item_distr = item_holder.map((value,key) =>
        <Row className='admin_al_lists_rows' middle='xs'>
          <Col xs={1}>
            {key+1}
          </Col>
          <Col xs={2}>
            {value._id}
          </Col>
          <Col xs={2}>
            {value.user_name}
        </Col>
        <Col xs={2}>
          {value.user_email}
        </Col>
        <Col xs={2}>
          {value.user_type}
        </Col>
        <Col xs={1}>
          {value.user_status}
        </Col>
        <Col xs={1}>
          {value.user_partnerships.length}
        </Col>
        <Col xs={1}>
          <EditButton handleEditItem={this.props.handleGetUserInfoAndShowUser} value={value._id}/>
        </Col>
      </Row>
      );
    }

    return(
      <>
        <Row className='admin_al_filter_col'>
          <Col xs={12}>
            <h4>Filters</h4>
          </Col>
          <Col xs={3} >
            <h6>Filter by e-mail:</h6>
            <SingleInputForm
            name="user_email"
            handleEditItemChange={this.filterTheTable} />
          </Col>
          <Col xs={3}>
            <h6>Filter by name:</h6>
            <SingleInputForm
            name="user_name"
            handleEditItemChange={this.filterTheTable} />
          </Col>
          <Col xs={3}>
            <h6>Filter by User Type:</h6>
            <SingleInputForm
            name="user_type"
            handleEditItemChange={this.filterTheTable} />
          </Col>
          <Col xs={3}>
            <h6>Filter by Status:</h6>
            <SingleInputForm
            name="user_status"
            handleEditItemChange={this.filterTheTable} />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <TableHeader {...props} />
          </Col>

          <Col xs={12}>
            {item_distr}
          </Col>
        </Row>
      </>
    )
  }
}

class TableHeader extends React.Component{
  render(){
    return(
      <Row>
      <Col xs={1}>
        
      </Col>
      <Col xs={2}>
        <Button block size="sm" name="_id" onClick={this.props.handleSortRequest}>ID</Button>
      </Col>
      <Col xs={2}>
      <Button block size="sm" name="user_name" onClick={this.props.handleSortRequest}>User name</Button>
    </Col>
    <Col xs={2}>
      <Button block size="sm" name="user_email" onClick={this.props.handleSortRequest}>E-mail</Button>
    </Col>
    <Col xs={2}>
    <Button block size="sm" name="user_type" onClick={this.props.handleSortRequest}>User Type</Button>
    </Col>
    <Col xs={1}>
      <Button block size="sm" name="user_status" onClick={this.props.handleSortRequest}>User-status</Button>
    </Col>
    <Col xs={1}># Packs</Col>
    <Col xs={1}>
    </Col>
  </Row>
    )
  }
}

class AdminPartnersPartner extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      partner_data: ''
    }
  }

  componentDidMount(){
    this.updateUser()
  }

  updateUser=async()=>{
    const request={
      partner_id:this.props.active_partner
    }
    try {
      const response = await ApiConnect.post('/api/admin/partner/details/get', request)
      this.setState({
        partner_data:response.data
      })
    } catch(error) {
        alert(error);
    }
  }

  render(){
    return(
      <>
        <Row>
          <Col xs={6}>
            <h3>Partner</h3>
          </Col>
          <Col xs={3}>
            <Button size='sm' block onClick={this.props.handleGoToUsersList}>Back to patners list</Button>
          </Col>
          <Col xs={3}>
            <Button size='sm' variant='warning' block onClick={this.props.handleGoToNewPartnership}>New Partnership</Button>
          </Col>
        </Row>
        <Row>
          <Col xs={3} className='admin_al_user_cols'>
            <h6>User ID</h6>
            {this.state.partner_data._id}
          </Col>
          <Col xs={3} className='admin_al_user_cols'>
            <h6>User Name</h6>
            {this.state.partner_data.user_name}
          </Col>
          <Col xs={3} className='admin_al_user_cols'>
            <h6>E-mail</h6>
            {this.state.partner_data.user_email}
          </Col>
          <Col xs={3} className='admin_al_user_cols'>
            <h6>Status</h6>
            {this.state.partner_data.user_status}
          </Col>
          <Col xs={3} className='admin_al_user_cols'>
            <h6>Type</h6>
            {this.state.partner_data.user_type}
          </Col>
          <Col xs={3} className='admin_al_user_cols'>
            <h6>Type</h6>
          { this.state.partner_data.user_type}
          </Col>
          <Col xs={3} className='admin_al_user_cols'>
            <h6>Birth</h6>
            {this.state.partner_data.user_birth}
          </Col>
          <Col xs={3} className='admin_al_user_cols'>
            <h6>Country</h6>
            {this.state.partner_data.user_country}
          </Col>
          <Col xs={3} className='admin_al_user_cols'>
            <h6>Creation</h6>
            {dateRenderizing(this.state.partner_data.user_creation_date)}
          </Col>
          <Col xs={3} className='admin_al_user_cols'>
            <h6>Activation</h6>
            {dateRenderizing(this.state.partner_data.user_registration_date)}
          </Col>
          <Col xs={3} className='admin_al_user_cols'>
            <h6>Last Login</h6>
            {dateRenderizing(this.state.partner_data.user_lastlogin_date)}
          </Col>
          <Col xs={3} className='admin_al_user_cols'>
            <h6>Number of logins</h6>
            {this.state.partner_data.user_number_of_logins}
          </Col>
          <Col xs={12} className='admin_al_user_cols'>
            <h5>Partnerships</h5>
            <ViewPartnershipsIOfPartner item_holder={this.state.partner_data.user_partnerships} handleGoToPartnership={this.props.handleGoToPartnership}/>
          </Col>
          <Col xs={12}>
          </Col>
        </Row>
      </>
    )
  }
}

function ViewPartnershipsIOfPartner(props){
  const item_holder = props.item_holder
  let item_distr="There are no available partnerships"
  if(item_holder !==undefined){
    item_distr = item_holder.map((value) =>
      <Row className='admin_al_lists_rows' center='xs' middle='xs'>
        <Col xs={2}>
          {value._id}
        </Col>
        <Col xs={2}>
          {value.pts_name}
        </Col>
        <Col xs={1}>
          {value.pts_type}
        </Col>
        <Col xs={1}>
          {value.pts_number_subs}
        </Col>
        <Col xs={1}>
          {value.pts_permission}
        </Col>
        <Col xs={1}>
          {value.pts_number_of_versions}
        </Col>
        <Col xs={1}>
          {booleanRenderizing(value.pts_duration_type)}
        </Col>
        <Col xs={1}>
          {dateRenderizing(value.pts_validity)}
        </Col>
        <Col xs={2}>
          <EditButton value={value._id} handleEditItem={props.handleGoToPartnership}/>
          <DeleteButton value={value._id} handleEditItem={props.handleGoToPartnership}/>
        </Col>
      </Row>
    )
  }
  return(
    <>
      <ViewPartnershipsIOfPartnerListHeader />
      {item_distr}
    </>
  )
}

function ViewPartnershipsIOfPartnerListHeader(){
  return(
    <Row className='list_table_header'>
      <Col xs={2}>
        ID
      </Col>
      <Col xs={2}>
        Name
      </Col>
      <Col xs={1}>
        Type
      </Col>
      <Col xs={1}>
        Subs
      </Col>
      <Col xs={1}>
        Permission
      </Col>
      <Col xs={1}>
        Versions
      </Col>
      <Col xs={1}>
        Flex/Fixed
      </Col>
      <Col xs={1}>
        Validity
      </Col>
      <Col xs={2}>
        Edit/Delete
      </Col>
    </Row>
  )
}

class ViewPartnership extends React.Component{
  constructor(props){
    super(props);
    this.state = {
        data: "",
    }
    this.handleChangePartnership=this.handleChangePartnership.bind(this)
    this.handleChangePartnershipBool=this.handleChangePartnershipBool.bind(this)
    this.handleChangePartnershipFloat=this.handleChangePartnershipFloat.bind(this)
    this.handleChangeFixed=this.handleChangeFixed.bind(this)
    this.handleChangeFlex=this.handleChangeFlex.bind(this)
  }

  componentDidMount(){
    this.updateSub()
  }

  updateSub=async()=>{
    const request={
      partnership_id:this.props.active_partnership
    }
    try {
      const response = await ApiConnect.post('/api/admin/partnership/details/get', request)
      this.setState({
        data:response.data,
      })
    } catch(error) {
        alert(error);
    }
  }

  handleChangePartnership(event){
    const name=event.target.name
    const value=event.target.value
    let go= this.state.data
    go[name]=value
    this.setState({
      data:go
    })
  }

  handleChangePartnershipBool(event){
    const name=event.target.name
    const value=(event.target.value==='true')

    let go= this.state.data
    go[name]=value
    this.setState({
      data:go
    })

  }

  handleChangePartnershipFloat(event){
    const name=event.target.name
    const value=parseFloat(event.target.value)
    let go= this.state.data
    go[name]=value
    this.setState({
      data:go
    })
  }

  handleChangeFlex(event){
    const value=(event.target.value)
    let go= this.state.data
    go.pts_duration_flex_days=value
    this.setState({
      data:go
    })
  }

  handleChangeFixed(event){
    const name=event.target.name
    const value=(event.target.value)
    let go= this.state.data
    go[name]=value
    go.pts_duration_type=false
    go.subs_duration_deadline=value
    this.setState({
      data:go
    })
  }
  
  render(){
    const renderBoolean=(input)=>{
      if(input==true){
        return 'True'
      } else {return 'False'}
    }

    const renderFixedDateOption=(input)=>{
      if(input==true){
        return (
          <Col xs={2}>
            <SingleInputIntegerForm
            label='Duration flex'
            value={this.state.data.pts_duration_flex_days}
            name='pts_duration_flex_days'
            handleinputeditChange ={this.handleChangeFlex}/>
          </Col>
        )
      } else {
        return(
          <Col xs={2}>
            <SubsFormDate
            label='Duration fixed'
            value={(this.state.data.pts_duration_fixed_day)}
            name='pts_duration_fixed_day'
            handleEditItemChange={this.handleChangeFixed}/>
          </Col>
        )
      }
    }

    function receiversCalc(input){
      if(input!==undefined){
        return input.length
      }else{ 
        return 0
      }
    }
    const receivers=receiversCalc(this.state.data.pts_receivers)
    
    return(
      <Row>
        <Col xs={9}>
          <h2>Partnership pack</h2>
        </Col>
        <Col xs={3}>
          <Button size='sm' block onClick={this.props.handleGoToUser}>Back to partner</Button>
        </Col>
        <Col xs={12} className='admin_al_sub_main_cols'>
          <h5>Partnership pack</h5>
        </Col>
        <Col xs={3} className='admin_al_sub_cols'>
          <h6>ID</h6>
          {this.state.data._id}
        </Col>
        <Col xs={3} className='admin_al_sub_cols'>
          <h6>Creation date</h6>
          {dateRenderizing(this.state.data.pts_date)}
        </Col>
        <Col xs={3} className='admin_al_sub_cols'>
          <h6>Receivers</h6>
          {receivers}
        </Col>
        <Col xs={12} className='admin_al_sub_main_cols'>
          <h5>Partnership setup changes</h5>
        </Col>
        <Col xs={2}>
          <SingleInputForm
          label='Type'
          value={this.state.data.pts_type}
          name='pts_type'
          handleEditItem={this.handleChangePartnership}/>
        </Col>
        <Col xs={2}>
        <SingleInputForm
          label='Permission'
          value={this.state.data.pts_permission}/>
        </Col>
        <Col xs={2}>
          <Form>
            <Form.Group>
              <Form.Label>TEMPLATE</Form.Label>
              <Form.Control as='select' name='pts_template' value={this.state.data.pts_template} onChange={this.handleChangePartnership}>
                <option value='casflo'>CASFLO</option>
                <option value='viseeon'>VISEEON</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </Col>
        <Col xs={2}>
          <SingleInputIntegerForm
            label='Number of colaborators'
            value={this.state.data.pts_number_of_colaborators}
            name='pts_number_of_colaborators'
            handleinputeditChange={this.handleChangePartnershipFloat}/>
        </Col>
        <Col xs={2}>
          <SingleInputIntegerForm
            label='Number of viewers'
            value={this.state.data.pts_number_of_viewers}
            name='pts_number_of_viewers'
            handleinputeditChange={this.handleChangePartnershipFloat}/>
        </Col>
        <Col xs={2}>
          <SingleInputIntegerForm
            label='Feasibility max versions'
            value={this.state.data.pts_number_of_versions}
            name='pts_number_of_versions'
            handleinputeditChange={this.handleChangePartnershipFloat}/>
        </Col>
        <Col xs={2}>
        <SubsDateType
          label='Duration type'
          value={this.state.data.pts_duration_type}
          name='pts_duration_type'
          handleEditItemChange={this.handleChangePartnershipBool}/>
        </Col>
        {renderFixedDateOption(this.state.data.pts_duration_type)}
        <Col xs={2}>
            <SubsFormDate
            label='Partnership validity'
            value={(this.state.data.pts_validity)}
            name='pts_validity'
            handleEditItemChange={this.handleChangeFixed}/>
          </Col>
      </Row>
    )
  }
}

class CreateNewParternship extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      data:{
        recipient_id: this.props.active_partner,
        pts_name:'',
        pts_type:'business_plan',
        pts_template:'casflo',
        pts_number_subs:5,
        pts_permission:'Full', 
        pts_number_of_colaborators:5,
        pts_number_of_viewers:10,
        pts_number_of_versions:6,
        pts_validity:new Date(),  
        pts_duration_type: true,
        pts_duration_flex_days:15,
        pts_duration_fixed_day: new Date(),
      }
    }
    this.handleChangePartnership=this.handleChangePartnership.bind(this)
    this.handleChangePartnershipBool=this.handleChangePartnershipBool.bind(this)
    this.handleChangePartnershipFloat=this.handleChangePartnershipFloat.bind(this)
    this.handleChangeFixed=this.handleChangeFixed.bind(this)
    this.handleChangeFlex=this.handleChangeFlex.bind(this)
    this.handlePartnershipValidity=this.handlePartnershipValidity.bind(this)
    this.handleCreatePartnership=this.handleCreatePartnership.bind(this)
  }

  handleChangePartnership(event){
    const name=event.target.name
    const value=event.target.value
    let go= this.state.data
    go[name]=value
    this.setState({
      data:go
    })
  }

  handleChangePartnershipBool(event){
    const name=event.target.name
    const value=(event.target.value==='true')
    let go= this.state.data
    if(value){
      const today= new Date()
      go.pts_validity=new Date(today.setDate(today.getDate()+180))
    } else{
      go.pts_validity=go.pts_duration_fixed_day
    }
    
    go[name]=value
    this.setState({
      data:go
    })

  }

  handleChangePartnershipFloat(event){
    const name=event.target.name
    const value=parseFloat(event.target.value)
    let go= this.state.data
    go[name]=value
    this.setState({
      data:go
    })
  }

  handleChangeFlex(event){
    const value=(event.target.value)
    let go= this.state.data
    const today= new Date()
    go.pts_duration_flex_days=value
    go.pts_validity=new Date(today.setDate(today.getDate()+180))
    this.setState({
      data:go
    })
  }

  handlePartnershipValidity(event){
    const name=event.target.name
    const value=(event.target.value)
    let go= this.state.data
    go[name]=value
    this.setState({
      data:go
    })
  }

  handleChangeFixed(event){
    const name=event.target.name
    const value=(event.target.value)
    let go= this.state.data
    go[name]=value
    go.pts_duration_type=false
    go.pts_validity=value
    this.setState({
      data:go
    })
  }

  handleCreatePartnership=async()=>{
    const request={
      content:this.state.data
    }
    window.confirm('Confirm new partnership?')
    try {
      await ApiConnect.post('api/admin/partnership/create', request)
      this.props.handleGoToUser()
    } catch(error) {
        alert(error);
    }
  }
  
  render(){
    const renderFixedDateOption=(input)=>{
      if(input==true){
        return (
          <Col xs={2}>
            <SingleInputIntegerForm
            label='Duration flex'
            value={this.state.data.pts_duration_flex_days}
            name='pts_duration_flex_days'
            handleinputeditChange ={this.handleChangeFlex}/>
          </Col>
        )
      } else {
        return(
          <Col xs={2}>
            <SubsFormDate
            label='Duration fixed'
            value={(this.state.data.pts_duration_fixed_day)}
            name='pts_duration_fixed_day'
            handleEditItemChange={this.handleChangeFixed}/>
          </Col>
        )
      }
    }
    
    return(
      <>
      <Row>
        <Col xs={9}>
          <h2>New Partnership</h2>
        </Col>
        <Col xs={3}>
          <Button size='sm' block onClick={this.props.handleGoToUser}>Back to user</Button>
        </Col>
        <Col xs={12} className='admin_al_sub_main_cols'>
          <h5>Partnership setup changes</h5>
        </Col>
        <Col xs={2}>
          <SingleInputForm
            label='Partnership name'
            value={this.state.data.pts_name}
            name='pts_name'
            handleEditItemChange={this.handleChangePartnership}/>
        </Col>
        <Col xs={2}>
          <Form>
            <Form.Group>
              <Form.Label>Type</Form.Label>
              <Form.Control as='select' name='pts_type' value={this.state.data.pts_type} onChange={this.handleChangePartnership}>
                <option value='business_plan'>Business Plan</option>
                <option value='feasibility_plan'>Feasibility Plan</option>
                <option value='canvas_plan'>Canvas Plan</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </Col>
        <Col xs={2}>
          <Form>
            <Form.Group>
              <Form.Label>Permission</Form.Label>
              <Form.Control as='select' name='pts_permission' value={this.state.data.pts_permission} onChange={this.handleChangePartnership}>
                <option value='Full'>Full</option>
                <option value='Demonstration'>Demonstration</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </Col>
        <Col xs={2}>
          <Form>
            <Form.Group>
              <Form.Label>TEMPLATE</Form.Label>
              <Form.Control as='select' name='pts_template' value={this.state.data.pts_template} onChange={this.handleChangePartnership}>
                <option value='casflo'>CASFLO</option>
                <option value='viseeon'>VISEEON</option>
                <option value='bazaar'>BAZAAR</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </Col>
        <Col xs={2}>
          <SingleInputIntegerForm
            label='Number of subs'
            value={this.state.data.pts_number_subs}
            name='pts_number_subs'
            handleinputeditChange={this.handleChangePartnershipFloat}/>
        </Col>
        <Col xs={2}>
          <SingleInputIntegerForm
            label='Number of colaborators'
            value={this.state.data.pts_number_of_colaborators}
            name='pts_number_of_colaborators'
            handleinputeditChange={this.handleChangePartnershipFloat}/>
        </Col>
        <Col xs={2}>
          <SingleInputIntegerForm
            label='Number of viewers'
            value={this.state.data.pts_number_of_viewers}
            name='pts_number_of_viewers'
            handleinputeditChange={this.handleChangePartnershipFloat}/>
        </Col>
        <Col xs={2}>
          <SingleInputIntegerForm
            label='Feasibility max versions'
            value={this.state.data.pts_number_of_versions}
            name='pts_number_of_versions'
            handleinputeditChange={this.handleChangePartnershipFloat}/>
        </Col>
        <Col xs={12} className='admin_al_sub_main_cols'>
          <h5>Pubscription setup changes</h5>
        </Col>
        <Col xs={2}>
        <SubsDateType
          label='Duration type'
          value={this.state.data.pts_duration_type}
          name='pts_duration_type'
          handleEditItemChange={this.handleChangePartnershipBool}/>
        </Col>
        {renderFixedDateOption(this.state.data.pts_duration_type)}
        <Col xs={2}>
          <SubsFormDate
          label='Validity'
          value={this.state.data.pts_validity}
          name='pts_validity'
          handleEditItemChange={this.handlePartnershipValidity}/>
        </Col>
      </Row>
      <Row>
        <Col xs={9}></Col>
        <Col xs={3}>
          <Button block size='sm' variant='success' onClick={this.handleCreatePartnership}>Give new Partnership pack</Button>
        </Col>
      </Row>
      </>
    )
  }
}

function SubsFormDate(props){
  let date=""
  const dateChecker=(input)=>{
    if(input===undefined){
      date=""
    } else {
      const dd=new Date(input)
      date=(dd.toISOString().substr(0,10))
    }
    return date
  }

  return(
    <>
    <Form>
      <Form.Group>
        <Form.Label>{props.label}</Form.Label>
        <Form.Control type='date' name={props.name} value={dateChecker(props.value)} onChange={props.handleEditItemChange}/>
      </Form.Group>
    </Form>
    </>
  )
}

function SubsDateType(props){
  return(
    <>
    <Form>
      <Form.Group>
        <Form.Label>{props.label}</Form.Label>
        <Form.Control name={props.name} property='Boolean' as='select' value={props.value} onChange={props.handleEditItemChange}>
          <option value={true}>Flex</option>
          <option value={false}>Fixed</option>
        </Form.Control>
      </Form.Group>
    </Form>
    </>
  )
}

export default AdminPartnerUsers;
