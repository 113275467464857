function utilityPestelRow(label,inputs,theme,f1,f2){
  const color_setup={
    "-3":"ff9800",
    "-2":"f9ac5d",
    "-1":"ecc199",
    "0":"d5d5d5",
    "1":"aac7b3",
    "2":"7eb992",
    "3":"4baa71",
  }
  const table_row=[]
  table_row[0]=[{text: label, options: { align: "center", fill:theme.cs.darkshade, fontSize:f1, color:theme.cs.lighttext }}]
  for(let i=1;i<(inputs.length+1);i++){
    table_row[i]=[{text: inputs[i-1].descr, options: { align: "center", fill:color_setup[inputs[i-1].impact], fontSize:f2}}]
  }
  return table_row
}

function utilityaddBulletText(input){
  const bulletizer=input.map(value =>{
    return {text: value,options:{breakLine:true}} 
  })
  return bulletizer
}

export {
  utilityPestelRow,
  utilityaddBulletText
}