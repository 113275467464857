import React, {useState} from 'react';
import { Grid, Row, Col } from 'Support/grid';
import {ComplexCogsCreator} from '../../../../CONFIG/CREATORS/revenue_creator';

import {FormEdit, NonEditableForm, PeriodHeader, SingleInputForm,SingleInputNumberForm, Observations, Dropdown} from 'Support/inputforms'
import {DeleteButton} from 'Support/listbuttons.js';
import { ToogleButtons,ToogleButtonsWithNotice } from 'Support/toogle_buttons';
import {AddItem} from 'Support/add_item';
import Loader from 'Support/loader';
import UserContext from 'CONFIG/auth';
import ApiConnect from 'CONFIG/axios';
import {calculateRevenues} from 'CALCULATIONS/Budget_calculations/cal_revenues';
import { AssumptionHeaderSaveReturn } from '../budget_items';


class RevenueEntry extends React.Component {
  static contextType = UserContext

  constructor(props) {
    super(props);
    this.state = {
      data: "",
      inflation:[1,1,1,1,1,1,1,1,1,1,1,1],
      seasonality:[],
      loaded:false
    }
    this.handleEditChange = this.handleEditChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleSimpleCogsChange = this.handleSimpleCogsChange.bind(this);
    this.handleObservationsChange = this.handleObservationsChange.bind(this);
    this.handlePeriodChange = this.handlePeriodChange.bind(this);
    this.handleCogsInputChange=this.handleCogsInputChange.bind(this);
    this.handleSimpleCogsChange=this.handleSimpleCogsChange.bind(this);
    this.handleAddComplexCog=this.handleAddComplexCog.bind(this);
    this.handleEditComplexCog=this.handleEditComplexCog.bind(this);
    this.handleDeleteComplexCog=this.handleDeleteComplexCog.bind(this);
    this.handleDaysOfStock=this.handleDaysOfStock.bind(this);
  }


  //GENERAL FUNCTIONS
  componentDidMount= async()=>{
    const request={
    budget_id:this.props.budget_id,
    version_id:this.props.current_version.id,
    item_id:this.props.active_item,
    item_type:"revenues"
    }
    try {
      const response = await ApiConnect.post('/api/budget_project/multitem/unique/get', request)
      this.setState({
        data:response.data.item,
        seasonality:response.data.seasonality,
        loaded:true
      })
    } catch(error) {
        alert(error);
    }
  }

  handleNameChange(event){
    const value = event.target.value;
    let go = this.state.data;
    go.rev_name=value;
    this.setState({data:go })
  }

  handleObservationsChange(event){
    const value = event.target.value;
    let go = this.state.data;
    go.rev_observations=value;
    this.setState({data:go })
  }

  async handleSave(){
    const request={
      budget_id:this.props.budget_id,
      version_id:this.props.current_version.id,
      item_id:this.props.active_item,
      item_type:"revenues",
      content:this.state.data
    }
    try{
      await ApiConnect.post('/api/budget_project/multitem/item/modify', request)
      this.props.changeScreenView("list",0)
    } catch(error) {
        alert(error);
    }
  }


  //REVENUES FUNCTIONS
  handleEditChange=(id, item)=>{
    let go = this.state.data;
    go[id]=item;
    calculateRevenues(go, this.props.budget_settings,this.state.inflation, this.state.seasonality)
    this.setState({data:go})
  }

  handleInputTypeChange=async(event)=>{
    const value = event.target.value;
    let go = this.state.data;
    go.rev_set_type=value;
    go.rev_quantity_type=1
    calculateRevenues(go, this.props.budget_settings,this.state.inflation, this.state.seasonality)
    this.setState({data:go})
  }

  handleChangeYearlyQuantity=(event)=>{
    const value = event.target.value;
    let go = this.state.data;
    go[event.target.name]=value;
    calculateRevenues(go, this.props.budget_settings,this.state.inflation, this.state.seasonality)
    this.setState({data:go})
  }

  handlePeriodChange=async(event)=>{
    const value = event.target.value;
    let go = this.state.data;
    go.rev_quantity_type=value;
    calculateRevenues(go, this.props.budget_settings,this.state.inflation, this.state.seasonality)
    this.setState({data:go})
  }

  handleCenterChange=async(event)=>{
    const value = event.target.value;
    let go = this.state.data;
    go.rev_center=value;
    this.setState({data:go})
  }


  //COGS
  handleCogsInputChange(val){
    let go = this.state.data
    go.rev_cogs.cogs_type=val
    calculateRevenues(go, this.props.budget_settings,this.state.inflation, this.state.seasonality)
    this.setState({data:go})
  }

  //SIMPLE COGS
  handleSimpleCogsChange=(id, item)=>{
    let go = this.state.data
    go.rev_cogs.s_cogs[id]=item;
    calculateRevenues(go, this.props.budget_settings,this.state.inflation, this.state.seasonality)
    this.setState({data:go})
  }


  //COMPLEX COGS
  //add a new complex cog
  handleAddComplexCog =(item)=>{
    let new_item=new ComplexCogsCreator(item, 12,this.props.budget_settings.vat)
    let go = this.state.data
    go.rev_cogs.c_cogs.push(new_item)
    this.setState({data:go})
  }
  //edit complex cog
  handleEditComplexCog=(id, item)=>{
    let go = this.state.data;
    go.rev_cogs.c_cogs[id].c_cogs_item_value=item;
    calculateRevenues(go, this.props.budget_settings,this.state.inflation, this.state.seasonality)
    this.setState({data:go})
  }

  //delete a complex cog
  handleDeleteComplexCog=async(event)=>{
    let index =event.target.value
    let go = this.state.data
    go.rev_cogs.c_cogs.splice(index,1)
    calculateRevenues(go, this.props.budget_settings,this.state.inflation, this.state.seasonality)
    this.setState({data:go})
  }


  //EXECUTE DAYS OF STOCK
  handleDaysOfStock=(id, item)=>{
    let go = this.state.data;
    go.rev_cogs[id]=item;
    calculateRevenues(go, this.props.budget_settings,this.state.inflation, this.state.seasonality)
    this.setState({data:go})
  }

  render() {
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_BUDGET.INSIDEPROJECT.Individual_edits')

    let p_base=['m1','m2','m3','m4','m5','m6','m7','m8','m9','m10','m11','m12']
    let periods=[]
    for(var i=0; i < p_base.length ;i++){
      periods[i]=trsl[p_base[i]]
    }

    const props ={
      trsl:trsl,
      handleScreenNavigation:this.props.handleScreenNavigation,
      budget_settings:this.props.budget_settings,
      user_permission:this.props.user_permission,
      data:this.state.data,
      periods:periods,
      handleNameChange:this.handleNameChange,
      handleObservationsChange:this.handleObservationsChange,
      handleSave:this.handleSave,
      handleEditChange:this.handleEditChange,
      handleInputTypeChange:this.handleInputTypeChange,
      handleChangeYearlyQuantity:this.handleChangeYearlyQuantity,
      handlePeriodChange:this.handlePeriodChange,
      handleCenterChange:this.handleCenterChange,
      handleCogsInputChange:this.handleCogsInputChange,
      handleSimpleCogsChange:this.handleSimpleCogsChange,
      handleAddComplexCog:this.handleAddComplexCog,
      handleEditComplexCog:this.handleEditComplexCog,
      handleDeleteComplexCog:this.handleDeleteComplexCog,
      handleDaysOfStock:this.handleDaysOfStock
    }

    function torender(data, i){
      return data[i];
    }  
    const pages ={
      true: <ToDisplay {...props}/>,
      false: <Loader loader_message="rev_edit"/>
    }
    var i = this.state.loaded
    const renderizing = torender(pages,i)

    return(
      <>
        {renderizing}
      </>
    )
  }
}

class ToDisplay extends React.Component {
  render(){
    const props=this.props

    return(
      <>
        <GlobalHeader {...props}/>
        <Row>
          <Rev1 {...props}/>
          <Col xs={12}>
            <CogsEntry {...props}/>
          </Col>
          <Col xs={12}>
            <StockAssumptions {...props}/>
          </Col>
            <Observations xs={12} name="rev_observations" value={this.props.data.rev_observations} handleObservationsChange={this.props.handleObservationsChange}/>
        </Row>
      </>
    );
  }
}

class GlobalHeader extends React.Component{
  render(){
    const props=this.props

    const options_item_holder = this.props.budget_settings.centers
    let options_item_distr=[{value:"",option_label:''}]
    if(options_item_holder !==undefined){
      options_item_holder.map(key =>{
        options_item_distr.push({value:key,option_label:key})
      })
    }

    return(
      <>
        <AssumptionHeaderSaveReturn {...props}/>
        <Row className="feasibility_edit_globalheader_row">
          <SingleInputForm
          xs={12} md={5}
          value={this.props.data.rev_name} 
          label= {this.props.trsl['rev_name']}
          placeholder={this.props.trsl['rev_name']}
          handleEditItemChange={this.props.handleNameChange}/>
          <Dropdown xs={12} md={7} label={this.props.trsl['center']} name="centers" value={this.props.data.rev_center} options={options_item_distr} onChange={this.props.handleCenterChange} />
        </Row>       
      </>
    );
  }
}

class Rev1 extends React.Component{
  render(){
    const props=this.props

    const rev_set_options=[
      {value:"default",option_label:this.props.trsl['rev_use_detail_price_quantities_per_month']},
      {value:"seasonality_qua",option_label:this.props.trsl['rev_use_yearly_quantity_seasonality']},
      {value:"fixed_qua",option_label:this.props.trsl["rev_use_yearly_quantity_evenly"]},
      {value:"monthly_rev",option_label:this.props.trsl["rev_use_monthly_revenue"]},
      {value:"seasonality_rev",option_label:this.props.trsl['rev_use_yearly_amount_seasonality']},
      {value:"fixed_rev",option_label:this.props.trsl["rev_use_yearly_amount_evenly"]}
    ]

    const quantitiy_period_options=[
      {value:30,option_label:this.props.trsl['daily']},
      {value:4.2,option_label:this.props.trsl['weekly']},
      {value:1,option_label:this.props.trsl['monthly']},
    ]

    let price_render=
      <>
        <PriceEdit {...props}/>
        <PriceWithInflation {...props}/>
        <VatEdit {...props} />
      </>
    let quantity_render=<QuantityEdit {...props}/>
    let quantity_period=<ToogleButtons xs= {12} md={12} label={this.props.trsl['rev_quantities_per']} toogle_data={quantitiy_period_options} handleToogleButton={this.props.handlePeriodChange} selected={this.props.data.rev_quantity_type}/>
    let revenue_render=<TotalRevenue {...props}/>
    let yearly_quantity=<></>
    let yearly_price=<></>

    if(this.props.data.rev_set_type==="monthly_rev"){
      quantity_render=<QuantityFakeRevenue {...props}/>
      revenue_render=<></>
      quantity_period=<></>
      price_render=<></>
    }
    else if(this.props.data.rev_set_type==="fixed_qua" ||this.props.data.rev_set_type==="seasonality_qua"){
      quantity_render=<></>
      quantity_period=<></>
      yearly_quantity=<SingleInputNumberForm xs={12} md={6} label={this.props.trsl['rev_quantity']} name='rev_quantity_yearly_total' value={this.props.data.rev_quantity_yearly_total} handleinputeditChange={this.props.handleChangeYearlyQuantity}/>
      yearly_price=<SingleInputNumberForm xs={12} md={6} label={this.props.trsl['rev_price']} name='rev_price_yearly' value={this.props.data.rev_price_yearly} handleinputeditChange={this.props.handleChangeYearlyQuantity}/>
      price_render=<></>
    }
    else if(this.props.data.rev_set_type==="fixed_rev" ||this.props.data.rev_set_type==="seasonality_rev"){
      quantity_render=<></>
      quantity_period=<></>
      yearly_quantity=<SingleInputNumberForm xs={12} md={6} label={this.props.trsl['rev_revenue']} name='rev_quantity_yearly_total' value={this.props.data.rev_quantity_yearly_total} handleinputeditChange={this.props.handleChangeYearlyQuantity}/>
      price_render=<></>
    }

    return(
      <>
      <ToogleButtonsWithNotice xs= {12} md={12} label={this.props.trsl['rev_input_style']} toogle_data={rev_set_options} notice={this.props.trsl['rev_style_notice']}handleToogleButton={this.props.handleInputTypeChange} selected={this.props.data.rev_set_type}/>
      {quantity_period}
      {yearly_quantity}
      {yearly_price}
      <Col xs={12}>
        <PeriodHeader label={this.props.trsl['period']} periods={this.props.periods}/>
        {quantity_render}
        {price_render}
        {revenue_render}
        <VatValue {...props}/>
      </Col>  
      </>
    )
  }
}

class PriceEdit extends React.Component{
  render(){
    return(
      <FormEdit
      use_historic={this.props.budget_settings.use_historic}
      item_holder={this.props.data.rev_price}
      id="rev_price"
      handleForm={this.props.handleEditChange}
      label={this.props.trsl['rev_price']}
      unit={new Intl.NumberFormat('ja-JP', { style: 'currency', currency: this.props.budget_settings.currency}).format(0).replace(/\d./g,"")}
      />
    );
  }
}

class PriceWithInflation extends React.Component{
  render(){ 
    return(
      <NonEditableForm
      s_value={this.props.s_value}
      item_holder={this.props.data._rev_price_inflated}
      label={this.props.trsl['rev_price_inflated']}
      value_type="currency"
      currency={this.props.budget_settings.currency}
      />
    );
  }
}


class QuantityEdit extends React.Component{
  render(){ 
    return(
      <FormEdit 
      use_historic={this.props.budget_settings.use_historic}
      item_holder={this.props.data.rev_quantity}
      id="rev_quantity"
      handleForm={this.props.handleEditChange}
      label={this.props.trsl['rev_quantity']}
      unit="U"
      />
    );
  }
}

class QuantityFakeRevenue extends React.Component{
  render(){ 
    return(
      <FormEdit 
      use_historic={this.props.budget_settings.use_historic}
      item_holder={this.props.data.rev_quantity}
      id="rev_quantity"
      handleForm={this.props.handleEditChange}
      label={this.props.trsl['rev_revenue']}
      unit={new Intl.NumberFormat('ja-JP', { style: 'currency', currency: this.props.budget_settings.currency}).format(0).replace(/\d./g,"")}
      />
    );
  }
}

class VatEdit extends React.Component{
  render(){
    const vatRendering=(input)=>{
      if(input===false){
        return(
          <FormEdit 
          use_historic={this.props.budget_settings.use_historic}
          item_holder={this.props.data.rev_vat_rate}
          id="rev_vat_rate"
          handleForm={this.props.handleEditChange}
          label={this.props.trsl['vat_rate']}
          unit="%"
          />
        )
      }
    }
    let renderizing=""
    renderizing=vatRendering(this.props.budget_settings.use_general_vat_rate)
    return(
      <>
        {renderizing}
      </>
    );
  }
}

class TotalRevenue extends React.Component{
  render(){ 
    return(
      <NonEditableForm
      item_holder={this.props.data._rev_total_revenue}
      label={this.props.trsl['rev_revenue']}
      value_type="currency"
      currency={this.props.budget_settings.currency}
      />
    );
  }
}

class VatValue extends React.Component{
  render(){ 
    return(
      <NonEditableForm
      item_holder={this.props.data._rev_total_vat}
      label={this.props.trsl['vat_total']}
      value_type="currency"
      currency={this.props.budget_settings.currency}
      />
    );
  }
}

//
/////COGS/////
//

class CogsEntry extends React.Component {
  render() {
    const props=this.props
    
    function torender(data, i){
      return data[i];
    }
    const pages ={
      true: <SimpleCogs {...props}/>,
      false: <ComplexCogs {...props}/>
    }
    var i = this.props.data.rev_cogs.cogs_type
    const renderizing = torender(pages,i)
    
    return(
        <Row className="feasibility_edits_break_row">
          <CogsHeader {...props} />
          <Col xs={12}>
            {renderizing}
          </Col>
          <Col xs={11} className="feasibility_list">
        </Col>
        <Col xs={1}> </Col>
        </Row>

    );
  }
}

class CogsHeader extends React.Component{
  handleCogsInputChange=(event)=>{
    if(event.target.value==="true"){this.props.handleCogsInputChange(true)}
    else{this.props.handleCogsInputChange(false)}
  }
  render(){
    const options=[
      {value:true, option_label:this.props.trsl["cogs_option1"]},
      {value:false, option_label:this.props.trsl["cogs_option2"]}
    ]
    return(
      <>          
        <Col xs={12}>
          <h4>{this.props.trsl['cogs_header']}</h4>
        </Col>
        <ToogleButtons xs={12} md={12} label={this.props.trsl['cogs_options']} toogle_data={options} selected={this.props.data.rev_cogs.cogs_type} handleToogleButton={this.handleCogsInputChange}/>
      </>
    );
  }
}

class SimpleCogs extends React.Component{
  render(){
    const props=this.props
    return(
      <>
      <Row>
        <Col xs={12} >
          <PeriodHeader label={this.props.trsl['period']} periods={this.props.periods}/>
        </Col>
        <Col xs={12} className="feasibility_list">
          <SimpleCogsPercentageEdit {...props}/>
          <SimpleCogsVatRate {...props}/>
          <SimpleCogsValue {...props} />
          <SimpleCogsVatValue {...props}/>
        </Col>
      </Row>
      </>
    );
  }
}

class SimpleCogsPercentageEdit extends React.Component{
  render(){
    return(
      <FormEdit 
      use_historic={this.props.budget_settings.use_historic}
      item_holder={this.props.data.rev_cogs.s_cogs.s_cogs_percentage} 
      id="s_cogs_percentage"
      handleForm={this.props.handleSimpleCogsChange}
      label={this.props.trsl['cogs_raw%']}
      unit="%"
      />
    );
  }
}

class SimpleCogsVatRate extends React.Component{
  render(){
    const vatRendering=(input)=>{
      if(input===false){
        return(
          <FormEdit 
          use_historic={this.props.budget_settings.use_historic}
          item_holder={this.props.data.rev_cogs.s_cogs.s_cogs_vat_rate} 
          id="s_cogs_vat_rate"
          handleForm={this.props.handleSimpleCogsChange}
          label={this.props.trsl['vat_rate']}
          unit="%"
          />
        )
      }
    }
    let renderizing=""
    renderizing=vatRendering(this.props.budget_settings.use_general_vat_rate)
    return(
      <>
        {renderizing}
      </>
    );
  }
}

class SimpleCogsValue extends React.Component{
  render(){ 
    return(
      <NonEditableForm
      item_holder={this.props.data.rev_cogs._cogs_total}
      label={this.props.trsl['cogs_complex_component_cost_inflated']}
      value_type="currency"
      currency={this.props.budget_settings.currency}
      />
    );
  }
}

class SimpleCogsVatValue extends React.Component{
  render(){ 
    return(
      <NonEditableForm
      item_holder={this.props.data.rev_cogs._cogs_vat_total}
      label={this.props.trsl['vat_total']}
      value_type="currency"
      currency={this.props.budget_settings.currency}
      />
    );
  }
}

function ComplexCogs (props){
  const [c_cogs_render, setRender] = useState("list");
  const [item, setItem] = useState("");
  const handleChange = (val) => {
    setItem(val)
    setRender("edit")
  }

  function torender(data, i){
    return data[i];
  }  
  const pages ={
    list: <ComplexCogsList {...props} handleChange={handleChange}/>,
    edit: <ComplexCogsEdit {...props}/>
  }
  var i = c_cogs_render
  const renderizing = torender(pages,i)

  return(
    <div>
      {renderizing}
    </div>
  )
}

class ComplexCogsList extends React.Component{
  render(){    
    const props=this.props
    const data = this.props.data.rev_cogs.c_cogs
    const data_distr = data.map((keys,value) =>
      <Row middle="xs" className="non_editable_list">
        <Col xs={11}>
          <FormEdit
          use_historic={this.props.budget_settings.use_historic}
          item_holder={keys.c_cogs_item_value}
          id={value}        
          handleForm={this.props.handleEditComplexCog}
          label={keys.c_cogs_item_name}
          unit={new Intl.NumberFormat('ja-JP', { style: 'currency', currency: this.props.budget_settings.currency}).format(0).replace(/\d./g,"")}
          />
        </Col>

        <Col xs={1}>
          <Row>
            <DeleteButton handleDelete={this.props.handleDeleteComplexCog} value={value}/>
          </Row>
        </Col>
      </Row>
    );
    return(
      <>
        <Row className="feasibility_revenues_add_item_cogs">       
          <Col xs={6}>
            <AddItem add_item={this.props.trsl["cogs_add_component"]} user_permission={this.props.user_permission} handleAdd={this.props.handleAddComplexCog} />
          </Col>
          <Col xs={11} >
            <PeriodHeader label={this.props.trsl['period']} periods={this.props.periods}/>
          </Col>
          <Col xs={1}></Col>
          <Col className="feasibility_list" xs={12}>
            {data_distr}
            
          </Col>
          <Col className="feasibility_list" xs={11}>
            <ComplexCogsVatRate {...props}/>
            <ComplexCogsValue {...props} />
            <ComplexCogsVatValue {...props}/>
          </Col>
        </Row>
      </>
    );
  }
}

class ComplexCogsEdit extends React.Component {
  render(){
    const props=this.props
    return(
      <Grid fluid className="feasibility_box"> 
        <Col xs={12} className="list_table_header">
          <PeriodHeader label={this.props.trsl['period']} periods={this.props.periods}/>
        </Col>
      </Grid>
    )
  }
}


class ComplexCogsVatRate extends React.Component{
  render(){
    const vatRendering=(input)=>{
      if(input===false){
        return(
          <FormEdit 
          use_historic={this.props.budget_settings.use_historic}
          item_holder={this.props.data.rev_cogs.s_cogs.s_cogs_vat_rate} 
          id="s_cogs_vat_rate"
          handleForm={this.props.handleSimpleCogsChange}
          label={this.props.trsl['vat_rate']}
          unit="%"
          />
        )
      }
    }
    let renderizing=""
    renderizing=vatRendering(this.props.budget_settings.use_general_vat_rate)
    return(
      <>
        {renderizing}
      </>
    );
  }
}


class ComplexCogsValue extends React.Component{
  render(){ 
    return(
      <NonEditableForm
      item_holder={this.props.data.rev_cogs._cogs_total}
      label={this.props.trsl['cogs_complex_component_cost_inflated']}
      value_type="currency"
      currency={this.props.budget_settings.currency}
      />
    );
  }
}

class ComplexCogsVatValue extends React.Component{
  render(){ 
    return(
      <NonEditableForm
      item_holder={this.props.data.rev_cogs._cogs_vat_total}
      label={this.props.trsl['vat_total']}
      value_type="currency"
      currency={this.props.budget_settings.currency}
      />
    );
  }
}


class StockAssumptions extends React.Component{
  render(){
    const props=this.props

    return(
      <>
        <Col sm={12} className="feasibility_edits_break_row">
          <h4>{this.props.trsl['stock_header']}</h4>
        </Col>
        <Row >
          <Col xs={12} >
            <PeriodHeader label={this.props.trsl['period']} periods={this.props.periods}/>
          </Col>
          <Col xs={12} >
            <DaysOfStock {...props} />
            <Inventory {...props} />
          </Col>
        </Row>
      </>
    )
  }
}

class DaysOfStock extends React.Component{
  render(){
    return(
      <FormEdit 
      use_historic={this.props.budget_settings.use_historic}
      item_holder={this.props.data.rev_cogs.days_of_stock} 
      id="days_of_stock"
      handleForm={this.props.handleDaysOfStock}
      label={this.props.trsl['stock_days']}
      unit="D"
      />
    );
  }
}

class Inventory extends React.Component{
  render(){ 
    return(
      <NonEditableForm
      item_holder={this.props.data.rev_cogs._inventory}
      label={this.props.trsl['stock_total']}
      value_type="currency"
      currency={this.props.budget_settings.currency}
      />
    );
  }
}


export default RevenueEntry;