class ShareholdersCreator{
  constructor(name, array_size, typester,hist){
    this.shareholders_item_name=name
    this.shareholders_type=typester
    this.shareholders_increase=new Array(array_size).fill(0)
    this.shareholders_decrease=new Array(array_size).fill(0)
    this.shareholders_premium_option=false
    this.shareholders_share_premium=new Array(array_size).fill(0)
    this._shareholders_share_premium_rate=new Array(array_size).fill(0)
    this._shareholders_share_premium_accumulated=new Array(array_size).fill(0)
    this._shareholders_total=new Array(array_size).fill(0)
    this.shareholders_loan_interest_rate=0
    this._shareholders_loan_interest=new Array(array_size).fill(0)
    this._rev_hist=hist
    this.shareholders_obs=""
  }
}

export default ShareholdersCreator;