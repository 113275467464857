import React from 'react'
import {Row} from 'Support/grid'

import './CSS/support.css'

class Navigation extends React.Component{
  handleNavigation=(event)=>{
    const selected=event.target.name
    this.props.handleSelect(selected)
  }
  
  render(){
    const childrenWithProps = React.Children.map(this.props.children, child => {
      // checking isValidElement is the safe way and avoids a typescript error too
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { 
          handleNavigation: this.handleNavigation,
          active_key:this.props.active_key
        });
      }
      return child;
    });

    function checkForTitle(i1){
      if(i1!==undefined){
        return(
          <h4 className='support_navigation_title'>{i1}</h4>
        )
      }
    }

    return(
      <Row className='support_navigation_main'>
        {checkForTitle(this.props.nav_title)}
        {childrenWithProps}
      </Row>
    )
  }
}

Navigation.Item=NavigationItem
Navigation.NavigationMega=NavigationMega

function NavigationItem(props){
  const goNav=()=>{
    const event={
      target:{
        name:props.name
      }
    }
    props.handleNavigation(event)
  }

  if(props.active_key===props.name){
    return(
      <div className='support_navigation_item_selected' onClick={goNav}>
        {props.children}
      </div>
    )
  } else{
    return(
      <div className='support_navigation_item_unselected' onClick={goNav}>
        {props.children}
      </div>
    )
  }
}


function NavigationMega(props){
  return(
    <div className='support_navigation_mega'>
      <h6>{props.children}</h6>
    </div>
  )
}


///////////////////////
////////HORIZONTAL////
/////////////////////

function HorizontalBarNavigation(props){
  const handleNavigation=(event)=>{
    const selected=event.target.name
    props.handleSelect(selected)
  }

  const childrenWithProps = React.Children.map(props.children, child => {
    // checking isValidElement is the safe way and avoids a typescript error too
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { 
        handleNavigation: handleNavigation,
        active_key:props.active_key
      });
    }
    return child;
  });

  return(
    <Row className='spt_hori_navigation'>
      {childrenWithProps}
    </Row>
  )
}

HorizontalBarNavigation.Item=HorizontalBarNavigationItem

function HorizontalBarNavigationItem(props){
  const goNav=()=>{
    const event={
      target:{
        name:props.name
      }
    }
    props.handleNavigation(event)
  }

  if(props.active_key===props.name){
    return(
      <div className='spt_hori_navigation_selected' onClick={goNav}>
        {props.children}
      </div>
    )
  } else{
    return(
      <div className='spt_hori_navigation_unselected' onClick={goNav}>
        {props.children}
      </div>
    )
  }
}

export {
  Navigation,
  HorizontalBarNavigation
}