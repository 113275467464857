import React from 'react';

import {Grid, Col, Row} from 'Support/grid'
import {RegularButtonSmall} from 'Support/listbuttons';
import { DetailedCard } from 'Support/warning_cards';
import Loader from 'Support/loader';

import ApiConnect from 'CONFIG/axios';
import Session from 'CONFIG/session';
import UserContext from 'CONFIG/auth';

class ShowTemplates extends React.Component{
  static contextType = UserContext

  constructor(props) {
    super(props);
    this.state = {
      data: "",
      loaded:false
    }
  }

  componentDidMount() {
    this.updateSettingsData()
  }

  updateSettingsData=async()=>{
    const request={
    }
    try{
      const response = await ApiConnect.post('/api/budget_project/template/list/', request)
      this.setState({
        data:response.data,
        loaded:true
      })
    }catch(error) {
      alert(error);
    }
  }

  handleGoToTemplateProject=(event)=>{
    const name= event.target.name
    Session.setBudget(event.target.value)
    this.props.handlePrincipalRouting(name)
  }

  render(){
    const props={
      data:this.state.data,
      handleGoToBudget:this.props.handleGoToBudget
    }
    function torender(data, i){
      return data[i];
      }  
    const pages ={
    true: <ToDisplay {...props} />,
    false: <LoaderWraper/>
    }
    const renderizing = torender(pages,this.state.loaded)
    return(
      <>
        {renderizing}
      </>
    )
  }
}

class ToDisplay extends React.Component{
  static contextType = UserContext

  render(){
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE.Templates')

    const item_holder=this.props.data
    const item_distr=item_holder.map((value)=>{
      return(
        <Col xs={6} md={4} className='blanktop_15'>
          <DetailedCard color='darkshade' className='main_screen_template_scard' >
            <DetailedCard.Header color='darkshade'>
              <h6>{value.budget_name}</h6>
            </DetailedCard.Header>
            <DetailedCard.Body>
              {value.budget_description}
              <RegularButtonSmall bk='bk_primary' name='main' value={value.budget_id} onClick={this.props.handleGoToBudget} label={trsl['view']}/> 
            </DetailedCard.Body>
          </DetailedCard>
        </Col>  
      )
    })

    return(
      <Grid>
        <Row className="colaborate_view_main_placer">
          <Col xs={12} md={12}>
              <h1>{trsl['templates']}</h1>
            </Col>
            <Col xs={12} md={12} className='blanktop_10'>
              {trsl['template_info']}
            </Col>
            {item_distr}
        </Row>
      </Grid>
    )
  }
}

function LoaderWraper (props){
  return(
    <Grid fluid>
      <Loader loader_message="templates_view"/>
    </Grid>
  )
}

export default ShowTemplates