const {dotMultiply, add,dotDivide,multiply, sum, pow, abs,max, subtract} = require('mathjs');

function addArrays(inputer){
  var summed=new Array(inputer[0].length)
  summed.fill(0)
  inputer.forEach(function (item){
    summed=add(summed,item)
  })
  return summed
}

function accumulateOneArray(inputer){
  let bug=[...inputer]
  for(var i=1;i<bug.length;i++){
    bug[i]=bug[i]+bug[i-1]
  }
  return bug
}

function unNan(input){
  let ad=input
  if(input===undefined){
    ad=0
  }
  return ad
}

function CorrectArray(input) {
  let arr=input
  for(var i=0;i<arr.length;i++){
    if(!Number.isFinite(arr[i])){
      arr[i]=0
    }
  }
  return arr
}

function removeFirstElementFromArray(element){
  let arr=[]
  arr= element.slice(1)
  return arr
}

//INCOME STATEMENT FUNCTIONS
function calRevenue(item,zero){
  let bug=[...zero]
  if(item.revenues.length>0){
      bug=addArrays(item.revenues.map((value)=>value._rev_total_revenue))
  }
  return bug
}

function calCogs(item,zero){
  let bug=[...zero]
  if(item.revenues.length>0){
    bug=addArrays(item.revenues.map((value)=> value.rev_cogs._cogs_total))
  }
  return bug
}

const calOperationalGrants=(item,zero)=>{
    let bug=[...zero]
    let centopeid=item.other_gains_cost
    let dune_buggy=centopeid.filter(centopeid => centopeid.other_gains_cost_type === "operational_subsidy")
    if(dune_buggy.length>0){
        bug=addArrays(dune_buggy.map((value)=>value.other_gains_cost_value))
    }
    return bug
}

const calOperatingExpenses=(item,zero)=>{
    let bug=[...zero]
    if(item.operating_expenses.length>0){
        bug=addArrays(item.operating_expenses.map((value)=>value._op_exp_total))
    }
    return bug
}
const calHr=(item,zero)=>{
  let bug=[...zero]
  if(item.human_resources.length>0){
      bug=addArrays(item.human_resources.map((value)=>value._hr_total_cost))
  }
  return bug
}

const calOtherGains=(item,zero)=>{
    let bug=[...zero]
    let centopeid=item.other_gains_cost
    let dune_buggy=centopeid.filter(centopeid => centopeid.other_gains_cost_type === "other_gain")
    if(dune_buggy.length>0){
        bug=addArrays(dune_buggy.map((value)=>value.other_gains_cost_value))
    }
    return bug
}


const calOtherCosts=(item,zero)=>{
    let bug=[...zero]
    let centopeid=item.other_gains_cost
    let dune_buggy=centopeid.filter(centopeid => centopeid.other_gains_cost_type === "other_cost")
    if(dune_buggy.length>0){
        bug=addArrays(dune_buggy.map((value)=>value.other_gains_cost_value))
    }
    return bug
}

const cal_depreciation=(item,zero)=>{
    let bug=[...zero]
    if(item.investments.length>0){
        bug=addArrays(item.investments.map((value)=>value._inv_depreciation))
    }
    return bug
}

const cal_interest_cost=(item,zero)=>{
  let bug=[...zero]
  let bug1=[...zero]
  let bug2=[...zero]
  if(item.debt.length>0){
    bug1=addArrays(item.debt.map((value)=>value._debt_interest))
  }
  if(item.shareholders.length>0){
    bug2=addArrays(item.shareholders.map((value)=>{
      if(value.shareholders_type=="shareholders_loan"){
        return value._shareholders_loan_interest
      } else{
        return [...zero]
      }
    }))
  }
  bug=add(bug1,bug2)
  return bug
}

const cal_corporate_tax=(item,zero,settings)=>{
  let bug=[...zero]
  let bug1=[...zero]
  let bug2=[...zero]
  let ebt=item.results._inc_st.ebt
  let revenue=item.results._inc_st.revenue
  let irc=''
  const gross_revenue_tax=settings.gross_revenue_tax
  if(item.version_settings.use_general_irc===true){
    irc=new Array(bug.length).fill(settings.irc)
  } else {
    irc=item.version_settings.version_corporate_tax_rate
  }
  for (var i=0;i<bug1.length;i++){
    bug1[i]=max(irc[i]/100*ebt[i],0)
  }
  bug2=dotMultiply(dotDivide(gross_revenue_tax,100),revenue)
  bug=add(bug1,bug2)
  return bug
}

//GOV FUNCTION
function calVatSales(item,zero){
  let bug=[...zero]
  if(item.revenues.length>0){
      bug=addArrays(item.revenues.map((value)=>value._rev_total_vat))
  }
  return bug
}

function calVatCogs(item,zero){
  let bug=[...zero]
  if(item.revenues.length>0){
    bug=addArrays(item.revenues.map((value)=>value.rev_cogs._cogs_vat_total))
  }
  return bug
}

function calVatOpExp(item,zero){
  let bug=[...zero]
  if(item.operating_expenses.length>0){
      bug=addArrays(item.operating_expenses.map((value)=>value._op_exp_vat_total))
  }
  return bug
}

function calVATInvestment(item,zero){
  let bug=[...zero]
  if(item.investments.length>0){
      bug=addArrays(item.investments.map((value)=>value._inv_vat))
  }
  return bug
}

function calVATReceivables(item,zero, project_settings){
  let vat_balance=item.results.gov.vat_balance
  let vat_pay_period=project_settings.vat_payment
  let bug=[...zero]
  for(var i=0;i<vat_balance.length;i++){
    if(vat_balance[i]<0){
      bug[i]=vat_balance[i]*vat_pay_period/365*-1
    }else {
      bug[i]=0
    }
  }
  return bug
}

function calVATPayables(item, zero,project_settings){
  let vat_balance=item.results.gov.vat_balance
  let vat_pay_period=project_settings.vat_payment
  let bug=[...zero]
  for(var i=0;i<vat_balance.length;i++){
    if(vat_balance[i]>=0){
      bug[i]=vat_balance[i]*vat_pay_period/365
    }else {
      bug[i]=0
    }
  }
  return bug
}

function calVATCashFlow(item,zero){
  let bug=[...zero]
  let it=item.results.gov
  for(var i=0;i<it.vat_balance.length;i++){
    if(i===0){
      bug[i]=it.vat_balance[i]+it.vat_receivable[i]-it.vat_payable[i]
    } else{
      bug[i]=it.vat_balance[i]+it.vat_receivable[i]-it.vat_receivable[i-1]-it.vat_payable[i]+it.vat_payable[i-1]
    }
  }
  return bug
}

//CF FUNCTIONS
const calClients=(item,zero)=>{
  let buggy=[...zero]
  let receivables=item.version_settings.days_accounts_receivables
  if(item.revenues.length>0){
      buggy=addArrays(item.revenues.map((value)=>value._rev_receivable))
  }
  return (dotDivide(dotMultiply(buggy,receivables),365))
}

function calPayables(item,zero){
  let buggy=[...zero]
  let dune_buggy=[...zero]
  if(item.revenues.length>0){
    buggy=addArrays(item.revenues.map((value)=>value.rev_cogs._cogs_payable))
  }
  if(item.operating_expenses.length>0){
    dune_buggy=addArrays(item.operating_expenses.map((value)=>value._op_exp_payable))
  }

  return add(buggy, dune_buggy)
}

function calSuppliers(item){
  let buggy=item.results.cf.payables
  let payables=item.version_settings.days_accounts_payables
  return (dotDivide(dotMultiply(buggy,payables),365))
}

function calInvestmentSuppliers(item,zero){
  let bug=[...zero]
  let dune_buggy=[]//total expenses with vat
  let payables=item.version_settings.investment_days_accounts_payables
  if(item.investments.length>0){
    dune_buggy=addArrays(item.investments.map((value)=>value._inv_receivable))
    bug=(dotDivide(dotMultiply(dune_buggy,payables),365))
  }
  return bug
}

function calReceivedFromClients(item,zero){
  let bug=[...zero]
  for(var i=0;i<item.results._inc_st.revenue.length;i++){
    if(i===0){
      bug[i]=item.results._inc_st.revenue[i]+ item.results.gov.vat_sales[i]-item.results.cf.clients_in_debt[i]
    } else {
      bug[i]=item.results._inc_st.revenue[i]+ item.results.gov.vat_sales[i]-item.results.cf.clients_in_debt[i]+item.results.cf.clients_in_debt[i-1]
    }
  }
  return bug  
}

function calPaidToSuppliers(item,zero){
  let bug=[...zero]
  for(var i=0;i<item.results._inc_st.revenue.length;i++){
    if(i===0){
      bug[i]=item.results.cf.payables[i]-item.results.cf.debt_to_suppliers[i]
    } else {
      bug[i]=item.results.cf.payables[i]-item.results.cf.debt_to_suppliers[i]+item.results.cf.debt_to_suppliers[i-1]
    }
  }
  return bug  
}

function calPaidToHr(item,zero){
  let bug=[...zero]
  if(item.human_resources.length>0){
      bug=addArrays(item.human_resources.map((value)=>value._hr_total_cost))
  }
  return bug
}

function calCorporateTaxCashFlow(item,zero){
  let bug=[...zero]
  for(var i=1;i<bug.length;i++){
    bug[i]=item.results._inc_st.corporate_tax[i-1]*-1
  }
  return bug
}

function calOtherGainsReceivables(item,zero){
  let bug=[...zero]
  let bug1=[...zero]
  let bug2=[...zero]
  let centopeid=item.other_gains_cost
  let dune_buggy=centopeid.filter(element => element.other_gains_cost_type === "other_gain")
  if(dune_buggy !== undefined &&dune_buggy.length>0){
    bug1=addArrays(dune_buggy.map((value)=>value.other_gains_cost_value))
  }
  let centopeid1=item.other_gains_cost
  let dune_buggy1=centopeid1.filter(element => element.other_gains_cost_type === "operational_subsidy")
  if(dune_buggy1 !== undefined &&dune_buggy1.length>0){
    bug2=addArrays(dune_buggy1.map((value)=>value.other_gains_cost_value))
  }
  bug=add(bug1,bug2)
  return bug
}
function calOtherCostsPayables(item,zero){
  let bug=[...zero]
  let centopeid=item.other_gains_cost
  let dune_buggy=centopeid.filter(element => element.other_gains_cost_type === "other_cost")
  if(dune_buggy!==undefined && dune_buggy.length>0){
    bug=addArrays(dune_buggy.map((value)=>value.other_gains_cost_value))
  }
  return bug
}

function calPaymentOfFixedAssets(item,zero){
  let bug=[...zero]
  let buggy=[]
  if(item.investments.length>0){
    buggy=addArrays(item.investments.map((value)=>value._inv_receivable))
    for(var i=0;i<bug.length;i++){
      if(i===0){
        bug[i]=buggy[i]-item.results.cf.debt_to_investment_suppliers[i]
      } else{
        bug[i]=buggy[i]-item.results.cf.debt_to_investment_suppliers[i]+item.results.cf.debt_to_investment_suppliers[i-1]
      }
    }
  }
  return bug
}

function callReceivedSharePremium(item,zero){
  let bug=[...zero]
  let centopeid=item.shareholders
  let dune_buggy=centopeid.filter(centopeid => centopeid.shareholders_type === "issued_capital")
  if(dune_buggy!==undefined && dune_buggy.length>0){
      bug=addArrays(dune_buggy.map((value)=>value.shareholders_share_premium))
  }
  return bug
}

function calCashFlowsOfNonCurrentAssetsLiabilities(item,zero){
  let bug=[...zero]
  let centopeid=item.other_balance
  let dune_buggy_assets=centopeid.filter(centopeids => (centopeids.other_balance_type === "non_current_assets" ||centopeids.other_balance_type === "current_assets"))
  let assets_bug=[...zero]
  if(dune_buggy_assets.length>0){
    const bugster=addArrays(dune_buggy_assets.map((value)=> value.other_balance_increase))
    const bugster2=addArrays(dune_buggy_assets.map((value)=> value.other_balance_decrease))
    for(var i=0;i<assets_bug.length;i++){
      assets_bug[i]=-bugster[i]+bugster2[i]
    }
  }
  let dune_buggy_liabilities=centopeid.filter(centopeids => (centopeids.other_balance_type === "non_current_liabilities" ||centopeids.other_balance_type === "current_liabilities"))
  let liabilities_bug=[...zero]
  if(dune_buggy_liabilities.length>0){
    const bugster_l=addArrays(dune_buggy_liabilities.map((value)=> value.other_balance_increase))
    const bugster2_l=addArrays(dune_buggy_liabilities.map((value)=> value.other_balance_decrease))
    for(var i=0;i<liabilities_bug.length;i++){
      liabilities_bug[i]=+bugster_l[i]-bugster2_l[i]
    }
  }
  bug=add(assets_bug,liabilities_bug)
  return bug
}

function calDebtIncrease(item,zero){
  let bug=item.results.cf.debt_increase
  let dune_buggy=[...zero]
  let centopeid=[...zero]
  if(item.debt.length>0){
    dune_buggy=addArrays(item.debt.map((value)=>value._debt_disbursement))
  }
  if(item.shareholders.length>0){
    centopeid=addArrays(item.shareholders.map((value)=>{
      if(value.shareholders_type=="shareholders_loan"){
        return value.shareholders_increase
      } else{
        return [...zero]
      }
    }))
  }
  bug= add(dune_buggy,centopeid)
  return bug
}

function calEquityAndEquivalentIncrease(item,zero){
  let bug=[...zero]
  if(item.shareholders.length>0){
    bug=addArrays(item.shareholders.map((value)=>{
      if(value.shareholders_type!="shareholders_loan"){
        return(
        value.shareholders_increase)
      } else{
        return [...zero]
      }
    }))
  }
  return bug
} 

function calDebtDecrease(item,zero){
  let bug=item.results.cf.debt_increase
  let dune_buggy=[...zero]
  let centopeid=[...zero]
  if(item.debt.length>0){
    dune_buggy=addArrays(item.debt.map((value)=>value._debt_reimbursement))
  }
  if(item.shareholders.length>0){
    centopeid=addArrays(item.shareholders.map((value)=>{
      if(value.shareholders_type=="shareholders_loan"){
        return(
        value.shareholders_decrease)
      } else {
        return [...zero]
      }
    }))
  }
  bug= add(dune_buggy,centopeid)
  return bug
}

function calEquityAndEquivalentDecrease(item,zero){
  let bug=[...zero]
  if(item.shareholders.length>0){
    bug=addArrays(item.shareholders.map((value)=>{
      if(value.shareholders_type!="shareholders_loan"){
        return(
        value.shareholders_decrease)
      } else{
        return [...zero]
      }
    }))
  }
  return bug
} 

function PaidDividends(item,zero){
  let bug=[...zero]
  if(item.version_settings.use_dividends==='payout'){
    for (var i=0;i<item.version_settings.dividends_payout_ratio.length;i++){
      if(i===0){
        bug[i]=0
      } else {
        if(item.results._inc_st.net_profit[i-1]>0){
          bug[i]=item.version_settings.dividends_payout_ratio[i] * item.results._inc_st.net_profit[i-1] / 100
        }
      }
    }
  }
  return bug
}

function calInitialCashFlows(item,zero){
  let bug=[...zero]
  for(var i=0;i<bug.length;i++){
    if(i===0){
      bug[i]=0
    } else{
      bug[i]=bug[i-1]+item.results.cf.cash_flow_variation[i-1]
    }
  }
  return bug
}

function calYearEndCashFlows(item,zero){
  let bug=[...zero]
  for(var i=0;i<bug.length;i++){
    bug[i]=item.results.cf.initial_cash_flows[i]+item.results.cf.cash_flow_variation[i]
  }
  return bug
}


//BALANCE FUNCTIONS
function calAssets(item,zero,slicer){
  let bug=[...zero]
  let centopeid=item.investments
  let dune_buggy=centopeid.filter(centopeid => centopeid.inv_type == slicer)
  if(dune_buggy.length>0){
      bug=addArrays(dune_buggy.map((value)=>value._inv_net_value))
  }
  
  return bug
}

const cal_other_balance=(item,zero,slicer)=>{
  let bug=[...zero]
  let centopeid=item.other_balance
  let dune_buggy=centopeid.filter(centopeid => centopeid.other_balance_type == slicer)
  if(dune_buggy.length>0){
      bug=addArrays(dune_buggy.map((value)=>value._other_balance_accumulated))
  }
  return bug
}

const cal_inventory=(item,zero)=>{
  let bug=[...zero]
  if(item.revenues.length>0){
      bug=addArrays(item.revenues.map((value)=>value.rev_cogs._inventory))
  }
  return bug
}

const calShareholders=(item,slicer,zero)=>{
  let bug=[...zero]
  let centopeid=item.shareholders
  
  let dune_buggy=centopeid.filter(centopeid => centopeid.shareholders_type === slicer)
  if(dune_buggy.length>0){
      bug=addArrays(dune_buggy.map((value)=>value._shareholders_total))
  }
  return bug
}

const callSharePremium=(item,zero)=>{
  let bug=[...zero]
  let centopeid=item.shareholders
  let dune_buggy=centopeid.filter(centopeid => centopeid.shareholders_type === "issued_capital")
  if(dune_buggy.length>0){
      bug=addArrays(dune_buggy.map((value)=>value._shareholders_share_premium_accumulated))
  }
  return bug
}


function cal_past_results(item,zero){
  let bug=item.results._inc_st.net_profit
  let buggy=[...zero]
  for(var i=0; i<buggy.length;i++){
    if(i===0){
      buggy[i]= item.version_historic_data.h_past_results
    } else{
      buggy[i] = bug[i-1]+buggy[i-1]
    }
  }
  let acumulated_dividends = accumulateOneArray(item.results.cf.paid_dividends)
  buggy = subtract(buggy, acumulated_dividends)
  return buggy
}

const cal_debt=(item,zero)=>{
  let bug=[...zero]
  if(item.debt.length>0){
      bug=addArrays(item.debt.map((value)=>value._debt_final_debt))
  }
  return bug
}

//WORKING CAPITAL
const calWcVariation=(item,zero)=>{
  let bug=[...zero]
  for(var i=0;i<item.length;i++){
    if(i===0){
      bug[i]=item[i]
    } else{
      bug[i]=item[i]-item[i-1]
    }
  }
  return bug
}

//VALUATION FUNCTIONS
function calOperationalCorporateTax(item,zero,settings){
  let bug=[...zero]
  let irc=''
  if(item.version_settings.use_general_irc===true){
    irc=new Array(bug.length).fill(settings.irc)
  } else {
    irc=item.version_settings.version_corporate_tax_rate
  }
  for( let i = 0; i< bug.length; i++){
    bug[i]=max(item.results.fe.ebit[i]*irc[i]/100,0)
  }
  return bug
}

function calFixedAssetInvestment(item,zero){
  let bug=[...zero]
  if(item.investments.length>0){
    bug=addArrays(item.investments.map((value)=>{
      if(value._inv_net_acquisition.length>0){
        return value._inv_net_acquisition
      } else {return value._inv_acquisition}
    }))
  }
  return bug
}

function calDiscountFCFf(item,zero,fcff_type,factor){
  const fcf_options={fcff: item.results.fe.fcff, fcfe: item.results.fe.fcfe}
  const fcf =fcf_options[fcff_type]

  const factor_calculator_options={ wacc:item.coc._coc_yearly_discount_factor, re:item.coc._coc_yearly_equity_discount_factor}
  const factor_calculator=factor_calculator_options[factor]

  let bug=[...zero]

  bug=dotMultiply(fcf,factor_calculator)
  return bug
}

function calNormalizedFCF(item){
  let normalized_fcff=0
  if(item.coc.coc_residual_value_type==="limited" || item.coc.coc_residual_value_type==="perpetuity"){
    const count=item.results.fe.fcff.length-1
    const fcff= item.results.fe.fcff[count]
    const inv= item.results.fe.fixed_asset_investment[count]
    const depre=item.results._inc_st.depreciations[count]
    normalized_fcff= (fcff+inv-depre)*(1+(item.coc.coc_residual_value_growth/100))
  }
  return normalized_fcff
}

function calNormalizedFCE(item){
  let normalized_fcfe=0
  if(item.coc.coc_residual_value_type==="limited" || item.coc.coc_residual_value_type==="perpetuity"){
    const count=item.results.fe.fcfe.length-1
    const fcfe= item.results.fe.fcfe[count]
    normalized_fcfe= (fcfe)*(1+(item.coc.coc_residual_value_growth/100))
  }
  return normalized_fcfe
}

function calPerpetuity(item, fcff_type, factor_type){
  const fcff_normalized=
    {fcff:item.results.fe.fcff_normalized, fcfe:item.results.fe.fcfe_normalized}
  const fcf =fcff_normalized[fcff_type]

  const perpetuity_calculator_options={ wacc:item.coc._coc_perpetuity_calculator, re:item.coc._coc_perpetuity_equity_calculator}
  const perpetuity_calculator=perpetuity_calculator_options[factor_type]

  let perpetuity=0
  if(item.coc.coc_residual_value_type==="perpetuity"){
    perpetuity= (fcf/perpetuity_calculator)
  } else if(item.coc.coc_residual_value_type==="limited"){
    const normalized_fcf=fcf
    const growth=item.coc.coc_residual_value_growth/100
    const rate=perpetuity_calculator
    const year_restricted=item.coc.coc_residual_value_years
    const perpetuity1=(normalized_fcf/rate)
    const prepertuity_2=normalized_fcf*pow((1+growth),year_restricted)
    const perpetuity2=(prepertuity_2/(rate))*(1/pow((1+rate+growth),year_restricted))
    perpetuity=perpetuity1-perpetuity2
  } else if(item.coc.coc_residual_value_type==="user_input"){
    perpetuity=unNan(item.coc.coc_resuidual_value_user_input)
  }
  return perpetuity
}
function calNPV(item){
  let bug=0
  const count=item.length
  bug=item[count-1]
  return bug
}

function calIRR(item,fcf_type,factor_type,npv_type){
  const fcf_options={fcff: item.results.fe.fcff, fcfe: item.results.fe.fcfe}
  const fcf =fcf_options[fcf_type]

  const fcf_nomralized_options={fcff: item.results.fe.fcff_normalized, fcfe: item.results.fe.fcfe_normalized}
  const normalized_fcf =fcf_nomralized_options[fcf_type]

  const factor_calculator_options={ wacc:item.coc._coc_wacc, re:item.coc._coc_equity_cost}
  const factor_calculator=factor_calculator_options[factor_type]


  function calDiscountFactor(discount, years){
    let bug=[]
    for(var i=0;i<years;i++){
      bug[i]=(1/(pow((1+(discount/100)),(i+1))))
    }
    return bug
  }
  
  
  const number_of_years=fcf.length
  let dcf_fcf=[]
  let previous_npv=item.results[npv_type].npv
  let previous_guess=(factor_calculator-1)
  let previous_guess_variation=1
  let guess_size=2
  let new_guess
  let npv
  let new_guess_variation=1
  let distance_variation=1
  let discount_factor=[]

  let growth=item.coc.coc_residual_value_growth
  let perpetuity=0
  let discounted_perpetuity=0
  let year_restricted=item.coc.coc_residual_value_years
  let perpetuity_2

  let iteration_max=100
  let iteration=0

  //Analyse the need for iteration
  let positive= false
  let negative = false
  for (var i = 0; i < fcf.length; i++) {
    if (fcf[i] > 0) positive = true;
    if (fcf[i] < 0) negative = true;
  }

  let proceed_computation = getIRR(item,fcf_type)

  if(proceed_computation === true){
    if(item.coc.coc_residual_value_type==="none"){
      do{
        //compute new npv
        discount_factor=calDiscountFactor(previous_guess,number_of_years)
        dcf_fcf=dotMultiply(fcf,discount_factor)
        npv=sum(dcf_fcf)

        //distance
        distance_variation=npv/previous_npv-1
        new_guess_variation=previous_guess_variation/(-distance_variation)
        new_guess=previous_guess+new_guess_variation-previous_guess_variation

        //reset values
        previous_guess_variation=new_guess-previous_guess
        previous_npv=npv
        previous_guess=new_guess
      }
      while(abs(npv)>0.5 && (++iteration <iteration_max))
    } else if(item.coc.coc_residual_value_type==="perpetuity"){
      do{
        
        //set new guess
        if(previous_npv<0){
          new_guess=previous_guess-guess_size
        } else if(previous_npv>0){
          new_guess=previous_guess+guess_size
        }

        //compute new npv
        discount_factor=calDiscountFactor(new_guess,number_of_years)
        dcf_fcf=dotMultiply(fcf,discount_factor)
        perpetuity=normalized_fcf/((new_guess-growth)/100)
        discounted_perpetuity=multiply(perpetuity, (1/(pow((1+(new_guess/100)),number_of_years))))
        npv=sum(dcf_fcf)+discounted_perpetuity

        //Decide wether to change guess size.
        if((npv<0 &&previous_npv>0)||(npv>0 &&previous_npv<0)){
          guess_size=guess_size/2
        }

        //reset guess and npv
        previous_guess=new_guess
        previous_npv=npv
      }
      while(abs(npv)>0.05 && (guess_size>0.01)) 
    } else if(item.coc.coc_residual_value_type==="limited"){
      do{
        //set new guess
        if(previous_npv<0){
          new_guess=previous_guess-guess_size
        } else if(previous_npv>0){
          new_guess=previous_guess+guess_size
        }

        //compute new npv
        discount_factor=calDiscountFactor(new_guess,number_of_years)
        dcf_fcf=dotMultiply(fcf,discount_factor)
        perpetuity=normalized_fcf/((new_guess-growth)/100)
        perpetuity_2=(normalized_fcf*(pow((1+growth),(year_restricted))))/((new_guess-growth)/100)*(1/pow((1+new_guess/100),year_restricted))
        discounted_perpetuity=multiply((perpetuity-perpetuity_2), (1/(pow((1+(new_guess/100)),number_of_years))))
        npv=sum(dcf_fcf)+discounted_perpetuity
        
        //Decide wether to change guess size.
        if((npv<0 &&previous_npv>0)||(npv>0 &&previous_npv<0)){
          guess_size=guess_size/2
        }

        //reset guess and npv
        previous_guess=new_guess
        previous_npv=npv
      }
      while(abs(npv)>0.05 && (guess_size>0.01)) 
    } else if(item.coc.coc_residual_value_type==="user_input"){
      do{
        
        //set new guess
        if(previous_npv<0){
          new_guess=previous_guess-guess_size
        } else if(previous_npv>0){
          new_guess=previous_guess+guess_size
        }

        //compute new npv
        discount_factor=calDiscountFactor(new_guess,number_of_years)
        dcf_fcf=dotMultiply(fcf,discount_factor)
        perpetuity=unNan(item.coc.coc_resuidual_value_user_input)
        discounted_perpetuity=multiply(perpetuity, (1/(pow((1+(new_guess/100)),number_of_years))))
        npv=sum(dcf_fcf)+discounted_perpetuity

        //Decide wether to change guess size.
        if((npv<0 &&previous_npv>0)||(npv>0 &&previous_npv<0)){
          guess_size=guess_size/2
        }

        //reset guess and npv
        previous_guess=new_guess
        previous_npv=npv
      }
      while(abs(npv)>0.05 && (guess_size>0.01)) 
    }
  } else {previous_guess=0}
  return (previous_guess/100)
}

function getIRR(item,fcff_type){
  const fcf_options={fcff: item.results.fe.fcff_accumulated, fcfe: item.results.fe.fcfe_accumulated}
  const fcf =fcf_options[fcff_type]

  //Analyse the need for iteration
  let positive= false
  let negative = false
  let changecount =0 
  for (var i = 0; i < fcf.length; i++) {
    if (fcf[i] > 0) positive = true;
    if (fcf[i] < 0) negative = true;
    if (i>0){
      if((fcf[i]<0 && fcf[i-1]>0) || (fcf[i]>0 && fcf[i-1]<0)){
        changecount++
      }
    }
  }
  if(positive===true && negative ===true && changecount<2) {
    return true
  } else{
    return false
  }
}


function calPayback(item,analysis_type){
  const fcf_options={sap: item.results._sap.fcff_discounted_accumulated, lep: item.results._lep.fcff_discounted_accumulated, ivp:item.results._ivp.fcfe_discounted_accumulated}

  const nsection=fcf_options[analysis_type]
  const section=[...nsection]
  let result=0
  for(var i=0;i<section.length;i++){
    if(i===0){
      if(section[0]>0){
        result=i+1
      }
    } else if (section[i]>0 && section[i-1]<0){
      result=i+1
    } else if (section[i]<0){
      result= (">"+section.length)
    }
  }
  return result
}

function calGrossMargin(item,zero){
  let bug=[...zero]
  const centopeid= new Array(bug.length).fill(1)
  bug= CorrectArray(add(dotDivide(dotMultiply(-1, item.results._inc_st.cogs),item.results._inc_st.revenue),centopeid))
  return bug
}

function calNetMargin(item,zero){
  let bug=[...zero]
  const centopeid= new Array(bug.length).fill(1)
  bug= CorrectArray(add(dotDivide(dotMultiply(-1, item.results._inc_st.net_profit),item.results._inc_st.revenue),centopeid))
  return bug
}

function calROS(item,zero){
  let bug=[...zero]
  bug= CorrectArray(dotDivide(item.results._inc_st.net_profit,item.results._inc_st.revenue))
  return bug
}

function calROA(item,zero){
  let bug=[...zero]
  bug= CorrectArray(dotDivide(item.results._inc_st.net_profit,item.results._bal.total_assets))
  return bug
}

function calROE(item,zero){
  let bug=[...zero]
  bug= CorrectArray(dotDivide(item.results._inc_st.net_profit,item.results._bal.total_equity))
  return bug
}

function calEquityRatio(item,zero){
  let bug=[...zero]
  bug= CorrectArray(dotDivide(item.results._bal.total_equity,item.results._bal.total_assets))
  return bug
}

function calDAR(item,zero,settings){
  let bug=[...zero]
  let vatter=1+settings.vat/100
  bug= CorrectArray(dotDivide(dotMultiply(365,item.results._bal.clients),dotMultiply(item.results._inc_st.revenue,vatter)))
  return bug
}

function calDAP(item,zero,settings){
  let bug=[...zero]
  let vatter=1+settings.vat/100
  bug= CorrectArray(dotDivide(dotMultiply(365,item.results._bal.suppliers),dotMultiply(add(item.results._inc_st.cogs,item.results._inc_st.operating_expenses),vatter)))
  return bug
}

function calDOS(item,zero){
  let bug=[...zero]
  bug= CorrectArray(dotDivide(dotMultiply(365,item.results._bal.inventories),item.results._inc_st.cogs))
  return bug
}

function calDepreciationRate(item,zero){
  let bug=[...zero]
  bug= CorrectArray(dotDivide(item.results._inc_st.depreciations,add(add(item.results._bal.tangible_assets,item.results._bal.intangible_assets),item.results._inc_st.depreciations)))
  return bug
}

function calCorporateTaxRate(item,zero){
  let bug=[...zero]
  bug= CorrectArray(dotDivide(item.results._inc_st.corporate_tax, item.results._inc_st.ebt))
  return bug
}

function calEBITDAInterestCoverage(item,zero){
  let bug=[...zero]
  bug= CorrectArray(dotDivide(item.results._inc_st.ebitda,item.results._inc_st.interest_cost))
  return bug
}

function calFCFFInterestCoverage(item,zero){
  let bug=[...zero]
  bug= CorrectArray(dotDivide(item.results.fe.fcff,item.results._inc_st.interest_cost))
  return bug
}

function calAvailableCashInterestCoverage(item,zero){
  let bug=[...zero]
  bug= CorrectArray(dotDivide(add(item.results.cf.year_end_cash_flows,item.results.cf.debt_decrease,item.results._inc_st.interest_cost),item.results._inc_st.interest_cost))
  return bug
}

function calEBITDADebtCoverage(item,zero){
  let bug=[...zero]
  bug= CorrectArray(dotDivide(item.results._inc_st.ebitda,add(item.results._inc_st.interest_cost, item.results.cf.debt_decrease)))
  return bug
}

function calFCFFDebtCoverage(item,zero){
  let bug=[...zero]
  bug= CorrectArray(dotDivide(item.results.fe.fcff,add(item.results._inc_st.interest_cost, item.results.cf.debt_decrease)))
  return bug
}

function calAvailableCashDebtCoverage(item,zero){
  let bug=[...zero]
  bug= CorrectArray(dotDivide(add(item.results.cf.year_end_cash_flows,item.results.cf.debt_decrease,item.results._inc_st.interest_cost),add(item.results._inc_st.interest_cost, item.results.cf.debt_decrease)))
  return bug
}

const histF={
  cal_inventory:(item,zero)=>{
    let bug=[...zero]
    if(item.revenues.length>0){
        bug=addArrays(item.revenues.map((value)=>value.rev_cogs._inventory))
    }
    bug[0]=item.version_historic_data.h_inventories
    return bug
  },
  calClients:(item,zero)=>{
    let buggy=[...zero]
    let receivables=item.version_settings.days_accounts_receivables
    if(item.revenues.length>0){
        buggy=addArrays(item.revenues.map((value)=>value._rev_receivable))
    }
    buggy=(dotDivide(dotMultiply(buggy,receivables),365))
    buggy[0]=item.version_historic_data.h_clients
    return buggy
  },
  calPayables:(item,zero)=>{
    let buggy=[...zero]
    let dune_buggy=[...zero]
    if(item.revenues.length>0){
      buggy=addArrays(item.revenues.map((value)=>value.rev_cogs._cogs_payable))
    }
    if(item.operating_expenses.length>0){
      dune_buggy=addArrays(item.operating_expenses.map((value)=>value._op_exp_payable))
    }
    dune_buggy[0]=0
    return dune_buggy
  },
  calVATReceivables:(item,zero, project_settings)=>{
    let vat_balance=item.results.gov.vat_balance
    let vat_pay_period=project_settings.vat_payment
    let bug=[...zero]
    for(var i=0;i<vat_balance.length;i++){
      if(i===0){
        bug[i]=item.version_historic_data.h_government_receivable
      }else if(vat_balance[i]<0){
        bug[i]=vat_balance[i]*vat_pay_period/365*-1
      }else {
        bug[i]=0
      }
    }
    return bug
  },
  calVATPayables:(item, zero,project_settings)=>{
    let vat_balance=item.results.gov.vat_balance
    let vat_pay_period=project_settings.vat_payment
    let bug=[...zero]
    for(var i=0;i<vat_balance.length;i++){
      if(i===0){
        bug[i]=item.version_historic_data.h_government_payable
      } else if(vat_balance[i]>=0){
        bug[i]=vat_balance[i]*vat_pay_period/365
      } else {
        bug[i]=0
      }
    }
    return bug
  },
  calVATCashFlow:(item,zero)=>{
    let bug=[...zero]
    let it=item.results.gov
    for(var i=0;i<it.vat_balance.length;i++){
      if(i===0){
        bug[i]=0 
      } else if(i===1){
        bug[i]=it.vat_balance[i]+it.vat_receivable[i]-it.vat_receivable[i-1]-it.vat_payable[i]+it.vat_payable[i-1]-item.results._inc_st.corporate_tax[0]
      } else{
        bug[i]=it.vat_balance[i]+it.vat_receivable[i]-it.vat_receivable[i-1]-it.vat_payable[i]+it.vat_payable[i-1]
      }
    }
    return bug
  },
  calGovPayables:(item,zero)=>{
    let buggy=[...zero]
    buggy=add(
      item.results.gov.vat_payable,
      item.results._inc_st.corporate_tax
    )
    buggy[0]=item.version_historic_data.h_government_payable
    return buggy
  },
  calGovReceivables:(item,zero)=>{
    let buggy=[...zero]
    buggy=item.results.gov.vat_receivable
    buggy[0]=item.version_historic_data.h_government_receivable
    return buggy
  },
  calInitialCashFlows:(item,zero)=>{
    let bug=[...zero]
    for(var i=0;i<bug.length;i++){
      if(i===0){
        bug[i]=item.version_historic_data.h_cash
      } else{
        bug[i]=bug[i-1]+item.results.cf.cash_flow_variation[i-1]
      }
    }
    return bug
  },
  calYearEndCashFlows:(item,zero)=>{
    let bug=[...zero]
    for(var i=0;i<bug.length;i++){
      if(i===0){
        bug[i]=item.version_historic_data.h_cash
      } else{
        bug[i]=item.results.cf.initial_cash_flows[i]+item.results.cf.cash_flow_variation[i]  
      }
    }
    return bug
  },
  calCashVariation:(item, zero)=>{
    let bug=[...zero]
    bug=add(
      item.results.cf.operational_cash_flow,
      item.results.cf.investment_cash_flows,
      item.results.cf.financing_cashflows
    )
    bug[0]=0
    return bug
  },
  callSharePremium:(item,zero)=>{
    let bug=[...zero]
    let centopeid=item.shareholders
    let dune_buggy=centopeid.filter(centopeid => centopeid.shareholders_type === "issued_capital")
    if(dune_buggy.length>0){
      bug=addArrays(dune_buggy.map((value)=>value._shareholders_share_premium_accumulated))
    }
    bug=add(bug,new Array(zero.length).fill(item.version_historic_data.h_other_equity_changes))
    return bug
  },
  calSuppliers:(item)=>{
    let buggy=item.results.cf.payables
    let payables=item.version_settings.days_accounts_payables
    buggy=dotDivide(dotMultiply(buggy,payables),365)
    buggy[0]=item.version_historic_data.h_suppliers
    return buggy
  },
  calInvestmentSuppliers:(item,zero)=>{
    let bug=[...zero]
    let dune_buggy=[]//total expenses with vat
    let payables=item.version_settings.investment_days_accounts_payables
    if(item.investments.length>0){
      dune_buggy=addArrays(item.investments.map((value)=>value._inv_receivable))
      bug=(dotDivide(dotMultiply(dune_buggy,payables),365))
    }
    bug[0]=0
    return bug
  },
  calOtherAccountsPayables:(item,zero)=>{
    let buggy=[...zero]
    buggy=item.results.cf.debt_to_investment_suppliers
    buggy[0]=item.version_historic_data.h_other_accounts_payables
    return buggy
  },
  calOperationalCorporateTax:(item,zero)=>{
    let bug=[0]
    let irc=removeFirstElementFromArray(item.version_settings.version_corporate_tax_rate)
    for( let i = 0; i< irc.length; i++){
      bug[i]=max(item.results.fe.ebit[i]*irc[i]/100,0)
    }
    return bug
  },
  calPayablesReceivables:(item,zero)=>{
    let bug=[...zero]
    const receivables_from_other_gains=calOtherGainsReceivables(item,zero)
    const payables_from_other_costs=dotMultiply(-1,calOtherCostsPayables(item,zero))
    bug=add(
    dotMultiply(-1,item.results.gov.vat_cashflow),
    receivables_from_other_gains,
    payables_from_other_costs)
    bug[1]=bug[1]-item.version_historic_data.h_other_accounts_payables
    return bug
  },
  calEBITDAInterestCoverage:(item,zero)=>{
    let bug=[...zero]
    bug= CorrectArray(dotDivide(item.results._inc_st.ebitda,item.results._inc_st.interest_cost))
    bug=bug.slice(1)
    return bug
  },  
  calFCFFInterestCoverage:(item,zero)=>{
    let bug=[...zero]
    bug= CorrectArray(dotDivide(item.results.fe.fcff,item.results._inc_st.interest_cost.slice(1)))
    return bug
  },  
  calAvailableCashInterestCoverage:(item,zero)=>{
    let bug=[...zero]
    bug= CorrectArray(dotDivide(add(item.results.cf.year_end_cash_flows,item.results.cf.debt_decrease,item.results._inc_st.interest_cost),item.results._inc_st.interest_cost))
    bug=bug.slice(1)
    return bug
  },  
  calEBITDADebtCoverage:(item,zero)=>{
    let bug=[...zero]
    bug= CorrectArray(dotDivide(item.results._inc_st.ebitda,add(item.results._inc_st.interest_cost, item.results.cf.debt_decrease)))
    bug=bug.slice(1)
    return bug
  },  
  calFCFFDebtCoverage:(item,zero)=>{
    let bug=[...zero]
    bug= CorrectArray(dotDivide(item.results.fe.fcff,add(item.results._inc_st.interest_cost.slice(1), item.results.cf.debt_decrease.slice(1))))
    return bug
  },  
  calAvailableCashDebtCoverage:(item,zero)=>{
    let bug=[...zero]
    bug= CorrectArray(dotDivide(add(item.results.cf.year_end_cash_flows,item.results.cf.debt_decrease,item.results._inc_st.interest_cost),add(item.results._inc_st.interest_cost, item.results.cf.debt_decrease)))
    bug=bug.slice(1)
    return bug
  }
}


//WHERE THE MAIN CAL COMES TO
const calculateOnlyIfEdited= async(version, settings)=>{
  if(version.version_edited===true){
    version=calResults(version,settings)
  }
  return version
}

const calResults = async(c,settings)=>{
  const s=settings
  
  const z1=new Array(s.number_of_years+s.number_of_historic_years).fill(0)
  const z2=new Array(s.number_of_years).fill(0)

  //INC STATEMENT
  c.results._inc_st.revenue=calRevenue(c,z1)
  c.results._inc_st.cogs=calCogs(c,z1)
  c.results._inc_st.operational_grants=calOperationalGrants(c,z1)
  c.results._inc_st.operating_expenses=calOperatingExpenses(c,z1)
  c.results._inc_st.hr=calHr(c,z1)
  c.results._inc_st.other_gains=calOtherGains(c,z1)
  c.results._inc_st.other_costs=calOtherCosts(c,z1)
  c.results._inc_st.ebitda=add(
    c.results._inc_st.revenue,
    c.results._inc_st.operational_grants,
    (dotMultiply(-1,c.results._inc_st.cogs)),
    (dotMultiply(-1,c.results._inc_st.operating_expenses)),
    (dotMultiply(-1,c.results._inc_st.hr)),
    c.results._inc_st.other_gains,
    (dotMultiply(-1,c.results._inc_st.other_costs))
  )
  
  c.results._inc_st.depreciations=cal_depreciation(c,z1)
  c.results._inc_st.ebit=add(
    c.results._inc_st.ebitda,
    (dotMultiply(-1,c.results._inc_st.depreciations))
  )
  c.results._inc_st.interest_cost=cal_interest_cost(c,z1)
  c.results._inc_st.ebt=add(
      c.results._inc_st.ebit,
      (dotMultiply(-1,c.results._inc_st.interest_cost))
  )

  c.results._inc_st.corporate_tax=cal_corporate_tax(c,z1,settings)
  c.results._inc_st.net_profit=add(
      c.results._inc_st.ebt,
      dotMultiply(-1,c.results._inc_st.corporate_tax)
  )    

  if(settings.use_historic!==true){
    
    // GOV
    c.results.gov.vat_sales=calVatSales(c,z1)
    c.results.gov.vat_cog=calVatCogs(c,z1)
    c.results.gov.vat_op_exp=calVatOpExp(c,z1)
    c.results.gov.vat_inv=calVATInvestment(c,z1)
    c.results.gov.vat_balance=add(
      c.results.gov.vat_sales,
      dotMultiply(-1,c.results.gov.vat_cog),
      dotMultiply(-1,c.results.gov.vat_op_exp),
      dotMultiply(-1,c.results.gov.vat_inv)
    )
    
    c.results.gov.vat_receivable=calVATReceivables(c,z1,s)
    c.results.gov.vat_payable=calVATPayables(c,z1,s)
    c.results.gov.vat_cashflow=calVATCashFlow(c,z1)
    c.results.gov.gov_receivable=c.results.gov.vat_receivable
    c.results.gov.gov_payable=add(
      c.results.gov.vat_payable,
      c.results._inc_st.corporate_tax
    )
    


    //CF
    c.results.cf.clients_in_debt=calClients(c,z1)
    c.results.cf.payables=calPayables(c,z1)
    c.results.cf.debt_to_suppliers=calSuppliers(c,z1)
    c.results.cf.debt_to_investment_suppliers=calInvestmentSuppliers(c,z1)
    c.results.cf.received_from_clients=calReceivedFromClients(c,z1)
    c.results.cf.paid_to_suppliers=calPaidToSuppliers(c,z1)
    c.results.cf.payment_to_hr=calPaidToHr(c,z1)
    c.results.cf.operations_cash_flows=add(
      c.results.cf.received_from_clients,
      dotMultiply(-1,c.results.cf.paid_to_suppliers),
      dotMultiply(-1,c.results.cf.payment_to_hr),
    )

    c.results.cf.corporate_tax_cash_flow=calCorporateTaxCashFlow(c,z1)
    c.results.cf.other_payments_and_receivables=add(
      dotMultiply(-1,c.results.gov.vat_cashflow),
      calOtherGainsReceivables(c,z1),
      dotMultiply(-1,calOtherCostsPayables(c,z1))
    )

    c.results.cf.operational_cash_flow=add(
      c.results.cf.operations_cash_flows,
      c.results.cf.corporate_tax_cash_flow,
      c.results.cf.other_payments_and_receivables
    )
    
    c.results.cf.payment_of_fixed_assets=calPaymentOfFixedAssets(c,z1)
    c.results.cf.cash_flows_of_non_current_assets_liabilities=calCashFlowsOfNonCurrentAssetsLiabilities(c,z1)
    
    c.results.cf.investment_cash_flows=add(
      dotMultiply(-1,c.results.cf.payment_of_fixed_assets),
      //c.results.cf.received_investment_grants,
      c.results.cf.cash_flows_of_non_current_assets_liabilities
    )
    
    c.results.cf.debt_increase=calDebtIncrease(c, z1)
    c.results.cf.equity_and_equivalent_increase=add(
    calEquityAndEquivalentIncrease(c, z1),
    callReceivedSharePremium(c,z1)
    )
    c.results.cf.received_financing=add(
      c.results.cf.debt_increase,
      c.results.cf.equity_and_equivalent_increase
    )
    c.results.cf.debt_decrease=calDebtDecrease(c, z1)
    c.results.cf.equity_and_equivalent_decrease=calEquityAndEquivalentDecrease(c, z1)
    c.results.cf.paid_dividends=PaidDividends(c, z1)
    c.results.cf.paid_financing=add(
      c.results.cf.debt_decrease,
      c.results.cf.equity_and_equivalent_decrease,
      c.results._inc_st.interest_cost,
      c.results.cf.paid_dividends
    )
    c.results.cf.financing_cashflows=add(
      c.results.cf.received_financing,
      dotMultiply(-1,c.results.cf.paid_financing)
    )
    c.results.cf.cash_flow_variation=add(
      c.results.cf.operational_cash_flow,
      c.results.cf.investment_cash_flows,
      c.results.cf.financing_cashflows
    )
    
    c.results.cf.initial_cash_flows=calInitialCashFlows(c, z1)
    c.results.cf.year_end_cash_flows=calYearEndCashFlows(c, z1)



    //BALANCE
    //non-current assets
    c.results._bal.tangible_assets=calAssets(c, z1,"Tangible")
    c.results._bal.intangible_assets=calAssets(c, z1, "Intangible")
    c.results._bal.other_non_current_assets=cal_other_balance(c, z1, "non_current_assets")
    c.results._bal.total_non_current_assets=add(
      c.results._bal.tangible_assets,
      c.results._bal.intangible_assets,
      c.results._bal.other_non_current_assets
    )
    //current assets
    c.results._bal.other_current_assets=cal_other_balance(c,z1,"current_assets")
    c.results._bal.inventories=cal_inventory(c,z1)
    c.results._bal.clients=c.results.cf.clients_in_debt
    c.results._bal.total_current_assets=add(
      c.results._bal.other_current_assets,
      c.results._bal.inventories,
      c.results._bal.clients,
      c.results.gov.gov_receivable,
      c.results.cf.year_end_cash_flows
    )
    c.results._bal.total_assets=add(
      c.results._bal.total_non_current_assets,
      c.results._bal.total_current_assets
    )
    //equity
    c.results._bal.issued_capital=calShareholders(c,"issued_capital",z1)
    c.results._bal.other_equity_instruments=calShareholders(c,"other_equity_instruments",z1)
    c.results._bal.other_equity_changes=callSharePremium(c,z1)
    c.results._bal.past_results=cal_past_results(c,z1)
    c.results._bal.total_equity=add(
      c.results._bal.issued_capital,
      c.results._bal.other_equity_instruments,
      c.results._bal.other_equity_changes,
      c.results._bal.past_results,
      c.results._inc_st.net_profit
    )
    //non-current liabilities
    c.results._bal.debt=cal_debt(c,z1)
    c.results._bal.other_non_current_liabilities=cal_other_balance(c,z1,"non_current_liabilities")
    c.results._bal.total_non_current_liabilities=add(
      c.results._bal.debt,
      c.results._bal.other_non_current_liabilities,
    )
    //current liabilities
    c.results._bal.suppliers=c.results.cf.debt_to_suppliers
    c.results._bal.shareholders_loan=calShareholders(c,"shareholders_loan",z1)
    c.results._bal.other_accounts_payables=c.results.cf.debt_to_investment_suppliers
    c.results._bal.other_current_liabilities=cal_other_balance(c,z1,"current_liabilities")
    c.results._bal.total_current_liabilities=add(
      c.results._bal.suppliers,
      c.results._bal.shareholders_loan,
      c.results._bal.other_accounts_payables,
      c.results._bal.other_current_liabilities,
      c.results.gov.gov_payable
    )
    c.results._bal.total_liabilities=add(
      c.results._bal.total_non_current_liabilities,
      c.results._bal.total_current_liabilities,
    )
    c.results._bal.equity_liabilities=add(
      c.results._bal.total_equity,
      c.results._bal.total_liabilities
    )

    //WORKING CAPITAL
    c.results.wc.wc_investment=add(
      c.results._bal.inventories,
      c.results._bal.clients,
      c.results.gov.gov_receivable,
      c.results._bal.other_current_assets
    )
    c.results.wc.wc_financing=add(
      c.results._bal.suppliers,
      c.results.gov.gov_payable,
      c.results._bal.other_accounts_payables,
      c.results._bal.other_current_liabilities,
    )
    c.results.wc.wc_needs=add(
      c.results.wc.wc_investment,
      dotMultiply(-1,c.results.wc.wc_financing)
    )
    c.results.wc.wc_variation=calWcVariation(c.results.wc.wc_needs, z1)
   

    //VALUATION
    c.results.fe.ebit=(c.results._inc_st.ebit)
    c.results.fe.depreciations=(c.results._inc_st.depreciations)
    c.results.fe.wc_variation=(c.results.wc.wc_variation)
    c.results.fe.operational_corporate_tax=calOperationalCorporateTax(c,z1,settings)
    c.results.fe.noplat=add(
      c.results._inc_st.ebit,
      dotMultiply(-1,c.results.fe.operational_corporate_tax),
      c.results._inc_st.depreciations
    )
    c.results.fe.fixed_asset_investment=calFixedAssetInvestment(c,z1)
    c.results.fe.fcff=add(
      c.results.fe.noplat,
      dotMultiply(-1,c.results.fe.fixed_asset_investment),
      dotMultiply(-1,c.results.wc.wc_variation)
    )
    c.results.fe.fcff_accumulated=accumulateOneArray(c.results.fe.fcff)
    c.results.fe.fcff_normalized=calNormalizedFCF(c)
    
    c.results.fe.fcfe=add(
      c.results.fe.fcff,
      dotMultiply(-1,c.results._inc_st.interest_cost),
      dotMultiply(-1,c.results.cf.debt_decrease)
    )
    c.results.fe.fcfe_accumulated=accumulateOneArray(c.results.fe.fcfe)
    c.results.fe.fcfe_normalized=calNormalizedFCE(c)

    if(settings.financial_standalone_project===true){
      c.results._sap.fcff_discounted=calDiscountFCFf(c,z1,'fcff','re')
      c.results._sap.fcff_discounted_accumulated=accumulateOneArray(c.results._sap.fcff_discounted)
      c.results._sap.perpetuity=calPerpetuity(c,'fcff','re')
      c.results._sap.perpetuity_discounted=multiply(c.results._sap.perpetuity,c.coc._coc_perpetuity_equity_discount_factor)
      c.results._sap.npv=add(calNPV(c.results._sap.fcff_discounted_accumulated), c.results._sap.perpetuity_discounted)
      c.results._sap._irr_exists=getIRR(c,'fcff')
      c.results._sap.irr=calIRR(c,'fcff','re', '_sap')
      c.results._sap.payback=calPayback(c, 'sap')
    }
    if(settings.financial_levered_project===true){
      c.results._lep.fcff_discounted=calDiscountFCFf(c,z1,'fcff','wacc')
      c.results._lep.fcff_discounted_accumulated=accumulateOneArray(c.results._lep.fcff_discounted)
      c.results._lep.perpetuity=calPerpetuity(c,'fcff','wacc')
      c.results._lep.perpetuity_discounted=multiply(c.results._lep.perpetuity,c.coc._coc_perpetuity_discount_factor)
      c.results._lep.npv=add(calNPV(c.results._lep.fcff_discounted_accumulated), c.results._lep.perpetuity_discounted)
      c.results._lep._irr_exists=getIRR(c,'fcff')
      c.results._lep.irr=calIRR(c,'fcff','wacc','_lep')
      c.results._lep.payback=calPayback(c, 'lep')
    }
    if(settings.financial_investor===true){
      c.results._ivp.fcfe_discounted=calDiscountFCFf(c,z1,'fcfe','re')
      c.results._ivp.fcfe_discounted_accumulated=accumulateOneArray(c.results._ivp.fcfe_discounted)
      c.results._ivp.perpetuity=calPerpetuity(c,'fcfe','re')
      c.results._ivp.perpetuity_discounted=multiply(c.results._ivp.perpetuity,c.coc._coc_perpetuity_equity_discount_factor)
      c.results._ivp.npv=add(calNPV(c.results._ivp.fcfe_discounted_accumulated), c.results._ivp.perpetuity_discounted)
      c.results._ivp._irr_exists=getIRR(c,'fcfe')
      c.results._ivp.irr=calIRR(c,'fcfe','re','_ivp')
      c.results._ivp.payback=calPayback(c, 'ivp')
    }
    //indicators
    c.results.ra.gross_margin=calGrossMargin(c,z1)
    c.results.ra.net_margin=calNetMargin(c,z1)
    c.results.ra.ros=calROS(c,z1)
    c.results.ra.roa=calROA(c,z1)
    c.results.ra.roe=calROE(c,z1)
    c.results.ra.equity_ratio=calEquityRatio(c,z1)
    c.results.ra.dar=calDAR(c,z1,settings)
    c.results.ra.dap=calDAP(c,z1,settings)
    c.results.ra.dos=calDOS(c,z1)
    c.results.ra.depreciation_rate=calDepreciationRate(c,z1)
    c.results.ra.corporate_tax_rate=calCorporateTaxRate(c,z1)
    c.results.ra.ebtida_interest_coverage_rate=calEBITDAInterestCoverage(c,z1)
    c.results.ra.fcff_interest_coverate_rate=calFCFFInterestCoverage(c,z1)
    c.results.ra.available_cash_interest_coverage_rate=calAvailableCashInterestCoverage(c,z1)
    c.results.ra.interest=c.results._inc_st.interest_cost
    c.results.ra.debt_service=add(c.results._inc_st.interest_cost,c.results.cf.debt_decrease)
    c.results.ra.ebtida_debt_service_rate=calEBITDADebtCoverage(c,z1)
    c.results.ra.fcff_debt_service_rate=calFCFFDebtCoverage(c,z1)
    c.results.ra.available_cash_debt_service_rate=calAvailableCashDebtCoverage(c,z1)
    
    
    //FINAL PROCEDURES TO SEND VERSION
    c.version_edited=false
  } else {

    // GOV
    c.results.gov.vat_sales=calVatSales(c,z1)
    c.results.gov.vat_cog=calVatCogs(c,z1)
    c.results.gov.vat_op_exp=calVatOpExp(c,z1)
    c.results.gov.vat_inv=calVATInvestment(c,z1)
    c.results.gov.vat_balance=add(
      c.results.gov.vat_sales,
      dotMultiply(-1,c.results.gov.vat_cog),
      dotMultiply(-1,c.results.gov.vat_op_exp),
      dotMultiply(-1,c.results.gov.vat_inv)
    )

    c.results.gov.vat_receivable= histF.calVATReceivables(c,z1,s)
    c.results.gov.vat_payable= histF.calVATPayables(c,z1,s)
    c.results.gov.vat_cashflow=histF.calVATCashFlow(c,z1)
    c.results.gov.gov_receivable=histF.calGovReceivables(c,z1)
    c.results.gov.gov_payable=histF.calGovPayables(c,z1)


    //CF
    c.results.cf.clients_in_debt=histF.calClients(c,z1)
    c.results.cf.payables=calPayables(c,z1)
    c.results.cf.debt_to_suppliers=histF.calSuppliers(c,z1)
    c.results.cf.debt_to_investment_suppliers=histF.calInvestmentSuppliers(c,z1)
    c.results.cf.received_from_clients=calReceivedFromClients(c,z1)
    c.results.cf.paid_to_suppliers=calPaidToSuppliers(c,z1)
    c.results.cf.payment_to_hr=calPaidToHr(c,z1)
    c.results.cf.operations_cash_flows=add(
      c.results.cf.received_from_clients,
      dotMultiply(-1,c.results.cf.paid_to_suppliers),
      dotMultiply(-1,c.results.cf.payment_to_hr),
    )

    c.results.cf.corporate_tax_cash_flow=calCorporateTaxCashFlow(c,z1)
    c.results.cf.other_payments_and_receivables=histF.calPayablesReceivables(c,z1)

    c.results.cf.operational_cash_flow=add(
      c.results.cf.operations_cash_flows,
      c.results.cf.corporate_tax_cash_flow,
      c.results.cf.other_payments_and_receivables
    )
    
    c.results.cf.payment_of_fixed_assets=calPaymentOfFixedAssets(c,z1)
    c.results.cf.cash_flows_of_non_current_assets_liabilities=calCashFlowsOfNonCurrentAssetsLiabilities(c,z1)
    
    c.results.cf.investment_cash_flows=add(
      dotMultiply(-1,c.results.cf.payment_of_fixed_assets),
      //c.results.cf.received_investment_grants,
      c.results.cf.cash_flows_of_non_current_assets_liabilities
    )
    
    c.results.cf.debt_increase=calDebtIncrease(c, z1)
    c.results.cf.equity_and_equivalent_increase=add(
    calEquityAndEquivalentIncrease(c, z1),
    callReceivedSharePremium(c,z1)
    )
    c.results.cf.received_financing=add(
      c.results.cf.debt_increase,
      c.results.cf.equity_and_equivalent_increase
    )
    c.results.cf.debt_decrease=calDebtDecrease(c, z1)
    c.results.cf.equity_and_equivalent_decrease=calEquityAndEquivalentDecrease(c, z1)
    c.results.cf.paid_dividends=PaidDividends(c, z1)
    c.results.cf.paid_financing=add(
      c.results.cf.debt_decrease,
      c.results.cf.equity_and_equivalent_decrease,
      c.results._inc_st.interest_cost,
      c.results.cf.paid_dividends
    )
    c.results.cf.financing_cashflows=add(
      c.results.cf.received_financing,
      dotMultiply(-1,c.results.cf.paid_financing)
    )
    c.results.cf.cash_flow_variation=histF.calCashVariation(c,z1)
    
    c.results.cf.initial_cash_flows=histF.calInitialCashFlows(c, z1)
    c.results.cf.year_end_cash_flows=histF.calYearEndCashFlows(c, z1)


    //BALANCE
    //non-current assets
    c.results._bal.tangible_assets=calAssets(c, z1,"Tangible")
    c.results._bal.intangible_assets=calAssets(c, z1, "Intangible")
    c.results._bal.other_non_current_assets=cal_other_balance(c, z1, "non_current_assets")
    c.results._bal.total_non_current_assets=add(
      c.results._bal.tangible_assets,
      c.results._bal.intangible_assets,
      c.results._bal.other_non_current_assets
    )
    //current assets
    c.results._bal.other_current_assets=cal_other_balance(c,z1,"current_assets")
    c.results._bal.inventories=histF.cal_inventory(c,z1)
    c.results._bal.clients=c.results.cf.clients_in_debt
    c.results._bal.total_current_assets=add(
      c.results._bal.other_current_assets,
      c.results._bal.inventories,
      c.results._bal.clients,
      c.results.gov.gov_receivable,
      c.results.cf.year_end_cash_flows
    )
    c.results._bal.total_assets=add(
      c.results._bal.total_non_current_assets,
      c.results._bal.total_current_assets
    )
    //equity
    
    c.results._bal.issued_capital=calShareholders(c,"issued_capital",z1)
    c.results._bal.other_equity_instruments=calShareholders(c,"other_equity_instruments",z1)
    c.results._bal.other_equity_changes=histF.callSharePremium(c,z1)
    c.results._bal.past_results=cal_past_results(c,z1)
    c.results._bal.total_equity=add(
      c.results._bal.issued_capital,
      c.results._bal.other_equity_instruments,
      c.results._bal.other_equity_changes,
      c.results._bal.past_results,
      c.results._inc_st.net_profit
    )
    //non-current liabilities
    c.results._bal.debt=cal_debt(c,z1)
    c.results._bal.other_non_current_liabilities=cal_other_balance(c,z1,"non_current_liabilities")
    c.results._bal.total_non_current_liabilities=add(
      c.results._bal.debt,
      c.results._bal.other_non_current_liabilities,
    )
    //current liabilities
    c.results._bal.suppliers=c.results.cf.debt_to_suppliers
    c.results._bal.shareholders_loan=calShareholders(c,"shareholders_loan",z1)
    c.results._bal.other_accounts_payables=histF.calOtherAccountsPayables(c,z1)
    c.results._bal.other_current_liabilities=cal_other_balance(c,z1,"current_liabilities")
    c.results._bal.total_current_liabilities=add(
      c.results._bal.suppliers,
      c.results._bal.shareholders_loan,
      c.results._bal.other_accounts_payables,
      c.results._bal.other_current_liabilities,
      c.results.gov.gov_payable
    )
    c.results._bal.total_liabilities=add(
      c.results._bal.total_non_current_liabilities,
      c.results._bal.total_current_liabilities,
    )
    c.results._bal.equity_liabilities=add(
      c.results._bal.total_equity,
      c.results._bal.total_liabilities
    )
  
    //WORKING CAPITAL
    c.results.wc.wc_investment=add(
      c.results._bal.inventories,
      c.results._bal.clients,
      c.results.gov.gov_receivable,
      c.results._bal.other_current_assets
    )
    c.results.wc.wc_financing=add(
      c.results._bal.suppliers,
      c.results.gov.gov_payable,
      c.results._bal.other_accounts_payables,
      c.results._bal.other_current_liabilities,
    )
    c.results.wc.wc_needs=add(
      c.results.wc.wc_investment,
      dotMultiply(-1,c.results.wc.wc_financing)
    )
    c.results.wc.wc_variation=calWcVariation(c.results.wc.wc_needs, z1)


    //Feasibility
    c.results.fe.ebit=removeFirstElementFromArray(c.results._inc_st.ebit)
    c.results.fe.depreciations=removeFirstElementFromArray(c.results._inc_st.depreciations)
    c.results.fe.wc_variation=removeFirstElementFromArray(c.results.wc.wc_variation)
    
    c.results.fe.operational_corporate_tax=histF.calOperationalCorporateTax(c,z1)
    c.results.fe.noplat=add(
      c.results.fe.ebit,
      dotMultiply(-1,c.results.fe.operational_corporate_tax),
      c.results.fe.depreciations
    )
    
    c.results.fe.fixed_asset_investment=removeFirstElementFromArray(calFixedAssetInvestment(c,z1))
    c.results.fe.fcff=add(
      c.results.fe.noplat,
      dotMultiply(-1,c.results.fe.fixed_asset_investment),
      dotMultiply(-1,c.results.fe.wc_variation)
    )    
    
    c.results.fe.fcff_accumulated=accumulateOneArray(c.results.fe.fcff)
    c.results.fe.fcff_normalized=calNormalizedFCF(c)
    //fcfe
    c.results.fe.fcfe=add(
      c.results.fe.fcff,
      dotMultiply(-1,c.results._inc_st.interest_cost.slice(1)),
      dotMultiply(-1,c.results.cf.debt_decrease.slice(1))
    )
    c.results.fe.fcfe_accumulated=accumulateOneArray(c.results.fe.fcfe)
    c.results.fe.fcfe_normalized=calNormalizedFCE(c)
    
    //feasibilities
    if(settings.financial_standalone_project===true){
      c.results._sap.fcff_discounted=calDiscountFCFf(c,z1,'fcff','re')
      c.results._sap.fcff_discounted_accumulated=accumulateOneArray(c.results._sap.fcff_discounted)
      c.results._sap.perpetuity=calPerpetuity(c,'fcff','re')
      c.results._sap.perpetuity_discounted=multiply(c.results._sap.perpetuity,c.coc._coc_perpetuity_equity_discount_factor)
      c.results._sap.npv=add(calNPV(c.results._sap.fcff_discounted_accumulated), c.results._sap.perpetuity_discounted)
      c.results._sap._irr_exists=getIRR(c,'fcff')
      c.results._sap.irr=calIRR(c,'fcff','re', '_sap')
      c.results._sap.payback=calPayback(c, 'sap')
    }
    
    if(settings.financial_levered_project===true){
      c.results._lep.fcff_discounted=calDiscountFCFf(c,z1,'fcff','wacc')
      c.results._lep.fcff_discounted_accumulated=accumulateOneArray(c.results._lep.fcff_discounted)
      c.results._lep.perpetuity=calPerpetuity(c,'fcff','wacc')
      c.results._lep.perpetuity_discounted=multiply(c.results._lep.perpetuity,c.coc._coc_perpetuity_discount_factor)
      c.results._lep.npv=add(calNPV(c.results._lep.fcff_discounted_accumulated), c.results._lep.perpetuity_discounted)
      c.results._lep._irr_exists=getIRR(c,'fcff')
      c.results._lep.irr=calIRR(c,'fcff','wacc','_lep')
      c.results._lep.payback=calPayback(c, 'lep')      
    }
    if(settings.financial_investor===true){
      c.results._ivp.fcfe_discounted=calDiscountFCFf(c,z1,'fcfe','re')
      c.results._ivp.fcfe_discounted_accumulated=accumulateOneArray(c.results._ivp.fcfe_discounted)
      c.results._ivp.perpetuity=calPerpetuity(c,'fcfe','re')
      c.results._ivp.perpetuity_discounted=multiply(c.results._ivp.perpetuity,c.coc._coc_perpetuity_equity_discount_factor)
      c.results._ivp.npv=add(calNPV(c.results._ivp.fcfe_discounted_accumulated), c.results._ivp.perpetuity_discounted)
      c.results._ivp._irr_exists=getIRR(c,'fcfe')
      c.results._ivp.irr=calIRR(c,'fcfe','re','_ivp')
      c.results._ivp.payback=calPayback(c, 'ivp')
    }
    //indicators
    c.results.ra.gross_margin=calGrossMargin(c,z1)

    c.results.ra.net_margin=calNetMargin(c,z1)
    c.results.ra.ros=calROS(c,z1)
    c.results.ra.roa=calROA(c,z1)
    c.results.ra.roe=calROE(c,z1)
    c.results.ra.equity_ratio=calEquityRatio(c,z1)
    c.results.ra.dar=calDAR(c,z1,settings)
    c.results.ra.dap=calDAP(c,z1,settings)
    c.results.ra.dos=calDOS(c,z1)
    c.results.ra.depreciation_rate=calDepreciationRate(c,z1)
    c.results.ra.corporate_tax_rate=calCorporateTaxRate(c,z1)
    c.results.ra.ebtida_interest_coverage_rate=histF.calEBITDAInterestCoverage(c,z1)
    c.results.ra.fcff_interest_coverate_rate=histF.calFCFFInterestCoverage(c,z1)
    c.results.ra.available_cash_interest_coverage_rate=histF.calAvailableCashInterestCoverage(c,z1)
    c.results.ra.interest=c.results._inc_st.interest_cost.slice(1)
    c.results.ra.debt_service=add(c.results._inc_st.interest_cost,c.results.cf.debt_decrease).slice(1)
    c.results.ra.ebtida_debt_service_rate=histF.calEBITDADebtCoverage(c,z1)
    c.results.ra.fcff_debt_service_rate=histF.calFCFFDebtCoverage(c,z1)
    c.results.ra.available_cash_debt_service_rate=histF.calAvailableCashDebtCoverage(c,z1)


    //FINAL PROCEDURES TO SEND VERSION
    c.version_edited=false 

  }
  return (c)
}

export {
  calculateOnlyIfEdited,
  calResults
}