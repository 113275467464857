import React from 'react';
import {Grid, Col, Row} from 'Support/grid';
import {Card, Button} from 'react-bootstrap';

import ApiConnect from 'CONFIG/axios';
import UserContext from 'CONFIG/auth';

import Loader from 'Support/loader';
import { RegularButtonSmall } from 'Support/listbuttons';

class ColaborateRequestNotification extends React.Component{
  static contextType = UserContext

  constructor(props) {
    super(props);
    this.state = {
      data: "",
      loaded:false
    }
  }

  componentDidMount() {
    this.updateSettingsData()
  }

  updateSettingsData=async()=>{
    const request={
    }
    try{
      const response = await ApiConnect.post('/api/project/colaborationviewer/requests/get', request)
      this.setState({
        data:response.data,
        loaded:true
      })
    }catch(error) {
      alert(error);
    }
  }

  handleAcceptRequest=async(event)=>{
    const request={
      project_id:event.target.value,
      type:event.target.name
    }
    try{
      const response = await ApiConnect.post('/api/project/colaborationviewer/requests/accept', request)
      this.updateSettingsData()
    }catch(error) {
      alert(error);
    }
  }

  handleCancelRequest=async(event)=>{
    const request={
      project_id:event.target.value,
      type:event.target.name
    }
    try{
      const response = await ApiConnect.post('/api/project/colaborationviewer/requests/cancel', request)
      this.updateSettingsData()
    }catch(error) {
      alert(error);
    }
  }

  render(){
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE.ColaborateView')
    const props={
      trsl:trsl,
      data:this.state.data,
      handleCancelRequest:this.handleCancelRequest,
      handleAcceptRequest:this.handleAcceptRequest,
      handleEditItem:this.props.handleEditItem,
    }
    function torender(data, i){
      return data[i];
      }  
    const pages ={
    true: <ToDisplay {...props} />,
    false: <LoaderWraper/>
    }
    const renderizing = torender(pages,this.state.loaded)

    return(
      <div>
        {renderizing}
      </div>
    )
  }
}

function LoaderWraper (props){
  return(
    <>
      <Loader loader_message="colaborates_view"/>
    </>
  )
}

class ToDisplay extends React.Component{
  render(){
    const props=this.props
    return(
      <>
      <Row className="blanktop_15">
        <Col xs={12}>
          <h3>{this.props.trsl['title']}</h3>
        </Col>
      </Row>
      <Row>
        <Colaboration {...props}/>
        <View {...props}/>
      </Row>
      </>
    )
  }
}

class Colaboration extends React.Component{
  render(){
    const props=this.props

    const item_holder = this.props.data.colaboration_requests
    let item_distr=this.props.trsl['no_projects_colaborate']
    if(item_holder.length>0){
      item_distr = item_holder.map((value) =>{
        return <SingleRequest trsl={this.props.trsl} data={value} name="colaboration" handleAcceptRequest={this.props.handleAcceptRequest} handleCancelRequest={this.props.handleCancelRequest}/>
      });
    }

    return(
      <>
        <Col xs={12} >
          <h5>{this.props.trsl['col_req']}</h5>
        </Col>
        <Col xs={12}>
          <Col xs={12} className="general_list">
            <ColaborationHeader {...props}/>
            {item_distr}
          </Col>
        </Col>
      </>
    )
  }
}

class View extends React.Component{
  render(){
    const props=this.props

    const item_holder = this.props.data.view_requests
    let item_distr=this.props.trsl['no_projects_view']
    if(item_holder.length>0){
      item_distr = item_holder.map((value) =>{
        return <SingleRequest trsl={this.props.trsl} data={value} name="view" handleAcceptRequest={this.props.handleAcceptRequest} handleCancelRequest={this.props.handleCancelRequest}/>
      });
    }

    return(
      <>
        <Col xs={12}>
          <h5>{this.props.trsl['view_req']}</h5>
        </Col>
        <Col xs={12}>
          <Col xs={12} className="general_list">
            <ViewHeader {...props}/>
            {item_distr}
          </Col>
        </Col>
      </>
    )
  }
}



class SingleRequest extends React.Component{
  render(){
    let status=this.props.trsl['active']
    let accept_button=""
    if(this.props.data.status===false){
      status= this.props.trsl['not_active']
      accept_button=<RegularButtonSmall bk="bk_success" label={this.props.trsl['accept']} name={this.props.name} value={this.props.data._id} onClick={this.props.handleAcceptRequest} />
    }
    return(
      <Row middle="xs" className="list_item">
        <Col xs={12} md={4}>
          {this.props.data.project_name}
        </Col>
        <Col xs={12} md={2}>
          {this.props.data.owner_name}
        </Col>
        <Col xs={12} md={2}>
          {this.props.data.owner_email}
        </Col>
        <Col xs={12} md={2}>
          {status}
        </Col>
        <Col xs={12} md={1}>
          {accept_button}
        </Col>
        <Col xs={12} md={1}>
          <RegularButtonSmall bk="bk_danger" label={this.props.trsl['cancel']} name={this.props.name} value={this.props.data._id} onClick={this.props.handleCancelRequest} />
        </Col>
      </Row>
    )
  }
}

function ColaborationHeader(props){
  return(
    <Row className="list_table_header_left">
    <Col xs={12} md={4} className="list_table_header_inside">
      {props.trsl['project_name']}
    </Col>
    <Col xs={12} md={2} className="list_table_header_inside">
      {props.trsl['project_owner']}
    </Col>
    <Col xs={12} md={2} className="list_table_header_inside">
      {props.trsl['email']}
    </Col>
    <Col xs={12} md={2} className="list_table_header_inside">
      {props.trsl['col_status']}
    </Col>
    <Col xs={12} md={2} className="list_table_header_inside">
      {props.trsl['manage_col']}
    </Col>
  </Row>
  )
}

function ViewHeader(props){
  return(
    <Row className="list_table_header_left">
    <Col xs={12} md={4} className="list_table_header_inside">
      {props.trsl['project_name']}
    </Col>
    <Col xs={12} md={2} className="list_table_header_inside">
      {props.trsl['project_owner']}
    </Col>
    <Col xs={12} md={2} className="list_table_header_inside">
      {props.trsl['email']}
    </Col>
    <Col xs={12} md={2} className="list_table_header_inside">
      {props.trsl['view_status']}
    </Col>
    <Col xs={12} md={2} className="list_table_header_inside">
      {props.trsl['manage_view']}
    </Col>
  </Row>
  )
}

export {
  ColaborateRequestNotification
}