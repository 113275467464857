import React from 'react';
import ApiConnect from 'CONFIG/axios';

import {SinglesFeasibilityListItems} from '../feasibility_items';
import {RevenueCreator} from '../../../../CONFIG/CREATORS/revenue_creator';


class RevenuesList extends React.Component{

  handleAdd =async(item)=>{
    let years=this.props.feasibility_settings.number_of_years
    if(this.props.feasibility_settings.use_historic===true){
      years=parseInt(this.props.feasibility_settings.number_of_years)+parseInt(this.props.feasibility_settings.number_of_historic_years)
    }
    const element_to_add=new RevenueCreator(
      item,
      years,
      this.props.feasibility_settings.vat,
      this.props.feasibility_settings.raw_materials
    )
    this.props.handleLists.handleAdd(element_to_add, 'revenues')
  } 
        
  render(){
    return(
      <>
        <SinglesFeasibilityListItems 
          data={this.props.version_data.revenues} 
          feasibility_settings={this.props.feasibility_settings}
          user_permission={this.props.user_permission}
          handleAdd={this.handleAdd} 
          handleScreenNavigation={this.props.handleScreenNavigation} 
          handleDeleteItem={this.props.handleLists.handleDeleteItem}
          handleDuplicateItem={this.props.handleLists.handleDuplicateItem}
          handleChangLevel={this.props.handleLists.handleChangLevel}
          title='rev_title'
          add_item='add_rev'
          item_type="revenues"
        />
      </>
    )
  }
}

export default RevenuesList;