import React from 'react';

import {AssumptionHeaderSaveReturn } from 'PRIVATE/PROJECT/Feasibility/feasibility_items';

import { Row, Col } from 'Support/grid';
import {FormEdit, NonEditableForm, PeriodSelect, PeriodHeader, SingleInputFormFlat, Observations} from 'Support/inputforms'
import ApiConnect from 'CONFIG/axios';
import UserContext from 'CONFIG/auth';
import Loader from 'Support/loader';
import {calculateOperatingExpenses} from 'CALCULATIONS/cal_operating_expenses';

class OperatingExpEntry extends React.Component {
  static contextType = UserContext

  constructor(props) {
    super(props);
    this.state = {
      data: "",
      inflation:"",
      loaded:false,
      years:this.props.feasibility_settings.historic_years.concat(this.props.feasibility_settings.years)
    }
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleObservationsChange = this.handleObservationsChange.bind(this);
    this.handlePeriodChange = this.handlePeriodChange.bind(this);
  }

  componentDidMount= async()=>{
    const request={
    project_id:this.props.project_id,
    version_id:this.props.version_id,
    user_id:this.props.user_id,
    item_id:this.props.active_item,
    item_type:"operating_expenses"
    }
    try {
      const response = await ApiConnect.post('/api/multitem/unique/get', request)
        this.setState({
          data:response.data.item,
          inflation:response.data.version_inflation,
          loaded:true
        })
    } catch(error) {
        alert(error);
    }
  }

  handleEditChange=(id, item)=>{
    let go = this.state.data;
    go[id]=item;
    calculateOperatingExpenses(go, this.props.feasibility_settings,this.state.inflation)
    this.setState({data:go})
  }

  handleNameChange(event){
    const value = event.target.value;
    let go = this.state.data;
    go.op_exp_name=value;
    this.setState({data:go })
  }

  handlePeriodChange(event){
    const value = event.target.value;
    let go = this.state.data;
    go.op_exp_period_type=value;
    calculateOperatingExpenses(go, this.props.feasibility_settings,this.state.inflation)
    this.setState({data:go})
  }

  handleObservationsChange(event){
    const value = event.target.value;
    let go = this.state.data;
    go.op_exp_obs=value;
    this.setState({data:go })
  }

  async handleSave(){
    const request={
      user_id:this.props.user_id,
      project_id:this.props.project_id,
      version_id:this.props.version_id,
      item_id:this.props.active_item,
      item_type:"operating_expenses",
      content:this.state.data
    }
    try{
      const response= await ApiConnect.post('/api/multitem/item/modify', request)
      this.props.changeScreenView("list",0)
      this.props.handleLists.handleSaveUpdater(response.data,'operating_expenses')
    } catch(error) {
        alert(error);
    }
  }

  render() {
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_PROJECT.PROJECT_FEASIBILITY.Individual_edits')

    const props ={
      trsl:trsl,
      handleScreenNavigation:this.props.handleScreenNavigation,
      handleEditChange:this.handleEditChange,
      handleSave:this.handleSave,
      handleNameChange:this.handleNameChange,
      handlePeriodChange:this.handlePeriodChange,
      handleObservationsChange:this.handleObservationsChange,
      data:this.state.data,
      years:this.state.years,
      feasibility_settings:this.props.feasibility_settings,
      user_permission:this.props.user_permission
  }

    function torender(data, i){
      return data[i];
    }  
    const pages ={
      true: <ToDisplay {...props}/>,
      false: <Loader loader_message="op_exp_edit"/>
    }
    var i = this.state.loaded
    const renderizing = torender(pages,i)

    return(
      <>
        {renderizing}
      </>
    )
  }
}

class ToDisplay extends React.Component {
  render(){
    const props=this.props
    return(
      <>
        <GlobalHeader {...props}/>
        <Row>
          <Col xs={12} >
            <PeriodHeader label={this.props.trsl['year']} periods={this.props.years}/>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="feasibility_list">
            <ValueEdit {...props}/>
            <ValueInflated {...props} />
            <VatEdit {...props} />
            <TotalExpense {...props}/>
            <VatValue {...props}/>
          </Col>
          <Col xs={12} className="feasibility_list">
            <Observations className="feasibility_item_list" name="op_exp_obs" value={this.props.data.op_exp_obs} label="Observations" handleObservationsChange={this.props.handleObservationsChange}/>
          </Col>
        </Row>
      </>
    );
  }
}

class GlobalHeader extends React.Component{
  render(){
    return(
      <>
        <AssumptionHeaderSaveReturn title={this.props.trsl['op_exp_header']} user_permission={this.props.user_permission} back={this.props.trsl['back_button']} save={this.props.trsl['save_back_button']} handleSave={this.props.handleSave} handleScreenNavigation={this.props.handleScreenNavigation}/>
        <Row className="feasibility_edit_globalheader_row" > 
            <SingleInputFormFlat xs={12} md={12}value={this.props.data.op_exp_name} label={this.props.trsl['op_exp_name']} handleEditItemChange={this.props.handleNameChange}/>
            <PeriodSelect xs={12} md={12} label={this.props.trsl['op_exp_quantities']} name="op_exp_period_type" handlePeriodChange={this.props.handlePeriodChange} selected={this.props.data.op_exp_period_type}/>
        </Row>
      </>
    );
  }
}

class ValueEdit extends React.Component{
  render(){ 
    return(
      <FormEdit 
      use_historic={this.props.feasibility_settings.use_historic}
      item_holder={this.props.data.op_exp_value}
      id="op_exp_value"
      handleForm={this.props.handleEditChange}
      label={this.props.trsl['op_exp_value']}
      unit={new Intl.NumberFormat('ja-JP', { style: 'currency', currency: this.props.feasibility_settings.currency}).format(0).replace(/\d./g,"")}
      />
    );
  }
}

class ValueInflated extends React.Component{
  render(){ 
    return(
      <NonEditableForm
      item_holder={this.props.data._op_exp_value_inflated}
      label={this.props.trsl['op_exp_value_inflated']}
      value_type="currency"
      currency={this.props.feasibility_settings.currency}
      />
    );
  }
}

class VatEdit extends React.Component{
  render(){
    const vatRendering=(input)=>{
      if(input===false){
        return(
          <FormEdit 
          use_historic={this.props.feasibility_settings.use_historic}
          item_holder={this.props.data.op_exp_vat_rate}
          id="op_exp_vat_rate"
          handleForm={this.props.handleEditChange}
          label={this.props.trsl['vat_rate']}
          unit="%"
          />
        )
      }
    }
    let renderizing=""
    renderizing=vatRendering(this.props.feasibility_settings.use_general_vat_rate)
    return(
      <>
        {renderizing}
      </>
    );
  }
}


class TotalExpense extends React.Component{
  render(){ 
    return(
      <NonEditableForm
      item_holder={this.props.data._op_exp_total}
      label={this.props.trsl['op_exp_total']}
      value_type="currency"
      currency={this.props.feasibility_settings.currency}
      />
    );
  }
}

class VatValue extends React.Component{
  render(){ 
    return(
      <NonEditableForm
      item_holder={this.props.data._op_exp_vat_total}
      label={this.props.trsl['vat_total']}
      value_type="currency"
      currency={this.props.feasibility_settings.currency}
      />
    );
  }
}

export default OperatingExpEntry;