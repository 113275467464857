import React from 'react';
import {AssumptionHeaderSaveReturn} from 'PRIVATE/PROJECT/Feasibility/feasibility_items';
import { Row, Col } from 'Support/grid';
import {FormEdit, NonEditableForm, PeriodHeader, SingleInputFormFlat, SingleInputNumberForm, Observations} from 'Support/inputforms'
import ApiConnect from 'CONFIG/axios';
import UserContext from 'CONFIG/auth';
import Loader from 'Support/loader';
import {calculateShareholders} from 'CALCULATIONS/cal_shareholders';
import { ToogleButtons } from 'Support/toogle_buttons';

class ShareholdersEntry extends React.Component {
  static contextType = UserContext

  constructor(props) {
    super(props);
    this.state = {
      data: "",
      loaded:false,
      years:this.props.feasibility_settings.historic_years.concat(this.props.feasibility_settings.years)
    }
    this.handleEditInterestChange=this.handleEditInterestChange.bind(this)
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleObservationsChange = this.handleObservationsChange.bind(this);
  }

  componentDidMount= async()=>{
    const request={
    project_id:this.props.project_id,
    version_id:this.props.version_id,
    user_id:this.props.user_id,
    item_id:this.props.active_item,
    item_type:"shareholders"
    }
    try {
      const response = await ApiConnect.post('/api/multitem/unique/get', request)
        this.setState({
          data:response.data.item,
          loaded:true
        })
    } catch(error) {
        alert(error);
    }
  }

  handleEditChange=(id, item)=>{
    let go = this.state.data;
    go[id]=item;
    calculateShareholders(go)
    this.setState({data:go})
  }
  handleEditInterestChange=async(event)=>{
    const name = event.target.name;
    const value = event.target.value;
    let go = this.state.data;
    go[name]=value;
    calculateShareholders(go)
    this.setState({data:go})
  }

  handleSharePremium=(event)=>{
    let val= event.target.value
    if(val=="false"){val=false} else {val=true}
    let go = this.state.data;
    go.shareholders_premium_option=val;
    calculateShareholders(go, this.props.feasibility_settings)
    this.setState({data:go })
  }


  handleNameChange(event){
    const value = event.target.value;
    let go = this.state.data;
    go.shareholders_item_name=value;
    this.setState({data:go })
  }

  handleObservationsChange(event){
    const value = event.target.value;
    let go = this.state.data;
    go.shareholders_obs=value;
    this.setState({data:go })
  }

  async handleSave(){
    const request={
      user_id:this.props.user_id,
      project_id:this.props.project_id,
      version_id:this.props.version_id,
      item_id:this.props.active_item,
      item_type:"shareholders",
      content:this.state.data
    }
    try{
      const response = await ApiConnect.post('/api/multitem/item/modify', request)
      this.props.changeScreenView("list",0)
      this.props.handleLists.handleSaveUpdater(response.data,'shareholders')
    } catch(error) {
        alert(error);
    }
  }

  render() {
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_PROJECT.PROJECT_FEASIBILITY.Individual_edits')

    const props ={
      trsl:trsl,
      handleScreenNavigation:this.props.handleScreenNavigation,
      handleEditChange:this.handleEditChange,
      handleEditInterestChange:this.handleEditInterestChange,
      handleSharePremium:this.handleSharePremium,
      handleSave:this.handleSave,
      handleNameChange:this.handleNameChange,
      handleObservationsChange:this.handleObservationsChange,
      data:this.state.data,
      years:this.state.years,
      feasibility_settings:this.props.feasibility_settings,
      user_permission:this.props.user_permission,
      multi_option:this.props.multi_option,
      multitabs:this.props.multitabs,
  }

    function torender(data, i){
      return data[i];
    }  
    const pages ={
      true: 
      <ToDisplay {...props}/>,
      
      false: 
      <Loader loader_message="sh_edit"/>
    }
    var i = this.state.loaded
    const renderizing = torender(pages,i)

    return(
      <div>
        {renderizing}
      </div>
    )
  }
}

class ToDisplay extends React.Component {
  render(){
    const props=this.props

    function showShareholderShow(type){
      if(type==='shareholders_loan'){
        return(
          <ShareholdersLoan {...props}/>
        )
      } else if(type==='issued_capital'){
        return(
          <IssuedCapital {...props}/>
        )
      } else if(type==='other_equity_instruments'){
        return(
          <OtherEquityInstruments {...props}/>
        )
      }
    }
    const render_shareholders=showShareholderShow(this.props.multi_option)

    return(
      <>
        {render_shareholders}
      </>
    )
  }
}

class IssuedCapital extends React.Component{
  render(){
    const props=this.props

    function showSharePremium(option){
      if(option===true){
        return(
          <>
            <Row >
              <Col xs={12} >
                <PeriodHeader label={props.trsl['year']} periods={props.years}/>
              </Col>
              <Col xs={12}>
                <IssueStockPremium {...props}/>
                <SharePremiumRate {...props}/>
              </Col>
            </Row>
          </>
        )
      }
    }
    const show_sharepremium=showSharePremium(this.props.data.shareholders_premium_option)
    
    return(
      <>
        <GlobalHeader {...props}/>
        <Row >
          <Col xs={12} >
            <PeriodHeader label={this.props.trsl['year']} periods={props.years}/>
          </Col>
          <Col xs={12}>
            <IncreaseEdit {...props}/>
            <DecreaseEdit  {...props}/>  
            <AccumulatedIC  {...props}/>
          </Col>
          <Col xs={12} className="feasibility_edits_break_row">
            <SharePremiumOption {...props}/>
          </Col>
        </Row>
        {show_sharepremium}
        <Row>
          <Col xs={12}>
            <Observations name="shareholders_obs" value={this.props.data.shareholders_obs} label="Observations" handleObservationsChange={this.props.handleObservationsChange}/>
          </Col>
        </Row>
      </>
    );
  }
}

class OtherEquityInstruments extends React.Component{
  render(){
    const props=this.props
    
    return(
      <>
        <GlobalHeader {...props}/>
        <Row>
          <Col xs={12} >
            <PeriodHeader label={this.props.trsl['year']} periods={this.props.feasibility_settings.years}/>
          </Col>
          <Col xs={12}>
            <IncreaseEdit {...props}/>
            <DecreaseEdit  {...props}/>  
            <AccumulatedOEI  {...props}/>
          </Col>
          <Col xs={12}>
            <Observations name="shareholders_obs" value={this.props.data.shareholders_obs} label="Observations" handleObservationsChange={this.props.handleObservationsChange}/>
          </Col>
        </Row>
      </>
    );
  }
}

class ShareholdersLoan extends React.Component{
  render(){
    const props=this.props
    
    return(
      <>
        <GlobalHeader {...props}/>
        <Row>
          <ShareholderInterstRate {...props}/>
        </Row>
        <Row>
          <Col xs={12} >
            <PeriodHeader label={this.props.trsl['year']} periods={this.props.feasibility_settings.years}/>
          </Col>
          <Col xs={12}>
            <IncreaseEdit {...props}/>
            <DecreaseEdit  {...props}/>  
            <AccumulatedSL  {...props}/>
            <ShareholderLoanInterest {...props}/>
          </Col>
          <Col xs={12}>
            <Observations name="shareholders_obs" value={this.props.data.shareholders_obs} label="Observations" handleObservationsChange={this.props.handleObservationsChange}/>
          </Col>
        </Row>
      </>
    );
  }
}

class GlobalHeader extends React.Component{
  render(){
    const item_holder=this.props.multitabs
    const selected=this.props.multi_option
    const element_name =item_holder.find(el => el.type.indexOf(selected) !== -1).item_trsl
    
    return(
      <>
        <AssumptionHeaderSaveReturn title={this.props.trsl[element_name]} user_permission={this.props.user_permission} back={this.props.trsl['back_button']} save={this.props.trsl['save_back_button']} handleSave={this.props.handleSave} handleScreenNavigation={this.props.handleScreenNavigation}/>
        <Row className="feasibility_edit_globalheader_row" middle="xs">
            <SingleInputFormFlat xs={12} md={12}
            value={this.props.data.shareholders_item_name} 
            label= {this.props.trsl['sh_description']} 
            handleEditItemChange={this.props.handleNameChange}/>
        </Row>
      </>
    );
  }
}

class IncreaseEdit extends React.Component{
  render(){ 
    return(
      <FormEdit 
      use_historic={this.props.feasibility_settings.use_historic}
      item_holder={this.props.data.shareholders_increase}
      id="shareholders_increase"
      handleForm={this.props.handleEditChange}
      label={this.props.trsl['sh_increase']}
      unit={new Intl.NumberFormat('ja-JP', { style: 'currency', currency: this.props.feasibility_settings.currency}).format(0).replace(/\d./g,"")}
      />
    );
  }
}

class DecreaseEdit extends React.Component{
  render(){ 
    return(
      <FormEdit 
      use_historic={this.props.feasibility_settings.use_historic}
      item_holder={this.props.data.shareholders_decrease}
      id="shareholders_decrease"
      handleForm={this.props.handleEditChange}
      label={this.props.trsl['sh_decrease']}
      unit={new Intl.NumberFormat('ja-JP', { style: 'currency', currency: this.props.feasibility_settings.currency}).format(0).replace(/\d./g,"")}
      />
    );
  }
}

class SharePremiumOption extends React.Component{
  render(){
    const toogle_data=[
      {option_label:this.props.trsl['sh_share_premium_option_2'],value:false},
      {option_label:this.props.trsl['sh_share_premium_option_1'],value:true}
    ]
    return(
      <>
        <Row>
          <Col xs={4}>
            <h4>{this.props.trsl['sh_share_premium_option']}</h4>
          </Col>
            <ToogleButtons
            xs={12}
            label={this.props.trsl['sh_share_premium_option_message']}
            value={this.props.value}
            toogle_data={toogle_data}
            selected={this.props.data.shareholders_premium_option}
            handleToogleButton={this.props.handleSharePremium}/>
        </Row>
      </>  
    )
  }
}
class IssueStockPremium extends React.Component{
  render(){ 
    return(
      <FormEdit 
      use_historic={this.props.feasibility_settings.use_historic}
      item_holder={this.props.data.shareholders_share_premium}
      id="shareholders_share_premium"
      handleForm={this.props.handleEditChange}
      label={this.props.trsl['sh_share_premium_value']}
      unit={new Intl.NumberFormat('ja-JP', { style: 'currency', currency: this.props.feasibility_settings.currency}).format(0).replace(/\d./g,"")}
      />
    );
  }
}

class ShareholderInterstRate extends React.Component{
  render(){ 
    return(
      <SingleInputNumberForm xs={2} md={3}
      name="shareholders_loan_interest_rate"
      value={this.props.data.shareholders_loan_interest_rate}
      handleinputeditChange={this.props.handleEditInterestChange}
      label={this.props.trsl['sh_interest_rate']}
      unit='%'
      />
    );
  }
}
class ShareholderLoanInterest extends React.Component{
  render(){ 
    return(
      <NonEditableForm
      item_holder={this.props.data._shareholders_loan_interest}
      label={this.props.trsl['sh_interest']}
      value_type="currency"
      currency={this.props.feasibility_settings.currency}
      />
    );
  }
}

class SharePremiumRate extends React.Component{
  render(){ 
    return(
      <NonEditableForm
      item_holder={this.props.data._shareholders_share_premium_rate}
      label={this.props.trsl['sh_share_premium_rate']}
      value_type="decimal"
      currency={this.props.feasibility_settings.currency}
      />
    );
  }
}


class AccumulatedIC extends React.Component{
  render(){ 
    return(
      <NonEditableForm
      item_holder={this.props.data._shareholders_total}
      label={this.props.trsl['sh_accumulated_ic']}
      value_type="currency"
      currency={this.props.feasibility_settings.currency}
      />
    );
  }
}

class AccumulatedOEI extends React.Component{
  render(){ 
    return(
      <NonEditableForm
      item_holder={this.props.data._shareholders_total}
      label={this.props.trsl['sh_accumulated_oei']}
      value_type="currency"
      currency={this.props.feasibility_settings.currency}
      />
    );
  }
}

class AccumulatedSL extends React.Component{
  render(){ 
    return(
      <NonEditableForm
      item_holder={this.props.data._shareholders_total}
      label={this.props.trsl['sh_accumulated_sl']}
      value_type="currency"
      currency={this.props.feasibility_settings.currency}
      />
    );
  }
}

export default ShareholdersEntry;