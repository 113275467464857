import React from 'react';
import { Row, Col } from 'Support/grid'
import {RegularButton} from 'Support/listbuttons';
import {MakeSlideBusinessPlanCover} from './slides/covers';
import { MakeSlideCanvas } from './slides/canvas';
import pptxgen from "pptxgenjs";
import Templates from "./ppt_master_slides"
import {multiply,add, dotMultiply, dotDivide, round, ceil} from "mathjs"


function FeasibilityAnalysisPowerPoint(props){
  let pptx = new pptxgen();

  pptx.author = 'CASFLO';
  pptx.company = 'CASFLO';

  let theme
  if(props.org==='viseeon'){
    theme=new Templates.viseeon()
  } else if (props.org==='bazaar') {
    theme=new Templates.bazaar()
  } else{
    theme=new Templates.casflo[props.selected_template]()
  }

  pptx.defineSlideMaster(theme.cover)
  pptx.defineSlideMaster(theme.separator)
  pptx.defineSlideMaster(theme.no_title)
  pptx.defineSlideMaster(theme.one_title)
  pptx.defineSlideMaster(theme.two_title)
  pptx.defineSlideMaster(theme.end)
  

  function TableRowL2(trsl,values){
    return(
      rowCreator(trsl,values,theme.cs.darkshade,9,'ffffff')
    )
  }

  function addBulletText(input){
    const bulletizer=input.map(value =>{
      return {text: value,options:{breakLine:true}} 
    })
    return bulletizer
  }
  
  function rowCreator(label,inputs,bkcolor,fsize,tcolor,font){
    const table_row=[]
    const col_size=(3/inputs.length)
    table_row[0]={text: label, options: { align: "left", fill:bkcolor, colW:1,  fontSize:fsize,color:tcolor,font: font, valign:"middle"}}
    for(let i=1;i<(inputs.length+1);i++){
      table_row[i]={text: editValues(inputs[i-1],'en'), options: { align: "right", fill:bkcolor, colW:col_size, fontSize:fsize, color:tcolor, valign:"middle"}}
    }
    return table_row
  }

  function rowCreatorForPercentage(label,inputs,bkcolor,fsize,tcolor,font){
    const table_row=[]
    const col_size=(3/inputs.length)
    table_row[0]={text: label, options: { align: "left", fill:bkcolor, colW:1,  fontSize:fsize,color:tcolor,font: font, valign:"middle" }}
    for(let i=1;i<(inputs.length+1);i++){
      table_row[i]={text: editPercentageValues(inputs[i-1],'en'), options: { align: "right", fill:bkcolor, colW:col_size, fontSize:fsize, color:tcolor, valign:"middle"}}
    }
    return table_row
  }
  
  function rowCreatorForHeader(label,inputs,bkcolor,fsize,tcolor){
    const table_row=[]
    const col_size=(3/inputs.length)
    table_row[0]={text: label, options: {align: "left", fill:bkcolor,  colW:1, fontSize:fsize, color:tcolor, valign:"middle", border:{type:'dash',color:theme.cs.primary,pt:.5}}}
    for(let i=1;i<(inputs.length+1);i++){
      table_row[i]={text: (inputs[i-1]).toFixed(0), options: {align: "center",  fill:bkcolor, colW:col_size, fontSize:fsize, color:tcolor, border:{type:'dash',color:theme.cs.primary,pt:.5}}}
    }
    return table_row
  }
  
  function sizeOnizer(inputs){
    const table_row_size=[]
    table_row_size[0]=2.5
    for(let i=1;i<(inputs.length+1);i++){
      table_row_size[i]=(7/inputs.length)
    }
    return table_row_size
  }
  
  function TableHeader(trsl,years,color){
    return(
      rowCreatorForHeader(trsl['year'],years,'',12,color,)
    )
  }
  
  function TableRowL1(trsl,values){
    return(
      rowCreator(trsl,values,theme.cs.primary,8,theme.cs.lighttext)
    )
  }
  
  function TableRow(trsl,values,tcolor){
    return(
      rowCreator(trsl,values,'',8,tcolor, 'Lato')
    )
  }

  function TableRowPercentage(trsl,values,tcolor){
    return(
      rowCreatorForPercentage(trsl,values,'',8,tcolor, 'Lato')
    )
  }

  function TableRowDecimals(trsl,values,tcolor){
    let n_arr=dotMultiply(values,100)
    return(
      rowCreator(trsl,n_arr,'',8,tcolor,2)
    )
  }


  //SLIDE 1 - COVER
  MakeSlideBusinessPlanCover(pptx, props)

  ////////CANVAS////
  MakeSlideCanvas(pptx, props, theme)


  ////////FEASIBILITY EXPORTS!!!!////
  if(props.data.feasibility!== null){
    //FEASIBILITY Introduction
    var feasibility_slide = pptx.addSlide('SEPARATOR');
    feasibility_slide.addText(
      props.trsl['feasibility_and_bm'],{ placeholder: "title1" }
    )
  }

  //HISTORIC DATA!!!!
  if(props.data.feasibility!== null && props.options.general.historic===true){
    const h_year_arr=[0,0,0]
    for(var i=0;i<h_year_arr.length;i++){
      h_year_arr[i]=parseFloat(props.years[0])+parseFloat([i])-3
    }
    //HISTORIC INTRODUCTION SLIDE
    var hist_slide = pptx.addSlide('NO TITLE');
    hist_slide.addText(
      props.trsl['historic_data'],{ x:0, y:1, w:'90%', h:2.5, align:'right', color:theme.cs.primary, fontSize:30, fontFace: theme.ft.head  }
    )
    //INCOME STATEMENT SLIDE
    var h_income_statemente_slide = pptx.addSlide("ONE TITLE");
    h_income_statemente_slide.addText(props.trsl['income_st'], { placeholder: "title1" })
    let is_rows = [
      TableHeader (props.trsl, h_year_arr,theme.cs.darkaccent),
      TableRow ( props.trsl['revenues'], props.data.feasibility.feasibility_historic.h_inc_st.h_revenue,theme.cs.darktext),
      TableRow ( props.trsl['subsidies'],  props.data.feasibility.feasibility_historic.h_inc_st.h_operational_grants,theme.cs.darktext),
      TableRow ( props.trsl['cogs'],   props.data.feasibility.feasibility_historic.h_inc_st.h_cogs,theme.cs.darktext),
      TableRow ( props.trsl['operating_exp'], props.data.feasibility.feasibility_historic.h_inc_st.h_operating_expenses,theme.cs.darktext),
      TableRow ( props.trsl['employees'],   props.data.feasibility.feasibility_historic.h_inc_st.h_hr,theme.cs.darktext),
      TableRow ( props.trsl['other_revenues'], props.data.feasibility.feasibility_historic.h_inc_st.h_other_gains,theme.cs.darktext),
      TableRow ( props.trsl['other_costs'], props.data.feasibility.feasibility_historic.h_inc_st.h_other_costs,theme.cs.darktext),
      TableRowL1 ( props.trsl['ebitda'],props.data.feasibility.feasibility_historic.h_inc_st.h_ebitda,theme.cs.darktext),
      TableRow ( props.trsl['depreciations'], props.data.feasibility.feasibility_historic.h_inc_st.h_depreciations,theme.cs.darktext),
      TableRowL1 ( props.trsl['ebit'], props.data.feasibility.feasibility_historic.h_inc_st.h_ebit,theme.cs.darktext),
      TableRow ( props.trsl['financing_costs'], props.data.feasibility.feasibility_historic.h_inc_st.h_interest_cost,theme.cs.darktext),
      TableRowL1 ( props.trsl['ebt'], props.data.feasibility.feasibility_historic.h_inc_st.h_ebt,theme.cs.darktext),
      TableRow ( props.trsl['corporate_tax'], props.data.feasibility.feasibility_historic.h_inc_st.h_corporate_tax,theme.cs.darktext),
      TableRowL2 ( props.trsl['net_income'], props.data.feasibility.feasibility_historic.h_inc_st.h_net_profit,theme.cs.darktext)    
    ];
    h_income_statemente_slide.addTable(is_rows, { x: .2, y: 0.8, fontFace: theme.ft.body, colW:sizeOnizer(h_year_arr) }); 


      var h_assets_slide = pptx.addSlide("ONE TITLE");
      h_assets_slide.addText(props.trsl['balance_sheet'], { placeholder: "title1" })
      let h_assets_rows = [
        TableHeader (props.trsl, h_year_arr, theme.cs.darkaccent),
        TableRow (props.trsl['tangible'], props.data.feasibility.feasibility_historic.h_bal.h_tangible_assets,theme.cs.darktext),
        TableRow (props.trsl['intangible'], props.data.feasibility.feasibility_historic.h_bal.h_intangible_assets,theme.cs.darktext),
        TableRow (props.trsl['other_non_assets'], props.data.feasibility.feasibility_historic.h_bal.h_other_non_current_assets,theme.cs.darktext),
        TableRowL1 (props.trsl['non_current_assets'], props.data.feasibility.feasibility_historic.h_bal.h_total_non_current_assets,theme.cs.darktext),
        TableRow (props.trsl['iventories'], props.data.feasibility.feasibility_historic.h_bal.h_inventories,theme.cs.darktext),
        TableRow (props.trsl['clients'], props.data.feasibility.feasibility_historic.h_bal.h_clients,theme.cs.darktext),
        TableRow (props.trsl['gov_receivables'], props.data.feasibility.feasibility_historic.h_bal.h_government_receivable,theme.cs.darktext),
        TableRow (props.trsl['other_current_assets'], props.data.feasibility.feasibility_historic.h_bal.h_other_current_assets,theme.cs.darktext),
        TableRow (props.trsl['cash'], props.data.feasibility.feasibility_historic.h_bal.h_cash,theme.cs.darktext),
        TableRowL1 (props.trsl['current_assets'], props.data.feasibility.feasibility_historic.h_bal.h_total_current_assets,theme.cs.darktext),
        TableRowL2 (props.trsl['total_assets'], props.data.feasibility.feasibility_historic.h_bal.h_total_assets,theme.cs.darktext),

      ];
      h_assets_slide.addTable(h_assets_rows, { x: .2, y: 0.8, fontFace: theme.ft.body, colW:sizeOnizer(h_year_arr) });

      //BALANCE - EQUITY + LIABILITIES SLIDE
      var h_eq_lia_slide = pptx.addSlide("ONE TITLE");
      h_eq_lia_slide.addText(props.trsl['balance_sheet'], { placeholder: "title1" })
      let h_eq_lia_rows = [
        TableHeader (props.trsl, h_year_arr, theme.cs.darkaccent),
        TableRow (props.trsl['issued_capital'], props.data.feasibility.feasibility_historic.h_bal.h_issued_capital,theme.cs.darktext),
        TableRow (props.trsl['other_equity_instr'], props.data.feasibility.feasibility_historic.h_bal.h_other_equity_instruments,theme.cs.darktext),
        TableRow (props.trsl['other_equity_chan'], props.data.feasibility.feasibility_historic.h_bal.h_other_equity_changes,theme.cs.darktext),
        TableRow (props.trsl['past_results'], props.data.feasibility.feasibility_historic.h_bal.h_past_results,theme.cs.darktext),
        TableRow (props.trsl['net_income'], props.data.feasibility.feasibility_historic.h_inc_st.h_net_profit,theme.cs.darktext),
        TableRowL2 (props.trsl['total_equity'], props.data.feasibility.feasibility_historic.h_bal.h_total_equity,theme.cs.darktext),
        TableRow (props.trsl['debt'], props.data.feasibility.feasibility_historic.h_bal.h_debt,theme.cs.darktext),
        TableRow (props.trsl['other_non_liabilities'], props.data.feasibility.feasibility_historic.h_bal.h_other_non_current_liabilities,theme.cs.darktext),
        TableRowL1 (props.trsl['non_current_liabilities'], props.data.feasibility.feasibility_historic.h_bal.h_total_non_current_liabilities),
        TableRow (props.trsl['suppliers'], props.data.feasibility.feasibility_historic.h_bal.h_suppliers,theme.cs.darktext),
        TableRow (props.trsl['gov_payables'], props.data.feasibility.feasibility_historic.h_bal.h_government_payable,theme.cs.darktext),
        TableRow (props.trsl['shareholders_loan'], props.data.feasibility.feasibility_historic.h_bal.h_shareholders_loan,theme.cs.darktext),
        TableRow (props.trsl['other_payables'], props.data.feasibility.feasibility_historic.h_bal.h_other_accounts_payables,theme.cs.darktext),
        TableRow (props.trsl['other_current_liabilities'], props.data.feasibility.feasibility_historic.h_bal.h_other_current_liabilities,theme.cs.darktext),
        TableRow (props.trsl['current_liabilities'], props.data.feasibility.feasibility_historic.h_bal.h_total_current_liabilities,theme.cs.darktext),
        TableRowL1 (props.trsl['total_liabilities'], props.data.feasibility.feasibility_historic.h_bal.h_total_liabilities,theme.cs.darktext),
        TableRowL2 (props.trsl['equity_liabilities'], props.data.feasibility.feasibility_historic.h_bal.h_equity_liabilities,theme.cs.darktext),
      ];
      h_eq_lia_slide.addTable(h_eq_lia_rows, { x: .2, y: 0.8, fontFace: theme.ft.body, colW:sizeOnizer(h_year_arr) });

      var h_profitability_slide = pptx.addSlide("ONE TITLE");
      h_profitability_slide.addText(props.trsl['ratios'], { placeholder: "title1" })
      let h_profitability_rows = [
        TableHeader (props.trsl, h_year_arr, theme.cs.darkaccent),
        TableRowDecimals (props.trsl['gross_margin'], props.data.feasibility.feasibility_historic.h_ratios.h_gross_margin,theme.cs.darktext),
        TableRowDecimals (props.trsl['net_margin'], props.data.feasibility.feasibility_historic.h_ratios.h_net_margin,theme.cs.darktext),
        TableRowDecimals (props.trsl['return_on_sales'], props.data.feasibility.feasibility_historic.h_ratios.h_ros,theme.cs.darktext),
        TableRowDecimals (props.trsl['return_on_assets'], props.data.feasibility.feasibility_historic.h_ratios.h_roa,theme.cs.darktext),
        TableRowDecimals (props.trsl['return_on_equity'], props.data.feasibility.feasibility_historic.h_ratios.h_roe,theme.cs.darktext),
        TableRowDecimals (props.trsl['equity_ratio'], props.data.feasibility.feasibility_historic.h_ratios.h_equity_ratio,theme.cs.darktext),
        TableRowDecimals (props.trsl['average_cost_of_debt'], props.data.feasibility.feasibility_historic.h_ratios.h_average_cost_of_debt,theme.cs.darktext),
        TableRowDecimals (props.trsl['interest_coverage'], props.data.feasibility.feasibility_historic.h_ratios.h_interest_coverage,theme.cs.darktext),
        TableRow (props.trsl['dar'], props.data.feasibility.feasibility_historic.h_ratios.h_dar,theme.cs.darktext),
        TableRow (props.trsl['dap'], props.data.feasibility.feasibility_historic.h_ratios.h_dap,theme.cs.darktext),
        TableRow (props.trsl['dos'], props.data.feasibility.feasibility_historic.h_ratios.h_dos,theme.cs.darktext),
      ];
      h_profitability_slide.addTable(h_profitability_rows, { x: .2, y: 0.8, fontFace: theme.ft.body, colW:sizeOnizer(h_year_arr) });
    }

  //RUNNING TRHROUGH VERSIONS

  const periods_with_history= JSON.parse(JSON.stringify(props.years))
  if(props.settings.use_historic===true){
    periods_with_history.splice(0,'',periods_with_history[0]-1)
  }

  const periods = JSON.parse(JSON.stringify(props.years))

  if(props.data.feasibility!== null && props.data.feasibility.feasibility_versions.length>0){
    for(var i=0;i<props.data.feasibility.feasibility_versions.length;i++){
      if(props.selected_versions.includes(i.toString())){
        let v_data=props.data.feasibility.feasibility_versions[i]

        //VERSION Introduction
        var v_slide = pptx.addSlide('NO TITLE');
        v_slide.addText(
          props.trsl['version']+': ' + v_data.version_name,{ x:0, y:1, w:'90%', h:2.5, align:'right', color:theme.cs.primary, fontSize:30, fontFace: theme.ft.head  }
        )
        v_slide.addText(
          props.trsl['forecast']+': ' + props.years.length +' ' + props.trsl['years'] ,{ x:0, y:3.5, w:'90%', h:0.5, align:'right', color:theme.cs.primary, fontSize:16, fontFace:"Lato "}
        )
        v_slide.addText(
          props.trsl['values_in']+ props.settings.currency,{ x:0, y:4, w:'90%', h:0.5, align:'right', color:theme.cs.primary, fontSize:12, fontFace: theme.ft.body  }
        )

        var settings_slide = pptx.addSlide('ONE TITLE');
        settings_slide.addText(
          props.trsl['general_assumptions'],{ placeholder: "title1" })
          let v_settings_rows = [
            TableHeader (props.trsl, periods_with_history, theme.cs.darkaccent),
            TableRowPercentage ( props.trsl['inflation'], dotDivide(v_data.version_settings.inflation, 100), theme.cs.darktext),
            TableRow ( props.trsl['vs_dar'], v_data.version_settings.days_accounts_receivables, theme.cs.darktext),
            TableRow ( props.trsl['vs_dap'], v_data.version_settings.days_accounts_payables, theme.cs.darktext),
            TableRow ( props.trsl['vs_dip'], v_data.version_settings.investment_days_accounts_payables, theme.cs.darktext)
          ]
          settings_slide.addTable(v_settings_rows, { x: .2, y: 1.75, fontFace: theme.ft.body, colW:sizeOnizer(periods_with_history), valign:"middle" });

        //PRODUCTS LIST
        if(props.options.feasibility.revenues_list===true){
          const produts_list_data=getProducts(v_data)
          if(produts_list_data.length>0){
            const pages_count = ceil(produts_list_data.length /10)
            for (var i=0;i<pages_count;i++){
              var products_list_slide=pptx.addSlide('TWO TITLE');
              products_list_slide.addText(props.trsl['revenues'], { placeholder: "title1" })
              products_list_slide.addText(props.trsl['products_and_services'], { placeholder: "title2" })
              products_list_slide.addTable(produts_list_data.slice(i*10,(i+1)*10),
              { colW:4.5,
                newSlideStartY:1.5,
                x: .2, 
                y: 1.5,
                fontFace: theme.ft.body,
                fontSize:12,
                color:theme.cs.darktext
              })
            }
          }
        }
        //PRODUCT SLIDES
        if(props.options.feasibility.revenues_assumptions===true){
          if(v_data.revenues.length>0){
            for(var i=0;i<v_data.revenues.length;i++){
              let peridiocity={
                1:props.trsl['yearly'],
                12:props.trsl['monthly'],
                52:props.trsl['weekly'],
                365:props.trsl['daily'],      
              }
              if(v_data.revenues[i]._rev_hist===false){ // PRODUCT SLIDE WITHOUT HISTORIC
                let product_slide=pptx.addSlide('TWO TITLE')
                product_slide.addText(props.trsl['revenues'], { placeholder: "title1" })
                product_slide.addText(v_data.revenues[i].rev_name, { placeholder: "title2" })
                product_slide.addText(props.trsl['quantities_set']+peridiocity[(v_data.revenues[i].rev_quantity_type)], {x:.2, y:1.5, h:.25, w:'30%', fontFace: theme.ft.body, fontSize:12, color:theme.cs.darktext})
                let prod_rows = [
                  TableHeader (props.trsl, periods_with_history, theme.cs.darkaccent),
                  TableRow ( props.trsl['quantities'], v_data.revenues[i].rev_quantity, theme.cs.darktext),
                  TableRow ( props.trsl['prices'], v_data.revenues[i].rev_price, theme.cs.darktext),
                  TableRowL2 ( props.trsl['total_revenue'], v_data.revenues[i]._rev_total_revenue, theme.cs.darktext),
                  TableRow ( props.trsl['vat'], v_data.revenues[i]._rev_total_vat, theme.cs.darktext),
                  TableRowL1 ( props.trsl['revenue_cost'], v_data.revenues[i].rev_cogs._cogs_total, theme.cs.darktext),
                  TableRow ( props.trsl['dos'], v_data.revenues[i].rev_cogs.days_of_stock, theme.cs.darktext),
                  TableRowL1 ( props.trsl['iventories'], v_data.revenues[i].rev_cogs._inventory, theme.cs.darktext),
                ]
                product_slide.addTable(prod_rows, { x: .2, y: 1.75, fontFace: theme.ft.body, colW:sizeOnizer(periods_with_history), valign:"middle" });
                if(v_data.revenues[i].rev_observations.length>0){ 
                  product_slide.addText(props.trsl['observations'],{ x:.2, y:4, h:0.5, fontFace: theme.ft.head, fontSize:14, color:theme.cs.darktext})
                  product_slide.addText(v_data.revenues[i].rev_observations, {x:.2, y:4.5, h:0.75, fontFace: theme.ft.body, fontSize:8, color:theme.cs.darktext, valign:'top' })
                }
              } else { // PRODUCT SLIDE WITH HISTORIC
                let product_slide=pptx.addSlide('TWO TITLE')
                product_slide.addText(props.trsl['revenues'], { placeholder: "title1" })
                product_slide.addText(v_data.revenues[i].rev_name, { placeholder: "title2" })
                product_slide.addText(props.trsl['quantities_set']+peridiocity[(v_data.revenues[i].rev_quantity_type)], {x:.2, y:1.5, h:.25, w:'30%', fontFace: theme.ft.body, fontSize:12, color:theme.cs.darktext})
                let prod_rows = [
                  TableHeader (props.trsl, periods_with_history, theme.cs.darkaccent),
                  TableRowL1 ( props.trsl['total_revenue'], v_data.revenues[i]._rev_total_revenue, theme.cs.darktext),
                  TableRow ( props.trsl['growth'], v_data.revenues[i].rev_quantity_growth, theme.cs.darktext),
                  TableRow ( props.trsl['cogs'], v_data.revenues[i].rev_cogs._cogs_total, theme.cs.darktext),
                  TableRow ( props.trsl['iventories'], v_data.revenues[i].rev_cogs._inventory, theme.cs.darktext),
                ]
                product_slide.addTable(prod_rows, { x: .2, y: 1.75, fontFace: theme.ft.body, colW:sizeOnizer(periods_with_history), valign:"middle" });
                if(v_data.revenues[i].rev_observations.length>0){ 
                  product_slide.addText(props.trsl['observations'],{ x:.2, y:3.5, h:0.5, fontFace: theme.ft.head, fontSize:14, color:theme.cs.darktext})
                  product_slide.addText(v_data.revenues[i].rev_observations, {x:.2, y:4, h:1.25, fontFace: theme.ft.body, fontSize:8, color:theme.cs.darktext, valign:'top' })
                }

              }
            }
          }
        }

        //REVENUE SLIDE
        if(props.options.feasibility.revenues_graph===true){
          var revenue_slide=pptx.addSlide("ONE TITLE");
          revenue_slide.addText(props.trsl['revenues'], { placeholder: "title1"})
          let revenue_chart = [
            {
              name: props.trsl['revenues'],
              labels: periods_with_history,
              values: v_data.results._inc_st.revenue,
            }
          ]
          revenue_slide.addChart(pptx.ChartType.bar, revenue_chart, { 
            x: .2, 
            y: 1, 
            w: 9.5, 
            h: 4.25, 
            chartColors:[theme.cs.primary],
            catAxisLabelFontFace:"Lato",
            catAxisLabelcolor:theme.cs.primary,
            valAxisLabelFontFace:'Lato',
            valAxisMinVal:0,
            valGridLine:{color:theme.cs.primary, style:'dot'},
            valAxisLabelColor:theme.cs.primary,
            barGapWidthPct:50,
            showLabel:true,
            showValue:true,
            dataLabelPosition: 'outEnd',
            dataLabelFormatCode:'# ###',
            dataLabelColor:theme.cs.darkaccent,
            dataLabelFontFace:'Lato',
            dataLabelFontSize:10
          })
        }

        //OPEX SLIDE
        if(props.options.feasibility.opex===true && v_data.operating_expenses.length>0 ){
          var op_ex_slide=pptx.addSlide("ONE TITLE");
          op_ex_slide.addText(props.trsl['op_exp'], { placeholder: "title1"})
          let opex_rows = [
            TableHeader (props.trsl, periods_with_history, theme.cs.darkaccent),
          ]
          opex_rows= opex_rows.concat(getOpExp(v_data))
          op_ex_slide.addTable(opex_rows, { x: .2, y: 1.5, fontFace: theme.ft.body, colW:sizeOnizer(periods_with_history), valign:"middle"  });

        }
          
        //HR SLIDES
        if(props.options.feasibility.hr===true){
          var hr_slide = pptx.addSlide("TWO TITLE");
          hr_slide.addText(props.trsl['employees'],{ placeholder: "title1" })
          hr_slide.addText(props.trsl['employees_number'],{ placeholder: "title2" })

          let hr_array=[]
          for(var i=0;i<v_data.human_resources.length;i++){
            if(v_data.human_resources[i].hr_type===false){
              hr_array[i]=TableRowL1 (v_data.human_resources[i].hr_name, v_data.human_resources[i].hr_number_of_workers)
            } else{
              const arr=new Array(periods_with_history.length).fill(1)
              hr_array[i]=TableRow(v_data.human_resources[i].hr_name,arr,theme.cs.darktext)
            }
          }
          let hr_rows = [
            TableHeader (props.trsl, periods_with_history, theme.cs.darkaccent)
          ]
          Array.prototype.push.apply(hr_rows, hr_array)
          hr_slide.addTable(hr_rows, { x: .2, y: 1.5, fontFace: theme.ft.body, colW:sizeOnizer(periods_with_history) });


          //HR SLIDE 2 - cost
          var hr_slide_2 = pptx.addSlide("TWO TITLE");
          hr_slide_2.addText(props.trsl['employees'],{ placeholder: "title1" })
          hr_slide_2.addText(props.trsl['employees_cost'],{ placeholder: "title2" })

          let hr_array_2=[]
          for(var i=0;i<v_data.human_resources.length;i++){
            hr_array_2[i]=TableRow(v_data.human_resources[i].hr_name,v_data.human_resources[i]._hr_total_cost,theme.cs.darktext)
          }
          let hr_rows_2 = [
            TableHeader (props.trsl, periods_with_history, theme.cs.darkaccent)
          ]
          Array.prototype.push.apply(hr_rows_2, hr_array_2)
          hr_slide_2.addTable(hr_rows_2, { x: .2, y: 1.5, fontFace: theme.ft.body, colW:sizeOnizer(periods_with_history), valign:"middle"  });



          //HR SLIDE 3
          var hr_slide1 = pptx.addSlide("TWO TITLE");
          hr_slide1.addText(props.trsl['employees'],{ placeholder: "title1" })
          hr_slide1.addText(props.trsl['employees_total'],{ placeholder: "title2" })


          let hr_count=new Array(periods_with_history.length).fill(0)
          for(var i=0;i<v_data.human_resources.length;i++){
            if(v_data.human_resources[i].hr_type===false){
              for(var j=0;j<v_data.human_resources[i].hr_number_of_workers.length;j++){
                hr_count[j]+=v_data.human_resources[i].hr_number_of_workers[j]
              }
            } else{
              for(var j=0;j<v_data.human_resources[i].hr_number_of_workers.length;j++){
                hr_count[j]+=1
              }
            }
          }
          let hr_count_chart = [
            {
              name: props.trsl['employees_number'],
              labels: periods_with_history,
              values: hr_count,
            }
          ]
          hr_slide1.addChart(pptx.ChartType.bar, hr_count_chart, { 
            x: .2, 
            y: 1.5, 
            w: 9.5, 
            h: 3.75, 
            chartColors:[theme.cs.primary],
            catAxisLabelFontFace:"Lato",
            catAxisLabelcolor:theme.cs.primary,
            valAxisLabelFontFace:'Lato',
            valAxisMinVal:0,
            valGridLine:{color:theme.cs.primary, style:'dot'},
            valAxisLabelColor:theme.cs.primary,
            barGapWidthPct:50,
          })
        }

        //COSTS SLIDE
        if(props.options.feasibility.costs===true){
          var costs_slide=pptx.addSlide("ONE TITLE");
          costs_slide.addText(props.trsl['costs'], { placeholder: "title1" })
          let cost_chart = [
            {
              name: props.trsl['cogs'],
              labels: periods_with_history,
              values: v_data.results._inc_st.cogs,
            },
            {
              name: props.trsl['operating_exp'],
              labels: periods_with_history,
              values: v_data.results._inc_st.operating_expenses,
            },
            {
              name: props.trsl['employees'],
              labels: periods_with_history,
              values: v_data.results._inc_st.hr,
            },
            {
              name: props.trsl['other_costs'],
              labels: periods_with_history,
              values: v_data.results._inc_st.other_costs,
            }
          ]
          costs_slide.addChart(pptx.ChartType.bar, cost_chart, { 
            x: .2, 
            y: 1, 
            w: 9.5, 
            h: 4.25, 
            barGrouping:'stacked',
            chartColors:[theme.cs.primary,theme.cs.darkaccent,theme.cs.darkshade,theme.cs.lightaccent],
            catAxisLabelFontFace:"Lato",
            catAxisLabelcolor:theme.cs.primary,
            valAxisLabelFontFace:'Lato',
            valAxisMinVal:0,
            valGridLine:{color:theme.cs.primary, style:'dot'},
            valAxisLabelColor:theme.cs.primary,
            barGapWidthPct:50,
            showLegend:true,
            legendFontFace:'Lato',
            legendFontSize:10,
            legendColor:theme.cs.darktext,
            legendPos:'b'
          })
        }
        

        //INVESTMENT SLIDE
        if(props.options.feasibility.investment===true){
          var investment_slide = pptx.addSlide("ONE TITLE");
          investment_slide.addText(props.trsl['investment'],{ placeholder: "title1" })
          investment_slide.addText(props.trsl['total_investment'] + sumInvestments(v_data.investments,props.locale), { 
            x: .2, 
            y: 1,
            h:1, 
            fontFace: theme.ft.body,
            fontSize:50,
            color:theme.cs.primary
          })
          investment_slide.addText(props.trsl['investment_subsidy'] + sumInvestmentSubsidy(v_data.investments,props.locale), { 
            x: .2, 
            y: 3,
            h:1, 
            fontFace: theme.ft.body,
            fontSize:16,
            color:theme.cs.primary
          })
          investment_slide.addText(props.trsl['year_investment'],{ 
            x: '70%', 
            y: 1,
            w:'25%', 
            fontFace: theme.ft.body,
            fontSize:16,
            color:theme.cs.darktext
          })
          investment_slide.addText(addBulletText(sumYearlyInvestments(v_data.investments,periods_with_history,props.locale)),{ 
            x: '70%', 
            y: 1.2,
            h:3,
            w:'25%', 
            fontFace: theme.ft.body,
            fontSize:16,
            valign:'top',
            color:theme.cs.darktext
          })

          //INVESTMENT LIST
          const inv_list_data=getInvestments(v_data,periods_with_history)
          if(inv_list_data.length>0){
            const pages_count=ceil(inv_list_data.length /15)
            for(var i=0;i<pages_count;i++){
              var investment_list_slide=pptx.addSlide("ONE TITLE");
              investment_list_slide.addText(props.trsl['investment'], { placeholder: "title1" })
              investment_list_slide.addTable(inv_list_data.slice(i*15,(i+1)*15),
              {
                x: .2, 
                y: 1,
                colW:[.5, 2.5, 1],
                fontFace: theme.ft.body,
                fontSize:9,
                color:theme.cs.darktext 
              })
            }
          }
        }


        //FINANCING SLIDE
        if(props.options.feasibility.financing===true){
          var financing_slide = pptx.addSlide("ONE TITLE");
          financing_slide.addText(props.trsl['financing'], { placeholder: "title1" })
          financing_slide.addText(addBulletText([
            props.trsl['issued_capital'] +': '+sumEquities(v_data.shareholders,'issued_capital',props.locale),
            '',
            props.trsl['share_premium'] +': '+sumSharePremium(v_data.shareholders,'issued_capital',props.locale),
            '',
            props.trsl['other_equity_instr'] +': '+sumEquities(v_data.shareholders,'other_equity_instruments', props.locale),
            '',
            props.trsl['shareholders_loan_1'] +': '+sumEquities(v_data.shareholders,'shareholders_loan', props.locale),
            '',
            props.trsl['debt'] +': '+sumDebt(v_data.debt,props.locale),
          ]), { 
            x: .2, 
            y: 1,
            h:4, 
            fontFace: theme.ft.body,
            fontSize:16,
            color:theme.cs.darktext
          })
        }

        //ISSUED CAPITAL SLIDE
        if(props.options.feasibility.shareholders===true){
          if(v_data.shareholders.length>0 && v_data.shareholders.filter((e)=>e.shareholders_type=='issued_capital').length>0){
            var ic_slide=pptx.addSlide("ONE TITLE");
            ic_slide.addText(props.trsl['shareholders_ic_participations'], { placeholder: "title1" })
            let ic_chart = createArrayForICSlide(v_data.shareholders,'issued_capital',periods_with_history)
            ic_slide.addChart(pptx.ChartType.bar, ic_chart, { 
              x: .2, 
              y: 1, 
              w: 9.5, 
              h: 4.25, 
              chartColors:[theme.cs.primary,theme.cs.darkaccent,theme.cs.darkshade,theme.cs.lightaccent],
              catAxisLabelFontFace:"Lato",
              catAxisLabelcolor:theme.cs.primary,
              valAxisLabelFontFace:'Lato',
              valAxisMinVal:0,
              valGridLine:{color:theme.cs.primary, style:'dot'},
              valAxisLabelColor:theme.cs.primary,
              barGrouping:'stacked',
              barGapWidthPct:50,
              showLegend:true,
              legendFontFace:'Lato',
              legendFontSize:10,
              legendColor:theme.cs.darktext,
              legendPos:'b'
              })
            }
          }

          //DEBT SLIDES
          if(props.options.feasibility.debt===true){
            if(v_data.debt.length>0){
              for(var i=0;i<v_data.debt.length;i++){
                let debt_slide=pptx.addSlide("TWO TITLE")
                debt_slide.addText(props.trsl['debt-technical'], { placeholder: "title1" })
                debt_slide.addText(v_data.debt[i].debt_name, { placeholder: "title2" })
                
                let debt_rows = [
                  TableHeader (props.trsl, periods_with_history,theme.cs.darkaccent),
                  TableRow ( props.trsl['initial_debt'], v_data.debt[i]._debt_initial_debt,theme.cs.darktext),
                  TableRow ( props.trsl['debt_reimbursement'], v_data.debt[i]._debt_reimbursement,theme.cs.darktext),
                  TableRow ( props.trsl['final_debt'], v_data.debt[i]._debt_final_debt,theme.cs.darktext),
                  TableRow ( props.trsl['debt_interest'], v_data.debt[i]._debt_interest,theme.cs.darktext),
                  TableRowL1 ( props.trsl['debt_service'], add(v_data.debt[i]._debt_reimbursement,v_data.debt[i]._debt_interest))
                ]

                let table_position=2.5

                if(v_data.debt[i].interest_rate_type==='floating'){
                  debt_rows.splice(3,0,TableRowPercentage ( props.trsl['debt_interest_rate'], dotDivide(v_data.debt[i].debt_interest_rate_float,100),theme.cs.darktext),)
                  table_position=2.25
                } else {
                  debt_slide.addText(props.trsl['debt_amount'] + v_data.debt[i].debt_amount, {x:.2, y:1.5, h:.25, w:'30%', fontFace: theme.ft.body, fontSize:12, color:theme.cs.darktext})
                  debt_slide.addText(props.trsl['debt_duration']+v_data.debt[i].debt_duration, {x:.2, y:1.75, h:.25, w:'30%', fontFace: theme.ft.body, fontSize:12, color:theme.cs.darktext })  
                  debt_slide.addText(props.trsl['debt_interest_rate']+ editPercentageValues(v_data.debt[i].debt_interest_rate/100,'en'), {x:.2, y:2, h:.25, w:'30%', fontFace: theme.ft.body, fontSize:12, color:theme.cs.darktext })
                }
                
                debt_slide.addTable(debt_rows, { x: .2, y: table_position, fontFace: theme.ft.body, colW:sizeOnizer(periods_with_history), valign:"middle"  });
                debt_slide.addText(props.trsl['observations'], { x:.2, y:4, h:0.5, fontFace: theme.ft.head, fontSize:14, color:theme.cs.darktext})
                debt_slide.addText(v_data.debt[i].debt_obs, {x:.2, y:4.5, h:.75, fontFace: theme.ft.body, fontSize:14, color:theme.cs.darktext, valign:'top'})
              }
            }
          }

        //RESULTS SLIDE
        var results_slide = pptx.addSlide("NO TITLE");
        results_slide.addText(
          props.trsl['results'],{ x:0, y:1.5, w:'100%', h:1.5, align:'center', color:theme.cs.primary, fontSize:70, fontFace: theme.ft.head  }
        )

        //INCOME STATEMENT SLIDE
        if(props.options.feasibility.is===true){
          var income_statemente_slide = pptx.addSlide("ONE TITLE");
          income_statemente_slide.addText(props.trsl['income_st'], { placeholder: "title1" })
          let is_rows = [
            TableHeader (props.trsl, periods_with_history,theme.cs.darkaccent),
            TableRow ( props.trsl['revenues'], v_data.results._inc_st.revenue,theme.cs.darktext),
            TableRow ( props.trsl['subsidies'],  v_data.results._inc_st.operational_grants,theme.cs.darktext),
            TableRow ( props.trsl['cogs'],   v_data.results._inc_st.cogs,theme.cs.darktext),
            TableRow ( props.trsl['operating_exp'], v_data.results._inc_st.operating_expenses,theme.cs.darktext),
            TableRow ( props.trsl['employees'],   v_data.results._inc_st.hr,theme.cs.darktext),
            TableRow ( props.trsl['other_revenues'], v_data.results._inc_st.other_gains,theme.cs.darktext),
            TableRow ( props.trsl['other_costs'], v_data.results._inc_st.other_costs,theme.cs.darktext),
            TableRowL1 ( props.trsl['ebitda'],v_data.results._inc_st.ebitda,theme.cs.darktext),
            TableRow ( props.trsl['depreciations'], v_data.results._inc_st.depreciations,theme.cs.darktext),
            TableRowL1 ( props.trsl['ebit'], v_data.results._inc_st.ebit,theme.cs.darktext),
            TableRow ( props.trsl['financing_costs'], v_data.results._inc_st.interest_cost,theme.cs.darktext),
            TableRowL1 ( props.trsl['ebt'], v_data.results._inc_st.ebt,theme.cs.darktext),
            TableRow ( props.trsl['corporate_tax'], v_data.results._inc_st.corporate_tax,theme.cs.darktext),
            TableRowL2 ( props.trsl['net_income'], v_data.results._inc_st.net_profit,theme.cs.darktext)    
          ];
          income_statemente_slide.addTable(is_rows, { x: .2, y: 0.8, fontFace: theme.ft.body, colW:sizeOnizer(periods_with_history), margin:[2,2,2,2], valign:"middle" }); 
        }

        //BALANCE - ASSETS SLIDE
        if(props.options.feasibility.bs===true){
          var assets_slide = pptx.addSlide("ONE TITLE");
          assets_slide.addText(props.trsl['balance_sheet'], { placeholder: "title1" })
          let assets_rows = [
            TableHeader (props.trsl, periods_with_history, theme.cs.darkaccent),
            TableRow (props.trsl['tangible'], v_data.results._bal.tangible_assets,theme.cs.darktext),
            TableRow (props.trsl['intangible'], v_data.results._bal.intangible_assets,theme.cs.darktext),
            TableRow (props.trsl['other_non_assets'], v_data.results._bal.other_non_current_assets,theme.cs.darktext),
            TableRowL1 (props.trsl['non_current_assets'], v_data.results._bal.total_non_current_assets,theme.cs.darktext),
            TableRow (props.trsl['iventories'], v_data.results._bal.inventories,theme.cs.darktext),
            TableRow (props.trsl['clients'], v_data.results._bal.clients,theme.cs.darktext),
            TableRow (props.trsl['gov_receivables'], v_data.results.gov.gov_receivable,theme.cs.darktext),
            TableRow (props.trsl['other_current_assets'], v_data.results._bal.other_current_assets,theme.cs.darktext),
            TableRow (props.trsl['cash'], v_data.results.cf.year_end_cash_flows,theme.cs.darktext),
            TableRowL1 (props.trsl['current_assets'], v_data.results._bal.total_current_assets,theme.cs.darktext),
            TableRowL2 (props.trsl['total_assets'], v_data.results._bal.total_assets,theme.cs.darktext),

          ];
          assets_slide.addTable(assets_rows, { x: .2, y: 0.8, fontFace: theme.ft.body, colW:sizeOnizer(periods_with_history), margin:[2,2,2,2], valign:"middle"  });

          //BALANCE - EQUITY + LIABILITIES SLIDE
          var eq_lia_slide = pptx.addSlide("ONE TITLE");
          eq_lia_slide.addText(props.trsl['balance_sheet'], { placeholder: "title1" })
          let rows = [
            TableHeader (props.trsl, periods_with_history, theme.cs.darkaccent),
            TableRow (props.trsl['issued_capital'], v_data.results._bal.issued_capital,theme.cs.darktext),
            TableRow (props.trsl['other_equity_instr'], v_data.results._bal.other_equity_instruments,theme.cs.darktext),
            TableRow (props.trsl['other_equity_chan'], v_data.results._bal.other_equity_changes,theme.cs.darktext),
            TableRow (props.trsl['past_results'], v_data.results._bal.past_results,theme.cs.darktext),
            TableRow (props.trsl['net_income'], v_data.results._inc_st.net_profit,theme.cs.darktext),
            TableRowL2 (props.trsl['total_equity'], v_data.results._bal.total_equity,theme.cs.darktext),
            TableRow (props.trsl['debt'], v_data.results._bal.debt,theme.cs.darktext),
            TableRow (props.trsl['other_non_liabilities'], v_data.results._bal.other_non_current_liabilities,theme.cs.darktext),
            TableRowL1 (props.trsl['non_current_liabilities'], v_data.results._bal.total_non_current_liabilities),
            TableRow (props.trsl['suppliers'], v_data.results._bal.suppliers,theme.cs.darktext),
            TableRow (props.trsl['gov_payables'], v_data.results.gov.gov_payable,theme.cs.darktext),
            TableRow (props.trsl['shareholders_loan'], v_data.results._bal.shareholders_loan,theme.cs.darktext),
            TableRow (props.trsl['other_payables'], v_data.results._bal.other_accounts_payables,theme.cs.darktext),
            TableRow (props.trsl['other_current_liabilities'], v_data.results._bal.other_current_liabilities,theme.cs.darktext),
            TableRow (props.trsl['current_liabilities'], v_data.results._bal.total_current_liabilities,theme.cs.darktext),
            TableRowL1 (props.trsl['total_liabilities'], v_data.results._bal.total_liabilities,theme.cs.darktext),
            TableRowL2 (props.trsl['equity_liabilities'], v_data.results._bal.equity_liabilities,theme.cs.darktext),
          ];
          eq_lia_slide.addTable(rows, { x: .2, y: 0.8, fontFace: theme.ft.body, colW:sizeOnizer(periods_with_history), margin:[2,2,2,2], valign:"middle"  });
        }

        //CASH STATEMENT SLIDE
        if(props.options.feasibility.cs===true){
          var cash_statemet_slide = pptx.addSlide("ONE TITLE");
          cash_statemet_slide.addText(props.trsl['cash_st'], { placeholder: "title1" })
          let cs_rows = [
            TableHeader (props.trsl, periods_with_history, theme.cs.darkaccent),
            TableRow (props.trsl['received_clients'], v_data.results.cf.received_from_clients,theme.cs.darktext),
            TableRow (props.trsl['paid_suppliers'], v_data.results.cf.paid_to_suppliers,theme.cs.darktext),
            TableRow (props.trsl['paid_hr'], v_data.results.cf.payment_to_hr,theme.cs.darktext),
            TableRowL1 ( props.trsl['operation_cf'],  v_data.results.cf.operations_cash_flows,theme.cs.darktext),
            TableRow (props.trsl['corporate_tax'], v_data.results.cf.corporate_tax_cash_flow,theme.cs.darktext),
            TableRow (props.trsl['other_pay_rec'], v_data.results.cf.other_payments_and_receivables,theme.cs.darktext),
            TableRowL1 (props.trsl['operational_cf'], v_data.results.cf.operational_cash_flow,theme.cs.darktext),
            TableRow (props.trsl['non_current_cf'], v_data.results.cf.cash_flows_of_non_current_assets_liabilities,theme.cs.darktext),
            TableRow (props.trsl['investment_in_assets'], v_data.results.cf.payment_of_fixed_assets,theme.cs.darktext),
            TableRowL1 (props.trsl['investment_cf'], v_data.results.cf.investment_cash_flows,theme.cs.darktext),
            TableRow (props.trsl['debt_increase'], v_data.results.cf.debt_increase,theme.cs.darktext),
            TableRow (props.trsl['equity_eq_increase'], v_data.results.cf.equity_and_equivalent_increase,theme.cs.darktext),
            TableRow (props.trsl['financing_costs'], v_data.results._inc_st.interest_cost,theme.cs.darktext),
            TableRow (props.trsl['debt_decrease'], v_data.results.cf.debt_decrease,theme.cs.darktext),
            TableRow (props.trsl['paid_dividends'], v_data.results.cf.paid_dividends,theme.cs.darktext),
            TableRow (props.trsl['equity_eq_decrease'], v_data.results.cf.equity_and_equivalent_decrease,theme.cs.darktext),
            TableRowL1 (props.trsl['financing_cashflows'], v_data.results.cf.financing_cashflows,theme.cs.darktext),
            TableRowL2 (props.trsl['cf_variation'], v_data.results.cf.cash_flow_variation,theme.cs.darktext),
            TableRowL1 (props.trsl['initial_cash'], v_data.results.cf.initial_cash_flows,theme.cs.darktext),
            TableRowL2 (props.trsl['end_cash'], v_data.results.cf.year_end_cash_flows,theme.cs.darktext),
          ];
          cash_statemet_slide.addTable(cs_rows, { x: .2, y: 0.8, fontFace: theme.ft.body, colW:sizeOnizer(periods_with_history), margin:[2,2,2,2], valign:"middle"  });
        }

        //WORKING CAPITAL STATEMENT SLIDE
        if(props.options.feasibility.wc===true){
          var working_capital_slide = pptx.addSlide("ONE TITLE");
          working_capital_slide.addText(props.trsl['working_capital'], { placeholder: "title1" })
          let wc_rows = [
            TableHeader (props.trsl, periods_with_history, theme.cs.darkaccent,theme.cs.darktext),
            TableRow (props.trsl['iventories'], v_data.results._bal.inventories,theme.cs.darktext),
            TableRow (props.trsl['clients'], v_data.results._bal.clients,theme.cs.darktext),
            TableRow (props.trsl['gov_receivables'], v_data.results.gov.gov_receivable,theme.cs.darktext),
            TableRow (props.trsl['other_current_assets'], v_data.results._bal.other_current_assets,theme.cs.darktext),
            TableRowL1 (props.trsl['working_capital_investent'], v_data.results.wc.wc_investment,theme.cs.darktext),
            TableRow (props.trsl['suppliers'], v_data.results._bal.suppliers,theme.cs.darktext),
            TableRow (props.trsl['gov_payables'], v_data.results.gov.gov_payable,theme.cs.darktext),
            TableRow (props.trsl['other_payables'], v_data.results._bal.other_accounts_payables,theme.cs.darktext),
            TableRow (props.trsl['other_current_liabilities'], v_data.results._bal.other_current_liabilities,theme.cs.darktext),
            TableRowL1 (props.trsl['working_capital_financing'], v_data.results.wc.wc_financing,theme.cs.darktext),
            TableRowL1 (props.trsl['working_capital_needs'], v_data.results.wc.wc_needs,theme.cs.darktext),
            TableRowL2 (props.trsl['working_capital_variation'], v_data.results.wc.wc_variation,theme.cs.darktext),
          ];
          working_capital_slide.addTable(wc_rows, { x: .2, y: 0.8, fontFace: theme.ft.body, colW:sizeOnizer(periods_with_history),margin:[2,2,2,2], valign:"middle"  });
        }

        //RATIOS
        if(props.options.feasibility.ra===true){
          var ratios_slide = pptx.addSlide("ONE TITLE");
          ratios_slide.addText(props.trsl['ratios'], { placeholder: "title1" })
          let ra_rows = [
            TableHeader (props.trsl, periods_with_history, theme.cs.darkaccent,theme.cs.darktext),
            TableRowPercentage (props.trsl['gross_margin'], v_data.results.ra.gross_margin,theme.cs.darktext),
            TableRowPercentage (props.trsl['net_margin'], v_data.results.ra.net_margin,theme.cs.darktext),
            TableRowPercentage (props.trsl['return_on_sales'], v_data.results.ra.ros,theme.cs.darktext),
            TableRowPercentage (props.trsl['return_on_assets'], v_data.results.ra.roa,theme.cs.darktext),
            TableRowPercentage (props.trsl['return_on_equity'], v_data.results.ra.roe,theme.cs.darktext),
            TableRowPercentage (props.trsl['equity_ratio'], v_data.results.ra.equity_ratio,theme.cs.darktext),
            TableRowPercentage (props.trsl['dar'], v_data.results.ra.dar,theme.cs.darktext),
            TableRowPercentage (props.trsl['dap'], v_data.results.ra.dap,theme.cs.darktext),
            TableRowPercentage (props.trsl['dos'], v_data.results.ra.dos,theme.cs.darktext),
            TableRowPercentage (props.trsl['depreciation_rate'], v_data.results.ra.depreciation_rate,theme.cs.darktext),
            TableRowPercentage (props.trsl['corporate_tax_rate'], v_data.results.ra.corporate_tax_rate,theme.cs.darktext)
          ];
          ratios_slide.addTable(ra_rows, { x: .2, y: 0.8, fontFace: theme.ft.body, colW:sizeOnizer(periods_with_history),margin:[2,2,2,2], valign:"middle"  });
        }

        //FEASIBILITY ANALISYS SLIDE
        if(props.options.feasibility.fea===true){
          var feasibilitya_analisys_slide = pptx.addSlide("ONE TITLE");
          feasibilitya_analisys_slide.addText(props.trsl['cash_flows'], { placeholder: "title1" })
          let fea_rows = [
            TableHeader (props.trsl, periods, theme.cs.darkaccent),
            TableRow (props.trsl['ebt'], v_data.results._inc_st.ebit,theme.cs.darktext),
            TableRow (props.trsl['operationa_corporate_tax'], v_data.results.fe.operational_corporate_tax,theme.cs.darktext),
            TableRow (props.trsl['depreciations'], v_data.results._inc_st.depreciations,theme.cs.darktext),
            TableRowL1 (props.trsl['noplat'], v_data.results.fe.noplat,theme.cs.darktext),
            TableRow (props.trsl['working_capital_variation'], v_data.results.wc.wc_variation,theme.cs.darktext),
            TableRow (props.trsl['fixed_asset_investment'], v_data.results.fe.fixed_asset_investment,theme.cs.darktext),
            TableRowL1 (props.trsl['fcff'], v_data.results.fe.fcff,theme.cs.darktext),
            TableRow (props.trsl['accumulated_fcff'], v_data.results.fe.fcff_accumulated,theme.cs.darktext),
          ];
          feasibilitya_analisys_slide.addTable(fea_rows, { x: .2, y: 0.8, fontFace: theme.ft.body, colW:sizeOnizer(periods),margin:[2,2,2,2], valign:"middle"  });

          //FEASIBILITY ASSUMPTIONS
          var feasibility_assumptions_slide = pptx.addSlide("ONE TITLE");
          feasibility_assumptions_slide.addText(props.trsl['financial_assumptions'], { placeholder: "title1" })
          feasibility_assumptions_slide.addText(props.trsl['CAPM'], {x:'6%', y:1, w:'25%', h:.5, align:'left', color:theme.cs.darkshade, fontSize:16, fontFace: theme.ft.head})
          feasibility_assumptions_slide.addText(props.trsl['CAPM_explanation'], {x:'6%', y:1.5, w:'25%', h:.5, align:'left', color:theme.cs.darkshade, fontSize:8, fontFace: theme.ft.body})        
          feasibility_assumptions_slide.addText(props.trsl['risk_free'] +': ' + (v_data.coc.coc_risk_free).toFixed(2) +'%', {x:'6%', y:2.5, w:'25%', h:.5, align:'left', color:theme.cs.primary, fontSize:12, fontFace: theme.ft.body})
          feasibility_assumptions_slide.addText(props.trsl['beta'] +': ' + (v_data.coc.coc_beta).toFixed(2), {x:'6%', y:3, w:'25%', h:.5, align:'left', color:theme.cs.primary, fontSize:12, fontFace: theme.ft.body})
          feasibility_assumptions_slide.addText(props.trsl['risk_premium'] +': ' + (v_data.coc.coc_risk_premium).toFixed(2) +'%', {x:'6%', y:3.5, w:'25%', h:.5, align:'left', color:theme.cs.primary, fontSize:12, fontFace: theme.ft.body})
          feasibility_assumptions_slide.addText(props.trsl['cost_of_equity'] +': ' + (v_data.coc._coc_equity_cost).toFixed(2) +'%', {x:'6%', y:4, w:'25%', h:.5, align:'left', color:theme.cs.primary, fontSize:12, fontFace: theme.ft.head})

          feasibility_assumptions_slide.addText(props.trsl['cost_of_debt'], {x:'37%', y:1, w:'25%', h:.5, align:'left', color:theme.cs.darkshade, fontSize:16, fontFace: theme.ft.head})  
          feasibility_assumptions_slide.addText(props.trsl['cost_debt_explanation'], {x:'37%', y:1.5, w:'25%', h:.5, align:'left', color:theme.cs.darkshade, fontSize:8, fontFace: theme.ft.body})         
          feasibility_assumptions_slide.addText(props.trsl['cost_of_debt_reference'] +': ' + (v_data.coc.coc_average_debt_interest).toFixed(2) +'%', {x:'37%', y:2.5, w:'30%', h:.5, align:'left', color:theme.cs.primary, fontSize:12, fontFace: theme.ft.body})
          feasibility_assumptions_slide.addText(props.trsl['tax_shield'] +': ' + (props.settings.irc).toFixed(2), {x:'37%', y:3, w:'25%', h:.5, align:'left', color:theme.cs.primary, fontSize:12, fontFace: theme.ft.body})
          feasibility_assumptions_slide.addText(props.trsl['cost_of_debt'] +': ' + (v_data.coc._coc_debt_cost).toFixed(2) +'%', {x:'37%', y:3.5, w:'25%', h:.5, align:'left', color:theme.cs.primary, fontSize:12, fontFace: theme.ft.head})
         
          feasibility_assumptions_slide.addText(props.trsl['wacc'], {x:'68%', y:1, w:'25%', h:.5, align:'left', color:theme.cs.darkshade, fontSize:16, fontFace: theme.ft.head}) 
          feasibility_assumptions_slide.addText(props.trsl['wacc_explanation'], {x:'68%', y:1.5, w:'25%', h:.5, align:'left', color:theme.cs.darkshade, fontSize:8, fontFace: theme.ft.body})          
          feasibility_assumptions_slide.addText(props.trsl['equity_weight'] +': ' + (v_data.coc.coc_equity_weight).toFixed(2) +'%', {x:'68%', y:2.5, w:'25%', h:.5, align:'left', color:theme.cs.primary, fontSize:12, fontFace: theme.ft.body})
          feasibility_assumptions_slide.addText(props.trsl['cost_of_equity'] +': ' + (v_data.coc._coc_equity_cost).toFixed(2) + '%', {x:'68%', y:3, w:'25%', h:.5, align:'left', color:theme.cs.primary, fontSize:12, fontFace: theme.ft.body})
          feasibility_assumptions_slide.addText(props.trsl['debt_weight'] +': ' + (v_data.coc._coc_debt_weight).toFixed(2) +'%', {x:'68%', y:3.5, w:'25%', h:.5, align:'left', color:theme.cs.primary, fontSize:12, fontFace: theme.ft.body})
          feasibility_assumptions_slide.addText(props.trsl['cost_of_debt'] +': ' + (v_data.coc._coc_debt_cost).toFixed(2) +'%', {x:'68%', y:4, w:'25%', h:.5, align:'left', color:theme.cs.primary, fontSize:12, fontFace: theme.ft.body})
          feasibility_assumptions_slide.addText(props.trsl['wacc'] +': ' + (v_data.coc._coc_wacc).toFixed(2) +'%', {x:'68%', y:4.5, w:'25%', h:.5, align:'left', color:theme.cs.primary, fontSize:12, fontFace: theme.ft.head})

          //UNLEVERED PROJECT 
          if(props.settings.financial_standalone_project===true && props.options.feasibility.sap===true){
            var financial_stand_alone_slide = pptx.addSlide("TWO TITLE");
            financial_stand_alone_slide.addText(props.trsl['financial_results'], { placeholder: "title1" })
            financial_stand_alone_slide.addText(props.trsl['stand_alone_analysis'], { placeholder: "title2" })
            let sap_rows = [
              TableHeader (props.trsl, periods, theme.cs.darkaccent),
              TableRow (props.trsl['discounted_fcff'], v_data.results._sap.fcff_discounted,theme.cs.darktext),
              TableRowL1 (props.trsl['accumulated_discounted_fcff'], v_data.results._sap.fcff_discounted_accumulated,theme.cs.darktext),
            ]
            financial_stand_alone_slide.addTable(sap_rows, { x: .2, y: 1.5, fontFace: theme.ft.body, colW:sizeOnizer(periods), margin:[2,2,2,2], valign:"middle"  });
            financial_stand_alone_slide.addText(
              [
                {text: props.trsl['residual_value'] +': ', options: {color:theme.cs.primary, fontSize:18, fontFace: theme.ft.body }},
                {text: editValues(v_data.results._sap.perpetuity_discounted,props.locale), options:{color:theme.cs.darktext, fontSize:18, fontFace: theme.ft.head  }}
              ],
             { x:.2, y:2.5, w:'90%', h:.5, align:'left'})
             financial_stand_alone_slide.addText(
              [
                {text: props.trsl['npv'] +': ', options: {color:theme.cs.primary, fontSize:18, fontFace: theme.ft.body }},
                {text: editValues(v_data.results._sap.npv,props.locale), options:{color:theme.cs.darktext, fontSize:18, fontFace: theme.ft.head  }}
              ],
              { x:.2, y:3, w:'90%', h:.5, align:'left'}
            )
            financial_stand_alone_slide.addText(
              [
                {text: props.trsl['irr'] +': ', options: {color:theme.cs.primary, fontSize:18, fontFace: theme.ft.body }},
                {text: (v_data.results._sap.irr*100).toFixed(2)+'%', options:{color:theme.cs.darktext, fontSize:18, fontFace: theme.ft.head  }}
              ],
              { x:.2, y:3.5, w:'90%', h:.5, align:'left'}
            )
            financial_stand_alone_slide.addText(
              [
                {text: props.trsl['payback_period'] +': ', options: {color:theme.cs.primary, fontSize:18, fontFace: theme.ft.body }},
                {text: v_data.results._sap.payback, options:{color:theme.cs.darktext, fontSize:18, fontFace: theme.ft.head  }}
              ],
              { x:.2, y:4, w:'90%', h:.5, align:'left'}
            )
          }

          //LEVERED PROJECT 
          if(props.settings.financial_levered_project===true && props.options.feasibility.lep===true){
            var financial_levered_slide = pptx.addSlide("TWO TITLE");
            financial_levered_slide.addText(props.trsl['financial_results'], { placeholder: "title1" })
            financial_levered_slide.addText(props.trsl['levered_analysis'], { placeholder: "title2" })
            let lep_rows = [
              TableHeader (props.trsl, periods, theme.cs.darkaccent),
              TableRow (props.trsl['discounted_fcff'], v_data.results._lep.fcff_discounted,theme.cs.darktext),
              TableRowL1 (props.trsl['accumulated_discounted_fcff'], v_data.results._lep.fcff_discounted_accumulated,theme.cs.darktext),
            ]
            financial_levered_slide.addTable(lep_rows, { x: .2, y: 1.5, fontFace: theme.ft.body, colW:sizeOnizer(periods), margin:[2,2,2,2], valign:"middle" });
            financial_levered_slide.addText(
              [
                {text: props.trsl['residual_value'] +': ', options: {color:theme.cs.primary, fontSize:18, fontFace: theme.ft.body }},
                {text: editValues(v_data.results._lep.perpetuity_discounted,props.locale), options:{color:theme.cs.darktext, fontSize:18, fontFace: theme.ft.head  }}
              ],
             { x:.2, y:2.5, w:'90%', h:.5, align:'left'})
            financial_levered_slide.addText(
              [
                {text: props.trsl['npv'] +': ', options: {color:theme.cs.primary, fontSize:18, fontFace: theme.ft.body }},
                {text: editValues(v_data.results._lep.npv,props.locale), options:{color:theme.cs.darktext, fontSize:18, fontFace: theme.ft.head  }}
              ],
              { x:.2, y:3, w:'90%', h:.5, align:'left'}
            )
            financial_levered_slide.addText(
              [
                {text: props.trsl['irr'] +': ', options: {color:theme.cs.primary, fontSize:18, fontFace: theme.ft.body }},
                {text: (v_data.results._lep.irr*100).toFixed(2)+'%', options:{color:theme.cs.darktext, fontSize:18, fontFace: theme.ft.head  }}
              ],
              { x:.2, y:3.5, w:'90%', h:.5, align:'left'}
            )
            financial_levered_slide.addText(
              [
                {text: props.trsl['payback_period'] +': ', options: {color:theme.cs.primary, fontSize:18, fontFace: theme.ft.body }},
                {text: v_data.results._lep.payback, options:{color:theme.cs.darktext, fontSize:18, fontFace: theme.ft.head  }}
              ],
              { x:.2, y:4, w:'90%', h:.5, align:'left'}
            )
          }

          //INVESTOR PROJECT 
          if(props.settings.financial_investor===true && props.options.feasibility.lep===true){
            var financial_investor_slide = pptx.addSlide("TWO TITLE");
            financial_investor_slide.addText(props.trsl['financial_results'], { placeholder: "title1" })
            financial_investor_slide.addText(props.trsl['investor_analysis'], { placeholder: "title2" })
            let lep_rows = [
              TableHeader (props.trsl, periods, theme.cs.darkaccent),
              TableRow (props.trsl['discounted_fcff'], v_data.results._ivp.fcfe_discounted,theme.cs.darktext),
              TableRowL1 (props.trsl['accumulated_discounted_fcff'], v_data.results._ivp.fcfe_discounted_accumulated,theme.cs.darktext),
            ]
            financial_investor_slide.addTable(lep_rows, { x: .2, y: 1.5, fontFace: theme.ft.body, colW:sizeOnizer(periods), margin:[2,2,2,2], valign:"middle"  });
            financial_investor_slide.addText(
              [
                {text: props.trsl['residual_value'] +': ', options: {color:theme.cs.primary, fontSize:18, fontFace: theme.ft.body }},
                {text: editValues(v_data.results._ivp.perpetuity_discounted,props.locale), options:{color:theme.cs.darktext, fontSize:18, fontFace: theme.ft.head  }}
              ],
              { x:.2, y:2.5, w:'90%', h:.5, align:'left'})
              financial_investor_slide.addText(
              [
                {text: props.trsl['npv'] +': ', options: {color:theme.cs.primary, fontSize:18, fontFace: theme.ft.body }},
                {text: editValues(v_data.results._ivp.npv,props.locale), options:{color:theme.cs.darktext, fontSize:18, fontFace: theme.ft.head  }}
              ],
              { x:.2, y:3, w:'90%', h:.5, align:'left'}
            )
            financial_investor_slide.addText(
              [
                {text: props.trsl['irr'] +': ', options: {color:theme.cs.primary, fontSize:18, fontFace: theme.ft.body }},
                {text: (v_data.results._ivp.irr*100).toFixed(2)+'%', options:{color:theme.cs.darktext, fontSize:18, fontFace: theme.ft.head  }}
              ],
              { x:.2, y:3.5, w:'90%', h:.5, align:'left'}
            )
            financial_investor_slide.addText(
              [
                {text: props.trsl['payback_period'] +': ', options: {color:theme.cs.primary, fontSize:18, fontFace: theme.ft.body }},
                {text: v_data.results._ivp.payback, options:{color:theme.cs.darktext, fontSize:18, fontFace: theme.ft.head  }}
              ],
              { x:.2, y:4, w:'90%', h:.5, align:'left'}
            )
          }

          ///DEBT SERVICE COVERAGE
          if(props.options.feasibility.dc===true){
            var debt_coverage_slide = pptx.addSlide("TWO TITLE");
            debt_coverage_slide.addText(props.trsl['financial_results'], { placeholder: "title1" })
            debt_coverage_slide.addText(props.trsl['debt_coverage'], { placeholder: "title2" })
            let dc_rows = [
              TableHeader (props.trsl, periods, theme.cs.darkaccent,theme.cs.darktext),
              TableRowL1 (props.trsl['debt_interest'], v_data.results._inc_st.interest_cost,theme.cs.darktext),
              TableRowPercentage (props.trsl['ebtida_coverage'], v_data.results.ra.ebtida_interest_coverage_rate,theme.cs.darktext),
              TableRowPercentage (props.trsl['fcff_coverage'], v_data.results.ra.fcff_interest_coverate_rate,theme.cs.darktext),
              TableRowPercentage (props.trsl['cash_coverage'], v_data.results.ra.available_cash_interest_coverage_rate,theme.cs.darktext),
              TableRowL1 (props.trsl['debt_service'], v_data.results.ra.debt_service,theme.cs.darktext),
              TableRowPercentage (props.trsl['ebtida_coverage'], v_data.results.ra.ebtida_debt_service_rate,theme.cs.darktext),
              TableRowPercentage (props.trsl['fcff_coverage'], v_data.results.ra.fcff_debt_service_rate,theme.cs.darktext),
              TableRowPercentage (props.trsl['cash_coverage'], v_data.results.ra.available_cash_debt_service_rate,theme.cs.darktext)
            ];
            debt_coverage_slide.addTable(dc_rows, { x: .2, y: 1.5, fontFace: theme.ft.body, colW:sizeOnizer(periods),margin:[2,2,2,2], valign:"middle"  });
          }
        }
      }
    }
  }

  var end_slide = pptx.addSlide("END");
  

  pptx.writeFile({fileName:'CASFLO EXPORT.pptx'})

  function getOpExp(input){
    let the_array=[]
    if(input.operating_expenses.length>0){
      for(var i=0;i<input.operating_expenses.length;i++){
        the_array[i]=TableRow(input.operating_expenses[i].op_exp_name,input.operating_expenses[i]._op_exp_total,theme.cs.darktext)
      }
    }
    return the_array
  }
}




function getProducts(input){
  let the_array=[]
  if(input.revenues.length>0){
    for(var i=0;i<input.revenues.length;i++){
      the_array[i]=[{text: input.revenues[i].rev_name, options: {align: "left"}}]
    }
  }
  return the_array
}

function getInvestments(input,years){
  let the_array=[]
  if(input.investments.length>0){
    for(var i=0;i<input.investments.length;i++){
      the_array[i]=[{text: years[input.investments[i].inv_year]}, {text:input.investments[i].inv_item_name}, {text: multiply(input.investments[i].inv_price,input.investments[i].inv_quantity)}]
    }
  }
  return the_array
}

function sumInvestments(input,locale ){
  let sum=0
  if(input.length>0){
    for(var i=0;i<input.length;i++){
      sum +=(parseFloat(input[i].inv_quantity*input[i].inv_price))
    }
  }
  return editValues(sum,locale ) 
}

function sumYearlyInvestments(input,years,locale){
  let sum=new Array(years.length).fill(0)
  if(input.length>0){
    for(var j=0;j<years.length;j++){
      for(var i=0;i<input.length;i++){
        if(input[i].inv_year===j)
        sum[j] +=(parseFloat(input[i].inv_quantity*input[i].inv_price))
      }
    }
  }
  let return_array=[]
  for(var e=0;e<years.length;e++){
    return_array[e]=years[e]+': '+ editValues(sum[e],locale)
  }
  return return_array 
}

function sumInvestmentSubsidy(input,locale ){
  let sum=0
  if(input!==undefined){
    for(var i=0;i<input.length;i++){
      sum +=(parseFloat(input[i]._inv_subsidy_comparticipation))
    }
  }
  return editValues(sum,locale ) 
}

function createArrayForICSlide(input,slicer,years){
  let bug=[]
  if(input!==undefined){
    for(var i=0;i<input.length;i++){
      if(input[i].shareholders_type===slicer){
        bug[i]={
          name:input[i].shareholders_item_name,
          labels:years,
          values:input[i]._shareholders_total
        }
      }
    }
  }  
  var filtered_bug = bug.filter(function (el) {
    return el != null;
  });
  return filtered_bug
}

function sumEquities(input,slicer,locale){
  let sum=0
  if(input!==undefined){
    for(var i=0;i<input.length;i++){
      if(input[i].shareholders_type===slicer){
        for(var j =0;j<input[i].shareholders_increase.length;j++){
          sum +=parseFloat(input[i].shareholders_increase[j])  
        }
      }
    }
  }
  return editValues(sum,locale) 
}

function sumSharePremium(input,slicer,locale){
  let sum=0
  if(input!==undefined){
    for(var i=0;i<input.length;i++){
      if(input[i].shareholders_type===slicer){
        for(var j =0;j<input[i].shareholders_increase.length;j++){
          sum +=parseFloat(input[i].shareholders_share_premium[j])  
        }
      }
    }
  }
  return editValues(sum,locale,0) 
}

function sumDebt(input,locale){
  let sum=0
  if(input.length>0){
    for(var i=0;i<input.length;i++){
      for(var j =0;j<input[i]._debt_disbursement.length;j++){ 
        sum +=(parseFloat(input[i]._debt_disbursement[j]))
      }
    }
  }
  return editValues(sum,locale) 
}

function editValues(input,locale){
  return (new Intl.NumberFormat(locale, { maximumFractionDigits:0,minimumFractionDigits:0 }).format(input))
}

function editPercentageValues(input,locale){
  return (new Intl.NumberFormat(locale, { style: 'percent', maximumFractionDigits:2,minimumFractionDigits:2 }).format(input))
}


class GenerateReportPPTX extends React.Component{
  
  handleGeneratePPTX=async()=>{ 
    const props={
      trsl:this.props.trsl,
      selected_template:this.props.selected_template,
      org:this.props.org,
      data:this.props.data,
      options:this.props.options,
      selected_versions:this.props.selected_versions,
      years:this.props.years,
      locale:this.props.locale,
      settings:this.props.settings,
      p_name:this.props.p_name,
      v_name:this.props.v_name
    }
    FeasibilityAnalysisPowerPoint(props)
  }

  render(){
    return(
      <Row>
        <Col xs={12} className="export_button">
          <RegularButton bk='bk_primary' onClick={this.handleGeneratePPTX} label={this.props.trsl['exporting_custom']} />
        </Col>
      </Row>
    )
  }
}

class GenerateVersionPPTReport extends React.Component{
  ppt_options={
    general:{
      project_description:true,
      historic:false
    },
    canvas:{
      swot:false,
      bmc:false
    },
    feasibility:{
      version_description:true,
      revenues_list:true,
      revenues_assumptions:true,
      revenues_graph:true,
      opex:true,
      hr:true,
      costs:true,
      investment:true,
      financing:true,
      shareholders:true,
      debt:true,
      feasibility_assumptions:true,
      is:true,
      bs:true,
      cs:true,
      ra:true,
      wc:true,
      fea:true,
      sap:true,
      lep:true,
      ivp:true,
      dc:true
    }
  }
  data_for_ppt={
    feasibility:{
      feasibility_versions:[this.props.data]
    }
  }
  
  handleGeneratePPTX=async()=>{ 

    const props={
      trsl:this.props.trsl,
      selected_template:0,
      org:this.props.org,
      data:this.data_for_ppt,
      options:this.ppt_options,
      selected_versions:['0'],
      years:this.props.years,
      locale:this.props.locale,
      settings:this.props.settings,
      p_name:this.props.p_name,
      v_name:this.props.v_name
    }
    FeasibilityAnalysisPowerPoint(props)
  }

  render(){
    return(
      <Row>
        <Col xs={6} className="export_button">
          <h5>PPTX</h5>
          <RegularButton bk='bk_primary' onClick={this.handleGeneratePPTX} label={this.props.trsl['exporting_custom']} />
        </Col>
      </Row>
    )
  }
}

export {
  GenerateReportPPTX,
  GenerateVersionPPTReport
};