import React from 'react';

import ApiConnect from 'CONFIG/axios';
import UserContext from 'CONFIG/auth';

import OtherBalanceCreator from '../../../../CONFIG/CREATORS/other_balance_creator';
import {MultipleFeasibilityListItems} from '../feasibility_items';


class OtherBalanceList extends React.Component{
  static contextType = UserContext

  handleAdd =async(item, element2)=>{
    let years=this.props.feasibility_settings.number_of_years
    if(this.props.feasibility_settings.use_historic===true){
      years=parseInt(this.props.feasibility_settings.number_of_years)+parseInt(this.props.feasibility_settings.number_of_historic_years)
    }
    const element_to_add= new OtherBalanceCreator(item,years, element2)
    this.props.handleLists.handleAdd(element_to_add, 'other_balance')
  }

  handleChangeType=(e)=>{
    let e1=e
    this.props.handleChangeType(e1)
  }

  render(){    
    const props ={
      data:this.props.version_data.other_balance,
      feasibility_settings:this.props.feasibility_settings,
      user_permission:this.props.user_permission,
      handleAdd:this.handleAdd, 
      handleScreenNavigation:this.props.handleScreenNavigation,
      handleDeleteItem: this.props.handleLists.handleDeleteItem,
      handleDuplicateItem:this.props.handleLists.handleDuplicateItem,
      handleChangLevel:this.props.handleLists.handleChangLevel,
      handleChangeType:this.handleChangeType,
      multi_option:this.props.multi_option,
      multitabs:this.props.multitabs,
      title:'other_bal_title',
      item_type:'other_balance'
    }
    return(
      <MultipleFeasibilityListItems {...props}/>
    )
  }
}

export default OtherBalanceList;