import React from 'react';
import {Row} from 'Support/grid';
import UserContext from 'CONFIG/auth';

import './CSS/loader.css';

class Loader extends React.Component{
  static contextType = UserContext

  render(){
    let user_c= this.context

    return(
      <Row className='grid_middle'>
        <Row  className='grid_middle main_loader'>
          <div className="subloader1"></div>
          <div className="subloader2"></div>
          <div className="subloader3"></div>
          <div className="subloader4"></div>
          <div className="subloader5"></div>
          <div className="subloader6"></div>
          <div className="subloader7"></div>
          <div className="subloader8"></div>
          <div className="subloader9"></div>
          <div className="subloader10"></div>
          <div className="subloader11"></div>
          <div className="subloader12"></div>
          <div className="subloader13"></div>
          <div className="subloader14"></div>
          <div className="subloader15"></div>
          <div className="subloader16"></div>
          <div className="subloader17"></div>
          <div className="subloader18"></div>
          <div className="subloader19"></div>
          <div className="subloader20"></div>
          <div className="subloader8"></div>
          <div className="subloader2"></div>
          <div className="subloader7"></div>
          <div className="subloader14"></div>
          <div className="subloader15"></div>
          <div className="subloader6"></div>
          <div className="subloader3"></div>
          <div className="subloader1"></div>
          <div className="subloader9"></div>
          <div className="subloader10"></div>
          <div className="subloader19"></div>
          <div className="subloader12"></div>
          <div className="subloader17"></div>
          <div className="subloader4"></div>
          <div className="subloader5"></div>
          <div className="subloader16"></div>
          <div className="subloader13"></div>
          <div className="subloader19"></div>
          <div className="subloader20"></div>
          <div className="subloader11"></div>
        </Row>
        <div className="loader_message">
          {user_c.i18n.t(`LOADER.${this.props.loader_message}`)}
        </div>        
      </Row>
    )
  }
}

class Loader2 extends React.Component{
  static contextType = UserContext
  render(){
    let user_c= this.context
    function showLoadedContent(props){
      if(props.data_loaded===false){
        return ''
      } else {
        return(
          <div>
            {props.children}
          </div>
        )
      }
    }
    return(
      <>
        {showLoadedContent(this.props)}
      </>
    )
  }
}



class NoContentLoader extends React.Component{
  render(){
    function showLoadedContent(props){
      if(props.data_loaded===false){
        return(
          <>
          {''}
          </>
        )
      } else {
        return(
          <div>
            {props.children}
          </div>
        )
      }
    }
    return(
      <>
        {showLoadedContent(this.props)}
      </>
    )
  }
}


export default Loader

export {
  Loader2,
  NoContentLoader
}