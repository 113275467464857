import color_sets from 'JSON/brand_color_sets.json'

//IMAGES FOR CF0
import casflo_0_logo from './exporting templates/casflo0/casflo_logo.png'
import casflo_0_intro from './exporting templates/casflo0/ppt_intro.png'
import casflo_0_end from './exporting templates/casflo0/ppt_end.png'

//IMAGES FOR CF1
import casflo_1_logo from './exporting templates/casflo1/casflo_logo.png'
import casflo_1_intro from './exporting templates/casflo1/ppt_intro.png'
import casflo_1_end from './exporting templates/casflo1/ppt_end.png'

//IMAGES FOR CF2
import casflo_2_logo from './exporting templates/casflo2/casflo_logo.png'
import casflo_2_intro from './exporting templates/casflo2/ppt_intro.png'
import casflo_2_end from './exporting templates/casflo2/ppt_end.png'

//IMAGES FOR CF3
import casflo_3_logo from './exporting templates/casflo3/casflo_logo.png'
import casflo_3_intro from './exporting templates/casflo3/ppt_intro.png'
import casflo_3_end from './exporting templates/casflo3/ppt_end.png'

//IMAGES FOR CF4
import casflo_4_logo from './exporting templates/casflo4/casflo_logo.png'
import casflo_4_intro from './exporting templates/casflo4/ppt_intro.png'
import casflo_4_end from './exporting templates/casflo4/ppt_end.png'

//IMAGES FOR CF5
import casflo_5_logo from './exporting templates/casflo5/casflo_logo.png'
import casflo_5_intro from './exporting templates/casflo5/ppt_intro.png'
import casflo_5_end from './exporting templates/casflo5/ppt_end.png'

//IMAGES FOR CF6
import casflo_6_logo from './exporting templates/casflo6/casflo_logo.png'
import casflo_6_intro from './exporting templates/casflo6/ppt_intro.png'
import casflo_6_end from './exporting templates/casflo6/ppt_end.png'

//IMAGES FOR VISEEON
import viseeon_front from 'TEMPLATES/Viseeon/Images/ppt_front.png'
import viseeon_midle from 'TEMPLATES/Viseeon/Images/viseeon_top.png'
import viseeon_end from 'TEMPLATES/Viseeon/Images/viseeon_end.png'

//IMAGES FOR Bazaar
import bazaar_front from './exporting templates/bazaar/ppt_front.png'
import bazaar_middle from './exporting templates/bazaar/ppt_middle.png'
import bazaar_end from './exporting templates/bazaar/ppt_end.png'

  //SLIDE MASTERS
function casflo_0(){
  this.ft={
    head:'Lato Black',
    body:'Lato',
    foot:"Lato Black"
  }
  this.cs={
    primary:'469cc0',
    secondary:'6db3bf',
    lightaccent:'94cfc9',
    lightshade:'EBEBE6',
    darkaccent:'20566e',
    darkshade:'183641',
    lighttext:'ffffff',
    darktext:'183641',
    black:'000000'
  }
  this.cover={
    title: "COVER",
    bkgd: this.cs.black,
    objects: [
      { image:{ x: 0, y: 0, w: '100%', h:'100%', path: casflo_0_intro}},
      {placeholder:{options: { name: "title1", type: "body", x: '5%', y: 1.5, w: '90%', h:0.5, fontFace:this.ft.head, fontSize:60, color:this.cs.lighttext, align:'center' }}},
      {placeholder:{options: { name: "title2", type: "body", x: '7.5%', y: .5, w: '82.5%', h:0.5, fontFace:this.ft.head, fontSize:15, color:this.cs.lighttext, align:'left' }}},
    ]
  }
  this.separator={
    title: "SEPARATOR",
    bkgd: this.cs.lightshade,
    objects: [
      { image:{ x: 9, y: 0.1, w: .5, h:.5, path: casflo_0_logo}},
      {line: { x: '5%', y: '95%', width: 1,  color:this.cs.black, dashType:'solid' } },
      {placeholder:{options: { name: "title1", type: "body", x: 0, y: 2, w: "100%", h:1.5, fontFace:this.ft.head, fontSize:50, color:this.cs.primary,valign:'middle', align:'center', autoFit:true  }}}
    ],
    slideNumber: { x: "0%", y: "95%", align:'center', w: '100%',h: '5%', valign:'middle', fontFace:this.ft.foot, fontSize:8, color:this.cs.lighttext } 
  }
  this.no_title={
    title: "NO TITLE",
    bkgd: this.cs.lightshade,
    objects: [
      { image:{ x: 9, y: 0.1, w: .5, h:.5, path: casflo_0_logo}},
      {rect: { x: 0, y: '95%', w: "100%", h: '5%', fill: { color:this.cs.primary } } },
    ],
    slideNumber: { x: "0%", y: "95%", align:'center', w: '100%',h: '5%', valign:'middle', fontFace:this.ft.foot, fontSize:8, color:this.cs.lighttext } 
  }
  this.one_title={
    title: "ONE TITLE",
    bkgd: this.cs.lightshade,
    objects: [
      { image:{ x: 9, y: 0.1, w: .5, h:.5, path: casflo_0_logo}},
      {rect: { x: 0, y: '95%', w: "100%", h: '5%', fill: { color:this.cs.primary } } },
      {line: { x: '5%', y: '95%', width: 1,  color:this.cs.black, dashType:'solid' } },
      {placeholder:{options: { name: "title1", type: "body", x: 0.20, y: .10, w: 9, h:0.5, fontFace:this.ft.head, fontSize:30, color:this.cs.primary }}}
    ],
    slideNumber: { x: "0%", y: "95%", align:'center', w: '100%',h: '5%', valign:'middle', fontFace:this.ft.foot, fontSize:8, color:this.cs.lighttext } 
  }
  this.two_title={
    title: "TWO TITLE",
    bkgd: this.cs.lightshade,
    objects: [
      { image:{ x: 9, y: 0.1, w: .5, h:.5, path: casflo_0_logo}},
      {rect: { x: 0, y: '95%', w: "100%", h: '5%', fill: { color:this.cs.primary } } },
      {line: { x: '5%', y: '95%', width: 1,  color:this.cs.black, dashType:'solid' } },
      {placeholder:{options: { name: "title1", type: "body", x: 0.20, y: .10, w: 9, h:0.5, fontFace:this.ft.head, fontSize:30,color:this.cs.primary }}},
      {placeholder:{options: { name: "title2", type: "body", x: 0.20, y: 1, w: 9, h:0.5, fontFace:this.ft.head, fontSize:18,color:this.cs.primary }}}
    ],
    slideNumber: { x: "0%", y: "95%", align:'center', w: '100%',h: '5%', valign:'middle', fontFace:this.ft.foot, fontSize:8, color:this.cs.lighttext } 
  }
  this.end={
    title: "END",
    bkgd: this.cs.lightshade,
    objects: [
      {image: { x: 0, y: 0,w:'100%', h:'100%', path: casflo_0_end } }
    ]
  }
}

function casflo_1(){
  this.ft={
    head:'Lato Light',
    body:'Lato Black',
    foot:"Lato Black"
  }
  this.cs={
    primary:'469cc0',
    secondary:'6db3bf',
    lightaccent:'94cfc9',
    lightshade:'FFFFFF',
    darkaccent:'20566e',
    darkshade:'183641',
    lighttext:'ffffff',
    darktext:'183641',
    black:'000000'
  }
  this.cover={
    title: "COVER",
    bkgd: this.cs.black,
    objects: [
      { image:{ x: 0, y: 0, w: '100%', h:'100%', path: casflo_1_intro}},
      {placeholder:{options: { name: "title1", type: "title", x: '0%', y: 2.5, w: '70%', h:2, fontFace:'Lato Black', fontSize:50, color:this.cs.darktext, valign:'middle', align:'center', autoFit:true }}},
      {rect:{ x: '0%', y: 2.5, w: '70%', h:2, fill:{color:'ffffff'}, }},
      {placeholder:{options: { name: "title2", type: "body", x: '7.5%', y: .5, w: '82.5%', h:0.5, fontFace:'Lato', fontSize:15, color:this.cs.darktext, align:'left' }}},
    ]
  }
  this.separator={
    title: "SEPARATOR",
    bkgd: this.cs.lightshade,
    objects: [
      { image:{ x: 9, y: 0.1, w: .5, h:.5, path: casflo_1_logo}},
      {line: { x: '5%', y: '95%', width: 1,  color:this.cs.black, dashType:'solid' } },
      { text: { text: "POWERED BY CASFLO", options: { x: 0.5, y: "97.5%", fontSize:8, color:this.cs.darktext, bold:true }}},
      {placeholder:{options: { name: "title1", type: "body", x: 0, y: 2, w: "100%", h:1.5, fontFace:'Lato Light', fontSize:50, color:this.cs.primary,valign:'middle', align:'center', autoFit:true  }}}
    ],
    slideNumber: { x: "50%", y: "95%", align:'center', h: '5%', valign:'middle', fontFace:'Lato Black', fontSize:8, color:this.cs.darktext }
  }
  this.no_title={
    title: "NO TITLE",
    bkgd: this.cs.lightshade,
    objects: [
      { image:{ x: 9, y: 0.1, w: .5, h:.5, path: casflo_1_logo}},
      { text: { text: "POWERED BY CASFLO", options: { x: 0.5, y: "97.5%", fontSize:8, color:this.cs.darkshade, bold:true }}},
    ],
    slideNumber: { x: "50%", y: "95%", align:'center', h: '5%', valign:'middle', fontFace:'Lato Black', fontSize:8, color:this.cs.darkshade } 
  }
  this.one_title={
    title: "ONE TITLE",
    bkgd: this.cs.lightshade,
    objects: [
      { image:{ x: 9, y: 0.1, w: .5, h:.5, path: casflo_1_logo}},
      {line: { x: '5%', y: '95%', width: 1,  color:this.cs.black, dashType:'solid' } },
      { text: { text: "POWERED BY CASFLO", options: { x: 0.5, y: "97.5%", fontSize:8, color:this.cs.darkshade, bold:true }}},
      {placeholder:{options: { name: "title1", type: "body", x: 0.20, y: .10, w: 9, h:0.5, fontFace:'Lato Light', fontSize:30, color:this.cs.primary }}}
    ],
    slideNumber: { x: "50%", y: "95%", align:'center', h: '5%', valign:'middle', fontFace:'Lato Black', fontSize:8, color:this.cs.darkshade }
  }
  this.two_title={
    title: "TWO TITLE",
    bkgd: this.cs.lightshade,
    objects: [
      { image:{ x: 9, y: 0.1, w: .5, h:.5, path: casflo_1_logo}},
      {line: { x: '5%', y: '95%', width: 1,  color:this.cs.black, dashType:'solid' } },
      { text: { text: "POWERED BY CASFLO", options: { x: 0.5, y: "97.5%", fontSize:8, color: this.cs.darkshade, bold:true }}},
      {placeholder:{options: { name: "title1", type: "body", x: 0.20, y: .10, w: 9, h:0.5, fontFace:'Lato Light', fontSize:30,color:this.cs.primary }}},
      {placeholder:{options: { name: "title2", type: "body", x: 0.20, y: 1, w: 9, h:0.5, fontFace:'Lato', fontSize:18,color:this.cs.primary }}}
    ],
    slideNumber: { x: "50%", y: "95%", align:'center', h: '5%', valign:'middle', fontFace:'Lato Black', fontSize:8, color: this.cs.darkshade } 
  }
  this.end={
    title: "END",
    bkgd: this.cs.lightshade,
    objects: [
      {image: { x: 0, y: 0,w:'100%', h:'100%', path: casflo_1_end } }
    ]
  }
}

function casflo_2(){
  this.ft={
    head:'Lato Light',
    body:'Lato Black',
    foot:"Lato Black"
  }
  this.cs={
    primary:'469cc0',
    secondary:'6db3bf',
    lightaccent:'183641',
    lightshade:'20566e',
    darkaccent:'FFFFFF',
    darkshade:'94cfc9',
    lighttext:'ffffff',
    darktext:'ffffff',
    black:'000000'
  }
  this.cover={
    title: "COVER",
    bkgd: this.cs.black,
    objects: [
      { image:{ x: 0, y: 0, w: '100%', h:'100%', path: casflo_2_intro}},
      {placeholder:{options: { name: "title1", type: "title", x: '0%', y: 2.5, w: '70%', h:2, fontFace:'Lato Black', fontSize:50, color:this.cs.primary, valign:'middle', align:'center', autoFit:true }}},
      {rect:{ x: '0%', y: 2.5, w: '70%', h:2, fill:{color:'ffffff'}, }},
      {placeholder:{options: { name: "title2", type: "body", x: '7.5%', y: .5, w: '82.5%', h:0.5, fontFace:'Lato', fontSize:15, color:this.cs.darktext, align:'left' }}},
    ]
  }
  this.separator={
    title: "SEPARATOR",
    bkgd: this.cs.black,
    objects: [
      { image:{ x: 9, y: 0.1, w: .5, h:.5, path: casflo_2_logo}},
      {line: { x: '5%', y: '95%', width: 1,  color:this.cs.black, dashType:'solid' } },
      { text: { text: "POWERED BY CASFLO", options: { x: 0.5, y: "97.5%", fontSize:8, color:this.cs.darktext, bold:true }}},
      {placeholder:{options: { name: "title1", type: "body", x: 0, y: 2, w: "100%", h:1.5, fontFace:'Lato Light', fontSize:50, color:this.cs.primary,valign:'middle', align:'center', autoFit:true  }}}
    ],
    slideNumber: { x: "50%", y: "95%", align:'center', h: '5%', valign:'middle', fontFace:'Lato Black', fontSize:8, color:this.cs.darktext }
  }
  this.no_title={
    title: "NO TITLE",
    bkgd: this.cs.black,
    objects: [
      { text: { text: "POWERED BY CASFLO", options: { x: 0.5, y: "97.5%", fontSize:8, color:this.cs.darktext, bold:true }}},
    ],
    slideNumber: { x: "50%", y: "95%", align:'center', h: '5%', valign:'middle', fontFace:'Lato Black', fontSize:8, color:this.cs.darktext } 
  }
  this.one_title={
    title: "ONE TITLE",
    bkgd: this.cs.black,
    objects: [
      { image:{ x: 9, y: 0.1, w: .5, h:.5, path: casflo_2_logo}},
      {line: { x: '5%', y: '95%', width: 1,  color:this.cs.black, dashType:'solid' } },
      { text: { text: "POWERED BY CASFLO", options: { x: 0.5, y: "97.5%", fontSize:8, color:this.cs.darktext, bold:true }}},
      {placeholder:{options: { name: "title1", type: "body", x: 0.20, y: .10, w: 9, h:0.5, fontFace:'Lato Light', fontSize:30, color:this.cs.primary }}}
    ],
    slideNumber: { x: "50%", y: "95%", align:'center', h: '5%', valign:'middle', fontFace:'Lato Black', fontSize:8, color:this.cs.darktext }
  }
  this.two_title={
    title: "TWO TITLE",
    bkgd: this.cs.black,
    objects: [
      { image:{ x: 9, y: 0.1, w: .5, h:.5, path: casflo_2_logo}},
      {line: { x: '5%', y: '95%', width: 1,  color:this.cs.black, dashType:'solid' } },
      { text: { text: "POWERED BY CASFLO", options: { x: 0.5, y: "97.5%", fontSize:8, color: this.cs.darktext, bold:true }}},
      {placeholder:{options: { name: "title1", type: "body", x: 0.20, y: .10, w: 9, h:0.5, fontFace:'Lato Light', fontSize:30,color:this.cs.primary }}},
      {placeholder:{options: { name: "title2", type: "body", x: 0.20, y: 1, w: 9, h:0.5, fontFace:'Lato', fontSize:18,color:this.cs.primary }}}
    ],
    slideNumber: { x: "50%", y: "95%", align:'center', h: '5%', valign:'middle', fontFace:'Lato Black', fontSize:8, color: this.cs.darktext } 
  }
  this.end={
    title: "END",
    bkgd: this.cs.black,
    objects: [
      {image: { x: 0, y: 0,w:'100%', h:'100%', path: casflo_2_end } }
    ]
  }
}

function casflo_3(){
  this.ft={
    head:'Lato Light',
    body:'Lato Black',
    foot:"Lato Black"
  }
  this.cs={
    primary:'469cc0',
    secondary:'6db3bf',
    lightaccent:'94cfc9',
    lightshade:'FFFFFF',
    darkaccent:'20566e',
    darkshade:'183641',
    lighttext:'ffffff',
    darktext:'183641',
    black:'000000'
  }
  this.cover={
    title: "COVER",
    bkgd: this.cs.lightshade,
    objects: [
      { image:{ x: 0, y: 0, w: '100%', h:'100%', path: casflo_3_intro}},
      {placeholder:{options: { name: "title1", type: "title", x: '10%', y: 2.5, w: '90%', h:2, fontFace:'Lato Black', fontSize:50, color:this.cs.darktext, valign:'middle', align:'left', autoFit:true }}},
      {placeholder:{options: { name: "title2", type: "body", x: '10%', y: 1, w: '90%', h:0.5, fontFace:'Lato Black', fontSize:20, color:this.cs.darktext, align:'left' }}},
    ]
  }
  this.separator={
    title: "SEPARATOR",
    bkgd: this.cs.lightshade,
    objects: [
      { image:{ x: 9, y: 0.1, w: .5, h:.5, path: casflo_3_logo}},
      {line: { x: '5%', y: '95%', width: 1,  color:this.cs.black, dashType:'solid' } },
      { text: { text: "POWERED BY CASFLO", options: { x: 0.5, y: "97.5%", fontSize:8, color:this.cs.darktext, bold:true }}},
      {placeholder:{options: { name: "title1", type: "body", x: 0, y: 2, w: "100%", h:1.5, fontFace:'Lato Light', fontSize:50, color:this.cs.primary,valign:'middle', align:'center', autoFit:true  }}}
    ],
    slideNumber: { x: "50%", y: "95%", align:'center', h: '5%', valign:'middle', fontFace:'Lato Black', fontSize:8, color:this.cs.darktext }
  }
  this.no_title={
    title: "NO TITLE",
    bkgd: this.cs.lightshade,
    objects: [
      { image:{ x: 9, y: 0.1, w: .5, h:.5, path: casflo_3_logo}},
      { text: { text: "POWERED BY CASFLO", options: { x: 0.5, y: "97.5%", fontSize:8, color:this.cs.darkshade, bold:true }}},
    ],
    slideNumber: { x: "50%", y: "95%", align:'center', h: '5%', valign:'middle', fontFace:'Lato Black', fontSize:8, color:this.cs.darkshade } 
  }
  this.one_title={
    title: "ONE TITLE",
    bkgd: this.cs.lightshade,
    objects: [
      { image:{ x: 9, y: 0.1, w: .5, h:.5, path: casflo_3_logo}},
      {line: { x: '5%', y: '95%', width: 1,  color:this.cs.black, dashType:'solid' } },
      { text: { text: "POWERED BY CASFLO", options: { x: 0.5, y: "97.5%", fontSize:8, color:this.cs.darkshade, bold:true }}},
      {placeholder:{options: { name: "title1", type: "body", x: 0.20, y: .10, w: 9, h:0.5, fontFace:'Lato Light', fontSize:30, color:this.cs.primary }}}
    ],
    slideNumber: { x: "50%", y: "95%", align:'center', h: '5%', valign:'middle', fontFace:'Lato Black', fontSize:8, color:this.cs.darkshade }
  }
  this.two_title={
    title: "TWO TITLE",
    bkgd: this.cs.lightshade,
    objects: [
      { image:{ x: 9, y: 0.1, w: .5, h:.5, path: casflo_3_logo}},
      {line: { x: '5%', y: '95%', width: 1,  color:this.cs.black, dashType:'solid' } },
      { text: { text: "POWERED BY CASFLO", options: { x: 0.5, y: "97.5%", fontSize:8, color: this.cs.darkshade, bold:true }}},
      {placeholder:{options: { name: "title1", type: "body", x: 0.20, y: .10, w: 9, h:0.5, fontFace:'Lato Light', fontSize:30,color:this.cs.primary }}},
      {placeholder:{options: { name: "title2", type: "body", x: 0.20, y: 1, w: 9, h:0.5, fontFace:'Lato', fontSize:18,color:this.cs.primary }}}
    ],
    slideNumber: { x: "50%", y: "95%", align:'center', h: '5%', valign:'middle', fontFace:'Lato Black', fontSize:8, color: this.cs.darkshade } 
  }
  this.end={
    title: "END",
    bkgd: this.cs.lightshade,
    objects: [
      {image: { x: 0, y: 0,w:'100%', h:'100%', path: casflo_3_end } }
    ]
  }
}

function casflo_4(){
  this.ft={
    head:'Arial Black',
    body:'Courier New',
    foot:"Arial Black"
  }
  this.cs={
    primary:'E68101',
    secondary:'0C7C59',
    lightaccent:'58A4B0',
    lightshade:'BAC1B8',
    darkaccent:'AC3931',
    darkshade:'2B303A',
    lighttext:'ffffff',
    darktext:'0C7C59',
    black:'000000'
  }
  this.cover={
    title: "COVER",
    bkgd: this.cs.lightshade,
    objects: [
      { image:{ x: 0, y: 0, w: '100%', h:'100%', path: casflo_4_intro}},
      {placeholder:{options: { name: "title1", type: "title", x: '10%', y: 2.5, w: '80%', h:2, fontFace:this.ft.body, fontSize:50, color:this.cs.secondary, valign:'middle', align:'right', autoFit:true }}},
      {placeholder:{options: { name: "title2", type: "body", x: '10%', y: 1, w: '80%', h:0.5, fontFace:this.ft.head, fontSize:20, color:this.cs.secondary, align:'right' }}},
    ]
  }
  this.separator={
    title: "SEPARATOR",
    bkgd: this.cs.secondary,
    objects: [
      { image:{ x: 9, y: 0.1, w: .5, h:.5, path: casflo_4_logo}},
      { text: { text: "POWERED BY CASFLO", options: { x: 0.5, y: "97.5%", fontSize:8, color:this.cs.lighttext, fontFace:this.ft.foot }}},
      {placeholder:{options: { name: "title1", type: "body", x: 0, y: 2, w: "100%", h:1.5, fontFace:this.ft.head, fontSize:50, color:this.cs.primary,valign:'middle', align:'center', autoFit:true  }}}
    ],
    slideNumber: { x: "50%", y: "95%", align:'center', h: '5%', valign:'middle', fontFace:this.ft.foot, fontSize:8, color:this.cs.lighttext }
  }
  this.no_title={
    title: "NO TITLE",
    bkgd: this.cs.lightshade,
    objects: [
      { image:{ x: 9, y: 0.1, w: .5, h:.5, path: casflo_4_logo}},
      { text: { text: "POWERED BY CASFLO", options: { x: 0.5, y: "97.5%", fontSize:8, color:this.cs.lighttext, fontFace:this.ft.foot }}},
    ],
    slideNumber: { x: "50%", y: "95%", align:'center', h: '5%', valign:'middle', fontFace:this.ft.foot, fontSize:8, color:this.cs.lighttext } 
  }
  this.one_title={
    title: "ONE TITLE",
    bkgd: this.cs.lightshade,
    objects: [
      { image:{ x: 9, y: 0.1, w: .5, h:.5, path: casflo_4_logo}},
      { text: { text: "POWERED BY CASFLO", options: { x: 0.5, y: "97.5%", fontSize:8, color:this.cs.lighttext, fontFace:this.ft.foot }}},
      {placeholder:{options: { name: "title1", type: "body", x: 0.20, y: .10, w: 9, h:0.5, fontFace:this.ft.head, fontSize:25, color:this.cs.primary }}}
    ],
    slideNumber: { x: "50%", y: "95%", align:'center', h: '5%', valign:'middle', fontFace:this.ft.foot, fontSize:8, color:this.cs.lighttext }
  }
  this.two_title={
    title: "TWO TITLE",
    bkgd: this.cs.lightshade,
    objects: [
      { image:{ x: 9, y: 0.1, w: .5, h:.5, path: casflo_4_logo}},
      { text: { text: "POWERED BY CASFLO", options: { x: 0.5, y: "97.5%", fontSize:8, color: this.cs.lighttext, fontFace:this.ft.foot, }}},
      {placeholder:{options: { name: "title1", type: "body", x: 0.20, y: .10, w: 9, h:0.5, fontFace:this.ft.head, fontSize:25,color:this.cs.primary }}},
      {placeholder:{options: { name: "title2", type: "body", x: 0.20, y: 1, w: 9, h:0.5, fontFace:this.ft.body, fontSize:18,color:this.cs.primary }}}
    ],
    slideNumber: { x: "50%", y: "95%", align:'center', h: '5%', valign:'middle', fontFace:this.ft.foot, fontSize:8, color: this.cs.lighttext } 
  }
  this.end={
    title: "END",
    bkgd: this.cs.lightshade,
    objects: [
      {image: { x: 0, y: 0,w:'100%', h:'100%', path: casflo_3_end } }
    ]
  }
}

function casflo_5(){
  this.ft={
    head:'Verdana',
    body:'Verdana',
    foot:"Verdana"
  }
  this.cs={
    primary:'469cc0',
    secondary:'6db3bf',
    lightaccent:'94cfc9',
    lightshade:'FFFFFF',
    darkaccent:'20566e',
    darkshade:'183641',
    lighttext:'ffffff',
    darktext:'183641',
    black:'000000'
  }
  this.cover={
    title: "COVER",
    bkgd: this.cs.lightshade,
    objects: [
      { image:{ x: 0, y: 0, w: '100%', h:'100%', path: casflo_5_intro}},
      {placeholder:{options: { name: "title1", type: "title", x: '74%', y: 2.5, w: '26%', h:2, fontFace:this.ft.body, fontSize:18, color:this.cs.secondary, valign:'middle', align:'center', autoFit:true }}},
      {placeholder:{options: { name: "title2", type: "body", x: '74%', y: 1, w: '26%', h:0.5, fontFace:this.ft.head, fontSize:12, color:this.cs.secondary, align:'center' }}},
    ]
  }
  this.separator={
    title: "SEPARATOR",
    bkgd: this.cs.secondary,
    objects: [
      { image:{ x: 9, y: 0.1, w: .5, h:.5, path: casflo_5_logo}},
      { text: { text: "POWERED BY CASFLO", options: { x: 0.5, y: "97.5%", fontSize:8, color:this.cs.darktext, fontFace:this.ft.foot }}},
      {placeholder:{options: { name: "title1", type: "body", x: 0, y: 2, w: "100%", h:1.5, fontFace:this.ft.head, fontSize:50, color:this.cs.lighttext,valign:'middle', align:'center', autoFit:true  }}}
    ],
    slideNumber: { x: "50%", y: "95%", align:'center', h: '5%', valign:'middle', fontFace:this.ft.foot, fontSize:8, color:this.cs.darktext }
  }
  this.no_title={
    title: "NO TITLE",
    bkgd: this.cs.lightshade,
    objects: [
      { image:{ x: 9, y: 0.1, w: .5, h:.5, path: casflo_5_logo}},
      { text: { text: "POWERED BY CASFLO", options: { x: 0.5, y: "97.5%", fontSize:8, color:this.cs.darktext, fontFace:this.ft.foot }}},
    ],
    slideNumber: { x: "50%", y: "95%", align:'center', h: '5%', valign:'middle', fontFace:this.ft.foot, fontSize:8, color:this.cs.darktext } 
  }
  this.one_title={
    title: "ONE TITLE",
    bkgd: this.cs.lightshade,
    objects: [
      { image:{ x: 9, y: 0.1, w: .5, h:.5, path: casflo_5_logo}},
      { text: { text: "POWERED BY CASFLO", options: { x: 0.5, y: "97.5%", fontSize:8, color:this.cs.darktext, fontFace:this.ft.foot }}},
      {placeholder:{options: { name: "title1", type: "body", x: 0.20, y: .10, w: 9, h:0.5, fontFace:this.ft.head, fontSize:25, color:this.cs.primary }}}
    ],
    slideNumber: { x: "50%", y: "95%", align:'center', h: '5%', valign:'middle', fontFace:this.ft.foot, fontSize:8, color:this.cs.darktext }
  }
  this.two_title={
    title: "TWO TITLE",
    bkgd: this.cs.lightshade,
    objects: [
      { image:{ x: 9, y: 0.1, w: .5, h:.5, path: casflo_5_logo}},
      { text: { text: "POWERED BY CASFLO", options: { x: 0.5, y: "97.5%", fontSize:8, color: this.cs.darktext, fontFace:this.ft.foot, }}},
      {placeholder:{options: { name: "title1", type: "body", x: 0.20, y: .10, w: 9, h:0.5, fontFace:this.ft.head, fontSize:25,color:this.cs.primary }}},
      {placeholder:{options: { name: "title2", type: "body", x: 0.20, y: 1, w: 9, h:0.5, fontFace:this.ft.body, fontSize:18,color:this.cs.primary }}}
    ],
    slideNumber: { x: "50%", y: "95%", align:'center', h: '5%', valign:'middle', fontFace:this.ft.foot, fontSize:8, color: this.cs.darktext } 
  }
  this.end={
    title: "END",
    bkgd: this.cs.lightshade,
    objects: [
      {image: { x: 0, y: 0,w:'100%', h:'100%', path: casflo_5_end } }
    ]
  }
}

function casflo_6(){
  this.ft={
    head:'Lato Light',
    body:'Lato Black',
    foot:"Lato Black"
  }
  this.cs={
    primary:'469cc0',
    secondary:'6db3bf',
    lightaccent:'183641',
    lightshade:'20566e',
    darkaccent:'FFFFFF',
    darkshade:'94cfc9',
    lighttext:'ffffff',
    darktext:'ffffff',
    black:'000000'
  }
  this.cover={
    title: "COVER",
    bkgd: this.cs.black,
    objects: [
      { image:{ x: 0, y: 0, w: '100%', h:'100%', path: casflo_6_intro}},
      {placeholder:{options: { name: "title1", type: "title", x: '70%', y: .5, w: '25%', h:2, fontFace:'Lato Black', fontSize:25, color:this.cs.primary, align:'center', autoFit:true }}},
      {placeholder:{options: { name: "title2", type: "body", x: '70%', y: 3.5, w: '25%', h:0.5, fontFace:'Lato', fontSize:12, color:this.cs.primary, align:'center' }}},
    ]
  }
  this.separator={
    title: "SEPARATOR",
    bkgd: this.cs.black,
    objects: [
      { image:{ x: 9, y: 0.1, w: .5, h:.5, path: casflo_6_logo}},
      {line: { x: '5%', y: '95%', width: 1,  color:this.cs.black, dashType:'solid' } },
      { text: { text: "POWERED BY CASFLO", options: { x: 0.5, y: "97.5%", fontSize:8, color:this.cs.darktext, bold:true }}},
      {placeholder:{options: { name: "title1", type: "body", x: 0, y: 2, w: "100%", h:1.5, fontFace:'Lato Light', fontSize:50, color:this.cs.primary,valign:'middle', align:'center', autoFit:true  }}}
    ],
    slideNumber: { x: "50%", y: "95%", align:'center', h: '5%', valign:'middle', fontFace:'Lato Black', fontSize:8, color:this.cs.darktext }
  }
  this.no_title={
    title: "NO TITLE",
    bkgd: this.cs.black,
    objects: [
      { text: { text: "POWERED BY CASFLO", options: { x: 0.5, y: "97.5%", fontSize:8, color:this.cs.darktext, bold:true }}},
    ],
    slideNumber: { x: "50%", y: "95%", align:'center', h: '5%', valign:'middle', fontFace:'Lato Black', fontSize:8, color:this.cs.darktext } 
  }
  this.one_title={
    title: "ONE TITLE",
    bkgd: this.cs.black,
    objects: [
      { image:{ x: 9, y: 0.1, w: .5, h:.5, path: casflo_6_logo}},
      {line: { x: '5%', y: '95%', width: 1,  color:this.cs.black, dashType:'solid' } },
      { text: { text: "POWERED BY CASFLO", options: { x: 0.5, y: "97.5%", fontSize:8, color:this.cs.darktext, bold:true }}},
      {placeholder:{options: { name: "title1", type: "body", x: 0.20, y: .10, w: 9, h:0.5, fontFace:'Lato Light', fontSize:30, color:this.cs.primary }}}
    ],
    slideNumber: { x: "50%", y: "95%", align:'center', h: '5%', valign:'middle', fontFace:'Lato Black', fontSize:8, color:this.cs.darktext }
  }
  this.two_title={
    title: "TWO TITLE",
    bkgd: this.cs.black,
    objects: [
      { image:{ x: 9, y: 0.1, w: .5, h:.5, path: casflo_6_logo}},
      {line: { x: '5%', y: '95%', width: 1,  color:this.cs.black, dashType:'solid' } },
      { text: { text: "POWERED BY CASFLO", options: { x: 0.5, y: "97.5%", fontSize:8, color: this.cs.darktext, bold:true }}},
      {placeholder:{options: { name: "title1", type: "body", x: 0.20, y: .10, w: 9, h:0.5, fontFace:'Lato Light', fontSize:30,color:this.cs.primary }}},
      {placeholder:{options: { name: "title2", type: "body", x: 0.20, y: 1, w: 9, h:0.5, fontFace:'Lato', fontSize:18,color:this.cs.primary }}}
    ],
    slideNumber: { x: "50%", y: "95%", align:'center', h: '5%', valign:'middle', fontFace:'Lato Black', fontSize:8, color: this.cs.darktext } 
  }
  this.end={
    title: "END",
    bkgd: this.cs.black,
    objects: [
      {image: { x: 0, y: 0,w:'100%', h:'100%', path: casflo_2_end } }
    ]
  }
}

const CASFLO={
  0:casflo_0,
  1:casflo_1,
  2:casflo_2,
  3:casflo_3,
  4:casflo_4,
  5:casflo_5,
  6:casflo_6
}

function VISEEON(){
  this.ft={
    head:'Arial Black',
    body:'Arial',
    foot:"Arial Black"
  }
  this.cs={
    primary:'ec6a2d',
    secondary:'ffffff',
    lightaccent:'595958',
    lightshade:'242423',
    darkaccent:'E43240',
    darkshade:'3a3335',
    lighttext:'fdf0d5',
    darktext:'3a3335',
    black:"000000"
  }
  this.cover={
    title: "COVER",
    bkgd: this.cs.darkshade,
    objects: [
      {placeholder:{options: { name: "title1", type: "body", x: '5%', y: 2, w: '90%', h:0.5, fontFace:'Source Sans Pro', bold:true, fontSize:30, color:this.cs.primary, align:'center' }}},
      {placeholder:{options: { name: "title2", type: "body", x: '2.5%', y: .8, w: '95%', h:0.5, fontFace:'Source Sans Pro', fontSize:12, color:this.cs.lightaccent, align:'right' }}},
      { image:{ x: 0, y: 0, w: '100%', h:'100%', path: viseeon_front}}
    ]
  }
  this.separator={
    title: "SEPARATOR",
    bkgd: this.cs.lightshade,
    objects: [
      { image: { x: 0, y: 0,w:'100%', h:'100%', path: viseeon_midle }},
      {line: { x: '5%', y: '95%', width: 1,  color:this.cs.black, dashType:'solid' } },
      { text: { text: "POWERED BY CASFLO", options: { x: 0.5, y: "97.5%", fontSize:8, color:this.cs.darktext, bold:true }}},
      {placeholder:{options: { name: "title1", type: "body", x: 0, y: 2, w: "100%", h:1.5, fontFace:'Lato Light', fontSize:50, color:this.cs.primary,valign:'middle', align:'center', autoFit:true  }}}
    ],
    slideNumber: { x: "50%", y: "95%", align:'center', h: '5%', valign:'middle', fontFace:'Lato Black', fontSize:8, color:this.cs.darktext }
  }
  this.no_title={
    title: "NO TITLE",
    bkgd: this.cs.lightshade,
    objects: [
      {image: { x: 0, y: 0,w:'100%', h:'100%', path: viseeon_midle } }    
    ],
    slideNumber: { x: "50%", y: "96%", align:'center', h: '4%', valign:'middle', fontFace:'Lato Black', fontSize:8, color:this.cs.lighttext } 
  }
  this.one_title={
    title: "ONE TITLE",
    bkgd: this.cs.lightshade,
    objects: [
      {image: { x: 0, y: 0,w:'100%', h:'100%', path: viseeon_midle } },
      {placeholder:{options: { name: "title1", type: "body", x: 0.20, y: 0.1, w: 9, h:0.5, fontFace:'Source Sans Pro', bold:true, fontSize:25,color:this.cs.primary }}}
    ],
    slideNumber: { x: "50%", y: "96%", align:'center', h: '4%', valign:'middle', fontFace:'Lato Black', fontSize:8, color:this.cs.lighttext } 
  }
  this.two_title={
    title: "TWO TITLE",
    bkgd: this.cs.lightshade,
    objects: [
      {image: { x: 0, y: 0,w:'100%', h:'100%', path: viseeon_midle } },
      {placeholder:{options: { name: "title1", type: "body", x: 0.20, y: 0.1, w: 9, h:0.5, fontFace:'Source Sans Pro', bold:true, fontSize:25,color:this.cs.primary }}},
      {placeholder:{options: { name: "title2", type: "body", x: 0.20, y: 1, w: 9, h:0.5, fontFace:'Source Sans Pro', bold:true, fontSize:18,color:this.cs.primary }}}
    ],
    slideNumber: { x: "50%", y: "96%", align:'center', h: '4%', valign:'middle', fontFace:'Lato Black', fontSize:8, color:this.cs.lighttext } 
  }
  this.end={
    title: "END",
    bkgd: this.cs.lightshade,
    objects: [
      {image: { x: 0, y: 0,w:'100%', h:'100%', path: viseeon_end }}
    ]
  }
}

function BAZAAR(){
  this.ft={
    head:'Verdana',
    body:'Verdana',
    foot:"Verdana"
  }
  this.cs={
    primary:'7D8BEF',
    secondary:'4ab594',
    lightaccent:'ff8c17',
    lightshade:'f7f7f7',
    darkaccent:'606060',
    darkshade:'000000',
    lighttext:'ffffff',
    darktext:'606060',
    black:"000000"
  }
  this.cover={
    title: "COVER",
    bkgd: this.cs.darkshade,
    objects: [
      {placeholder:{options: { name: "title1", type: "body", x: '5%', y: 2, w: '90%', h:0.5, fontFace:this.ft.head, bold:true, fontSize:30, color:this.cs.primary, align:'center' }}},
      {placeholder:{options: { name: "title2", type: "body", x: '2.5%', y: .8, w: '95%', h:0.5, fontFace:this.ft.head, fontSize:12, color:this.cs.primary, align:'center' }}},
      { image:{ x: 0, y: 0, w: '100%', h:'100%', path: bazaar_front}}
    ]
  }
  this.separator={
    title: "SEPARATOR",
    bkgd: this.cs.lightshade,
    objects: [
      { image: { x: 0, y: 0,w:'100%', h:'100%', path: bazaar_middle }},
      {line: { x: '5%', y: '95%', width: 1,  color:this.cs.black, dashType:'solid' } },
      { text: { text: "POWERED BY CASFLO", options: { x: 0.5, y: "97.5%", fontSize:8, color:this.cs.darktext, bold:true }}},
      {placeholder:{options: { name: "title1", type: "body", x: 0, y: 2, w: "100%", h:1.5, fontFace:this.ft.head, fontSize:50, color:this.cs.primary,valign:'middle', align:'center', autoFit:true  }}}
    ],
    slideNumber: { x: "50%", y: "95%", align:'center', h: '5%', valign:'middle', fontFace:this.ft.head, fontSize:8, color:this.cs.darktext }
  }
  this.no_title={
    title: "NO TITLE",
    bkgd: this.cs.lightshade,
    objects: [
      {image: { x: 0, y: 0,w:'100%', h:'100%', path: bazaar_middle } }    
    ],
    slideNumber: { x: "50%", y: "96%", align:'center', h: '4%', valign:'middle', fontFace:this.ft.head, fontSize:8, color:this.cs.lighttext } 
  }
  this.one_title={
    title: "ONE TITLE",
    bkgd: this.cs.lightshade,
    objects: [
      {image: { x: 0, y: 0,w:'100%', h:'100%', path: bazaar_middle } },
      {placeholder:{options: { name: "title1", type: "body", x: 0.20, y: 0.1, w: 9, h:0.5, fontFace:this.ft.head, bold:true, fontSize:22,color:this.cs.primary }}}
    ],
    slideNumber: { x: "50%", y: "96%", align:'center', h: '4%', valign:'middle', fontFace:this.ft.head, fontSize:8, color:this.cs.lighttext } 
  }
  this.two_title={
    title: "TWO TITLE",
    bkgd: this.cs.lightshade,
    objects: [
      {image: { x: 0, y: 0,w:'100%', h:'100%', path: bazaar_middle } },
      {placeholder:{options: { name: "title1", type: "body", x: 0.20, y: 0.1, w: 9, h:0.5, fontFace:this.ft.head, bold:true, fontSize:22,color:this.cs.primary }}},
      {placeholder:{options: { name: "title2", type: "body", x: 0.20, y: 1, w: 9, h:0.5, fontFace:this.ft.head, bold:true, fontSize:18,color:this.cs.primary }}}
    ],
    slideNumber: { x: "50%", y: "96%", align:'center', h: '4%', valign:'middle', fontFace:this.ft.head, fontSize:8, color:this.cs.lighttext } 
  }
  this.end={
    title: "END",
    bkgd: this.cs.lightshade,
    objects: [
      {image: { x: 0, y: 0,w:'100%', h:'100%', path: bazaar_end }}
    ]
  }
}



const templates={
  casflo:CASFLO,
  viseeon:VISEEON,
  bazaar:BAZAAR
}

  export default templates