import React, { useState,useContext } from 'react';
import {Row, Col, Col_NoGutter} from 'Support/grid';
import UserContext from 'CONFIG/auth';

const checker=(reference)=>{
  if (isNaN(reference)){
  return 0
  } else{return reference}
}

function ToogleButtons(props){
  const item_holder=props.toogle_data
  const selected=props.selected
  let item_distr=''
  function show_toogleButtons(input,selected){
    let item
    if(input!=undefined){
      item=input.map((it)=>{
        if(it.value==selected){
          return(
            <ToogleButton name={it.name} value={it.value} onClick={props.handleToogleButton} selected={true} disabled={it.disabled}>
              {it.option_label}
            </ToogleButton>
          )
        } else{
          return(
            <ToogleButton name={it.name} value={it.value} onClick={props.handleToogleButton} selected={false} disabled={it.disabled}>
              {it.option_label}
            </ToogleButton>
          )
        }
      })
    }
    return item
  }
  item_distr=show_toogleButtons(item_holder,selected)

  const direction={
    true:'flex_column'
  }
  
  return(
    <Col_NoGutter xs={props.xs} md={props.md}>
      <div className='support_buttons_TB_group'>
        <div className='support_buttons_TB_label'>{props.label}</div>
          <div className={`support_buttons_TB_div ${direction[props.column]}`}>
          {item_distr}
        </div>
      </div>
    </Col_NoGutter>
  )
}

function ToogleButtonsWithNotice(props){
  const item_holder=props.toogle_data
  const selected=props.selected
  let item_distr=''
  function show_toogleButtons(input,selected){
    let item
    if(input!=undefined){
      item=input.map((it)=>{
        if(it.value==selected){
          return(
            <ToogleButton name={it.name} value={it.value} onClick={props.handleToogleButton} selected={true} disabled={it.disabled}>
              {it.option_label}
            </ToogleButton>
          )
        } else{
          return(
            <ToogleButton name={it.name} value={it.value} onClick={props.handleToogleButton} selected={false} disabled={it.disabled}>
              {it.option_label}
            </ToogleButton>
          )
        }
      })
    }
    return item
  }
  item_distr=show_toogleButtons(item_holder,selected)

  const direction={
    true:'flex_column'
  }
  
  return(
    <Col xs={props.xs} md={props.md}>
      <div className='support_buttons_TB_group'>
        <div className='support_buttons_TB_label'>{props.label}</div>
          <div className={`support_buttons_TB_div ${direction[props.column]}`}>
          {item_distr}
        </div>
        <div className='support_buttons_TB_notice bk_info'>{props.notice}</div>
      </div>
    </Col>
  )
}

function ToogleMultipleButtons(props){
  const item_holder=props.toogle_data
  const selected=props.selected
  let item_distr=''
  function show_toogleButtons(input,selected){
    let item
    if(input!=undefined){
      item=input.map((it)=>{
        if(selected.includes(it.value)){
          
          return(
            <ToogleButton name={it.name} value={it.value} onClick={props.handleToogleButton} selected={true}>
              {it.option_label}
            </ToogleButton>
          )
        } else{
          return(
            <ToogleButton name={it.name} value={it.value} onClick={props.handleToogleButton} selected={false}>
              {it.option_label}
            </ToogleButton>
          )
        }
      })
    }
    return item
  }
  item_distr=show_toogleButtons(item_holder,selected)

  const direction={
    true:'flex_column'
  }
  
  return(
    <Col_NoGutter xs={props.xs} md={props.md}>
      <div className='support_buttons_TB_group'>
        <div className='support_buttons_TB_label'>{props.label}</div>
        <div className={`support_buttons_TB_div ${direction[props.column]}`}>
          {item_distr}
        </div>
      </div>
    </Col_NoGutter>
  )
}

function ToogleButton(props){
  const css_picker={
    true:'support_buttons_TB_selected',
    false:'support_buttons_TB_unselected'
  }
  return(
    <>
      <button className={`support_buttons_TB_button ${css_picker[props.selected]}`} name={props.name} value={props.value} disabled={props.disabled} onClick={props.onClick}>
        {props.children}
      </button>
    </>
  )
}


function CreateToogleButtons(props){
  const item_holder=props.toogle_data
  const selected=props.selected
  let item_distr=''
  function show_toogleButtons(input,selected){
    let item
    if(input!=undefined){
      item=input.map((it,key)=>{
        if(key==selected){
          return(
              <div className='spt_toobtn_stepper_item'> 
                <div class="spt_toobtn_step_counter bk_primary">{key+1}</div>
                <div class="spt_toobt_stepper_active_label"><strong>{it.option_label}</strong></div>
              </div>
          )
        } else if(key<selected) {
          return(
            <div className='spt_toobtn_stepper_item'> 
              <div class="spt_toobtn_step_counter bk_success">{key+1}</div>
              <div class="spt_toobt_stepper_active_label">{it.option_label}</div>
            </div>
          )
        } else {
          return(
            <div className='spt_toobtn_stepper_item'> 
              <div class="spt_toobtn_step_counter">{key+1}</div>
              <div class="spt_toobt_stepper_unactive_label">{it.option_label}</div>
            </div>
          )
        }
      })
    }
    return item
  }
  item_distr=show_toogleButtons(item_holder,selected)
  
  return(
    <Col xs={props.xs} md={props.md}>
      <div className={`spt_toobtn_stepper_wrapper`}>
        {item_distr}
      </div>
    </Col>
  )
}

function ToogleButtonsWitNumberInput(props){
  const context = useContext(UserContext)
  const [focus,setFocus] = useState(false)
  const item_holder=props.toogle_data
  const selected=props.selected
  let item_distr=''
  function show_toogleButtons(input,selected){
    let item
    if(input!=undefined){
      item=input.map((it)=>{
        if(it.value==selected){
          return(
            <ToogleButton name={it.name} value={it.value} onClick={props.handleToogleButton} selected={true} disabled={it.disabled}>
              {it.option_label}
            </ToogleButton>
          )
        } else{
          return(
            <ToogleButton name={it.name} value={it.value} onClick={props.handleToogleButton} selected={false} disabled={it.disabled}>
              {it.option_label}
            </ToogleButton>
          )
        }
      })
    }
    return item
  }
  item_distr=show_toogleButtons(item_holder,selected)

  const direction={
    true:'flex_column'
  }


  const sanitizeNumber=(str)=>{
    let sanitizer
    sanitizer=str.replace(/\D/g,'')
    sanitizer=parseFloat(sanitizer)/100
    sanitizer =sanitizer.toFixed(2)
    return sanitizer
  }

  const handleChange=(event)=>{
    event.target.value=(sanitizeNumber(event.target.value))
    props.handleinputeditChange(event)
  }

  const handleFocus=()=>{
    setFocus(!focus)
  }

  let user_c= context
  let val
  if(focus){
    val=(parseFloat(checker(props.value))).toFixed(2)
  } else{
    val=user_c.i18n.deciF(checker(props.value),true)
  }
  
  return(
    <Col_NoGutter xs={props.xs} md={props.md}>
      <div className='support_buttons_TB_group'>
        <div className='support_buttons_TB_label'>{props.label}</div>
          <div className={`support_buttons_TB_div ${direction[props.column]}`}>
          {item_distr}
        </div>
        <Col xs={12}>
          <Row className="support_buttons_TB_numberinput">
        <Col xs={6} className='grid_middle'>
          {props.number_label}
        </Col>

        <Col xs={6} >
          <input
            className="support_forms_align_right"
            name={props.name}
            value={val}
            placeholder={props.placeholder}
            onFocus={handleFocus}
            onBlur={handleFocus}
            onChange={handleChange}/>
        </Col>
        </Row>
        </Col>

      </div>
    </Col_NoGutter>
  )
}

export{
  ToogleButtons,
  ToogleButtonsWithNotice,
  ToogleMultipleButtons,
  CreateToogleButtons,
  ToogleButtonsWitNumberInput
}