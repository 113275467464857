import React from 'react';
import {Col} from 'Support/grid'

class WarningCard extends React.Component{
  render(){
    return(
        <div className={`warning_div_outside bk_${this.props.color}`}>
          <div className={`warning_div_header bk_${this.props.color}`}>
            <h6>{this.props.label1}</h6>
          </div>
          <div className={`warning_div_inside`}>
          {this.props.label2}
          </div>
        </div>
    );
  }
}

class Card extends React.Component{
  render(){
    return(
        <div className={`warning_div_outside bk_${this.props.color}`}>
          <div className={`warning_div_header bk_${this.props.color}`}>
            <h6>{this.props.header}</h6>
          </div>
          <div className={`warning_div_inside`}>
            {this.props.body}
          </div>
        </div>
    );
  }
}


class DetailedCard extends React.Component{
  render(){
    return(
        <div className={`warning_div_outside bk_${this.props.color}`}>
          {this.props.children}
        </div>
    );
  }
}

DetailedCard.Body=CardBody
DetailedCard.Header=CardHeader

function CardHeader (props){
  return(
    <div className={`warning_div_header bk_${props.color}`}>
      {props.children}
  </div>
  )
}

function CardBody(props){
  return(
    <div className={`warning_div_inside`}>
      {props.children}
  </div>
  )
}


class MiniCard extends React.Component{
  render(){
    return(
      <Col xs={this.props.xs} md={this.props.md} lg={this.props.lg}>
        <div className={`warning_div_outside bk_${this.props.color}`}>
        <div className={`warning_div_header bk_${this.props.color}`}>
          <h6>{this.props.header}</h6>
        </div>
        <div className={`warning_div_inside`}>
          {this.props.body}
        </div>
        </div>
      </Col>
    );
  }
}

export {
  WarningCard,
  Card,
  DetailedCard,
  MiniCard
}