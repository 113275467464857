import React, {useState, useContext} from 'react'
import {Image} from 'react-bootstrap';
import {Grid, Row, Col} from 'Support/grid';

import {FirstLoginViseeon} from 'TEMPLATES/Viseeon/public_templates'
import {FirstLoginBazaar} from 'TEMPLATES/BAZAAR/public_templates'

import image1 from 'images/welcome/1.png';
import image2 from 'images/welcome/2.png';
import image3 from 'images/welcome/3.png';
import image4 from 'images/welcome/4.png';
import UserContext from 'CONFIG/auth';
import { RegularButton } from 'Support/listbuttons';

function FirstLogin(props){
  const user_c=useContext(UserContext)
  const trsl=user_c.i18n.t('PUBLIC.Register')

  const possible_sources=[
    {casflo:<FirstLoginCASFLO {...props} trsl={trsl}/>},
    {viseeon:<FirstLoginViseeon {...props} trsl={trsl} />},
    {bazaar:<FirstLoginBazaar {...props} trsl={trsl}/>}
  ]

  for(var i=0;i<possible_sources.length;i++){
    if(props.template.includes(Object.keys(possible_sources[i]))){
      return Object.values(possible_sources[i])
    }
  }
}

function FirstLoginCASFLO(props){
  const [index, setIndex] = useState(0);
  
  if(index===0){
    setTimeout(() => {
      handleSelect()
    }, 3000);
  }

  const handleSelect = () => {
    setIndex(index+1);
  };

  const pane_1=
    <>
    <Image fluid src={image1}/> 
      <div >
        <p className="first_pass_caption">{props.trsl['first_login_casflo_1']}</p>
      </div>
    </>

const pane_2=
  <>
    <Image fluid src={image3}/>
    <p className="first_pass_caption">{props.trsl['first_login_casflo_2']}</p>
    <p className="first_pass_caption">{props.trsl['first_login_casflo_3']}</p>
    <RegularButton bk="bk_warning" onClick={handleSelect} label={props.trsl['first_login_casflo_4']}/>
  </>

const pane_3=
  <>
    <Image fluid src={image2}/>
    <p className="first_pass_caption">{props.trsl['first_login_casflo_5']}</p>
    <RegularButton bk="bk_danger" onClick={handleSelect} label={props.trsl['first_login_casflo_6']}/>
</>

const pane_4=
  <>
    <Image fluid src={image4}/>
    <p className="first_pass_caption">{props.trsl['first_login_casflo_7']}</p>
    <RegularButton bk="bk_success" onClick={props.moveToApp} label={props.trsl['first_login_casflo_8']}/>
  </>

  const display_arr=[pane_1,pane_2,pane_3,pane_4]

  return(
    <Grid fluid className="main_block public_box">
    <Row>
      <Col md={2}></Col>
      <Col md={8}>
        {display_arr[index]}
      </Col>
      <Col md={2}></Col>
    </Row>
    </Grid>
  )
}

export default FirstLogin