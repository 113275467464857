import React from 'react';
import { Row, Col } from 'Support/grid';
import UserContext from 'CONFIG/auth';
import {QuestionButton, BackButton, ChangeMenuButton} from 'Support/listbuttons';

class BudgetHeaderBar extends React.Component{
  static contextType = UserContext

  render(){
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_BUDGET.INSIDEPROJECT.Navigation&Header')

    return(
      <Row className="headerbar_row">
        <Col xs={6} md={10}>
          <Row >
            <Col xs={12} md={4}>
              <h4>
                {trsl['header']}
              </h4>
            </Col>
            <Col xs={12} md={3}>
              <h6>
              {trsl['project']}{this.props.budget_name}
              </h6>
            </Col>
            <Col xs={12} md={3}>
              <h6>
              {trsl['header_version']}{this.props.current_version.name}
              </h6>
            </Col>
            <Col xs={12} md={2}>
              <h6>
              {trsl['year']}{this.props.current_version.year}
              </h6>
            </Col>
          </Row>
        </Col>
        <Col xs={6} md={2} className="headerbar_buttons_position">
          <ChangeMenuButton handleEditItem={this.props.handleChangeFeasibilityHeader}/>
          <BackButton name="main" handleEditItem={this.props.handleGoToInProject}/>
        </Col>
      </Row>
    );
  }
}


class DashboardHeaderBar extends React.Component{
  render(){
    return(
      <Row middle="xs" className="headerbar_row">
        <Col xs={6} md={10}>
          <Row middle='xs'>
            <Col xs={12} md={4}>
              <h4>
                {this.props.trsl['header']}
              </h4>
            </Col>
            <Col xs={12} md={4}>
              <h6>
                {this.props.p_name}
              </h6>
            </Col>
            <Col xs={12} md={4}>
              <h6>
              {this.props.trsl['main_dashboard']}
              </h6>
            </Col>
          </Row>
        </Col>
        <Col xs={6} md={2} className="headerbar_buttons_position">
              <QuestionButton />
              <BackButton name="main" handleEditItem={this.props.handleBudgetRouting}/>
        </Col>
      </Row>
    );
  }
}

export{
  BudgetHeaderBar,
  DashboardHeaderBar
};