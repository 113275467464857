import React, {useState, useContext} from 'react'
import  {Grid,Col, Row} from 'Support/grid'
import {Button, Card, Image, Carousel, Form} from 'react-bootstrap'

import UserContext from 'CONFIG/auth';

import brand_logo from './Images/bazaar_logo.jpeg'
import casflo_logo from './Images/casflo2.png'

import image5 from 'TEMPLATES/Viseeon/Images/Welcome/1.png'
import image6 from 'TEMPLATES/Viseeon/Images/Welcome/2.png'
import image7 from 'TEMPLATES/Viseeon/Images/Welcome/3.png'
import image8 from 'TEMPLATES/Viseeon/Images/Welcome/4.png'
import 'TEMPLATES/BAZAAR/CSS/public.css'
import 'TEMPLATES/BAZAAR/CSS/effects.css'

class LoginBazaar extends React.Component{
  render(){
    const props={
      trsl:this.props.trsl,
      toast:this.props.toast,
      handeLogIn:this.props.handeLogIn,
      handleLanguage:this.props.handleLanguage,
      AlertFunctions:this.props.AlertFunctions
    }
    return(
      <>
        <Grid fluid className="main_block t_public_login">
          <Row>
            <Col md={1}></Col>
            <Col md={4} className='bk_lightshade show_animation'>
              <Row className='public_language_bar'>
                <Col md={12}>
                  {this.props.change_languages}
                </Col>
              </Row>
              <div className='center'>
                <a href='https://bazaar.com.pt' target='_blank'>
                  <Image src={brand_logo} className="t_public_logos"/> 
                </a>
              </div>
                  {this.props.login}
              <div className="blanktop_15">
                <h6>{props.trsl['alternatives_to_login']}</h6>
                {this.props.go_register}
                {this.props.go_password}
                {this.props.go_activation}
              </div>
            </Col>
            <Col md={1}></Col>
            <Col md={2}></Col>
            <Col md={3} className='main_block c_primary'>
              <div className='center t_public_col2'>
                <h1>BAZAAR PLAN</h1>
                <h5>POWERED BY</h5>
                <p></p>
                <a href='https://casflo-app.com' target='_blank'>
                  <Image src={casflo_logo} className="t_public_logos"/>                         
                </a>
              </div>
            </Col>
            <Col md={1}></Col>
          </Row>
        </Grid>
      </>
    );
  }
}

class RegisterBazaar extends React.Component{
  render(){
    return(
      <>
      <Grid fluid className="main_block t_public_login">
        <Row >
          <Col md={1}></Col>
          <Col md={5}  className='bk_lightshade show_animation'>
            <Row className='public_language_bar'>
              <Col md={12}>
                {this.props.change_languages}
              </Col>
            </Row>
            <div className='center'>
              <Image src={brand_logo} className="t_public_logos"/>
            </div>
              {this.props.register_form}
            <div className="t_public_second_row public_white_font">
              <h6>{this.props.trsl['alternatives_to_register']}</h6>
              {this.props.go_login}
              {this.props.go_password}
              {this.props.go_activation}
            </div>
          </Col>
          <Col md={1}></Col>
          <Col md={4} className='main_block c_primary'>  
            <div className='center t_public_col2'>
              <h1>BAZAAR PLAN</h1>
              <h5>POWERED BY CASFLO APP</h5>
              <Image src={casflo_logo} className="t_public_logos"/>            
            </div>
          </Col>
          <Col md={1}></Col>
        </Row>
      </Grid>
      </>
    );
  }
}

function FirstLoginBazaar(props){
  const [index, setIndex] = useState(0);

  const user_c=useContext(UserContext)
  const trsl=user_c.i18n.t('TEMPLATES.Bazaar')
  
  if(index===0){
    setTimeout(() => {
      handleSelect()
    }, 3000);
  }

  const handleSelect = (selectedIndex, e) => {
    setIndex(index+1);
  };

  return(
    <Grid fluid className="main_block public_box bk_primary">
    <Row>
      <Col md={2}></Col>
      <Col md={8}>
        <Carousel activeIndex={index} controls={false} indicators={false}>
          <Carousel.Item>
            <Image fluid src={image5}/> 
            <Carousel.Caption >
              <div>
                <p className="first_pass_caption">{trsl['first_login_1']}</p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <Image fluid src={image7}/>
            <Carousel.Caption>
              <p className="first_pass_caption">{trsl['first_login_2']}</p>
              <p className="first_pass_caption">{trsl['first_login_3']}</p>
              <Button block size="sm" variant="warning" onClick={handleSelect}>{trsl['first_login_4']}</Button>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <Image fluid src={image6}/>
            <Carousel.Caption>
              <p className="first_pass_caption">{trsl['first_login_5']}</p>
              <Button block size="sm" variant="danger" onClick={handleSelect}>{trsl['first_login_6']}</Button>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <Image fluid src={image8}/>
            <Carousel.Caption>
              <p className="first_pass_caption">{trsl['first_login_7']}</p>
              <Button block size="sm" variant="success" onClick={props.moveToApp}>{trsl['first_login_8']}</Button>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </Col>
      <Col md={2}></Col>
    </Row>
    </Grid>
  )
}

class PasswordRecoveryViseeon extends React.Component{
  static contextType = UserContext
  render(){
    let user_c= this.context
    return(
      <Grid fluid className="main_block public_box">
        <Row >
          <Col xs={12}>
            {this.props.logged}
          </Col>
          <Col md={4}>
            
          </Col>
          <Col md={4}>
          <Image src={brand_logo} className="public_logo"/>
          <Card>
            <Card.Header>
              {user_c.i18n.t('PUBLIC.Recovery.recovery')}
            </Card.Header>
            <Card.Body>
              <Card.Text>
                <p>{user_c.i18n.t('PUBLIC.Recovery.did_you_forget')}</p>
                <p>{user_c.i18n.t('PUBLIC.Recovery.its_alright')}</p>
                <p>{user_c.i18n.t('PUBLIC.Recovery.explanation')}</p>
              </Card.Text>
              <Form onSubmit={this.props.handeLogIn}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Control required type="email" name="id" placeholder={user_c.i18n.t('PUBLIC.Recovery.enter_email')} />
                </Form.Group>
                <Button block variant="primary" type="submit" >
                {user_c.i18n.t('PUBLIC.Recovery.button_recovery')}
                </Button>
              </Form>
            </Card.Body>
          </Card>
          </Col>
          <Col md={4}></Col>
        </Row>
        <Row className="public_second_row public_white_font">
          <Col md={4}></Col>
          <Col md={4}>
            <h6>{user_c.i18n.t('PUBLIC.Recovery.alternatives_to_recovery')}</h6>
          </Col>
          <Col md={4}></Col>
          <Col md={4}></Col>
          <Col md={2}>
            <Button block variant="secondary" href="/register" >
              {user_c.i18n.t('PUBLIC.Recovery.button_register')}
            </Button>
          </Col>
          <Col md={2}>
            <Button block variant="secondary" href="/login">
            {user_c.i18n.t('PUBLIC.Recovery.button_login')}
            </Button>         
          </Col>
        </Row>
      </Grid>
    );
  }
}

class PasswordRecoveryNewPasswordViseeon extends React.Component{
  static contextType = UserContext
  render(){
    let user_c= this.context
    return(
      <Grid fluid className="main_block public_box">
        <Row >
          <Col xs={12}>
            {this.props.logged}
          </Col>
          <Col md={4}>
            
          </Col>
          <Col md={4}>
          <Image src={brand_logo} className="public_logo"/>
          <Card>
            <Card.Header>
              {user_c.i18n.t('PUBLIC.Recovery_request.recovery')}
            </Card.Header>
            <Card.Body>
              <Form onSubmit={this.props.handleRecoveryRequest}>
                <Form.Group onChange={this.props.handleChange}>
                  <Form.Control required type="password" name="password" placeholder={user_c.i18n.t('PUBLIC.Recovery_request.type_password')} />
                  <Form.Control required type="password" name="retype_password" placeholder={user_c.i18n.t('PUBLIC.Recovery_request.retype_password')} />
                </Form.Group>
                <Button block variant="primary" type="submit" >
                {user_c.i18n.t('PUBLIC.Recovery_request.button_recovery')}
                </Button>
              </Form>
              <Card.Text>
                <h6>{user_c.i18n.t('USER.Edit_profile.password_rules')}</h6>
                {user_c.i18n.t('PUBLIC.Recovery_request.password_rules')}
              </Card.Text>
            </Card.Body>
          </Card>
          </Col>
          <Col md={4}></Col>
        </Row>
        <Row className="public_second_row public_white_font">
          <Col md={4}></Col>
          <Col md={4}>
            <h6>{user_c.i18n.t('PUBLIC.Recovery.alternatives_to_recovery')}</h6>
          </Col>
          <Col md={4}></Col>
          <Col md={4}></Col>
          <Col md={2}>
            <Button block variant="secondary" href="/register" >
              {user_c.i18n.t('PUBLIC.Recovery.button_register')}
            </Button>
          </Col>
          <Col md={2}>
            <Button block variant="secondary" href="/login">
            {user_c.i18n.t('PUBLIC.Recovery.button_login')}
            </Button>         
          </Col>
        </Row>
      </Grid>
    );
  }
}

class ActivationRequestViseeon extends React.Component{
  static contextType = UserContext
  render(){
    let user_c= this.context
    return(
      <Grid fluid className="main_block public_box">
        <Row >
          <Col xs={12}>
            {this.props.logged}
          </Col>
          <Col md={4}>
            
          </Col>
          <Col md={4}>
          <Image src={brand_logo} className="public_logo"/>
          <Card>
            <Card.Header>
              {user_c.i18n.t('PUBLIC.Activation_request.recovery')}
            </Card.Header>
            <Card.Body>
              <Card.Text>
                <p>{user_c.i18n.t('PUBLIC.Activation_request.text')}</p>
              </Card.Text>
              <Form onSubmit={this.props.handeLogIn}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Control required type="email" name="email" placeholder={user_c.i18n.t('PUBLIC.Activation_request.enter_email')} />
                </Form.Group>
                <Button block variant="primary" type="submit" >
                {user_c.i18n.t('PUBLIC.Activation_request.button_recovery')}
                </Button>
              </Form>
            </Card.Body>
          </Card>
          </Col>
          <Col md={4}></Col>
        </Row>
        <Row className="public_second_row public_white_font">
          <Col md={4}></Col>
          <Col md={4}>
            <h6>{user_c.i18n.t('PUBLIC.Activation_request.alternatives_to_recovery')}</h6>
          </Col>
          <Col md={4}></Col>
          <Col md={4}></Col>
          <Col md={2}>
            <Button block variant="secondary" href="/register" >
              {user_c.i18n.t('PUBLIC.Activation_request.button_register')}
            </Button>
          </Col>
          <Col md={2}>
            <Button block variant="secondary" href="/login">
            {user_c.i18n.t('PUBLIC.Activation_request.button_login')}
            </Button>         
          </Col>
        </Row>
      </Grid>
    );
  }
}

class ActivateViseeon extends React.Component{
  static contextType = UserContext
  render(){
    let user_c= this.context
    function renderizing_proceed(input){
      if(input===true){
        return(
          <div className="activate_div">
            <h2>{user_c.i18n.t('PUBLIC.Activation_done.activation_completed')}</h2>
            <Button block href="/login" >
              {user_c.i18n.t('PUBLIC.Activation_done.button')}
            </Button> 
          </div>
        )
      } else{
        return(
          <div className="activate_div">
            <h2>{user_c.i18n.t('PUBLIC.Activation_done.activation_in_process')}</h2>
          </div>
        )
      }
    }

    const renderizing=renderizing_proceed(this.props.activation_status)
    return(
      <Grid fluid className="main_block public_box">
        <Row >
          <Col md={4}>          
          </Col>
          <Col md={4}>
            <Image src={brand_logo} className="public_logo"/>
            {renderizing}
          </Col>
        </Row>
      </Grid>
    );
  }
}

export {
  LoginBazaar,
  RegisterBazaar,
  FirstLoginBazaar,
  PasswordRecoveryViseeon,
  PasswordRecoveryNewPasswordViseeon,
  ActivationRequestViseeon,
  ActivateViseeon
}