import React from 'react';
import { AssumptionHeaderSaveReturn } from '../budget_items';
import { Row, Col } from 'Support/grid';

import {FormEdit, PeriodHeader, SingleInputForm, Observations,Dropdown} from 'Support/inputforms'
import ApiConnect from 'CONFIG/axios';
import UserContext from 'CONFIG/auth';
import Loader from 'Support/loader';
import {calculateOtherGainsCosts} from 'CALCULATIONS/cal_other_gains_costs';

class OtherGainsCostEntry extends React.Component {
  static contextType = UserContext

  constructor(props) {
    super(props);
    this.state = {
      data: "",
      loaded:false
    }
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleObservationsChange = this.handleObservationsChange.bind(this);
    this.handlePeriodChange = this.handlePeriodChange.bind(this);
  }

  componentDidMount= async()=>{
    const request={
    budget_id:this.props.budget_id,
    version_id:this.props.current_version.id,
    item_id:this.props.active_item,
    item_type:"other_gains_cost"
    }
    try {
      const response = await ApiConnect.post('/api/budget_project/multitem/unique/get', request)
        this.setState({
          data:response.data.item,
          loaded:true
        })
    } catch(error) {
        alert(error);
    }
  }

  handleEditChange=(id, item)=>{
    let go = this.state.data;
    go[id]=item;
    calculateOtherGainsCosts(go)
    this.setState({data:go})
  }

  handleNameChange(event){
    const value = event.target.value;
    let go = this.state.data;
    go.other_gains_cost_item_name=value;
    this.setState({data:go })
  }

  handlePeriodChange(val){
    const value = val;
    let go = this.state.data;
    go.other_gains_cost_period_type=value;
    calculateOtherGainsCosts(go)
    this.setState({data:go})
  }

  handleCenterChange=async(event)=>{
    const value = event.target.value;
    let go = this.state.data;
    go.other_gains_cost_center=value;
    this.setState({data:go})
  }

  handleObservationsChange(event){
    const value = event.target.value;
    let go = this.state.data;
    go.other_gains_cost_obs=value;
    this.setState({data:go })
  }

  async handleSave(){
    const request={
      user_id:this.props.user_id,
      budget_id:this.props.budget_id,
      version_id:this.props.current_version.id,
      item_id:this.props.active_item,
      item_type:"other_gains_cost",
      content:this.state.data
    }
    try{
      await ApiConnect.post('/api/budget_project/multitem/item/modify', request)
      this.props.changeScreenView("list",0)
    } catch(error) {
        alert(error);
    }
  }

  render() {
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_BUDGET.INSIDEPROJECT.Individual_edits')

    let p_base=['m1','m2','m3','m4','m5','m6','m7','m8','m9','m10','m11','m12']
    let periods=[]
    for(var i=0; i < p_base.length ;i++){
      periods[i]=trsl[p_base[i]]
    }


    const props ={
      trsl:trsl,
      handleScreenNavigation:this.props.handleScreenNavigation,
      handleEditChange:this.handleEditChange,
      handleSave:this.handleSave,
      handleNameChange:this.handleNameChange,
      handlePeriodChange:this.handlePeriodChange,
      handleCenterChange:this.handleCenterChange,
      handleObservationsChange:this.handleObservationsChange,
      data:this.state.data,
      periods:periods,
      budget_settings:this.props.budget_settings,
      user_permission:this.props.user_permission,
      multi_option:this.props.multi_option,
      multitabs:this.props.multitabs,
  }

    function torender(data, i){
      return data[i];
    }  
    const pages ={
      true: <ToDisplay {...props}/>,
      
      false: <Loader loader_message="o_gc_edit"/>
    }
    var i = this.state.loaded
    const renderizing = torender(pages,i)

    return(
      <div>
        {renderizing}
      </div>
    )
  }
}

class ToDisplay extends React.Component {
  render(){
    const props=this.props
    return(
      <>
        <GlobalHeader {...props}/> 
        <Row>   
          <Col xs={12} >
            <PeriodHeader label={this.props.trsl['period']} periods={this.props.periods} {...props}/>
          </Col>
          <Col xs={12} >
            <ValueEdit  {...props}/>
          </Col>
          <Observations xs={12} name="other_gains_cost_obs" value={this.props.data.other_gains_cost_obs} label="Observations" handleObservationsChange={this.props.handleObservationsChange}/>
        </Row>
      </>
    );
  }
}

class GlobalHeader extends React.Component{
  render(){
    const props=this.props
    
    const item_holder=this.props.multitabs
    const selected=this.props.multi_option
    const element_name =item_holder.find(el => el.type.indexOf(selected) !== -1).item_trsl

    const options_item_holder = this.props.budget_settings.centers
    let options_item_distr=[{value:"",option_label:''}]
    if(options_item_holder !==undefined){
      options_item_holder.map(key =>{
        options_item_distr.push({value:key,option_label:key})
      })
    }

    return(
      <div>
        <AssumptionHeaderSaveReturn {...props}/>
        <Row className="feasibility_edit_globalheader_row" middle="xs">
          <SingleInputForm xs={12} md={5} value={this.props.data.other_gains_cost_item_name} label= {this.props.trsl['other_gain_costs_name']} handleEditItemChange={this.props.handleNameChange}/>
          <Dropdown xs={12} md={7} label={this.props.trsl['center']} name="centers" value={this.props.data.rev_center} options={options_item_distr} onChange={this.props.handleCenterChange} />
        </Row>
      </div>
    );
  }
}

class ValueEdit extends React.Component{
  render(){ 
    return(
      <FormEdit 
      use_historic={this.props.budget_settings.use_historic}
      item_holder={this.props.data.other_gains_cost_value}
      id="other_gains_cost_value"
      handleForm={this.props.handleEditChange}
      label={this.props.trsl['other_gain_costs_value']}
      unit={new Intl.NumberFormat('ja-JP', { style: 'currency', currency: this.props.budget_settings.currency}).format(0).replace(/\d./g,"")}
      />
    );
  }
}

export default OtherGainsCostEntry;