const {dotMultiply, add,dotDivide} = require('mathjs');

function calOpExpIterate(opexp,feasibility_settings,version_inflation){
  let new_stream=[]
  for(var i=0;i<opexp.length;i++){
    new_stream[i]=calculateOperatingExpenses(opexp[i],feasibility_settings,version_inflation)
  }
  return new_stream
}

function vatSelector(settings,reference){
    if(settings.use_general_vat_rate===true){
      return settings.vat
    } else{
      return reference
    }
  }
  const calculateOperatingExpenses= (c,settings,inflation, seasonality)=>{
  
  if(c.op_exp_set_type ==='fixed' && c.op_exp_yearly_total!=0){
    c.op_exp_value= c.op_exp_value.fill(dotDivide(c.op_exp_yearly_total,12))
  } else if(c.op_exp_set_type ==='fixed' ){
    c.op_exp_value = c.op_exp_value.fill(0)
  } else if(c.op_exp_set_type ==='seasonality' && c.op_exp_yearly_total!=0){
    c.op_exp_value= dotMultiply(c.op_exp_yearly_total/100,seasonality)
  } else if(c.op_exp_set_type ==='seasonality' ){
    c.op_exp_value = c.op_exp_value.fill(0)
  }

    c._op_exp_value_inflated=dotMultiply(c.op_exp_value,inflation)
    c._op_exp_total = dotMultiply(c.op_exp_period_type,c._op_exp_value_inflated)
    c._op_exp_vat_total = dotMultiply(0.01, dotMultiply(c._op_exp_total,vatSelector(settings,c.op_exp_vat_rate)))
    c._op_exp_payable = add(c._op_exp_total,c._op_exp_vat_total) 
    return c
  };
  module.exports={
    calOpExpIterate,
    calculateOperatingExpenses
  }