const {max,add, dotDivide, dotMultiply, min} = require('mathjs');

const undefinedToZero=(in1)=>{
  if(in1===undefined) return 0
  else return in1
}

const cal_disbursement=(years, amount, year)=>{
  var bug=[]
  let i
  for(i=0;i<years;i++){
    if(i==year){
      bug[i]=amount
    } else{
      bug[i]=0
    }
  }
  return bug
}

const cal_initial_debt=(years, disbursement, final_debt)=>{
  var bug=[]
  let i
  for(i=0;i<years;i++){
    if(i==0){
      bug[i]=disbursement[i]
    } else if(bug[i-1]>0){
      bug[i]=final_debt[i-1]
    } else{
      bug[i]=bug[i-1]+disbursement[i]
    }
  }
  return bug
}

const cal_usable_months=(years, year, month)=>{
  var bug=[]
  let i
  for(i=0;i<years;i++){
    if(i==year){
      bug[i]=12-month
    } else if(i<year){
      bug[i]=0
    } else{
      bug[i]=12
    }
  }
  return bug
}

const cal_number_months=(years, usable_months, duration,startmonth)=>{
  var bug=[]
  let i
  for(i=0;i<years;i++){
    if(i===0){
      if(duration>=12){
        bug[i]=duration-usable_months[i]
      } else {
        bug[i]=add(12,-add(duration,startmonth))
      }
    } else {
      bug[i]=max(bug[i-1]-min(usable_months[i],12),0)
    }
  }
  return bug
}

const cal_monthly_reimbursement=(duration,amount,gracebool,gracemonth)=>{
  if(duration>0){
    if(gracebool===true){
      return (amount/(duration-undefinedToZero(gracemonth)))
    }else {
      return (amount/(duration))
    }
  } else{
    return 0
  }
}


const cal_final_debt=(years, year, number_of_months, monthly_reimbursement,duration,gracebool,gracemonth)=>{
  var bug=[]
  let i
  function forGrace(i1,i2){
    if(i1>0){
      return(i2)
    } else return 0
  }
  const for_grace=forGrace(number_of_months,undefinedToZero(gracemonth))
  for(i=0;i<years;i++){
    if(gracebool===true){
      if(i>=year){
        bug[i]=min(add(duration,-undefinedToZero(gracemonth)),add(number_of_months[i],for_grace))*monthly_reimbursement
      }else {
        bug[i]=0
      }
    } else {
      if(i>=year){
        bug[i]=number_of_months[i]*monthly_reimbursement
      }else {
        bug[i]=0
      }
    }
  }
  return bug
}

const cal_reimbursement=(years,debt_reibursement_type,initial_debt,final_debt)=>{
  if(debt_reibursement_type===undefined || debt_reibursement_type==0){
    var bug=new Array(years).fill(0)
    let i
    for(i=0;i<years;i++){
      bug[i]=initial_debt[i]-final_debt[i]
    }
    return bug
  } else return 
}

const calInsteresConstantCapital=(years, initial_debt,type, fixed_rate,floating_rate, usable_months, number_of_months,gracebool)=>{
  var bug=[]
  let i
  if(type==='fixed'){
    for(i=0;i<years;i++){
      if(gracebool===true){
        if(i===0){
          bug[i]=initial_debt[i]*fixed_rate/100/12*usable_months[i]
        } else{
          bug[i]=initial_debt[i]*fixed_rate/100/12*(min(usable_months[i],number_of_months[i-1]))
        }
      }else{
        if(i===0){
          bug[i]=initial_debt[i]*fixed_rate/100/12*usable_months[i]
        } else{
          bug[i]=initial_debt[i]*fixed_rate/100/12*min(usable_months[i],number_of_months[i-1])
        }
      }
    }
   } else if(type==='floating'){
    bug= dotMultiply(initial_debt,dotDivide(floating_rate,100))
  }
  return bug
}

const calInitialDebtProjectStyle=(years, disbursement, reimbursment)=>{
  var bug=[]
  let i
  for(i=0;i<years;i++){
    if(i===0){
      bug[i]=0+disbursement[i]
    } else{
      bug[i]=parseFloat(bug[i-1])+parseFloat(disbursement[i])-parseFloat(reimbursment[i-1])
    }
  }
  return bug
}

const calInsterestProjectStyle=(initial_debt, type,fixed_rate,floating_rate)=>{
  var bug=[]
  if(type==="fixed"){
    bug=dotMultiply(initial_debt,(fixed_rate/100))
  }
  else if (type==='floating'){
    bug=dotMultiply(initial_debt,dotDivide(floating_rate,100))
  }
  return bug
}



const calculateDebt= (c,years)=>{

  if(c.debt_reibursement_type===undefined || c.debt_reibursement_type==0){
    c._debt_disbursement=cal_disbursement(years, c.debt_amount, c.debt_year)
    c._debt_usable_months=cal_usable_months(years, c.debt_year, c.debt_month)
    c._debt_number_of_months=cal_number_months(years, c._debt_usable_months, c.debt_duration, c.debt_month)
    c._debt_monthly_reimbursement=cal_monthly_reimbursement(c.debt_duration,c.debt_amount,c.debt_grace_period_bool, c.debt_grace_period_month)
    c._debt_final_debt=cal_final_debt(years, c.debt_year, c._debt_number_of_months, c._debt_monthly_reimbursement,c.debt_duration, c.debt_grace_period_bool, c.debt_grace_period_month)    
    c._debt_initial_debt=cal_initial_debt(years, c._debt_disbursement, c._debt_final_debt)
    c._debt_reimbursement=cal_reimbursement(years,c.debt_reibursement_type,c._debt_initial_debt,c._debt_final_debt)
    c._debt_interest=calInsteresConstantCapital(years, c._debt_initial_debt,c.interest_rate_type,c.debt_interest_rate,c.debt_interest_rate_float,c._debt_usable_months, c._debt_number_of_months,c.debt_grace_period_bool)
  } else {
    c._debt_initial_debt=calInitialDebtProjectStyle(years, c._debt_disbursement,c._debt_reimbursement)
    c._debt_final_debt=add(c._debt_initial_debt, dotMultiply(-1,c._debt_reimbursement))
    c._debt_interest=calInsterestProjectStyle(c._debt_initial_debt,c.interest_rate_type,c.debt_interest_rate,c.debt_interest_rate_float)
  }
  return c
};

function calDebtIteration (debt, settings){
  let new_stream=[]
  for(var i=0;i<debt.length;i++){
    new_stream[i]=calculateDebt(debt[i],settings.number_of_years+settings.number_of_historic_years)
  }
  return new_stream
}

export {
  calculateDebt,
  calDebtIteration
};