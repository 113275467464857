const {dotMultiply, dotDivide, add} = require ('mathjs');

function addArrays(settings, inputer){
  var summed
  if(inputer!==undefined){
    summed=new Array(settings.number_of_years+settings.number_of_historic_years)
    summed.fill(0)
    inputer.forEach(function(item){
      summed=add(summed,item)
    })
  }
  return summed
}

const calInventory=(c)=>{
  return(dotDivide(dotMultiply(c.rev_cogs.days_of_stock,c.rev_cogs._cogs_total),365))
}

const calCogsPayable=(c)=>{
  let bug=[]
  for(var i=0;i<c.rev_cogs._cogs_total.length;i++){
    if(i===0){
      bug[i]=c.rev_cogs._cogs_total[i]+c.rev_cogs._cogs_vat_total[i]+c.rev_cogs._inventory[i]
    } else{
      bug[i]=c.rev_cogs._cogs_total[i]+c.rev_cogs._cogs_vat_total[i]+c.rev_cogs._inventory[i]-c.rev_cogs._inventory[i-1]
    }
  }
  return bug
}
function vatSelector(settings,reference){
  if(settings.use_general_vat_rate===true){
     return settings.vat
  } else{
    return reference
  }
}

function calculateGrowth(arr){
  let bug=[0]
  for(let i =1; i< arr.length; i++){
    bug.push(dotDivide(arr[i],arr[i-1])-1)
  }
  return bug
}

function calculateQuantityFromPrevious(growth,quantity){
  growth = dotDivide(growth,100)
  growth= add(1,growth)
  for (var i=1;i<quantity.length;i++){
    quantity[i]=quantity[i-1] * growth[i]
  }
  return quantity
}

const calculateRevenues= (c,settings,inflation)=>{

  //calculate revs
  if (c._rev_hist===false){ //REV 1
    c._rev_price_inflated= dotMultiply(c.rev_price,inflation)
    c.rev_quantity_growth = calculateGrowth(c.rev_quantity)
    c._rev_total_revenue = dotMultiply(c.rev_quantity_type, dotMultiply(c._rev_price_inflated,c.rev_quantity))
  } else { //REV 2
    c._rev_price_inflated= dotMultiply(c.rev_price,inflation)
    c.rev_quantity = calculateQuantityFromPrevious(c.rev_quantity_growth, c.rev_quantity)
    c._rev_total_revenue = dotMultiply(c.rev_quantity_type, dotMultiply(c._rev_price_inflated,c.rev_quantity))
  }

  c._rev_total_vat=dotMultiply(0.01, dotMultiply(c._rev_total_revenue,vatSelector(settings,c.rev_vat_rate)))
  c._rev_receivable=add(c._rev_total_vat,c._rev_total_revenue)


  //calculate complex cogs
  c.rev_cogs.c_cogs.forEach((e)=>{
  e._c_cogs_item_multiplied_value=dotMultiply(dotMultiply(e.c_cogs_item_value,c.rev_quantity),c.rev_quantity_type)
  e._c_cogs_item_total_vat=dotDivide(dotMultiply(e._c_cogs_item_multiplied_value,e.c_cogs_item_vat_rate),100)
  })
  //Calculate total cogs
  if(c.rev_cogs.cogs_type===true){
    c.rev_cogs._cogs_total=dotMultiply(0.01,dotMultiply(c._rev_total_revenue,c.rev_cogs.s_cogs.s_cogs_percentage))
  }else {
    c.rev_cogs._cogs_total=addArrays(settings, c.rev_cogs.c_cogs.map((value)=>value._c_cogs_item_multiplied_value))
  }
  //CALCULATE INVENTORY
  c.rev_cogs._inventory=calInventory(c)
  
  //Calculate total cogs vat
  if(c.rev_cogs.cogs_type===true){
    c.rev_cogs._cogs_vat_total=add(
      dotMultiply(0.01, dotMultiply(c.rev_cogs._cogs_total,vatSelector(settings,c.rev_cogs.s_cogs.s_cogs_vat_rate))),
      dotMultiply(0.01,dotMultiply(c.rev_cogs._inventory,vatSelector(settings,c.rev_cogs.s_cogs.s_cogs_vat_rate)))
    )
  }else {
    c.rev_cogs._cogs_vat_total=
    add(
      addArrays(settings, c.rev_cogs.c_cogs.map((value)=>value._c_cogs_item_total_vat)),
      dotMultiply(0.01,dotMultiply(c.rev_cogs._inventory,vatSelector(settings,c.rev_cogs.s_cogs.s_cogs_vat_rate)))
    )
  }
  //CALCULATE COGS PAYABLES
  c.rev_cogs._cogs_payable=calCogsPayable(c)
  
  return c
};

function calRevIteration(revenues,feasibility_settings,version_inflation){
  let new_stream=[]
  for(var i=0;i<revenues.length;i++){
    new_stream[i]=calculateRevenues(revenues[i],feasibility_settings,version_inflation)
  }
  return new_stream
}

function calRevenuesSensivityAnalysisIteration(revenues,version_settings,sens_analisys){
  let new_stream=[]
  for(var i=0;i<revenues.length;i++){
    revenues[i].rev_price=dotMultiply(revenues[i].rev_price,add(1,(sens_analisys/100)))
    new_stream[i]=calculateRevenues(revenues[i],version_settings,version_settings._inflation_factor)
  }
  return new_stream
}

export{
  calculateRevenues,
  calRevIteration,
  calRevenuesSensivityAnalysisIteration,
}