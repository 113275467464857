import React from 'react';
import {Row, Col} from 'Support/grid'
import { SingleInputForm,SingleInputIntegerForm,SingleInputLargeForm } from 'Support/inputforms';
import {  RegularButtonFullHeight } from 'Support/listbuttons';

const initial_state={
  open:false,
  v_name:"",
  v_year:"",
  v_description:""
}

class AddVersion extends React.Component{
  constructor(props) {
    super(props);
    this.state = initial_state
    this.handleOpen=this.handleOpen.bind(this)
    this.handleChange=this.handleChange.bind(this)
    this.handleSubmitEvent=this.handleSubmitEvent.bind(this)
  }

  handleOpen(){
    const newer=!(this.state.open);
    if(newer===true){
      this.setState({
          open:newer
      })
    } else {
      this.setState (
        initial_state
      )
    }
  }

  handleChange(event){
    const value=event.target.value;
    const name=event.target.name;
    let go=this.state;
    go[name]=value
    this.setState({
        go
    });
  }

  handleSubmitEvent=async()=>{
    if(this.state.v_name.length>0 && this.state.v_year.length>0){
      await this.props.handleAdd(this.state.v_name,this.state.v_year, this.state.v_description);
      this.setState(
        initial_state
      )
    }
  }

  render(){
    return (
      <Row>
        <Col xs={12} className='blanktop_15'>
          <h4>{this.props.trsl['add_version']}</h4>
            <Row>
              <SingleInputForm xs={12} md={8} label={`${this.props.trsl['version_name']} *`} name="v_name" value={this.state.v_name} handleEditItemChange={this.handleChange}/>
              <SingleInputIntegerForm xs={12} md={4} label={`${this.props.trsl['version_year']} *`} name="v_year" value={this.state.v_year} handleinputeditChange={this.handleChange}/>
              <SingleInputLargeForm xs={12} md={8} label={this.props.trsl['version_description']} name="v_description" value={this.state.v_description} maxlength={500} handleEditItemChange={this.handleChange}/>
              <Col xs={12} md={4} className="blanktop_075 blankbottom_075">
                <RegularButtonFullHeight label={this.props.trsl['version_add_button']} bk="bk_darkaccent" onClick={this.handleSubmitEvent} />    
              </Col>
            </Row>
        </Col>
      </Row>
    );
  }
}

export default AddVersion;