import React from 'react'
import {Row, Col} from 'Support/grid'

import ApiConnect from 'CONFIG/axios';
import UserContext from 'CONFIG/auth';

import { EditButton } from 'Support/listbuttons.js';
import Loader from 'Support/loader';

const dateRenderizing=(input)=>{
  if(input!=undefined){
    return(
      new Intl.DateTimeFormat('en-US').format(new Date(input))
    )
  }
}

class PartnershipPacks extends React.Component{
  constructor(props){
    super(props);
    this.state = {
        data: "",
        loaded:false
    }
  }

  componentDidMount(){
    this.handleGetPartnerDataList()
  }

  handleGetPartnerDataList=async()=>{
    const request={}
    try {
      const response = await ApiConnect.post('/api/user/partnerships/get', request)
      this.setState({
        data:response.data,
        loaded:true
      })
    } catch(error) {
        alert(error);
    }
  }

  render(){
    const props={
      data:this.state.data,
      handleGoToManage:this.props.handleGoToManage
    }
    function torender(data, i){
      return data[i];
    }  
    const pages ={
      true: <ToDisplay {...props}/>,
      false: <Loader loader_message="rev_edit"/>
    }
    var i = this.state.loaded
    const renderizing = torender(pages,i)
    return(
      <>
        {renderizing}
      </>
    )
  }
}

class ToDisplay extends React.Component{
  static contextType = UserContext

  render(){
    const user_c= this.context
    const trsl= user_c.i18n.t('PRIVATE_PARTNERSHIPS.PARTNERSHIP_PACKS')

    const item_holder = this.props.data
    let item_distr=trsl['pp_no_partnerships']
    if(item_holder !==undefined){
      item_distr = item_holder.map((value,key) =>
        <Row className='admin_al_lists_rows' middle='xs'>
          <Col xs={1}>
            {key+1}
          </Col>
          <Col xs={2}>
            {value._id}
          </Col>
          <Col xs={2}>
            {value.pts_name}
        </Col>
        <Col xs={2}>
          {trsl[value.pts_type]}
        </Col>
        <Col xs={1}>
          {trsl[value.pts_permission]}
        </Col>
        <Col xs={1}>
          {value.pts_number_subs}
        </Col>
        <Col xs={1}>
          {value.pts_receivers.length}
        </Col>
        <Col xs={1}>
          {dateRenderizing(value.pts_validity)}
        </Col>
        <Col xs={1}>
          <EditButton handleEditItem={this.props.handleGoToManage} value={value._id}/>
        </Col>
      </Row>
      );
    }

    return(
      <>
        <h3>{trsl.pp_header}</h3>
        <TableHeader trsl={trsl}/>
        {item_distr}
      </>
    )
  }
}

function TableHeader(props){
  return(
    <Row className='list_table_header' middle='xs'>
      <Col xs={1}></Col>
      <Col xs={2}>
        {props.trsl.pp_id}
      </Col>
      <Col xs={2}>
        {props.trsl.pp_name}
      </Col>
      <Col xs={2}>
        {props.trsl.pp_type}
      </Col>
      <Col xs={1}>
        {props.trsl.pp_permission}
      </Col>
      <Col xs={1}>
        {props.trsl.pp_subs}
      </Col>
      <Col xs={1}>
        {props.trsl.pp_subs_used}
      </Col>
      <Col xs={1}>
        {props.trsl.pp_validity}
      </Col>
      <Col xs={1}>
        {props.trsl.pp_edit}
      </Col>
    </Row>
  )
}

export default PartnershipPacks