import React from 'react';
import {Row} from 'Support/grid'

import { ToogleButtonsWitNumberInput } from 'Support/toogle_buttons';

function SensitivityAnalysisOptions(props){
  return(
    <>
      <h4>
        {props.trsl['sa_title']}
      </h4>
      <Row>
        <VariableCard2 label_1 ={props.trsl['sa_rev_price']} on={props.trsl['sa_activate']} off={props.trsl['sa_deactivate']} label_2 ={props.trsl['sa_variation']} {...props} sname='rev_price_switch' fname='rev_price_factor' value={props.factors.rev_price_factor}/>
        <VariableCard2 label_1 ={props.trsl['sa_rev_quantities']} on={props.trsl['sa_activate']} off={props.trsl['sa_deactivate']} label_2 ={props.trsl['sa_variation']}{...props} sname='rev_quantity_switch' fname='rev_quantity_factor' value={props.factors.rev_quantity_factor}/>
        <VariableCard2 label_1 ={props.trsl['sa_rev_cogs']} on={props.trsl['sa_activate']} off={props.trsl['sa_deactivate']} label_2 ={props.trsl['sa_variation']}{...props} sname='rev_cogs_switch' fname='rev_cogs_factor' value={props.factors.rev_cogs_factor}/>
        <VariableCard2 label_1 ={props.trsl['sa_op_exp']} on={props.trsl['sa_activate']} off={props.trsl['sa_deactivate']} label_2 ={props.trsl['sa_variation']}{...props} sname='op_exp_price_switch' fname='op_exp_factor' value={props.factors.op_exp_factor}/>
        <VariableCard2 label_1 ={props.trsl['sa_employees']} on={props.trsl['sa_activate']} off={props.trsl['sa_deactivate']} label_2 ={props.trsl['sa_variation']}{...props} sname='hr_switch' fname='hr_factor' value={props.factors.hr_factor}/>
        <VariableCard2 label_1 ={props.trsl['sa_inv_price']} on={props.trsl['sa_activate']} off={props.trsl['sa_deactivate']} label_2 ={props.trsl['sa_variation']}{...props} sname='inv_price_switch' fname='inv_price_factor' value={props.factors.inv_price_factor}/>
        <VariableCard2 label_1 ={props.trsl['sa_inv_quantity']} on={props.trsl['sa_activate']} off={props.trsl['sa_deactivate']} label_2 ={props.trsl['sa_variation']}{...props} sname='inv_quantity_switch' fname='inv_quantity_factor' value={props.factors.inv_quantity_factor}/>
        <VariableCard2 label_1 ={props.trsl['sa_debt_cost']} on={props.trsl['sa_activate']} off={props.trsl['sa_deactivate']} label_2 ={props.trsl['sa_variation']}{...props} sname='debt_cost_switch' fname='debt_cost_factor' value={props.factors.debt_cost_factor}/>
        <VariableCard2 label_1 ={props.trsl['sa_debt_value']} on={props.trsl['sa_activate']} off={props.trsl['sa_deactivate']} label_2 ={props.trsl['sa_variation']}{...props} sname='debt_value_switch' fname='debt_value_factor' value={props.factors.debt_value_factor}/>
        <VariableCard2 label_1 ={props.trsl['sa_receivables']} on={props.trsl['sa_activate']} off={props.trsl['sa_deactivate']} label_2 ={props.trsl['sa_variation']}{...props} sname='receivables_switch' fname='receivables_factor' value={props.factors.receivables_factor}/>
        <VariableCard2 label_1 ={props.trsl['sa_payables']} on={props.trsl['sa_activate']} off={props.trsl['sa_deactivate']} label_2 ={props.trsl['sa_variation']}{...props} sname='payables_switch' fname='payables_factor' value={props.factors.payables_factor}/>
        <VariableCard2 label_1 ={props.trsl['sa_inv_payables']} on={props.trsl['sa_activate']} off={props.trsl['sa_deactivate']} label_2 ={props.trsl['sa_variation']}{...props} sname='investment_payables_switch' fname='investment_payables_factor' value={props.factors.investment_payables_factor}/>
        <VariableCard2 label_1 ={props.trsl['sa_inventory']} on={props.trsl['sa_activate']} off={props.trsl['sa_deactivate']} label_2 ={props.trsl['sa_variation']}{...props} sname='inventory_switch' fname='inventory_factor' value={props.factors.inventory_factor}/>
      </Row>
    </>
  )
}

function VariableCard2(props){
  const toogle_data=[
    {value:false,option_label:props.off,name:props.sname},
    {value:true,option_label:props.on,name:props.sname}
  ]
  return(
    <ToogleButtonsWitNumberInput xs={12}
      label={props.label_1}
      selected={props.switches[props.sname]}
      toogle_data={toogle_data}
      handleToogleButton={props.handleSwitch}
      number_label={props.label_2}
      value={props.value}
      name={props.fname}
      handleinputeditChange={props.handleFactors}
    />
  )
}


export default SensitivityAnalysisOptions