import React from 'react';
import { AssumptionHeaderSaveReturn } from '../budget_items';
import { Row, Col } from 'Support/grid'
import { SingleInputFormFlat, FormEdit, SingleInputNumberForm, SingleInputIntegerForm, PeriodHeader, NonEditableForm, Dropdown, Observations} from 'Support/inputforms'
import { ToogleButtons} from 'Support/toogle_buttons';
import ApiConnect from 'CONFIG/axios';
import UserContext from 'CONFIG/auth';
import Loader from 'Support/loader';
import {calculateDebt} from 'CALCULATIONS/Budget_calculations/cal_debt';
import {add} from 'mathjs'

class InvestmentEntry extends React.Component {
  static contextType = UserContext

  constructor(props) {
    super(props);
    this.state = {
      data: "",
      loaded:false
    }
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleGracePeriodChange = this.handleGracePeriodChange.bind(this)
    this.handleSave = this.handleSave.bind(this);
    this.handleObservationsChange = this.handleObservationsChange.bind(this);
  }

  componentDidMount= async()=>{
    const request={
      budget_id:this.props.budget_id,
      version_id:this.props.current_version.id,
      item_id:this.props.active_item,
      item_type:"debt"
    }
    try {
      const response = await ApiConnect.post('/api/budget_project/multitem/unique/get', request)
        this.setState({
          data:response.data.item,
          loaded:true
        })
    } catch(error) {
        alert(error);
    }
  }

  handleEditChange=(event)=>{
    const name = event.target.name;
    const value = event.target.value;
    let go = this.state.data;
    go[name]=value;
    calculateDebt(go)
    this.setState({data:go})
  }

  handleNameChange(event){
    const value = event.target.value;
    let go = this.state.data;
    go.debt_name=value;
    this.setState({data:go })
  }

  handleGracePeriodChange(event){
    let val= event.target.value
    if(val=="false"){val = false} else {val= true}
    let go = this.state.data;
    go.debt_grace_period_bool=val;
    calculateDebt(go)
    this.setState({data:go })
  }
  handleRowEdit=(id, array)=>{
    let go = this.state.data;
    go[id]=array;
    calculateDebt(go)
    this.setState({data:go})
  }

  handleTypeOfPaymentChange=(event)=>{
    const val = event.target.value
    let go = this.state.data;
    go.debt_reibursement_type=val;
    if(val==2){
      go._debt_reimbursement.fill(0) 
    }
    calculateDebt(go)
    this.setState({data:go })
  }

  handleObservationsChange(event){
    const value = event.target.value;
    let go = this.state.data;
    go.debt_obs=value;
    this.setState({data:go })
  }

  async handleSave(){
    const request={
      budget_id:this.props.budget_id,
      version_id:this.props.current_version.id,
      item_id:this.props.active_item,
      item_type:"debt",
      content:this.state.data
    }
    try{
      await ApiConnect.post('/api/budget_project/multitem/item/modify', request)
      this.props.changeScreenView("list",0)
    } catch(error) {
        alert(error);
    }
  }

  render() {
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_PROJECT.PROJECT_FEASIBILITY.Individual_edits')

    let p_base=['m1','m2','m3','m4','m5','m6','m7','m8','m9','m10','m11','m12']
    let periods=[]
    for(var i=0; i < p_base.length ;i++){
      periods[i]=trsl[p_base[i]]
    }

    const props ={
      trsl:trsl,
      handleScreenNavigation:this.props.handleScreenNavigation,
      handleEditChange:this.handleEditChange,
      handleRowEdit:this.handleRowEdit,
      handleRowEqualizer:this.handleRowEqualizer,
      handleSave:this.handleSave,
      handleNameChange:this.handleNameChange,
      handleTypeOfPaymentChange:this.handleTypeOfPaymentChange,
      handleGracePeriodChange:this.handleGracePeriodChange,
      handlePeriodChange:this.handlePeriodChange,
      handleObservationsChange:this.handleObservationsChange,
      data:this.state.data,
      budget_settings:this.props.budget_settings,
      user_permission:this.props.user_permission,
      periods:periods
  }

    function torender(data, i){
      return data[i];
    }  
    const pages ={
      true: <ToDisplay {...props}/>,
      false: <Loader loader_message="debt_edit"/>
    }
    var i = this.state.loaded
    const renderizing = torender(pages,i)

    return(
      <div>
        {renderizing}
      </div>
    )
  }
}

class ToDisplay extends React.Component {
  render(){
    const props=this.props
    const show_debt={
      0:<ConstantCapitalDebtEdit {...props}/>,
      2:<ProjectStyleDebtEdit {...props}/>
    }
    const renderizing=show_debt[this.props.data.debt_reibursement_type]
    return(
      <>
        <Header {...props} />
        <Row className="feasibility_edit_globalheader_row">
          <TypeOfReimbursement {...props}/>
        </Row>        
        {renderizing}
        <Row>
          <Col xs={12} className="feasibility_list">
            <Observations xs={12} className="feasibility_item_list" name="debt_obs" value={this.props.data.op_exp_obs} label="Observations" handleObservationsChange={this.props.handleObservationsChange}/>
          </Col>
        </Row>
      </>
    );
  }
}

class Header extends React.Component{
  render(){
    const props= this.props
    return(
      <>
        <AssumptionHeaderSaveReturn {...props}/>
        <Row className="feasibility_edit_globalheader_row">
            <SingleInputFormFlat xs={12} md={12} value={this.props.data.debt_name} label= {this.props.trsl['debt_name']} handleEditItemChange={this.props.handleNameChange}/>
        </Row>
      </>
    );
  }
}

class ConstantCapitalDebtEdit extends React.Component{
  render(){
    const props=this.props

    const options_months=[
      {value: 0, option_label: this.props.trsl['month_jan']},
      {value: 1, option_label: this.props.trsl['month_feb']},
      {value: 2, option_label: this.props.trsl['month_mar']},
      {value: 3, option_label: this.props.trsl['month_apr']},
      {value: 4, option_label: this.props.trsl['month_may']},
      {value: 5, option_label: this.props.trsl['month_jun']},
      {value: 6, option_label: this.props.trsl['month_jul']},
      {value: 7, option_label: this.props.trsl['month_aug']},
      {value: 8, option_label: this.props.trsl['month_sep']},
      {value: 9, option_label: this.props.trsl['month_oct']},
      {value: 10, option_label:this.props.trsl['month_nov']},
      {value: 11, option_label:this.props.trsl['month_dec']}
    ]

    return(
      <>
        <Row>
          <Col xs={12}>
            <Row>    
              <Dropdown xs={6} md={2}label={this.props.trsl['debt_month']} name="debt_month" value={this.props.data.debt_month} options={options_months} onChange={this.props.handleEditChange} />
              <SingleInputIntegerForm 
              xs={6} md={2}
              label={this.props.trsl['debt_duration']}
              name="debt_duration"
              value={this.props.data.debt_duration}
              handleinputeditChange={this.props.handleEditChange}
              />
              <SingleInputNumberForm
              xs={6} md={2}
              label={this.props.trsl['debt_amount']}
              name="debt_amount"
              value={this.props.data.debt_amount}
              handleinputeditChange={this.props.handleEditChange}
              unit={new Intl.NumberFormat('ja-JP', { style: 'currency', currency: this.props.budget_settings.currency}).format(0).replace(/\d./g,"")}
              />
              <SingleInputNumberForm 
              xs={6} md={2}
              label={this.props.trsl['debt_interest_rate']}
              name="debt_interest_rate"
              value={this.props.data.debt_interest_rate}
              handleinputeditChange={this.props.handleEditChange}
              unit='%'
              />
            </Row>
          </Col>
          <GracePeriod {...props}/>        
          <Col xs={12} >
            <PeriodHeader label={this.props.trsl['year']} periods={this.props.periods}/>
          </Col>
          <Col xs={12}>
            <InitialAmount {...props}/>
            <Reimbursement {...props}/>
            <FinalAmount {...props}/>
            <InterestRate {...props}/>
            <DebtService {...props}/>
          </Col>
        </Row>
      </>
    );
  }
}


class ProjectStyleDebtEdit extends React.Component{
  render(){
    const props=this.props
    let item_holder = this.props.periods
    const item_distr = item_holder.map((value, key) =>{
      return <option value={key}>{value}</option>
    })    
    return(
      <>
        <Row>
          <Col xs={7}>
            <Row>    
                <SingleInputNumberForm 
                label={this.props.trsl['debt_interest_rate']}
                name="debt_interest_rate"
                value={this.props.data.debt_interest_rate}
                handleinputeditChange={this.props.handleEditChange}
                unit='%'
                xs={6} md={4}
              />
            </Row>
          </Col>    
        </Row>
        <Row className="list_table_header">
          <Col xs={12} >
            <PeriodHeader label={this.props.trsl['year']} periods={this.props.periods}/>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <InitialAmount {...props}/>
            <ProjectStyleDisbursement {...props}/>
            <ProjectStyleReimbursement {...props}/>
            <FinalAmount {...props}/>
            <InterestRate {...props}/>
            <DebtService {...props}/>
          </Col>
        </Row>
      </>
    );
  }
}

class TypeOfReimbursement extends React.Component{
  render(){
    const options=[
      {value:0,option_label:this.props.trsl['debt_constant_notional_reibumrsement']},
      {value:2,option_label:this.props.trsl['detb_presonalized_reimbursement']}
    ]
    function undefinedToFalse(input){
      if(input===undefined){
        return 0
      } else return input
    }
    return(
      <>
        <Col xs={12}>
          <h5>{this.props.trsl['debt_reibursement_type']}</h5>
        </Col>
        <ToogleButtons 
        xs={12} md={12} 
        label={this.props.trsl['debt_reibursement_type']}
        toogle_data={options}
        selected={undefinedToFalse(this.props.data.debt_reibursement_type)}
        handleToogleButton={this.props.handleTypeOfPaymentChange} />
      </>  
    )
  }
}

class GracePeriod extends React.Component{
  render(){
    const props=this.props

    const options=[
      {value:true,option_label:this.props.trsl['debt_grace_period_yes']},
      {value:false,option_label:this.props.trsl['debt_grace_period_no']}
    ]

    function undefinedToFalse(input){
      if(input===undefined){
        return false
      } else return input
    }

    function torender(data){
      if(data!==true){
        return ""
      }  else{
        return <GracePeriodValue {...props}/>
      }
    }
    const renderizing = torender(this.props.data.debt_grace_period_bool)

    if(props.data.debt_reibursement_type===2){
      return ""
    } else {
      return(
        <Col xs={12}>
          <Row>
            <Col xs={12}>
              <h5>{this.props.trsl['debt_grace_period']}</h5>
            </Col>
            <ToogleButtons xs={12} md={12} toogle_data={options} label={this.props.trsl['debt_grace_period_question']} selected={undefinedToFalse(this.props.data.debt_grace_period_bool)} handleToogleButton={this.props.handleGracePeriodChange}/>
            {renderizing}
          </Row>
        </Col>
      )
    }
  }
}

class GracePeriodValue extends React.Component{
  render(){
    return(

        <SingleInputIntegerForm
        xs={6} md={4}
        label={this.props.trsl['debt_grace_period_duration']}
        name="debt_grace_period_month"
        value={this.props.data.debt_grace_period_month}
        handleinputeditChange={this.props.handleEditChange}
        />
    )
  }
}

class InitialAmount extends React.Component{
  render(){ 
    return(
      <NonEditableForm
      item_holder={this.props.data._debt_initial_debt} 
      label={this.props.trsl['debt_initial_amount']}
      value_type="currency"
      currency={this.props.budget_settings.currency}
      />
    );
  }
}

class Reimbursement extends React.Component{
  render(){ 
    return(
      <NonEditableForm
      item_holder={this.props.data._debt_reimbursement} 
      s_value={this.props.s_value} 
      label={this.props.trsl['debt_reimbursement']}
      value_type="currency"
      currency={this.props.budget_settings.currency}
      />
    );
  }
}

class InterestRate extends React.Component{
  render(){ 
    return(
      <NonEditableForm
      item_holder={this.props.data._debt_interest} 
      s_value={this.props.s_value} 
      label={this.props.trsl['debt_interest']}
      value_type="currency"
      currency={this.props.budget_settings.currency}
      />
    );
  }
}

class FinalAmount extends React.Component{
  render(){ 
    return(
      <NonEditableForm
      item_holder={this.props.data._debt_final_debt} 
      s_value={this.props.s_value} 
      label={this.props.trsl['debt_final']}
      value_type="currency"
      currency={this.props.budget_settings.currency}
      />
    );
  }
}

class DebtService extends React.Component{
  render(){ 
    return(
      <NonEditableForm
      item_holder={add(this.props.data._debt_interest,this.props.data._debt_reimbursement)} 
      s_value={this.props.s_value} 
      label={this.props.trsl['debt_service']}
      value_type="currency"
      currency={this.props.budget_settings.currency}
      />
    );
  }
}

class ProjectStyleReimbursement extends React.Component{
  render(){
    return(
      <FormEdit 
      item_holder={this.props.data._debt_reimbursement}
      id="_debt_reimbursement"
      handleForm={this.props.handleRowEdit}
      label={this.props.trsl['debt_reimbursement']}
      unit={new Intl.NumberFormat('ja-JP', { style: 'currency', currency: this.props.budget_settings.currency}).format(0).replace(/\d./g,"")}
      />
    );
  }
}

class ProjectStyleDisbursement extends React.Component{
  render(){
    return(
      <FormEdit 
      item_holder={this.props.data._debt_disbursement}
      id="_debt_disbursement"
      handleForm={this.props.handleRowEdit}
      label={this.props.trsl['debt_disbursement']}
      unit={new Intl.NumberFormat('ja-JP', { style: 'currency', currency: this.props.budget_settings.currency}).format(0).replace(/\d./g,"")}
      />
    );
  }
}

export default InvestmentEntry;