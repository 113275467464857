import React from 'react';
import {Row, Col} from 'Support/grid';
import { Button } from 'react-bootstrap';
import UserContext from 'CONFIG/auth';

class SubscriptionThankYou extends React.Component{
  static contextType = UserContext
  componentDidMount(){
  }

  handleProceedtoList=()=>{
    const event={
      target:{
        value:0,
        name:'list',
        id:""
      }
    }
    this.props.handleScreenNavigation(event)
  }
  render(){
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_SUBSCRIPTION.Subscription_thank_you')
    return(
      <>
        <Row center='xs'>
          <Col xs={5}>
            <div className='subscription_thank_u_h1'>{trsl['thank_1']}</div>
            <h2>{trsl['thank_2']}</h2>
          </Col>
        </Row>
        <Row center='xs'>
          <Col xs={4}>
            <Button block onClick={this.handleProceedtoList}>{trsl['thank_button']}</Button>
          </Col>
        </Row>
      </>
    )
  }
}

export default SubscriptionThankYou