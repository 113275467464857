import React from 'react';
import {Row, Col } from 'Support/grid'
import OtherGainsCostEntry from './other_gains_cost';
import OtherGainsCostList from './other_gains_cost_list';

class OtherGainsCostMain extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      to_render: "list",
      active_item:0,
      multi_option:'operational_subsidy',
    }
    this.handleScreenNavigation=this.handleScreenNavigation.bind(this)
    this.changeScreenView=this.changeScreenView.bind(this)
    this.handleChangeType=this.handleChangeType.bind(this)
  }

  changeScreenView=(window, item)=>{
      this.setState({
          active_item: item,
          to_render: window
      })
  }

  handleScreenNavigation(event){
      const value = event.target.value
      const name = event.target.name
      this.changeScreenView(name, value)
  }

  handleChangeType=(event)=>{
    this.setState({ 
      multi_option:event.target.name
    });
  }

  render(){
    const other_gains_costs_items=[
      {
        item_trsl:'other_gc_operational_subsidy',
        add_item_trsl:'add_other_gc_operational_subsidy',
        type:'operational_subsidy',
      },
      {
        item_trsl:'other_gc_other_gain',
        add_item_trsl:'add_other_gc_other_gain',
        type:'other_gain',
      },
      {
        item_trsl:'other_gc_other_cost',
        add_item_trsl:'add_other_gc_other_cost',
        type:'other_cost'
      }
    ]
    
    const props={
      version_id:this.props.version_id,
      active_item:this.state.active_item,
      multi_option:this.state.multi_option,
      budget_id:this.props.budget_id,
      current_version:this.props.current_version,
      changeScreenView:this.changeScreenView,
      budget_settings:this.props.budget_settings,
      user_permission:this.props.user_permission,
      handleScreenNavigation:this.handleScreenNavigation,
      handleChangeType:this.handleChangeType,
      multitabs:other_gains_costs_items,
    }

    function torender(data, i){
      return data[i];
    }
    const pages ={
      edit: <OtherGainsCostEntry {...props}/>,
      list: <OtherGainsCostList {...props}/>
    }
    var i = this.state.to_render
    const renderizing = torender(pages,i)

    return(
      <Row>
      <Col xs={12}>
        {renderizing}
      </Col>
      </Row>
    );
  }
}

export default OtherGainsCostMain;