import React from 'react';
import {Row, Col} from 'Support/grid';

import ApiConnect from 'CONFIG/axios';
import UserContext from 'CONFIG/auth';

import Loader from 'Support/loader';
import { ExpandButton,RegularButton,ViewButton } from 'Support/listbuttons.js';


class SubscriptionsList extends React.Component{
  static contextType = UserContext

  constructor(props) {
    super(props);
    this.state = {
      unused_data:'',
      used_data:'',
      loaded:false
    }
  }

  componentDidMount() {
    this.updateListData()
  }

  updateListData=async()=>{
    const request={}
    try {
      const response = await ApiConnect.post('/api/user/subscriptions/list', request)
      this.setState({
        unused_data:response.data.unused_subscriptions,
        used_data:response.data.used_subscriptions,
        loaded:true,
      })
    } catch(error) {
        alert(error);
    }
  }

  render() {
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_SUBSCRIPTION.Subscription_list')

    const props={
      trsl:trsl,
      unused_data:this.state.unused_data,
      used_data:this.state.used_data,
      handleScreenNavigation:this.props.handleScreenNavigation
    }

    function torender(data, i){
      return data[i];
    }  
    const pages ={
      true: <ToDisplay {...props}/>,
      false: <Loader loader_message="subscriptions"/>
    }
    var i = this.state.loaded
    const renderizing = torender(pages,i)

    return(
      <div>
        {renderizing}
      </div>
    )
  }
}

class ToDisplay extends React.Component {
  render(){
    const props=this.props
    return(
      <>
        <Header handleScreenNavigation={this.props.handleScreenNavigation} trsl={this.props.trsl}/>
            <UnusedSubscription {...props}/>
            <UsedSubscription {...props}/>
      </>
    )
  }
}

class Header extends React.Component{
  render(){
    return(
      <Row className="subscription_buy_button_row">
      <Col xs={12} md={9}>
          <h1>{this.props.trsl['main_title']}</h1>
      </Col>
      <Col xs={12} md={3}>
        <RegularButton bk='bk_primary' label={this.props.trsl['buy_button']} name="buy" onClick={this.props.handleScreenNavigation}/>
      </Col>
  </Row>
    );
  }
}

class UnusedSubscription extends React.Component{
  static contextType = UserContext
  
  render(){
    let user_c= this.context

    const item_holder = this.props.unused_data
    let item_distr=this.props.trsl['no_available_subscriptions']
    if(item_holder !==undefined && item_holder.length>0){
      item_distr = item_holder.map((value) =>
        <Row middle="xs" className="list_item">
          <Col xs={6} md={4}>
            {value.subscription_id}
          </Col>
          <Col xs={6} md={2}>
            {this.props.trsl[value.subscription_type]}
        </Col>
        <Col xs={6} md={2}>
          {this.props.trsl[value.subscription_origin]}
        </Col>
        <Col xs={6} md={3}>
          {user_c.i18n.dateF(value.subscription_validity)}
        </Col>
        <Col xs={6} md={1}>
          <Row center="xs">
            <ViewButton handleEditItem={this.props.handleScreenNavigation} value={value.subscription_id}/>
          </Row>
        </Col>
      </Row>
      );
    }

    return(
      <Row className="general_list">
        <h4>{this.props.trsl['unused_subs']}</h4>
        <Col xs={12}>
          <SubscriptionListUnusedHeader trsl={this.props.trsl}/>
          {item_distr}
        </Col>
      </Row>
    )
  }
}


class UsedSubscription extends React.Component{
  render(){
    function showAvailable(input){
      if(input==='viseeon'){
        return 'VISEEON PLAN'
      } else return 'CASFLO APP'
    }

    const item_holder = this.props.used_data
    let item_distr=this.props.trsl['no_available_subscriptions']
    if(item_holder !==undefined){
      item_distr = item_holder.map((value) =>{
        let extending
        if(value.subscription_permission!="Demonstration"){
          extending=<ExpandButton handleEditItem={this.props.handleScreenNavigation} value={value.subscription_id} id={value.subscription_type}/>
        }
        return(
          <Row middle="xs" className="list_item">
            <Col xs={6} md={4}>
              {value.subscription_id}
            </Col>
            <Col xs={6} md={2}>
              {this.props.trsl[value.subscription_type]}
            </Col>
            <Col xs={6} md={2}>
              {this.props.trsl[value.subscription_origin]}
            </Col>
            <Col xs={6} md={3}>
              {value.subscription_project}
            </Col>
            <Col xs={6} md={1}>
              <Row center="xs">
                {extending}
              </Row>
            </Col>
          </Row>
          )
        }
      );
    }

    return(
      <Row className="general_list">
        <Col xs={12}>
          <h4>{this.props.trsl['used_subs']}</h4>
          <SubscriptionListUsedHeader trsl={this.props.trsl}/>
          {item_distr}
        </Col>
      </Row>
    )
  }
}

function SubscriptionListUnusedHeader (props){
  return(
    <Row className="list_table_header_left">
      <Col xs={6} md={4}>
        <h5>{props.trsl['id']}</h5>
      </Col>
      <Col xs={6} md={2}>
        <h5>{props.trsl['type']}</h5>
      </Col>
      <Col xs={6} md={2}>
        <h5>{props.trsl['origin']}</h5>
      </Col>
      <Col xs={6} md={3}>
        <h5>{props.trsl['activation_deadline']}</h5>
      </Col>
      <Col xs={6} md={1}>
        <h5>{props.trsl['details']}</h5>
      </Col>
    </Row>
  )
}

function SubscriptionListUsedHeader (props){
  return(
    <Row className="list_table_header_left">
      <Col xs={6} md={4}>
        <h5>{props.trsl['id']}</h5>
      </Col>
      <Col xs={6} md={2}>
        <h5>{props.trsl['type']}</h5>
      </Col>
      <Col xs={6} md={2}>
        <h5>{props.trsl['origin']}</h5>
      </Col>
      <Col xs={6} md={3}>
        <h5>{props.trsl['project']}</h5>
      </Col>
      <Col xs={6} md={1}>
        <h5>{props.trsl['extend']}</h5>
      </Col>
    </Row>
  )
}

export default SubscriptionsList;