import React from 'react';
import {Col, Row} from 'Support/grid';
import {ToggleButton, ToggleButtonGroup } from 'react-bootstrap';

import UserContext from 'CONFIG/auth';
import ApiConnect from 'CONFIG/axios';

import Loader from 'Support/loader';

import currencies_base from 'JSON/currencies.json';
import countries from 'JSON/countries_pt.json';
import {SingleInputForm,SingleInputLargeForm, SingleInputNumberForm, SingleInputIntegerForm, Dropdown} from 'Support/inputforms';
import default_values from 'JSON/countries_support.json';
import { RegularButton } from 'Support/listbuttons';
import { ToogleButtons, ToogleButtonsWithNotice } from 'Support/toogle_buttons';

class ProjectSettings extends React.Component{
  static contextType = UserContext

  constructor(props) {
    super(props);
    this.state = {
      project_data:"",
      feasibility_settings:"",
      loaded:false,
      historic_changed:false,
      edit_number_of_years:false
    }
  }

  componentDidMount(){
    this.updateSettingsData()
  }

  updateSettingsData=async()=>{
    const request={
      project_id: this.props.project_id, 
    }
    try{
      const response = await ApiConnect.post('/api/project/data/get', request)
      this.setState({
        project_data:response.data.project_data,
        feasibility_settings:response.data.feasibility_settings,
        loaded:true
      })
    }catch(error) {
      alert(error);
    }
  }

  handleSaveSettingsData=async()=>{
    const request={
      project_id: this.props.project_id, 
      project_data:this.state.project_data,
      feasibility_settings:this.state.feasibility_settings
    }
    const request2={
      project_id: this.props.project_id,
      use_historic:this.state.feasibility_settings.use_historic,
      number_of_historic_years:3
    }
    try{
      await ApiConnect.post('/api/project/settings/change', request)
      if(this.state.historic_changed===true){
        await ApiConnect.post('/api/project/feasibility/sethistoric', request2)
      }
    }catch(error) {
      alert(error);
    }
  }

  handleEditSettingsChange=async(event)=>{
    const name = event.target.name;
    const value = event.target.value;
    let go = this.state.feasibility_settings;
    go[name]=value;
    this.setState({
      feasibility_settings:go
    })
  }

  handleChangeNameDescription=(event)=>{
    const name = event.target.name;
    const value = event.target.value;
    let go=this.props.project_data
    go[name]=value
    this.setState({
      project_data:go
    })
  }

  handleFinancialType=(event)=>{
    let go=this.state.feasibility_settings
    let val= event.target.value
    if(val==="simple"){
      go.financial_type='simple'
      go.financial_standalone_project=false
      go.financial_levered_project=true
      go.financial_investor=false
    } else{
      go.financial_type='complex'
    }
    this.setState({
      projefeasibility_settingsct_data:go
    })
  }
  handleAllowToChangeNumberOfYears=()=>{
    this.setState({
      edit_number_of_years:!this.state.edit_number_of_years
    })
  }

  handleChangeNumberOfYears=(event)=>{
    const value=Number(event.target.value);
    const number=this.state.feasibility_settings.starting_year
    const years= this.calculateYears(value, number)
    let go = this.state.feasibility_settings;
    go.number_of_years=value
    go.years=years
    this.setState({
      feasibility_settings:go
    })
  }

  handleChangeStartingYear=(event)=>{
    const value=Number(event.target.value);
    const number=this.state.feasibility_settings.number_of_years
    const years= this.calculateYears(number, value)
    let go = this.state.feasibility_settings;
    go.starting_year=value
    go.years=years
    this.setState({
      feasibility_settings:go
    })
  }

  calculateYears(number, start){
    let all_years=[]
    for(let i=0; i<number;i++){
      all_years[i]=start + i;
    }
    return all_years
  }
  
  handleCountryChange=async(event)=>{
    const name = event.target.name;
    const value = event.target.value;
    let go = this.state.feasibility_settings;
    go[name]=value;
    const years= this.calculateYears(this.state.feasibility_settings.number_of_years, this.state.feasibility_settings.starting_year)
    go.years=years
    if(default_values.countries[value].irc!=undefined){
      go.irc=default_values.countries[value].irc
      go.corporate_social_security=default_values.countries[value].corporate_social_security
      go.vat=default_values.countries[value].vat
      go.vat_payment=default_values.countries[value].vat_payment
      go.working_months=default_values.countries[value].working_months
      go.currency=default_values.countries[value].currency
    } else{
      go.irc=0
      go.corporate_social_security=0
      go.vat=0
      go.vat_payment=0
      go.working_months=12
    }
    this.setState({
      feasibility_settings:go
    })
  }

  handleEnableDisableHistoric=async(event,text)=>{
    const changer=window.confirm(text)
    if (changer){
      const value = event.target.value;
      const go= this.state.feasibility_settings
      go.use_historic=value
      this.setState({
        feasibility_settings:go,
        historic_changed:true
      })
    }
  }

  handleTypeOfHistoricInputChange = (event)=>{
    const go= this.state.feasibility_settings
    go.type_of_historic_input=event.target.value
    this.setState({
      feasibility_settings:go
    })
  }

  handleSaveAndBack=async(event)=>{
    await this.handleSaveSettingsData()
    await this.props.updateSettingsData()
    let e={
      target:{
        name:"main",
        value:this.props.project_id
      }
    }
    this.props.handleEditItem(e)
  }

  render(){
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_PROJECT.PROJECT_MAIN.Project_settings+Add_project')

    const props={
      trsl:trsl,
      project_name:this.state.project_data.project_name,
      project_description:this.state.project_data.project_description,
      feasibility_settings:this.state.feasibility_settings,
      project_data:this.state.project_data,
      edit_number_of_years:this.state.edit_number_of_years,
      handleEditSettingsChange:this.handleEditSettingsChange,
      handleAllowToChangeNumberOfYears:this.handleAllowToChangeNumberOfYears,
      handleChangeNumberOfYears:this.handleChangeNumberOfYears,
      handleChangeStartingYear:this.handleChangeStartingYear,
      handleChangeNameDescription:this.handleChangeNameDescription,
      handleFinancialType:this.handleFinancialType,
      handleCountryChange:this.handleCountryChange,
      handleEnableDisableHistoric:this.handleEnableDisableHistoric,
      handleTypeOfHistoricInputChange:this.handleTypeOfHistoricInputChange,
      handleSaveAndBack:this.handleSaveAndBack,
      handleEditItem:this.props.handleEditItem,
    }

    function torender(data, i){
      return data[i];
    }  
    const pages ={
      true: 
      <ToDisplay {...props} />,
      false: <Loader loader_message="Searching for your settings!"/>
      }
      var i = this.state.loaded
      const renderizing = torender(pages,i)

    return(
      <>
      {renderizing}
      </>
    )
  }
}

class ToDisplay extends React.Component{
  render(){
    const props= this.props
    let showFeasibilityModule=(input)=>{
      if(input==="business_plan" || input==="feasibility_plan"){
        return(
          <ProjetFeasibilitySettings {...props}/>
        )
      }
    }
    const renderizing=showFeasibilityModule(this.props.project_data.project_type)

    return(
      <>
        <Row>
          <Col xs={12}>
            <ProjectSettingsHeader {...props}/>
          </Col>
        </Row>
        <Row>
          <Col className="project_settings_rows" xs={12}>
            <h2>{this.props.trsl['general_settings']}</h2>
            <ProjectNameDescription {...props} />
          </Col>
          <Col className="project_settings_rows" xs={12}>
            <h2>{this.props.trsl['fea_settings']}</h2>
            <Col xs={12}>
          <Row>
            <AlterNumberOfYearsInSettings {...props}/>
          </Row>
        </Col>
            {renderizing}
          </Col>
        </Row>
      </>
    );
  }
}

class ProjectSettingsHeader extends React.Component{
  render(){
    return(
      <Row>
        <Col xs={12} md={8}>
          <h1>{this.props.trsl['project_settings']}</h1>
        </Col>
        <Col xs={6} md={2}>
          <RegularButton bk='bk_primary' label={this.props.trsl['back_button']} name="main" onClick={this.props.handleEditItem}/>
        </Col>  
        <Col xs={6} md={2}>
          <RegularButton bk='bk_primary' label={this.props.trsl['save_back_button']} name="main" onClick={this.props.handleSaveAndBack} />
        </Col>
      </Row>
    );
  }
}

class ProjectNameDescription extends React.Component{
  render(){
    return(
      <Row>
        <SingleInputForm
        xs={12} md={12}
        name="project_name" 
        value={this.props.project_name} 
        label={this.props.trsl['project_name']}
        placehandler={this.props.trsl['project_name']}
        handleEditItemChange={this.props.handleChangeNameDescription}/>
        <SingleInputLargeForm
        xs={12} md={12}
        name="project_description"
        value={this.props.project_description} 
        label={this.props.trsl['project_descritpion']}
        placehandler={this.props.trsl['project_descritpion']}
        maxlength={500}
        handleEditItemChange={this.props.handleChangeNameDescription}/>
      </Row>
    );
  }
}

class ProjetFeasibilitySettings extends React.Component{
  render(){
    const props=this.props

    return(
      <Row>
        <StartingYear {...props}/>        
        <Col xs={12}>
          <ProjetMainAssumptions {...props}/>
        </Col>
        <Col xs={12}>
          <ProjetTaxAssumptions {...props}/>
        </Col>
        <Col xs={12}>
          <ProjetInputAssumptions {...props}/>
        </Col>
        <Col xs={12}>
          <ProjetFinancialCalculations {...props}/>
        </Col>
        <Col xs={12}>
          {<ProjetHistoricAssumptions {...props}/>}
        </Col>
      </Row>
    );
  }
}

class ProjetTaxAssumptions extends React.Component{

  static contextType = UserContext

  render(){
    const props=this.props

    const checkNumber=(event)=>{
      const functions=this.props.handleEditSettingsChange
      const validator=parseFloat(event.target.value)
      if(validator>=0 && validator<=100){
        functions(event)
      } else{
        alert(this.props.trsl['alert_values'])
      }
    }
    return(
      <Row className='blanktop_15'>
        <Col xs={12}>
          <h3>{this.props.trsl['feasibility_settings']}</h3>    
        </Col>
        <Col xs={12} className='blanktop_15'>
          <h5>{this.props.trsl['profit_sales']}</h5> 
          <Row>           
            <SingleInputNumberForm xs={12} sm={6} md={6}name="irc" value={this.props.feasibility_settings.irc} label={this.props.trsl['corporate_tax']} handleinputeditChange={checkNumber} unit='%'/>
            <SingleInputNumberForm xs={12} sm={6} md={6}name="gross_revenue_tax" value={this.props.feasibility_settings.gross_revenue_tax} label={this.props.trsl['gross_revenue_tax']} handleinputeditChange={checkNumber} unit='%'/>
          </Row>
        </Col>
        <Col xs={12} className='blanktop_10'>
        <h5>{this.props.trsl['vat']}</h5> 
          <Row>
            <VAT {...props}/>
            <SingleInputNumberForm xs={12} sm={6} md={6} name="vat" value={this.props.feasibility_settings.vat} label={this.props.trsl['vat_rate']} handleinputeditChange={checkNumber} unit='%'/>
            <SingleInputIntegerForm xs={12} sm={6} md={6} name="vat_payment" value={this.props.feasibility_settings.vat_payment} label={this.props.trsl['vat_payment']} handleinputeditChange={this.props.handleEditSettingsChange}/>          
          </Row>  
        </Col>
        <Col xs={12} className='blanktop_10'>
          <h5>{this.props.trsl['other_taxes_and_fees']}</h5>
          <Row>           
            <SingleInputNumberForm xs={12} sm={6} md={6}name="corporate_social_security" value={this.props.feasibility_settings.corporate_social_security} label={this.props.trsl['social_security']} handleinputeditChange={checkNumber} unit='%'/>
            <SingleInputIntegerForm xs={12} sm={6} md={6}name="working_months"  value={this.props.feasibility_settings.working_months} label={this.props.trsl['salary_month']} handleinputeditChange={checkNumber}/>
          </Row>
        </Col>
      </Row>
    );
  }
}

class ProjectYearAssumptions extends React.Component{
  render(){
    const props = this.props

    return(
      <Row>
        <Col xs={12}>
          <h2>{this.props.trsl['years_forecasting']}</h2>
        </Col>
        <NumberOfYears {...props}/>
        <Col xs={6} className='blanktop_10'>
          {this.props.trsl['years_forecasting_notice']}
        </Col>
      </Row>
    )
  }
}

class ProjetMainAssumptions extends React.Component{
  render(){
    const props = this.props

    return(
      <Row className='blanktop_15'>
        <Col xs={12}>
          <h5>{this.props.trsl['forecasting_country']}</h5>
        </Col>
          <Country {...props}/>
          <Currency {...props}/>
        <Col xs={12} md={6}>
          {this.props.trsl['country_notice']}
        </Col>
        <Col xs={2}></Col>
        <Col md={3}>    
        </Col>
      </Row>
    )
  }
}

class ProjetInputAssumptions extends React.Component{
  render(){
    const props = this.props

    return(
      <Row className='blanktop_15'>
        <Col xs={12}>
          <h3>{this.props.trsl['input_type']}</h3>
        </Col>
          <RawMaterials {...props}/>
          <Employees {...props}/>
          <DecimalReports {...props}/>
      </Row>
    );
  }
}

class ProjetFinancialCalculations extends React.Component{
  render(){
    const props = this.props

    let complex_render = 
    <>
      <FinancialStandAlone {...props}/>
      <FinancialLevered {...props}/>
      <FinancialInvestor {...props}/>
    </>

    if(this.props.feasibility_settings.financial_type==="simple"){
      complex_render= ""
    }

    return(
      <Row className='blanktop_15'>
        <Col xs={12}>
          <h3>{this.props.trsl['financial_analysis']}</h3>
        </Col>
        <FinancialType {...props}/>
        <Col xs={12}>
          <h5>{this.props.trsl['financial_to_calculate']}</h5>
        </Col>
        {complex_render}
      </Row>
    );
  }
}

class ProjetHistoricAssumptions extends React.Component{
  render(){
    const props = this.props

    return(
      <Row className='blanktop_15'>
        <Col xs={12}>
          <h3>{this.props.trsl['historic_type']}</h3>
        </Col>
          <HistoricData {...props}/>
      </Row>
    );
  }
}

class AlterNumberOfYearsInSettings extends React.Component{
  render(){
    const options=[
      {value:true,option_label:this.props.trsl['yes']},
      {value:false,option_label:this.props.trsl['no']}
    ]
    const handleChange = (e) => {
      let val=e.target.value
      if(val==='false'){val= false} else val=true
      const event={
        target:{
          value:val,
          name:""
        }
      }
      this.props.handleAllowToChangeNumberOfYears(event)
    }

    const rendering={
      false: "",
      true:<NumberOfYears trsl={this.props.trsl} disabled={this.props.edit_number_of_years} feasibility_settings={this.props.feasibility_settings} handleChangeNumberOfYears={this.props.handleChangeNumberOfYears}/>
    }
    return(
      <>
        <ToogleButtonsWithNotice xs={12} md={6} label={this.props.trsl['allow_to_change_number_of_years']} toogle_data={options} selected={this.props.edit_number_of_years} handleToogleButton={handleChange} notice={this.props.trsl['allow_to_change_number_of_years_notice']}/>
        {rendering[this.props.edit_number_of_years]}
      </>
    )
  }
}



class NumberOfYears extends React.Component{
  render(){
    const numberyears=[
      {value:2,option_label:2},
      {value:3,option_label:3},
      {value:4,option_label:4},
      {value:5,option_label:5},
      {value:6,option_label:6},
      {value:7,option_label:7},
      {value:8,option_label:8},
      {value:9,option_label:9},
      {value:10,option_label:10}
    ]
    return <Dropdown label={this.props.trsl['years_of_forecasting']} name="starting_year" value={this.props.feasibility_settings.number_of_years} options={numberyears} onChange={this.props.handleChangeNumberOfYears} />
  }
}

class StartingYear extends React.Component{
  render(){
    const date= Number(new Date().getFullYear())
    const possible_years=[
      {value:date-1,option_label:date-1},
      {value:date,option_label:date},
      {value:date+1,option_label:date+1},
      {value:date+2,option_label:date+2},
      {value:date+3,option_label:date+3},
      {value:date+4,option_label:date+4},
      {value:date+5,option_label:date+5},
      {value:date+6,option_label:date+6},
    ]

    return <Dropdown xs={12} md={6} label={this.props.trsl['starting_year']} name="starting_year" value={this.props.feasibility_settings.starting_year} options={possible_years} onChange={this.props.handleChangeStartingYear} />
  }
}

class Country extends React.Component{

  static contextType = UserContext

  render(){
    let user_c= this.context

    const item_holder = countries[user_c.locale]
    let item_distr
    if(item_holder !==undefined){
      item_distr = Object.entries(item_holder).map(([key,value]) =>{
        return(
          {value:key, option_label:value}
        )
      })
    }

    const country_holder=(input)=>{
      let starter="PT"
      if(input===""){
      return(starter)
      } else{
        return(input)
      }
    }
    const country_selection=country_holder(this.props.feasibility_settings.country)

    return(
      <Dropdown xs={12} md={6} label={this.props.trsl['country']} name="country" value={country_selection} options={item_distr} onChange={this.props.handleCountryChange} />
    )
  }
}

class Currency extends React.Component{
  render(){
    const item_holder = currencies_base
    let item_distr
    if(item_holder !==undefined){
      item_distr = Object.entries(item_holder).map(([key,value]) =>{
        return(
          {value:key, option_label:value}
        )
      })
    }
    return(
      <Dropdown xs={12} md={6} label={this.props.trsl['currency']} name="currency" value={this.props.feasibility_settings.currency} options={item_distr} onChange={this.props.handleEditSettingsChange} />
    )
  }
}

class InputType extends React.Component{
  render(){
    const handleChange = (val) => {
      const event={
        target:{
          value:val,
          name:"input_type"
        }
      }
      this.props.handleEditSettingsChange(event)}
    return(
      <Row>
        <Col xs={2} className="project_settings_input_row">
          <span>{this.props.trsl['input_type_input']}</span>
        </Col>
        <Col xs={10}>
          <ToggleButtonGroup
          size="sm"
          type="radio"
          name="radio"
          value={this.props.feasibility_settings.input_type} 
          onChange={handleChange}>
            <ToggleButton value={true}>{this.props.trsl['units']}</ToggleButton>
            <ToggleButton value={false}>{this.props.trsl['growth_rate']}</ToggleButton>
            </ToggleButtonGroup>
        </Col>
      </Row>
    )
  }
}

class RawMaterials extends React.Component{
  render(){
    const options=[
      {value:true,option_label:this.props.trsl['percentage']},
      {value:false,option_label:this.props.trsl['components']}
    ]
    const handleChange = (e) => {
      let val=e.target.value
      if(val==='false'){val= false} else val=true
      const event={
        target:{
          value:val,
          name:"raw_materials"
        }
      }
      this.props.handleEditSettingsChange(event)
    }
    return(
      <ToogleButtons xs={12} md={6} label={this.props.trsl['raw_materials']} toogle_data={options} selected={this.props.feasibility_settings.raw_materials} handleToogleButton={handleChange}/>
    )
  }
}

class Employees extends React.Component{
  render(){
    const options=[
      {value:true,option_label:this.props.trsl['categories']},
      {value:false,option_label:this.props.trsl['worker']}
    ]
    const handleChange = (e) => {
      let val=e.target.value
      if(val==='false'){val= false} else val=true
      const event={
        target:{
          value:val,
          name:"employees"
        }
      }
      this.props.handleEditSettingsChange(event)}
    return(
      <ToogleButtons xs={12} md={6} label={this.props.trsl['employees_input']} toogle_data={options} selected={this.props.feasibility_settings.employees} handleToogleButton={handleChange}/>
    )
  }
}

class VAT extends React.Component{
  render(){
    const options=[
      {value:true,option_label:this.props.trsl['general_vat']},
      {value:false,option_label:this.props.trsl['every_item_vat']}
    ]
    const handleChange = (e) => {
      let val=e.target.value
      if(val==='false'){val= false} else val=true
      const event={
        target:{
          value:val,
          name:"use_general_vat_rate"
        }
      }
      this.props.handleEditSettingsChange(event)}
    return( 
      <ToogleButtons xs={12} md={12} label={this.props.trsl['vat_input']} toogle_data={options} selected={this.props.feasibility_settings.use_general_vat_rate} handleToogleButton={handleChange}/>
    )
  }
}

class DecimalReports extends React.Component{
  render(){
    const options=[
      {value:true,option_label:this.props.trsl['decimals_true']},
      {value:false,option_label:this.props.trsl['decimals_false']}
    ]
    const handleChange = (e) => {
      let val=e.target.value
      if(val==='false'){val= false} else val=true
      const event={
        target:{
          value:val,
          name:"decimal_on_reports"
        }
      }
      this.props.handleEditSettingsChange(event)
    }
    return(
      <ToogleButtons xs={12} md={6} label={this.props.trsl['decimals']} toogle_data={options} selected={this.props.feasibility_settings.decimal_on_reports} handleToogleButton={handleChange}/>
    )
  }
}

class FinancialType extends React.Component{
  render(){
    const options=[
      {value:'simple',option_label:this.props.trsl['simple']},
      {value:'complex',option_label:this.props.trsl['complex']}
    ]
    return(
      <ToogleButtonsWithNotice xs={12} md={6} label={this.props.trsl['financial_type']} toogle_data={options} selected={this.props.feasibility_settings.financial_type} notice={this.props.trsl['financial_type_explanation_1']}  handleToogleButton={this.props.handleFinancialType}/>
    )
  }
}

class FinancialStandAlone extends React.Component{
  render(){
    const options=[
      {value:true,option_label:this.props.trsl['yes']},
      {value:false,option_label:this.props.trsl['no']}
    ]
    const handleChange = (e) => {
      let val=e.target.value
      if(val==='false'){val= false} else val=true
      const event={
        target:{
          value:val,
          name:"financial_standalone_project"
        }
      }
      this.props.handleEditSettingsChange(event)
    }
    return(
      <ToogleButtons xs={12} md={6} label={this.props.trsl['financial_standalone_project']} toogle_data={options} selected={this.props.feasibility_settings.financial_standalone_project} handleToogleButton={handleChange}/>
    )
  }
}

class FinancialLevered extends React.Component{
  render(){
    const options=[
      {value:true,option_label:this.props.trsl['yes']},
      {value:false,option_label:this.props.trsl['no']}
    ]
    const handleChange = (e) => {
      let val=e.target.value
      if(val==='false'){val= false} else val=true
      const event={
        target:{
          value:val,
          name:"financial_levered_project"
        }
      }
      this.props.handleEditSettingsChange(event)
    }
    return(
      <ToogleButtons xs={12} md={6} label={this.props.trsl['financial_levered_project']} toogle_data={options} selected={this.props.feasibility_settings.financial_levered_project} handleToogleButton={handleChange}/>
    )
  }
}

class FinancialInvestor extends React.Component{
  render(){
    const options=[
      {value:true,option_label:this.props.trsl['yes']},
      {value:false,option_label:this.props.trsl['no']}
    ]
    const handleChange = (e) => {
      let val=e.target.value
      if(val==='false'){val= false} else val=true
      const event={
        target:{
          value:val,
          name:"financial_investor"
        }
      }
      this.props.handleEditSettingsChange(event)
    }
    return(
      <ToogleButtons xs={12} md={6} label={this.props.trsl['financial_investor']} toogle_data={options} selected={this.props.feasibility_settings.financial_investor} handleToogleButton={handleChange}/>
    )
  }
}


class HistoricData extends React.Component{
  render(){
    const options=[
      {value:true,option_label:this.props.trsl['yes']},
      {value:false,option_label:this.props.trsl['no']}
    ]

    const options_of_input=[
      {value:'simple',option_label:this.props.trsl['simple']},
      {value:'detailed',option_label:this.props.trsl['complex']}
    ]

    const handleChange = (e) => {
      let val=e.target.value
      if(val==='false'){val= false} else val=true
      const text =this.props.trsl['change_historic']
      const event={
        target:{
          value:val,
          name:"use_historic"
        }
      }
      this.props.handleEnableDisableHistoric(event,text)}

    let value_check=false
    if(this.props.feasibility_settings.use_historic){
      value_check=true
    }
    let type_of_input_render=""
    if(this.props.feasibility_settings.use_historic){
      type_of_input_render=<ToogleButtons xs={12} md={6} label={this.props.trsl['historic_input']} toogle_data={options_of_input} selected={this.props.feasibility_settings.type_of_historic_input} handleToogleButton={this.props.handleTypeOfHistoricInputChange}/>
    }
    return(
      <>
        <ToogleButtons xs={12} md={6} label={this.props.trsl['historic_input']} toogle_data={options} selected={value_check} handleToogleButton={handleChange}/>
        {type_of_input_render}
      </>
    )
  }
}

export default ProjectSettings;

export{
  ProjectSettingsHeader,
  ProjectNameDescription,
  ProjetFeasibilitySettings,
  ProjectYearAssumptions
}