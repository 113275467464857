import {sum} from 'mathjs';

const accumulate=(t)=>{
  var accumulated=[Number]
  var i
  for(i=0;i<t.other_balance_increase.length;i++){
    if(i===0){
      accumulated[i]=sum(t.other_balance_increase[i],-t.other_balance_decrease[i])
    } else{
      accumulated[i]=sum(accumulated[i-1],t.other_balance_increase[i],-t.other_balance_decrease[i])
    }
  }
  return accumulated
}
 
const calculateOtherBalance= (c)=>{
  //accumulate each item operating expenses
  c._other_balance_accumulated = accumulate(c)

  return c
};

const calOtherBalanceIteration=(other_balance)=>{
    let new_stream=[]
    for(var i=0;i<other_balance.length;i++){
      new_stream[i]=calculateOtherBalance(other_balance[i])
    }
    return new_stream
  }

export {
    calculateOtherBalance,
    calOtherBalanceIteration
}
