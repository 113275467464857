import React from 'react';
import { Row, Col  } from 'Support/grid';

import UserContext from 'CONFIG/auth';
import ApiConnect from 'CONFIG/axios';
import Loader from 'Support/loader';
import { DeleteButton, AddButton, RegularButtonSmall } from 'Support/listbuttons';
import { DetailedCard } from 'Support/warning_cards';
import { SingleInputLargeForm } from 'Support/inputforms';

class SwotMain extends React.Component{
  static contextType = UserContext

  constructor(props) {
    super(props);
      this.state = {
      data: "",
      canva_id:""
    }
    this.handleAddItem=this.handleAddItem.bind(this)
    this.handleDelete=this.handleDelete.bind(this)
    this.handleEditItem=this.handleEditItem.bind(this)
    this.handleSave=this.handleSave.bind(this)
  }

  componentDidMount(){
    this.updataData()
  }

  updataData=async()=>{
    const request={
      project_id: this.props.project_id,
      item_type: "swot" 
    }
    try{
      const response = await ApiConnect.post('/api/project/canva/get', request)
      this.setState({
        data:response.data.item,
        canva_id:response.data.canva_id,
        loaded:true
      })
    }catch(error) {
      alert(error);
    }
  }

  handleAddItem(event){
    const value=event.target.value
    let go= this.state.data
    go[value].push("")
    this.setState({
      data:go
    })
  }

  handleDelete(event){
    let go= this.state.data
    go[event.target.name].splice(event.target.value,1)
    this.setState({
      data:go
    })
  }

  handleEditItem(event){
    let go= this.state.data
    go[event.target.name][event.target.id]=event.target.value
    this.setState({
      data:go
    })
  }

  handleSave=async()=>{
    const request={
      canva_id: this.state.canva_id,
      item_type: "swot",
      content:this.state.data 
    }
    try{
      const response = await ApiConnect.post('/api/project/canva/modify', request)
      this.props.activateAlert('ok_auto','Saved','', this.context)
    }catch(error) {
      alert(error);
    }
  }

  render(){
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_PROJECT.PROJECT_CANVA.SWOT')

    const props ={
      trsl:trsl,
      data:this.state.data,
      p_name:this.props.project_data.project_name,
      user_permission:this.props.user_permission,
      template:user_c.template,
      handleAddItem:this.handleAddItem,
      handleDelete:this.handleDelete,
      handleEditItem:this.handleEditItem,
      handleSave:this.handleSave
    }
    function torender(data, i){
      return data[i];
      }  
    const pages ={
    true: 
    <ToDisplay {...props} />,
    false: <Loader loader_message="Let's get hands on the job!"/>
    }
    var i = this.state.loaded
    const renderizing = torender(pages,i)

    return(
      <div>
        {renderizing}
      </div>
    )
  }
}


class ToDisplay extends React.Component{
  render(){
    const props=this.props
    return(
      <div>
        <Header {...props} />
        <Row className="canva_swot_swot_rows">
          <Col md={6} >
            <SwotFields label={this.props.trsl['strength']} element="strengths" {...props}/>
          </Col>
          <Col md={6} >
            <SwotFields label={this.props.trsl['weaknesses']} element="weaknesses" {...props}/>
          </Col>
        </Row>
        <Row className="canva_swot_swot_rows">
          <Col md={6} >
            <SwotFields label={this.props.trsl['opportunities']} element="opportunities" {...props}/>
          </Col>
          <Col md={6}>
            <SwotFields label={this.props.trsl['threats']} element="threats" {...props}/>
          </Col>
        </Row>
      </div>
    );
  }
}

class Header extends React.Component{
  render(){
    let permission=true
    if(this.props.user_permission=="owner" || this.props.user_permission=="edit" ){
      permission= false
    }
    return(
      <div>
        <Row className="canva_header_row grid_middle">
          <Col xs={9}>
            <h4>{this.props.trsl['swot']}</h4>
          </Col>
          <Col xs={3}>
            <RegularButtonSmall bk="bk_success" label={this.props.trsl['save_button']} onClick={this.props.handleSave} disabled={permission} />
          </Col>
        </Row>
      </div>
    )
  }
}

class SwotFields extends React.Component{
  render(){
    const item_holder=this.props.data[this.props.element]
    let item_distr=""
    if(item_holder!==undefined && item_holder.length>0){
      item_distr=item_holder.map((value,key)=>
        <SWOTForm 
        trsl={this.props.trsl}
        element={this.props.element} 
        item={key} 
        value={value}
        handleDelete={this.props.handleDelete} 
        handleEditItem={this.props.handleEditItem}/>
      )
    }

    return(
      <Row >
        <Col xs={10}>
          <h5>{this.props.label}</h5>
        </Col>
        <Col xs={2} className="canva_align_right">
          <AddButton value={this.props.element} handleAddItem={this.props.handleAddItem} />
        </Col>
        <Col xs={12}>
          {item_distr}
        </Col>
      </Row>
    );
  }
}

function SWOTForm (props){
  const mutate=props.item.toString()
  return(
    <DetailedCard color="white">
      <DetailedCard.Header color="lightaccent">
        <Row className="grid_middle">
          <Col xs={2}>
            <h5>{props.item+1}</h5>
          </Col>
          <Col xs={7}></Col>
          <Col xs={3} className="canva_align_right">
            <DeleteButton name={props.element} value={props.item} handleDelete={props.handleDelete}/>
          </Col>
        </Row> 
      </DetailedCard.Header>
      <DetailedCard.Body>
        <SingleInputLargeForm 
        label={props.trsl['insert_text']}       
        id={mutate}
      name={props.element}
      value={props.value} 
      handleEditItemChange={props.handleEditItem} />
      </DetailedCard.Body>
    </DetailedCard>
  )
}

export default SwotMain;