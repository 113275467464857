import React from 'react';

import ApiConnect from 'CONFIG/axios';

import DebtCreator from '../../../../CONFIG/CREATORS/debt_creator';
import {MultipleFeasibilityListItems} from  '../budget_items';
import {calTotalsForListsWithFilters} from 'CALCULATIONS/cal_totals'

import Loader from 'Support/loader';

class DebtList extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      data:[],
      loaded:false,
      toast:{
        show:false,
        toast_header:"",
        toast_body:"",
      },
      totals:[]
    }
    this.handleAdd=this.handleAdd.bind(this)
    this.updateListData=this.updateListData.bind(this)
    this.handleDeleteItem=this.handleDeleteItem.bind(this)
    this.handleDuplicateItem=this.handleDuplicateItem.bind(this)
  }

  componentDidMount() {
    this.updateListData()
  }

  updateListData(){
    const request={
      budget_id:this.props.budget_id,
      version_id:this.props.current_version.id,
      item_type:"debt"
    }
    ApiConnect.post('/api/budget_project/multitem/all/get', request)
    .then(res => {
      this.setState({ 
        data:res.data,
        loaded:true,
        totals:calTotalsForListsWithFilters(res.data,"existing")
      });
    })
    .catch(function (error) {
      alert(error);
    })
  }

  handleAdd =async(el1,el2)=>{
    let months=12
    let first_year=0
    const request={
      budget_id:this.props.budget_id,
      version_id:this.props.current_version.id,
      item_type:"debt",
      content: new DebtCreator(
        el1,
        months,
        first_year,
        "",
        el2
      )
    }
    try{
      const response= await ApiConnect.post(`/api/budget_project/multitem/item/create`, request)
      const event={
        target:{
          name:'edit',
          value:response.data
        }
      }
      this.props.handleScreenNavigation(event)
    } catch(error) {
      alert(error);
    }
  }

  handleDuplicateItem =async(event)=>{
    const request={
      budget_id:this.props.budget_id,
      version_id:this.props.current_version.id,
      item_type:"debt",
      item_id:event.target.value
    }
    try{
      const response= await ApiConnect.post(`/api/budget_project/multitem/item/duplicate`, request)
      this.updateListData()
      this.AlertFunctions.setAlert(response.data)
    } catch(error) {
      alert(error);
    }
  }

  handleDeleteItem =async(event)=>{
    const request={
      budget_id:this.props.budget_id,
      version_id:this.props.current_version.id,
      item_type:"debt",
      item_id:event.target.value
    }
    try{
      const response= await ApiConnect.post(`/api/budget_project/multitem/item/delete`, request)
      this.updateListData()
      this.AlertFunctions.setAlert(response.data)
    } catch(error) {
      alert(error);
    }
  }

  handleUpLevel= async(event)=>{
    const request={
      budget_id:this.props.budget_id,
      version_id:this.props.current_version.id,
      item_type:"debt",
      item_id:event.target.value
    }
    try{
    const response= await ApiConnect.post(`/api/budget_project/multitem/item/uplevel`, request)
      this.updateListData()
    } catch(error) {
      alert(error);
    }
  }

  handleDownLevel= async(event)=>{
    const request={
      budget_id:this.props.budget_id,
      version_id:this.props.current_version.id,
      item_type:"debt",
      item_id:event.target.value
    }
    try{
    const response= await ApiConnect.post(`/api/budget_project/multitem/item/downlevel`, request)
      this.updateListData()
    } catch(error) {
      alert(error);
    }
  }

  handleChangeType=(e)=>{
    let e1=e
    this.setState({
      totals:calTotalsForListsWithFilters(this.state.data,e1.target.name)
    })
    this.props.handleChangeType(e1)
  }

    //TOAST ALERT
    AlertFunctions={
      setAlert:(header,body)=>{
        this.setState({
          toast:{
            show:true,
            header: header,
            body:body
          }
        })
      },
      unsetalert:()=>{
        this.setState({
          toast:{
            show:false
          }
        })
      }
    }

  render(){
    function torender(data, i){
      return data[i];
    }  
    const pages ={
      true: 
      <MultipleFeasibilityListItems 
      data={this.state.data} 
      totals={this.state.totals}
      budget_settings={this.props.budget_settings}
      user_permission={this.props.user_permission}
      handleAdd={this.handleAdd} 
      handleScreenNavigation={this.props.handleScreenNavigation} 
      handleItem={this.handleDeleteItem}
      handleDeleteItem={this.handleDeleteItem}
      handleDuplicateItem={this.handleDuplicateItem}
      handleEditItem={this.props.handleDuplicateItem}
      handleUpLevel={this.handleUpLevel}
      handleDownLevel={this.handleDownLevel}
      handleChangeType={this.handleChangeType}
      toast={this.state.toast}
      multitabs={this.props.multitabs}
      multi_option={this.props.multi_option}
      AlertFunctions={this.AlertFunctions}
      title='debt_title'
      add_item='add_debt'
      />,
      false: <Loader loader_message="debt_list"/>
    }
    var i = this.state.loaded
    const renderizing = torender(pages,i)

    return(
      <div>
        {renderizing}
      </div>
    )
  }
}

export default DebtList;