import React from 'react';
import { Row, Col } from 'Support/grid';
import {Collapse} from 'react-bootstrap';

import DashboardTable from '../0 Dashboard/dashboard_tables';
import Graphs from '../0 Dashboard/dashboard_graphs';
import ExcelDownload from '../0 Dashboard/dashboard_excel';
import {GenerateVersionPPTReport} from 'PRIVATE/PROJECT/Export Central/export_ppt';
import SensitivityAnalysisOptions from './sensitivity_analysis_operator'

import {calResults} from 'CALCULATIONS/cal_incomestatement';

import ApiConnect from 'CONFIG/axios';
import UserContext from 'CONFIG/auth';
import Loader from 'Support/loader';
import color_sets from 'JSON/brand_color_sets.json';
import '../0 Dashboard/dashboard_style.css'

import IterateSensitivityAnalysisVersion from 'CALCULATIONS/cal_sensitivity_analysis_iterations'
import { ActivateButtonPositiveNegative, RegularButtonSmall } from 'Support/listbuttons';

class SensitivityAnalysis extends React.Component{
  static contextType = UserContext

  constructor(props) {
    super(props);
    this.state = {
      data:"",
      loaded:false,
      visuals:true,
      reports:true,
      sensitiviy_analysis_data:'',
      switches:{
        rev_price_switch:false,
        rev_quantity_switch:false,
        rev_cogs_switch:false,
        op_exp_price_switch:false,
        hr_switch:false,
        inv_price_switch:false,
        inv_quantity_switch:false,
        debt_cost_switch:false,
        debt_value_switch:false,
        receivables_switch:false,
        payables_switch:false,
        investment_payables_switch:false,
        inventory_switch:false
      },
      factors:{
        rev_price_factor:0,
        rev_quantity_factor:0,
        rev_cogs_factor:0,
        op_exp_factor:0,
        hr_factor:0,
        inv_price_factor:0,
        inv_quantity_factor:0,
        debt_cost_factor:0,
        debt_value_factor:0,
        receivables_factor:0,
        payables_factor:0,
        investment_payables_factor:0,
        inventory_factor:0
      }
    }
  }

  componentDidMount() {
    this.updateListData()
  }
  
  //GENERAL FUNCTIONS
  updateListData=async()=>{
    const request={
      version_id:this.props.version_id,
      project_id:this.props.project_id,
      settings:this.props.feasibility_settings
    }
    try {
      const response = await ApiConnect.post('/api/version/get', request)
      let edited_data=response.data
      edited_data=await calResults(edited_data,this.props.feasibility_settings)
      this.setState({
        data:edited_data,
        loaded:true,
        sensitiviy_analysis_data:edited_data
      })
    } catch(error) {
        alert(error);
    }
  }

  //MANAGE BOARDS FUNCTIONS
  manageBoard=(event)=>{
    let setter
    const item=event.target.name
    if(this.state[item]===true) {setter= false}
    else {setter=true}
    this.setState({
      [item]:setter
    })
  }

  //MANAGE THE SENSITIVITY ANALYSIS SWTCHS
  handleSwitch=async(event)=>{
    const name=event.target.name
    let go=this.state.switches
    go[name]=!this.state.switches[name]
    const sa= await IterateSensitivityAnalysisVersion(this.state.data,this.props.feasibility_settings,go,this.state.factors)
    this.setState({
      switches:go,
      sensitiviy_analysis_data:sa
    })
  }

  handleFactors=async(event)=>{
    const name=event.target.name
    const value=event.target.value
    let go=this.state.factors
    go[name]=value
    const sa=await IterateSensitivityAnalysisVersion(this.state.data,this.props.feasibility_settings,this.state.switches,go)
    this.setState({
      factors:go,
      sensitiviy_analysis_data:sa
    })
  }
  
  render() {
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_PROJECT.PROJECT_FEASIBILITY.Dashboards')
    const props={
      trsl:trsl,
      data:this.state.sensitiviy_analysis_data,
      initial_data:this.state.data,
      switches:this.state.switches,
      factors:this.state.factors,
      feasibility_settings:this.props.feasibility_settings,
      p_name:this.props.p_name,
      v_name:this.props.v_name,
      manageBoard:this.manageBoard,
      warnings:this.state.warnings,
      visuals:this.state.visuals,
      reports:this.state.reports,
      handleSwitch:this.handleSwitch,
      handleFactors:this.handleFactors,
      user_permission:this.props.user_permission,
      years:this.props.feasibility_settings.historic_years.concat(this.props.feasibility_settings.years)
    }
    function torender(data, i){
      return data[i];
    }
    const pages ={
      true: <ToDisplay {...props}/>,
      false: <Loader loader_message="single_dashboard"/>
    }
    var i = this.state.loaded
    const renderizing = torender(pages,i)

    return(
      <div>{renderizing}</div>
    );
  }
}

class ToDisplay extends React.Component{
  static contextType = UserContext
  render(){
    let user_c= this.context
    const locale=user_c.locale
    
    const active_color_set=color_sets[user_c.template]
    const stackpalette=[
      active_color_set.primary, 
      active_color_set.lightaccent,
      active_color_set.darkaccent,
      active_color_set.lightshade,
      active_color_set.darkshade,
      active_color_set.secondary]

    let show_exportables=''
    if(this.props.user_permission==='owner' || this.props.user_permission==='edit'){
      show_exportables=
        <>
          <h3>{this.props.trsl['exporting']}</h3>
          <ExcelDownload 
          trsl={this.props.trsl} 
          data={this.props.data} 
          feasibility_settings={this.props.feasibility_settings}
          use_historic={this.props.feasibility_settings.user_historic}
          years={this.props.feasibility_settings.years}
          h_years={this.props.feasibility_settings.historic_years.concat(this.props.feasibility_settings.years)}
          p_name={this.props.p_name}
          v_name={this.props.v_name}/>
          <GenerateVersionPPTReport
          trsl={user_c.i18n.t('PRIVATE_PROJECT.Exporting')}
          org={user_c.template}
          data={this.props.data} 
          years={this.props.feasibility_settings.years}
          settings={this.props.feasibility_settings}
          locale={locale}
          p_name={this.props.p_name}
          v_name={this.props.v_name} />
        </>
    }
    const props=this.props
    return(
      <>
        <Row className="dashboard_header_row">
          <Col xs={6}>
            <h3>{this.props.trsl['sensitivity_analysis']}</h3>
          </Col>
          <Col xs={6}>
            <Row middle="xs">
              <Col xs={4}>
              </Col>
              <Col xs={4}>
                <ActivateButtonPositiveNegative name="visuals" label={this.props.trsl['visuals']} value={this.props.visuals} onClick={this.props.manageBoard}/>
              </Col>
              <Col xs={4}>
                <ActivateButtonPositiveNegative name="reports" label= {this.props.trsl['reports']} value={this.props.reports} onClick={this.props.manageBoard}/>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={3}>
            <SensitivityAnalysisOptions {...props}/>
          </Col>
          <Col xs={9}>
         <Collapse in={this.props.visuals}>
            <div className="dashboard_main_separator">
              <Graphs {...props} stackpalette={stackpalette}/>
            </div>
          </Collapse>
          <Collapse in={this.props.reports}>
            <div className="dashboard_main_separator">
              <DashboardTable {...props}/>
            </div>
          </Collapse>
          </Col>
          <Col xs={12}>
            {show_exportables}
          </Col>
        </Row>
      </>
    );
  }
}

export default SensitivityAnalysis;