import React from 'react';
import { Row, Col } from 'Support/grid'
import DebtEntry from './debt';
import DebtList from './debtlist';

class DebtMain extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            to_render: "list",
            active_item:0,
            multi_option:'existing',
        }
        this.handleScreenNavigation=this.handleScreenNavigation.bind(this)
        this.changeScreenView=this.changeScreenView.bind(this)
    }

    changeScreenView=(window, item)=>{
        this.setState({
            active_item: item,
            to_render: window
        })
    }

    handleScreenNavigation(event){
        const value = event.target.value
        const name = event.target.name
        this.changeScreenView(name, value)
    }

    handleChangeType=(event)=>{
        this.setState({ 
          multi_option:event.target.name
        });
      }

    render(){
        const debt_items=[
            {
                item_trsl:'debt_existing',
                add_item_trsl:'add_debt_existing',
                type:'existing',
              },
            {
              item_trsl:'debt_new',
              add_item_trsl:'add_debt_new',
              type:'new',
            }
          ]
        const props={
            active_item:this.state.active_item,
            multi_option:this.state.multi_option,
            budget_id:this.props.budget_id,
            current_version:this.props.current_version,
            changeScreenView:this.changeScreenView,
            budget_settings:this.props.budget_settings,
            user_permission:this.props.user_permission,
            handleScreenNavigation:this.handleScreenNavigation,
            handleChangeType:this.handleChangeType,
            multitabs:debt_items,
        }

        function torender(data, i){
            return data[i];
        }
        const pages ={
            edit: <DebtEntry {...props}/>,
            list: <DebtList {...props}/>
        }
        var i = this.state.to_render
        const renderizing = torender(pages,i)

    return(
      <Col xs={12}>
        {renderizing}
      </Col>
    );
  }
}

export default DebtMain;