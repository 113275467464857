import React from 'react';
import { Row, Col } from 'Support/grid'
import UserContext from 'CONFIG/auth';

import {BackButton, ChangeMenuButton} from 'Support/listbuttons';

class VersionHeaderBar extends React.Component{
  static contextType = UserContext

  render(){
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_PROJECT.PROJECT_FEASIBILITY.Navigation&Header')

    return(
      <Row middle="xs" className="headerbar_row">
        <Col xs={6} md={10}>
          <Row middle='xs'>
            <Col xs={12} md={6}>
              <h4>
                {trsl['header']}
              </h4>
            </Col>
            <Col xs={12} md={6}>
              <h6>
              {trsl['project']}{this.props.p_name}
              </h6>
              <h6>
              {trsl['header_version']}{this.props.v_name}
              </h6>
            </Col>
          </Row>
        </Col>
        <Col xs={6} md={2} className="headerbar_buttons_position">
          <ChangeMenuButton handleEditItem={this.props.handleChangeFeasibilityHeader}/>
          <BackButton name="main" handleEditItem={this.props.handleEditItem}/>
        </Col>
      </Row>
    );
  }
}

class HistoricHeaderBar extends React.Component{
  static contextType = UserContext

  render(){
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_PROJECT.PROJECT_HISTORIC.Navigation&Header')

    return(
      <Row className="headerbar_row grid_middle">
        <Col xs={6} md={10}>
          <Row >
            <Col xs={12} md={6}>
              <h4> {trsl['header']} </h4>
              <h6> {trsl['historic']} </h6>
            </Col>
            <Col xs={12} md={6}>
              <h6>
                {this.props.p_name}
              </h6>
            </Col>
          </Row>
        </Col>
        <Col xs={6} md={2}>
          <Row >
            <Col xs={12} className="headerbar_buttons_position">
              <ChangeMenuButton handleEditItem={this.props.handleChangeFeasibilityHeader}/>
              <BackButton name="main" handleEditItem={this.props.handleExitFromGistoricToMain}/>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

class DashboardHeaderBar extends React.Component{
  render(){
    return(
      <Row middle="xs" className="headerbar_row">
        <Col xs={6} md={10}>
          <Row middle='xs'>
            <Col xs={12} md={4}>
              <h4> {this.props.trsl['header']}</h4>
              <h6> {this.props.trsl['main_dashboard']} </h6>
            </Col>
            <Col xs={12} md={4}>
              <h6>
                {this.props.p_name}
              </h6>
            </Col>
            <Col xs={12} md={4}>
 
            </Col>
          </Row>
        </Col>
        <Col xs={6} md={2} className="headerbar_buttons_position">
          <BackButton name="main" handleEditItem={this.props.handleEditItem}/>
        </Col>
      </Row>
    );
  }
}

export{
  VersionHeaderBar,
  DashboardHeaderBar,
  HistoricHeaderBar
};