import React from 'react';
import { Row, Col } from 'Support/grid';
import {Form} from 'react-bootstrap';

import {GenerateReportPPTX} from './export_ppt';
import {calResults} from 'CALCULATIONS/cal_incomestatement';

import ApiConnect from 'CONFIG/axios';
import UserContext from 'CONFIG/auth';
import Loader from 'Support/loader';
import {ActivateButtonPositiveNegative, BackButton} from 'Support/listbuttons';

import 'CSS/exporting.css'
import { MiniCard } from 'Support/warning_cards';

class Exporting extends React.Component{
  static contextType = UserContext

  constructor(props) {
    super(props);
    this.state = {
      data:"",
      loaded:false,
      selected_template:0,
      ppt_options:{
        general:{
          project_description:true,
          historic:false
        },
        canvas:{
          description:true,
          swot:true,
          pestel:true,
          bmc:true,
          competitive:true,
        },
        feasibility:{
          version_description:true,
          revenues_list:true,
          revenues_assumptions:true,
          revenues_graph:true,
          hr:true,
          costs:true,
          investment:true,
          financing:true,
          shareholders:true,
          debt:true,
          feasibility_assumptions:true,
          is:true,
          bs:true,
          cs:true,
          wc:true,
          ra:true,
          fea:true,
          sap:true,
          lep:true,
          ivp:true,
          dc:true
        }
      },
      selected_versions:["0"]
    }
  }

  componentDidMount() {
    this.updateListData()
  }
  
  //GENERAL FUNCTIONS
  updateListData=async()=>{
    const request={
      version_id:this.props.version_id,
      project_id:this.props.project_id,
      settings:this.props.feasibility_settings
    }
    try {
      const response = await ApiConnect.post('/api/project/report/get', request)
      this.setState({
        data:response.data,
        loaded:true
      })
    } catch(error) {
        alert(error);
    }
  }

  handleSelectTemplate=(value)=>{
    this.setState({
      selected_template:value
    })
  }

  handleCanvasSwitches=async(event)=>{
    const name = event.target.name
    let go = this.state.ppt_options
    go.canvas[name]=!this.state.ppt_options.canvas[name]
    this.setState({
      ppt_options:go
    })
  }
  handleGeneralSwitches=async(event)=>{
    const name = event.target.name
    let go = this.state.ppt_options
    go.general[name]=!this.state.ppt_options.general[name]
    this.setState({
      ppt_options:go
    })
  }
  handleFeasibilitySwitches=async(event)=>{
    const name = event.target.name
    let go = this.state.ppt_options
    go.feasibility[name]=!this.state.ppt_options.feasibility[name]
    this.setState({
      ppt_options:go
    })
  }

  handleVersionSwitches=(event)=>{
    const value = event.target.value
    let go = this.state.selected_versions
    if(go.includes(value)){
      go.splice(go.indexOf(value),1)
    } else{
      go.push(value)
    }
    this.setState({
      selected_versions:go
    })
  }
  
  render() {
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_PROJECT.Exporting')
    const props={
      trsl:trsl,
      data:this.state.data,
      selected_template:this.state.selected_template,
      ppt_options:this.state.ppt_options,
      selected_versions:this.state.selected_versions,
      feasibility_options:this.state.feasibility_options,
      feasibility_settings:this.props.feasibility_settings,
      p_name:this.props.project_data.project_name,
      user_permission:this.props.project_data.user_permission,
      handleSelectTemplate:this.handleSelectTemplate,
      handleCanvasSwitches:this.handleCanvasSwitches,
      handleGeneralSwitches:this.handleGeneralSwitches,
      handleFeasibilitySwitches:this.handleFeasibilitySwitches,
      handleVersionSwitches:this.handleVersionSwitches,
      handleEditItem:this.props.handleEditItem
    }
    function torender(data, i){
      return data[i];
    }
    const pages ={
      true: <ToDisplay {...props}/>,
      false: <Loader loader_message="single_dashboard"/>
    }
    var i = this.state.loaded
    const renderizing = torender(pages,i)

    return(
      <div>{renderizing}</div>
    );
  }
}

class ToDisplay extends React.Component{
  static contextType = UserContext
  render(){
    let user_c= this.context
    const locale=user_c.locale
    const props=this.props

    const runCalculationsThroughVersions=async(c, settings)=>{
      for(var i=0; i<c.feasibility.feasibility_versions.length;i++){
        c.feasibility.feasibility_versions[i]=await calResults(c.feasibility.feasibility_versions[i],settings) 
      }
      return c
    }
    const edited_data=runCalculationsThroughVersions(this.props.data,this.props.feasibility_settings)
  
    let show_exportables=''
    if(this.props.user_permission==='owner' || this.props.user_permission==='edit'){
      show_exportables=
          <Row className="feasibility_edit_globalheader_row">
            <Col xs={12} md={6}>
              <Row>
                <SelectTemplate {...props} org_template={user_c.template}/>
              </Row>
            </Col>
            <Col xs={12} md={1}></Col>
            <Col xs={12} md={5}>
              <h4>{this.props.trsl['custom_bp']}</h4>
              {this.props.trsl['custom_bp_explanation']}
              <CanvasExportingOptions {...props}/>
              <FeasibilityExportingOptions {...props} />
              <Row>
                <Col xs={12}>
                  <GenerateReportPPTX
                  trsl={this.props.trsl} 
                  selected_template={this.props.selected_template}
                  org={user_c.template}
                  options={this.props.ppt_options}
                  selected_versions={this.props.selected_versions}
                  data={this.props.data} 
                  years={this.props.feasibility_settings.years}
                  h_years={this.props.feasibility_settings.historic_years.concat(this.props.feasibility_settings.years)}
                  settings={this.props.feasibility_settings}
                  locale={locale}
                  p_name={this.props.p_name}
                  v_name={this.props.v_name} />
                </Col>
                <Col className='blanktop_10'></Col>
              </Row>
            </Col>
          </Row>
    }

    return(
      <>
        <Row Row className="feasibility_headerbar_panes">
          <Col xs={12}>
            <ExportHeaderBar p_name={this.props.p_name}trsl={this.props.trsl} handleEditItem={this.props.handleEditItem}/>
          </Col>
          <Col xs={12}>
            {show_exportables}
          </Col>
        </Row>
      </>
    );
  }
}

class ExportHeaderBar extends React.Component{
  static contextType = UserContext

  render(){
    return(
      <Row middle="xs" className="headerbar_row">
        <Col xs={4} md={3}>
          <h4>
            {this.props.trsl['header']}
          </h4>
        </Col>
        <Col xs={4} md={3}>
          <h6>
            {this.props.p_name}
          </h6>
        </Col>
        <Col xs={1} md={4}>
          <h6>
          {this.props.trsl['header_version']}{this.props.v_name}
          </h6>
        </Col>
        <Col xs={3} md={2} className="headerbar_buttons_position">
          <BackButton name="main" handleEditItem={this.props.handleEditItem}/>
        </Col>
      </Row>
    );
  }
}

function CanvasExportingOptions(props){
  if(props.data.canvas!==null){
    return(
      <Row>
      <Col xs={12}><h5 className='template_options_h5'>{props.trsl['canvas_module_features']}</h5></Col>
      <ActivateButtonPositiveNegative value={props.ppt_options.canvas.description} label={props.trsl['description']} name='description' onClick={props.handleCanvasSwitches}/>
      <ActivateButtonPositiveNegative value={props.ppt_options.canvas.swot} label={props.trsl['swot']} name='swot' onClick={props.handleCanvasSwitches}/>
      <ActivateButtonPositiveNegative value={props.ppt_options.canvas.pestel} label={props.trsl['pestel']} name='pestel' onClick={props.handleCanvasSwitches}/>
      <ActivateButtonPositiveNegative value={props.ppt_options.canvas.bmc} label={props.trsl['bmc']} name='bmc' onClick={props.handleCanvasSwitches}/>
      <ActivateButtonPositiveNegative value={props.ppt_options.canvas.competitive} label={props.trsl['competitive']} name='competitive' onClick={props.handleCanvasSwitches}/>
    </Row>
    )
  } else return ""
}

function FeasibilityExportingOptions(props){
  const exportHistoric=(props)=>{
    if(props.data.feasibility.feasibility_historic!==undefined){
      return <ActivateButtonPositiveNegative value={props.ppt_options.general.historic} name='historic' label={props.trsl['historic']} onClick={props.handleGeneralSwitches}/> 
    }
  }
  if(props.data.feasibility!==null){
    return(
      <Row>
        <Col xs={12}><h5 className='template_options_h5'>{props.trsl['feasibility_module_features']}</h5></Col>
        <Col xs={12}><h6 className='template_options_h6'>{props.trsl['general']}</h6></Col>
        <ActivateButtonPositiveNegative value={props.ppt_options.general.project_description} name='project_description' label={props.trsl['project_description']} onClick={props.handleGeneralSwitches}/>
        {exportHistoric(props)}
        <ActivateButtonPositiveNegative value={props.ppt_options.feasibility.version_description} name='version_description' label={props.trsl['version_description']} onClick={props.handleFeasibilitySwitches}/>
        <Col xs={12}><h6 className='template_options_h6'>{props.trsl['assumptions']}</h6></Col>
        <ActivateButtonPositiveNegative value={props.ppt_options.feasibility.revenues_list} label={props.trsl['revenues_list']} name='revenues_list' onClick={props.handleFeasibilitySwitches}/>
        <ActivateButtonPositiveNegative value={props.ppt_options.feasibility.revenues_assumptions} label={props.trsl['revenues_assumptions']} name='revenues_assumptions' onClick={props.handleFeasibilitySwitches}/>
        <ActivateButtonPositiveNegative value={props.ppt_options.feasibility.hr} label={props.trsl['hr']} name='hr' onClick={props.handleFeasibilitySwitches}/>
        <ActivateButtonPositiveNegative value={props.ppt_options.feasibility.costs} label={props.trsl['costs_']} name='costs' onClick={props.handleFeasibilitySwitches}/>
        <ActivateButtonPositiveNegative value={props.ppt_options.feasibility.investment} label={props.trsl['investment_']} name='investment' onClick={props.handleFeasibilitySwitches}/>
        <ActivateButtonPositiveNegative value={props.ppt_options.feasibility.financing} label={props.trsl['financing_']} name='financing' onClick={props.handleFeasibilitySwitches}/>
        <ActivateButtonPositiveNegative value={props.ppt_options.feasibility.shareholders} label={props.trsl['shareholders']} name='shareholders' onClick={props.handleFeasibilitySwitches}/>
        <ActivateButtonPositiveNegative value={props.ppt_options.feasibility.debt} label={props.trsl['debt']} name='debt' onClick={props.handleFeasibilitySwitches}/>
        <ActivateButtonPositiveNegative value={props.ppt_options.feasibility.feasibility_assumptions} label={props.trsl['feasibility_assumptions']} name='feasibility_assumptions' onClick={props.handleFeasibilitySwitches}/>
        <Col xs={12}><h6 className='template_options_h6'>{props.trsl['results_']}</h6></Col>
        <ActivateButtonPositiveNegative value={props.ppt_options.feasibility.is} label={props.trsl['is']} name='is' onClick={props.handleFeasibilitySwitches}/>
        <ActivateButtonPositiveNegative value={props.ppt_options.feasibility.bs} label={props.trsl['bs']} name='bs' onClick={props.handleFeasibilitySwitches}/>
        <ActivateButtonPositiveNegative value={props.ppt_options.feasibility.cs} label={props.trsl['cs']} name='cs' onClick={props.handleFeasibilitySwitches}/>
        <ActivateButtonPositiveNegative value={props.ppt_options.feasibility.wc} label={props.trsl['wc']} name='wc' onClick={props.handleFeasibilitySwitches}/>
        <ActivateButtonPositiveNegative value={props.ppt_options.feasibility.ra} label={props.trsl['ra']} name='ra' onClick={props.handleFeasibilitySwitches}/>
        <ActivateButtonPositiveNegative value={props.ppt_options.feasibility.fea} label={props.trsl['fea']} name='fea' onClick={props.handleFeasibilitySwitches}/>
        <ActivateButtonPositiveNegative value={props.ppt_options.feasibility.sap} label={props.trsl['sap']} name='sap' onClick={props.handleFeasibilitySwitches}/>
        <ActivateButtonPositiveNegative value={props.ppt_options.feasibility.lep} label={props.trsl['lep']} name='lep' onClick={props.handleFeasibilitySwitches}/>
        <ActivateButtonPositiveNegative value={props.ppt_options.feasibility.ivp} label={props.trsl['ivp']} name='ivp' onClick={props.handleFeasibilitySwitches}/>
        <ActivateButtonPositiveNegative value={props.ppt_options.feasibility.dc} label={props.trsl['dc']} name='dc' onClick={props.handleFeasibilitySwitches}/>
        <Col xs={12}><h6 className='template_options_h6'>{props.trsl['select_version']}</h6></Col>
        <Col xs={12}>
          <ChooseVersion {...props}/>
        </Col>
    </Row>
    )
  }
}

function ChooseVersion(props){
  const item_holder=props.data.feasibility.feasibility_versions
  let item_distr=""
  let selected=props.selected_versions
  item_distr=item_holder.map((value,k)=>{
    let checker=false
    if (selected.includes(k.toString())){
      checker=true
    }
    return(
      <Col xs={12}>
        <Form>
        <Form.Check
        type='checkbox'
        checked={checker}
        label={value.version_name}
        id={k}
        value={k}
        onChange={props.handleVersionSwitches}
        />
        </Form>
      </Col>
    )
  })
  return(
    <Row>
      {item_distr}
    </Row>
  )
}

function SelectTemplate (props){
  const template_names={
    0:'dark_blue',
    1:'light_blue',
    2:'black',
    3:'city',
    4:'rio_de_janeiro',
    5:'lisbon',
    6:'cycling'
  }
  if(props.org_template!=="casflo"){
    return""
  } else{
    return(
      <>
        <Col xs={12}>
          <h4>{props.trsl['select_template']}</h4>
        </Col>
        <TemplatePicker template={0} template_names={template_names} {...props}/>
        <TemplatePicker template={1} template_names={template_names} {...props}/>
        <TemplatePicker template={2} template_names={template_names} {...props}/>
        <TemplatePicker template={3} template_names={template_names} {...props}/>
        <TemplatePicker template={4} template_names={template_names} {...props}/>
        <TemplatePicker template={5} template_names={template_names} {...props}/>
        <TemplatePicker template={6} template_names={template_names} {...props}/>
      </>
    )
  }
}

function TemplatePicker (props){
  let color= 'primary'
  if(props.template == props.selected_template){
    color= 'success'
  }

  const onclick=()=>{
    props.handleSelectTemplate(props.template)
  }
  const header = props.trsl[props.template_names[props.template]]
  const body = <img className="template_image" src={require(`./exporting templates/casflo${props.template}/example.png`)} onClick={onclick}/>
  return(
    <MiniCard xs={12} md={6} lg={4} color={color} header={header} body={body}/>
  )
}

export default Exporting;