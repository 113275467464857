import React from 'react';
import { Grid, Row, Col} from 'Support/grid';
import Loader from 'Support/loader';
import { BuyOptionsDisabled, BuyOptionExtraVersions, CheckOptionProjectRevision } from 'Support/buy_options';
import {StripeCurrencySelector} from 'PRIVATE/ACCOUNT/subscription_stripe';

import UserContext from 'CONFIG/auth';
import ApiConnect from 'CONFIG/axios';

class Pricing extends React.Component {
  static contextType = UserContext

  constructor(props) {
    super(props);
    this.state = {
      data:"",
      data_loaded:false,
      currency:'eur'
    }
  }

  componentDidMount(){
    this.updateData(  )
  }
  updateData=async()=>{
    const response = await ApiConnect.post('/api/user/helpdesk/pricing')
    this.setState({
      data:response.data,
      data_loaded:true
    })
  }

  handleCurrencySelection=(event)=>{
    this.setState({
      currency:event.target.value
    })
  }
  render(){
    const props ={
      data:this.state.data,
      currency:this.state.currency,
      handleCurrencySelection:this.handleCurrencySelection
    }

    function torender(data, i){
      return data[i];
    }  
    const pages ={
      true: <ToDisplay {...props}/>,
      false: <Loader loader_message="pricing"/>
    }
    var i = this.state.data_loaded
    const renderizing = torender(pages,i)

    return(
      <>
        {renderizing}
      </>
    )
  }
}

class ToDisplay extends React.Component {
  static contextType = UserContext
render(){
  const props=this.props
  let user_c= this.context
  const trsl=user_c.i18n.t('PRIVATE.Pricing')

  let bp_holder=this.props.data.bp_prices.business_plan
  const bp_distr= bp_holder.map((el)=>{
    const it={ 
      duration:el.period,
      price:el.price[this.props.currency],
      versions:6
    }
    return(
      <BuyOptionsDisabled it={it} currency={this.props.currency} trsl={trsl} user_c={user_c}/>
    )
  })

  const budget_holder=this.props.data.budget_prices.find(el => el.currency==this.props.currency)
  const budget_distr= budget_holder.base.durations.map((el)=>{
    const it={ 
      duration:el.duration,
      price:el.price,
      versions:el.versions
    }
    return(
      <BuyOptionsDisabled it={it} currency={this.props.currency} trsl={trsl} user_c={user_c}/>
    )
  })

    return(
      <Grid fluid>
        <Row >
          <Col xs={12} className='blanktop_15'>
            <h1>{trsl['pricing']} </h1>
          </Col>
          <Col xs={12} md={9} className='blanktop_05'>
            <Row>
              <Col xs={12}>
                <h3>{trsl['bp']} </h3>
                <div className='pricing_group'>
                  {bp_distr}
                </div>
              </Col>
              <Col xs={12} className='blanktop_15'>
                <h3>{trsl['budget']} </h3>
                <div className='pricing_group'>
                  {budget_distr}
                  <BuyOptionExtraVersions price={budget_holder.base.extra_version.price} currency={this.props.currency} trsl={trsl} user_c={user_c}/>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={3} className='blanktop_05'>
            <StripeCurrencySelector handleCurrencySelection={this.props.handleCurrencySelection}/>
          </Col>
        </Row>
      </Grid>
    )
  }
}

export default Pricing;