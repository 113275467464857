import React from 'react';
import{Grid, Row, Col} from 'Support/grid';

import './admin_style.css'
import AdminUsers from './admin_acounts_list';
import AdminPartnerUsers from './admin_partners';
import AdminSuperChange from './admin_super';
import AdminImport from './admin_import';
import AdminImportData from './importa_data';

import {SimpleHorizontalTab} from 'Support/tabs'

class ManagerMain extends React.Component{
  constructor(props){
    super(props);
    this.state = {
        screen: "users_list",
        user_view:'',
        subscription_view:''
    }
    this.handleAdminMainScreenNavigation=this.handleAdminMainScreenNavigation.bind(this)
  }

  handleAdminMainScreenNavigation=(event)=>{
    const name=event.target.name
    this.setState({
      screen:name
    })
  }



  render(){
    const screens=[
      {label:'partners', value:'partners'},
      {label:'users_list', value:'users_list'},
      {label:'super_change', value:'super_change'},
      {label:'admin_import', value:'admin_import'}
    ]
    
    const pages={
      partners: <AdminPartnerUsers />,
      users_list:<AdminUsers />,
      super_change:<AdminSuperChange />,
      admin_import:<AdminImport />
    }
    function toRender(data, i){
      return data[i];
    } 
    const renderizing = toRender(pages,this.state.screen)

    return(
      <Grid fluid>
        <Row>
          <Col xs={12}>
            <SimpleHorizontalTab options={screens} handleTabs={this.handleAdminMainScreenNavigation}/>
                  {renderizing}
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default ManagerMain;