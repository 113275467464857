class OtherGainsCostCreator{
  constructor(name, array_size, typester,hist){
    this.other_gains_cost_item_name=name
    this.other_gains_cost_type=typester
    this.other_gains_cost_value=new Array(array_size).fill(0)
    this._other_gains_cost_accumulated=new Array(array_size).fill(0)
    this._rev_hist=hist
    this.other_gains_cost_obs=""
  }
}

export default OtherGainsCostCreator;