import React,{useContext} from 'react';
import { Row, Col } from 'Support/grid';
import UserContext from 'CONFIG/auth';

import {BudgetList, BudgetPeriodHeaderWithEdit,FilterList, BudgetListTotal} from 'Support/inputforms'
import { RegularButton, DeleteButton, EditButton, DuplicateButton, ViewButtonForObserver, RegularButtonSmall, UpButton, DownButton} from 'Support/listbuttons.js';
import {AddItem} from 'Support/add_item';
import { ToogleButtons } from 'Support/toogle_buttons';

function SinglesFeasibilityListItems(props){
  const user_c = useContext(UserContext);
  const trsl=user_c.i18n.t('PRIVATE_BUDGET.INSIDEPROJECT.Lists')
  const periods=["m1","m2","m3","m4","m5","m6","m7","m8","m9","m10","m11","m12"]
  return(
    <>
      <FeasibilityListHeader {...props} trsl={trsl} periods={periods}/>
      <ListItems {...props} trsl={trsl} periods={periods}/>
    </>
  )
}


function MultipleFeasibilityListItems (props){
  const user_c = useContext(UserContext);
  const trsl=user_c.i18n.t('PRIVATE_BUDGET.INSIDEPROJECT.Lists')
  const periods=["m1","m2","m3","m4","m5","m6","m7","m8","m9","m10","m11","m12"]

  const item_holder=props.multitabs
  const size= 12 / item_holder.length
  const selected=props.multi_option
  const item_distr=item_holder.map((value)=>{
    if(value.type===selected){
      return(
        <Col xs={size}>
          <RegularButton bk='bk_darkshade' name={value.type} onClick={props.handleChangeType} label={trsl[value.item_trsl]}/>
        </Col>
      )
    } else{
        return(
          <Col xs={size}>
            <RegularButton bk='bk_white' name={value.type} onClick={props.handleChangeType} label={trsl[value.item_trsl]}/>
          </Col>
        )
      }
  })
  function filterMultiItems(arr, filter){
    return arr.filter(el => el.item_typester===filter)
  }
  const filtered_data=filterMultiItems(props.data,selected)
 
  const add_element=item_holder.find(el => el.type ==selected).add_item_trsl

  const element2=item_holder.find(el => el.type ==selected).type

  const propis={
    data:filtered_data,
    totals:props.totals,
    budget_settings:props.budget_settings,
    user_permission:props.user_permission,
    handleAdd:props.handleAdd, 
    handleScreenNavigation:props.handleScreenNavigation,
    handleItem:props.handleDeleteItem,
    handleDeleteItem:props.handleDeleteItem,
    handleDuplicateItem:props.handleDuplicateItem,
    handleEditItem:props.handleDuplicateItem,
    handleUpLevel:props.handleUpLevel,
    handleDownLevel:props.handleDownLevel,
    toast:props.toast,
    AlertFunctions:props.AlertFunctions,
    add_item:add_element,
    element2:element2
  }
  return(
    <>
      <FeasibilityListHeader {...props} trsl={trsl} periods={periods}/>
      <div className="feasiblity_tabs_rows">
        <Row>{item_distr}</Row>
      </div>
      <ListItems {...propis} trsl={trsl} periods={periods}/>
    </>
  );
}

function FeasibilityListHeader (props){
  return(
    <Row className="feasibility_edit_globalheader_row">
      <Col xs={12}>
        <h4>{props.trsl[props.title]}</h4>
      </Col>
    </Row>
  )
}

class ListItems extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      data:[],
      usable_data:[],
      sorter:'',
      toogle_months:false
    }
  }

  static getDerivedStateFromProps(props, state) {
    if(props.data!==state.data){
      return {
        data:props.data,
        usable_data:props.data
      }
    }
  }

  filterTheTable=(event)=>{
    const filterItems = (arr, query) => {
      return arr.filter(el => el.item_name.toLowerCase().indexOf(query.toLowerCase()) !== -1)
    }
    let go=this.props.data
    let tester= event.target.value
    if(tester.length>0){
      go=filterItems(this.props.data,tester)
    }
    this.setState({
      usable_data:go
    })
  }

  handleViewNavigation=(e)=>{
    const event={
      target:{
        name:'edit',
        value:e.target.value
      }
    }
    this.props.handleScreenNavigation(event)
  }

  handleMonthsAndTotals=()=>{
    this.setState({
      toogle_months: !this.state.toogle_months
    })
  }


  render(){
    const data = this.state.usable_data
    const data_distr = data.map((keys) =>{

      function setListButtons(props,keys,handleViewNavigation){
        if(props.user_permission=="owner" || props.user_permission=="edit"){
          return(
            <>
              <EditButton handleEditItem={props.handleScreenNavigation} name="edit" value={keys.item_id}/>
              <DeleteButton handleDelete={props.handleDeleteItem} value={keys.item_id}/>
              <DuplicateButton handleDuplicate={props.handleDuplicateItem} value={keys.item_id}/>
              <UpButton onClick={props.handleUpLevel} value={keys.item_id}/>
              <DownButton onClick={props.handleDownLevel} value={keys.item_id}/>
            </>
          )
        } else{
          return(
            <>
              <ViewButtonForObserver handleEditItem={handleViewNavigation} permission='show' name="edit" value={keys.item_id}/>
            </>
          )
        }
      }
      const buttonRendering=setListButtons(this.props,keys, this.handleViewNavigation)
      
      return(
        <Col xs={12}>
        <Row className=" grid_middle non_editable_list">
            <BudgetList
            trsl={this.props.trsl}
            periods={this.props.periods}
            item_holder={keys.item_values}
            label={keys.item_name}
            value_type="currency"
            currency={this.props.budget_settings.currency}
            decimals={this.props.budget_settings.decimal_on_report}
            toogle={this.state.toogle_months}
            />
            <Col xs={6} md={2}>
              <Row className="grid_center">
                {keys.item_center}
              </Row>
            </Col>
          <Col xs={6} md={3}>
            <Row>
              {buttonRendering}
            </Row>
          </Col>
        </Row>
        </Col>
      )}
    );

    const showTotals=(props)=>{
      if(props.totals!==undefined && props.totals.length>0){
        return(
          <Col xs={12}>
          <Row className="grid_middle non_editable_list">
              <BudgetListTotal
              trsl={this.props.trsl}
              periods={this.props.periods}
              item_holder={this.props.totals}
              label={this.props.trsl['totals']}
              value_type="currency"
              currency={this.props.budget_settings.currency}
              decimals={this.props.budget_settings.decimal_on_report}
              toogle={this.state.toogle_months}
              />
            <Col xs={2}></Col>
          </Row>
          </Col>
        )
      } else return ''
    }
    const totals_distr =showTotals(this.props) 
    
    const toogle_options=[
      {value:true,option_label:this.props.trsl['total']},
      {value:false,option_label:this.props.trsl['months']}
    ]
    return(
      <>
        <Row className='feasibility_middle_row'>     
          <Col xs={12} md={6}>
            <AddItem add_item={this.props.trsl[this.props.add_item]} element2={this.props.element2} handleAdd={this.props.handleAdd} disbaled={this.props.user_permission}/>
          </Col>
          <Col xs={6} md={3}>
            <FilterList label={this.props.trsl['filter']} placeholder={this.props.trsl['filter_placeholder']} filterTheTable={this.filterTheTable}/>
          </Col>
          <ToogleButtons xs={6} md={3} label={this.props.trsl['toogle_months']} toogle_data={toogle_options} selected={this.state.toogle_months} handleToogleButton={this.handleMonthsAndTotals}/>
        </Row>
        <Row>
          <Col xs={12}>
            <BudgetPeriodHeaderWithEdit
            label1={this.props.trsl['element']} 
            label2={this.props.trsl['months']}
            label3={this.props.trsl['centers']}
            label4={this.props.trsl['actions']}
            handleSortRequest={this.handleSortRequest}/>
            </Col>
        </Row>
        <Row>
          <Col xs={12}>
            {data_distr}
            {totals_distr}
          </Col>
        </Row>
      </>
    );
  }
}

class AssumptionHeaderSaveReturn extends React.Component{
  render(){
    let edit_ony_permission=true
    if(this.props.user_permission=="owner" || this.props.user_permission=="edit"){
      edit_ony_permission= false
    }
    return(
      <Row className="feasibility_edit_globalheader_row" >
      <Col xs={12} md={6}>
        <h4>{this.props.trsl['vs_name']}</h4>
      </Col>
      <Col xs={6} md={3}>
        <RegularButtonSmall value={0} bk="bk_warning" label={this.props.trsl['back_button']} name="list" onClick={this.props.handleScreenNavigation} />
      </Col>
      <Col xs={6} md={3}>
        <RegularButtonSmall bk="bk_success" label= {this.props.trsl['save_back_button']} value={0} name="list" onClick={this.props.handleSave} disabled={edit_ony_permission} />
      </Col>
      </Row>
    )
  }
}

class AssumptionHeaderSave extends React.Component{
  render(){
    let edit_ony_permission=true
    if(this.props.user_permission=="owner" || this.props.user_permission=="edit"){
      edit_ony_permission= false
    }
    return(
      <Row className="feasibility_edit_globalheader_row" >
      <Col xs={12} md={9}>
        <h4>{this.props.trsl['vs_name']}</h4>
      </Col>
      <Col xs={12} md={3}>
        <RegularButtonSmall bk="bk_success" label= {this.props.trsl['save_back_button']} value={0} name="list" onClick={this.props.handleSave} disabled={edit_ony_permission} />
      </Col>
      </Row>
    )
  }
}




export{
  MultipleFeasibilityListItems,
  SinglesFeasibilityListItems,
  AssumptionHeaderSaveReturn,
  AssumptionHeaderSave
}