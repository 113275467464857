import React, {useContext} from 'react';
import {Button, Image, Form} from 'react-bootstrap';
import {Grid,Row, Col} from 'Support/grid';
import UserContext from 'CONFIG/auth';
import casflo_logo from 'TEMPLATES/BAZAAR/Images/casflo_logo_black.png';
import bazaar_logo from 'TEMPLATES/BAZAAR/Images/bazaar_logo.jpeg';
import EU from 'images/useful/EU.png';
import PT from 'images/useful/PT.jpeg';

class HeaderRegularBazaar extends React.Component{
  render(){
    const user_c=this.props.user_c
    const avatar=this.props.avatar

    const screen_width = (window.innerWidth > 0) ? window.innerWidth : window.width;
    let show_Avatar=''
    if(screen_width>756){
      show_Avatar=
        <Col xs={8} md={1}>
          <Row bottom="xs" className="header_profile">
            <Col xs={7}>
              <Image 
              className="header_profile_image" 
              roundedCircle 
              src={require(`images/avatars/user-${avatar}.png`).default} />
            </Col>
          </Row>
        </Col>
    }

    return(        
    <Grid fluid className="header_brand">
        <Row noGutters>
          <Col xs={12}>
          <Row middle="xs">
            <Col xs={6} md={5}>
              <a href='https://casflo-app.com' target='_blank'>
                <Image className="header_logo" src={casflo_logo} />
              </a>
            </Col>
            <Col xs={6} md={2}>
              <a href='https://bazaar.com.pt' target='_blank'>
                <Image className='header_logo'src={bazaar_logo}/>
              </a>
            </Col>
            <Col xs={12} md={5}>
              <Row>
                <Col xs={6} md={4}>
                  <Button variant="outline-primary" size="sm" block name="/app/main" onClick={this.props.handlePrincipalButtonRouting}>{user_c.i18n.t('PRIVATE.Header.main_page')}</Button>
                </Col>
                <Col xs={6} md={4}>          
                  <Button variant="outline-primary" size="sm" block name="/app/profile" onClick={this.props.handlePrincipalButtonRouting}>{user_c.i18n.t('PRIVATE.Header.user')}</Button>
                </Col>
                <Col xs={6} md={4}>          
                  <Button variant="outline-primary" size="sm" block onClick={this.props.handlePrincipalButtonRouting}>{user_c.i18n.t('PRIVATE.Header.logout')}</Button>
                </Col>
              </Row>
            </Col>
          </Row>
          </Col>
          </Row >
        </Grid>
    );
  }
}

class FooterBazaar extends React.Component{
  static contextType = UserContext
  render(){
    let user_c= this.context
    return(
      <Row middle="xs" >
        <Col lg={3} md={4} xs={12}>
          <h6>BAZAAR PLAN</h6>
        </Col>
        <Col lg={5} md={4} xs={12}><h6>Made in Portugal </h6></Col>
        <Col lg={2} md={2} xs={12}>
          <Image src={PT} className="footer_flag"/>
          <Image src={EU} className="footer_flag"/>
        </Col>
        <Col lg={2} md={2} xs={12}>
          <h6><a href="https://casflo-app.com/terms-of-service/" target="blank">{user_c.i18n.t('PRIVATE.Header.terms')}</a></h6>
        </Col>   
      </Row>
    )
  }
}

export {
  HeaderRegularBazaar,
  FooterBazaar
}