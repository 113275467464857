import React from 'react';
import { Row, Col } from 'Support/grid'

import {QuestionButton, BackButton} from 'Support/listbuttons';

class CanvaHeaderBar extends React.Component{
  render(){
    return(
      <Row middle="xs" className="headerbar_row">
        <Col xs={6} md={10}>
          <Row middle='xs'>
            <Col xs={12} md={4}>
              <h4>Canvas</h4>
            </Col>
            <Col xs={12} md={4}>
              <h6>{this.props.p_name}</h6>
            </Col>
            <Col xs={12} md={4}>
            </Col>
          </Row>
          </Col>
          <Col xs={6} md={2}>
            <Row className='headerbar_buttons_position'>
                <QuestionButton />
                <BackButton name="main" handleEditItem={this.props.handleEditItem}/>
          </Row>
        </Col>
      </Row>
    );
  }
}

export{
  CanvaHeaderBar
};