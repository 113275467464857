const {max,subtract, multiply, dotMultiply, add} = require('mathjs');

function vatSelector(settings,reference){
  let use_vat_rate
  if(settings.use_general_vat_rate===true){
    use_vat_rate=settings.vat
  } else{
    use_vat_rate=reference
  }
  return use_vat_rate
}

const cal_inv_depreciation_months=(depreciation_rate)=>{
  let result
  if(depreciation_rate>0){
    result = 1/depreciation_rate*12
  } else {
    result=0
  }
  return result
}

const cal_inv_monthly_depreciation=(inv_quantity,inv_price, inv_depreciation_rate,inv_subsidy_option,inv_subsidy_percentage)=>{
  if(inv_depreciation_rate===0){
    return 0
  } else if(inv_subsidy_option===true){
    return inv_quantity*inv_price*(1-(inv_subsidy_percentage/100))*(inv_depreciation_rate)/12
  } else {
    return inv_quantity*inv_price*(inv_depreciation_rate)/12
  }
}

const cal_inv_gross_value=(years, inv_year, inv_quantity, inv_price,inv_subsidy_option,inv_subsidy_percentage)=>{
  var bug=[]
  let i
  for(i=0;i<years;i++){
    if(i<inv_year){
      bug[i]=0
    } else{
      if(inv_subsidy_option===true){
        bug[i]=inv_quantity*inv_price*(1-(inv_subsidy_percentage/100))
      } else {
        bug[i]=inv_quantity*inv_price
      }
    }
  }
  return bug
}

const cal_inv_acquisition=(years,inv_year,inv_quantity,inv_price)=>{
  var bug=[]
  let i
  for(i=0;i<years;i++){
    if(i==inv_year){
      bug[i]=inv_quantity*inv_price
    } else{
      bug[i]=0
    }
  }
  return bug
}

const calInvNetAcquisition=(_inv_acquisition, inv_subsidy_option,inv_subsidy_percentage)=>{
  var bug=[]
  if(inv_subsidy_option===true){
    bug=dotMultiply((1-(inv_subsidy_percentage/100)),_inv_acquisition)
  } else{
    bug=_inv_acquisition
  }
  return bug
}

const calSubsidyComparticipation=(_inv_acquisition, inv_subsidy_option,inv_subsidy_percentage)=>{
  var bug=new Array(_inv_acquisition.length).fill(0)
  if(inv_subsidy_option===true){
    bug=dotMultiply(((inv_subsidy_percentage/100)),_inv_acquisition)
  }
  return bug
}

const cal_inv_depreciations=(years, inv_year,inv_depreciation_months, inv_month)=>{
  var bug=[]
  let i
  inv_month=Number(inv_month)
  for(i=0;i<years;i++){
    if(i==inv_year){
      bug[i]=inv_depreciation_months-12+inv_month
    } else if((i>inv_year)){
      bug[i]=max(bug[i-1]-12,0)
    } else{
      bug[i]=0
    }
  }
  return bug
}

const cal_inv_acumulated_depreciation=(years,inv_year,inv_depreciation_months,inv_depreciations,inv_monthly_depreciation)=>{
  var bug=[]
  let i
  for(i=0;i<years;i++){
    if(i>=inv_year){
      bug[i]=(inv_depreciation_months-inv_depreciations[i])*inv_monthly_depreciation
    } else{
      bug[i]=0
    }
  }
  return bug
}

const cal_inv_depreciation=(years,_inv_acumulated_depreciation)=>{
  var bug=[]
  let i
  for(i=0;i<years;i++){
    if(i==0){
      bug[i]=_inv_acumulated_depreciation[i]
    } else{
      bug[i]=_inv_acumulated_depreciation[i]-_inv_acumulated_depreciation[i-1]
    }
  }
  return bug
}

const calculateInvestment= (c,years,settings)=>{
  //calculate depreciation months
  c._inv_depreciation_months=cal_inv_depreciation_months(c.inv_depreciation_rate)
  //calculate monthly depreciation
  c._inv_monthly_depreciation=cal_inv_monthly_depreciation(c.inv_quantity,c.inv_price, c.inv_depreciation_rate,c.inv_subsidy_option, c.inv_subsidy_percentage)
  //calculate gross value
  c._inv_gross_value=cal_inv_gross_value(years, c.inv_year, c.inv_quantity, c.inv_price, c.inv_subsidy_option, c.inv_subsidy_percentage)
  //calculate investment acquisition
  c._inv_acquisition=cal_inv_acquisition(years,c.inv_year,c.inv_quantity,c.inv_price)
  //calculate investment acquisition after subsidy
  c._inv_net_acquisition=calInvNetAcquisition(c._inv_acquisition,c.inv_subsidy_option,c.inv_subsidy_percentage)
  //calculate comparticipation of subsidy
  c._inv_subsidy_comparticipation=calSubsidyComparticipation(c._inv_acquisition,c.inv_subsidy_option,c.inv_subsidy_percentage)
  //calculate depreciaction months
  c._inv_depreciations=cal_inv_depreciations(years, c.inv_year, c._inv_depreciation_months, c.inv_month)
  //calculate accumulated depreciation
  c._inv_acumulated_depreciation=cal_inv_acumulated_depreciation(years, c.inv_year, c._inv_depreciation_months, c._inv_depreciations, c._inv_monthly_depreciation)
  //calculate depreciation
  c._inv_depreciation=cal_inv_depreciation(years,c._inv_acumulated_depreciation)
  //calculate net value
  c._inv_net_value=subtract(c._inv_gross_value,c._inv_acumulated_depreciation)
  //calculate vat value
  c._inv_vat=dotMultiply(0.01, multiply(c._inv_net_acquisition,vatSelector(settings,c.inv_vat_rate)))
  //calculate receivable
  c._inv_receivable=add(
    c._inv_vat,
    c._inv_net_acquisition
  )

  return c
};

function calInvestmentIteration (investment, settings){
  let new_stream=[]
  for(var i=0;i<investment.length;i++){
    new_stream[i]=calculateInvestment(investment[i],settings.number_of_years+settings.number_of_historic_years, settings)
  }
  return new_stream
}

export{
  calculateInvestment,
  calInvestmentIteration
};