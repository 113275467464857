

const Session={
  //Token and Logging
  setToken(token){
    sessionStorage.setItem('token',token);
    sessionStorage.setItem('last_used_timmer', Date.now())
  },
  unsetToken(){
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('last_used_timmer');
  },
  getToken(){
    return sessionStorage.getItem('token');
  },

  checkTokenAndLoggedTimmer(){
    const token=sessionStorage.getItem('token');
    if(!token ){
      return false
    }
    else {
      return true
    }
  },

  //USER_TYPE
  setUserType(usertype){
    sessionStorage.setItem('user_type',usertype)
  },
  getUserType(){
    if(sessionStorage.getItem('user_type')===undefined){
      return 'General'
    } else {
      return sessionStorage.getItem('user_type')
    }
  },

  //AVATAR
  setAvatar(avatar){
    sessionStorage.setItem('avatar',avatar)
  },
  getAvatar(){
    return sessionStorage.getItem('avatar')
  },

  //locale
  setLocale(inserter){
    if(inserter=="en" || inserter=="pt"){
      sessionStorage.setItem('user_locale',inserter)
    }
  },
  getLocale(){
    let response=sessionStorage.getItem('user_locale')
    if(!response){
      response="en"
    }
    return response
  },

  //History  
  setHistory(inserter){
    sessionStorage.setItem('location',inserter);
  },

  //TEMPLATE
  getFirstTemplate(){
    var url=(window.location.href).toLowerCase()
    if(url.includes("viseeon-plan")){
      return 'viseeon'
    } else if(url.includes("bazaar")){
      return 'bazaar'
    } else{ 
      return 'casflo'}
  },
  setTemplate(template){
    var url=(window.location.href).toLowerCase()
    if(url.includes("viseeon-plan")){
      return 'viseeon'
    } else if(template==='viseeon'){
      return 'viseeon'
    } else if(url.includes("bazaar")){
      return 'bazaar'
    } else{ 
      return 'casflo'}
  },
  getTemplate(){
  },
  checkUrl(old_t){
    let new_t
    var url=(window.location.href).toLowerCase()
    if(url.includes("viseeon-plan")){
      new_t= 'viseeon'
    } else{ 
      new_t='casflo'
    }
    if(new_t===old_t){
      return({template:new_t, reload:false})
    } else {
      return ({template:new_t, reload:true})
    }
  },

//Navigation storage
  setBusinessPlanProjectId(inserter){
    sessionStorage.setItem('project',inserter);
  },
  getBusinessPlanProjectId(){
    return sessionStorage.getItem('project');
  }, 
  //SET VERSION ID AND NAME
  setVersion(inserter1, inserter2){
    sessionStorage.setItem('version_id',inserter1);
    sessionStorage.setItem('version_name',inserter2);
  },
  getVersionId(){
    return sessionStorage.getItem('version_id');
  },
  getVersionName(){
    return sessionStorage.getItem('version_name');
  },
  //SET ELEMENT FOR THE FEASIBILITY NAVIGATOR
  setVersionRender(inserter){
    sessionStorage.setItem('version_render',inserter);
  },
  getVersionRender(){
    let version_render= sessionStorage.getItem('version_render');
    if(version_render===undefined){version_render="version"}
    return version_render
  },
  setItem(inserter){
    sessionStorage.setItem('Item',inserter);
  },
  getItem(){
    return sessionStorage.getItem('Item');
  },
   //SET ELEMENT FOR THE CANVa NAVIGATOR
  setCanvaRender(inserter){
    sessionStorage.setItem('canva_render',inserter);
  },
  getCanvaRender(){
    return sessionStorage.getItem('canva_render');
  },

  ////BUDGET
  //Navigation storage
  setBudget(inserter){
    sessionStorage.setItem('budget',inserter);
  },
  getBudget(){
    return sessionStorage.getItem('budget');
  }, 
}

export default Session