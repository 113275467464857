import React from 'react';
import { ToggleButtonGroup,ToggleButton } from 'react-bootstrap';
import {AssumptionHeaderSaveReturn} from 'PRIVATE/PROJECT/Feasibility/feasibility_items';
import { Row, Col } from 'Support/grid';
import { SingleInputFormFlat,SingleInputNumberForm, PeriodHeader,NonEditableForm, Observations, Dropdown } from 'Support/inputforms'
import ApiConnect from 'CONFIG/axios';
import UserContext from 'CONFIG/auth';
import Loader from 'Support/loader';
import {calculateInvestment} from 'CALCULATIONS/cal_investment';
import { ToogleButtons } from 'Support/toogle_buttons';

class InvestmentEntry extends React.Component {
  static contextType = UserContext

  constructor(props) {
    super(props);
    this.state = {
      data: "",
      loaded:false,
      years:this.props.feasibility_settings.historic_years.concat(this.props.feasibility_settings.years)
    }
    this.handleEditChange = this.handleEditChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleSubsidyChange = this.handleSubsidyChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleObservationsChange = this.handleObservationsChange.bind(this);
  }

  componentDidMount= async()=>{
    const request={
    project_id:this.props.project_id,
    version_id:this.props.version_id,
    user_id:this.props.user_id,
    item_id:this.props.active_item,
    item_type:"investments"
    }
    try {
      const response = await ApiConnect.post('/api/multitem/unique/get', request)
        this.setState({
          data:response.data.item,
          loaded:true
        })
    } catch(error) {
        alert(error);
    }
  }

  handleEditChange=async(event)=>{
    let number_of_years=(this.props.feasibility_settings.number_of_years)
    if(this.props.feasibility_settings.use_historic===true){
      number_of_years+=this.props.feasibility_settings.number_of_historic_years
    }
    const name = event.target.name;
    const value = event.target.value;
    let go = this.state.data;
    go[name]=value;
    calculateInvestment(go, number_of_years, this.props.feasibility_settings)
    this.setState({data:go})
  }

  handleNameChange(event){
    const value = event.target.value;
    let go = this.state.data;
    go.inv_item_name=value;
    this.setState({data:go })
  }

  handleSubsidyChange(event){
    let val= event.target.value
    if(val=="false") {val=false} else {val=true}
    let number_of_years=(this.props.feasibility_settings.number_of_years)
    if(this.props.feasibility_settings.use_historic===true){
      number_of_years+=this.props.feasibility_settings.number_of_historic_years
    }
    let go = this.state.data;
    go.inv_subsidy_option=val;
    calculateInvestment(go, number_of_years, this.props.feasibility_settings)
    this.setState({data:go })
  }

  handleObservationsChange(event){
    const value = event.target.value;
    let go = this.state.data;
    go.inv_obs=value;
    this.setState({data:go })
  }

  async handleSave(){
    const request={
      user_id:this.props.user_id,
      project_id:this.props.project_id,
      version_id:this.props.version_id,
      item_id:this.props.active_item,
      item_type:"investments",
      content:this.state.data
    }
    try{
      const response = await ApiConnect.post('/api/multitem/item/modify', request)
      this.props.changeScreenView("list",0)
      this.props.handleLists.handleSaveUpdater(response.data,'investments')
    } catch(error) {
        alert(error);
    }
  }

  render() {
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_PROJECT.PROJECT_FEASIBILITY.Individual_edits')

    const props ={
      trsl:trsl,
      handleScreenNavigation:this.props.handleScreenNavigation,
      handleEditChange:this.handleEditChange,
      handleSubsidyChange:this.handleSubsidyChange,
      handleSave:this.handleSave,
      handleNameChange:this.handleNameChange,
      handlePeriodChange:this.handlePeriodChange,
      handleObservationsChange:this.handleObservationsChange,
      handleEqualizer:this.handleEqualizer,
      data:this.state.data,
      years:this.state.years,
      feasibility_settings:this.props.feasibility_settings,
      user_permission:this.props.user_permission
  }

    function torender(data, i){
      return data[i];
    }  
    const pages ={
      true: <ToDisplay {...props}/>,
      false: <Loader loader_message="inv_edit"/>
    }
    var i = this.state.loaded
    const renderizing = torender(pages,i)

    return(
      <div>
        {renderizing}
      </div>
    )
  }
}

class ToDisplay extends React.Component {
  render(){
    const props=this.props

    const showSubsidizedResults =(input)=>{
      if(input===true){
        return <AcquisitionWithSubsidy {...props}/>
      }
    }
    const subsidy_results_renderizing=showSubsidizedResults(this.props.data.inv_subsidy_option)

    return(
      <>
        <Header {...props} />
        <Row>
          <Col xs={12} md={12}>
            <InvestmentEdit {...props}/>
          </Col>
          <Col xs={12} md={12}>
          <Subsidy {...props}/>
          </Col>
        </Row>
        <Row>
          <Col xs={12} >
            <PeriodHeader label={this.props.trsl['year']} periods={this.props.years} />
          </Col>
          <Col xs={12}>
            <AcquisitionValue {...props}/>
            {subsidy_results_renderizing}
            <GrossValue {...props}/>
            <Depreciation {...props}/>
            <AccumulatedDepreciation {...props} />
            <NetValue {...props}/>
          </Col>
          <Col xs={12} className="feasibility_list">
            <Observations className="feasibility_item_list" name="inv_obs" value={this.props.data.op_exp_obs} label="Observations" handleObservationsChange={this.props.handleObservationsChange}/>
          </Col>
        </Row>
      </>
    );
  }
}

class Header extends React.Component{
  render(){
    return(
      <>
        <AssumptionHeaderSaveReturn title={this.props.trsl['investment_header']} user_permission={this.props.user_permission} back={this.props.trsl['back_button']} save={this.props.trsl['save_back_button']} handleSave={this.props.handleSave} handleScreenNavigation={this.props.handleScreenNavigation}/>
        <Row className="feasibility_edit_globalheader_row">
          <SingleInputFormFlat xs={12} md={12} value={this.props.data.inv_item_name} label= {this.props.trsl['investment_name']} handleEditItemChange={this.props.handleNameChange}/>
        </Row>
      </>
    );
  }
}

class InvestmentEdit extends React.Component{
  render(){
    const vatRendering=(input)=>{
      if(input===false){
        return(
          <Col xs={6} md={2}>
            <SingleInputNumberForm label={this.props.trsl['vat_rate']} name="inv_vat_rate" value={this.props.data.inv_vat_rate} handleinputeditChange={this.props.handleEditChange} unit='%'/>
          </Col>
        )
      }
    }
    let renderizing=""
    renderizing=vatRendering(this.props.feasibility_settings.use_general_vat_rate)

    let item_holder= this.props.feasibility_settings.years
    if(this.props.feasibility_settings.use_historic===true){
      item_holder=this.props.feasibility_settings.historic_years.concat(this.props.feasibility_settings.years)
    }
    const item_distr = item_holder.map((value, key) =>{
      if(key<this.props.feasibility_settings.number_of_historic_years){
        return {disabled:true,value:key, option_label:value}
      } else {
        return {value:key, option_label:value}
      }
    })

    const options_months=[
      {value: 0, option_label: this.props.trsl['month_jan']},
      {value: 1, option_label: this.props.trsl['month_feb']},
      {value: 2, option_label: this.props.trsl['month_mar']},
      {value: 3, option_label: this.props.trsl['month_apr']},
      {value: 4, option_label: this.props.trsl['month_may']},
      {value: 5, option_label: this.props.trsl['month_jun']},
      {value: 6, option_label: this.props.trsl['month_jul']},
      {value: 7, option_label: this.props.trsl['month_aug']},
      {value: 8, option_label: this.props.trsl['month_sep']},
      {value: 9, option_label: this.props.trsl['month_oct']},
      {value: 10, option_label:this.props.trsl['month_nov']},
      {value: 11, option_label:this.props.trsl['month_dec']}
    ]
    const options_type=[
      {value:"Tangible",option_label:this.props.trsl['investment_tangible']},
      {value:"Intangible",option_label:this.props.trsl['investment_intangible']}
    ]
    const options_depreciation=[
      {value:1/1, option_label:this.props.trsl['investment_depreciaton_rate_1']},
      {value:1/2, option_label:this.props.trsl['investment_depreciaton_rate_2']},
      {value:1/3, option_label:this.props.trsl['investment_depreciaton_rate_3']},
      {value:1/4, option_label:this.props.trsl['investment_depreciaton_rate_4']},
      {value:1/5, option_label:this.props.trsl['investment_depreciaton_rate_5']},
      {value:1/6, option_label:this.props.trsl['investment_depreciaton_rate_6']},
      {value:1/8, option_label:this.props.trsl['investment_depreciaton_rate_8']},
      {value:1/10, option_label:this.props.trsl['investment_depreciaton_rate_10']},
      {value:1/12, option_label:this.props.trsl['investment_depreciaton_rate_12']},
      {value:1/15, option_label:this.props.trsl['investment_depreciaton_rate_15']},
      {value:1/20, option_label:this.props.trsl['investment_depreciaton_rate_20']},
      {value:1/25, option_label:this.props.trsl['investment_depreciaton_rate_25']},
      {value:1/50, option_label:this.props.trsl['investment_depreciaton_rate_50']},
      {value:0, option_label:this.props.trsl['investment_depreciaton_rate_0']}
    ]
    return(
      <Row >
        <Col xs={12}>
          <h5>{this.props.trsl['investment_acquisition']}</h5>
        </Col>
        <Dropdown xs={6} md={2} label={this.props.trsl['investment_year']} name="inv_year" value={this.props.data.inv_year} options={item_distr} onChange={this.props.handleEditChange} />
        <Dropdown xs={6} md={2} label={this.props.trsl['investment_month']} name="inv_month" value={this.props.data.inv_month} options={options_months} onChange={this.props.handleEditChange} />
        <SingleInputNumberForm xs={6} md={2}  label={this.props.trsl['investment_quantity']} name="inv_quantity" value={this.props.data.inv_quantity} handleinputeditChange={this.props.handleEditChange} unit='U'/>
        <SingleInputNumberForm xs={6} md={2} label={this.props.trsl['investment_price']} name="inv_price" value={this.props.data.inv_price} handleinputeditChange={this.props.handleEditChange} unit={new Intl.NumberFormat('ja-JP', { style: 'currency', currency: this.props.feasibility_settings.currency}).format(0).replace(/\d./g,"")} />
        <Dropdown xs={6} md={2} label={this.props.trsl['investment_asset']} name="inv_type" value={this.props.data.inv_type} options={options_type} onChange={this.props.handleEditChange} />
        <Dropdown xs={6} md={2} label={this.props.trsl['investment_depreciaton_rate']} name="inv_depreciation_rate" value={this.props.data.inv_depreciation_rate} options={options_depreciation} onChange={this.props.handleEditChange} />  

      {renderizing}
    </Row>
    );
  }
}

class Subsidy extends React.Component{
  render(){
    const props=this.props

    function torender(data){
      if(data!==true){
        return ""
      }  else{
        return <SubsidyValue {...props}/>
      }
    }
    const renderizing = torender(this.props.data.inv_subsidy_option)

    const toogle_data=[
      {value:false,option_label:this.props.trsl['no_subsidy']},
      {value:true,option_label:this.props.trsl['non_reembursable']}
    ]
    return(
      <Row>
        <Col xs={12}>
          <h5>{this.props.trsl['investment_subsidy']}</h5>
        </Col>
        <ToogleButtons xs={12} toogle_data={toogle_data} label={this.props.trsl['investment_subsidy_question']} selected={this.props.data.inv_subsidy_option} handleToogleButton={this.props.handleSubsidyChange}/>
        {renderizing}
      </Row>  
    )
  }
}

class SubsidyValue extends React.Component{
  render(){
    return(
        <SingleInputNumberForm 
        xs={12} md={4}
        label={this.props.trsl['investment_subsidy_percentage']}
        name="inv_subsidy_percentage"
        value={this.props.data.inv_subsidy_percentage}
        handleinputeditChange={this.props.handleEditChange}
        unit='%'
        />
    )
  }
}

class AcquisitionValue extends React.Component{
  render(){ 
    return(
      <NonEditableForm
      item_holder={this.props.data._inv_acquisition} 
      s_value={this.props.s_value} 
      label={this.props.trsl['investment_acquisition_value']}
      value_type="currency"
      currency={this.props.feasibility_settings.currency}
      />
    );
  }
}

class GrossValue extends React.Component{
  render(){ 
    return(
      <NonEditableForm
      item_holder={this.props.data._inv_gross_value} 
      s_value={this.props.s_value} 
      label={this.props.trsl['investment_gross_value']}
      value_type="currency"
      currency={this.props.feasibility_settings.currency}
      />
    );
  }
}

class AcquisitionWithSubsidy extends React.Component{
  render(){ 
    return(
      <NonEditableForm
      item_holder={this.props.data._inv_subsidy_comparticipation} 
      s_value={this.props.s_value} 
      label={this.props.trsl['investment_subsidy_comparticipation']}
      value_type="currency"
      currency={this.props.feasibility_settings.currency}
      />
    );
  }
}

class Depreciation extends React.Component{
  render(){ 
    return(
      <NonEditableForm
      item_holder={this.props.data._inv_depreciation} 
      s_value={this.props.s_value} 
      label={this.props.trsl['investment_depreciaton']}
      value_type="currency"
      currency={this.props.feasibility_settings.currency}
      />
    );
  }
}

class AccumulatedDepreciation extends React.Component{
  render(){ 
    return(
      <NonEditableForm
      item_holder={this.props.data._inv_acumulated_depreciation} 
      s_value={this.props.s_value} 
      label={this.props.trsl['investment_depreciaton_accumulated']}
      value_type="currency"
      currency={this.props.feasibility_settings.currency}
      />
    );
  }
}

class NetValue extends React.Component{
  render(){ 
    return(
      <NonEditableForm
      item_holder={this.props.data._inv_net_value} 
      s_value={this.props.s_value} 
      label={this.props.trsl['investment_net']}
      value_type="currency"
      currency={this.props.feasibility_settings.currency}
      />
    );
  }
}

export default InvestmentEntry;