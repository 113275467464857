import React from 'react';
import { Row, Col  } from 'Support/grid';

import UserContext from 'CONFIG/auth';
import ApiConnect from 'CONFIG/axios';
import Loader from 'Support/loader';
import { DeleteButton, AddButton, RegularButtonSmall } from 'Support/listbuttons';
import { DetailedCard } from 'Support/warning_cards';
import { SingleInputLargeForm } from 'Support/inputforms';


class BMCMain extends React.Component{
  static contextType = UserContext
  
  constructor(props) {
    super(props);
      this.state = {
      data: "",
      canva_id:"",
      active_bmc:"value_proposition",
      bmc_view:true
    }
    this.handleAddItem=this.handleAddItem.bind(this)
    this.handleDelete=this.handleDelete.bind(this)
    this.handleEditItem=this.handleEditItem.bind(this)
    this.handleSave=this.handleSave.bind(this)
    this.handleChangeBMCElement=this.handleChangeBMCElement.bind(this)
    this.handleChangeBMCView=this.handleChangeBMCView.bind(this)
  }

  componentDidMount(){
    this.updataData()
  }

  updataData=async()=>{
    const request={
      project_id: this.props.project_id,
      item_type: "bmc" 
    }
    try{
      const response = await ApiConnect.post('/api/project/canva/get', request)
      this.setState({
        data:response.data.item,
        canva_id:response.data.canva_id,
        loaded:true
      })
    }catch(error) {
      alert(error);
    }
  }

  handleAddItem(event){
    const value=event.target.value
    let go= this.state.data
    go[value].push("")
    this.setState({
      data:go
    })
  }

  handleDelete(event){
    let go= this.state.data
    go[event.target.name].splice(event.target.value,1)
    this.setState({
      data:go
    })
  }

  handleEditItem(event){
    let go= this.state.data
    go[event.target.name][event.target.id]=event.target.value
    this.setState({
      data:go
    })
  }

  handleSave=async()=>{
    const request={
      canva_id: this.state.canva_id,
      item_type: "bmc",
      content:this.state.data 
    }
    try{
      const response = await ApiConnect.post('/api/project/canva/modify', request)
      this.props.activateAlert('ok_auto','Saved','', this.context)
    }catch(error) {
      alert(error);
    }
  }

  handleChangeBMCElement(event){
    this.setState({
      active_bmc:event.target.value
    })
  }

  handleChangeBMCView(){
    let go=this.state.bmc_view
    go=!go
    this.setState({
      bmc_view:go
    })
  }

  render(){
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_PROJECT.PROJECT_CANVA.BMC')

    const props ={
      trsl:trsl,
      data:this.state.data,
      active_bmc:this.state.active_bmc,
      bmc_view:this.state.bmc_view,
      template:user_c.template,
      user_permission:this.props.user_permission,
      p_name:this.props.project_data.project_name,
      handleAddItem:this.handleAddItem,
      handleDelete:this.handleDelete,
      handleEditItem:this.handleEditItem,
      handleSave:this.handleSave,
      handleChangeBMCElement:this.handleChangeBMCElement,
      handleChangeBMCView:this.handleChangeBMCView
    }
    function torender(data, i){
      return data[i];
      }  
    const pages ={
      true: <ToDisplay {...props} />,
      false: <Loader loader_message="Let's get hands on the job!"/>
    }
    var i = this.state.loaded
    const renderizing = torender(pages,i)

    return(
      <>
        {renderizing}
      </>
    )
  }
}


class ToDisplay extends React.Component{
  render(){
    const props=this.props

    let renderizing
    if(this.props.bmc_view===true){
      renderizing=<BMCEdit {...props} />
    } else{
      renderizing=<BMCShow {...props} />
    }
    return(
      <>
      {renderizing}
      </>
    )
  }
}

class BMCEdit extends React.Component{
  render(){
    const props=this.props

    let permission=true
    if(this.props.user_permission=="owner" || this.props.user_permission=="edit" ){
      permission= false
    }

    return(
      <>
        <Header {...props} />
        <Row className="canva_bmc_2row">
          <Col xs={9}><h4>{this.props.trsl['pick_option']}</h4></Col>
          <Col xs={3}>
          <RegularButtonSmall bk="bk_success" onClick={this.props.handleSave} disabled={permission} label={this.props.trsl['save_button']}/>
        </Col>
          <Col sm={12} lg={12}>
            <BMCOptions {...props}/>
          </Col>
          <Col sm={12} lg={12}>
            <BMCFields {...props}/>
          </Col>
        </Row>
      </>
    );
  }
}

class Header extends React.Component{
  render(){
    let button_label=this.props.trsl['full_view']
    if(this.props.bmc_view===false){
      button_label=this.props.trsl['edit_view']
    }
    return(
      <Row middle="xs" className="canva_header_row">
        <Col xs={9}>
          <h4>{this.props.trsl['bmc']}</h4>
        </Col>
        <Col xs={3}>
          <RegularButtonSmall bk="bk_primary" onClick={this.props.handleChangeBMCView} label={button_label}/>
        </Col>
      </Row>
  )
  }
}

class BMCOptions extends React.Component{
  render(){
    const props=this.props
    return(
      <div className="canva_bmc_options_row">
        <div className="canva_bmc_button_20">
          <BMCButton classer="canva_bmc_key" button_namer="key_partners" {...props}/>
        </div>
        <div className="canva_bmc_button_20">
          <BMCButton classer="canva_bmc_key" button_namer="key_resource" {...props}/>
          <BMCButton classer="canva_bmc_key" button_namer="key_activities" {...props}/>
        </div>
        <div className="canva_bmc_button_20">
          <BMCButton classer="canva_bmc_value" button_namer="value_proposition" {...props}/>
        </div>
        <div className="canva_bmc_button_20">
          <BMCButton classer="canva_bmc_client"button_namer="client_relationship" {...props}/>
          <BMCButton classer="canva_bmc_client"button_namer="client_channels" {...props}/>         
        </div>
        <div className="canva_bmc_button_20">
          <BMCButton classer="canva_bmc_client"button_namer="client_segment" {...props}/>
        </div>
        <div className="canva_bmc_button_100">
          <BMCButton classer="canva_bmc_finance"button_namer="costs" {...props}/>
        </div>
        <div className="canva_bmc_button_100">
          <BMCButton classer="canva_bmc_finance"button_namer="revenues" {...props}/>
        </div>
      </div> 
    )
  }
}

class BMCFields extends React.Component{
  render(){
    const item_holder=this.props.data[this.props.active_bmc]
    let item_distr=this.props.trsl['not_added']
    if(item_holder!==undefined && item_holder.length>0){
      item_distr=item_holder.map((value,key)=>
        <CanvasForm 
        element={this.props.active_bmc} 
        item={key} 
        value={value}
        handleDelete={this.props.handleDelete} 
        handleEditItem={this.props.handleEditItem}/>
      )
    }

    return(
      <>
        <Row className='blanktop_15'>
          <Col xs={10}>
            <h5>{this.props.trsl[this.props.active_bmc]}</h5>
          </Col>
          <Col xs={2} className="canva_align_right">
            <AddButton value={this.props.active_bmc} handleAddItem={this.props.handleAddItem} />
          </Col>
          {item_distr}
        </Row>
      </>
    );
  }
}

function CanvasForm (props){
  const mutate=props.item.toString()
  return(
    <Col xs={6}>
      <DetailedCard color="white">
        <DetailedCard.Header color="lightaccent">
          <Row className="grid_middle">
            <Col xs={1}>
              <h5>{props.item+1}</h5>
            </Col>
            <Col xs={9}></Col>
            <Col xs={2} className="canva_align_right">
              <DeleteButton name={props.element} value={props.item} handleDelete={props.handleDelete}/>
            </Col>
          </Row> 
        </DetailedCard.Header>
        <DetailedCard.Body>
          <SingleInputLargeForm label="Insert text" id={mutate} name={props.element} value={props.value} handleEditItemChange={props.handleEditItem} />
        </DetailedCard.Body>
      </DetailedCard>
    </Col>
  )
}

function BMCButton (props){
  
  let classic="";
  if(props.active_bmc==props.button_namer){
    classic="_active"
  }
  return(
    <button className={`${props.classer + classic} canva_bmc_buttons`} value={props.button_namer} onClick={props.handleChangeBMCElement}>
      {props.trsl[props.button_namer]}
    </button>
  )
}

class BMCShow extends React.Component{
  render(){
    const props=this.props

    return(
      <div>
        <Header {...props} />
        <div className="canva_bmc_options_row canva_bmc_2row">
          <div className="canva_bmc_box_20">
            <BMCBoxes classer="canva_bmc_key" namer="key_partners" {...props}/>
          </div>
          <div className="canva_bmc_box_20">
            <BMCBoxes classer="canva_bmc_key" namer="key_resource" {...props}/>
            <BMCBoxes classer="canva_bmc_key" namer="key_activities" {...props}/>
          </div>
          <div className="canva_bmc_box_20">
            <BMCBoxes classer="canva_bmc_value" namer="value_proposition" {...props}/>
          </div>
          <div className="canva_bmc_box_20">
            <BMCBoxes classer="canva_bmc_client"namer="client_relationship" {...props}/>
            <BMCBoxes classer="canva_bmc_client" namer="client_channels" {...props}/>         
          </div>
          <div className="canva_bmc_box_20">
            <BMCBoxes classer="canva_bmc_client" namer="client_segment" {...props}/>
          </div>
          <div className="canva_bmc_box_50">
            <BMCBoxes classer="canva_bmc_finance" namer="costs" {...props}/>
          </div>
          <div className="canva_bmc_box_50">
            <BMCBoxes classer="canva_bmc_finance" namer="revenues" {...props}/>
          </div>
        </div>
      </div>
    );
  }
}

class BMCBoxes extends React.Component{
  render(){
    const item_holder=this.props.data[this.props.namer]
    let item_distr=""
    if(item_holder!==undefined && item_holder.length>0){
      item_distr=item_holder.map((value,key)=>
        <div className="canva_bmc_box_list"> 
        -{value}
        </div>
      )
    }
    return(
      <div className={`${this.props.classer} canva_bmc_box`}>
        <h4>{this.props.trsl[this.props.namer]}</h4>
        {item_distr}
      </div>
    )
  }
}

export default BMCMain;