import {add, dotMultiply,dotDivide} from 'mathjs';

//check if about individual or group and provide number of owrkers


const cal_basis=(c,settings)=>{
  let bug=new Array(c.hr_gross_salary.length).fill(1)
    bug=(settings.working_months/12)
  return bug
}

//calculate gross salary
const cal_gross_salary =(c, basis)=>{
  if(c.hr_type===false){
    return dotMultiply(dotMultiply(c._hr_gross_salary_inflated,c.hr_number_of_workers),basis)
  } else{
    return dotMultiply(c._hr_gross_salary_inflated,basis)
  }
} 

const cal__prizes=(c, basis,inflation)=>{
  if(c.hr_type===false){
    return dotMultiply(dotMultiply(c.hr_prizes_bonus,inflation),c.hr_number_of_workers)
  } else{
    return dotMultiply(c.hr_prizes_bonus,inflation)
  }
}

const cal_no_social_security=(c, basis,inflation)=>{
  if(c.hr_type===false){
  return dotMultiply(dotMultiply(c.hr_subsidy,inflation),c.hr_number_of_workers)
  } else{
    return dotMultiply(c.hr_subsidy,inflation)
  }
}

const cal_hr_total_cost=(c,settings)=>{
  let rate=add(1,dotMultiply(settings.corporate_social_security,0.01))
  let i = dotMultiply(add(c._hr_gross_salary,c._hr_prizes_bonus),rate)
  return add(i, c._hr_subsidy)
}

  
const calculateHumanResources= (c,settings,inflation)=>{
  let bas=cal_basis(c,settings)
  c._hr_gross_salary_inflated=dotMultiply(c.hr_gross_salary,inflation)
  c._hr_gross_salary=cal_gross_salary(c, bas) 
  c._hr_prizes_bonus= cal__prizes(c, bas, inflation)
  c._hr_subsidy = cal_no_social_security(c, bas, inflation)
  c._hr_total_cost=cal_hr_total_cost(c, settings)
  return c
};

function calRhIterate(hr,feasibility_settings,version_inflation){
  let new_stream=[]
  for(var i=0;i<hr.length;i++){
    new_stream[i]=calculateHumanResources(hr[i],feasibility_settings,version_inflation)
  }
  return new_stream
}

function calRhIterateSpecial(hr,feasibility_settings,version_inflation){
  let new_stream=[]
  for(var i=0;i<hr.length;i++){
    hr[i].hr_gross_salary=dotDivide(hr[i].hr_gross_salary,1+(feasibility_settings.corporate_social_security/100))
    new_stream[i]=calculateHumanResources(hr[i],feasibility_settings,version_inflation)
  }
  return new_stream
}

export {
  calRhIterate,
  calculateHumanResources,
  calRhIterateSpecial
}