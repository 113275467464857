import React from 'react';
import {  Row, Col} from 'Support/grid';
import { BarGraph, MultipleLineGraphs, dataTransformForGrouping,dataTransform } from 'Support/graphs';
import { Card } from 'Support/warning_cards';
import { ToogleMultipleButtons } from 'Support/toogle_buttons';

const bk='lightaccent'
const graph_xs_size=6

class FeasibilityCompareAll extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      version_selector:this.props.data.map((value,key) => true),
      usable_data:this.props.data
    }
    this.handleActiveVersion=this.handleActiveVersion.bind(this)
  }

  handleActiveVersion(event){
    function selector(value,index){
      return(go[index])
    }
    let go= this.state.version_selector
    go[event.target.value]=!go[event.target.value]
    const go_bug=this.props.data.filter(selector)
    this.setState({
      version_selector:go,
      usable_data:go_bug
    })
  }

  render(){
    const props={
      trsl:this.props.trsl,
      data:this.state.usable_data,
      years:this.props.feasibility_settings.historic_years.concat(this.props.feasibility_settings.years),
      stackpalette:this.props.stackpalette
    }

    const item_holder=this.props.data

    const item_distr = item_holder.map((value,key)=>
      {
        return ({option_label:value.version_name, value:key})
      }
    )
    let selected =[]
    this.state.version_selector.map((el,key) => {
      if(el===true) selected.push(key) 
    })

    let discounted_render= ""

    if(this.props.feasibility_settings.financial_levered_project===true){
      discounted_render =
      <>
        <Col xs={graph_xs_size}>
          <DiscountedFCFFGraph {...props} />
        </Col>
        <Col xs={graph_xs_size}>
          <AccumulatedDiscountedFCFFGraph {...props} />
        </Col>
        <Col xs={graph_xs_size}>
          <NPVGraph {...props} />
        </Col>
        <Col xs={graph_xs_size}>
          <IRRGraph {...props} />
        </Col>
      </>
    }

    return(
      <>
        <Row className="feasiblity_dashboard_graphs_row">
          <Col xs={12}>
            <h4>{this.props.trsl['all_versions']}</h4>
          </Col>
          <Col xs={12}>
            <Row>          
              <ToogleMultipleButtons xs={12} label={this.props.trsl['select_scenarios']} toogle_data={item_distr} selected={selected} handleToogleButton={this.handleActiveVersion}/>
            </Row>
          </Col>
        </Row>
        <Row className="feasiblity_dashboard_graphs_row">
          <Col xs={12}>
            <h4>{this.props.trsl['visuals']}</h4>
          </Col>
          <Col xs={graph_xs_size}>
            <RevenueGraph {...props} />
          </Col>
          <Col xs={graph_xs_size}>
            <EBITDAGraph {...props} />
          </Col>
          <Col xs={graph_xs_size}>
            <NetIncomeGraph {...props} />
          </Col>
          <Col xs={graph_xs_size}>
            <COGsGraph {...props} />
          </Col>
          <Col xs={graph_xs_size}>
            <OperatingExpensesGraph {...props} />
          </Col>
          <Col xs={graph_xs_size}>
            <EmployeesGraph {...props} />
          </Col>
          <Col xs={graph_xs_size}>
            <TotalAssetsGraph {...props} />
          </Col>
          <Col xs={graph_xs_size}>
            <TotalEquityGraph {...props} />
          </Col>
          <Col xs={graph_xs_size}>
            <TotalLiabilitiesGraph {...props} />
          </Col>
          <Col xs={graph_xs_size}>
            <FCFFGraph {...props} />
          </Col>
          {discounted_render}
        </Row>
      </>
    );
  }
}

function RevenueGraph(props){
  let x_input=extractDataFromVersionForLines(props.data,'_inc_st','revenue')
  let legend = extractVersionName(props.data)
  return(
    <Card color={bk} header={props.trsl['revenues']} body={<MultipleLineGraphs data={dataTransformForGrouping(x_input, props.years, legend, props.stackpalette)} />} />
  );
}

function EBITDAGraph (props){
  let x_input=extractDataFromVersionForLines(props.data,'_inc_st','ebitda')
  let legend = extractVersionName(props.data)
  return(
    <Card color={bk} header={props.trsl['ebitda']} body={<MultipleLineGraphs data={dataTransformForGrouping(x_input, props.years, legend, props.stackpalette)} />} />
  );
}

function NetIncomeGraph(props){
  let x_input=extractDataFromVersionForLines(props.data,'_inc_st','net_profit')
  let legend = extractVersionName(props.data)
  return(
    <Card color={bk} header={props.trsl['net_income']} body={<MultipleLineGraphs data={dataTransformForGrouping(x_input, props.years, legend, props.stackpalette)} />} />
  );
}

function COGsGraph(props){
  let x_input=extractDataFromVersionForLines(props.data,'_inc_st','net_profit')
  let legend = extractVersionName(props.data)
  return(
    <Card color={bk} header={props.trsl['cogs']} body={<MultipleLineGraphs data={dataTransformForGrouping(x_input, props.years, legend, props.stackpalette)} />} />
  );
}

function OperatingExpensesGraph(props){
  let x_input=extractDataFromVersionForLines(props.data,'_inc_st','operating_expenses')
  let legend = extractVersionName(props.data)
  return(
    <Card color={bk} header={props.trsl['operating_exp']} body={<MultipleLineGraphs data={dataTransformForGrouping(x_input, props.years, legend, props.stackpalette)} />} />
  );
}

function EmployeesGraph(props){
  let x_input=extractDataFromVersionForLines(props.data,'_inc_st','hr')
  let legend = extractVersionName(props.data)
  return(
    <Card color={bk} header={props.trsl['employees']} body={<MultipleLineGraphs data={dataTransformForGrouping(x_input, props.years, legend, props.stackpalette)} />} />
  );
}

function TotalAssetsGraph(props){
  let x_input=extractDataFromVersionForLines(props.data,'_bal','total_assets')
  let legend = extractVersionName(props.data)
  return(
    <Card color={bk} header={props.trsl['assets']} body={<MultipleLineGraphs data={dataTransformForGrouping(x_input, props.years, legend, props.stackpalette)} />} />
  );
}

function TotalEquityGraph(props){
  let x_input=extractDataFromVersionForLines(props.data,'_bal','total_equity')
  let legend = extractVersionName(props.data)
  return(
    <Card color={bk} header={props.trsl['equity']} body={<MultipleLineGraphs data={dataTransformForGrouping(x_input, props.years, legend, props.stackpalette)} />} />
  );
}

function TotalLiabilitiesGraph(props){
  let x_input=extractDataFromVersionForLines(props.data,'_bal','total_liabilities')
  let legend = extractVersionName(props.data)
  return(
    <Card color={bk} header={props.trsl['liabilities']} body={<MultipleLineGraphs data={dataTransformForGrouping(x_input, props.years, legend, props.stackpalette)} />} />
  );
}

function FCFFGraph(props){
  let x_input=extractDataFromVersionForLines(props.data,'fe','fcff')
  let legend = extractVersionName(props.data)
  return(
    <Card color={bk} header={props.trsl['fcff']} body={<MultipleLineGraphs data={dataTransformForGrouping(x_input, props.years, legend, props.stackpalette)} />} />
  );
}

function DiscountedFCFFGraph(props){
  let x_input=extractDataFromVersionForLines(props.data,'_lep','fcff_discounted')
  let legend = extractVersionName(props.data)
  return(
    <Card color={bk} header={props.trsl['discounted_fcff']} body={<MultipleLineGraphs data={dataTransformForGrouping(x_input, props.years, legend, props.stackpalette)} />} />
  );
}

function AccumulatedDiscountedFCFFGraph(props){
  let x_input=extractDataFromVersionForLines(props.data,'_lep','fcff_discounted_accumulated')
  let legend = extractVersionName(props.data)
  return(
    <Card color={bk} header={props.trsl['accumulated_discounted_fcff']} body={<MultipleLineGraphs data={dataTransformForGrouping(x_input, props.years, legend, props.stackpalette)} />} />
  );
}

function NPVGraph(props){
  let x_input=extractDataFromVersionForLines(props.data,'_lep','npv')
  let legend = extractVersionName(props.data)
  return(
    <Card color={bk} header={props.trsl['npv']} body={<BarGraph data={dataTransform(x_input, legend)} />} />
  );
}

function IRRGraph(props){
  let x_input=extractDataFromVersionForLines(props.data,'_lep','irr')
  let legend = extractVersionName(props.data)
  return(
    <Card color={bk} header={props.trsl['irr']} body={<BarGraph data={dataTransform(x_input, legend)} />} />
  );
}

function extractDataFromVersionForLines(data,map, account){
  let bug=[]
  for (var i=0;i<data.length;i++){
    bug[i]=data[i].results[map][account]
  }
  return bug
}

function extractVersionName(data,){
  let bug=[]
  for (var i=0;i<data.length;i++){
    bug.push(`${[i+1]}: ${data[i].version_name}`)
  }
  return bug
}

export default FeasibilityCompareAll;