import React from 'react';
import { Row, Col } from 'Support/grid';

import ApiConnect from 'CONFIG/axios';
import UserContext from 'CONFIG/auth';

import calculateCoc from '../../../../CALCULATIONS/cal_cost_of_capital';
import {CocCAPM, PerpetuityRender,ZeroYearPicker,DiscountFactor} from './cost_of_capital_capm';

class CocAssumptionMain extends React.Component{
  static contextType = UserContext

  constructor(props) {
    super(props);
    this.state = {
      data: JSON.parse(JSON.stringify(this.props.version_data.coc)),
      loaded:false
    }
  }

  handleCocChange=(event)=>{
    let go= this.state.data
    go[event.target.name]=parseFloat(event.target.value)
    calculateCoc(go, this.props.feasibility_settings)
    this.setState({
      data:go
    })
  }

  handleCocModel=async(event)=>{
    let go= this.state.data
    go.coc_model=event.target.value
    calculateCoc(go, this.props.feasibility_settings)
    this.setState({
      data:go
    })
  }

  handleDiscountType=async(event)=>{
    let go= this.state.data
    let val=event.target.value
    if(val=="false") {val=false} else {val=true}
    go.coc_residual_discount_type=val
    calculateCoc(go, this.props.feasibility_settings)
    this.setState({
      data:go
    })
  }

  handleResidualValue=async(event)=>{
    let go= this.state.data
    go.coc_residual_value_type=event.target.value
    this.setState({
      data:go
    })
  }

  handleSave=()=>{
    this.props.handleLists.handleCocSave(this.state.data)
  }


  render(){
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_PROJECT.PROJECT_FEASIBILITY.Individual_edits')

    const props={
      trsl:trsl,
      data:this.state.data,
      feasibility_settings:this.props.feasibility_settings,
      handleCocChange:this.handleCocChange,
      handleCocModel:this.handleCocModel,
      handleDiscountType:this.handleDiscountType,
      handleResidualValue:this.handleResidualValue,
      handleSave:this.handleSave,
      user_permission:this.props.user_permission
    }

    return(
      <Row>
        <Col xs={12}>
          <CocCAPM {...props}/>
          <PerpetuityRender {...props}/>
          <ZeroYearPicker {...props}/>
          <DiscountFactor {...props}/>
        </Col>
      </Row>
    )
  }
}

export default CocAssumptionMain;