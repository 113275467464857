import React from 'react';
import { AssumptionHeaderSaveReturn } from '../budget_items';
import { Row, Col } from 'Support/grid'
import {FormEdit, FormEditInteger, NonEditableForm, Dropdown, PeriodHeader, SingleInputForm, Observations,Selectacble} from 'Support/inputforms'
import { ToogleButtons } from 'Support/toogle_buttons';
import UserContext from 'CONFIG/auth';
import Loader from 'Support/loader';
import ApiConnect from 'CONFIG/axios';
import {calculateHumanResources} from 'CALCULATIONS/Budget_calculations/cal_human_resources';

class HrEntry extends React.Component {
  static contextType = UserContext

  constructor(props) {
    super(props);
    this.state = {
      data: "",
      inflation:[1,1,1,1,1,1,1,1,1,1,1,1],
      loaded:false
    }
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleObservationsChange = this.handleObservationsChange.bind(this);
    this.handlePeriodChange = this.handlePeriodChange.bind(this);
    this.handleTypeChange=this.handleTypeChange.bind(this);
  }


  //GENERAL FUNCTIONS
  componentDidMount= async()=>{
    const request={
    budget_id:this.props.budget_id,
    version_id:this.props.current_version.id,
    item_id:this.props.active_item,
    item_type:"human_resources"
    }
    try {
      const response = await ApiConnect.post('/api/budget_project/multitem/unique/get', request)
      this.setState({
        data:response.data.item,
        loaded:true
      })
    } catch(error) {
        alert(error);
    }
  }

  handleNameChange(event){
    const value = event.target.value;
    let go = this.state.data;
    go.hr_name=value;
    this.setState({data:go })
  }

  handleObservationsChange(event){
    const value = event.target.value;
    let go = this.state.data;
    go.hr_observations=value;
    this.setState({data:go })
  }

  async handleSave(){
    const request={
      budget_id:this.props.budget_id,
      version_id:this.props.current_version.id,
      item_id:this.props.active_item,
      item_type:"human_resources",
      content:this.state.data
    }
    try{
      await ApiConnect.post('/api/budget_project/multitem/item/modify', request)
      this.props.changeScreenView("list",0)
    } catch(error) {
        alert(error);
    }
  }


  //HR FUNCTIONS
  handleEditChange=(id, item)=>{
    let go = this.state.data;
    go[id]=item;
    calculateHumanResources(go,this.props.budget_settings, this.state.inflation)
    this.setState({data:go})
  }

  handlePeriodChange=async(val)=>{
    const value = val;
    let go = this.state.data;
    go.hr_period=value;
    calculateHumanResources(go,this.props.budget_settings, this.state.inflation)
    this.setState({data:go})
  }

  handleCenterChange=async(event)=>{
    const value = event.target.value;
    let go = this.state.data;
    go.hr_center=value;
    this.setState({data:go})
  }

  handleTypeChange=async(event)=>{
    let value = event.target.value;
    if(value==="false"){value=false} else {value=true}
    let go = this.state.data;
    go.hr_type=value;
    calculateHumanResources(go,this.props.budget_settings, this.state.inflation)
    this.setState({data:go})
  }
  
  render() {
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_BUDGET.INSIDEPROJECT.Individual_edits')

    let p_base=['m1','m2','m3','m4','m5','m6','m7','m8','m9','m10','m11','m12']
    let periods=[]
    for(var i=0; i < p_base.length ;i++){
      periods[i]=trsl[p_base[i]]
    }

    const props ={
      trsl:trsl,
      handleScreenNavigation:this.props.handleScreenNavigation,
      budget_settings:this.props.budget_settings,
      user_permission:this.props.user_permission,
      data:this.state.data,      
      periods:periods,
      handleNameChange:this.handleNameChange,
      handleObservationsChange:this.handleObservationsChange,
      handleSave:this.handleSave,
      handleEditChange:this.handleEditChange,
      handlePeriodChange:this.handlePeriodChange,
      handleCenterChange:this.handleCenterChange,
      handleTypeChange:this.handleTypeChange
    }

    function torender(data, i){
      return data[i];
    }  
    const pages ={
      true: <ToDisplay {...props}/>,
      
      false: <Loader loader_message="hr_edit"/>
    }
    var i = this.state.loaded
    const renderizing = torender(pages,i)

    return(
      <div>
        {renderizing}
      </div>
    )
  }
}

class ToDisplay extends React.Component {
  render(){
    const props=this.props

    const category_render=(checker)=>{
      if(checker===false){
        return <NumberOfWorkersEdit className="feasibility_item_list" {...props} />
      }
    }
    const num_workers_renderizing= category_render(this.props.data.hr_type)

    return(
      <>
        <GlobalHeader {...props}/>
        <Row>
          <Col xs={12} >
            <PeriodHeader label={this.props.trsl['period']} periods={this.props.periods} />
          </Col>
          <Col xs={12} className="feasibility_list">
            {num_workers_renderizing}
            <SalaryEdit {...props}/>
            <PrizesEdit {...props}/>
            <SubsidyEdit {...props}/>
            <TotalCost {...props}/>
          </Col>
            <Observations xs={12} name="hr_observations" value={this.props.data.hr_observations} label="Observations" handleObservationsChange={this.props.handleObservationsChange}/>
        </Row>
      </>
    );
  }
}

class GlobalHeader extends React.Component{
  render(){
    const props=this.props

    const options=[
      {value:true,option_label:this.props.trsl['hr_individual']},
      {value:false,option_label:this.props.trsl['hr_category']}
    ]

    const options_item_holder = this.props.budget_settings.centers
    let options_item_distr=[{value:"",option_label:''}]
    if(options_item_holder !==undefined){
      options_item_holder.map(key =>{
        options_item_distr.push({value:key,option_label:key})
      })
    }

    return(
      <div>
        <AssumptionHeaderSaveReturn {...props}/>
        <Row className="feasibility_edit_globalheader_row">
          <SingleInputForm
          xs={12} md={5}
          value={this.props.data.hr_name} 
          label= {this.props.trsl['hr_name']} 
          handleEditItemChange={this.props.handleNameChange}/>
          <Dropdown xs={12} md={7} label={this.props.trsl['center']} name="centers" value={this.props.data.hr_center} options={options_item_distr} onChange={this.props.handleCenterChange} />
        <ToogleButtons
          xs={12} md={12} 
          label={this.props.trsl['salaries_set2']}
          toogle_data={options}
          selected={this.props.data.hr_type} 
          handleToogleButton={this.props.handleTypeChange} />
        </Row>
      </div>
    );
  }
}

class SalaryEdit extends React.Component{
  render(){
    return(
      <FormEdit 
      use_historic={this.props.budget_settings.use_historic}
      item_holder={this.props.data.hr_gross_salary}
      id="hr_gross_salary"
      handleForm={this.props.handleEditChange}
      label={this.props.trsl['hr_gross']}
      unit={new Intl.NumberFormat('ja-JP', { style: 'currency', currency: this.props.budget_settings.currency}).format(0).replace(/\d./g,"")}
      />
    );
  }
}

class NumberOfWorkersEdit extends React.Component{
  render(){ 
    return(
      <FormEdit 
      use_historic={this.props.budget_settings.use_historic}
      item_holder={this.props.data.hr_number_of_workers}
      id="hr_number_of_workers"
      handleForm={this.props.handleEditChange}
      label={this.props.trsl['hr_number_workers']}
      unit="U"
      />
    );
  }
}

class PrizesEdit extends React.Component{
  render(){ 
    return(
      <FormEdit 
      use_historic={this.props.budget_settings.use_historic}
      item_holder={this.props.data.hr_prizes_bonus}
      id="hr_prizes_bonus"
      handleForm={this.props.handleEditChange}
      label={this.props.trsl['hr_prizes']}
      unit={new Intl.NumberFormat('ja-JP', { style: 'currency', currency: this.props.budget_settings.currency}).format(0).replace(/\d./g,"")}
      />
    );
  }
}

class SubsidyEdit extends React.Component{
  render(){ 
    return(
      <FormEdit 
      use_historic={this.props.budget_settings.use_historic}
      item_holder={this.props.data.hr_subsidy}
      id="hr_subsidy"
      handleForm={this.props.handleEditChange}
      label={this.props.trsl['hr_subsidy']}
      unit={new Intl.NumberFormat('ja-JP', { style: 'currency', currency: this.props.budget_settings.currency}).format(0).replace(/\d./g,"")}
      />
    );
  }
}

class GrossSalaryInflated extends React.Component{
  render(){ 
    return(
      <NonEditableForm
      item_holder={this.props.data._hr_gross_salary_inflated}
      label={this.props.trsl['hr_gross_salary_inflated']}
      value_type="currency"
      currency={this.props.budget_settings.currency}
      />
    );
  }
}

class TotalCost extends React.Component{
  render(){ 
    return(
      <NonEditableForm
      item_holder={this.props.data._hr_total_cost}
      label={this.props.trsl['hr_total']}
      value_type="currency"
      currency={this.props.budget_settings.currency}
      />
    );
  }
}


export default HrEntry;