import React from 'react';
import {  Route, Routes } from "react-router-dom";

import {BudgetHeaderBar} from './budget_headerbar';
import Dashboard from './0 Dashboard/dashboardmain';
import VersionMain from './1 Version_Settings/versionsmain';
import RevenuesAndCogsMain from './2 Revenues/revenuesandcogsmain';
import OperatingExpensesMain from './3 Operating_exp/operating_exp_main';
import HrMain from './4 HR/hrmain';
import OtherGainsCostMain from './5 Other_gains_costs/other_gains_cost_main';
import InvestmentMain from './6 Investment/investmentmain';
import DebtMain from './8 Debt/debtmain';

import Session from 'CONFIG/session';
import UserContext from 'CONFIG/auth';

import {Navigation,HorizontalBarNavigation} from 'Support/navigation'
import { Row, Col } from 'Support/grid';

import 'CSS/panes.css';


const route="/app/budget_project/edit/"



class BudgetPane extends React.Component{
  //Routing
  constructor(props) {
    super(props);
      this.state = {
      version_render: Session.getVersionRender(),
      tab_preference:false
    }
    this.handleSelect=this.handleSelect.bind(this);
  }

  screen_width = (window.innerWidth > 0) ? window.innerWidth : window.width;

  componentDidMount(){
  }

  handleSelect(selectedKey) {
    this.setState({
      version_render: selectedKey
    })
    Session.setVersionRender(selectedKey)
    this.props.handlePrincipalRouting(route+selectedKey)
  }

  handleChangeFeasibilityHeader=()=>{
    let go=this.state.tab_preference
    this.setState({
      tab_preference:!go
    })
  }

  render(){
    const props={
      screen_width:this.screen_width,
      budget_id:this.props.budget_id,
      user_id:this.props.user_id,
      user_permission:this.props.budget_data.user_permission,
      budget_settings:this.props.budget_settings,
      budget_name:this.props.budget_data.budget_name,
      version_render:this.state.version_render,
      current_version:this.props.current_version,
      handleBudgetRouting:this.props.handleBudgetRouting,
      handleGoToInProject:this.props.handleGoToInProject,
      active_key:this.state.version_render,
      handleSelect:this.handleSelect,
    }

    const panes={
      true:<Pane1 {...props}/>,
      false:<Pane2 {...props}/>
    }

    function selectPane(options,input){
      return options[input]
    }
    let render=selectPane(panes,this.state.tab_preference)
    
    return(
      <>
        <Row >
          <Col xs={12}>
            <BudgetHeaderBar 
            budget_name={this.props.budget_name} 
            current_version={this.props.current_version}
            version_render={this.props.version_render}
            screen_width={this.props.screen_width}
            handleGoToInProject={this.props.handleGoToInProject}
            handleChangeFeasibilityHeader={this.handleChangeFeasibilityHeader}/>
          </Col>
          {render}
        </Row>
      </>
    )
  }
}

class Pane1 extends React.Component{
  render(){
    const props=this.props
    return(
      <>
          <Col xs={2} className='bk_darkshade'>
            <Navigator active_key={this.props.active_key} handleSelect={this.props.handleSelect}/>
          </Col>
          <Col xs={10} >
            <BudgetRoutes {...props}/>
          </Col>
      </>
    );
  }
}

class Pane2 extends React.Component{
  render(){
    const props=this.props
    return(
      <>
          <Col xs={12}>
            <Navigation2 active_key={this.props.active_key} handleSelect={this.props.handleSelect}/>
          </Col>
          <Col xs={12}>
            <BudgetRoutes {...props}/>
          </Col>
      </>
    );
  }
}

function BudgetRoutes(props){
  return(
      <Routes>
        <Route index element={<RevenuesAndCogsMain {...props}/>}/>
        <Route path={`version`} element={<VersionMain {...props}/>}/>
        <Route path={`revenue`} element={<RevenuesAndCogsMain {...props}/>}/>
        <Route path={`operating_expenses`} element={<OperatingExpensesMain {...props}/>}/>
        <Route path={`hr`} element={<HrMain {...props}/>}/>
        <Route path={`other_gains_cost`} element={<OtherGainsCostMain {...props}/>}/>
        <Route path={`investment`} element={<InvestmentMain {...props}/>}/>
        <Route path={`debt`} element={<DebtMain {...props}/>}/>
        <Route path={`dashboard`} element={<Dashboard {...props} current_version={props.current_version}/>}/>
      </Routes>
  )
}


class Navigator extends React.Component {
  static contextType = UserContext
  render(){
    const props=this.props
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_PROJECT.PROJECT_FEASIBILITY.Navigation&Header')

    return(     
      <Navigation {...props} >
        <Navigation.NavigationMega>{trsl['assumptions']}</Navigation.NavigationMega>
        <Navigation.Item name='version'>{trsl['version']}</Navigation.Item>
        <Navigation.Item name='revenue'>{trsl['revenue']}</Navigation.Item>
        <Navigation.Item name='operating_expenses'>{trsl['operating_expenses']}</Navigation.Item>
        <Navigation.Item name='hr'>{trsl['hr']}</Navigation.Item>
        <Navigation.Item name='other_gains_cost'>{trsl['other_gains_cost']}</Navigation.Item>
        <Navigation.Item name='investment'>{trsl['investment']}</Navigation.Item>
        <Navigation.Item name='debt'>{trsl['debt']}</Navigation.Item>
        <Navigation.NavigationMega>{trsl['results']}</Navigation.NavigationMega>
        <Navigation.Item name='dashboard'>{trsl['dashboard']}</Navigation.Item>
      </Navigation>
    );
  }
}

class Navigation2 extends React.Component {
  static contextType = UserContext
  render(){
    const props=this.props
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_PROJECT.PROJECT_FEASIBILITY.Navigation&Header')

    return(     
      <HorizontalBarNavigation {...props}>
          <HorizontalBarNavigation.Item name='version'>{trsl['version']}</HorizontalBarNavigation.Item>
          <HorizontalBarNavigation.Item name='revenue'>{trsl['revenue']}</HorizontalBarNavigation.Item>
          <HorizontalBarNavigation.Item name="operating_expenses">{trsl['operating_expenses']}</HorizontalBarNavigation.Item>
          <HorizontalBarNavigation.Item name="hr">{trsl['hr']}</HorizontalBarNavigation.Item>
          <HorizontalBarNavigation.Item name="other_gains_cost">{trsl['other_gains_cost']}</HorizontalBarNavigation.Item>
          <HorizontalBarNavigation.Item name="investment">{trsl['investment']}</HorizontalBarNavigation.Item>
          <HorizontalBarNavigation.Item name="debt">{trsl['debt']}</HorizontalBarNavigation.Item>
          <HorizontalBarNavigation.Item name='dashboard'>{trsl['dashboard']}</HorizontalBarNavigation.Item>
      </HorizontalBarNavigation>
    );
  }
}

export default BudgetPane;