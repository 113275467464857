import React from 'react';
import { Row, Col } from 'Support/grid';
import calInflationFactor from '../../../../CALCULATIONS/version_cal';
import UserContext from 'CONFIG/auth';

import {AssumptionHeaderSave} from 'PRIVATE/PROJECT/Feasibility/feasibility_items';

import {FormEdit,FormEditInflation, SingleInputFormFlat,SingleInputLargeForm, PeriodHeader} from 'Support/inputforms'
import { ToogleButtons } from 'Support/toogle_buttons';
import Loader from 'Support/loader';

class VersionMain extends React.Component {
  static contextType = UserContext

  constructor(props) {
    super(props);
    this.state = {
      data: "",
      loaded:false
    }
  }

  componentDidMount(){
    this.setState({
      data:JSON.parse(JSON.stringify(this.props.version_data)),
      loaded:true
    })
  }

  handleEditChange=async(id, item)=>{
    let go = this.state.data;
    go.version_settings[id]=item;
    go.version_settings._inflation_factor=calInflationFactor(go.version_settings.inflation)
    this.setState({data:go})
  }

  handleNameChange=(event)=>{
    const value = event.target.value;
    const name = event.target.name;
    let go = this.state.data;
    go[name]=value;
    this.setState({data:go })
  }

  handleIRCSelection=(event)=>{
    const name=event.target.name
    let val=true
    if(event.target.value==="false") {val=false}
    let go= this.state.data
    go.version_settings[name]=val
    
    if(go.version_settings.use_general_irc===false && go.version_settings.version_corporate_tax_rate.length===0){
      go.version_settings.version_corporate_tax_rate =new Array(go.version_settings.days_accounts_receivables.length).fill(0)
    }
    this.setState({data:go})
  }

  handleDividendsSelection=(event)=>{
    let go= this.state.data
    go.version_settings.use_dividends=event.target.value
    this.setState({data:go})
  }

  handleSave=async()=>{
    this.props.handleLists.handleSaveSettings(this.state.data)
  }

  render() {
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_PROJECT.PROJECT_FEASIBILITY.Individual_edits')
    const props ={
      trsl:trsl,
      handleScreenNavigation: this.props.handleScreenNavigation,
      handleEditChange: this.handleEditChange,
      handleSave: this.handleSave,
      handleNameChange: this.handleNameChange,
      handlePeriodChange: this.handlePeriodChange,
      handleIRCSelection: this.handleIRCSelection,
      handleDividendsSelection: this.handleDividendsSelection,
      data: this.state.data,
      years: this.props.years,
      feasibility_settings: this.props.feasibility_settings,
      user_permission: this.props.user_permission
    }

    function torender(data, i){
      return data[i];
    }
    const pages ={
      true: <ToDisplay {...props}/>,
      false: <Loader loader_message="single_dashboard"/>
    }
    var i = this.state.loaded
    const renderizing = torender(pages,i)
    
    return(
      <div>{renderizing}</div>
    );
  }
}

function ToDisplay(props){
  return(
    <Row>
      <Col xs={12}>
        <GlobalHeader {...props}/>
        <Row>
          <Col xs={12} >
            <PeriodHeader label={props.trsl['year']} periods={props.years}/>
          </Col>
          <Col xs={12} className="feasibility_list">
            <InflationChoice className="feasibility_item_list" {...props}/>
            <DAR className="feasibility_item_list" {...props} />
            <DAP className="feasibility_item_list" {...props}/>
            <IDAP className="feasibility_item_list" {...props}/>
          </Col>
            <SelectCorporateTaxRate xs ={12}md={12} {...props}/>
          <Col xs={12}>
            <CorporateTaxRate {...props}/>
          </Col>
            <SelectDividends xs ={12}md={12} {...props}/>
          <Col xs={12}>
            <DividendsPayout {...props}/>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

class GlobalHeader extends React.Component{
  render(){
    let edit_ony_permission=true
    if(this.props.user_permission=="owner" || this.props.user_permission=="edit"){
      edit_ony_permission= false
    }
    return(
      <div>
        <AssumptionHeaderSave title={this.props.trsl['vs_header']} user_permission={this.props.user_permission} save={this.props.trsl['save_button']} handleSave={this.props.handleSave} />
        <Row className="feasibility_edit_globalheader_row">
          <SingleInputFormFlat xs={12} md={12} name='version_name' value={this.props.data.version_name} label= {this.props.trsl['vs_name']} handleEditItemChange={this.props.handleNameChange}/>
          <SingleInputLargeForm xs ={12} md={12} name='version_description' value={this.props.data.version_description} label= {this.props.trsl['vs_description']} maxlength={500} handleEditItemChange={this.props.handleNameChange}/>
        </Row>
      </div>
    );
  }
}

class InflationChoice extends React.Component{
  render(){
    const props=this.props
    if(this.props.feasibility_settings.use_historic){
      return <InflationWhiHistoric {...props}/>
    } else {
      return <Inflation {...props}/>
    }
  }
}

class Inflation extends React.Component{
  render(){ 
    return(
      <FormEditInflation 
      item_holder={this.props.data.version_settings.inflation}
      id="inflation"
      handleForm={this.props.handleEditChange}
      label={this.props.trsl['vs_inflation']}
      unit="%"
      />
    );
  }
}

class InflationWhiHistoric extends React.Component{
  render(){ 
    return(
      <FormEdit 
      use_historic={this.props.feasibility_settings.use_historic}
      item_holder={this.props.data.version_settings.inflation}
      id="inflation"
      handleForm={this.props.handleEditChange}
      label={this.props.trsl['vs_inflation']}
      unit="%"
      />
    );
  }
}

class DAR extends React.Component{
  render(){ 
    return(
      <FormEdit
      use_historic={this.props.feasibility_settings.use_historic}
      s_value={this.props.s_value}
      item_holder={this.props.data.version_settings.days_accounts_receivables}
      id="days_accounts_receivables"
      handleForm={this.props.handleEditChange}
      label={this.props.trsl['vs_dar']}
      unit="D"
      />
    );
  }
}
class DAP extends React.Component{
  render(){ 
    return(
      <FormEdit 
      use_historic={this.props.feasibility_settings.use_historic}
      s_value={this.props.s_value}
      item_holder={this.props.data.version_settings.days_accounts_payables}
      id="days_accounts_payables"
      handleForm={this.props.handleEditChange}
      label={this.props.trsl['vs_dap']}
      unit="D"
      />
    );
  }real_corporate_tax_rate
}
class IDAP extends React.Component{
  render(){ 
    return(
      <FormEdit 
      use_historic={this.props.feasibility_settings.use_historic}
      item_holder={this.props.data.version_settings.investment_days_accounts_payables}
      id="investment_days_accounts_payables"
      handleForm={this.props.handleEditChange}
      label={this.props.trsl['vs_dip']}
      unit="D"
      />
    );
  }
}

class SelectCorporateTaxRate extends React.Component{
  render(){
    const options=[
      {value:true,option_label:this.props.trsl['vs_general_irc'], name:'use_general_irc'},
      {value:false,option_label:this.props.trsl['vs_edited_irc'], name:'use_general_irc'}
    ]

    const valueChecker=(input)=>{
      if(input===undefined || input===true){
        return true
      } else return false
    }

    if(this.props.feasibility_settings.use_historic!==true){
      return(
        <>    
          <ToogleButtons
          xs={12} md={12} 
          label={this.props.trsl['vs_irc']}
          toogle_data={options}
          selected={valueChecker(this.props.data.version_settings.use_general_irc)}
          handleToogleButton={this.props.handleIRCSelection} />
        </>
      )
    } else return ''
  }
}

function CorporateTaxRate (props){
  if(props.data.version_settings.use_general_irc===false){
    return(
      <>
        <PeriodHeader label={props.trsl['year']} periods={props.years}/>
        <FormEdit 
        use_historic={props.feasibility_settings.use_historic}
        item_holder={props.data.version_settings.version_corporate_tax_rate}
        id="version_corporate_tax_rate"
        handleForm={props.handleEditChange}
        label={props.trsl['vs_tax']}
        unit="%"
        />
      </>
    );
  } else return ''
}

class SelectDividends extends React.Component{
  render(){
    const options=[
      {value:'none',option_label:this.props.trsl['vs_dividends_1']},
      {value:'payout',option_label:this.props.trsl['vs_dividends_2']}
    ]
    return(
      <>    
        <ToogleButtons
        xs={12} md={12} 
        label={this.props.trsl['vs_dividends']}
        toogle_data={options}
        selected={this.props.data.version_settings.use_dividends}
        handleToogleButton={this.props.handleDividendsSelection} />
      </>
    )
  }
}

function DividendsPayout (props){
  if(props.data.version_settings.use_dividends==='payout'){
    return(
      <>
        <PeriodHeader label={props.trsl['year']} periods={props.years}/>
        <FormEdit 
        use_historic={props.feasibility_settings.use_historic}
        item_holder={props.data.version_settings.dividends_payout_ratio}
        id="dividends_payout_ratio"
        handleForm={props.handleEditChange}
        label={props.trsl['vs_dividends_payout_ratio']}
        unit="%"
        />
      </>
    );
  } else return ''
}

export default VersionMain;