import React from 'react';
import {Row, Col} from 'Support/grid';

import UserContext from 'CONFIG/auth';
import ApiConnect from 'CONFIG/axios';

import {BeforeStripeCheckout,StripeSubsSelector,StripeCurrencySelector} from './subscription_stripe';
import { RegularButton } from 'Support/listbuttons';
import { Dropdown } from 'Support/inputforms';


class SubscriptionManage extends React.Component{

  render(){
    const props=this.props
    return(
      <>
        <Header {...props} />
        <Row className="subscription_buy_box1">
          <Col xs={12}>
            <SubscriptionDisplay {...props} />
            <SubscriptionExtend {...props}/>
          </Col>
        </Row>
      </>
    )
  }
}

class SubscriptionManageInProject extends React.Component{

  render(){
    const props={
      subscription_id:this.props.project_data.project_subscription_id,
      project_id:this.props.project_id
    }
    return(
      <>
        <Row className="subscription_buy_box1">
          <Col xs={12}>
            <SubscriptionDisplay {...props} />
            <SubscriptionExtend {...props}/>
            <SubscriptionReplace {...props} />
          </Col>
        </Row>
      </>
    )
  }
}

class SubscriptionView extends React.Component{

  render(){
    const props=this.props
    return(
      <>
        <Header {...props} />
        <Row>
          <Col xs={12}>
            <SubscriptionDisplay {...props} />
          </Col>
        </Row>
      </>
    )
  }
}

class Header extends React.Component{
  static contextType = UserContext

  render(){
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_SUBSCRIPTION.Subscription_manage')

    return(
      <Row>
      <Col xs={10}>
        <h2>{trsl['subscription']}</h2>
      </Col>
      <Col xs={2}>
        <RegularButton bk='bk_primary' label={trsl['back_button']} name="list" onClick={this.props.handleScreenNavigation} />
      </Col>
    </Row>
    )
  }
}

class SubscriptionDisplay extends React.Component{
  static contextType = UserContext

  constructor(props){
    super(props);
    this.state = {
      data: '',
      subscription_loaded:false
    }
  }

  componentDidMount(){
    this.updateData()
  }

  updateData=async()=>{
    try{
      const request={
        subscription_id:this.props.subscription_id
      }
      const response = await ApiConnect.post('/api/user/subscription/unique/get', request)
      this.setState({
        data:response.data,
        subscription_loaded:true
      })
    } catch(error) {
      alert(error);
    }
  }


  render(){
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_SUBSCRIPTION.Subscription_manage')

    const subscriptionDuration=(durationchecker, wasusedchecker)=>{
      if(this.state.subscription_loaded===true){
        if(durationchecker===true && wasusedchecker===true){
          return(
            <p>
              {trsl['sub_validity_of1']} {this.state.data.subscription_duration_flex_days} {trsl['sub_validity_of2']} {user_c.i18n.dateF(this.state.data.subscription_duration_deadline)}. 
            </p>
          )
        } else if(durationchecker===true && wasusedchecker===false){
          return(
            <p>
              {trsl['sub_duration1']}{this.state.data.subscription_duration_flex_days} {trsl['sub_duration2']}{user_c.i18n.dateF(this.state.data.subscription_validity)}. 
            </p>
          )
        } else if(durationchecker===false && wasusedchecker===true){
          return(
            <p>
              {trsl['sub_remain_active1']}{user_c.i18n.dateF(this.state.data.subscription_duration_deadline)}{trsl['sub_remain_active2']}
            </p>
          )
        } else {
          return(
            <p>
              {trsl['activate_unitil1']} {user_c.i18n.dateF(this.state.data.subscription_validity)}{trsl['activate_unitil2']} {user_c.i18n.dateF(this.state.data.subscription_duration_deadline)} {trsl['activate_unitil3']}
            </p> 
          )
        }
      }
    }
    const duration_renderizing=subscriptionDuration(this.state.data.subscription_duration_type,this.state.data.subscription_used)

    const subscriptionWasUsed=(checker)=>{
      if(checker===true){
        return(
          <p>
            {trsl['sub_activated_for_project1']} {this.state.data.subscription_used_project} {trsl['sub_activated_for_project2']} {user_c.i18n.dateF(this.state.data.subscription_date_used)}.
          </p>
        )
      } else {
        return(
          <p>
            {trsl['sub_not_used']}
          </p>            
        )
      }
    }
    const was_used_renderizing=subscriptionWasUsed(this.state.data.subscription_used)

    return(
      <>
            <h3>{trsl['subscription_details']}</h3>

            <Row className="subscription_manage_blocks">
              <Col xs={8}>
                <Row>
                  <Col xs={4} className="subscription_manage_details">
                    <h6>{trsl['id']}</h6>
                    {this.state.data.subscription_id}
                  </Col>
                  <Col xs={4} className="subscription_manage_details">
                  <h6>{trsl['type']}</h6>
                    {trsl[this.state.data.subscription_type]}
                  </Col>
                  <Col xs={4} className="subscription_manage_details">
                    <h6>{trsl['permission']}</h6>
                    {trsl[this.state.data.subscription_permission]}
                  </Col>
                  <Col xs={4} className="subscription_manage_details">
                    <h6>{trsl['origin']}</h6>
                    {trsl[this.state.data.subscription_origin]}
                  </Col>
                  <Col xs={4} className="subscription_manage_details">
                    <h6>{trsl['duration']}</h6>
                    {this.state.data.subscription_duration_flex_days}
                  </Col>
                </Row>
                <Row>
                  <Col xs={4} className="subscription_manage_details">
                    <h6>{trsl['number_of_col']}</h6>
                    {this.state.data.subscription_number_of_colaborators}
                  </Col>
                  <Col xs={4} className="subscription_manage_details">
                    <h6>{trsl['number_of_viewers']}</h6>
                    {this.state.data.subscription_number_of_viewers}
                  </Col>
                  <Col xs={4} className="subscription_manage_details">
                    <h6>{trsl['number_of_versions']}</h6>
                    {this.state.data.subscription_number_of_versions}
                  </Col>
                </Row>
              </Col>
              <Col xs={1}></Col>
              <Col xs={3}>
                <h4>{trsl['status']}</h4>
                {was_used_renderizing}
                {duration_renderizing}
              </Col>
            </Row>

      </>
    )
  }
}

class SubscriptionExtend extends React.Component{
  static contextType = UserContext

  constructor(props){
    super(props);
    this.state = {
      data: '',
      subscriptions_types:[],
      selected_subscription:"",
      currency:"eur",
      stripe_disabled:"true"
    }
  }

  componentDidMount(){
    this.getSubscriptionsTypesList()
  }

  getSubscriptionsTypesList=async()=>{
    try{
      const request={
        currency:this.state.currency
      }
      const response = await ApiConnect.post('/api/user/subscription/options/get', request)
      this.setState({
        subscriptions_types:response.data.subs_selection
      })
    } catch(error) {
      alert(error);
    }
  }

  handleCheckoutSession=async(event)=>{
    const value=event.target.value

    try{
      const request={
        currency:this.state.currency,
        subs_type:'business_plan',
        selected:value,
        subscription_id:this.props.subscription_id
      }
      const response = await ApiConnect.post('/api/user/subscription/selected/get', request)
      this.setState({
        data:response.data,
        selected_subscription:value,
        stripe_disabled:false
      })
    } catch(error) {
      alert(error);
    }
  }

  handleCurrencySelection=async(event)=>{
    this.setState({
      currency:event.target.value,
      data:"",
      selected_subscription:"",
      stripe_disabled:true
    })
  }

  render(){
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_SUBSCRIPTION.Subscription_manage')

    const props={
      handleScreenNavigation:this.props.handleScreenNavigation,
      handleCheckoutSession:this.handleCheckoutSession,
      data:this.state.data,
      subscriptions_types:this.state.subscriptions_types["business_plan"],
      selected:this.state.selected_subscription,
      currency:this.state.currency,
      stripe_disabled:this.state.stripe_disabled
    }

    return(
      <>
        <Row className='blanktop_15'>
          <h3>{trsl['extend_sub']}</h3>
        </Row>
        <Row>
          <Col xs={8}>
          <ExtendPlan {...props}/>
          </Col>
          <Col xs={1}></Col>
          <Col xs={3}>
            <h5>{trsl['buying_cart']}</h5>
            <StripeCurrencySelector handleCurrencySelection={this.handleCurrencySelection} />
            <BeforeStripeCheckout data={this.state.data} stripe_disabled={this.state.stripe_disabled}/>
          </Col>
        </Row>

      </>
    )
  }
}

class SubscriptionReplace extends React.Component{
  static contextType = UserContext
  constructor(props){
    super(props);
    this.state = {
      selected_subscription:undefined,
      subscription_data:'',
      subscription_data_loaded:false
    }
  }

  handleChangeSubscription=async(event)=>{
    const value=event.target.value;
    this.setState({
      selected_subscription:value,
    });
    const request={
      subscription_id:value
    }
    try {
      const response = await ApiConnect.post('/api/user/subscription/unique/get', request)
      this.setState({
        subscription_data:response.data,
        subscription_data_loaded:true
      })
    } catch(error) {
        alert(error);
    }
  }

  handleReplaceSubscription=async(event)=>{
    if(this.state.selected_subscription!==undefined){
      const request={
        subscription_id:this.props.subscription_id,
        new_subscription:this.state.selected_subscription,
        project_id:this.props.project_id
      }
      try {
        await ApiConnect.post('/api/project/subscription/replace', request)
        this.props.updateSettingsData()
      } catch(error) {
        alert(error);
      }
    }
    else{
      alert("You did not select any subscription")
    }
  }

  render(){
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_SUBSCRIPTION.Subscription_in_project')
    const item_holder = this.state.selected_subscription
    let item_distr
    if(item_holder !==undefined){
        item_distr = <ShowSubscription data={this.state.subscription_data} data_loaded={this.state.subscription_data_loaded}/>   
    } else {
      item_distr=trsl['sub_not_selected']
    }
    return(
      <Row >
        <Col xs={12} className='blanktop_15'>
          <h3>{trsl['replace']}</h3>
        </Col>
        <Col xs={4}>
          <SelectSubscription project_type="business_plan" handleChangeSubscription={this.handleChangeSubscription}/>
        </Col>
        <Col xs={8}></Col>
        <Col xs={12}>
        {item_distr}
        </Col>
        <Col xs={4}></Col>         
        <Col xs={12} className='blanktop_05'>
          <RegularButton bk='bk_primary' label={trsl['replace_sub']} onClick={this.handleReplaceSubscription} />
        </Col>
    </Row>
    )
  }
}

class SelectSubscription extends React.Component{
  static contextType = UserContext
  constructor(props) {
    super(props);
    this.state = {
      data:'',
      loaded:false
    }
  }

  componentDidMount() {
    this.updateSubscription()
  }

  updateSubscription=async()=>{
    const request={
    }
    try {
      const response = await ApiConnect.post('/api/user/subscriptions/list', request)
      this.setState({
        data:response.data.unused_subscriptions,
        loaded:true,
      })
    } catch(error) {
        alert(error);
    }
  }
  render() {
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_SUBSCRIPTION.Subscription_in_project')
    
    function findValidDates(obj,now){
      if(obj.length>0){
        return obj.filter(({subscription_validity})=>new Date(subscription_validity).getTime()>new Date(now).getTime())
      }
    }
    const now =Date.now()
    const valid_subs=findValidDates(this.state.data,now)
    const props ={
      trsl:trsl,
      data:valid_subs,
      project_type:this.props.project_type,
      handleChangeSubscription:this.props.handleChangeSubscription
    }

    function torender(data, i){
      return data[i];
    }  
    const pages ={
      true: 
      <ToDisplay2 {...props}/>,
      
      false: 
      <div>loading</div>
    }
    var i = this.state.loaded
    const renderizing = torender(pages,i)

    return(
      <>
        {renderizing}
      </>
    )
  }
}

class ToDisplay2 extends React.Component {
  render(){
    const item_holder = this.props.data
    let item_distr
    if(item_holder !==undefined && item_holder.length>0){
      let item_holder2 = item_holder.filter(({subscription_type})=>subscription_type==this.props.project_type)
      item_distr = item_holder2.map((v) =>{
        return({value:v.subscription_id,option_label:`${v.subscription_id}`})
      }
      );
      item_distr.splice(0,0,{value:'null',option_label:this.props.trsl['selec_sub']})
    } else {
      item_distr=[]
    }
    
    return(
      <>
        <Dropdown xs={12} md={12} label={this.props.trsl['selec_sub']} value={this.props.subscription_id} options={item_distr} onChange={this.props.handleChangeSubscription}/>
      </>
    )
  }
}

class ShowSubscription extends React.Component{
  static contextType = UserContext

  constructor(props) {
    super(props);
    this.state = {
      data:'',
      loaded:false
    }
  }

  render() {
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_SUBSCRIPTION.Subscription_in_project')
    
    const props={
      trsl:trsl,
      data:this.props.data,
    }

    function torender(data, i){
      return data[i];
    }  
    const pages ={
      true: 
      <ToDisplay1 {...props}/>,
      
      false: 
      <div>loading...</div>
    }
    var i = this.props.data_loaded
    const renderizing = torender(pages,i)

    return(
      <div>
        {renderizing}
      </div>
    )
  }
}

class ToDisplay1 extends React.Component {
  static contextType = UserContext

  render(){
    let user_c= this.context

    const originFunction = (input)=>{
      if(input=='Administrator'){
        return(
          <p>
            {this.props.trsl['sub_by_admin']}
          </p>
        )
      } else if(input=='Partner'){
        return(
          <p>
            {this.props.trsl['sub_by_partner']}
          </p>
        )
      } else if(input=='Acquisition'){
        return(
          <p>
            {this.props.trsl['sub_by_partner']}
          </p>
        )
      } else {
        return(
          <p>
            {this.props.trsl['sub_by_demo']}
          </p>
        )
      }
    }

    let displayduration1
    let displayduration2

    const displayDurationFunction=(input)=>{
      if(input===true){
        displayduration1=
          <>
            <Col xs={6}>
              <h6>{this.props.trsl['duration']}</h6> 
            </Col>
            <Col xs={6}>
              {this.props.data.subscription_duration_flex_days}{this.props.trsl['days']}
            </Col>
            </>
            displayduration2=
            <p>
              {this.props.trsl['this_is_floating_sub']} {this.props.data.subscription_duration_flex_days} {this.props.trsl['this_is_floating_sub2']}
            </p>
      } else {
        displayduration1=
          <>
            <Col xs={6}>
              <h6>{this.props.trsl['duration']}</h6>
            </Col>
            <Col xs={6}>
              {user_c.i18n.dateF(this.props.data.subscription_duration_fixed_day)}
            </Col>
          </>
        displayduration2=
          <p>
            {this.props.trsl['this_is_fixed_sub']} 
          </p>          
      }
    }
    displayDurationFunction(this.props.data.subscription_duration_type)
    
    return(
      <>
        <Row>
          <Col xs={12}>
            <h6>{this.props.trsl['sub_details']} </h6>
          </Col>
          <Col xs={6}>
            <Row className="subscription_in_project_list_items">
              <Col xs={6}>
                <h6>{this.props.trsl['id']}</h6>
              </Col>
              <Col xs={6}>
                {this.props.data.subscription_id}
              </Col>
            </Row>
            <Row className="subscription_in_project_list_items">
              <Col xs={6}>
                <h6>{this.props.trsl['type']}</h6>
              </Col>
              <Col xs={6}>
                {this.props.trsl[this.props.data.subscription_type]}
              </Col>
            </Row>
            <Row className="subscription_in_project_list_items">
              <Col xs={6}>
                <h6>{this.props.trsl['number_of_versions']}</h6> 
              </Col>
              <Col xs={6}>
                {this.props.data.subscription_number_of_versions}
              </Col>
            </Row>
            <Row className="subscription_in_project_list_items">
              <Col xs={6}>
                <h6>{this.props.trsl['valid_until']}</h6> 
              </Col>
              <Col xs={6}>
                {user_c.i18n.dateF(this.props.data.subscription_validity)}
              </Col>
            </Row>
            <Row className="subscription_in_project_list_items">
              {displayduration1}
            </Row>
            <Row className="subscription_in_project_list_items">
              <Col xs={6}>
                <h6>{this.props.trsl['number_of_col']}</h6> 
              </Col>
              <Col xs={6}>
                {this.props.data.subscription_number_of_colaborators}
              </Col>
            </Row>
            <Row className="subscription_in_project_list_items">
              <Col xs={6}>
                <h6>{this.props.trsl['number_of_viewers']}</h6> 
              </Col>
              <Col xs={6}>
                {this.props.data.subscription_number_of_viewers}
              </Col>
            </Row>
          </Col>
          <Col xs={1}></Col>
          <Col xs={5}>
            <Row>
              <h5><span variant="warning">{this.props.trsl['relevant']}</span></h5>
            </Row>
            <Row>
              {displayduration2}
            </Row>
          </Col>
        </Row>
      </>
    )
  }
}









class ExtendPlan  extends React.Component{
  render(){
    const item_holder=this.props.subscriptions_types
    let item_distr=""
    if(item_holder!=undefined){
      item_distr=item_holder.map((value)=>{
        return(
          <>
            <StripeSubsSelector 
            id={value.id}
            period={value.period}
            name={value.product_name} 
            price={value.price[this.props.currency]} 
            selected={this.props.selected}
            handleCheckoutSession={this.props.handleCheckoutSession}
            currency= {this.props.currency} />
          </>
        )
      })
    }
    return(
      <Row>
        {item_distr}
      </Row>
    )
  }
}


export {
  SubscriptionManage,
  SubscriptionManageInProject,
  SelectSubscription,
  ShowSubscription,
  SubscriptionView
};