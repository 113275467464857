class DebtCreator{
  constructor(name, array_size,first_year,hist, debt_existence_for_budget){
    this.debt_name=name
    this.debt_existence=debt_existence_for_budget
    this.debt_year=first_year
    this.debt_month=1
    this.debt_amount=0
    this.debt_duration=0
    this.debt_interest_rate=0
    this.debt_reibursement_type=0
    this.interest_rate_type="fixed"
    this.debt_interest_rate_float=new Array(array_size).fill(0)
    this._debt_disbursement=new Array(array_size).fill(0)
    this._debt_initial_debt=new Array(array_size).fill(0)
    this._debt_usable_month=new Array(array_size).fill(0)
    this._debt_number_of_months=new Array(array_size).fill(0)
    this._debt_monthly_reimbursement=0
    this._debt_final_debt=new Array(array_size).fill(0)
    this._debt_reimbursement=new Array(array_size).fill(0)
    this._debt_interest=new Array(array_size).fill(0)
    this._debt_hist=hist
  }
}

export default DebtCreator;