import React from 'react';
import {Row, Col } from 'Support/grid'

import UserContext from 'CONFIG/auth';
import Session from 'CONFIG/session';


import {EditProjectButton, DeleteProjectButton, ViewProjectButton, RegularButton} from 'Support/listbuttons';
import Loader from 'Support/loader';

class BugetList extends React.Component{
  static contextType = UserContext

  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentDidMount() {
    Session.setHistory(this.props.location)
  }

  render(){
    let user_c= this.context
    const trsl=user_c.i18n.t('PRIVATE_BUDGET.MAIN.List')

      const props={
        trsl:trsl,
        handleGoToBudget:this.props.handleGoToBudget,
        handleGoToAddProject:this.props.handleGoToAddProject,
        handleDeleteProject:this.props.handleDeleteProject,
        owned_projects:this.props.list.owned_projects,
        colaborating_projects:this.props.list.colaborating_projects,
        viewer_projects:this.props.list.viewer_projects,
        user_id:this.props.user_id
      }

        function torender(data, i){
            return data[i];
          }  
        const pages ={
        true: <ToDisplay {...props}/>,
        false: <LoaderWraper />
        }
        var i = this.props.list.list_loaded
        const renderizing = torender(pages,i)
    
        return(
            <div>
              {renderizing}
            </div>
        )
    }
}

function LoaderWraper (props){
  return(
    <>
      <Loader loader_message="projects_list"/>
    </>
  )
}

class ToDisplay extends React.Component{
  
  render(){
    const props=this.props
    return(
      <>
        <Row>
          <Col xs={12} md={9} >
            <h1>
              {this.props.trsl['projects_list']}
            </h1>
          </Col>
          <Col xs={12} md={3} className='blanktop_05'>
            <RegularButton label={this.props.trsl['create_project_button']} bk="bk_primary" onClick={this.props.handleGoToAddProject} />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <ProjectListItem cl_type="owned_projects" label={this.props.trsl['your_projects']} {...props} no_projects={this.props.trsl['no_own_projects']}/>
          </Col>
        </Row>
      </>
    );
  }
}

class ProjectListItem extends React.Component{
  static contextType = UserContext

  render(){
    let user_c= this.context

    const item_holder = this.props[this.props.cl_type]
    let item_distr
    if(Array.isArray(item_holder) && item_holder.length>0){
      item_distr = item_holder.map((value) =>{
        let button_to_display=''
        if(this.props.cl_type=='viewer_projects'){
          button_to_display= <ViewProjectButton name="main" handleEditItem={this.props.handleGoToBudget} value={value.budget_id}/>
        } else {
          button_to_display=<EditProjectButton name="main" handleEditItem={this.props.handleGoToBudget} value={value.budget_id}/>
        }

        return(      
          <>
            <Row className="grid_middle list_item">
              <Col xs={6} md={4}>
                {value.budget_name}
              </Col>
              <Col xs={6} md={3}>
                {value.owner}
              </Col>                       
              <Col xs={6} md={3}>
                {user_c.i18n.dateF(value.validity)}
              </Col>
              <Col xs={6} md={2}>
                <Row center="xs">
                  {button_to_display}
                  <DeleteProjectButton value={value.budget_id} handleDelete={this.props.handleDeleteProject}/>
                </Row>
              </Col>
            </Row>
          </>
        )}
      );
    } else {
      item_distr =
      <Row>
        <Col xs={12}>
          {this.props.no_projects}
        </Col>
      </Row>
    }

    return(
      <div className='blanktop_15'>
        <h5>{this.props.label}</h5>
        <Col xs={12} className="general_list">
          <ProjectListHeader label={this.props.label} trsl={this.props.trsl}/>
          {item_distr}
        </Col>
      </div>
    );
  }
}

class ProjectColViewListItem extends React.Component{
  static contextType = UserContext

  render(){
    let user_c= this.context

    const item_holder = this.props[this.props.cl_type]
    let item_distr
    if(Array.isArray(item_holder) && item_holder.length>0){
      item_distr = item_holder.map((value) =>{
        let button_to_display=''
        if(this.props.cl_type=='viewer_projects'){
          button_to_display= <ViewProjectButton name="main" handleEditItem={this.props.handleGoToBudget} value={value.project_id}/>
        } else {
          button_to_display=<EditProjectButton name="main" handleEditItem={this.props.handleGoToBudget} value={value.budget_id}/>
        }
        return(
          <>
            <Row className="grid_middle list_item">
              <Col xs={6} md={4}>
                {value.budget_name}
              </Col>
              <Col xs={6} md={7}>
                {value.owner}
              </Col>
              <Col xs={6} md={1}>
                <Row center="xs">
                  {button_to_display}
                </Row>
              </Col>
            </Row>
          </>
        )}
      );
    } else {
      item_distr =
      <Row>
        <Col xs={12}>
          {this.props.no_projects}
        </Col>
      </Row>
    }

    return(
      <div className='blanktop_15'>
        <h5>{this.props.label}</h5>
        <Col xs={12} className="general_list">
          <ProjectColViewListHeader label={this.props.label} trsl={this.props.trsl}/>
            {item_distr}
        </Col>
      </div>
    );
  }
}

function ProjectListHeader (props){
  return(
    <Row className="list_table_header_left">
      <Col xs={6} md={4} className="list_table_header_inside">
        {props.trsl['projects']}
      </Col>
      <Col xs={6} md={3} className="list_table_header_inside">
        {props.trsl['owner']}
      </Col>
      <Col xs={6} md={3} className="list_table_header_inside">
        {props.trsl['validity']}
      </Col>
      <Col xs={6} md={2}className="list_table_header_inside">
      </Col>
    </Row>
  );
}

function ProjectColViewListHeader (props){
  return(
    <Row className="list_table_header_left">
      <Col xs={6} md={4} className="list_table_header_inside">
      {props.trsl['projects']}
      </Col>
      <Col xs={6} md={7} className="list_table_header_inside">
        {props.trsl['owner']}
      </Col>
      <Col xs={6} md={1} className="list_table_header_inside">
      </Col>
    </Row>
  );
}


export default BugetList;