import React from 'react';

import DebtCreator from '../../../../CONFIG/CREATORS/debt_creator';
import {SinglesFeasibilityListItems} from '../feasibility_items';


class DebtList extends React.Component{
  handleAdd =async(item)=>{
    let years=this.props.feasibility_settings.number_of_years
    if(this.props.feasibility_settings.use_historic===true){
      years=parseInt(this.props.feasibility_settings.number_of_years)+parseInt(this.props.feasibility_settings.number_of_historic_years)
    }
    let first_year=0
    if(this.props.feasibility_settings.use_historic===true){
      first_year+= parseInt(this.props.feasibility_settings.number_of_historic_years)
    }
    const element_to_add= new DebtCreator(
        item,
        years,
        first_year
      )
      this.props.handleLists.handleAdd(element_to_add, 'debt')
  }

  render(){
    return(
      <SinglesFeasibilityListItems 
      data={this.props.version_data.debt} 
      feasibility_settings={this.props.feasibility_settings}
      user_permission={this.props.user_permission}
      handleAdd={this.handleAdd} 
      handleScreenNavigation={this.props.handleScreenNavigation} 
      handleDeleteItem={this.props.handleLists.handleDeleteItem}
      handleDuplicateItem={this.props.handleLists.handleDuplicateItem}
      handleChangLevel={this.props.handleLists.handleChangLevel}
      title='debt_title'
      add_item='add_debt'
      item_type='debt'
      />
    )
  }
}

export default DebtList;