import React, {useContext} from 'react';
import { BrowserRouter, redirect, Route, Routes,Navigate } from "react-router-dom";
import ReactGA from 'react-ga4';

import UserContext,{ UserProvider}  from "./CONFIG/auth";
import Session  from "./CONFIG/session";
import ApiConnect from 'CONFIG/axios';

import Login from './PUBLIC/login';
import Activate from './PUBLIC/activate';
import Register from './PUBLIC/register';
import PasswordRecovery from './PUBLIC/password_recovery';
import PasswordRecoveryNewPassword from './PUBLIC/password_recovery_new';
import ActivationRequest from './PUBLIC/activation_request_resend';
import InPrivateAPP from './PRIVATE/Private';

import 'CSS/index.css'
import { Alerts } from 'Support/alert';

class App extends React.Component{
  constructor(props){
    super(props);
    this.state={
      alert_system:{
        show:false,
        type:'',
        header:"",
        body:"",
      }
    }
  }

  activateAlert=(type,header,body)=>{
    this.setState({
      alert_system:{
        show:true,
        type:type,
        header:header,
        body:body
      }
    })
  }

  disactivateAlert=()=>{
    this.setState({
      alert_system:{
        show:false,
        type:"",
        header:"",
        body:""
      }
    })
  }
 

  render(){
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS)

    return(
      <UserProvider >
        <ThemeSelector>
          <Alerts alert_system={this.state.alert_system} disactivateAlert={this.disactivateAlert}/>
          <Wrapper activateAlert={this.activateAlert}/>
        </ThemeSelector>
    </UserProvider>
    )
  }
}

class Wrapper extends React.Component{
  static contextType = UserContext


  componentDidMount(){
    ReactGA.send({hitType: "pageview", page: window.location.pathname ,title: window.location.pathname});
  }  
  render(){ 
    const props=this.props 
    const context = this.context
    const private_displayer =(value)=>{
      if(value){
        return <Route path="/app/*" element={<InPrivateAPP activateAlert={this.activateAlert}/>}/>
      } else {
        return redirect("/login")
      }
    }
    return(
      <>
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Login activateAlert={this.props.activateAlert}/>}/>
            <Route path="login" element={<Login activateAlert={this.props.activateAlert}/>}/>
            <Route path="/activate/:user/:email/:id" element={<Activate activateAlert={this.props.activateAlert}/>} />
            <Route path="/register" element={<Register activateAlert={this.props.activateAlert}/>}/>
            <Route path="/password" element={<PasswordRecovery activateAlert={this.props.activateAlert}/>} />
            <Route path="/passwordrecovery/:email/:element" element={<PasswordRecoveryNewPassword activateAlert={this.props.activateAlert}/>} />
            <Route path="/activationrequest" element={<ActivationRequest activateAlert={this.props.activateAlert}/>} />
            <Route path ='/app/*' element={
              <ProtectedRoute value={context.logged}>
                <InPrivateAPP activateAlert={this.props.activateAlert}/>
              </ProtectedRoute>
            }/>
          </Routes>  
        </BrowserRouter>
        <Axi />
        {props.children}
      </>
    )
  }
}

function ProtectedRoute ({ value, children }) {
  if (!value) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

function Axi(){
  const context = useContext(UserContext);
  ApiConnect.interceptors.response.use(
    (response)=>{
      if(response.headers.token!=undefined && response.headers.token.length>0){
        Session.setToken(response.headers.token)
        return response
      } else{
        context.unSetUser()
        alert("Session Time out")
        return response
      }
    },
    (error)=>{
      return Promise.reject(error)
    }
  )
  return(
    <></>
  )
}


const CasfloTemplate = React.lazy(() => import('TEMPLATES/CASFLO/casflo_template'));
const ViseeonTemplate = React.lazy(() => import('TEMPLATES/Viseeon/template'));
const BazaarTemplate = React.lazy(() => import('TEMPLATES/BAZAAR/template'));

const ThemeSelector = ({ children }) => {
  const context = useContext(UserContext);
  const chosen_theme =context.template
  
  return (
    <>
      <React.Suspense fallback={<></>}>
        {(chosen_theme === 'casflo' || chosen_theme === 'viseeon') && <CasfloTemplate />}
        {(chosen_theme === 'bazaar') && <BazaarTemplate />}
      </React.Suspense>
      {children}
    </>
  )
}

export default App;