import {RevenueCreator} from './revenue_creator';
import OperatingExpensesCreator from './operating_exp_creator'
import HrCreator from  './hr_creator'
import OtherGainsCostCreator from './other_gains_cost_creator'
import InvestmentCreator from './investment_creator'
import ShareholdersCreator from './shareholders_creator'
import DebtCreator from './debt_creator'
import OtherBalanceCreator from './other_balance_creator'


const Creators={
  RevenueCreator:RevenueCreator,
  OperatingExpensesCreator:OperatingExpensesCreator,
  HrCreator:HrCreator,
  OtherGainsCostCreator:OtherGainsCostCreator,
  InvestmentCreator:InvestmentCreator,
  ShareholdersCreator:ShareholdersCreator,
  DebtCreator:DebtCreator,
  OtherBalanceCreator:OtherBalanceCreator
}

export default Creators;